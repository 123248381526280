import * as React from 'react'
import { Property } from 'csstype'

import { styled, Text, spacing } from '@thg-commerce/gravity-theme'
import { VisuallyHidden } from '@thg-commerce/gravity-elements/VisuallyHidden'

import Button from '../../Button'
import { Star } from './Star'
import { DrawFocus } from '../../mixin/focus'

export enum RatingSize {
  Small = 'small',
  Large = 'large',
}

export interface ProductReviewProps {
  starRating: number
  numberOfReviews?: number
  screenReaderOnlyText: string
  showAverage?: boolean
  size?: RatingSize
  reviewsCounterSuffixText?: string
  reviewLinkUrl?: string
  className?: string
  starFill?: Property.Color
  url?: string
  colorFills?: {
    fill: Property.Color
    background: Property.Color
  }
  onProductReviewClick?: (e: React.SyntheticEvent) => void
  enableFocusOutline?: boolean
}

const RatingContainer = styled.div<{
  enableFocusOutline?: boolean
}>`
  display: flex;
  align-items: center;
  text-decoration: none;
  ${(props) => props.enableFocusOutline && DrawFocus}
`

const StarContainer = styled.div`
  display: flex;
  align-content: center;
`

const StyledText = styled.p<{ size?: RatingSize }>`
  margin-left: ${spacing(1)};

  ${(props) =>
    props.size === RatingSize.Large
      ? Text('large1', 'alternate')
      : Text('bodyText', 'default')}
`

export const ProductReview = (props: ProductReviewProps) => {
  const stars = Array.from({ length: 5 }, (_, index) => {
    return (
      <Star
        fillValue={props.starRating - index}
        height={props.size === RatingSize.Large ? '30px' : '16px'}
        width={props.size === RatingSize.Large ? '30px' : '16px'}
        colorFills={props.colorFills}
        key={index}
      />
    )
  })

  const reviewText = props.reviewsCounterSuffixText
    ? `${props.numberOfReviews} ${props.reviewsCounterSuffixText}`
    : `(${props.numberOfReviews})`

  return (
    <RatingContainer
      className={props.className}
      data-testid="product-rating-container"
      as={props.url ? 'a' : undefined}
      href={props.url}
      enableFocusOutline={props.enableFocusOutline ?? true}
    >
      <VisuallyHidden text={props.screenReaderOnlyText} />
      <StarContainer aria-hidden={true} data-testid="star-container">
        {stars}
      </StarContainer>
      {props.numberOfReviews && (
        <StyledText data-testid="number-of-reviews" size={props.size}>
          {props.reviewLinkUrl ? (
            <Button
              renderedAs="a"
              emphasis="low"
              href={props.reviewLinkUrl}
              onClick={props.onProductReviewClick}
            >
              {reviewText}
            </Button>
          ) : (
            reviewText
          )}
        </StyledText>
      )}
      {!props.numberOfReviews && props.showAverage && (
        <StyledText size={props.size} aria-hidden={true}>
          {props.starRating}
        </StyledText>
      )}
    </RatingContainer>
  )
}
