import * as React from 'react'
import { RefObject } from 'react'
import { v4 as uuid } from 'uuid'

import {
  StyledDropdown,
  HelperTextContainer,
  StyledChevronDown,
  StyledDropdownContainer,
  StyledChevronDownContainer,
} from './Styles'
import { Label, LabelText } from '../Label'
import DropdownOption from './DropdownOption'

interface DropdownI18nText {
  optionalLabel?: string
  requiredError?: string
}

interface DropdownProps {
  children: React.ReactNode
  label: string
  /** If a boolean, shows the error state without a message. If a string is passed, shows it as a message with the error state. */
  error?: string | boolean
  /** Is dropdown disabled */
  disabled?: boolean
  /** Is dropdown a required field */
  required?: boolean
  /** Is the label hidden */
  labelHidden?: boolean
  helperText?: string
  validators?: { name: string; argument: string | number }[]
  placeholder?: string
  defaultValue?: string
  value?: string
  bindref?: RefObject<HTMLSelectElement>
  i18nText?: DropdownI18nText
  dark?: boolean
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  className?: string
  /** Dropdown styles apply to all dropdowns. This overrides those default styles where required */
  styleOverride?: {
    textColor?: string
    borderColor?: string
    textSize?: string
  }
  ariaLabel?: string
  labelCustomComponent?: React.ReactNode
}

const defaultProps = {
  error: false,
  helperText: '',
  labelHidden: false,
  required: false,
}

/** @component */
export const Dropdown = (props: DropdownProps) => {
  const { children, label, placeholder, className, ...rest } = props

  const dropdownValues = props.value
    ? { value: props.value }
    : {
        defaultValue: props.defaultValue || '',
      }

  const dropdownEl: RefObject<HTMLSelectElement> =
    props.bindref || React.useRef<HTMLSelectElement>(null)

  const elementLabel = `${props.label
    .toLowerCase()
    .split(' ')
    .join('-')}-dropdown-element-id-${uuid()}`

  return (
    <div className={className}>
      {!props.labelHidden && (
        <Label
          className={props.disabled ? 'disabled' : ''}
          htmlFor={elementLabel}
          labelCustomComponent={Boolean(props.labelCustomComponent)}
        >
          <LabelText
            required={props.required}
            className={props.labelHidden ? 'hidden' : ''}
            optionalText={
              (props.i18nText && props.i18nText.optionalLabel) || ''
            }
            disabled={props.disabled}
          >
            {props.label}
          </LabelText>
          {props.labelCustomComponent ? props.labelCustomComponent : null}
        </Label>
      )}
      <StyledDropdownContainer>
        <StyledDropdown
          {...dropdownValues}
          {...rest}
          ref={dropdownEl}
          data-testid={`${props.label}-dropdown`}
          id={`${elementLabel}`}
          labelHidden={props.labelHidden}
          styleOverride={props.styleOverride}
          aria-label={props.ariaLabel}
          placeholder={placeholder}
        >
          {placeholder && (
            <DropdownOption
              selected
              hidden={props.required}
              disabled={props.required}
              value={''}
            >
              {placeholder}
            </DropdownOption>
          )}

          {props.children}
        </StyledDropdown>
        <StyledChevronDownContainer>
          <StyledChevronDown
            fill={props.styleOverride?.textColor}
            className={props.disabled ? 'disabled' : ''}
            aria-hidden={true}
            focusable={false}
          />
        </StyledChevronDownContainer>
      </StyledDropdownContainer>
      {!props.error && props.helperText && (
        <HelperTextContainer data-testid="Dropdown-helper-text">
          {props.helperText}
        </HelperTextContainer>
      )}
    </div>
  )
}

Dropdown.defaultProps = defaultProps

export default Dropdown
