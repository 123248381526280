import * as styledComponents from 'styled-components'
import merge from 'lodash.merge'

import { theme as BaseTheme } from '@thg-commerce/gravity-theme'
import { ComponentThemeInterface, CombinedThemeInterface } from './'
import { theme as AccordionTheme } from './components/Accordion/theme'
import { theme as PictureTheme } from './components/Picture/theme'

export const componentTheme: ComponentThemeInterface = {
  components: {
    accordion: AccordionTheme,
    picture: PictureTheme,
  },
}

const theme = merge({}, BaseTheme, componentTheme)

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme,
  useTheme,
} = (styledComponents as unknown) as styledComponents.ThemedStyledComponentsModule<
  CombinedThemeInterface
>

export {
  theme,
  styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme,
  useTheme,
}
