import * as React from 'react'
import loadable from '@loadable/component'

import { CopyrightLogo } from '@thg-commerce/gravity-patterns/Footer/theme'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { spacing, Text } from '@thg-commerce/gravity-theme'

const CopyrightThg = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/CopyrightThg'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const CopyrightThgSimpleLogo = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Copyright'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const CopyrightThgIngenuity = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/CopyrightThgIngenuity'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Authentication = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Authentication'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const MailFromUk = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/MailFromUk'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

export interface CopyrightProps {
  text: string
  hasFooterCopyrightAboveLogo?: boolean
  hasFooterCopyrightIcons?: boolean
  displayIcon?: CopyrightLogo
  logoFill?: string
  ariaMomentumText: string
}

const StyledMailSvg = styled(MailFromUk)`
  margin-right: ${spacing(1.25)};
`

const StyledLargeSvgContainer = styled.div`
  height: 25px;
  margin: ${spacing(1.25)} 0 ${spacing(2)} 0;
`

const StyledCopyrightText = styled.div`
  ${(props) =>
    Text(
      props.theme.patterns.footer.copyrightAndPayments.font.copyright.entry ??
        'bodyText',
      props.theme.patterns.footer.copyrightAndPayments.font.copyright.style ??
        'default',
    )}
  color:${(props) =>
    props.theme.patterns.footer.copyrightAndPayments.font.copyright.textColor};
`

const StyledImageList = styled.li`
  list-style: none outside;
`

const StyledCopyrightContainer = styled.div<{ aboveLogo: boolean | undefined }>`
  display: flex;
  flex-direction: ${(props) => (props.aboveLogo ? 'column' : 'column-reverse')};
`

export const Copyright = (props: CopyrightProps) => {
  const CopyrightSvg = () => {
    if (!props.displayIcon) {
      return null
    }
    const copyrightDisplaySvg =
      props.displayIcon === 'ingenuity' ? (
        <CopyrightThgIngenuity fill={props.logoFill} />
      ) : props.displayIcon === 'thgSimpleLogo' ? (
        <CopyrightThgSimpleLogo fill={props.logoFill} />
      ) : (
        <CopyrightThg
          aria-label={props.ariaMomentumText}
          fill={props.logoFill}
        />
      )
    return (
      <StyledLargeSvgContainer>{copyrightDisplaySvg}</StyledLargeSvgContainer>
    )
  }

  const CopyrightImages = () => {
    return (
      <StyledImageList>
        <StyledMailSvg />
        <Authentication />
      </StyledImageList>
    )
  }
  return (
    <StyledCopyrightContainer aboveLogo={props.hasFooterCopyrightAboveLogo}>
      <StyledCopyrightText>{props.text}</StyledCopyrightText>
      <div>
        <CopyrightSvg />
        {props.hasFooterCopyrightIcons && (
          <CopyrightImages data-testid="copyright-images" />
        )}
      </div>
    </StyledCopyrightContainer>
  )
}
