import * as React from 'react'
import withHydrationOnDemand from 'react-hydration-on-demand'
import Media from 'react-media'
import loadable from '@loadable/component'
import ResizeObserver from 'resize-observer-polyfill'

import { FavouritesOutline } from '@thg-commerce/gravity-icons/src'
import {
  FlyoutDirection,
  HeaderComponent,
  HeaderThemeInterface,
} from '@thg-commerce/gravity-patterns/Header/theme'
import { useTheme } from '@thg-commerce/gravity-patterns/theme'
import { BreakpointKey, mq } from '@thg-commerce/gravity-theme'

import { AccountDropdown } from '../Header/AccountDropdown'
import { HeaderLink, HeaderProps } from '../Header/Header'
import { HeaderSlots } from '../Header/HeaderSlots'
import { Navigation } from '../Header/Navigation'
import { Submenu } from '../Header/Submenu'
import { DropdownOptions } from '../Header/types'

import {
  AccountDropdownIcon,
  Basket,
  FlyoutPresenter,
  Logo,
} from './components'
import {
  AccountWrapper,
  BasketWrapper,
  Container,
  DesktopWrapper,
  ExposedSearchWrapper,
  FlyoutWrapper,
  Header,
  LinkWrapper,
  NavigationInnerWrapper,
  SearchWrapper,
  SubmenuWrapper,
  WishlistLink,
  WishlistWrapper,
} from './styles'

const HeaderSearchComponent = loadable(
  () => import('../Header/HeaderSearch').then((mod) => mod.HeaderSearch),
  {
    ssr: true,
  },
)

const HeaderSearch = withHydrationOnDemand({
  on: [['idle']],
  onBefore: HeaderSearchComponent.load,
})(HeaderSearchComponent)

const slotComponentMap: {
  [component in HeaderComponent]: (
    props: HeaderProps,
    isDesktop: boolean,
    headerTheme: HeaderThemeInterface,
    breakpoint: BreakpointKey,
    newHeaderRef: React.RefObject<HTMLDivElement>,
  ) => React.ReactNode
} = {
  logo: (props) => {
    return (
      <div style={{ gridArea: HeaderComponent.LOGO }}>
        <Logo
          logoAriaLabel={props.i18nText.logoAriaLabel}
          logoLink={props.logoLink}
          logoOnClick={props.logoOnClick}
        />
      </div>
    )
  },
  navigation: (props, isDesktop, _headerTheme, _breakpoint, newHeaderRef) => {
    return (
      <div
        style={{
          gridArea: HeaderComponent.NAVIGATION,
          width: '100%',
          maxWidth: '1400px',
          display: isDesktop ? 'block' : 'none',
        }}
      >
        <NavigationInnerWrapper data-testid="navigation-inner-wrapper">
          <Navigation
            fullWidthSeparator={true}
            headerPromotionUrl={props.headerPromotionUrl}
            truncateNavigationItems={true}
            brandsData={props.brands.brandsData}
            routes={{
              viewAllBrandsLink: props.brands.viewAllBrandsLink,
              listExtension: props.routes.listExtension,
            }}
            navigationTree={props.navigationTree}
            hasDynamicHeaderDropdown={props.hasDynamicHeaderDropdown}
            hasWaterfallMenu={props.hasWaterfallMenu}
            i18nText={{
              categoryHomeText: props.flyoutI18nText.linkWithChildrenLabel,
              viewAllBrandsText: props.brands.i18nText.viewAllBrands,
              navAriaLabel: props.brands.i18nText.navAriaLabel,
            }}
            navWrapperHeight={_headerTheme.navigation.height}
            navHeaderHeight={props.headerHeight}
            enableThreeTierNav={props.enableThreeTierNav}
            selectTopLevelNavigationItem={props.selectTopLevelNavigationItem}
            selectedTopLevelNavigationItemIndex={
              props.selectedTopLevelNavigationItemIndex
            }
            headerRef={newHeaderRef}
            navigationOnClickCallback={props.navigationOnClickCallback}
          />
        </NavigationInnerWrapper>
      </div>
    )
  },
  search: (props, isDesktop, headerTheme) => {
    return (
      <SearchWrapper mobileSearch={headerTheme.mobileSearch}>
        <HeaderSearch
          currency={props.headerSearch.currency}
          shippingDestination={props.headerSearch.shippingDestination}
          isMobile={!isDesktop}
          showExposedSearch={props.headerSearch.showExposedSearch}
          showPowerReview={props.headerSearch.showPowerReview}
          headerSearchI18nText={{
            placeholder: props.i18nText.searchPlaceholder,
            searchButtonAriaLabel: props.i18nText.searchButtonAriaLabel,
            clearAriaLabel: props.i18nText.clearAriaLabel,
          }}
          InstantSearchInjector={props.headerSearch.InstantSearchInjector}
          searchI18nText={props.headerSearch.searchI18nText}
          onSubmit={props.headerSearch.onSubmit}
          autocompleteLink={props.headerSearch.autocompleteLink}
          renderAnnouncer={props.headerSearch.renderAnnouncer}
          triggerAriaLabels={{
            close: props.headerDropdownI18nText.closeButtonAriaLabel,
            search: props.i18nText.searchButtonAriaLabel,
          }}
          mobileIcon={headerTheme.search.mobileIcon}
          onFocus={props.headerSearch.onFocus}
          mobileSearch={isDesktop && headerTheme.mobileSearch}
          slimHeader={true}
          vipPriceEnabled={props?.headerSearch.vipPriceEnabled ?? null}
          {...(props.enableRecentSearches && {
            enableRecentSearches: props.enableRecentSearches,
            recentlySearchedTitle: props.recentlySearchedTitle,
            recentlySearchedText: props.recentlySearchedText,
            clearRecentSearch: props.clearRecentSearch,
          })}
          {...(props.enablePromotionalSearch && {
            promotionalProducts: props.headerSearch.promotionalProducts || [],
            enablePromotionalSearch: props.enablePromotionalSearch || false,
          })}
          trendingTerms={props.headerSearch.trendingTerms}
          enableTrendingSearch={props.headerSearch.enableTrendingSearch}
          enablePersistentSearch={props.enablePersistentSearch}
        />
      </SearchWrapper>
    )
  },

  link: (props) => {
    return (
      <LinkWrapper>
        <HeaderSlots
          headerType="header"
          i18nText={props.i18nText.slotText}
          slotConfig={props.headerSlots}
          link={<HeaderLink />}
        />
      </LinkWrapper>
    )
  },
  account: (props, isDesktop) => {
    return (
      <AccountWrapper>
        <AccountDropdown {...props} isDesktop={isDesktop} newHeaderEnabled />
      </AccountWrapper>
    )
  },
  basket: (props, isDesktop, headerTheme) => {
    return (
      <BasketWrapper>
        <Basket
          isDesktop={isDesktop}
          route={props.routes.basket}
          dropdownWidth={headerTheme.dropdowns.basket.width}
          {...props}
        />
      </BasketWrapper>
    )
  },
  submenu: (props, isDesktop, headerTheme) => {
    return (
      isDesktop && (
        <SubmenuWrapper data-testid="sub-menu-wrapper">
          <Submenu
            isDesktop={isDesktop}
            {...props.submenu}
            accountDropdownProps={{
              isMobile: !isDesktop,
              trigger: {
                icon: (
                  <AccountDropdownIcon
                    accountIcon={headerTheme.dropdowns.account.icon.svgPath}
                  />
                ),
                text: props.i18nText.accountLabel,
                triggerHref: props.routes.accountHome,
              },
              dropdownWidth: headerTheme.dropdowns.account.width,
              content: props.accountNav,
              i18nAriaLabels: {
                close: props.headerDropdownI18nText.closeButtonAriaLabel,
                trigger: props.i18nText.accountButtonAriaLabel,
              },
              disableFocusLogic: false,
              notification: props.wishlistNotification,
              dropdownType: DropdownOptions.ACCOUNT,
            }}
          />
        </SubmenuWrapper>
      )
    )
  },
  flyout: (props, isDesktop, headerTheme, breakpoint) => {
    return (
      !isDesktop && (
        <FlyoutWrapper>
          <FlyoutPresenter
            data-testid="flyout"
            isDesktop={isDesktop}
            headerPromotionUrl={props.headerPromotionUrl}
            i18nText={props.i18nText}
            headerDropdownI18nText={props.headerDropdownI18nText}
            navigationTree={props.navigationTree}
            flyoutI18nText={props.flyoutI18nText}
            routes={props.routes}
            accountNav={props.accountNav}
            submenu={props.submenu}
            headerSlots={props.headerSlots}
            responsiveFlyoutMenuIcons={props.responsiveFlyoutMenuIcons}
            slots={headerTheme.slots}
            flyoutIcon={headerTheme.dropdowns.account.icon.svgPath}
            dropdownWidth={headerTheme.dropdowns.account.width}
            burgerIconColor={headerTheme.navigation.burgerIconColor}
            flyoutMenuIcon={headerTheme.flyout.icon}
            flyoutDirection={
              headerTheme.flyout.direction[breakpoint] as FlyoutDirection
            }
            accountDropdownProps={{
              isMobile: !isDesktop,
              trigger: {
                icon: (
                  <AccountDropdownIcon
                    accountIcon={headerTheme.dropdowns.account.icon.svgPath}
                  />
                ),
                text: props.i18nText.accountLabel,
                triggerHref: props.routes.accountHome,
              },
              dropdownWidth: headerTheme.dropdowns.account.width,
              content: props.accountNav,
              i18nAriaLabels: {
                close: props.headerDropdownI18nText.closeButtonAriaLabel,
                trigger: props.i18nText.accountButtonAriaLabel,
              },
              disableFocusLogic: false,
              notification: props.wishlistNotification,
              dropdownType: DropdownOptions.ACCOUNT,
            }}
            enableThreeTierNav={props.enableThreeTierNav}
            selectTopLevelNavigationItem={props.selectTopLevelNavigationItem}
            homeButtonOnClick={props.logoOnClick}
            primaryNavImages={props.primaryNavImages}
            navSubLevelPromotionList={props.navSubLevelPromotionList}
            selectedTopLevelNavigationItemIndex={
              props.selectedTopLevelNavigationItemIndex
            }
            navigationOnClickCallback={props.navigationOnClickCallback}
            hideThreeTierTab={props.hideThreeTierTab}
          />
        </FlyoutWrapper>
      )
    )
  },
  wishlist: (props, isDesktop, headerTheme) => {
    return (
      isDesktop && (
        <WishlistWrapper>
          <WishlistLink
            href={props.routes.wishlist}
            aria-label={props.i18nText.wishlistAriaLabel}
            data-testid="header-wishlist-wrapper"
          >
            {headerTheme.wishlist.icon.svgPath !== '' ? (
              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox={headerTheme.wishlist.icon.viewBox}
                height={headerTheme.wishlist.icon.height}
                width={headerTheme.wishlist.icon.width}
              >
                <path
                  d={headerTheme.wishlist.icon.svgPath}
                  fillRule="evenodd"
                ></path>
                <title>{props.i18nText.wishlistAriaLabel}</title>
              </svg>
            ) : (
              <FavouritesOutline />
            )}
          </WishlistLink>
        </WishlistWrapper>
      )
    )
  },
}

export const NewHeader = (props: HeaderProps) => {
  const { headerRef, ...otherProps } = props
  const trackingEventRef = React.useRef<HTMLDivElement>(null)
  const newHeaderRef = React.useRef<HTMLDivElement>(null)

  const theme = useTheme()
  const headerTheme = theme.patterns.header

  React.useEffect(() => {
    let resizeObserver: ResizeObserver | undefined
    if (headerRef?.current) {
      resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        // TODO: fix lint error
        const { height } = entries[0]?.contentRect
        otherProps.setHeight(height)
      })
      resizeObserver.observe(headerRef.current)
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [headerRef, otherProps])

  const components = otherProps.useAlternateHeaderGrid
    ? headerTheme.alternateEnabledComponents
    : headerTheme.enabledComponents

  return (
    <React.Fragment>
      <Header
        ref={headerRef}
        sticky={otherProps.sticky}
        extendBackground={components.lg.includes(HeaderComponent.SUBMENU)}
        useAlternateGrid={otherProps.useAlternateHeaderGrid}
        enableThreeTierNav={otherProps.enableThreeTierNav}
      >
        <Container
          data-testid="new-header"
          useAlternateGrid={otherProps.useAlternateHeaderGrid}
          useTemplateRows={components.lg.includes(HeaderComponent.SUBMENU)}
          enableThreeTierNav={otherProps.enableThreeTierNav}
          ref={newHeaderRef}
        >
          <Media
            query={mq(theme.breakpointUtils.map, 'lg', true)}
            defaultMatches={!otherProps.isMobile}
          >
            {(isDesktop) => (
              <DesktopWrapper>
                {components.lg.map((component) => {
                  return slotComponentMap[component](
                    props,
                    isDesktop,
                    headerTheme,
                    'lg',
                    newHeaderRef,
                  )
                })}
              </DesktopWrapper>
            )}
          </Media>
          <Media
            queries={{
              md: `${mq(
                theme.breakpointUtils.map,
                'md',
                true,
              )} and (max-width: calc(${
                theme.breakpointUtils.map.lg
              }px - 1px))`,
            }}
            defaultMatches={{ md: otherProps.isMobile }}
            render={() => {
              return components.md.map((component) => {
                return slotComponentMap[component](
                  props,
                  false,
                  headerTheme,
                  'md',
                  newHeaderRef,
                )
              })
            }}
          />
          <Media
            queries={{
              sm: `${mq(
                theme.breakpointUtils.map,
                'sm',
                true,
              )} and (max-width: calc(${
                theme.breakpointUtils.map.md
              }px - 1px))`,
            }}
            defaultMatches={{ sm: otherProps.isMobile }}
            render={() => {
              return components.sm.map((component) => {
                return slotComponentMap[component](
                  props,
                  false,
                  headerTheme,
                  'sm',
                  newHeaderRef,
                )
              })
            }}
          />
          <Media
            queries={{
              xs: `${mq(
                theme.breakpointUtils.map,
                'xs',
                true,
              )} and (max-width: calc(${
                theme.breakpointUtils.map.sm
              }px - 1px))`,
            }}
            defaultMatches={{ xs: otherProps.isMobile }}
            render={() => {
              return components.xs.map((component) => {
                return slotComponentMap[component](
                  props,
                  false,
                  headerTheme,
                  'xs',
                  newHeaderRef,
                )
              })
            }}
          />
        </Container>
      </Header>
      {!otherProps.isMobileApp && otherProps.headerSearch.showExposedSearch && (
        <Media
          queries={{
            xs: `${mq(
              theme.breakpointUtils.map,
              'xs',
              true,
            )} and (max-width: calc(${theme.breakpointUtils.map.lg}px - 1px))`,
          }}
          defaultMatches={{ xs: otherProps.isMobile }}
        >
          <ExposedSearchWrapper
            ref={trackingEventRef}
            data-testid="exposed-search-wrapper"
          >
            <HeaderSearch
              currency={otherProps.headerSearch.currency}
              shippingDestination={otherProps.headerSearch.shippingDestination}
              isMobile={
                otherProps.headerSearch.showExposedSearch
                  ? false
                  : otherProps.isMobile
              }
              showExposedSearch={otherProps.headerSearch.showExposedSearch}
              showPowerReview={otherProps.headerSearch.showPowerReview}
              headerSearchI18nText={{
                placeholder: otherProps.i18nText.searchPlaceholder,
                searchButtonAriaLabel:
                  otherProps.i18nText.searchButtonAriaLabel,
                clearAriaLabel: otherProps.i18nText.clearAriaLabel,
              }}
              InstantSearchInjector={
                otherProps.headerSearch.InstantSearchInjector
              }
              searchI18nText={otherProps.headerSearch.searchI18nText}
              onSubmit={otherProps.headerSearch.onSubmit}
              autocompleteLink={otherProps.headerSearch.autocompleteLink}
              renderAnnouncer={otherProps.headerSearch.renderAnnouncer}
              triggerAriaLabels={{
                close: otherProps.headerDropdownI18nText.closeButtonAriaLabel,
                search: otherProps.i18nText.searchButtonAriaLabel,
              }}
              mobileIcon={headerTheme.search.mobileIcon}
              onFocus={otherProps.headerSearch.onFocus}
              mobileSearch={!otherProps.isMobile && headerTheme.mobileSearch}
              slimHeader={true}
              vipPriceEnabled={props?.headerSearch?.vipPriceEnabled ?? null}
              {...(otherProps.enableRecentSearches && {
                enableRecentSearches: otherProps.enableRecentSearches,
                recentlySearchedTitle: otherProps.recentlySearchedTitle,
                recentlySearchedText: otherProps.recentlySearchedText,
                clearRecentSearch: otherProps.clearRecentSearch,
              })}
              {...(otherProps.enablePromotionalSearch && {
                promotionalProducts:
                  otherProps.headerSearch?.promotionalProducts,
                enablePromotionalSearch: otherProps.enablePromotionalSearch,
              })}
              trendingTerms={otherProps.headerSearch.trendingTerms}
              enableTrendingSearch={
                otherProps.headerSearch.enableTrendingSearch
              }
              enablePersistentSearch={otherProps.enablePersistentSearch}
            />
          </ExposedSearchWrapper>
        </Media>
      )}
    </React.Fragment>
  )
}
