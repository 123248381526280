import { Property } from 'csstype'

import {
  Margin,
  Padding,
  spacing,
  TextStyle,
} from '@thg-commerce/gravity-theme'
import {
  BreakpointArray,
  BreakpointType,
} from '@thg-commerce/gravity-theme/breakpoints'

import { Font, HeaderSlotTheme, TextStyling } from '../'

import {
  Alignment,
  Border,
  BorderBox,
  Order,
  Orientation,
  Separator,
  Visibility,
} from './types'

export enum HeaderComponent {
  LOGO = 'logo',
  NAVIGATION = 'navigation',
  SEARCH = 'search',
  ACCOUNT = 'account',
  BASKET = 'basket',
  WISHLIST = 'wishlist',
  FLYOUT = 'flyout',
  SUBMENU = 'submenu',
  LINK = 'link',
}

export enum DropdownDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum FlyoutDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum ImageThumbnailAlignment {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum FlexDirection {
  ROW = 'row',
  COLUMN = 'column',
}

export type SlotConfiguration = {
  component: HeaderComponent
  colSpan: string
  alignment: Alignment
}

export type IconStyling = {
  svgPath: string
  height: string
  width: string
  color: Property.Color
  viewBox: string
  rectBackgroundFill?: string
  rectStrokeFill?: string
}

export interface HeaderThemeInterface {
  mobileBackgroundColor: Property.Color
  backgroundColor: Property.Color
  bottomSeparator: Separator
  slotsGridAreas: BreakpointType<{ area: string; colSpan: string }>
  alternateGridAreas: BreakpointType<{ area: string; colSpan: string }>
  accountDropdownDirection: BreakpointType<DropdownDirection>
  enabledComponents: BreakpointType<HeaderComponent[]>
  alternateEnabledComponents: BreakpointType<HeaderComponent[]>
  slots: BreakpointType<SlotConfiguration[]>[]
  alignItems: Alignment
  flyout: {
    width: string
    direction: BreakpointType<string>
    icon: IconStyling
    homeIcon: IconStyling
    closeIcon: IconStyling
    chevronRightIcon: IconStyling
    chevronLeftIcon: IconStyling
    storeIcon: IconStyling
    flyoutHeaderContentPadding?: number
  }
  headerSlots: {
    iconColor: {
      active: Property.Color
      inactive: Property.Color
    }
    textStyling: {
      active: TextStyling
      inactive: TextStyling
    }
    orientation: Orientation
  }
  slotItems: {
    submenu: HeaderSlotTheme[]
    header: HeaderSlotTheme[]
    flyout: HeaderSlotTheme[]
  }
  sticky?: {
    top: string
  }
  dropdowns: {
    container: {
      padding: Padding
    }
    flex: {
      width: string
    }
    order: {
      mobile: Order
      desktop: Order
    }
    triggerHeight: string
    account: {
      hideText: boolean
      width: string
      useAlternativeIconSVG: string
      icon: IconStyling
      iconColor: Property.Color
      closeIconColor: Property.Color
      hoverIconColor: Property.Color
      focusBorderColor?: Property.Color
      mobileIconColor: Property.Color
      mobileIcon: IconStyling
      font: {
        trigger: TextStyling
        dropdownLink: TextStyling
        ctaHigh: Font
        ctaMedium: Font
        welcomeText: TextStyling
      }
    }
    basket: {
      hideText: boolean
      width: string
      icon: IconStyling
      mobileIcon: IconStyling
      iconColor: Property.Color
      closeIconColor: Property.Color
      hoverIconColor: Property.Color
      focusBorderColor?: Property.Color
      mobileIconColor: Property.Color
      quantityStyling: {
        height: string
        width: string
        mobileHeight: string
        mobileWidth: string
        position: {
          top: string
          right: string
          left: string
          bottom: string
        }
        mobilePosition: {
          top: string
          right: string
          left: string
          bottom: string
        }
        text: TextStyling
        mobileText: TextStyling
        backgroundColor: Property.Color
        hoverText: TextStyling
        hoverBackgroundColor: Property.Color
      }
      font: {
        trigger: TextStyling
        bodyText: TextStyling
        alternateText: TextStyling
        cta: Font
      }
    }
    headerButton?: {
      backgroundHoverColor: string
      iconHoverColor?: string
    }
  }
  navigation: {
    mobileSearchWrapper: {
      MaxWidth?: string
      margin?: string
    }
    alignItems: Alignment
    itemHorizontalSpacing: number
    backgroundColor: {
      active: Property.Color
      inactive: Property.Color
    }
    bottomSeparator: Separator
    borderTop: string
    borderBottom: string
    navigationTextItem?: {
      borderBottom: string
      left: string
      width: string
    }
    font: {
      active: TextStyling
      inactive: TextStyling
    }
    dropdownHeaderHover: {
      hoverLinkFont: TextStyling
      hoverBackgroundColor: Property.Color
    }
    link: {
      border: {
        top: {
          width: string
          style: string
          color: Property.Color
        }
      }
      active: {
        border: {
          top: {
            width: string
            style: string
            color: Property.Color
          }
        }
        backgroundColor?: Property.Color
        outline?: string
      }
      focus: {
        border: {
          top: {
            width: string
            style: string
            color: Property.Color
          }
        }
        backgroundColor?: Property.Color
        outline?: string
        outlineColor?: Property.Color
      }
      wrapper: {
        margin: Margin
      }
      subNavigation?: {
        padding: Padding
      }
    }
    linkHover: {
      hoverLinkFont: TextStyling
      hoverBackgroundColor: Property.Color
    }
    breakLine: boolean
    verticalSeparator: Separator
    tabPanelVerticalMargin: string
    height: string
    itemWidth: {
      max: string
      min: string
    }
    linkFont: TextStyling
    dropdownHeaderFont: TextStyling
    mobileLinkFont: TextStyling
    mobileLinkPadding?: number
    mobileLinkFontThirdTier: TextStyling
    mobileNavThirdTierHeader?: {
      backgroundColor: Property.Color
      borderColor: Property.Color
    }
    showNavigationLabel?: boolean
    showFirstLevelHome?: boolean
    showSecondLevelHome?: boolean
    mobileLinkSubheading: {
      backgroundColor: Property.Color
      textTransform?: string
    }
    flyout: {
      headerLabel: TextStyling
    }
    homeIconColor: Property.Color
    closeIconColor: Property.Color
    chevronIconColor: Property.Color
    burgerIconColor: Property.Color
    mobileLinkHover: TextStyling
    mobileLinkBackgroundHover: Property.Color
    waterfall: {
      link: TextStyling
      hoverLink: TextStyling
      hoverBackgroundColor: Property.Color
    }
    navigationListPadding?: number
    subMenu: {
      display: string
      flexDirection: string
      flexWrap: string
      gridAutoFlow?: string
      gridNumberOfRows?: number
      submenuLinksSpacing: string
      columnMargin: Margin
      promotion?: {
        textColor: Property.Color
      }
      border?: {
        show: boolean
      }
      column?: {
        height: string
        list: {
          height: string
        }
        count?: string
      }
      heading?: {
        margin: Margin
        padding: Padding
      }
      padding: Padding
    }
    homeLevel?: {
      margin?: Margin
    }
    threeTierNav: {
      secondTier: {
        link: TextStyling
        height: string
      }
      backgroundColor: Property.Color
    }
    imageThumbnail: {
      secondTier: {
        height: string
        width: string
        font: TextStyling
        imagePadding: Padding
      }
      thirdTier: {
        height: string
        width: string
        font: TextStyling
        imagePadding: Padding
      }
      hover: {
        font: TextStyling
        backgroundColor: Property.Color
      }
      listItemMargin: Margin
      radius: string
      alignment: ImageThumbnailAlignment
      backgroundColor: Property.Color
      imageMargin: Margin
      textPadding: Padding
      flexDirection: FlexDirection
      hideHomeText?: boolean
      lg: {
        height: string
        width: string
        imagePadding: Padding
        imageMargin: Margin
        textPadding: Padding
      }
    }
    imageCard: {
      image: {
        aspectRatio: BreakpointArray<string>
        objectFit: string
        thirdTier?: {
          height?: string
        }
        height: string
      }
      text: {
        font: TextStyling
        padding: Padding
        background: Property.Color
        position: string
        bottom: BreakpointArray<string>
        maxWidth: BreakpointArray<string>
      }
    }
    promotionItem: {
      border: Property.Color
      title?: Property.Color
      textColourOne?: Property.Color
      textColourTwo?: Property.Color
      backgroundColour?: Property.Color
      backgroundColourTwo?: Property.Color
      textStyleOne?: TextStyle
    }
    styleHeader: {
      margin: Margin
    }
    textNavPadding: {
      padding: Padding
    }
    textNavMarginLinkWrapper: {
      margin: Margin
    }
    paddingSubNavLink: {
      padding: Padding
    }
    textNavPaddingNavContentGrid: {
      padding: Padding
    }
    textNavRemovingMargin?: boolean
    textNavRemovingPadding?: boolean
  }
  subNavigation: {
    backgroundColor: Property.Color
    bottomSeparator: Separator
    hoverColor: Property.Color
    color: Property.Color
    dropdowns: {
      triggerHeight: string
      account: {
        icon: {
          height: string
          width: string
        }
      }
    }
    mobile: {
      font: TextStyling
      footerBackgroundColor: Property.Color
      changeTextColor: Property.Color
    }
    desktop: {
      font: TextStyling
      hoverFont: TextStyling
      focus?: {
        borderColor: Property.Color
      }
    }
  }
  headerLogo: {
    mobile: {
      width: string
      height: string
      headerHeight: string
      order: Order
      alignment: Alignment
      useAlternateLogo: boolean
      aspectRatio?: string
      focus?: {
        borderColor: string
      }
    }
    desktop: {
      width: string
      height: string
      // TODO REBUILD-7412: Replace uses of verticalMargin with margin
      verticalMargin: string
      margin?: {
        top: string
        right: string
        bottom: string
        left: string
      }
      order: Order
      alignment: Alignment
      useAlternateLogo: boolean
      container?: {
        flex?: string
        margin?: Margin
      }
      svg?: {
        image?: {
          width?: string
        }
      }
      borderColor?: Property.Color
    }
  }
  search: {
    width: string
    alignment: Alignment
    order: {
      mobile: Order
      desktop: Order
    }
    height: {
      desktop: string
      mobile: string
    }
    margin?: {
      top: string
      bottom: string
      left: string
    }
    showExposedSearchBarOnMobile: boolean
    icon: IconStyling
    iconColor: { active: Property.Color; inactive: Property.Color }
    clearTextIcon: Property.Color
    border: { active: BorderBox; inactive: BorderBox; mobile: Border }
    searchBorders: { active: BorderBox; inactive: BorderBox; mobile: Border }
    buttonBackgroundColor: { active: Property.Color; inactive: Property.Color }
    buttonBorder: { active: BorderBox; inactive: BorderBox; mobile: Border }
    backgroundColor: { active: Property.Color; inactive: Property.Color }
    placeholderFont: TextStyling
    placeHolderPadding: string
    dropdownHeaderFont: TextStyling
    linkFont: TextStyling
    activeFont: TextStyling
    mobileIcon: IconStyling
    mobileCloseIconColor: Property.Color
    focusBorderColor?: Property.BorderColor
    flex: string
    promotionBackgroundColor: Property.BorderColor
  }
  mobileSearch: boolean
  link: {
    margin: {
      bottom: string
    }
  }
  account: {
    margin: {
      bottom: string
    }
  }
  basket: {
    margin: {
      bottom: string
    }
  }
  wishlist: {
    icon: IconStyling
    margin: {
      bottom: string
    }
  }
}

const textStyling: TextStyling = {
  entry: 'bodyText',
  style: 'default',
  transform: 'none',
  textDecoration: 'none',
  textColor: '#333333',
}
const ctaTextStyling: Font = {
  entry: 'bodyText',
  style: 'default',
  transform: 'none',
  textDecoration: 'none',
}

const alternateTextStyling: TextStyling = {
  entry: 'bodyText',
  style: 'alternate',
  transform: 'none',
  textDecoration: 'none',
  textColor: '#333333',
}

const thirdTierTextStyling: TextStyling = {
  entry: 'bodyText',
  style: 'alternate',
  transform: 'none',
  textDecoration: 'none',
  textColor: '#333333',
}

const hoverTextStyling: TextStyling = {
  entry: 'bodyText',
  style: 'default',
  transform: 'none',
  textDecoration: 'none',
  textColor: '#333333',
}

const borderStyling: Border = {
  display: true,
  color: '#8C8C8C',
  width: '1px',
}

const borders: BorderBox = {
  radius: '0px',
  top: borderStyling,
  right: borderStyling,
  bottom: borderStyling,
  left: borderStyling,
}

const alternateBorderStyling: Border = {
  display: true,
  color: '#8C8C8C',
  width: '0px',
}

const alternateBorders: BorderBox = {
  radius: '0px',
  top: alternateBorderStyling,
  right: alternateBorderStyling,
  bottom: alternateBorderStyling,
  left: alternateBorderStyling,
}

const mobileSlots: SlotConfiguration[] = [
  {
    component: HeaderComponent.FLYOUT,
    colSpan: '48px',
    alignment: Alignment.center,
  },
  {
    component: HeaderComponent.SEARCH,
    colSpan: '48px',
    alignment: Alignment.center,
  },
  {
    component: HeaderComponent.LOGO,
    colSpan: 'auto',
    alignment: Alignment.center,
  },
  {
    component: HeaderComponent.ACCOUNT,
    colSpan: '48px',
    alignment: Alignment.center,
  },
  {
    component: HeaderComponent.BASKET,
    colSpan: '48px',
    alignment: Alignment.center,
  },
]
const desktopSlots: SlotConfiguration[] = [
  {
    component: HeaderComponent.LOGO,
    colSpan: 'auto',
    alignment: Alignment.flexStart,
  },
  {
    component: HeaderComponent.SEARCH,
    colSpan: 'auto',
    alignment: Alignment.center,
  },
  {
    component: HeaderComponent.ACCOUNT,
    colSpan: 'auto',
    alignment: Alignment.center,
  },
  {
    component: HeaderComponent.BASKET,
    colSpan: 'auto',
    alignment: Alignment.center,
  },
]

export const theme: HeaderThemeInterface = {
  mobileBackgroundColor: '#FFFFFF',
  backgroundColor: '#FFFFFF',
  sticky: {
    top: '-32px',
  },
  alternateGridAreas: {
    xs: {
      area: `"\"flyout search logo account basket\""`,
      colSpan: '48px 48px auto 48px 48px',
    },
    sm: {
      area: `"\"flyout search logo account basket\""`,
      colSpan: '48px 48px auto 48px 48px',
    },
    md: {
      area: `"\"flyout search logo account basket\""`,
      colSpan: '48px 48px auto 48px 48px',
    },
    lg: {
      area: `"\"submenu submenu submenu submenu\" \"logo search account basket\" \"navigation navigation navigation navigation\""`,
      colSpan: 'auto auto auto auto',
    },
  },
  slotsGridAreas: {
    xs: {
      area: `"\"flyout search logo account basket\""`,
      colSpan: '48px 48px auto 48px 48px',
    },
    sm: {
      area: `"\"flyout search logo account basket\""`,
      colSpan: '48px 48px auto 48px 48px',
    },
    md: {
      area: `"\"flyout search logo account basket\""`,
      colSpan: '48px 48px auto 48px 48px',
    },
    lg: {
      area: `"\"submenu submenu submenu submenu\" \"logo search account basket\" \"navigation navigation navigation navigation\""`,
      colSpan: 'auto auto auto auto',
    },
  },
  flyout: {
    flyoutHeaderContentPadding: 0,
    width: '300px',
    direction: {
      lg: FlyoutDirection.LEFT,
      md: FlyoutDirection.LEFT,
      sm: FlyoutDirection.LEFT,
      xs: FlyoutDirection.LEFT,
    },
    icon: {
      svgPath: '',
      height: '24px',
      width: '24px',
      color: '#333333',
      viewBox: '0 0 24 24',
    },
    homeIcon: {
      svgPath: '',
      height: '24px',
      width: '24px',
      color: '#333333',
      viewBox: '0 0 24 24',
    },
    closeIcon: {
      svgPath: '',
      height: '24px',
      width: '24px',
      color: '#333333',
      viewBox: '0 0 24 24',
    },
    chevronRightIcon: {
      svgPath: '',
      height: '20px',
      width: '20px',
      color: '#333333',
      viewBox: '0 0 24 24',
    },
    chevronLeftIcon: {
      svgPath: '',
      height: '20px',
      width: '20px',
      color: '#333333',
      viewBox: '0 0 24 24',
    },
    storeIcon: {
      svgPath: '',
      height: '24px',
      width: '24px',
      color: '#333333',
      viewBox: '0 0 24 24',
    },
  },
  enabledComponents: {
    xs: [
      HeaderComponent.FLYOUT,
      HeaderComponent.SEARCH,
      HeaderComponent.LOGO,
      HeaderComponent.ACCOUNT,
      HeaderComponent.BASKET,
    ],
    sm: [
      HeaderComponent.FLYOUT,
      HeaderComponent.SEARCH,
      HeaderComponent.LOGO,
      HeaderComponent.ACCOUNT,
      HeaderComponent.BASKET,
    ],
    md: [
      HeaderComponent.FLYOUT,
      HeaderComponent.SEARCH,
      HeaderComponent.LOGO,
      HeaderComponent.ACCOUNT,
      HeaderComponent.BASKET,
    ],
    lg: [
      HeaderComponent.SUBMENU,
      HeaderComponent.LOGO,
      HeaderComponent.SEARCH,
      HeaderComponent.ACCOUNT,
      HeaderComponent.BASKET,
      HeaderComponent.NAVIGATION,
    ],
  },
  alternateEnabledComponents: {
    xs: [
      HeaderComponent.FLYOUT,
      HeaderComponent.SEARCH,
      HeaderComponent.LOGO,
      HeaderComponent.ACCOUNT,
      HeaderComponent.BASKET,
    ],
    sm: [
      HeaderComponent.FLYOUT,
      HeaderComponent.SEARCH,
      HeaderComponent.LOGO,
      HeaderComponent.ACCOUNT,
      HeaderComponent.BASKET,
    ],
    md: [
      HeaderComponent.FLYOUT,
      HeaderComponent.SEARCH,
      HeaderComponent.LOGO,
      HeaderComponent.ACCOUNT,
      HeaderComponent.BASKET,
    ],
    lg: [
      HeaderComponent.SUBMENU,
      HeaderComponent.LOGO,
      HeaderComponent.SEARCH,
      HeaderComponent.ACCOUNT,
      HeaderComponent.BASKET,
      HeaderComponent.NAVIGATION,
    ],
  },
  accountDropdownDirection: {
    xs: DropdownDirection.LEFT,
    sm: DropdownDirection.LEFT,
    md: DropdownDirection.LEFT,
    lg: DropdownDirection.LEFT,
  },
  slots: [
    {
      xs: mobileSlots,
      sm: mobileSlots,
      md: mobileSlots,
      lg: [
        {
          component: HeaderComponent.SUBMENU,
          colSpan: 'auto',
          alignment: Alignment.flexEnd,
        },
      ],
    },
    {
      xs: [],
      sm: [],
      md: [],
      lg: desktopSlots,
    },
    {
      xs: [],
      sm: [],
      md: [],
      lg: [
        {
          component: HeaderComponent.NAVIGATION,
          colSpan: 'auto',
          alignment: Alignment.flexStart,
        },
      ],
    },
  ],
  alignItems: Alignment.center,
  headerSlots: {
    iconColor: { active: '#333333', inactive: '#333333' },
    textStyling: {
      active: textStyling,
      inactive: textStyling,
    },
    orientation: Orientation.HORIZONTAL,
  },
  slotItems: {
    submenu: [
      {
        visibility: {
          xs: Visibility.SHOW,
          sm: Visibility.SHOW,
          md: Visibility.SHOW,
          lg: Visibility.SHOW,
        },
      },
      {
        visibility: {
          xs: Visibility.SHOW,
          sm: Visibility.SHOW,
          md: Visibility.SHOW,
          lg: Visibility.SHOW,
        },
      },
      {
        visibility: {
          xs: Visibility.SHOW,
          sm: Visibility.SHOW,
          md: Visibility.SHOW,
          lg: Visibility.SHOW,
        },
      },
      {
        visibility: {
          xs: Visibility.SHOW,
          sm: Visibility.SHOW,
          md: Visibility.SHOW,
          lg: Visibility.SHOW,
        },
      },
      {
        visibility: {
          xs: Visibility.SHOW,
          sm: Visibility.SHOW,
          md: Visibility.SHOW,
          lg: Visibility.SHOW,
        },
      },
    ],
    header: [
      {
        visibility: {
          xs: Visibility.SHOW,
          sm: Visibility.SHOW,
          md: Visibility.SHOW,
          lg: Visibility.SHOW,
        },
      },
      {
        visibility: {
          xs: Visibility.SHOW,
          sm: Visibility.SHOW,
          md: Visibility.SHOW,
          lg: Visibility.SHOW,
        },
      },
      {
        visibility: {
          xs: Visibility.SHOW,
          sm: Visibility.SHOW,
          md: Visibility.SHOW,
          lg: Visibility.SHOW,
        },
      },
      {
        visibility: {
          xs: Visibility.SHOW,
          sm: Visibility.SHOW,
          md: Visibility.SHOW,
          lg: Visibility.SHOW,
        },
      },
    ],
    flyout: [
      {
        visibility: {
          xs: Visibility.SHOW,
          sm: Visibility.SHOW,
          md: Visibility.SHOW,
          lg: Visibility.SHOW,
        },
      },
      {
        visibility: {
          xs: Visibility.SHOW,
          sm: Visibility.SHOW,
          md: Visibility.SHOW,
          lg: Visibility.SHOW,
        },
      },
      {
        visibility: {
          xs: Visibility.HIDE,
          sm: Visibility.HIDE,
          md: Visibility.HIDE,
          lg: Visibility.HIDE,
        },
      },
      {
        visibility: {
          xs: Visibility.HIDE,
          sm: Visibility.HIDE,
          md: Visibility.HIDE,
          lg: Visibility.HIDE,
        },
      },
    ],
  },
  bottomSeparator: {
    color: '#333333',
    width: '0px',
  },
  search: {
    width: '458px',
    alignment: Alignment.center,
    order: {
      mobile: Order.two,
      desktop: Order.two,
    },
    height: {
      desktop: '40px',
      mobile: '40px',
    },
    icon: {
      svgPath: '',
      height: '24px',
      width: '24px',
      color: '#333333',
      viewBox: '0 0 24 24',
    },
    showExposedSearchBarOnMobile: false,
    placeHolderPadding: '8px',
    placeholderFont: textStyling,
    dropdownHeaderFont: textStyling,
    linkFont: textStyling,
    activeFont: textStyling,
    iconColor: { active: '#FFFFFF', inactive: '#333333' },
    border: { active: borders, inactive: borders, mobile: borderStyling },
    searchBorders: {
      active: borders,
      inactive: borders,
      mobile: borderStyling,
    },
    buttonBackgroundColor: { active: '#8C8C8C', inactive: '#FFFFFF' },
    buttonBorder: {
      active: alternateBorders,
      inactive: alternateBorders,
      mobile: borderStyling,
    },
    backgroundColor: { active: '#FFFFFF', inactive: '#FFFFFF' },
    promotionBackgroundColor: '#f2f2f2',
    clearTextIcon: '#333333',
    mobileIcon: {
      svgPath: '',
      height: '24px',
      width: '24px',
      color: '#333333',
      viewBox: '0 0 24 24',
    },
    mobileCloseIconColor: '#333333',
    flex: '30%',
  },
  dropdowns: {
    flex: {
      width: '20%',
    },
    container: {
      padding: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
      },
    },
    order: {
      mobile: Order.four,
      desktop: Order.three,
    },
    triggerHeight: '48px',
    account: {
      hideText: false,
      width: '275px',
      useAlternativeIconSVG: '',
      icon: {
        svgPath: '',
        width: '24px',
        height: '24px',
        color: '#333333',
        viewBox: '0 0 24 24',
      },
      iconColor: '#333333',
      mobileIconColor: '#333333',
      mobileIcon: {
        svgPath: '',
        width: '24px',
        height: '24px',
        color: '#333333',
        viewBox: '0 0 24 24',
      },
      hoverIconColor: '#333333',
      closeIconColor: '#333333',
      font: {
        trigger: textStyling,
        dropdownLink: textStyling,
        ctaHigh: ctaTextStyling,
        ctaMedium: ctaTextStyling,
        welcomeText: textStyling,
      },
    },
    basket: {
      hideText: false,
      width: '320px',
      icon: {
        svgPath: '',
        width: '24px',
        height: '24px',
        color: '#333333',
        viewBox: '0 0 24 24',
      },
      mobileIcon: {
        svgPath: '',
        width: '24px',
        height: '24px',
        color: '#333333',
        viewBox: '0 0 24 24',
      },
      iconColor: '#333333',
      mobileIconColor: '#333333',
      hoverIconColor: '#333333',
      closeIconColor: '#333333',
      quantityStyling: {
        height: '16px',
        width: '16px',
        mobileHeight: '16px',
        mobileWidth: '16px',
        position: {
          top: '-5px',
          right: '-5px',
          left: '',
          bottom: '0',
        },
        mobilePosition: {
          top: '-5px',
          right: '-5px',
          left: '',
          bottom: '0',
        },
        text: {
          entry: 'xsmall',
          style: 'default',
          transform: 'none',
          textDecoration: 'none',
          textColor: '#FFFFFF',
        },
        mobileText: {
          entry: 'xsmall',
          style: 'default',
          transform: 'none',
          textDecoration: 'none',
          textColor: '#FFFFFF',
        },
        backgroundColor: '#333333',
        hoverText: {
          entry: 'xsmall',
          style: 'default',
          transform: 'none',
          textDecoration: 'none',
          textColor: '#FFFFFF',
        },
        hoverBackgroundColor: '#333333',
      },
      font: {
        trigger: textStyling,
        bodyText: textStyling,
        alternateText: alternateTextStyling,
        cta: ctaTextStyling,
      },
    },
    headerButton: {
      backgroundHoverColor: '#ffffff',
      iconHoverColor: '#333333',
    },
  },
  navigation: {
    mobileSearchWrapper: {
      MaxWidth: '400px',
      margin: '8px auto',
    },
    alignItems: Alignment.flexStart,
    itemHorizontalSpacing: 2,
    backgroundColor: { active: '#F2F2F2', inactive: '#FFFFFF' },
    borderTop: '',
    borderBottom: '',
    bottomSeparator: {
      color: '#333333',
      width: '0px',
    },
    font: {
      active: textStyling,
      inactive: textStyling,
    },
    dropdownHeaderHover: {
      hoverLinkFont: textStyling,
      hoverBackgroundColor: '#FFFFFF',
    },
    link: {
      border: {
        top: {
          width: '0px',
          style: 'solid',
          color: 'transparent',
        },
      },
      active: {
        border: {
          top: {
            width: '0px',
            style: 'solid',
            color: 'transparent',
          },
        },
      },
      focus: {
        border: {
          top: {
            width: '0px',
            style: 'solid',
            color: 'transparent',
          },
        },
      },
      wrapper: {
        margin: { top: 0, right: -1, bottom: 0, left: -1 },
      },
    },
    linkHover: {
      hoverLinkFont: textStyling,
      hoverBackgroundColor: '#FFFFFF',
    },
    breakLine: false,
    verticalSeparator: { color: '#FFFFFF', width: '0px' },
    tabPanelVerticalMargin: '0px',
    height: '40px',
    itemWidth: {
      max: 'auto',
      min: 'auto',
    },
    showFirstLevelHome: true,
    showSecondLevelHome: true,
    linkFont: textStyling,
    dropdownHeaderFont: alternateTextStyling,
    mobileLinkFont: alternateTextStyling,
    mobileLinkFontThirdTier: thirdTierTextStyling,
    mobileLinkPadding: 1.75,
    mobileLinkSubheading: {
      backgroundColor: '#F2F2F2',
      textTransform: 'none',
    },
    flyout: {
      headerLabel: alternateTextStyling,
    },
    mobileLinkBackgroundHover: '#F2F2F2',
    mobileLinkHover: alternateTextStyling,
    homeIconColor: '#333333',
    closeIconColor: '#333333',
    chevronIconColor: '#333333',
    burgerIconColor: '#333333',
    waterfall: {
      link: {
        entry: 'small',
        style: 'default',
        transform: 'none',
        textDecoration: 'none',
        textColor: '#333333',
      },
      hoverLink: {
        entry: 'small',
        style: 'default',
        transform: 'none',
        textDecoration: 'underline',
        textColor: '#333333',
      },
      hoverBackgroundColor: '#FFFFFF',
    },
    threeTierNav: {
      secondTier: {
        link: textStyling,
        height: '48px',
      },
      backgroundColor: '#F2F2F2',
    },
    imageThumbnail: {
      radius: '50%',
      alignment: ImageThumbnailAlignment.LEFT,
      backgroundColor: '#F2F2F2',
      secondTier: {
        height: '44px',
        width: '44px',
        font: alternateTextStyling,
        imagePadding: {
          top: 0,
          left: 0,
          right: '16px',
          bottom: 0,
        },
      },
      thirdTier: {
        height: '44px',
        width: '44px',
        font: textStyling,
        imagePadding: {
          top: 0,
          left: 0,
          right: '16px',
          bottom: 0,
        },
      },

      hover: {
        font: alternateTextStyling,
        backgroundColor: '#FFFFFF',
      },
      listItemMargin: {
        top: '8px',
        left: 0,
        right: 0,
        bottom: 0,
      },
      imageMargin: {
        top: '8px',
        left: 0,
        right: 0,
        bottom: 0,
      },
      textPadding: {
        top: '8px',
        left: 0,
        right: 0,
        bottom: 0,
      },
      flexDirection: FlexDirection.ROW,
      lg: {
        height: '56px',
        width: '56px',
        imagePadding: {
          top: 0,
          left: 0,
          right: '16px',
          bottom: 0,
        },
        imageMargin: {
          top: '8px',
          left: 0,
          right: 0,
          bottom: 0,
        },
        textPadding: {
          top: '8px',
          left: 0,
          right: 0,
          bottom: 0,
        },
      },
    },
    imageCard: {
      image: {
        aspectRatio: ['16/9'],
        objectFit: 'fill',
        height: '100px',
      },
      text: {
        font: {
          style: 'alternate',
          entry: 'bodyText',
          textColor: '#383838',
          textDecoration: 'none',
          transform: 'none',
        },
        background: '#FFFFFF',
        position: 'relative',
        bottom: ['auto', 'auto', 'auto', '0px'],
        padding: {
          top: '14px',
          left: '14px',
          right: '14px',
          bottom: '14px',
        },
        maxWidth: ['100%'],
      },
    },
    subMenu: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      submenuLinksSpacing: `${spacing(0.75)}`,
      columnMargin: {
        top: '8px',
        left: '16px',
        right: '16px',
        bottom: '16px',
      },
      promotion: {
        textColor: '#DA291C',
      },
      padding: {
        top: 2,
        right: 2,
        bottom: 2,
        left: 2,
      },
      heading: {
        margin: {
          top: 1,
          left: -1,
          right: -1,
          bottom: 1,
        },
        padding: {
          top: 1,
          right: 0.75,
          bottom: 1,
          left: 0.75,
        },
      },
    },
    promotionItem: {
      border: '#DA291C',
    },
    styleHeader: {
      margin: {
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
      },
    },
    textNavPadding: {
      padding: {
        top: '0',
        right: '0',
        bottom: '16px',
        left: '0',
      },
    },
    textNavMarginLinkWrapper: {
      margin: {
        top: '0',
        left: '0',
        right: '0',
        bottom: '8px',
      },
    },
    paddingSubNavLink: {
      padding: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
      },
    },
    textNavPaddingNavContentGrid: {
      padding: {
        top: '16px',
        right: '16px',
        bottom: '24px',
        left: '16px',
      },
    },
    textNavRemovingMargin: false,
    textNavRemovingPadding: false,
  },
  subNavigation: {
    backgroundColor: '#F2F2F2',
    bottomSeparator: {
      color: '#333333',
      width: '0px',
    },
    hoverColor: '#CCCCCC',
    color: '#333333',
    dropdowns: {
      triggerHeight: '34px',
      account: {
        icon: {
          height: '24px',
          width: '24px',
        },
      },
    },
    mobile: {
      font: textStyling,
      footerBackgroundColor: '#F2F2F2',
      changeTextColor: '#333333',
    },
    desktop: {
      font: textStyling,
      hoverFont: hoverTextStyling,
    },
  },
  headerLogo: {
    mobile: {
      width: '100%',
      height: '15.43px',
      headerHeight: '48px',
      order: Order.three,
      alignment: Alignment.flexStart,
      useAlternateLogo: false,
    },
    desktop: {
      width: '100%',
      height: '18px',
      verticalMargin: '24px',
      order: Order.one,
      alignment: Alignment.flexStart,
      useAlternateLogo: false,
    },
  },
  mobileSearch: false,
  link: { margin: { bottom: '0' } },
  account: { margin: { bottom: '0' } },
  basket: { margin: { bottom: '0' } },
  wishlist: {
    icon: {
      svgPath: '',
      width: '24px',
      height: '24px',
      color: '#333333',
      viewBox: '0 0 24 24',
    },
    margin: { bottom: '0' },
  },
}
