import { Grid, GridItem } from '@thg-commerce/gravity-system'
import {
  BreakpointArray,
  BreakpointKey,
  css,
  dangerous_mqMax,
  margin,
  mediaQueryRenderer,
  mq,
  padding,
  spacing,
  styled,
} from '@thg-commerce/gravity-theme'
import { HorizontalAlignment } from '@thg-commerce/gravity-theme/alignments'
import { Margin } from '@thg-commerce/gravity-theme/margin'
import { Padding } from '@thg-commerce/gravity-theme/padding'

import { CarouselButtonPlacement } from './types'

// Control wrapper width (96) + button right offset (32) + padding (16). Used for right-aligned controls placement
export const CONTROLS_OFFSET = 144

const SLIDE_PREVIEW_SPACING = 14

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: inherit;
  margin: 0 auto;
  touch-action: pan-y;
`

export const ControlsWrapper = styled.div<{
  hideControls?: boolean[] | boolean
  controlPlacement?: CarouselButtonPlacement
}>`
  position: absolute;
  z-index: 1;

  ${(props) =>
    Array.isArray(props.hideControls)
      ? props.hideControls
          .map(
            (hide, index) => `${mq(
              props.theme.breakpointUtils.map,
              props.theme.breakpointUtils.keys[index] as BreakpointKey,
            )} {
              display: ${hide ? 'none' : 'flex'};
            }`,
          )
          .join('')
      : props.hideControls && `display: none;`}

  ${(props) =>
    props.controlPlacement === CarouselButtonPlacement.BottomRightFloat &&
    css`
      right: 50%;
      bottom: 2px;
      display: flex;
      transform: translateX(-2px);
    `}
`

export const HiddenControls = styled.div`
  visibility: hidden;
  margin-bottom: ${spacing(6)};
  width: 100%;
`

const DEFAULT_CONTROL_SIZE = 48 // px

export const Control = styled.button<{
  visible: BreakpointArray<boolean>
  disabled: boolean
  position?: 'left' | 'right'
  buttonPlacement?: CarouselButtonPlacement
  size?: number
  alternativeDisabledStyling?: boolean
  enableWhiteControls?: boolean
}>`
  width: ${(props) => props.size || DEFAULT_CONTROL_SIZE}px;
  height: ${(props) => props.size || DEFAULT_CONTROL_SIZE}px;

  ${(props) =>
    mediaQueryRenderer(
      props.visible,
      (visible) => `
        display: ${visible ? 'inline-block' : 'none'}
    `,
    )}

  ${(props) =>
    props.buttonPlacement === CarouselButtonPlacement.Split &&
    props.position &&
    `
      top: 50%;
      margin-top: -${(props.size || DEFAULT_CONTROL_SIZE) / 2}px;
      z-index: 1;
      position: absolute;

      ${
        props.position === 'right'
          ? `right: ${
              (DEFAULT_CONTROL_SIZE - (props.size || DEFAULT_CONTROL_SIZE)) / 2
            }px;`
          : `left: ${
              (DEFAULT_CONTROL_SIZE - (props.size || DEFAULT_CONTROL_SIZE)) / 2
            }px;`
      }
      `}

  background-color: ${(props) =>
    props.disabled &&
    !props.alternativeDisabledStyling &&
    !props.enableWhiteControls
      ? props.theme.colors.palette.greys.light
      : props.theme.colors.palette.greys.white};
  border: 1px solid
    ${(props) =>
      props.disabled
        ? props.enableWhiteControls
          ? props.theme.colors.palette.greys.lighter
          : props.theme.colors.palette.greys.light
        : props.theme.colors.palette.brand.base};
  fill: ${(props) =>
    props.disabled
      ? props.alternativeDisabledStyling
        ? props.theme.colors.palette.greys.light
        : props.enableWhiteControls
        ? props.theme.colors.palette.greys.grey
        : props.theme.colors.palette.greys.white
      : props.theme.colors.palette.greys.darker};

  &:focus {
    outline: none;
    border: ${(props) =>
      props.position === 'left'
        ? `2px 1px 2px 2px solid ${(props) =>
            props.theme.colors.palette.brand.base}`
        : props.position === 'right'
        ? `2px 2px 2px 1px solid ${(props) =>
            props.theme.colors.palette.brand.base}`
        : `2px solid ${(props) => props.theme.colors.palette.brand.base}`};
  }
`

export const SplitControlWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  transition: opacity 0.25s ease-in-out 0s;
  justify-content: space-between;
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 0.25s ease-in-out 0s;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    opacity: 0;
  }

  &:hover {
    opacity: 1;
  }

  &:focus-within {
    opacity: 1;
  }
`

export const OverflowWrapper = styled.div<{
  overflow?: BreakpointArray<string>
}>`
  height: 100%;
  ${(props) =>
    props.overflow
      ? mediaQueryRenderer(props.overflow, (overflow) => `width: ${overflow}`)
      : ''}
`

export const CarouselWrapper = styled.div<{
  translateXCurrentSlide: number
  translateXUserSlide: number
  itemGapSpacing?: number
  itemsPerSlide: number | number[]
  itemCount: number
  currentSlide: number
  enableSlidePreview?: boolean
  overflow?: BreakpointArray<string>
}>`
  height: 100%;
  transition: ${(props) =>
    Math.abs(props.translateXUserSlide) > 0 ? 'none' : '0.3s ease'};

  ${(props) => {
    const currentSlideOffset = props.translateXCurrentSlide

    const slidePreviewOffset = `(${spacing(
      props.enableSlidePreview ? SLIDE_PREVIEW_SPACING : 0,
    )} * ${props.currentSlide})`

    return mediaQueryRenderer(
      (typeof props.itemsPerSlide === 'object'
        ? props.itemsPerSlide
        : [
            props.itemsPerSlide,
            props.itemsPerSlide,
            props.itemsPerSlide,
            props.itemsPerSlide,
          ]) as BreakpointArray<number>,
      (itemsPerSlide, _, index) => {
        const unitOfTranslation = 100 / itemsPerSlide
        const lastSlideItemCount = props.itemCount % itemsPerSlide
        const lastSlideTranslationPercentage =
          unitOfTranslation * lastSlideItemCount

        const slideGapOffset = `(${spacing(
          (props.itemGapSpacing || 0) * 2,
        )} * ${
          (props.overflow?.[index] && props.overflow?.[index] !== '100%') ||
          itemsPerSlide > 1
            ? itemsPerSlide
            : 0
        } * ${props.currentSlide})`

        return `transform: translateX(
          calc(
            ${
              props.currentSlide <
                Math.floor(props.itemCount / itemsPerSlide) ||
              props.itemCount < itemsPerSlide
                ? currentSlideOffset
                : currentSlideOffset - lastSlideTranslationPercentage + 100
            }% + 
            ${slidePreviewOffset} -
            ${slideGapOffset}
          )
        ) translateX(${props.translateXUserSlide}px)`
      },
    )
  }}
`

export const CarouselItemContainer = styled.ul<{
  itemsPerSlide: number | number[]
  itemCount: number
  itemGapSpacing?: number
  overflow?: BreakpointArray<string>
}>`
  height: 100%;

  ${(props) =>
    mediaQueryRenderer(
      (typeof props.itemsPerSlide === 'object'
        ? props.itemsPerSlide
        : [
            props.itemsPerSlide,
            props.itemsPerSlide,
            props.itemsPerSlide,
            props.itemsPerSlide,
          ]) as BreakpointArray<number>,
      (itemsPerSlide, _, index) => {
        return `
    width: calc(${
      100 * Math.ceil(props.itemCount / itemsPerSlide)
    }% + (${spacing(props.itemGapSpacing || 0)} * ${props.itemCount + 2} * ${
          (props.overflow?.[index] && props.overflow?.[index] !== '100%') ||
          itemsPerSlide > 1
            ? 1
            : 0
        })); 
          ${
            props.itemGapSpacing
              ? `margin: 0 -${spacing(
                  (props.overflow?.[index] &&
                    props.overflow?.[index] !== '100%') ||
                    itemsPerSlide > 1
                    ? props.itemGapSpacing || 0
                    : 0,
                )};`
              : ''
          }`
      },
    )}
`

interface CarouselItemProps {
  active: boolean
  hideSlidePreview?: boolean
  isZoomModal?: boolean
  zoomClickable?: boolean
  controlsHidden?: boolean
  inactiveSlideFullOpacity?: boolean
  itemsPerSlide: number | number[]
  itemCount: number
  overflow?: BreakpointArray<string>
  previewWidthSpacing?: number
  enableSlidePreview?: boolean
  spacing?: number
  fullWidthCarousel?: boolean
}

export const CarouselItem = styled.li<CarouselItemProps>`
  position: relative;
  margin-top: 0;
  display: ${(props) => (props.isZoomModal ? 'flex' : 'inline-block')};
  list-style: none;
  padding: ${(props) =>
    props.itemCount > 1 ? `${spacing(0.25)} ${spacing(2)} ` : '0'};
  border: ${(props) =>
    props.fullWidthCarousel ? 'none' : '1px solid transparent'};
  opacity: ${(props) =>
    props.active || props.inactiveSlideFullOpacity ? 1 : 0.25};
  pointer-events: ${(props) =>
    props.active || props.inactiveSlideFullOpacity ? `auto` : `none`};
  flex-shrink: 0;

  ${(props) => props.isZoomModal && 'justify-content: center;'}
  cursor: ${(props) => props.zoomClickable && 'zoom-in'};

  ${(props) =>
    ((props.hideSlidePreview && !props.controlsHidden) ||
      props.itemCount < 2) &&
    `
      padding: 0;
      opacity: 1;
    `}

  ${(props) =>
    mediaQueryRenderer(
      (typeof props.itemsPerSlide === 'object'
        ? props.itemsPerSlide
        : [
            props.itemsPerSlide,
            props.itemsPerSlide,
            props.itemsPerSlide,
            props.itemsPerSlide,
          ]) as BreakpointArray<number>,
      (itemsPerSlide, _, index) => `
        width: calc(${
          100 / (itemsPerSlide * Math.ceil(props.itemCount / itemsPerSlide))
        }% - (${spacing(
        props.enableSlidePreview ? SLIDE_PREVIEW_SPACING : 0,
      )} / ${itemsPerSlide}));

        ${
          props.spacing
            ? `padding: 0 ${spacing(
                (props.overflow?.[index] &&
                  props.overflow?.[index] !== '100%') ||
                  itemsPerSlide > 1
                  ? props.spacing || 0
                  : 0,
              )}`
            : ''
        }
      `,
    )}
`

export const PageIndicatorWrapper = styled.div<{
  mobileHorizontalAlignment: HorizontalAlignment
  hide?: boolean
}>`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  height: 100%;
  align-items: center;
  justify-content: ${(props) => props.mobileHorizontalAlignment};
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    justify-content: center;
  }
`

export const PageIndicator = styled.div<{
  active: boolean
  indicatorStyle?: 'letterbox' | 'dot' | 'hidden'
  dotHorizontalMargin?: number
  enableWhiteControls?: boolean
}>`
  background-color: ${(props) =>
    props.active
      ? !props.enableWhiteControls
        ? props.theme.colors.palette.brand.light
        : props.theme.colors.palette.brand.darker
      : props.theme.colors.palette.greys.light};

  ${(props) =>
    props.indicatorStyle === 'letterbox'
      ? `
  width: 24px;
  height: 2px;
  margin: 0px ${spacing(0.5)};
`
      : `
      width: 8px;
      height: 8px;
      margin: 0px ${spacing(props.dotHorizontalMargin ?? 1)};
      border-radius: 50%;
    `};
`

export const PageIndicatorGrid = styled(Grid)<{
  indicatorStyle?: 'letterbox' | 'dot' | 'hidden'
  marginTop?: number
  containerWidth?: number
  margin?: Margin
  indicatorPadding?: Padding
}>`
  ${(props) =>
    props.indicatorStyle !== 'hidden'
      ? margin(
          props.margin ||
            props.theme.patterns.carousel?.pageIndicator.wrapper.margin || {
              top: 3,
              bottom: 0,
              right: 0,
              left: 0,
            },
        )
      : 'margin: 0px'};
  ${(props) =>
    props.indicatorStyle !== 'hidden' &&
    !props.margin &&
    `margin-top: ${spacing(3)};`}
  grid-auto-flow: dense;
  grid-template-columns: repeat(1, [col] 1fr);
  ${(props) =>
    padding(props.indicatorPadding || { top: 0, right: 0, bottom: 0, left: 0 })}

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    grid-template-columns: repeat(3, [col] 1fr);
  }
`

export const PageIndicatorGridItem = styled(GridItem)`
  padding: 0;
  margin: 0;

  ${(props) => dangerous_mqMax(props.theme.breakpointUtils.map, 'md')} {
    grid-column-start: 1;
  }
`

export const BottomControlsWrapper = styled(GridItem)<{
  horizontalAlignment: HorizontalAlignment
}>`
  padding: 0;
  margin: 0;

  ${(props) =>
    props.horizontalAlignment && `text-align: ${props.horizontalAlignment}`};

  ${(props) => dangerous_mqMax(props.theme.breakpointUtils.map, 'sm')} {
    ${(props) => props.horizontalAlignment && `grid-column-start: 2;`};
  }
`

export const ControlsContainer = styled.div<{
  controlPlacement?: CarouselButtonPlacement
  hide: boolean
  removeControls?: boolean
}>`
  ${(props) =>
    props.removeControls
      ? 'display: none;'
      : `visibility: ${props.hide ? 'hidden' : 'visible'};`}
`
