import * as React from 'react'
import loadable from '@loadable/component'
import dynamic from 'next/dynamic'
import { v4 as uuid } from 'uuid'

import { Font } from '@thg-commerce/gravity-patterns'
import { styled, useTheme } from '@thg-commerce/gravity-system/theme'
import {
  padding,
  SpacingBox,
  Text,
  TextEntry,
} from '@thg-commerce/gravity-theme'

import { AccordionThemeInterface } from './theme'

const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

export enum AccordionBorderPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  UNDER = 'under',
  FULL = 'full',
  NONE = 'none',
}

interface AccordionProps {
  title: React.ReactNode
  subtitleElement?: React.ReactNode
  id: string
  headerTextColor?: string
  textEntry?: TextEntry
  textTransform?: string
  footerTitlePadding?: boolean
  isDark?: boolean
  isOpen?: boolean
  /**  Will allow you to change stylings based on focus state, currently forced to brand until artemis implementation is complete */
  focusColour?: 'brand' | 'grey'
  borderPlacement?: AccordionBorderPlacement
  onClick?: (id: string) => void
  onOpenAccordion?: (id: string) => void
  children: React.ReactChildren | React.ReactNode | React.ReactElement | Element
  theme?: AccordionThemeInterface
  focusRef?: React.RefObject<HTMLDivElement>
}

// const TRANSITION_LENGTH_SECONDS = 0.3

export const Title = styled.label<{
  fontStyle?: Font
  padding?: SpacingBox
  color?: string
  borderPlacement?: AccordionBorderPlacement
}>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  ${(props) => props.padding && padding(props.padding)};
  ${(props) =>
    Text(
      props.fontStyle?.entry || 'bodyText',
      props.fontStyle?.style || 'alternate',
    )};
  ${(props) =>
    (props.borderPlacement === AccordionBorderPlacement.BOTTOM ||
      props.borderPlacement === AccordionBorderPlacement.UNDER) &&
    `border-bottom: 1px solid ${props.theme.colors.palette.greys.light};`}

  ${(props) =>
    props.borderPlacement === AccordionBorderPlacement.TOP &&
    `border-top: 1px solid ${props.theme.colors.palette.greys.light};
    `}

  ${(props) => props.color && `color: ${props.color};`}
`

const Content = styled.div<{
  padding: SpacingBox
}>`
  display: none;
  max-height: 0;
  visibility: hidden;
  ${(props) => padding(props.padding)};
`

const ChevronDown = dynamic(() =>
  import('@thg-commerce/gravity-icons/src/components/ChevronDown').then(
    (mod) => mod.SvgChevronDown,
  ),
)

const ChevronContainer = styled.div`
  width: 24px;
  height: 24px;
`

const Input = styled.input<{
  focusPlacement?: AccordionBorderPlacement
}>`
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:focus {
    + ${Title} {
      outline: none;
      border: solid 2px transparent;
      border-color: ${(props) => props.theme.colors.palette.brand.base};
    }
  }

  &:checked {
    + ${Title} ${ChevronContainer} {
      transform: rotate(180deg);
    }

    + ${Title} {
      ${(props) =>
        props.focusPlacement === AccordionBorderPlacement.UNDER &&
        'border: none;'};
    }

    ~ ${Content} {
      display: block;
      max-height: 100%;
      visibility: initial;
      ${(props) =>
        props.focusPlacement === AccordionBorderPlacement.UNDER &&
        `border-bottom: 1px solid ${props.theme.colors.palette.greys.dark};`}
    }
  }
`

export const Accordion: React.FunctionComponent<AccordionProps> = (props) => {
  const id = uuid()
  const inputId = `${id}-input`
  const defaultTheme = useTheme()

  const onChange =
    props.onOpenAccordion &&
    (() =>
      typeof props.onOpenAccordion === 'function' &&
      props.onOpenAccordion(inputId))
  return (
    <div id={`${props.id}-${id}`} ref={props.focusRef}>
      <Input
        id={inputId}
        defaultChecked={props.isOpen}
        type="checkbox"
        focusPlacement={
          props.borderPlacement || AccordionBorderPlacement.BOTTOM
        }
        onChange={onChange}
      />
      <Title
        htmlFor={inputId}
        color={props.headerTextColor}
        borderPlacement={
          props.borderPlacement || AccordionBorderPlacement.BOTTOM
        }
        padding={
          props.theme?.title.padding ||
          defaultTheme.components.accordion.title.padding
        }
      >
        {props.title}
        <ChevronContainer>
          {props.theme?.chevronIcon?.svgPath ||
          defaultTheme.components.accordion.chevronIcon?.svgPath ? (
            <SvgIcon
              xmlns="http://www.w3.org/2000/svg"
              viewBox={
                props.theme?.chevronIcon?.viewBox ||
                defaultTheme.components.accordion.chevronIcon?.viewBox
              }
              width={
                props.theme?.chevronIcon?.width ||
                defaultTheme.components.accordion.chevronIcon?.width
              }
              height={
                props.theme?.chevronIcon?.height ||
                defaultTheme.components.accordion.chevronIcon?.height
              }
            >
              <path
                d={
                  props.theme?.chevronIcon?.svgPath ||
                  defaultTheme.components.accordion.chevronIcon?.svgPath
                }
                fillRule="evenodd"
              />
            </SvgIcon>
          ) : (
            <ChevronDown aria-hidden="true" focusable="false" />
          )}
        </ChevronContainer>
      </Title>
      {props.subtitleElement}
      <Content
        padding={
          props.theme?.content.padding ||
          defaultTheme.components.accordion.content.padding
        }
      >
        {props.children}
      </Content>
    </div>
  )
}
