import { Property } from 'csstype'

export type IconStyling = {
  color: Property.Color
  height: string
  svgPath: string
  viewBox: string
  width: string
  stroke?: string
  strokeLinecap?: 'inherit' | 'butt' | 'round' | 'square'
}

export interface IconsInterface {
  plus?: IconStyling
  minus?: IconStyling
  home?: IconStyling
  return?: IconStyling
  wishlist?: IconStyling
  subscription?: IconStyling
  loyaltyCard?: IconStyling
  loyalty?: IconStyling
  credit?: IconStyling
  referral?: IconStyling
  customerDetails?: IconStyling
  addressBook?: IconStyling
  paymentCard?: IconStyling
  contactPreferences?: IconStyling
  profile?: IconStyling
  chat?: IconStyling
  socialLinks?: IconStyling
  perks?: IconStyling
  help?: IconStyling
  chatOutline?: IconStyling
  chatFilled?: IconStyling
  whatsApp?: IconStyling
  instagram?: IconStyling
  youTube?: IconStyling
  pinterest?: IconStyling
  messages?: IconStyling
  facebook?: IconStyling
  twitter?: IconStyling
  email?: IconStyling
  logOut?: IconStyling
  noOrders?: IconStyling
  order?: IconStyling
  packing?: IconStyling
  transit?: IconStyling
  returned?: IconStyling
  refunding?: IconStyling
  noAddresses?: IconStyling
  pencil?: IconStyling
  bin?: IconStyling
  noPaymentCards?: IconStyling
  zoomIn?: IconStyling
  zoomOut?: IconStyling
  chevronDown?: IconStyling
  sizeGuide?: IconStyling
  crossCircle?: IconStyling
  mapPin?: IconStyling
  selectedCard?: IconStyling
  tickCircle?: IconStyling
  closeCross?: IconStyling
  trendingCurved?: IconStyling
  helpCentrePhone?: IconStyling
}

const emptySvg = {
  color: '',
  height: '',
  svgPath: '',
  viewBox: '',
  width: '',
}

export const defaultIcons: IconsInterface = {
  home: emptySvg,
  order: emptySvg,
  return: emptySvg,
  wishlist: emptySvg,
  loyaltyCard: emptySvg,
  subscription: emptySvg,
  loyalty: emptySvg,
  credit: emptySvg,
  referral: emptySvg,
  customerDetails: emptySvg,
  addressBook: emptySvg,
  paymentCard: emptySvg,
  contactPreferences: emptySvg,
  profile: emptySvg,
  socialLinks: emptySvg,
  perks: emptySvg,
  help: emptySvg,
  chatOutline: emptySvg,
  chatFilled: emptySvg,
  whatsApp: emptySvg,
  instagram: emptySvg,
  youTube: emptySvg,
  pinterest: emptySvg,
  messages: emptySvg,
  facebook: emptySvg,
  twitter: emptySvg,
  email: emptySvg,
  logOut: emptySvg,
  noOrders: emptySvg,
  packing: emptySvg,
  transit: emptySvg,
  returned: emptySvg,
  refunding: emptySvg,
  noAddresses: emptySvg,
  pencil: emptySvg,
  bin: emptySvg,
  noPaymentCards: emptySvg,
  zoomIn: emptySvg,
  zoomOut: emptySvg,
  crossCircle: emptySvg,
  mapPin: emptySvg,
  selectedCard: emptySvg,
  tickCircle: emptySvg,
  closeCross: emptySvg,
}
