export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /**
   * This scalar represents a price or other unit of money.
   *
   * It is serialized as a string of the format `GBP8.85`.
   *
   * The first 3 characters are a currency code, and the rest is a decimal number representing the number of
   * units of the given currency.
   */
  Money: any
  /** A string which has already been translated to the sites locale by the API. Can be displayed to the customer without the need for any localisation. */
  DisplayString: any
  /** Numeric product ID */
  SKU: any
  /**
   * A string which authorises the origin customer account to be used to start the account linking process.
   *
   * An AccountLinkingToken should be retrieved via the getAccountLinkingToken mutation as the first step in
   * linking two accounts.
   *
   * This token should then be sent to the site of the other account to be linked, which should submit it to the
   * getAccountExtensionToken mutation.
   */
  AccountLinkingToken: any
  /** May be absolute or relative */
  URL: any
  /** Hex colour (e.g. "#000000") */
  Colour: any
  /** Milliseconds elapsed since the unix epoch */
  Timestamp: any
  /** Language and country e.g. en_GB */
  Locale: any
  /**
   * The path to a page (Not including extensions)
   *
   * (e.g. "/dietary-needs/vegan")
   */
  PagePath: any
  /** A date in the format YYYY-MM-DD (In London Time) */
  Date: any
  DeliveryMethod: any
  SpecialOfferGroup: any
  OrderNumber: any
  /** 2-digit integer e.g. January is 01 */
  Month: any
  /** 4-digit integer e.g. 2019 */
  Year: any
  /** Binary data, base64-encoded to allow easy transport as a JSON string. */
  Base64Bytes: any
  /** A string which represents a link between a single account on a social login provider */
  SocialLinkID: any
  /**
   * A string which represents a partially completed Social Login.
   *
   * It can be submitted to the exchangeSocialLoginToken query or socialLogin mutation to continue socially logging in.
   *
   * In the case that a social login fails, a new SocialAuthenticationToken will be returned which can be used
   * to continue the social login process with additional data
   */
  SocialAuthenticationToken: any
  /**
   * A string which authorises a password reset for a specific customer account.
   *
   * When the forgottenPassword mutation is called, a PasswordResetToken will be sent to the customer's email address.
   *
   * This PasswordResetToken can be used in the resetPassword mutation to reset the user's password.
   */
  PasswordResetToken: any
  /** Always null */
  Void: any
  /**
   * A string which authorises the target customer account to be linked to the origin customer account.
   *
   * An AccountLinkingExtensionToken can be retrieved on the target customer's site by using the origin customer's
   * AccountLinkingToken in the getAccountExtensionToken mutation.
   *
   * To use an AccountLinkingExtensionToken to link two accounts, the token should first be transferred back to the site
   * of the origin customer account, and then submitted to the linkAccounts mutation.
   */
  AccountLinkingExtensionToken: any
  /**
   * A token included in email links to verify that actions are performed by the intended user only
   * and to identify the user without having to pass through emails as parameters
   */
  MarketingToken: any
  /** A signed 64-bit integer. */
  LongInt: any
}

export type AccordionWidget = Widget & {
  __typename?: 'AccordionWidget'
  content?: Maybe<RichContent>
  id: Scalars['ID']
  query: Query
  title?: Maybe<Scalars['String']>
}

export type AccordionWidgetContainer = Widget & {
  __typename?: 'AccordionWidgetContainer'
  banners?: Maybe<Array<Maybe<Widget>>>
  heading?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  title?: Maybe<Scalars['String']>
  titlePosition?: Maybe<Scalars['String']>
}

/**  From the uuid parameter from the email unsubscribe link */
export enum AccountCreationMarketingPreferences {
  /** Returned if a customer already exists for this email address */
  CustomerAlreadyExists = 'CUSTOMER_ALREADY_EXISTS',
  /** Returned when the email address has no customer and is not signed up for newsletters */
  DoesNotReceiveMarketingMaterial = 'DOES_NOT_RECEIVE_MARKETING_MATERIAL',
  /** Returned when the email address has signed up for newsletters but has no customer */
  ReceivesMarketingMaterial = 'RECEIVES_MARKETING_MATERIAL',
}

/**
 * Contains information about an AccountLinkingToken, to be displayed for the target user to accept the link
 *
 * If error is non-null, the rest of the data will be null
 */
export type AccountLinkingTokenInfo = {
  __typename?: 'AccountLinkingTokenInfo'
  error?: Maybe<AccountLinkingTokenInfoError>
  email?: Maybe<Scalars['String']>
}

export enum AccountLinkingTokenInfoError {
  OriginAlreadyLinked = 'ORIGIN_ALREADY_LINKED',
  TargetAlreadyLinked = 'TARGET_ALREADY_LINKED',
  InvalidLinkingToken = 'INVALID_LINKING_TOKEN',
}

export type AccountUpdateStatus = {
  __typename?: 'AccountUpdateStatus'
  customer?: Maybe<Customer>
  error?: Maybe<AuthenticationError>
  fieldErrors?: Maybe<Array<Maybe<FormFieldValidationError>>>
}

export type AddDiscussionMessageInput = {
  attachmentToken?: Maybe<Scalars['String']>
  message: Scalars['String']
}

export type AddProductToBasketItem = {
  quantity: Scalars['Int']
  sku: Scalars['SKU']
}

export type AddProductWaitlistInput = {
  deviceDetails?: Maybe<PushSubscriptionInput>
  emailDetails?: Maybe<EmailDetails>
  sku: Scalars['SKU']
  waitlistType: WaitlistType
}

export type Address = {
  __typename?: 'Address'
  /** House number / House Name / Apartment Number / Floor / Suite number */
  addressLine1: Scalars['String']
  /** Street */
  addressLine2?: Maybe<Scalars['String']>
  addressLine3?: Maybe<Scalars['String']>
  /** City */
  addressLine4?: Maybe<Scalars['String']>
  /** Region / County */
  addressLine5?: Maybe<Scalars['String']>
  addresseeName: Scalars['String']
  clickAndCollect: Scalars['Boolean']
  companyName?: Maybe<Scalars['String']>
  country: Country
  defaultBillingAddress?: Maybe<Scalars['Boolean']>
  defaultDeliveryAddress?: Maybe<Scalars['Boolean']>
  phoneNumber?: Maybe<Scalars['String']>
  postalCode: Scalars['String']
  /** Mutually exclusive with addressLine5 */
  state?: Maybe<State>
}

export type Addresses = {
  __typename?: 'Addresses'
  addresses: Array<AddressRecord>
  hasMore: Scalars['Boolean']
  total: Scalars['Int']
}

export type AddressesFilterInput = {
  id?: Maybe<Scalars['ID']>
}

export type AddressInput = {
  /** House number / House Name / Apartment Number / Floor / Suite number */
  addressLine1: Scalars['String']
  /** Street */
  addressLine2?: Maybe<Scalars['String']>
  addressLine3?: Maybe<Scalars['String']>
  /** City */
  addressLine4?: Maybe<Scalars['String']>
  /** Region / County */
  addressLine5?: Maybe<Scalars['String']>
  addresseeName: Scalars['String']
  companyName?: Maybe<Scalars['String']>
  country: Country
  defaultBillingAddress?: Maybe<Scalars['Boolean']>
  defaultDeliveryAddress?: Maybe<Scalars['Boolean']>
  phoneNumber?: Maybe<Scalars['String']>
  /** Post Code / Zip Code */
  postalCode: Scalars['String']
  /** Mutually exclusive with addressLine5 */
  state?: Maybe<State>
}

export type AddressRecord = {
  __typename?: 'AddressRecord'
  address: Address
  id: Scalars['ID']
}

export enum AddReviewError {
  InvalidInfo = 'INVALID_INFO',
}

export type AddReviewInput = {
  fields: Array<ReviewFieldInput>
  sku: Scalars['SKU']
}

export type AddReviewResponse = {
  __typename?: 'AddReviewResponse'
  error?: Maybe<AddReviewError>
  fieldErrors: Array<FormFieldValidationError>
}

export type AddToWaitlistResponse = {
  __typename?: 'AddToWaitlistResponse'
  status: AddToWaitlistResponseStatus
}

export enum AddToWaitlistResponseStatus {
  Failed = 'FAILED',
  Pass = 'PASS',
  RequiresVerification = 'REQUIRES_VERIFICATION',
}

/**
 * URLs to alternative locale versions of the current page or product.
 *
 * These should be added to the page in <link rel="alternate" hreflang="$locale" href="$url"> tags to
 * provide hints for SEO purposes.
 */
export type AlternateLink = {
  __typename?: 'AlternateLink'
  locale: Scalars['Locale']
  url: Scalars['URL']
}

export type AnswerOption = {
  __typename?: 'AnswerOption'
  /**
   * This is an option to a question, an optionKey will always be provided and a translation may sometimes be provided.
   * If a translation is not provided, you should use the optionKey to present a translated value to the customer.
   */
  optionKey: Scalars['String']
  translation?: Maybe<Scalars['DisplayString']>
}

export type AppliedOffer = {
  __typename?: 'AppliedOffer'
  info?: Maybe<Scalars['DisplayString']>
  message: Scalars['DisplayString']
  /** Some offers automatically apply so cannot be removed */
  removeable: Scalars['Boolean']
  /** May be null if the discount amount should not be shown */
  totalBasketDiscount?: Maybe<MoneyValue>
}

export type ApproveSocialLinkInput = {
  socialLinkId: Scalars['SocialLinkID']
}

export type AsymmetricGrid = Widget & {
  __typename?: 'asymmetricGrid'
  altTextImgOne?: Maybe<Scalars['String']>
  altTextImgTwo?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageOnePath?: Maybe<Scalars['URL']>
  imageOneSubtitle?: Maybe<Scalars['String']>
  imageTwoPath?: Maybe<Scalars['URL']>
  imageTwoSubtitle?: Maybe<Scalars['String']>
  linkOne?: Maybe<Scalars['String']>
  linkTwo?: Maybe<Scalars['String']>
  query: Query
  title?: Maybe<Scalars['String']>
  widthImageOne?: Maybe<Scalars['Int']>
  widthImageOneMobile?: Maybe<Scalars['Int']>
  widthImageTwo?: Maybe<Scalars['Int']>
  widthImageTwoMobile?: Maybe<Scalars['Int']>
}

/**
 * To upload an attachment:
 *
 * 1) Query for the customer's Attachment Uploader using the attachmentUploaderConfig query.
 * 2) POST the file to the upload URL of the sites domain with the authToken in the "uploadToken" form field
 *    and the attachment in the "attachment" form field as "Content type: application/octet-stream"
 * 3) Get the "token" from the response and submit that as part of your mutation input as attachmentToken
 */
export type AttachmentUploaderConfig = {
  __typename?: 'AttachmentUploaderConfig'
  authToken: Scalars['String']
  uploadUrl: Scalars['String']
}

export enum AuthenticationError {
  /** On registration, an account with the given email address already exists */
  AccountExists = 'ACCOUNT_EXISTS',
  /** The user's account is locked, and can be unlocked by resetting their password with the forgottenPassword mutation */
  AccountLocked = 'ACCOUNT_LOCKED',
  AlreadyUsedToken = 'ALREADY_USED_TOKEN',
  /**
   * On registration, there was already an account, but the account did not have a password set up.
   *
   * We sent the user a verification email which will allow them to set a password on their existing account.
   *
   * This will happen if registration occurs over a social login or guest checkout account.
   */
  EmailVerificationSent = 'EMAIL_VERIFICATION_SENT',
  ExpiredToken = 'EXPIRED_TOKEN',
  /** On login, either the user's email was not found, or their password was incorrect */
  FailedLogin = 'FAILED_LOGIN',
  /** The data provided on registration or social login was invalid, or insufficient to create an account. */
  InvalidData = 'INVALID_DATA',
  /** The password provided on registration did not meet the password complexity requirements */
  InvalidPassword = 'INVALID_PASSWORD',
  InvalidToken = 'INVALID_TOKEN',
  /** User verified by an alternative authentication service but requires password reset. */
  OkRequiresPasswordReset = 'OK_REQUIRES_PASSWORD_RESET',
  /**
   * On Social Login, the link between the account on the social authentication provider and the customer was unable to
   * be verified.  It can be verified via email.  A social link verification email can be sent with the
   * requestSocialLinkVerificationEmail mutation
   */
  SocialLinkPending = 'SOCIAL_LINK_PENDING',
}

/**
 * Returned on any field intended to log the user in.
 *
 * For Web clients, just receiving a successful AuthenticationResponse is enough to be logged in, as
 * the HTTP response will come with an authentication cookie.
 *
 * For API clients, the token field should be extracted on a successful login and used
 * as the Authorization header for future API requests, in the following format:
 *
 * Authorization: Opaque <token>
 */
export type AuthenticationResponse = {
  __typename?: 'AuthenticationResponse'
  customer?: Maybe<Customer>
  error?: Maybe<AuthenticationError>
  fieldErrors?: Maybe<Array<Maybe<FormFieldValidationError>>>
  newCustomer?: Maybe<Scalars['Boolean']>
  /** Returned when user is required to reset their password on site */
  passwordResetToken?: Maybe<Scalars['PasswordResetToken']>
}

export enum AvailabilitySource {
  Warehouse = 'WAREHOUSE',
  Dropship = 'DROPSHIP',
  Marketplace = 'MARKETPLACE',
}

export type AvailablePaymentOption = {
  __typename?: 'AvailablePaymentOption'
  option: Scalars['String']
  subOption?: Maybe<Scalars['String']>
}

export type AverageFeatureScore = {
  __typename?: 'AverageFeatureScore'
  name: Scalars['String']
  value: Scalars['Float']
}

export type Basket = {
  __typename?: 'Basket'
  appliedOffers: Array<AppliedOffer>
  availablePaymentOptions: Array<AvailablePaymentOption>
  /** The total price after applying any discounts. */
  chargePrice: MoneyValue
  /** This object will be null if no code is on the basket. */
  code?: Maybe<BasketCode>
  discount: MoneyValue
  earnableLoyaltyPoints?: Maybe<Scalars['Int']>
  /**
   * A basket may be ineligible for checkout if there's a problem with the user's chosen
   * click-and-collect options.
   *
   * For information on why the basket is ineligible, check Basket.messages and BasketItem.messages
   */
  eligibleForCheckout: Scalars['Boolean']
  guestCheckoutAllowed: Scalars['Boolean']
  id: Scalars['ID']
  items: Array<BasketItem>
  /**
   * Have the contents of the current customer's saved basket been merged into this basket.
   * If this flag is true, then checkout will be blocked.
   * Clear by setting the "acknowledgeMerge" flag on the basket query.
   */
  merged: Scalars['Boolean']
  messages: Array<BasketMessage>
  recommendations?: Maybe<Array<Product>>
  selectYourSample: Array<SelectYourSample>
  /** The total price before applying any discounts. */
  standardPrice: MoneyValue
  totalQuantity: Scalars['Int']
}

export type BasketRecommendationsArgs = {
  limit?: Scalars['Int']
}

export type BasketAddFulfilmentInput = {
  method: FulfilmentMethod
  storeId?: Maybe<Scalars['ID']>
}

export type BasketCode = {
  __typename?: 'BasketCode'
  /** May be null if the offer triggered by the code is not valid anymore but the code is yet to be removed */
  offer?: Maybe<AppliedOffer>
  value: Scalars['String']
}

export type BasketItem = {
  __typename?: 'BasketItem'
  appliedOffers: Array<AppliedOffer>
  /**
   * The price after applying any discounts.  Will be 0 for free gifts
   * @deprecated Use chargePricePerUnit instead
   */
  chargePrice: MoneyValue
  /** The price per unit after applying any discounts.  Will be 0 for free gifts */
  chargePricePerUnit: MoneyValue
  discountPerUnit: MoneyValue
  /** If the product has been added as a free gift by an offer */
  freeGift: Scalars['Boolean']
  /**
   * The selected fulfilment method for this item.  The fulfilment method can be changed by removing
   * the item, and adding a copy of the same SKU with a different fulfilment method, which can be
   * performed in a single API call.
   */
  fulfilmentMethod: FulfilmentMethod
  id: Scalars['ID']
  messages: Array<BasketItemMessage>
  product: ProductVariant
  quantity: Scalars['Int']
  /** Returns true when the item in the basket is ranged for a combination of item and store. */
  ranged?: Maybe<Scalars['Boolean']>
  /** The price per unit before applying any discounts. */
  standardPricePerUnit: MoneyValue
  /**
   * The selected store for this item's fulfilment, if the fulfilment method is one that requires
   * a store.  The store can be changed by removing the item, and adding a copy of the same SKU
   * with a different store, which can be performed in a single API call.
   */
  store?: Maybe<Store>
  /**
   * The amount of stock in the selected store, if the fulfilment method is one that requires a
   * store.
   */
  storeStock?: Maybe<Scalars['Int']>
  subscriptionContract?: Maybe<SubscriptionContract>
  /** The price after applying any discounts.  Will be 0 for free gifts */
  totalChargePrice: MoneyValue
  totalDiscount: MoneyValue
  /** The price before applying any discounts. */
  totalStandardPrice: MoneyValue
}

export type BasketItemMessage = {
  __typename?: 'BasketItemMessage'
  type: BasketItemMessageType
  message?: Maybe<Scalars['DisplayString']>
}

export enum BasketItemMessageType {
  /**
   * This flag will appear if there is not enough stock for the product and fulfilment option the
   * customer has chosen. These issues can be fixed by changing the requested stock.
   *
   * If there is absolutely no stock, FULFILMENT_ISSUE is returned instead.
   */
  NotEnoughStock = 'NOT_ENOUGH_STOCK',
  /**
   * This flag will appear if the product is no longer eligible for the selected fulfilment option,
   * e.g. this product may no longer be available for home delivery, or click and collect or the
   * product is marked as not purchasable online anymore. These problems can be fixed by changing
   * the fulfilment type - note the product may no longer be available for any fulfilment type.
   */
  ProductIssue = 'PRODUCT_ISSUE',
  /**
   * This flag will appear if the selected fulfilment type is no longer available. This can be where
   * the store is closed or does not have click and collect available and is related the the
   * fulfilment type itself rather than the product. These problems can be fixed by changing
   * something about the fulfilment type e.g. changing store.
   */
  FulfilmentIssue = 'FULFILMENT_ISSUE',
  /**
   * This flag will appear if the selected fulfilment type is Home Delivery. This can be available
   * when product is in stock, is qualified for Home Delivery, is bookable and if it is in the out
   * of gauge weight group.
   */
  HomeDeliveryNamedDay = 'HOME_DELIVERY_NAMED_DAY',
  /**
   * This flag will appear if the selected fulfilment type is Home Delivery. This can be available
   * when product is in stock, is qualified for Home Delivery, is bookable and if it is in the one
   * man weight group.
   */
  HomeDeliveryNextDay = 'HOME_DELIVERY_NEXT_DAY',
  /**
   * This flag will appear if the selected fulfilment type is Home Delivery. This can be available
   * when product is in stock, is qualified for Home Delivery, is not bookable, if it is not in
   * the vendor direct weight group and if it doesn't have lead time.
   */
  HomeDeliveryXDays = 'HOME_DELIVERY_X_DAYS',
  /**
   * This flag will appear if the selected fulfilment type is Home Delivery. This can be available
   * when product is in stock, is qualified for Home Delivery, is bookable, if it is in the
   * vendor direct weight group and if it has lead time.
   */
  HomeDeliveryLeadTime = 'HOME_DELIVERY_LEAD_TIME',
  /**
   * This flag will appear if the selected fulfilment type is Deliver To Store. This can be available
   * when product is in stock, is qualified for Home Delivery, store is active and can fulfill this fulfilment type.
   */
  None = 'NONE',
  /**
   * This flag will appear if the selected fulfilment type is Collect In Store. This can be available
   * when product is in stock, is click and collect, stock is available in store
   * and store is active and can fulfill this fulfilment type.
   */
  InStock = 'IN_STOCK',
  /**
   * This flag will appear if the selected fulfilment type is Collect In Store. This can be available
   * when product is in stock, is click and collect, stock is not available in store
   * but the store stock is ranged.
   */
  OutOfStock = 'OUT_OF_STOCK',
  /**
   * This flag will appear if the selected fulfilment type is Collect In Store. This can be available
   * when product is in stock, is click and collect, and stock exists but is not ranged.
   */
  NotRanged = 'NOT_RANGED',
  /**
   * This flag will appear if the selected fulfilment type is Collect In Store. This can be available
   * when product is in stock, is click and collect, and stock exists but the store cannot fulfill the fulfilment type.
   * Or it can appear when the fulfilment type is Collect In Store but the product is not click and collect.
   */
  ClickAndCollectUnavailable = 'CLICK_AND_COLLECT_UNAVAILABLE',
  /**
   * This flag will appear if the selected fulfilment type is Collect In Store. This can be available
   * when product has the flag no check stock.
   */
  NoCheckStock = 'NO_CHECK_STOCK',
  /**
   * This flag will appear if the selected fulfilment type is Collect In Store. This can be available
   * when product has the collection type as none.
   */
  NotAvailableInStores = 'NOT_AVAILABLE_IN_STORES',
  /**
   * This flag will appear if the selected fulfilment type is Collect In Store. This can be available
   * when product has the collection type as order in store.
   */
  OrderInStore = 'ORDER_IN_STORE',
  /**
   * This flag will appear if the selected fulfilment type is either Collect In Store or Deliver to Store.
   * This can be available when the store is present but it is not active.
   */
  StoreClosed = 'STORE_CLOSED',
  /**
   * This flag will appear if the selected fulfilment type is Deliver To Store. This can be available
   * when the product is not in stock in warehouse or when product is not eligible for home delivery
   * which is a requirement for deliver to store.
   */
  NotDeliverToStore = 'NOT_DELIVER_TO_STORE',
  /**
   * This flag will appear if the selected fulfilment type is either Collect In Store or Deliver to Store.
   * This can be available when the store is not present or not active or it cannot support the fulfilment type.
   */
  DeliveryToStoreUnavailable = 'DELIVERY_TO_STORE_UNAVAILABLE',
  /**
   * This flag will appear if the selected fulfilment type is Home Delivery.
   * This can be available when the product is not in stock in warehouse.
   */
  HomeDeliveryOutOfStock = 'HOME_DELIVERY_OUT_OF_STOCK',
  /**
   * This flag will appear if the selected fulfilment type is Home Delivery.
   * This can be available when the product is in stock in warehouse but it is not eligible for Home Delivery.
   */
  HomeDeliveryUnavailable = 'HOME_DELIVERY_UNAVAILABLE',
}

/** Messages to show at the top of the basket */
export type BasketMessage = {
  __typename?: 'BasketMessage'
  message?: Maybe<Scalars['DisplayString']>
  type: BasketMessageType
}

export enum BasketMessageType {
  /**
   * Display as info.  The code the user applied with an `applyCodeToBasket` mutation was valid, but cancels out an
   * existing, higher value offer. Note: This does not prevent a user overriding their current code with a lower value code
   */
  BetterOfferAlreadyApplied = 'BETTER_OFFER_ALREADY_APPLIED',
  /** Display as success.  The code the user applied with an `applyCodeToBasket` mutation was valid */
  CodeApplied = 'CODE_APPLIED',
  /** Display as an error.  The code the user applied with an `applyCodeToBasket` mutation is valid, but has expired. */
  CodeExpired = 'CODE_EXPIRED',
  /** Display as an error.  The code the user applied with an `applyCodeToBasket` mutation is invalid. */
  CodeInvalid = 'CODE_INVALID',
  /**
   * Display as an error.  The code the user applied with an `applyCodeToBasket`
   * mutation is valid, but not applicable to their basket.
   */
  CodeValidButNotApplicableToBasket = 'CODE_VALID_BUT_NOT_APPLICABLE_TO_BASKET',
  /**
   * Display as error.  This basket contains one or more items with errors.
   * See BasketItem.messages
   */
  ItemsHaveErrors = 'ITEMS_HAVE_ERRORS',
  /**
   * Display as error.  This basket contains a mix of store-based
   * (COLLECT_IN_STORE, DELIVER_TO_STORE) and non-store-based (HOME_DELIVERY) fulfilment methods.
   */
  MixedFulfilmentMethods = 'MIXED_FULFILMENT_METHODS',
  /** Display as success.  An offer has been applied to the basket */
  OfferApplied = 'OFFER_APPLIED',
  /** Display as an error.  Product quantity has exceeded maximum allowed per order. */
  ProductMaxPerOrderExceeded = 'PRODUCT_MAX_PER_ORDER_EXCEEDED',
  /** Display as an error.  One or more products (detailed in the message) are currently out of stock. */
  ProductOutOfStock = 'PRODUCT_OUT_OF_STOCK',
  /**
   * Display as an error.  The code the user applied with an `applyCodeToBasket`
   * mutation is a promotion code that has already been used.
   */
  PromoCodeAlreadyUsed = 'PROMO_CODE_ALREADY_USED',
  /** Display as info.  The referral code is valid but as an existing customer you are not eligible to use it */
  RefereeNotEligible = 'REFEREE_NOT_ELIGIBLE',
  /** Display as success.  The referral code is valid and has been applied to the basket */
  ReferralCodeInserted = 'REFERRAL_CODE_INSERTED',
  /** Display as info.  The referral code is valid but needs the user to register or log in to apply it */
  ReferralCodeNeedsRegistration = 'REFERRAL_CODE_NEEDS_REGISTRATION',
  /** Display as an error.  The referral code is valid, but the account is already referred or is not a new account */
  ReferrerNotEligible = 'REFERRER_NOT_ELIGIBLE',
  /** Display as success.  The user became eligible for a free gift selection */
  SelectYourSampleApplied = 'SELECT_YOUR_SAMPLE_APPLIED',
  /** Display as info.  The user can add additional products to their basket to receive a discount. */
  Upsell = 'UPSELL',
}

export type BmiCalculator = Widget & {
  __typename?: 'BMICalculator'
  calculateButtonText?: Maybe<Scalars['String']>
  formSummaryDefaultText?: Maybe<Scalars['String']>
  formSummaryDefaultTitle?: Maybe<Scalars['String']>
  formSummaryNormalWeightText?: Maybe<Scalars['String']>
  formSummaryNormalWeightTitle?: Maybe<Scalars['String']>
  formSummaryObeseWeightText?: Maybe<Scalars['String']>
  formSummaryObeseWeightTitle?: Maybe<Scalars['String']>
  formSummaryOverWeightText?: Maybe<Scalars['String']>
  formSummaryOverWeightTitle?: Maybe<Scalars['String']>
  formSummaryUnderWeightText?: Maybe<Scalars['String']>
  formSummaryUnderWeightTitle?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imperialDefault?: Maybe<Scalars['String']>
  nextButtonNormalWeightLink?: Maybe<Scalars['String']>
  nextButtonObeseWeightLink?: Maybe<Scalars['String']>
  nextButtonOverWeightLink?: Maybe<Scalars['String']>
  nextButtonText?: Maybe<Scalars['String']>
  nextButtonUnderWeightLink?: Maybe<Scalars['String']>
  query: Query
  widgetSubtitle?: Maybe<Scalars['String']>
  widgetTitle?: Maybe<Scalars['String']>
}

export type BmiCalculatorV2 = Widget & {
  __typename?: 'BMICalculatorV2'
  id: Scalars['ID']
  imperialDefault?: Maybe<Scalars['String']>
  nextButtonNormalWeightLink?: Maybe<Scalars['String']>
  nextButtonObeseWeightLink?: Maybe<Scalars['String']>
  nextButtonOverWeightLink?: Maybe<Scalars['String']>
  nextButtonText?: Maybe<Scalars['String']>
  nextButtonUnderWeightLink?: Maybe<Scalars['String']>
  query: Query
  widgetSubtitle?: Maybe<Scalars['String']>
  widgetTitle?: Maybe<Scalars['String']>
}

export type Brand = {
  __typename?: 'Brand'
  /** Not all brands have an associated logo */
  imageUrl?: Maybe<Scalars['URL']>
  name: Scalars['DisplayString']
  page?: Maybe<BrandPage>
}

export type BrandEntity = {
  __typename?: 'BrandEntity'
  id: Scalars['Int']
  name: Scalars['String']
  slug: Scalars['String']
  description?: Maybe<Scalars['String']>
  publishedAt: Scalars['Timestamp']
  origin?: Maybe<Scalars['String']>
  images: Array<EntityImage>
  productList: ProductList
  outfits: Array<Outfit>
}

export type BrandEntityImagesArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type BrandEntityProductListArgs = {
  options: ProductListInput
}

export type BrandEntityOutfitsArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type BrandPage = {
  __typename?: 'BrandPage'
  path: Scalars['PagePath']
}

export type BrandsPageWidget = Widget & {
  __typename?: 'BrandsPageWidget'
  id: Scalars['ID']
  query: Query
}

/**
 * Breadcrumbs are links to other pages that appear at the top of a page, providing a way for customers
 * to get back to a "parent" page, for example from the "Protein" page to the general "Nutrition" page.
 */
export type Breadcrumb = {
  __typename?: 'Breadcrumb'
  displayName: Scalars['DisplayString']
  pagePath: Scalars['PagePath']
}

export type Buildyourownbundle = Widget & {
  __typename?: 'buildyourownbundle'
  banners?: Maybe<Array<Maybe<Widget>>>
  bundlePageTitle?: Maybe<Scalars['String']>
  bundleSubTitle?: Maybe<Scalars['String']>
  id: Scalars['ID']
  maxProducts?: Maybe<Scalars['String']>
  packSummary?: Maybe<Scalars['String']>
  query: Query
  showPrices?: Maybe<Scalars['String']>
  summaryAmount?: Maybe<Scalars['String']>
}

export type BuildYourOwnBundleProductList = Widget & {
  __typename?: 'BuildYourOwnBundleProductList'
  disablePackQuantity?: Maybe<Scalars['String']>
  id: Scalars['ID']
  list_row_bundle_size?: Maybe<Scalars['String']>
  maxSelectableErrorMessage?: Maybe<Scalars['String']>
  maxSelectableProducts?: Maybe<Scalars['Int']>
  productCount?: Maybe<Scalars['Int']>
  productList?: Maybe<Scalars['String']>
  quantitySelector?: Maybe<Scalars['String']>
  query: Query
  widgetTitle?: Maybe<Scalars['String']>
}

export type ButtonLink = Widget & {
  __typename?: 'buttonLink'
  id: Scalars['ID']
  link?: Maybe<Scalars['String']>
  query: Query
  style?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
}

export type BuyNowPayLaterProvider = {
  __typename?: 'BuyNowPayLaterProvider'
  displayName: Scalars['DisplayString']
  /** The instalment amount is calculated from VIP pricing if available. */
  instalmentAmount: MoneyValue
  landingPageLink: Scalars['URL']
  numberOfInstalments: Scalars['Int']
  providerName: Scalars['String']
}

export type CancelOrderInput = {
  orderNumber: Scalars['OrderNumber']
  reason: OrderCancellationReason
}

export enum SubscriptionAutoRenewalOptInType {
  MONTHLY_ROLLING = 'MONTHLY_ROLLING',
  EXISTING_CONTRACT = 'EXISTING_CONTRACT',
  NOT_SELECTED = 'NOT_SELECTED',
}

export type SubscriptionAutoRenewalOptInTypeInput = {
  optInType: SubscriptionAutoRenewalOptInType
}

export type CancelOrderProductInput = {
  quantity: Scalars['Int']
  reason: OrderCancellationReason
  sku: Scalars['SKU']
}

export type CancelOrderProductsInput = {
  orderNumber: Scalars['OrderNumber']
  products: Array<CancelOrderProductInput>
}

export type CancelOrderSpecialOfferGroupInput = {
  group: Scalars['SpecialOfferGroup']
  reason: OrderCancellationReason
}

export type CancelOrderSpecialOfferGroupsInput = {
  groups: Array<CancelOrderSpecialOfferGroupInput>
  orderNumber: Scalars['OrderNumber']
}

export type CaptchaConfiguration = {
  __typename?: 'CaptchaConfiguration'
  /**
   * Any **public** configuration information for this captcha type.
   * Not required for mobile app mechanisms.
   */
  siteKey?: Maybe<Scalars['String']>
  type: CaptchaType
}

export enum CaptchaType {
  AndroidPlayIntegrity = 'ANDROID_PLAY_INTEGRITY',
  AndroidSafetyNetAttestation = 'ANDROID_SAFETY_NET_ATTESTATION',
  AppleDeviceCheck = 'APPLE_DEVICE_CHECK',
  V2Invisible = 'V2_INVISIBLE',
  V2Visible = 'V2_VISIBLE',
}

export type CategoryItemCard = Widget & {
  __typename?: 'categoryItemCard'
  altTextitem?: Maybe<Scalars['String']>
  id: Scalars['ID']
  itemCTA?: Maybe<Scalars['String']>
  itemDescription?: Maybe<Scalars['String']>
  itemImagePath?: Maybe<Scalars['URL']>
  itemTitle?: Maybe<Scalars['String']>
  itemUrl?: Maybe<Scalars['String']>
  query: Query
  textAlignment?: Maybe<Scalars['String']>
}

export type CategoryItemCardWithTextOverlay = Widget & {
  __typename?: 'categoryItemCardWithTextOverlay'
  altTextitem?: Maybe<Scalars['String']>
  id: Scalars['ID']
  itemCTA?: Maybe<Scalars['String']>
  itemDescription?: Maybe<Scalars['String']>
  itemImagePath?: Maybe<Scalars['URL']>
  itemTitle?: Maybe<Scalars['String']>
  itemUrl?: Maybe<Scalars['String']>
  query: Query
  textAlignment?: Maybe<Scalars['String']>
}

export enum CheckoutStartError {
  /**
   * The customer's baskets were merged when attempting to start checkout.
   * They should be shown the basket again and asked to confirm.
   *
   * Only occurs on checkoutStart
   */
  BasketsMerged = 'BASKETS_MERGED',
  /** Only occurs on guestCheckoutStart */
  CustomerLoggedIn = 'CUSTOMER_LOGGED_IN',
  /** Occurs on both checkoutStart and guestCheckoutStart */
  InvalidBasket = 'INVALID_BASKET',
  /** Occurs on both checkoutStart and guestCheckoutStart */
  InvalidBasketMaxQuantityExceeded = 'INVALID_BASKET_MAX_QUANTITY_EXCEEDED',
  /** Occurs on both checkoutStart and guestCheckoutStart */
  InvalidBasketNoItemOnBasket = 'INVALID_BASKET_NO_ITEM_ON_BASKET',
  /** Only occurs on guestCheckoutStart */
  InvalidBasketSubscriptionsOnGuestCheckout = 'INVALID_BASKET_SUBSCRIPTIONS_ON_GUEST_CHECKOUT',
  InvalidClickAndCollectBasket = 'INVALID_CLICK_AND_COLLECT_BASKET',
  /** Occurs on both checkoutStart and guestCheckoutStart */
  InvalidCurrency = 'INVALID_CURRENCY',
  /** Occurs on both checkoutStart and guestCheckoutStart */
  InvalidPaymentOption = 'INVALID_PAYMENT_OPTION',
  /** Occurs on both checkoutStart and guestCheckoutStart */
  InvalidShippingDestination = 'INVALID_SHIPPING_DESTINATION',
  /** Only occurs on checkoutStart */
  NotEligibleForReferral = 'NOT_ELIGIBLE_FOR_REFERRAL',
  NoPaymentProblem = 'NO_PAYMENT_PROBLEM',
  NoSuchOrder = 'NO_SUCH_ORDER',
  NoSuchSubscription = 'NO_SUCH_SUBSCRIPTION',
}

export type CheckoutStartInput = {
  basketId: Scalars['ID']
  currency: Currency
  paymentOption?: Maybe<PaymentOption>
  shippingDestination: Country
}

export type CheckoutStartResponse = {
  __typename?: 'CheckoutStartResponse'
  checkoutUrl?: Maybe<Scalars['URL']>
  error?: Maybe<CheckoutStartError>
}

export enum CodeValidationResult {
  AlreadyUsed = 'ALREADY_USED',
  Invalid = 'INVALID',
  NotActive = 'NOT_ACTIVE',
  Valid = 'VALID',
}

export type Concession = {
  __typename?: 'Concession'
  concessionCode: Scalars['String']
  homepage?: Maybe<Page>
  header: Header
  footer: Footer
}

/** As described here: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 */
export enum Country {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  /**
   * [Netherlands Antilles](https://en.wikipedia.org/wiki/Netherlands_Antilles). No longer legally
   * exists but still used as a shipping country within our system.
   */
  An = 'AN',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW',
}

export type CountryDeliveryInfo = {
  __typename?: 'CountryDeliveryInfo'
  country: Country
  /** An ordered list of values to use as the cells for this table row */
  values: Array<CountryDeliveryInfoCell>
}

export type CountryDeliveryInfoCell = {
  __typename?: 'CountryDeliveryInfoCell'
  header: Scalars['String']
  value: Scalars['DisplayString']
}

export type CoverageCalculator = Widget & {
  __typename?: 'coverageCalculator'
  additionalInfoDisclaimerText?: Maybe<Scalars['String']>
  additionalInfoText?: Maybe<RichContent>
  additionalInfoTitle?: Maybe<Scalars['String']>
  buyButtonLink?: Maybe<Scalars['String']>
  calculatorSubtitle?: Maybe<Scalars['String']>
  calculatorTitle?: Maybe<Scalars['String']>
  calculatorType?: Maybe<Scalars['String']>
  excludeAreaHelpText?: Maybe<RichContent>
  id: Scalars['ID']
  instructionsText?: Maybe<RichContent>
  instructionsTitle?: Maybe<Scalars['String']>
  paintCoatsHelpText?: Maybe<RichContent>
  paintCoverage?: Maybe<Scalars['String']>
  query: Query
  wallpaperRollLength?: Maybe<Scalars['String']>
  wallpaperRollWidth?: Maybe<Scalars['String']>
}

export type CreateDiscussionInput = {
  category: DiscussionCategory
  message: AddDiscussionMessageInput
  selection?: Maybe<DiscussionSelectionInput>
}

export type CreditAccount = {
  __typename?: 'CreditAccount'
  actions: CreditActions
  /**
   * The total credit for this account for this currency, including credit shared from linked accounts.
   * Always in the currency of the credit account.
   */
  balance: MoneyValue
  currency: Currency
  expiringIn: MoneyValue
  linkedAccounts: Array<LinkedCreditAccount>
}

export type CreditAccountActionsArgs = {
  limit?: Scalars['Int']
  offset?: Scalars['Int']
}

export type CreditAccountExpiringInArgs = {
  days?: Scalars['Int']
}

export type CreditAccountsFilterInput = {
  currency?: Maybe<Currency>
}

export type CreditAction = {
  __typename?: 'CreditAction'
  addedAt: Scalars['Timestamp']
  /** Amounts are always in the currency of the credit account. */
  amount: MoneyValue
  /** Will always be `amount` minus `amountUsed`. */
  amountAvailable: MoneyValue
  /** Will always be between 0 and `amount`, will be 0 for Reservations and Paid Out Actions. */
  amountUsed: MoneyValue
  expiresAt?: Maybe<Scalars['Timestamp']>
  id: Scalars['ID']
  message?: Maybe<Scalars['DisplayString']>
  order?: Maybe<Order>
  status: CreditActionStatus
  type: CreditActionType
}

export type CreditActions = {
  __typename?: 'CreditActions'
  actions: Array<CreditAction>
  hasMore: Scalars['Boolean']
  total: Scalars['Int']
}

export enum CreditActionStatus {
  Expired = 'EXPIRED',
  Valid = 'VALID',
}

export enum CreditActionType {
  PaidIn = 'PAID_IN',
  PaidOut = 'PAID_OUT',
  Reservation = 'RESERVATION',
}

export type CriteoSponsoredBannerAds = Widget & {
  __typename?: 'CriteoSponsoredBannerAds'
  id: Scalars['ID']
  query: Query
  widgetVersion?: Maybe<Scalars['String']>
}

export type CriteoSponsoredBannerAdsFlagship = Widget & {
  __typename?: 'CriteoSponsoredBannerAdsFlagship'
  id: Scalars['ID']
  query: Query
  sponsoredFlagshipAdsImage?: Maybe<Scalars['URL']>
}

export enum Currency {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Azn = 'AZN',
  Bam = 'BAM',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Byr = 'BYR',
  Cad = 'CAD',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Czk = 'CZK',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Eur = 'EUR',
  Fjd = 'FJD',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Hrk = 'HRK',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jpy = 'JPY',
  Kes = 'KES',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kzt = 'KZT',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Mad = 'MAD',
  Mkd = 'MKD',
  Mop = 'MOP',
  Mxn = 'MXN',
  Myr = 'MYR',
  Ngn = 'NGN',
  Nok = 'NOK',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pen = 'PEN',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Sar = 'SAR',
  Sek = 'SEK',
  Sgd = 'SGD',
  Thb = 'THB',
  Tnd = 'TND',
  Try = 'TRY',
  Twd = 'TWD',
  Uah = 'UAH',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Vnd = 'VND',
  Wst = 'WST',
  Xcd = 'XCD',
  Zar = 'ZAR',
}

export type Customer = {
  __typename?: 'Customer'
  addresses?: Maybe<Addresses>
  creditAccounts?: Maybe<Array<CreditAccount>>
  /** @deprecated Use the paginated returns field instead. */
  customerReturns: Array<CustomerReturn>
  dateOfBirth?: Maybe<Scalars['Date']>
  /**
   * Returns all discussions for this customer.
   * If status is provided, the result will be filtered to just discussions with the given status.
   */
  discussions?: Maybe<Discussions>
  email: Scalars['String']
  fullName: Scalars['String']
  hashedEmail?: Maybe<Scalars['String']>
  invoices: Array<Scalars['String']>
  loyalty?: Maybe<CustomerLoyalty>
  loyaltyOptIn?: Maybe<Scalars['Boolean']>
  marketingPreferences?: Maybe<Scalars['Boolean']>
  offersWallet: Array<RewardOffer>
  orders?: Maybe<Orders>
  paymentCards?: Maybe<PaymentCards>
  phoneNumber?: Maybe<Scalars['String']>
  referralCode: Scalars['String']
  referralCount: Scalars['Int']
  referralLink: Scalars['String']
  returns?: Maybe<CustomerReturns>
  rewardCardNumber?: Maybe<Scalars['String']>
  /**
   * All social links for the customer with the given status.
   *
   * If status is null or not supplied, all social links are returned
   */
  socialLinks?: Maybe<Array<SocialLink>>
  socialReferralMethods: Array<SocialReferralMethod>
  /** Returns all subscriptions for the user.  If status is supplied, only returns subscriptions matching the given status */
  subscriptions?: Maybe<Subscriptions>
  wishlist?: Maybe<WishlistItems>
}

export type CustomerAddressesArgs = {
  filter?: Maybe<AddressesFilterInput>
  limit?: Scalars['Int']
  offset?: Scalars['Int']
}

export type CustomerCreditAccountsArgs = {
  filter?: Maybe<CreditAccountsFilterInput>
}

export type CustomerDiscussionsArgs = {
  filter?: Maybe<DiscussionsFilterInput>
  limit?: Scalars['Int']
  offset?: Scalars['Int']
}

export type CustomerInvoicesArgs = {
  orderNumber: Scalars['OrderNumber']
  sku: Scalars['SKU']
}

export type CustomerMarketingPreferencesArgs = {
  type: MarketingType
}

export type CustomerOrdersArgs = {
  filter?: Maybe<OrdersFilterInput>
  limit?: Scalars['Int']
  offset?: Scalars['Int']
}

export type CustomerPaymentCardsArgs = {
  filter?: Maybe<PaymentCardsFilterInput>
  limit?: Scalars['Int']
  offset?: Scalars['Int']
}

export type CustomerReturnsArgs = {
  limit?: Scalars['Int']
  offset?: Scalars['Int']
}

export type CustomerSocialLinksArgs = {
  status?: Maybe<SocialLinkStatus>
}

export type CustomerSubscriptionsArgs = {
  filter?: Maybe<SubscriptionsFilterInput>
  limit?: Scalars['Int']
  offset?: Scalars['Int']
}

export type CustomerWishlistArgs = {
  currency: Currency
  limit?: Scalars['Int']
  offset?: Scalars['Int']
  shippingDestination: Country
  sort?: WishlistSort
  productContentKeys?: string[]
}

export type CustomerLoyalty = {
  __typename?: 'CustomerLoyalty'
  pendingPoints?: Maybe<Scalars['Int']>
  points: Scalars['Int']
  tier?: Maybe<LoyaltyTier>
  transactions?: Maybe<Array<LoyaltyTransaction>>
}

export type CustomerReturn = {
  __typename?: 'CustomerReturn'
  customerReturnInfo: CustomerReturnInfo
  label?: Maybe<LabelWithDetails>
  returnMethod: Scalars['String']
  trackingLink?: Maybe<Scalars['URL']>
}

export type CustomerReturnInfo = {
  __typename?: 'CustomerReturnInfo'
  cancelledAt?: Maybe<Scalars['Timestamp']>
  completedAt?: Maybe<Scalars['Timestamp']>
  createdAt: Scalars['Timestamp']
  customerReturnInfoLines: Array<CustomerReturnInfoLine>
  customerReturnStatus: CustomerReturnStatus
  dispatchedAt?: Maybe<Scalars['Timestamp']>
  refundValue: MoneyValue
  returnNumber: Scalars['Int']
  shippingPrice?: Maybe<MoneyValue>
  totalValue: MoneyValue
  tracking: Scalars['Boolean']
}

export type CustomerReturnInfoLine = {
  __typename?: 'CustomerReturnInfoLine'
  product: ProductVariant
  quantity: Scalars['Int']
}

export type CustomerReturns = {
  __typename?: 'CustomerReturns'
  customerReturns: Array<CustomerReturn>
  hasMore: Scalars['Boolean']
  total: Scalars['Int']
}

export enum CustomerReturnStatus {
  /** The return has been cancelled */
  Cancelled = 'CANCELLED',
  /** The return parcel is in transit */
  InTransit = 'IN_TRANSIT',
  /** The label has been created */
  Packing = 'PACKING',
  /** The return has been processed by the warehouse */
  Refunding = 'REFUNDING',
  /** The return parcel has been delivered */
  Returned = 'RETURNED',
}

export type DateRange = {
  __typename?: 'DateRange'
  from?: Maybe<Scalars['Date']>
  to?: Maybe<Scalars['Date']>
}

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type DeliveryInfo = {
  __typename?: 'DeliveryInfo'
  groups: Array<DeliveryInfoGroup>
}

/** Represents a single grouping of shipping destinations, for example "Europe", "North America" or "Rest Of The World" */
export type DeliveryInfoGroup = {
  __typename?: 'DeliveryInfoGroup'
  /** Unordered list of countries.  You may wish to sort this alphabetically by the country's translated name. */
  countryDetails: Array<CountryDeliveryInfo>
  /** An ordered list of keys representing the headers of the table columns */
  headers: Array<Scalars['String']>
  title: Scalars['DisplayString']
}

export type DeliveryInformationWidget = Widget & {
  __typename?: 'DeliveryInformationWidget'
  deliveryOption?: Maybe<Scalars['String']>
  deliveryPrice?: Maybe<Scalars['String']>
  hasDetails?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  sectionDetails?: Maybe<RichContent>
  sectionTitle?: Maybe<Scalars['String']>
}

export type DeliveryInfoWidget = Widget & {
  __typename?: 'DeliveryInfoWidget'
  id: Scalars['ID']
  query: Query
}

export type DeliveryInstructions = {
  __typename?: 'DeliveryInstructions'
  generalInstructions: Array<Scalars['DisplayString']>
  title: Scalars['DisplayString']
}

export type Designer = {
  __typename?: 'Designer'
  id: Scalars['Int']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  slug: Scalars['String']
  publishedAt: Scalars['Timestamp']
  images: Array<EntityImage>
  outfits: Array<Outfit>
  stories: Array<Story>
}

export type DesignerImagesArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type DesignerOutfitsArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type DesignerStoriesArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type Discussion = {
  __typename?: 'Discussion'
  category: DiscussionCategory
  createdAt: Scalars['Timestamp']
  id: Scalars['ID']
  messages: DiscussionMessages
  read: Scalars['Boolean']
  selection?: Maybe<DiscussionSelection>
  status: DiscussionStatus
  updatedAt: Scalars['Timestamp']
}

export type DiscussionMessagesArgs = {
  limit?: Scalars['Int']
  offset?: Scalars['Int']
}

export enum DiscussionCategory {
  Cancellation = 'CANCELLATION',
  Checkout = 'CHECKOUT',
  DamagedItem = 'DAMAGED_ITEM',
  DeliveryProblem = 'DELIVERY_PROBLEM',
  Feedback = 'FEEDBACK',
  FreeGift = 'FREE_GIFT',
  HelpWithAnNhsOrder = 'HELP_WITH_AN_NHS_ORDER',
  IncorrectItemReceived = 'INCORRECT_ITEM_RECEIVED',
  InvoiceRequest = 'INVOICE_REQUEST',
  IWantToCreateAnAccount = 'I_WANT_TO_CREATE_AN_ACCOUNT',
  IWantToReturnAnItem = 'I_WANT_TO_RETURN_AN_ITEM',
  IWantToSubmitADataRequest = 'I_WANT_TO_SUBMIT_A_DATA_REQUEST',
  MissingItem = 'MISSING_ITEM',
  MyTrackingHasntUpdated = 'MY_TRACKING_HASNT_UPDATED',
  NutritionalAdvice = 'NUTRITIONAL_ADVICE',
  OffersAndPromotions = 'OFFERS_AND_PROMOTIONS',
  Payment = 'PAYMENT',
  PlacingAnOrder = 'PLACING_AN_ORDER',
  PricebeaterRequest = 'PRICEBEATER_REQUEST',
  ProblemWithMyOrder = 'PROBLEM_WITH_MY_ORDER',
  ProductInformation = 'PRODUCT_INFORMATION',
  QualityIssue = 'QUALITY_ISSUE',
  ReturnsAndRefunds = 'RETURNS_AND_REFUNDS',
  StockAvailability = 'STOCK_AVAILABILITY',
  TaxRequest = 'TAX_REQUEST',
  UpdateOnAReturn = 'UPDATE_ON_A_RETURN',
  WebsiteIssues = 'WEBSITE_ISSUES',
  WhereIsMyOrder = 'WHERE_IS_MY_ORDER',
}

export type DiscussionMessage = {
  __typename?: 'DiscussionMessage'
  createdAt: Scalars['Timestamp']
  id: Scalars['ID']
  message: Scalars['DisplayString']
  type: DiscussionMessageType
}

export type DiscussionMessages = {
  __typename?: 'DiscussionMessages'
  hasMore: Scalars['Boolean']
  messages: Array<DiscussionMessage>
  total: Scalars['Int']
}

export enum DiscussionMessageType {
  Query = 'QUERY',
  Response = 'RESPONSE',
}

export type Discussions = {
  __typename?: 'Discussions'
  discussions: Array<Discussion>
  hasMore: Scalars['Boolean']
  total: Scalars['Int']
}

export type DiscussionSelection = {
  __typename?: 'DiscussionSelection'
  selectedOrder?: Maybe<Order>
  selectedProducts: Array<OrderProduct>
}

export type DiscussionSelectionInput = {
  selectedOrderId: Scalars['ID']
  selectedProductSkus: Array<Scalars['SKU']>
}

export type DiscussionsFilterInput = {
  id?: Maybe<Scalars['ID']>
  orderNumber?: Maybe<Scalars['OrderNumber']>
  status?: Maybe<DiscussionStatus>
}

export enum DiscussionStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Outstanding = 'OUTSTANDING',
  Pending = 'PENDING',
}

export type DynamicEntity =
  | Outfit
  | Designer
  | Story
  | BrandEntity
  | FullBrandEntity

export type DynamicEntityList = {
  __typename?: 'DynamicEntityList'
  hasMore: Scalars['Boolean']
  total: Scalars['Int']
  dynamicEntities: Array<Maybe<DynamicEntity>>
}

export enum DynamicEntityType {
  BrandEntity = 'BRAND_ENTITY',
  Designer = 'DESIGNER',
  FullBrandEntity = 'FULL_BRAND_ENTITY',
  Outfit = 'OUTFIT',
  Story = 'STORY',
}

export type DynamicReferralWidget = Widget & {
  __typename?: 'DynamicReferralWidget'
  balanceNote?: Maybe<Scalars['String']>
  codeSubtitle?: Maybe<Scalars['String']>
  codeTitle?: Maybe<Scalars['String']>
  copySubtitle?: Maybe<Scalars['String']>
  copyTitle?: Maybe<Scalars['String']>
  friendsNote?: Maybe<Scalars['String']>
  id: Scalars['ID']
  mainSubtitle?: Maybe<Scalars['String']>
  mainTitle?: Maybe<Scalars['String']>
  query: Query
  sharingSubtitle?: Maybe<Scalars['String']>
  sharingTitle?: Maybe<Scalars['String']>
  showCopyYourCodeSection?: Maybe<Scalars['String']>
  showYourCodeSection?: Maybe<Scalars['String']>
}

export type Easiware = Widget & {
  __typename?: 'Easiware'
  id: Scalars['ID']
  query: Query
}

export type EditorialWidget = Widget & {
  __typename?: 'EditorialWidget'
  content?: Maybe<RichContent>
  id: Scalars['ID']
  query: Query
}

export type EGift = {
  __typename?: 'EGift'
  productVariant: ProductVariant
  quantity: Scalars['Int']
}

export type EGiftSummary = {
  __typename?: 'EGiftSummary'
  code: Scalars['String']
  codeValidationResult: CodeValidationResult
  eGifts: Array<Maybe<EGift>>
}

export type EightLinkButtonWidget = Widget & {
  __typename?: 'eightLinkButtonWidget'
  buttonTextEight?: Maybe<Scalars['String']>
  buttonTextFive?: Maybe<Scalars['String']>
  buttonTextFour?: Maybe<Scalars['String']>
  buttonTextOne?: Maybe<Scalars['String']>
  buttonTextSeven?: Maybe<Scalars['String']>
  buttonTextSix?: Maybe<Scalars['String']>
  buttonTextThree?: Maybe<Scalars['String']>
  buttonTextTwo?: Maybe<Scalars['String']>
  buttonUrlEight?: Maybe<Scalars['String']>
  buttonUrlFive?: Maybe<Scalars['String']>
  buttonUrlFour?: Maybe<Scalars['String']>
  buttonUrlOne?: Maybe<Scalars['String']>
  buttonUrlSeven?: Maybe<Scalars['String']>
  buttonUrlSix?: Maybe<Scalars['String']>
  buttonUrlThree?: Maybe<Scalars['String']>
  buttonUrlTwo?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  title?: Maybe<Scalars['String']>
  titleAlign?: Maybe<Scalars['String']>
}

export type EmailDetails = {
  email: Scalars['String']
  marketingConsentAuditData?: Maybe<MarketingConsentAuditData>
}

export type EmailReEngagementModal = Widget & {
  __typename?: 'EmailReEngagementModal'
  defaultImageHeight?: Maybe<Scalars['String']>
  defaultImageWidth?: Maybe<Scalars['String']>
  emailReEngagementBulletText1?: Maybe<Scalars['String']>
  emailReEngagementBulletText2?: Maybe<Scalars['String']>
  emailReEngagementBulletText3?: Maybe<Scalars['String']>
  emailReEngagementOptInDiscount?: Maybe<Scalars['String']>
  emailReEngagementTitleText?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageAltText?: Maybe<Scalars['String']>
  imageLarge?: Maybe<Scalars['URL']>
  imageMedium?: Maybe<Scalars['URL']>
  imageSmall?: Maybe<Scalars['URL']>
  query: Query
}

export type EntityImage = {
  __typename?: 'EntityImage'
  name: Scalars['String']
  url: Scalars['URL']
  alternativeText?: Maybe<Scalars['String']>
}

export type Facet = {
  facetHeader: Scalars['DisplayString']
  facetName: Scalars['String']
}

export type FacetInput = {
  facetName: Scalars['String']
  selections: Array<FacetSelectionInput>
}

/** If optionName is non-null, from and to are ignored. */
export type FacetSelectionInput = {
  from?: Maybe<Scalars['Float']>
  optionName?: Maybe<Scalars['String']>
  to?: Maybe<Scalars['Float']>
}

export type FastTrackBanner = Widget & {
  __typename?: 'FastTrackBanner'
  fastTrackContent?: Maybe<Scalars['String']>
  fastTrackHeader?: Maybe<Scalars['String']>
  fastTrackUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
}

export enum Feature {
  AccountLinking = 'ACCOUNT_LINKING',
  AddressManagement = 'ADDRESS_MANAGEMENT',
  AgeGating = 'AGE_GATING',
  AgeOnRegistration = 'AGE_ON_REGISTRATION',
  AlternateAuth = 'ALTERNATE_AUTH',
  AssociatedProducts = 'ASSOCIATED_PRODUCTS',
  BuyNowPayLater = 'BUY_NOW_PAY_LATER',
  ClickAndCollect = 'CLICK_AND_COLLECT',
  CocaColaPersonalisation = 'COCA_COLA_PERSONALISATION',
  Concessions = 'CONCESSIONS',
  Credit = 'CREDIT',
  CrossSiteReferrals = 'CROSS_SITE_REFERRALS',
  DateOfBirth = 'DATE_OF_BIRTH',
  DefaultAddresses = 'DEFAULT_ADDRESSES',
  DoubleOptIn = 'DOUBLE_OPT_IN',
  DynamicEntityManagement = 'DYNAMIC_ENTITY_MANAGEMENT',
  FastTrack = 'FAST_TRACK',
  /**
   * Whether or not the site supports login with passwords to dedicated user accounts on the site.
   *
   * If this feature is not available, other methods of logging in or proceeding may be available such
   * as social login or guest checkout
   */
  FirstPartyAuth = 'FIRST_PARTY_AUTH',
  FormInfo = 'FORM_INFO',
  FriendsReferralScheme = 'FRIENDS_REFERRAL_SCHEME',
  FullNameOnRegistration = 'FULL_NAME_ON_REGISTRATION',
  GenderOnRegistration = 'GENDER_ON_REGISTRATION',
  GiftingEmails = 'GIFTING_EMAILS',
  /**  Whether or not the site allows for the purchasing and using of gift cards. */
  GiftCards = 'GIFT_CARDS',
  GuestCheckout = 'GUEST_CHECKOUT',
  GuestCheckoutWithoutEmail = 'GUEST_CHECKOUT_WITHOUT_EMAIL',
  /** Whether products have multiple availability sources */
  HasMultipleAvailabilitySources = 'HAS_MULTIPLE_AVAILABILITY_SOURCES',
  Loyalty = 'LOYALTY',
  LoyaltyOptIn = 'LOYALTY_OPT_IN',
  /** Whether or not the site supports manual recommendations */
  ManualRecommendations = 'MANUAL_RECOMMENDATIONS',
  MarketingConsentOnRegistration = 'MARKETING_CONSENT_ON_REGISTRATION',
  MobileNumber = 'MOBILE_NUMBER',
  NextDayDelivery = 'NEXT_DAY_DELIVERY',
  /** Whether or not the site supports returns */
  OfflineOrders = 'OFFLINE_ORDERS',
  OrderReturns = 'ORDER_RETURNS',
  PopInABoxTracker = 'POP_IN_A_BOX_TRACKER',
  ProductPersonalisation = 'PRODUCT_PERSONALISATION',
  Profiles = 'PROFILES',
  ReferralCodeOnRegistration = 'REFERRAL_CODE_ON_REGISTRATION',
  Reviews = 'REVIEWS',
  ReviewVoting = 'REVIEW_VOTING',
  RewardCards = 'REWARD_CARDS',
  SiteProperties = 'SITE_PROPERTIES',
  SplitNamesOnRegistration = 'SPLIT_NAMES_ON_REGISTRATION',
  SponsoredAds = 'SPONSORED_ADS',
  SubscribeAndSave = 'SUBSCRIBE_AND_SAVE',
  Subscriptions = 'SUBSCRIPTIONS',
  SubscriptionsCancelOnline = 'SUBSCRIPTIONS_CANCEL_ONLINE',
  SubscriptionsDelay = 'SUBSCRIPTIONS_DELAY',
  SubscriptionGifts = 'SUBSCRIPTION_GIFTS',
  Supersize = 'SUPERSIZE',
  Surveys = 'SURVEYS',
  TitleOnRegistration = 'TITLE_ON_REGISTRATION',
  /**  Whether vip pricing is available on the site and should be calculated  */
  VipPricingEnabled = 'VIP_PRICING_ENABLED',
  Waitlist = 'WAITLIST',
  WhereDidYouHearOnRegistration = 'WHERE_DID_YOU_HEAR_ON_REGISTRATION',
  Wishlist = 'WISHLIST',
}

/**
 * A downloadable file, which can be saved or displayed on the client.
 * This is needed primarily for workflows (e.g. returns) that include programatically-created PDF files.
 */
export type FileDownload = {
  __typename?: 'FileDownload'
  /** The contents of this file, base64 encoded. */
  content: Scalars['Base64Bytes']
  /**
   * The [MIME type](https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types)
   * (as used in `Content-Type` headers) for this file. e.g. `application/pdf` or `text/plain`.
   */
  mimeType: Scalars['String']
}

/**
 * Flags are available as part of the extensions for every request.
 *
 * On each GraphQL request, the extensions will contain a list of all flags that apply.
 *
 * Each flag will appear at most once.
 */
export enum Flag {
  LoggedIn = 'LOGGED_IN',
  ProductContentItemsUnavailable = 'PRODUCT_CONTENT_ITEMS_UNAVAILABLE',
  RegistrationUnavailable = 'REGISTRATION_UNAVAILABLE',
}

export type Footer = {
  __typename?: 'Footer'
  navigation?: Maybe<Navigation>
  widgets?: Maybe<Array<Widget>>
}

export enum ForgottenPasswordError {
  UnknownError = 'UNKNOWN_ERROR',
  UserNotFound = 'USER_NOT_FOUND',
}

export type ForgottenPasswordInput = {
  username: Scalars['String']
}

export type ForgottenPasswordResponse = {
  __typename?: 'ForgottenPasswordResponse'
  /** Check to make sure error is not set to confirm success */
  error?: Maybe<ForgottenPasswordError>
  /** @deprecated Check error instead */
  success: Scalars['Boolean']
}

export type Form = {
  __typename?: 'Form'
  /** Can this form be sumitted (again)? */
  canSubmit: Scalars['Boolean']
  /** Some forms may offer a credit reward for completion. */
  creditReward?: Maybe<MoneyValue>
  /** How the form should be displayed once you have started it. */
  displayStyle: FormDisplayStyle
  /** An ordered list of fields to be included with the form */
  fields: Array<FormField>
  /** Used as an identifier and for translations. */
  identifier: Scalars['ID']
  /** Can this form be submitted multiple times? */
  isMultiSubmission: Scalars['Boolean']
  /** An optional SKU which this form relates to. */
  productSku?: Maybe<Scalars['SKU']>
  /** Get a single submission by ID */
  submission?: Maybe<FormSubmission>
  /** A list of all submissions to this form. */
  submissions: Array<FormSubmission>
  /** The type of form (see the enum for descriptions) */
  type: FormType
}

export type FormSubmissionArgs = {
  submissionId?: Maybe<Scalars['ID']>
}

export type FormAnswer = {
  __typename?: 'FormAnswer'
  /** Sometimes a question is locked and cannot be edited */
  locked: Scalars['Boolean']
  /** Use to get the "name" of the Field, to pre-populate the values. */
  question: FormField
  /** For single-select or free text answers, a singleton list will be returned. */
  values: Array<Scalars['String']>
}

export type FormAnswerInput = {
  /** Provide the text entered into the "other" field when offered. */
  otherText?: Maybe<Scalars['String']>
  questionName: Scalars['String']
  /** For single-select or free text answers, only a singleton list will be accepted */
  values: Array<Scalars['String']>
}

export enum FormDisplayStyle {
  OneQuestionPerPage = 'ONE_QUESTION_PER_PAGE',
  ProductSurvey = 'PRODUCT_SURVEY',
  SinglePage = 'SINGLE_PAGE',
}

export type FormField = {
  __typename?: 'FormField'
  answerOptions?: Maybe<Array<Maybe<AnswerOption>>>
  /**
   * If confirmable is true, the field should be rendered with an additional confirmation field, where the user must
   * re-enter the value.  Validation of this should be done on the frontend and this does not affect the data that should
   * be sent to the GraphQL API.
   */
  confirmable: Scalars['Boolean']
  /**
   * The default value of this field.  May be ignored, for example if there's an existing value from
   * a submission being edited.
   */
  defaultValue?: Maybe<Scalars['String']>
  /**
   * Can this field be edited? If true, then this field should still be shown on the form with the standard logic to
   * determine its initial value, but the entry should not be changed. If this field is submitted with the rest of the
   * form to the API (optional) then its value will be ignored
   */
  disabled: Scalars['Boolean']
  label?: Maybe<Scalars['String']>
  /**
   * This will only be non-null for likert scale questions, in which case there should be a grid of radio buttons
   * where the options are provider for each likert question.
   * The answers should be submitted in the order specified here.
   */
  likertQuestions?: Maybe<Array<Scalars['String']>>
  name: Scalars['String']
  /** Should the customer be offered the chance to choose "other" and input their own answer? */
  offerOtherTextInput: Scalars['Boolean']
  /**
   * If answerOptions is non-null, the field will be rejected if the value is not one of the values provided, or null.
   * @deprecated User answerOptions instead
   */
  options?: Maybe<Array<Scalars['String']>>
  /** @deprecated Use AnswerOption.translation instead */
  optionsAreLocalised: Scalars['Boolean']
  /**
   * A list of requirements, all of which must be met in order for this field to be displayed.
   * If the field is not displayed, then it should not be incliuded when the form is submitted to the API.
   * These requirement will be dynamic to the form will become visible or hidden as the answers to other questions change.
   */
  renderConditions: Array<RenderCondition>
  /** If required is true, the field will be rejected if the value is empty or null */
  required: Scalars['Boolean']
  translation?: Maybe<Scalars['DisplayString']>
  type?: Maybe<FormFieldType>
  validators: Array<Validator>
}

export enum FormFieldType {
  /** E.g. a checkbox. */
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Email = 'EMAIL',
  Featurerating = 'FEATURERATING',
  FileUpload = 'FILE_UPLOAD',
  /** E.g. radio buttons. */
  Group = 'GROUP',
  LikertScale = 'LIKERT_SCALE',
  /** E.g. a drop down list. */
  List = 'LIST',
  MarketingConsent = 'MARKETING_CONSENT',
  MultiSelect = 'MULTI_SELECT',
  Number = 'NUMBER',
  Password = 'PASSWORD',
  PhoneNumber = 'PHONE_NUMBER',
  Text = 'TEXT',
  Textbox = 'TEXTBOX',
}

export type FormFieldValidationError = {
  __typename?: 'FormFieldValidationError'
  fieldName: Scalars['String']
  /** true if this field has a strict set of options (LIST / GROUP) but the value provided was not one of them */
  invalidOption: Scalars['Boolean']
  /** true if this field was marked as required, but the provided value was empty or null */
  requiredButNotProvided: Scalars['Boolean']
  /** The list of validators which rejected this field.  A subset of the validators returned in the form */
  validators: Array<ValidatorName>
}

export type FormFilterInput = {
  identifier?: Maybe<Scalars['ID']>
  /** You may filter by either Form Type or Identifier (or neither). */
  type?: Maybe<FormType>
}

export type FormInput = {
  identifier: Scalars['ID']
}

export type FormSubmission = {
  __typename?: 'FormSubmission'
  answers: Array<FormAnswer>
  complete: Scalars['Boolean']
  editable: Scalars['Boolean']
  id: Scalars['ID']
}

export type FormSubmissionInput = {
  answers: Array<FormAnswerInput>
  identifier: Scalars['ID']
  /** The submission being edited, null if this is a new submission. */
  submissionId?: Maybe<Scalars['ID']>
}

export type FormSubmissionResponse = {
  __typename?: 'FormSubmissionResponse'
  fieldErrors?: Maybe<Array<FormFieldValidationError>>
  status: FormSubmissionStatus
}

export enum FormSubmissionStatus {
  /** This form is locked, or otherwise unavailable, don't offer a retry. */
  CantBeSubmitted = 'CANT_BE_SUBMITTED',
  /** Success, Form was edited, but the form is still incomplete. */
  EditedIncomplete = 'EDITED_INCOMPLETE',
  /** Success, Form was edited, and this submission completed the form. */
  EditedNowComplete = 'EDITED_NOW_COMPLETE',
  /** Success, Form was edited, and it is still complete. */
  EditedStillComplete = 'EDITED_STILL_COMPLETE',
  /** There was invalid data in this submission, fix the error and retry. */
  ErrorSubmitting = 'ERROR_SUBMITTING',
  /** Success, Form was submitted and is complete. */
  Submitted = 'SUBMITTED',
  /** Success, Form was submitted, but was not completed, questions missing. */
  SubmittedIncomplete = 'SUBMITTED_INCOMPLETE',
}

export enum FormType {
  /** Forms for use within Account Creation */
  AccountCreation = 'ACCOUNT_CREATION',
  AccountSettings = 'ACCOUNT_SETTINGS',
  /** Additional Profiles will be shown on another page within the Account Section */
  AdditionalProfile = 'ADDITIONAL_PROFILE',
  /** Profiles that hold custom marketing preferences information */
  CustomMarketingPreferences = 'CUSTOM_MARKETING_PREFERENCES',
  /** Profiles that return outcomes based on answers given */
  GoalSelector = 'GOAL_SELECTOR',
  /** Main Profiles should be displayed prominently on the Account Home page */
  MainProfile = 'MAIN_PROFILE',
  /** Product recommendation as a profile */
  ProductRecommender = 'PRODUCT_RECOMMENDER',
  /** Forms for product reviews */
  ProductReview = 'PRODUCT_REVIEW',
  /** Surveys will have their own page within the Account Section too */
  ProductSurvey = 'PRODUCT_SURVEY',
  /** Professional accounts application form as a profile */
  ProfessionalAccounts = 'PROFESSIONAL_ACCOUNTS',
  /** Subscription Cancellation form as a profile */
  SubscriptionCancellation = 'SUBSCRIPTION_CANCELLATION',
  /** Trade accounts application form as a profile */
  TradeAccounts = 'TRADE_ACCOUNTS',
}

export type FoundationFinderLandingPageWidget = Widget & {
  __typename?: 'FoundationFinderLandingPageWidget'
  id: Scalars['ID']
  query: Query
}

export type Fouritemwindowproductslider = Widget & {
  __typename?: 'fouritemwindowproductslider'
  ctaLink?: Maybe<Scalars['String']>
  ctaText?: Maybe<Scalars['String']>
  id: Scalars['ID']
  productCount?: Maybe<Scalars['Int']>
  productList?: Maybe<Scalars['String']>
  query: Query
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export enum FulfilmentMethod {
  /**
   * This product will be delivered to the user's home.
   * This is the default behaviour for non-click-and-collect stores.
   *
   * BasketItems with HOME_DELIVERY as the fulfilment method do not have a destination store.
   */
  HomeDelivery = 'HOME_DELIVERY',
  /**
   * This product will be made available to collect at the selected store
   *
   * Basket items with COLLECT_IN_STORE as the fulfilment method will have a destination store.
   */
  CollectInStore = 'COLLECT_IN_STORE',
  /**
   * This product will be delivered to the selected store, which may incur an additional charge,
   * visible at checkout.
   *
   * Basket items with DELIVER_TO_STORE as the fulfilment method will have a destination store.
   */
  DeliverToStore = 'DELIVER_TO_STORE',
}

export type FullBrandEntity = {
  __typename?: 'FullBrandEntity'
  id: Scalars['Int']
  name: Scalars['String']
  slug: Scalars['String']
  description?: Maybe<Scalars['String']>
  publishedAt: Scalars['Timestamp']
  origin?: Maybe<Scalars['String']>
  images: Array<EntityImage>
  productList: ProductList
  outfits: Array<Outfit>
  unisexPath?: Maybe<Scalars['String']>
  womanPath?: Maybe<Scalars['String']>
  manPath?: Maybe<Scalars['String']>
}

export type FullBrandEntityImagesArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type FullBrandEntityProductListArgs = {
  options: ProductListInput
}

export type FullBrandEntityOutfitsArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type GeneralIframe = Widget & {
  __typename?: 'generalIframe'
  id: Scalars['ID']
  iframeSource?: Maybe<Scalars['String']>
  query: Query
}

export type GeneralQuoteBanner = Widget & {
  __typename?: 'generalQuoteBanner'
  ctaText?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  subtitle?: Maybe<Scalars['String']>
  textLink?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type GenerateAndAddRewardCardResponse = {
  __typename?: 'GenerateAndAddRewardCardResponse'
  success: Scalars['Boolean']
  error?: Maybe<RewardCardError>
  rewardCardNumber?: Maybe<Scalars['String']>
}

export enum GetAccountExtensionTokenError {
  OriginAlreadyLinked = 'ORIGIN_ALREADY_LINKED',
  TargetAlreadyLinked = 'TARGET_ALREADY_LINKED',
  InvalidLinkingToken = 'INVALID_LINKING_TOKEN',
}

export type GetAccountExtensionTokenInput = {
  accountLinkingToken?: Maybe<Scalars['AccountLinkingToken']>
}

/** This response will contain either the error field or the accountExtensionToken field */
export type GetAccountExtensionTokenResponse = {
  __typename?: 'GetAccountExtensionTokenResponse'
  error?: Maybe<GetAccountExtensionTokenError>
  accountExtensionToken?: Maybe<Scalars['AccountLinkingExtensionToken']>
}

export enum GetAccountLinkingTokenError {
  OriginAlreadyLinked = 'ORIGIN_ALREADY_LINKED',
}

/** This response will contain either the error field or the accountLinkingToken field */
export type GetAccountLinkingTokenResponse = {
  __typename?: 'GetAccountLinkingTokenResponse'
  error?: Maybe<GetAccountLinkingTokenError>
  accountLinkingToken?: Maybe<Scalars['AccountLinkingToken']>
}

export type GiftCard = {
  __typename?: 'GiftCard'
  cardNumber: Scalars['String']
  cardUuid: Scalars['String']
  /** @deprecated The card number is not obfuscated. Please use cardNumber field */
  obfuscatedCardNumber: Scalars['String']
}

export type GlobalAboutUsVideo = Widget & {
  __typename?: 'GlobalAboutUsVideo'
  buttonLink?: Maybe<Scalars['String']>
  buttonText?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  textBlock?: Maybe<RichContent>
  title?: Maybe<Scalars['String']>
  transcript?: Maybe<RichContent>
  videoTitle?: Maybe<Scalars['String']>
  videoURL?: Maybe<Scalars['String']>
}

export type GlobalAccreditationIcon = Widget & {
  __typename?: 'GlobalAccreditationIcon'
  altText?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageAltText?: Maybe<Scalars['String']>
  imagePath?: Maybe<Scalars['URL']>
  openNewWindow?: Maybe<Scalars['String']>
  query: Query
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type GlobalAccreditationIconCollection = Widget & {
  __typename?: 'GlobalAccreditationIconCollection'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
}

export type GlobalBrandLogos = Widget & {
  __typename?: 'GlobalBrandLogos'
  brandLogosItemOneImageVariant?: Maybe<Scalars['URL']>
  brandLogosItemOneImageVariantAlt?: Maybe<Scalars['String']>
  brandLogosItemOneVariantURL?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isScrollable?: Maybe<Scalars['String']>
  itemFiveImage?: Maybe<Scalars['URL']>
  itemFiveImageAlt?: Maybe<Scalars['String']>
  itemFiveURL?: Maybe<Scalars['String']>
  itemFourImage?: Maybe<Scalars['URL']>
  itemFourImageAlt?: Maybe<Scalars['String']>
  itemFourURL?: Maybe<Scalars['String']>
  itemOneImage?: Maybe<Scalars['URL']>
  itemOneImageAlt?: Maybe<Scalars['String']>
  itemOneURL?: Maybe<Scalars['String']>
  itemSixImage?: Maybe<Scalars['URL']>
  itemSixImageAlt?: Maybe<Scalars['String']>
  itemSixURL?: Maybe<Scalars['String']>
  itemThreeImage?: Maybe<Scalars['URL']>
  itemThreeImageAlt?: Maybe<Scalars['String']>
  itemThreeURL?: Maybe<Scalars['String']>
  itemTwoImage?: Maybe<Scalars['URL']>
  itemTwoImageAlt?: Maybe<Scalars['String']>
  itemTwoURL?: Maybe<Scalars['String']>
  query: Query
  title?: Maybe<Scalars['String']>
  titleAlign?: Maybe<Scalars['String']>
}

export type GlobalBuyingRightNow = Widget & {
  __typename?: 'GlobalBuyingRightNow'
  id: Scalars['ID']
  productTextFour?: Maybe<Scalars['String']>
  productTextOne?: Maybe<Scalars['String']>
  productTextThree?: Maybe<Scalars['String']>
  productTextTwo?: Maybe<Scalars['String']>
  query: Query
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  sku?: Maybe<ProductVariant>
  skuNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  skufour?: Maybe<ProductVariant>
  skufourNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  skuthree?: Maybe<ProductVariant>
  skuthreeNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  skutwo?: Maybe<ProductVariant>
  skutwoNew?: Maybe<WidgetProductItem>
  title?: Maybe<Scalars['String']>
}

export type GlobalBuyingRightNowManualProductUrl = Widget & {
  __typename?: 'GlobalBuyingRightNowManualProductURL'
  id: Scalars['ID']
  productTextFour?: Maybe<Scalars['String']>
  productTextOne?: Maybe<Scalars['String']>
  productTextThree?: Maybe<Scalars['String']>
  productTextTwo?: Maybe<Scalars['String']>
  query: Query
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  sku?: Maybe<ProductVariant>
  skuNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  skufour?: Maybe<ProductVariant>
  skufourNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  skuthree?: Maybe<ProductVariant>
  skuthreeNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  skutwo?: Maybe<ProductVariant>
  skutwoNew?: Maybe<WidgetProductItem>
  title?: Maybe<Scalars['String']>
  url1?: Maybe<Scalars['String']>
  url2?: Maybe<Scalars['String']>
  url3?: Maybe<Scalars['String']>
  url4?: Maybe<Scalars['String']>
}

export type GlobalCardScrollerCard = Widget & {
  __typename?: 'GlobalCardScrollerCard'
  altText?: Maybe<Scalars['String']>
  buttonText?: Maybe<Scalars['String']>
  colorOfText?: Maybe<Scalars['String']>
  id: Scalars['ID']
  image?: Maybe<Scalars['URL']>
  query: Query
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  verticalPositionOfText?: Maybe<Scalars['String']>
}

export type GlobalCardScrollerSet = Widget & {
  __typename?: 'GlobalCardScrollerSet'
  banners?: Maybe<Array<Maybe<Widget>>>
  cardStyle?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  title?: Maybe<Scalars['String']>
}

export type GlobalDispatchAndDateCountdownWidget = Widget & {
  __typename?: 'GlobalDispatchAndDateCountdownWidget'
  countDownEndDay?: Maybe<Scalars['String']>
  countDownEndHour?: Maybe<Scalars['Int']>
  countDownEndMinutes?: Maybe<Scalars['Int']>
  countDownEndMonth?: Maybe<Scalars['String']>
  countDownEndSeconds?: Maybe<Scalars['Int']>
  countDownEndYear?: Maybe<Scalars['String']>
  countDownSubTitle?: Maybe<Scalars['String']>
  countDownTitle?: Maybe<Scalars['String']>
  daysTitle?: Maybe<Scalars['String']>
  decrement?: Maybe<Scalars['Int']>
  defaultNextDayDeliveryCutoff?: Maybe<Scalars['Int']>
  direction?: Maybe<Scalars['String']>
  dispatchIcon?: Maybe<Scalars['String']>
  dispatchTimeForFriday?: Maybe<Scalars['Int']>
  dispatchTimeForMonday?: Maybe<Scalars['Int']>
  dispatchTimeForSaturday?: Maybe<Scalars['Int']>
  dispatchTimeForSunday?: Maybe<Scalars['Int']>
  dispatchTimeForThursday?: Maybe<Scalars['Int']>
  dispatchTimeForTuesday?: Maybe<Scalars['Int']>
  dispatchTimeForWednesday?: Maybe<Scalars['Int']>
  dispatchTimer?: Maybe<Scalars['String']>
  hoursTitle?: Maybe<Scalars['String']>
  id: Scalars['ID']
  linkUrl?: Maybe<Scalars['String']>
  minutesTitle?: Maybe<Scalars['String']>
  query: Query
  removeOnEnd?: Maybe<Scalars['String']>
  secondsTitle?: Maybe<Scalars['String']>
  separator?: Maybe<Scalars['String']>
  showTimer?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['String']>
  textAlignment?: Maybe<Scalars['String']>
  useLocal?: Maybe<Scalars['String']>
  weeksTitle?: Maybe<Scalars['String']>
}

export type GlobalEditorialWithFeature = Widget & {
  __typename?: 'GlobalEditorialWithFeature'
  CTAFeatured?: Maybe<Scalars['String']>
  CTAItemOne?: Maybe<Scalars['String']>
  CTAItemTwo?: Maybe<Scalars['String']>
  altItemTwo?: Maybe<Scalars['String']>
  altTextFeatured?: Maybe<Scalars['String']>
  altTextItemOne?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  descriptionFeatured?: Maybe<Scalars['String']>
  descriptionItemOne?: Maybe<Scalars['String']>
  descriptionItemTwo?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageFeaturedLarge?: Maybe<Scalars['URL']>
  imageFeaturedSmall?: Maybe<Scalars['URL']>
  imageOneLarge?: Maybe<Scalars['URL']>
  imageOneSmall?: Maybe<Scalars['URL']>
  imageTwoLarge?: Maybe<Scalars['URL']>
  imageTwoSmall?: Maybe<Scalars['URL']>
  linkFeatured?: Maybe<Scalars['String']>
  linkItemOne?: Maybe<Scalars['String']>
  linkItemTwo?: Maybe<Scalars['String']>
  query: Query
  title?: Maybe<Scalars['String']>
  titleFeatured?: Maybe<Scalars['String']>
  titleItemOne?: Maybe<Scalars['String']>
  titleItemTwo?: Maybe<Scalars['String']>
}

export type GlobalFooterAccreditationIcons = Widget & {
  __typename?: 'GlobalFooterAccreditationIcons'
  Image2Alt?: Maybe<Scalars['String']>
  Image3Alt?: Maybe<Scalars['String']>
  Image3Href?: Maybe<Scalars['URL']>
  id: Scalars['ID']
  image1?: Maybe<Scalars['URL']>
  image1Alt?: Maybe<Scalars['String']>
  image1Href?: Maybe<Scalars['URL']>
  image2?: Maybe<Scalars['URL']>
  image2Href?: Maybe<Scalars['URL']>
  image3?: Maybe<Scalars['URL']>
  query: Query
}

export type GlobalFooterContactUs = Widget & {
  __typename?: 'GlobalFooterContactUs'
  id: Scalars['ID']
  line1?: Maybe<Scalars['String']>
  line10?: Maybe<Scalars['String']>
  line2?: Maybe<Scalars['String']>
  line3?: Maybe<Scalars['String']>
  line4?: Maybe<Scalars['String']>
  line5?: Maybe<Scalars['String']>
  line6?: Maybe<Scalars['String']>
  line7?: Maybe<Scalars['String']>
  line8?: Maybe<Scalars['String']>
  line9?: Maybe<Scalars['String']>
  query: Query
  title?: Maybe<Scalars['String']>
}

export type GlobalFourBestSellers = Widget & {
  __typename?: 'GlobalFourBestSellers'
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  ProductFour?: Maybe<ProductVariant>
  ProductFourButtonText?: Maybe<Scalars['String']>
  ProductFourNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  ProductOne?: Maybe<ProductVariant>
  ProductOneButtonText?: Maybe<Scalars['String']>
  ProductOneNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  ProductThree?: Maybe<ProductVariant>
  ProductThreeButtonText?: Maybe<Scalars['String']>
  ProductThreeNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  ProductTwo?: Maybe<ProductVariant>
  ProductTwoButtonText?: Maybe<Scalars['String']>
  ProductTwoNew?: Maybe<WidgetProductItem>
  Title?: Maybe<Scalars['String']>
  disableTitle?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
}

export type GlobalFourButtonLink = Widget & {
  __typename?: 'GlobalFourButtonLink'
  buttonTextFour?: Maybe<Scalars['String']>
  buttonTextOne?: Maybe<Scalars['String']>
  buttonTextThree?: Maybe<Scalars['String']>
  buttonTextTwo?: Maybe<Scalars['String']>
  buttonUrlFour?: Maybe<Scalars['String']>
  buttonUrlOne?: Maybe<Scalars['String']>
  buttonUrlThree?: Maybe<Scalars['String']>
  buttonUrlTwo?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
}

export type GlobalFourItemEditorial = Widget & {
  __typename?: 'GlobalFourItemEditorial'
  altItemFour?: Maybe<Scalars['String']>
  altItemOne?: Maybe<Scalars['String']>
  altItemThree?: Maybe<Scalars['String']>
  altItemTwo?: Maybe<Scalars['String']>
  id: Scalars['ID']
  itemFourCTA?: Maybe<Scalars['String']>
  itemFourDescription?: Maybe<Scalars['String']>
  itemFourImage?: Maybe<Scalars['URL']>
  itemFourSubtitle?: Maybe<Scalars['String']>
  itemFourTitle?: Maybe<Scalars['String']>
  itemFourUrl?: Maybe<Scalars['String']>
  itemOneCTA?: Maybe<Scalars['String']>
  itemOneDescription?: Maybe<Scalars['String']>
  itemOneImage?: Maybe<Scalars['URL']>
  itemOneSubtitle?: Maybe<Scalars['String']>
  itemOneTitle?: Maybe<Scalars['String']>
  itemOneUrl?: Maybe<Scalars['String']>
  itemThreeCTA?: Maybe<Scalars['String']>
  itemThreeDescription?: Maybe<Scalars['String']>
  itemThreeImage?: Maybe<Scalars['URL']>
  itemThreeSubtitle?: Maybe<Scalars['String']>
  itemThreeTitle?: Maybe<Scalars['String']>
  itemThreeUrl?: Maybe<Scalars['String']>
  itemTwoCTA?: Maybe<Scalars['String']>
  itemTwoDescription?: Maybe<Scalars['String']>
  itemTwoImage?: Maybe<Scalars['URL']>
  itemTwoSubtitle?: Maybe<Scalars['String']>
  itemTwoTitle?: Maybe<Scalars['String']>
  itemTwoUrl?: Maybe<Scalars['String']>
  itemsCTAAlignment?: Maybe<Scalars['String']>
  noWrapItems?: Maybe<Scalars['String']>
  query: Query
  styleModifier?: Maybe<Scalars['String']>
  textAlignment?: Maybe<Scalars['String']>
  widgetLink?: Maybe<Scalars['String']>
  widgetLinkText?: Maybe<Scalars['String']>
  widgetSubtitle?: Maybe<Scalars['String']>
  widgetTitle?: Maybe<Scalars['String']>
}

export type GlobalGeneralImageBanner = Widget & {
  __typename?: 'GlobalGeneralImageBanner'
  id: Scalars['ID']
  imageAltText?: Maybe<Scalars['String']>
  largeImage?: Maybe<Scalars['URL']>
  linkUrl?: Maybe<Scalars['String']>
  mediumImage?: Maybe<Scalars['URL']>
  query: Query
  smallImage?: Maybe<Scalars['URL']>
}

export type GlobalHelpCentreAccordion = Widget & {
  __typename?: 'GlobalHelpCentreAccordion'
  accordionContent?: Maybe<RichContent>
  accordionHeading?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
}

export type GlobalHelpCentreAccordion2 = Widget & {
  __typename?: 'GlobalHelpCentreAccordion2'
  accordionContent?: Maybe<RichContent>
  accordionHeading?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
}

export type GlobalHelpCentreAccordionCollection = Widget & {
  __typename?: 'GlobalHelpCentreAccordionCollection'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
  title?: Maybe<Scalars['String']>
}

export type GlobalHelpCentreCollection = Widget & {
  __typename?: 'GlobalHelpCentreCollection'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
}

export type GlobalHeroCtaBanner = Widget & {
  __typename?: 'GlobalHeroCTABanner'
  altTextImg?: Maybe<Scalars['String']>
  buttonOne?: Maybe<Scalars['String']>
  buttonOneLink?: Maybe<Scalars['String']>
  buttonTwo?: Maybe<Scalars['String']>
  buttonTwoLink?: Maybe<Scalars['String']>
  headline?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageDesktop?: Maybe<Scalars['URL']>
  imageExtraSmall?: Maybe<Scalars['URL']>
  imageMedium?: Maybe<Scalars['URL']>
  imageSmall?: Maybe<Scalars['URL']>
  query: Query
  subtitle?: Maybe<Scalars['String']>
}

export type GlobalImageCard = Widget & {
  __typename?: 'GlobalImageCard'
  altText?: Maybe<Scalars['String']>
  buttonText?: Maybe<Scalars['String']>
  colorOfText?: Maybe<Scalars['String']>
  id: Scalars['ID']
  image?: Maybe<Scalars['URL']>
  noBorder?: Maybe<Scalars['Boolean']>
  openLinksInNewTab?: Maybe<Scalars['String']>
  query: Query
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  verticalPositionOfText?: Maybe<Scalars['String']>
}

export type GlobalImageCardSet = Widget & {
  __typename?: 'GlobalImageCardSet'
  banners?: Maybe<Array<Maybe<Widget>>>
  cardStyle?: Maybe<Scalars['String']>
  id: Scalars['ID']
  noBorder?: Maybe<Scalars['Boolean']>
  query: Query
  stackCardsOnMobile?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
}

export type GlobalMultiButton = Widget & {
  __typename?: 'GlobalMultiButton'
  id: Scalars['ID']
  multiButtonWidget_buttonStyle?: Maybe<Scalars['String']>
  multiButtonWidget_itemFiveCTAText?: Maybe<Scalars['String']>
  multiButtonWidget_itemFiveURL?: Maybe<Scalars['String']>
  multiButtonWidget_itemFourCTAText?: Maybe<Scalars['String']>
  multiButtonWidget_itemFourURL?: Maybe<Scalars['String']>
  multiButtonWidget_itemOneCTAText?: Maybe<Scalars['String']>
  multiButtonWidget_itemOneURL?: Maybe<Scalars['String']>
  multiButtonWidget_itemSixCTAText?: Maybe<Scalars['String']>
  multiButtonWidget_itemSixURL?: Maybe<Scalars['String']>
  multiButtonWidget_itemThreeCTAText?: Maybe<Scalars['String']>
  multiButtonWidget_itemThreeURL?: Maybe<Scalars['String']>
  multiButtonWidget_itemTwoCTAText?: Maybe<Scalars['String']>
  multiButtonWidget_itemTwoURL?: Maybe<Scalars['String']>
  query: Query
}

export type GlobalPrimaryBanner = Widget & {
  __typename?: 'GlobalPrimaryBanner'
  altImageLarge?: Maybe<Scalars['String']>
  altLogoPng?: Maybe<Scalars['String']>
  bannerURL?: Maybe<Scalars['String']>
  contentAlign?: Maybe<Scalars['String']>
  contentBoxPosition?: Maybe<Scalars['String']>
  contentTheme?: Maybe<Scalars['String']>
  ctaOne?: Maybe<Scalars['String']>
  ctaOneAriaLabel?: Maybe<Scalars['String']>
  ctaOneURL?: Maybe<Scalars['String']>
  ctaTwo?: Maybe<Scalars['String']>
  ctaTwoAriaLabel?: Maybe<Scalars['String']>
  ctaTwoURL?: Maybe<Scalars['String']>
  hasMargin?: Maybe<Scalars['String']>
  headline?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageLarge?: Maybe<Scalars['URL']>
  imageMedium?: Maybe<Scalars['URL']>
  imageSmall?: Maybe<Scalars['URL']>
  logopngImageBG?: Maybe<Scalars['URL']>
  logopngWhiteBG?: Maybe<Scalars['URL']>
  query: Query
  subtitle?: Maybe<Scalars['String']>
  useH1?: Maybe<Scalars['String']>
}

export type GlobalPrimaryBannerWithList = Widget & {
  __typename?: 'GlobalPrimaryBannerWithList'
  altImageLarge?: Maybe<Scalars['String']>
  altLogoPng?: Maybe<Scalars['String']>
  bannerURL?: Maybe<Scalars['String']>
  contentAlign?: Maybe<Scalars['String']>
  contentBoxPosition?: Maybe<Scalars['String']>
  contentTheme?: Maybe<Scalars['String']>
  ctaOne?: Maybe<Scalars['String']>
  ctaOneAriaLabel?: Maybe<Scalars['String']>
  ctaOneURL?: Maybe<Scalars['String']>
  ctaTwo?: Maybe<Scalars['String']>
  ctaTwoAriaLabel?: Maybe<Scalars['String']>
  ctaTwoURL?: Maybe<Scalars['String']>
  headline?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageLarge?: Maybe<Scalars['URL']>
  imageMedium?: Maybe<Scalars['URL']>
  imageSmall?: Maybe<Scalars['URL']>
  listBrowserSection?: Maybe<Scalars['String']>
  logopngImageBG?: Maybe<Scalars['URL']>
  logopngWhiteBG?: Maybe<Scalars['URL']>
  query: Query
  subtitle?: Maybe<Scalars['String']>
}

export type GlobalPrimaryBannerWithTextOverlay = Widget & {
  __typename?: 'GlobalPrimaryBannerWithTextOverlay'
  altImageLarge?: Maybe<Scalars['String']>
  altLogoPng?: Maybe<Scalars['String']>
  bannerURL?: Maybe<Scalars['String']>
  contentAlign?: Maybe<Scalars['String']>
  contentBoxPosition?: Maybe<Scalars['String']>
  contentTheme?: Maybe<Scalars['String']>
  ctaOne?: Maybe<Scalars['String']>
  ctaOneAriaLabel?: Maybe<Scalars['String']>
  ctaOneURL?: Maybe<Scalars['String']>
  ctaTwo?: Maybe<Scalars['String']>
  ctaTwoAriaLabel?: Maybe<Scalars['String']>
  ctaTwoURL?: Maybe<Scalars['String']>
  hasMargin?: Maybe<Scalars['String']>
  headline?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageLarge?: Maybe<Scalars['URL']>
  imageMedium?: Maybe<Scalars['URL']>
  imageSmall?: Maybe<Scalars['URL']>
  logopngImageBG?: Maybe<Scalars['URL']>
  logopngWhiteBG?: Maybe<Scalars['URL']>
  openInTabOne?: Maybe<Scalars['String']>
  openInTabTwo?: Maybe<Scalars['String']>
  query: Query
  subtitle?: Maybe<Scalars['String']>
  useH1?: Maybe<Scalars['String']>
}

export type GlobalProductCardScroller = Widget & {
  __typename?: 'GlobalProductCardScroller'
  hasArrows?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  productSkuList?: Maybe<Array<Maybe<ProductVariant>>>
  productSkuListNew?: Maybe<Array<Maybe<WidgetProductItem>>>
  query: Query
  title?: Maybe<Scalars['String']>
}

export type GlobalReferral3StepGuide = Widget & {
  __typename?: 'GlobalReferral3StepGuide'
  id: Scalars['ID']
  query: Query
  smallprint?: Maybe<Scalars['String']>
  step1icon?: Maybe<Scalars['String']>
  step1text?: Maybe<Scalars['String']>
  step1title?: Maybe<Scalars['String']>
  step2icon?: Maybe<Scalars['String']>
  step2text?: Maybe<Scalars['String']>
  step2title?: Maybe<Scalars['String']>
  step3icon?: Maybe<Scalars['String']>
  step3text?: Maybe<Scalars['String']>
  step3title?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type GlobalScalableLogos = Widget & {
  __typename?: 'GlobalScalableLogos'
  altTextFive?: Maybe<Scalars['String']>
  altTextFour?: Maybe<Scalars['String']>
  altTextOne?: Maybe<Scalars['String']>
  altTextSix?: Maybe<Scalars['String']>
  altTextThree?: Maybe<Scalars['String']>
  altTextTwo?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imagePathFive?: Maybe<Scalars['URL']>
  imagePathFour?: Maybe<Scalars['URL']>
  imagePathOne?: Maybe<Scalars['URL']>
  imagePathSix?: Maybe<Scalars['URL']>
  imagePathThree?: Maybe<Scalars['URL']>
  imagePathTwo?: Maybe<Scalars['URL']>
  query: Query
  titleFive?: Maybe<Scalars['String']>
  titleFour?: Maybe<Scalars['String']>
  titleOne?: Maybe<Scalars['String']>
  titleSix?: Maybe<Scalars['String']>
  titleThree?: Maybe<Scalars['String']>
  titleTwo?: Maybe<Scalars['String']>
  urlFive?: Maybe<Scalars['String']>
  urlFour?: Maybe<Scalars['String']>
  urlOne?: Maybe<Scalars['String']>
  urlSix?: Maybe<Scalars['String']>
  urlThree?: Maybe<Scalars['String']>
  urlTwo?: Maybe<Scalars['String']>
}

export type GlobalSectionPeek = Widget & {
  __typename?: 'GlobalSectionPeek'
  id: Scalars['ID']
  numberOfProducts?: Maybe<Scalars['Int']>
  productList?: Maybe<ProductList>
  qubitPlacementId?: Maybe<Scalars['String']>
  query: Query
  title?: Maybe<Scalars['String']>
  titleAlign?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type GlobalSectionPeekProductListArgs = {
  input: ProductListInput
}

export type GlobalSetAndromeda = Widget & {
  __typename?: 'GlobalSetAndromeda'
  banners?: Maybe<Array<Maybe<Widget>>>
  columns?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  query: Query
}

export type GlobalSimpleTextCtaWidget = Widget & {
  __typename?: 'GlobalSimpleTextCTAWidget'
  id: Scalars['ID']
  itemOneCTAText?: Maybe<Scalars['String']>
  itemOneURL?: Maybe<Scalars['String']>
  query: Query
  text?: Maybe<Scalars['String']>
  textAlign?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type GlobalSixItemCategories = Widget & {
  __typename?: 'GlobalSixItemCategories'
  altItemFive?: Maybe<Scalars['String']>
  altItemFour?: Maybe<Scalars['String']>
  altItemOne?: Maybe<Scalars['String']>
  altItemSix?: Maybe<Scalars['String']>
  altItemThree?: Maybe<Scalars['String']>
  altItemTwo?: Maybe<Scalars['String']>
  id: Scalars['ID']
  itemCTAAlign?: Maybe<Scalars['String']>
  itemFiveCTA?: Maybe<Scalars['String']>
  itemFiveImage?: Maybe<Scalars['URL']>
  itemFiveLink?: Maybe<Scalars['String']>
  itemFiveTitle?: Maybe<Scalars['String']>
  itemFourCTA?: Maybe<Scalars['String']>
  itemFourImage?: Maybe<Scalars['URL']>
  itemFourLink?: Maybe<Scalars['String']>
  itemFourTitle?: Maybe<Scalars['String']>
  itemOneCTA?: Maybe<Scalars['String']>
  itemOneImage?: Maybe<Scalars['URL']>
  itemOneLink?: Maybe<Scalars['String']>
  itemOneTitle?: Maybe<Scalars['String']>
  itemSixCTA?: Maybe<Scalars['String']>
  itemSixImage?: Maybe<Scalars['URL']>
  itemSixLink?: Maybe<Scalars['String']>
  itemSixTitle?: Maybe<Scalars['String']>
  itemThreeCTA?: Maybe<Scalars['String']>
  itemThreeImage?: Maybe<Scalars['URL']>
  itemThreeLink?: Maybe<Scalars['String']>
  itemThreeTitle?: Maybe<Scalars['String']>
  itemTitleAlign?: Maybe<Scalars['String']>
  itemTwoCTA?: Maybe<Scalars['String']>
  itemTwoImage?: Maybe<Scalars['URL']>
  itemTwoLink?: Maybe<Scalars['String']>
  itemTwoTitle?: Maybe<Scalars['String']>
  query: Query
  title?: Maybe<Scalars['String']>
}

export type GlobalSocialIcon = Widget & {
  __typename?: 'GlobalSocialIcon'
  icon?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  url?: Maybe<Scalars['String']>
}

export type GlobalSocialIconCollection = Widget & {
  __typename?: 'GlobalSocialIconCollection'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
  title?: Maybe<Scalars['String']>
}

export type GlobalSocialIconCollectionv2 = Widget & {
  __typename?: 'GlobalSocialIconCollectionv2'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
  title?: Maybe<Scalars['String']>
}

export type GlobalSocialIconv2 = Widget & {
  __typename?: 'GlobalSocialIconv2'
  icon?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  url?: Maybe<Scalars['String']>
}

export type GlobalStripBanner = Widget & {
  __typename?: 'GlobalStripBanner'
  CTAAlignment?: Maybe<Scalars['String']>
  hideDefaultBanner?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  stripBannerText?: Maybe<Scalars['String']>
  stripBannerTextAccount?: Maybe<Scalars['String']>
  stripBannerTextBasket?: Maybe<Scalars['String']>
  stripBannerTextHomepage?: Maybe<Scalars['String']>
  stripBannerTextPDP?: Maybe<Scalars['String']>
  stripBannerTextPLP?: Maybe<Scalars['String']>
  stripBannerURL?: Maybe<Scalars['String']>
  stripBannerURLAccount?: Maybe<Scalars['String']>
  stripBannerURLBasket?: Maybe<Scalars['String']>
  stripBannerURLHomepage?: Maybe<Scalars['String']>
  stripBannerURLPDP?: Maybe<Scalars['String']>
  stripBannerURLPLP?: Maybe<Scalars['String']>
}

export type GlobalSubscriptionOptions = Widget & {
  __typename?: 'GlobalSubscriptionOptions'
  id: Scalars['ID']
  query: Query
  subscriptionOptionsRibbonMessage?: Maybe<Scalars['String']>
  subscriptionOptionsShowRibbon?: Maybe<Scalars['String']>
  subscriptionOptionsSkuFour?: Maybe<Scalars['String']>
  subscriptionOptionsSkuFourCTAText?: Maybe<Scalars['String']>
  subscriptionOptionsSkuFourDeliveryInfo?: Maybe<Scalars['String']>
  subscriptionOptionsSkuFourImage?: Maybe<Scalars['URL']>
  subscriptionOptionsSkuFourPrice?: Maybe<Scalars['String']>
  subscriptionOptionsSkuFourPriceAppend?: Maybe<Scalars['String']>
  subscriptionOptionsSkuFourSaveMessage?: Maybe<Scalars['String']>
  subscriptionOptionsSkuFourTitle?: Maybe<Scalars['String']>
  subscriptionOptionsSkuFourUspMessage?: Maybe<Scalars['String']>
  subscriptionOptionsSkuOne?: Maybe<Scalars['String']>
  subscriptionOptionsSkuOneCTAText?: Maybe<Scalars['String']>
  subscriptionOptionsSkuOneDeliveryInfo?: Maybe<Scalars['String']>
  subscriptionOptionsSkuOneImage?: Maybe<Scalars['URL']>
  subscriptionOptionsSkuOnePrice?: Maybe<Scalars['String']>
  subscriptionOptionsSkuOnePriceAppend?: Maybe<Scalars['String']>
  subscriptionOptionsSkuOneSaveMessage?: Maybe<Scalars['String']>
  subscriptionOptionsSkuOneTitle?: Maybe<Scalars['String']>
  subscriptionOptionsSkuOneUspMessage?: Maybe<Scalars['String']>
  subscriptionOptionsSkuThree?: Maybe<Scalars['String']>
  subscriptionOptionsSkuThreeCTAText?: Maybe<Scalars['String']>
  subscriptionOptionsSkuThreeDeliveryInfo?: Maybe<Scalars['String']>
  subscriptionOptionsSkuThreeImage?: Maybe<Scalars['URL']>
  subscriptionOptionsSkuThreePrice?: Maybe<Scalars['String']>
  subscriptionOptionsSkuThreePriceAppend?: Maybe<Scalars['String']>
  subscriptionOptionsSkuThreeSaveMessage?: Maybe<Scalars['String']>
  subscriptionOptionsSkuThreeTitle?: Maybe<Scalars['String']>
  subscriptionOptionsSkuThreeUspMessage?: Maybe<Scalars['String']>
  subscriptionOptionsSkuTwo?: Maybe<Scalars['String']>
  subscriptionOptionsSkuTwoCTAText?: Maybe<Scalars['String']>
  subscriptionOptionsSkuTwoDeliveryInfo?: Maybe<Scalars['String']>
  subscriptionOptionsSkuTwoImage?: Maybe<Scalars['URL']>
  subscriptionOptionsSkuTwoPrice?: Maybe<Scalars['String']>
  subscriptionOptionsSkuTwoPriceAppend?: Maybe<Scalars['String']>
  subscriptionOptionsSkuTwoSaveMessage?: Maybe<Scalars['String']>
  subscriptionOptionsSkuTwoTitle?: Maybe<Scalars['String']>
  subscriptionOptionsSkuTwoUspMessage?: Maybe<Scalars['String']>
}

export type GlobalTabbedWidgetSet = Widget & {
  __typename?: 'GlobalTabbedWidgetSet'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
  tabbedWidgetSetSubTitle?: Maybe<Scalars['String']>
  tabbedWidgetSetTitle?: Maybe<Scalars['String']>
  tabbedWidgetTabTitle_1?: Maybe<Scalars['String']>
  tabbedWidgetTabTitle_2?: Maybe<Scalars['String']>
}

export type GlobalThreeItemEditorial = Widget & {
  __typename?: 'GlobalThreeItemEditorial'
  altItemOne?: Maybe<Scalars['String']>
  altItemThree?: Maybe<Scalars['String']>
  altItemTwo?: Maybe<Scalars['String']>
  id: Scalars['ID']
  itemOneCTAText?: Maybe<Scalars['String']>
  itemOneDescription?: Maybe<Scalars['String']>
  itemOneImage?: Maybe<Scalars['URL']>
  itemOneSubtitle?: Maybe<Scalars['String']>
  itemOneTitle?: Maybe<Scalars['String']>
  itemOneUrl?: Maybe<Scalars['String']>
  itemOne_secondaryCTAText?: Maybe<Scalars['String']>
  itemOne_secondaryCTAUrl?: Maybe<Scalars['String']>
  itemThreeCTAText?: Maybe<Scalars['String']>
  itemThreeDescription?: Maybe<Scalars['String']>
  itemThreeImage?: Maybe<Scalars['URL']>
  itemThreeSubtitle?: Maybe<Scalars['String']>
  itemThreeTitle?: Maybe<Scalars['String']>
  itemThreeUrl?: Maybe<Scalars['String']>
  itemThree_secondaryCTAText?: Maybe<Scalars['String']>
  itemThree_secondaryCTAUrl?: Maybe<Scalars['String']>
  itemTwoCTAText?: Maybe<Scalars['String']>
  itemTwoDescription?: Maybe<Scalars['String']>
  itemTwoImage?: Maybe<Scalars['URL']>
  itemTwoSubtitle?: Maybe<Scalars['String']>
  itemTwoTitle?: Maybe<Scalars['String']>
  itemTwoUrl?: Maybe<Scalars['String']>
  itemTwo_secondaryCTAText?: Maybe<Scalars['String']>
  itemTwo_secondaryCTAUrl?: Maybe<Scalars['String']>
  query: Query
  threeItemItemOneAltTextVariant?: Maybe<Scalars['String']>
  threeItemItemOneCTATextVariant?: Maybe<Scalars['String']>
  threeItemItemOneDescriptionVariant?: Maybe<Scalars['String']>
  threeItemItemOneImageVariant?: Maybe<Scalars['URL']>
  threeItemItemOneSubtitleVariant?: Maybe<Scalars['String']>
  threeItemItemOneTitleVariant?: Maybe<Scalars['String']>
  threeItemItemOneVariantUrl?: Maybe<Scalars['String']>
  useCircularImages?: Maybe<Scalars['String']>
  widgetHeadingSize?: Maybe<Scalars['String']>
  widgetSubtitle?: Maybe<Scalars['String']>
  widgetTitle?: Maybe<Scalars['String']>
}

export type GlobalThreeItemEditorialSubtitleBg = Widget & {
  __typename?: 'GlobalThreeItemEditorialSubtitleBG'
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  itemOneAltText?: Maybe<Scalars['String']>
  itemOneCTAText?: Maybe<Scalars['String']>
  itemOneDescription?: Maybe<Scalars['String']>
  itemOneImage?: Maybe<Scalars['URL']>
  itemOneSubtitle?: Maybe<Scalars['String']>
  itemOneSubtitleBGColour?: Maybe<Scalars['String']>
  itemOneTitle?: Maybe<Scalars['String']>
  itemOneUrl?: Maybe<Scalars['String']>
  itemThreeAltText?: Maybe<Scalars['String']>
  itemThreeCTAText?: Maybe<Scalars['String']>
  itemThreeDescription?: Maybe<Scalars['String']>
  itemThreeImage?: Maybe<Scalars['URL']>
  itemThreeSubtitle?: Maybe<Scalars['String']>
  itemThreeSubtitleBGColour?: Maybe<Scalars['String']>
  itemThreeTitle?: Maybe<Scalars['String']>
  itemThreeUrl?: Maybe<Scalars['String']>
  itemTwoAltText?: Maybe<Scalars['String']>
  itemTwoCTAText?: Maybe<Scalars['String']>
  itemTwoDescription?: Maybe<Scalars['String']>
  itemTwoImage?: Maybe<Scalars['URL']>
  itemTwoSubtitle?: Maybe<Scalars['String']>
  itemTwoSubtitleBGColour?: Maybe<Scalars['String']>
  itemTwoTitle?: Maybe<Scalars['String']>
  itemTwoUrl?: Maybe<Scalars['String']>
  query: Query
  title?: Maybe<Scalars['String']>
}

export type GlobalTransformationSlider = Widget & {
  __typename?: 'GlobalTransformationSlider'
  afterImageAltText?: Maybe<Scalars['String']>
  afterImageLarge?: Maybe<Scalars['URL']>
  afterImageMedium?: Maybe<Scalars['URL']>
  afterImageSmall?: Maybe<Scalars['URL']>
  beforeImageAltText?: Maybe<Scalars['String']>
  beforeImageLarge?: Maybe<Scalars['URL']>
  beforeImageMedium?: Maybe<Scalars['URL']>
  beforeImageSmall?: Maybe<Scalars['URL']>
  dividingLineColor?: Maybe<Scalars['String']>
  dragButtonPosition?: Maybe<Scalars['String']>
  dragLinePosition?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  title?: Maybe<Scalars['String']>
  titlePosition?: Maybe<Scalars['String']>
}

export type GlobalTrendingHashtagBlock = Widget & {
  __typename?: 'GlobalTrendingHashtagBlock'
  hashtag?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  url?: Maybe<Scalars['String']>
}

export type GlobalTrendingHashtagCollection = Widget & {
  __typename?: 'GlobalTrendingHashtagCollection'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
}

export type GlobalTwoBestSellers = Widget & {
  __typename?: 'GlobalTwoBestSellers'
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  ProductOne?: Maybe<ProductVariant>
  ProductOneButtonText?: Maybe<Scalars['String']>
  ProductOneNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  ProductTwo?: Maybe<ProductVariant>
  ProductTwoButtonText?: Maybe<Scalars['String']>
  ProductTwoNew?: Maybe<WidgetProductItem>
  id: Scalars['ID']
  query: Query
}

export type GlobalTwoItemEditorial = Widget & {
  __typename?: 'GlobalTwoItemEditorial'
  altItemOne?: Maybe<Scalars['String']>
  altItemTwo?: Maybe<Scalars['String']>
  id: Scalars['ID']
  itemOneCTAText?: Maybe<Scalars['String']>
  itemOneDescription?: Maybe<Scalars['String']>
  itemOneImage?: Maybe<Scalars['URL']>
  itemOneTitle?: Maybe<Scalars['String']>
  itemOneURL?: Maybe<Scalars['String']>
  itemTwoCTAText?: Maybe<Scalars['String']>
  itemTwoDescription?: Maybe<Scalars['String']>
  itemTwoImage?: Maybe<Scalars['URL']>
  itemTwoTitle?: Maybe<Scalars['String']>
  itemTwoURL?: Maybe<Scalars['String']>
  query: Query
  widgetSubtitle?: Maybe<Scalars['String']>
  widgetTitle?: Maybe<Scalars['String']>
}

export type GlobalTwoItemImageTextBlock = Widget & {
  __typename?: 'GlobalTwoItemImageTextBlock'
  backgroundColour?: Maybe<Scalars['String']>
  button2Text?: Maybe<Scalars['String']>
  button2Url?: Maybe<Scalars['String']>
  hasMargin?: Maybe<Scalars['String']>
  hasPadding?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageAnnotationText?: Maybe<Scalars['String']>
  imageProductText?: Maybe<Scalars['String']>
  imageProductURL?: Maybe<Scalars['String']>
  itemAlign?: Maybe<Scalars['String']>
  itemAlt?: Maybe<Scalars['String']>
  itemButton?: Maybe<Scalars['String']>
  itemImage?: Maybe<Scalars['URL']>
  itemImageSmall?: Maybe<Scalars['URL']>
  itemText?: Maybe<RichContent>
  itemTitle?: Maybe<Scalars['String']>
  itemURL?: Maybe<Scalars['String']>
  lightMode?: Maybe<Scalars['String']>
  query: Query
  textAlign?: Maybe<Scalars['String']>
  transcript?: Maybe<RichContent>
  videoTitle?: Maybe<Scalars['String']>
  videoUrl?: Maybe<Scalars['String']>
}

export type GlobalTwoItemImageTextCta = Widget & {
  __typename?: 'GlobalTwoItemImageTextCTA'
  backgroundColour?: Maybe<Scalars['String']>
  contentTheme?: Maybe<Scalars['String']>
  hasMargin?: Maybe<Scalars['String']>
  hasPadding?: Maybe<Scalars['String']>
  id: Scalars['ID']
  itemOneCTAText?: Maybe<Scalars['String']>
  itemOneContentAlign?: Maybe<Scalars['String']>
  itemOneDescription?: Maybe<Scalars['String']>
  itemOneImage?: Maybe<Scalars['URL']>
  itemOneImageAltText?: Maybe<Scalars['String']>
  itemOneImageSmall?: Maybe<Scalars['URL']>
  itemOneTitle?: Maybe<Scalars['String']>
  itemOneURL?: Maybe<Scalars['String']>
  itemTwoCTAText?: Maybe<Scalars['String']>
  itemTwoContentAlign?: Maybe<Scalars['String']>
  itemTwoDescription?: Maybe<Scalars['String']>
  itemTwoImage?: Maybe<Scalars['URL']>
  itemTwoImageAltText?: Maybe<Scalars['String']>
  itemTwoImageSmall?: Maybe<Scalars['URL']>
  itemTwoTitle?: Maybe<Scalars['String']>
  itemTwoURL?: Maybe<Scalars['String']>
  query: Query
}

export type GlobalTwoItemImageTextCta3070 = Widget & {
  __typename?: 'GlobalTwoItemImageTextCTA3070'
  contentTheme?: Maybe<Scalars['String']>
  id: Scalars['ID']
  itemOneCTAText?: Maybe<Scalars['String']>
  itemOneContentAlign?: Maybe<Scalars['String']>
  itemOneDescription?: Maybe<Scalars['String']>
  itemOneImage?: Maybe<Scalars['URL']>
  itemOneImageAltText?: Maybe<Scalars['String']>
  itemOneImageSmall?: Maybe<Scalars['URL']>
  itemOneTitle?: Maybe<Scalars['String']>
  itemOneURL?: Maybe<Scalars['String']>
  itemTwoCTAText?: Maybe<Scalars['String']>
  itemTwoContentAlign?: Maybe<Scalars['String']>
  itemTwoDescription?: Maybe<Scalars['String']>
  itemTwoImage?: Maybe<Scalars['URL']>
  itemTwoImageAltText?: Maybe<Scalars['String']>
  itemTwoImageSmall?: Maybe<Scalars['URL']>
  itemTwoTitle?: Maybe<Scalars['String']>
  itemTwoURL?: Maybe<Scalars['String']>
  query: Query
}

export type GlobalUgcCarousel = Widget & {
  __typename?: 'GlobalUGCCarousel'
  category?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  tag?: Maybe<Scalars['String']>
  ugcSubText?: Maybe<Scalars['String']>
  ugcTitle?: Maybe<Scalars['String']>
}

export type GlobalVideoGallery = Widget & {
  __typename?: 'GlobalVideoGallery'
  id: Scalars['ID']
  itemFiveButtonLink?: Maybe<Scalars['String']>
  itemFiveCTA?: Maybe<Scalars['String']>
  itemFiveCdnPath?: Maybe<Scalars['String']>
  itemFiveImage?: Maybe<Scalars['URL']>
  itemFiveImageAltText?: Maybe<Scalars['String']>
  itemFiveTitle?: Maybe<Scalars['String']>
  itemFiveTranscript?: Maybe<RichContent>
  itemFiveVideoURL?: Maybe<Scalars['String']>
  itemFourButtonLink?: Maybe<Scalars['String']>
  itemFourCTA?: Maybe<Scalars['String']>
  itemFourCdnPath?: Maybe<Scalars['String']>
  itemFourImage?: Maybe<Scalars['URL']>
  itemFourImageAltText?: Maybe<Scalars['String']>
  itemFourTitle?: Maybe<Scalars['String']>
  itemFourTranscript?: Maybe<RichContent>
  itemFourVideoURL?: Maybe<Scalars['String']>
  itemOneButtonLink?: Maybe<Scalars['String']>
  itemOneCTA?: Maybe<Scalars['String']>
  itemOneCdnPath?: Maybe<Scalars['String']>
  itemOneImage?: Maybe<Scalars['URL']>
  itemOneImageAltText?: Maybe<Scalars['String']>
  itemOneTitle?: Maybe<Scalars['String']>
  itemOneTranscript?: Maybe<RichContent>
  itemOneVideoURL?: Maybe<Scalars['String']>
  itemSixButtonLink?: Maybe<Scalars['String']>
  itemSixCTA?: Maybe<Scalars['String']>
  itemSixCdnPath?: Maybe<Scalars['String']>
  itemSixImage?: Maybe<Scalars['URL']>
  itemSixImageAltText?: Maybe<Scalars['String']>
  itemSixTitle?: Maybe<Scalars['String']>
  itemSixTranscript?: Maybe<RichContent>
  itemSixVideoURL?: Maybe<Scalars['String']>
  itemThreeButtonLink?: Maybe<Scalars['String']>
  itemThreeCTA?: Maybe<Scalars['String']>
  itemThreeCdnPath?: Maybe<Scalars['String']>
  itemThreeImage?: Maybe<Scalars['URL']>
  itemThreeImageAltText?: Maybe<Scalars['String']>
  itemThreeTitle?: Maybe<Scalars['String']>
  itemThreeTranscript?: Maybe<RichContent>
  itemThreeVideoURL?: Maybe<Scalars['String']>
  itemTwoButtonLink?: Maybe<Scalars['String']>
  itemTwoCTA?: Maybe<Scalars['String']>
  itemTwoCdnPath?: Maybe<Scalars['String']>
  itemTwoImage?: Maybe<Scalars['URL']>
  itemTwoImageAltText?: Maybe<Scalars['String']>
  itemTwoTitle?: Maybe<Scalars['String']>
  itemTwoTranscript?: Maybe<RichContent>
  itemTwoVideoURL?: Maybe<Scalars['String']>
  query: Query
  textAlign?: Maybe<Scalars['String']>
  textBlock?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  useTitleHeadingLevel2?: Maybe<Scalars['Boolean']>
}

export type GlobalVideoHeroBannerWidget = Widget & {
  __typename?: 'GlobalVideoHeroBannerWidget'
  VideoPreviewImageTablet?: Maybe<Scalars['URL']>
  autoplayVideo?: Maybe<Scalars['String']>
  id: Scalars['ID']
  muteVideo?: Maybe<Scalars['String']>
  query: Query
  transcript?: Maybe<RichContent>
  videoAssetPathMp4?: Maybe<Scalars['String']>
  videoAssetPathWebm?: Maybe<Scalars['String']>
  videoCaptionPathVTT?: Maybe<Scalars['URL']>
  videoHeroContentAlign?: Maybe<Scalars['String']>
  videoHeroContentBoxPosition?: Maybe<Scalars['String']>
  videoHeroContentTheme?: Maybe<Scalars['String']>
  videoHeroCtaOne?: Maybe<Scalars['String']>
  videoHeroCtaOneAriaLabel?: Maybe<Scalars['String']>
  videoHeroCtaOneURL?: Maybe<Scalars['String']>
  videoHeroCtaTwo?: Maybe<Scalars['String']>
  videoHeroCtaTwoAriaLabel?: Maybe<Scalars['String']>
  videoHeroCtaTwoURL?: Maybe<Scalars['String']>
  videoHeroHeadline?: Maybe<Scalars['String']>
  videoHeroSubtitle?: Maybe<Scalars['String']>
  videoHeroUseH1?: Maybe<Scalars['String']>
  videoPreviewImage?: Maybe<Scalars['URL']>
  videoPreviewImageMobile?: Maybe<Scalars['URL']>
  videoTitle?: Maybe<Scalars['String']>
}

export type GlobalVideoTextImageCta = Widget & {
  __typename?: 'GlobalVideoTextImageCTA'
  id: Scalars['ID']
  itemCTA?: Maybe<Scalars['String']>
  itemCTALink?: Maybe<Scalars['String']>
  itemDescription?: Maybe<Scalars['String']>
  itemImage?: Maybe<Scalars['URL']>
  itemImageLink?: Maybe<Scalars['String']>
  query: Query
  videoUrl?: Maybe<Scalars['String']>
  widgetTitle?: Maybe<Scalars['String']>
}

export type GlobalWaitListSignUpFormResponses = Widget & {
  __typename?: 'GlobalWaitListSignUpFormResponses'
  continueButtonText?: Maybe<Scalars['String']>
  errorButtonText?: Maybe<Scalars['String']>
  formResponse?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  registerButtonText?: Maybe<Scalars['String']>
  responseStartSubText?: Maybe<Scalars['String']>
  responseSubText?: Maybe<RichContent>
  responseTitle?: Maybe<Scalars['String']>
}

export type GlobalWaitListSignUpWidget = Widget & {
  __typename?: 'GlobalWaitListSignUpWidget'
  buttonText?: Maybe<Scalars['String']>
  campaignCode?: Maybe<Scalars['ID']>
  enableNameField?: Maybe<Scalars['String']>
  enableSignUp?: Maybe<Scalars['String']>
  errorReturnUrl?: Maybe<Scalars['String']>
  fullNamePlaceholderText?: Maybe<Scalars['String']>
  gdprDisclaimerText?: Maybe<Scalars['String']>
  id: Scalars['ID']
  noAccountReturnUrl?: Maybe<Scalars['String']>
  placeholderText?: Maybe<Scalars['String']>
  query: Query
  registeredReturnUrl?: Maybe<Scalars['String']>
  signUpText?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type GlobalWidgetSirius = Widget & {
  __typename?: 'GlobalWidgetSirius'
  altText?: Maybe<Scalars['String']>
  button?: Maybe<Scalars['String']>
  highlight?: Maybe<Scalars['String']>
  id: Scalars['ID']
  image?: Maybe<Scalars['URL']>
  imagePosition?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  query: Query
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  widgetSiriusAltTextVariant?: Maybe<Scalars['String']>
  widgetSiriusButtonVariant?: Maybe<Scalars['String']>
  widgetSiriusHighlightVariant?: Maybe<Scalars['String']>
  widgetSiriusImageVariant?: Maybe<Scalars['URL']>
  widgetSiriusLinkVariant?: Maybe<Scalars['String']>
  widgetSiriusSubTitleVariant?: Maybe<Scalars['String']>
  widgetSiriusTitleVariant?: Maybe<Scalars['String']>
}

export type GlobalWidgetVega = Widget & {
  __typename?: 'GlobalWidgetVega'
  altTextImg?: Maybe<Scalars['String']>
  button?: Maybe<Scalars['String']>
  container?: Maybe<Scalars['String']>
  containerBg?: Maybe<Scalars['String']>
  highlight?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageDesktop?: Maybe<Scalars['URL']>
  imageMobile?: Maybe<Scalars['URL']>
  link?: Maybe<Scalars['String']>
  query: Query
  subTitle?: Maybe<Scalars['String']>
  textAlign?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type GlobalYoutubeGalleryItem = Widget & {
  __typename?: 'GlobalYoutubeGalleryItem'
  id: Scalars['ID']
  query: Query
  title?: Maybe<Scalars['String']>
  videoId?: Maybe<Scalars['String']>
}

export type GlobalYoutubeGallerySet = Widget & {
  __typename?: 'GlobalYoutubeGallerySet'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
}

export type GravityPicture = {
  __typename?: 'GravityPicture'
  default: Scalars['String']
  alt: Scalars['String']
  sources: Array<GravityPictureSource>
}

export type GravityPictureSource = {
  __typename?: 'GravityPictureSource'
  sourceSet: Scalars['String']
  media?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type GuestCheckoutEmailInput = {
  email: Scalars['String']
  marketingConsent: MarketingConsentPreference
  marketingConsentAuditData: MarketingConsentAuditData
}

export type GuestCheckoutStartInput = {
  checkoutStartInput: CheckoutStartInput
  guestCheckoutEmailInput: GuestCheckoutEmailInput
}

export type Header = {
  __typename?: 'Header'
  navigation?: Maybe<Navigation>
  widgets?: Maybe<Array<Widget>>
}

export type HmacAppliedPlacement = {
  __typename?: 'HmacAppliedPlacement'
  onClickBeacon?: Maybe<Scalars['String']>
  onLoadBeacon?: Maybe<Scalars['String']>
  onViewBeacon?: Maybe<Scalars['String']>
  placementFormatToProducts: Array<PlacementFormatElement>
  rendering: Array<RenderingElement>
}

export type HmacAppliedProduct = {
  __typename?: 'HmacAppliedProduct'
  onBasketChangeBeacon?: Maybe<Scalars['String']>
  onClickBeacon?: Maybe<Scalars['String']>
  onLoadBeacon?: Maybe<Scalars['String']>
  onViewBeacon?: Maybe<Scalars['String']>
  onWishlistBeacon?: Maybe<Scalars['String']>
  product?: Maybe<Product>
}

export type Hyperlink = {
  __typename?: 'Hyperlink'
  noFollow?: Maybe<Scalars['Boolean']>
  /** @deprecated Field no longer supported */
  noIndex?: Maybe<Scalars['Boolean']>
  openExternally?: Maybe<Scalars['Boolean']>
  text: Scalars['DisplayString']
  url: Scalars['URL']
}

export type IconsType = {
  __typename?: 'IconsType'
  homeDelivery?: Maybe<Scalars['String']>
  storeAvailable?: Maybe<Scalars['String']>
  storeDelivery?: Maybe<Scalars['String']>
}

export type Image = {
  __typename?: 'Image'
  alt?: Maybe<Scalars['DisplayString']>
  url: Scalars['URL']
}

export type ImageSelectCard = Widget & {
  __typename?: 'imageSelectCard'
  ImageInformationText?: Maybe<Scalars['String']>
  altTextImage?: Maybe<Scalars['String']>
  altTextThumbnail?: Maybe<Scalars['String']>
  id: Scalars['ID']
  itemImageLargePath?: Maybe<Scalars['URL']>
  itemImagePath?: Maybe<Scalars['URL']>
  itemImageSmallPath?: Maybe<Scalars['URL']>
  query: Query
  thumbnailImagePath?: Maybe<Scalars['URL']>
}

export type ImageSelectSlider = Widget & {
  __typename?: 'imageSelectSlider'
  altTextLogoImage?: Maybe<Scalars['String']>
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  logoImagePath?: Maybe<Scalars['URL']>
  query: Query
  titleText?: Maybe<Scalars['String']>
}

export type ImprovedSearchBestSellers = Widget & {
  __typename?: 'improvedSearchBestSellers'
  id: Scalars['ID']
  productId1?: Maybe<Scalars['String']>
  productId2?: Maybe<Scalars['String']>
  productId3?: Maybe<Scalars['String']>
  productId4?: Maybe<Scalars['String']>
  query: Query
  widgetTitle?: Maybe<Scalars['String']>
}

export type InstantSearchCorrection = {
  __typename?: 'InstantSearchCorrection'
  correction: Scalars['DisplayString']
  highlightedSearchCorrection: Scalars['DisplayString']
}

export type InstantSearchResult = {
  __typename?: 'InstantSearchResult'
  corrections: Array<InstantSearchCorrection>
  products: Array<Product>
  suggestedSearchQueries: Array<Scalars['DisplayString']>
}

export enum InStockLocation {
  Warehouse = 'WAREHOUSE',
  Store = 'STORE',
}

export enum InteractionAwardType {
  AccountCreation = 'ACCOUNT_CREATION',
  ProfileCompletion = 'PROFILE_COMPLETION',
  Review = 'REVIEW',
}

export type KitBuilder = Widget & {
  __typename?: 'kitBuilder'
  id: Scalars['ID']
  kitBuilderDistributorId?: Maybe<Scalars['String']>
  kitBuilderKey?: Maybe<Scalars['String']>
  query: Query
}

export type LabelWithDetails = {
  __typename?: 'LabelWithDetails'
  deliveryInstructions: DeliveryInstructions
  label: FileDownload
}

export type LandingPageLinkType = {
  __typename?: 'LandingPageLinkType'
  text?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  openExternally?: Maybe<Scalars['String']>
  noFollow?: Maybe<Scalars['String']>
}

export enum LinkAccountsError {
  OriginAlreadyLinked = 'ORIGIN_ALREADY_LINKED',
  TargetAlreadyLinked = 'TARGET_ALREADY_LINKED',
  InvalidExtensionToken = 'INVALID_EXTENSION_TOKEN',
}

export type LinkAccountsInput = {
  accountExtensionToken?: Maybe<Scalars['AccountLinkingExtensionToken']>
}

/** This response will contain either the error field or the email field */
export type LinkAccountsResponse = {
  __typename?: 'LinkAccountsResponse'
  error?: Maybe<LinkAccountsError>
  email?: Maybe<Scalars['String']>
}

export type LinkedCreditAccount = {
  __typename?: 'LinkedCreditAccount'
  /** The site code, e.g. "lfint", of the linked account. */
  site: Scalars['String']
  email: Scalars['String']
  /** Always in the currency of the credit account. */
  balance: MoneyValue
}

export type LiveChatWidget = Widget & {
  __typename?: 'LiveChatWidget'
  id: Scalars['ID']
  query: Query
}

export type LocalizedString = {
  __typename?: 'LocalizedString'
  key: Scalars['String']
  location: LocalizedStringLocation
  value: Scalars['DisplayString']
}

export enum LocalizedStringLocation {
  Global = 'GLOBAL',
  Language = 'LANGUAGE',
  Locale = 'LOCALE',
  Site = 'SITE',
  SiteAndLanguage = 'SITE_AND_LANGUAGE',
  SiteAndLocale = 'SITE_AND_LOCALE',
}

export type Location = {
  __typename?: 'Location'
  displayName: Scalars['DisplayString']
  longitude: Scalars['Float']
  latitude: Scalars['Float']
  postcode: Scalars['DisplayString']
}

export type LoginAndApproveSocialLinkInput = {
  verificationToken: Scalars['String']
}

export type LoginInput = {
  password: Scalars['String']
  username: Scalars['String']
}

export type LoyaltyHubBirthdayGift = Widget & {
  __typename?: 'LoyaltyHubBirthdayGift'
  banner?: Maybe<Scalars['URL']>
  customerLists?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  formInstruction?: Maybe<Scalars['String']>
  giftActive?: Maybe<Scalars['String']>
  id: Scalars['ID']
  modalHeading?: Maybe<Scalars['String']>
  query: Query
  title?: Maybe<Scalars['String']>
  userNotification?: Maybe<Scalars['String']>
}

export type LoyaltyHubTier = Widget & {
  __typename?: 'LoyaltyHubTier'
  banner?: Maybe<Scalars['URL']>
  bannerAlt?: Maybe<Scalars['String']>
  hidden?: Maybe<Scalars['String']>
  iconType?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  rewardListText1?: Maybe<Scalars['String']>
  rewardListText2?: Maybe<Scalars['String']>
  rewardListText3?: Maybe<Scalars['String']>
  rewardListText4?: Maybe<Scalars['String']>
  rewardListText5?: Maybe<Scalars['String']>
  rewardsHeader?: Maybe<Scalars['String']>
  textPart1?: Maybe<Scalars['String']>
  textPart2?: Maybe<Scalars['String']>
  textPart3?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type LoyaltyInteractionAward = {
  __typename?: 'LoyaltyInteractionAward'
  earnablePoints: Scalars['Int']
  type: InteractionAwardType
}

export type LoyaltyRewardsList = Widget & {
  __typename?: 'loyaltyRewardsList'
  id: Scalars['ID']
  listItem1?: Maybe<Scalars['String']>
  listItem2?: Maybe<Scalars['String']>
  listItem3?: Maybe<Scalars['String']>
  listItem4?: Maybe<Scalars['String']>
  listItem5?: Maybe<Scalars['String']>
  listItem6?: Maybe<Scalars['String']>
  listItem7?: Maybe<Scalars['String']>
  listItem8?: Maybe<Scalars['String']>
  query: Query
  title?: Maybe<Scalars['String']>
}

export type LoyaltyRewardTier = Widget & {
  __typename?: 'LoyaltyRewardTier'
  id: Scalars['ID']
  query: Query
  tierListItem1?: Maybe<Scalars['String']>
  tierListItem2?: Maybe<Scalars['String']>
  tierListItem3?: Maybe<Scalars['String']>
  tierListItem4?: Maybe<Scalars['String']>
  tierListItem5?: Maybe<Scalars['String']>
  tierName?: Maybe<Scalars['String']>
  tierRange?: Maybe<Scalars['String']>
  tierType?: Maybe<Scalars['String']>
}

export type LoyaltyRewardTiers = Widget & {
  __typename?: 'LoyaltyRewardTiers'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type LoyaltyScheme = {
  __typename?: 'LoyaltyScheme'
  interactionAwards: Array<LoyaltyInteractionAward>
  /** Loyalty schemes that are not tier based will have an empty list of tiers as opposed to a single tier */
  tiers: Array<LoyaltyTier>
}

export type LoyaltyTier = {
  __typename?: 'LoyaltyTier'
  id: Scalars['ID']
  name: Scalars['String']
  rewards: Array<LoyaltyTierReward>
  threshold: Scalars['Int']
}

export type LoyaltyTierReward = {
  __typename?: 'LoyaltyTierReward'
  description: Scalars['DisplayString']
  name: Scalars['DisplayString']
  type: LoyaltyTierRewardType
}

export enum LoyaltyTierRewardType {
  BirthdayGift = 'BIRTHDAY_GIFT',
  Generic = 'GENERIC',
  SubscriptionGift = 'SUBSCRIPTION_GIFT',
}

export type LoyaltyTransaction = {
  __typename?: 'LoyaltyTransaction'
  clears?: Maybe<Scalars['Timestamp']>
  earned: Scalars['Timestamp']
  pointsEarned: Scalars['Int']
  productVariant?: Maybe<ProductVariant>
  quantity: Scalars['Int']
}

export type LoyaltyWelcomeMessage = Widget & {
  __typename?: 'loyaltyWelcomeMessage'
  accountCreationTextPart1?: Maybe<Scalars['String']>
  accountCreationTextPart2?: Maybe<Scalars['String']>
  heading?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageAltText?: Maybe<Scalars['String']>
  imagePath?: Maybe<Scalars['URL']>
  profileCompletionTextPart1?: Maybe<Scalars['String']>
  profileCompletionTextPart2?: Maybe<Scalars['String']>
  query: Query
  subHeading?: Maybe<Scalars['String']>
}

export type MailingList = Widget & {
  __typename?: 'MailingList'
  buttonText?: Maybe<Scalars['String']>
  campaignCode?: Maybe<Scalars['ID']>
  dropdownAnswer1?: Maybe<Scalars['String']>
  dropdownAnswer10?: Maybe<Scalars['String']>
  dropdownAnswer2?: Maybe<Scalars['String']>
  dropdownAnswer3?: Maybe<Scalars['String']>
  dropdownAnswer4?: Maybe<Scalars['String']>
  dropdownAnswer5?: Maybe<Scalars['String']>
  dropdownAnswer6?: Maybe<Scalars['String']>
  dropdownAnswer7?: Maybe<Scalars['String']>
  dropdownAnswer8?: Maybe<Scalars['String']>
  dropdownAnswer9?: Maybe<Scalars['String']>
  dropdownAnswerPlaceholder?: Maybe<Scalars['String']>
  dropdownAnswerQuestionText?: Maybe<Scalars['String']>
  emailLabelText?: Maybe<Scalars['String']>
  emailPlaceholderText?: Maybe<Scalars['String']>
  enableDropdownAnswerField?: Maybe<Scalars['String']>
  enableNameField?: Maybe<Scalars['String']>
  enableSignUp?: Maybe<Scalars['String']>
  enablefreeAnswerField?: Maybe<Scalars['String']>
  errorReturnUrl?: Maybe<Scalars['String']>
  freeAnswerQuestionText?: Maybe<Scalars['String']>
  fullNameLabelText?: Maybe<Scalars['String']>
  fullNamePlaceholderText?: Maybe<Scalars['String']>
  gdprDisclaimerText?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  registeredReturnUrl?: Maybe<Scalars['String']>
  signUpText?: Maybe<Scalars['String']>
  widgetSubtitle?: Maybe<Scalars['String']>
  widgetTitle?: Maybe<Scalars['String']>
}

export type MarkDiscussionMessagesAsReadInput = {
  discussionId: Scalars['ID']
  upToMessageId: Scalars['ID']
}

export type MarketedSpecialOfferType = {
  __typename?: 'MarketedSpecialOfferType'
  onlyDisplayOfferBadge?: Maybe<Scalars['String']>
  shouldRemoveScrollLock?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  landingPageLink?: Maybe<LandingPageLinkType>
}

export enum MarketingConsent {
  DoesNotReceiveMarketingMaterial = 'DOES_NOT_RECEIVE_MARKETING_MATERIAL',
  ReceivesMarketingMaterial = 'RECEIVES_MARKETING_MATERIAL',
}

export type MarketingConsentAuditData = {
  /** An identifier of the form being submitted */
  formIdentifier: Scalars['String']
  /** A string that identifies where on site the opt-in was displayed, such as a URL */
  formLocation: Scalars['String']
  /** The message shown to the user for marketing consent, either as plaintext, or HTML. */
  messageShown: Scalars['String']
}

export enum MarketingConsentPreference {
  IConsentToReceivingMarketingMaterial = 'I_CONSENT_TO_RECEIVING_MARKETING_MATERIAL',
  IDoNotConsentToReceivingMarketingMaterial = 'I_DO_NOT_CONSENT_TO_RECEIVING_MARKETING_MATERIAL',
  KeepExistingMarketingPreferences = 'KEEP_EXISTING_MARKETING_PREFERENCES',
}

export type MarketingPreferences = {
  __typename?: 'MarketingPreferences'
  marketingConsent: MarketingConsent
  registered: Scalars['Boolean']
  type: MarketingType
}

export type MarketingPreferencesInput = {
  type: MarketingType
  username: Scalars['String']
}

export enum MarketingType {
  Email = 'EMAIL',
  Sms = 'SMS',
}

export type MoneyValue = {
  __typename?: 'MoneyValue'
  /** A string containing a decimal number, to avoid rounding and precision issues */
  amount: Scalars['String']
  currency: Currency
  /** A fully formatted string for displaying this amount of money in HTML. e.g "&#163;76.49" */
  displayValue: Scalars['String']
  scalarValue: Scalars['Money']
}

export type MultiCategoryCardSet = Widget & {
  __typename?: 'multiCategoryCardSet'
  backgroundColour?: Maybe<Scalars['String']>
  banners?: Maybe<Array<Maybe<Widget>>>
  columnsNumber?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  multiCategoryCardSet_CTAText?: Maybe<Scalars['String']>
  query: Query
  widgetSubtitle?: Maybe<Scalars['String']>
  widgetTitle?: Maybe<Scalars['String']>
}

export type MultiCategoryCardSetV2 = Widget & {
  __typename?: 'multiCategoryCardSetV2'
  alternativeItemCardDesign?: Maybe<Scalars['Boolean']>
  backgroundColour?: Maybe<Scalars['String']>
  banners?: Maybe<Array<Maybe<Widget>>>
  columnsNumber?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  marginStyle?: Maybe<Scalars['String']>
  mobileColumns?: Maybe<Scalars['Int']>
  query: Query
  scrollable?: Maybe<Scalars['Boolean']>
  stackCardsOnMobile?: Maybe<Scalars['Boolean']>
  theme?: Maybe<Scalars['String']>
  widgetSubtitle?: Maybe<Scalars['String']>
  widgetTitle?: Maybe<Scalars['String']>
}

export type MultipleCtaBanner = Widget & {
  __typename?: 'MultipleCTABanner'
  altImageLarge?: Maybe<Scalars['String']>
  altLogoPng?: Maybe<Scalars['String']>
  bannerURL?: Maybe<Scalars['String']>
  contentBoxPosition?: Maybe<Scalars['String']>
  contentTheme?: Maybe<Scalars['String']>
  ctaOne?: Maybe<Scalars['String']>
  ctaOneURL?: Maybe<Scalars['String']>
  ctaTwo?: Maybe<Scalars['String']>
  ctaTwoURL?: Maybe<Scalars['String']>
  headline?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageLarge?: Maybe<Scalars['URL']>
  imageMedium?: Maybe<Scalars['URL']>
  imageSmall?: Maybe<Scalars['URL']>
  logopngImageBG?: Maybe<Scalars['URL']>
  query: Query
  subtitle?: Maybe<Scalars['String']>
}

export type Mutation = {
  __typename?: 'Mutation'
  /**
   * Adds a new address based on the given input.
   * Requires Authentication.
   */
  addAddress: Scalars['ID']
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   * @deprecated Use addProductsToBasket instead
   */
  addProductToBasket: Basket
  addProductToBasketWithSubscriptionContract: Basket
  /** @deprecated Use addProductToWaitlistByType instead. */
  addProductToWaitlist?: Maybe<AddToWaitlistResponse>
  addProductToWaitlistByType?: Maybe<AddToWaitlistResponse>
  addProductToWishlist?: Maybe<Scalars['Boolean']>
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  addProductsToBasket: Basket
  addReview?: Maybe<AddReviewResponse>
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  addSelectYourSampleProductToBasket: Basket
  addSubscriptionProducts?: Maybe<Scalars['Boolean']>
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  applyCodeToBasket: Basket
  approveSocialLink?: Maybe<Scalars['Boolean']>
  /** Cancels an entire order, if it is cancellable. */
  cancelOrder?: Maybe<Scalars['Void']>
  /** Partially cancels an order. Cannot be used to cancel OrderProducts which are in a special offer group. */
  cancelOrderProducts?: Maybe<Scalars['Void']>
  /** Partially cancels an order, cancelling entire special offer groups. */
  cancelOrderSpecialOfferGroups?: Maybe<Scalars['Void']>
  cancelReturn?: Maybe<Scalars['Void']>
  cancelSubscription?: Maybe<Scalars['Void']>
  updateSubscriptionAutoRenewalOptInType?: Maybe<Scalars['Void']>
  cancelSubscriptionDelay?: Maybe<Scalars['Void']>
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  changeFulfilmentType: Basket
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   * Provide the item id of the product to be switched to subscribe and save and it will be changed in the basket.
   * The toSubscription input field is used to specify the direction in which to
   * switch. It should be true when switching a normal product to subscribe and
   * save and false in the opposite case.
   * If the purpose of the call is to change from a subscribe and save product to a
   * normal product, this mutation can be called without the contractId input.
   */
  changeProductSubscriptionContractInBasket: Basket
  check?: Maybe<Scalars['String']>
  checkout?: Maybe<CheckoutStartResponse>
  /**
   * Requires authentication
   * Returns the id of the newly created messaged
   * New discussions
   */
  createDiscussion: Scalars['ID']
  delaySubscription?: Maybe<Scalars['Void']>
  /**
   * Deletes the address with the given ID if it exists.
   * Requires Authentication.
   */
  deleteAddress?: Maybe<Scalars['Void']>
  deletePaymentCard?: Maybe<Scalars['Void']>
  deleteProfileSubmission?: Maybe<Scalars['Void']>
  /** If a null basketId is provided, this will fall back to the logged in customer's saved basket. */
  emptyBasket: Basket
  /**
   * Causes a PasswordResetToken to be sent to the user's email address, if an account exists with the given username.
   *
   * The PasswordResetToken from the email can be used with the resetPassword mutation.
   */
  forgottenPassword?: Maybe<ForgottenPasswordResponse>
  generateAndAddRewardCard: GenerateAndAddRewardCardResponse
  /**
   * The second stage of the account linking process.
   *
   * This should be called on the target site while logged in, with the AccountLinkingToken returned from
   * `getAccountLinkingToken`, which was called on the origin site.
   */
  getAccountExtensionToken?: Maybe<GetAccountExtensionTokenResponse>
  /**
   * The first stage of the account linking process.
   *
   * This should be called on the origin site while logged in.
   */
  getAccountLinkingToken?: Maybe<GetAccountLinkingTokenResponse>
  guestCheckout?: Maybe<CheckoutStartResponse>
  guestCheckoutWithoutEmail?: Maybe<CheckoutStartResponse>
  /** Perform an impersonate login (e.g. for Customer Services agents) using a token previously obtained by other means. */
  impersonateLogin?: Maybe<AuthenticationResponse>
  /**
   * The third stage of the account linking process.
   *
   * This should be called on the origin site while logged in, with the AccountLinkingExtensionToken returned from
   * `getAccountExtensionToken`, which was called on the target site.
   *
   * This will complete the account linking.
   */
  linkAccounts?: Maybe<LinkAccountsResponse>
  login?: Maybe<AuthenticationResponse>
  loginAndApproveSocialLink?: Maybe<AuthenticationResponse>
  logout?: Maybe<Scalars['Void']>
  /**
   * Requires authentication
   * If there are newer messages than the provided message ID, the discussion stays unread, preventing an unseen
   * message from being unknowingly marked as read
   */
  markDiscussionMessagesAsRead?: Maybe<Scalars['Void']>
  /**
   * Registration forms can be dynamic. To get the exact fields and options
   * required to register for a site, query the forms API with
   * the identifier ACCOUNT_CREATION. If this doesn't exist, the fields are static and visible in the RegistrationInput type.
   */
  register: AuthenticationResponse
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  removeCodeFromBasket: Basket
  removeDefaultBillingAddress?: Maybe<Scalars['Void']>
  removeDefaultDeliveryAddress?: Maybe<Scalars['Void']>
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  removeProductFromBasket: Basket
  removeProductFromWaitlistByType?: Maybe<Scalars['Boolean']>
  removeProductFromWishlist?: Maybe<Scalars['Boolean']>
  removeRewardCard: RemoveRewardCardResponse
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  removeSelectYourSampleProductFromBasket: Basket
  removeSocialLink?: Maybe<Scalars['Boolean']>
  removeSubscriptionProducts?: Maybe<Scalars['Boolean']>
  /**
   * Deletes the address with the given ID if it exists.
   * And adds a new address based on the given input and returns the ID of the new address.
   * This does not update the address with the given ID.
   * Requires Authentication.
   */
  replaceAddress: Scalars['ID']
  /**
   * Requires authentication
   * Returns the id of the newly created message
   */
  replyToDiscussion: Scalars['ID']
  reportReview?: Maybe<ReviewActionResult>
  /**
   * Causes a PasswordResetToken to be sent to the user's email address.  The email's wording is different, as this is
   * intended to be used for accounts which do not already have a password (such as Wechat social accounts)
   *
   * The PasswordResetToken from the email can be used with the resetPassword mutation.
   */
  requestSetPasswordEmail?: Maybe<RequestSetPasswordEmailResponse>
  /** The following endpoints handle the approving of a link between a Social User and a Customer on this site. */
  requestSocialLinkVerificationEmail?: Maybe<
    RequestSocialLinkVerificationEmailResponse
  >
  resetPassword?: Maybe<AuthenticationResponse>
  resolveOrderPaymentProblem?: Maybe<CheckoutStartResponse>
  resolveSubscriptionPaymentProblem?: Maybe<CheckoutStartResponse>
  sendReferralEmail?: Maybe<Scalars['Void']>
  setDefaultBillingAddress?: Maybe<Scalars['Void']>
  setDefaultDeliveryAddress?: Maybe<Scalars['Void']>
  signUpForEmailMarketingCampaign?: Maybe<Scalars['Void']>
  /** Sign up for email or SMS marketing without the requirement to be logged in */
  signUpForMarketing?: Maybe<SignUpResult>
  signUpForPushNotifications?: Maybe<PushSubscriptionResponse>
  /**
   * Attempts to log you into the website using the SocialAuthenticationToken returned from Social Login Service
   * or the token returned from a previous attempt to log in.
   *
   * socialAuthenticationToken and missingInformation should be considered deprecated andwill be removed in due course.
   * Please only submit the SocialLoginInput object
   */
  socialLogin?: Maybe<SocialAuthenticationResponse>
  submitProfile?: Maybe<FormSubmissionResponse>
  submitSurvey?: Maybe<FormSubmissionResponse>
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   * Provide the item id of the product to be supersized (must be supersizable) and
   * it will be added to the basket and the current product removed.
   */
  supersizeProductInBasket: Basket
  swapSubscriptionProducts?: Maybe<Scalars['Boolean']>
  testCheckout?: Maybe<Scalars['String']>
  testGuestCheckout?: Maybe<Scalars['String']>
  testGuestCheckoutWithoutEmail?: Maybe<Scalars['String']>
  unlinkAccounts?: Maybe<Scalars['Boolean']>
  unsubscribeMarketing?: Maybe<Scalars['Void']>
  unsubscribeSmsMarketing?: Maybe<Scalars['Boolean']>
  /** See the Form from the `accountSettingsForm` query */
  updateAccountSettings: AccountUpdateStatus
  /** @deprecated Use updateAccountSettings instead */
  updateDateOfBirth: AccountUpdateStatus
  updateEmailAddress: AccountUpdateStatus
  updateLoyaltyOptIn: AccountUpdateStatus
  updateMarketingPreferences?: Maybe<UpdateMarketingPreferencesResponse>
  /** @deprecated Use updateAccountSettings instead */
  updateMobilePhoneNumber: AccountUpdateStatus
  /** @deprecated Use updateAccountSettings instead */
  updateName: AccountUpdateStatus
  updatePassword: PasswordUpdateStatus
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  updateProductQuantityInBasket: Basket
  updateSubscriptionAddress?: Maybe<Scalars['Void']>
  updateSubscriptionBillingDate?: Maybe<Scalars['Boolean']>
  updateSubscriptionFrequency?: Maybe<Scalars['Void']>
  updateSubscriptionPaymentMethod?: Maybe<CheckoutStartResponse>
  verifyAndAddRewardCard: VerifyAndAddRewardCardResponse
  voteReviewNegative?: Maybe<ReviewActionResult>
  voteReviewPositive?: Maybe<ReviewActionResult>
}

export type MutationAddAddressArgs = {
  input: AddressInput
}

export type MutationAddProductToBasketArgs = {
  basketId?: Maybe<Scalars['ID']>
  fulfilmentInput: BasketAddFulfilmentInput
  quantity: Scalars['Int']
  settings: SessionSettings
  sku: Scalars['SKU']
}

export type MutationAddProductToBasketWithSubscriptionContractArgs = {
  basketId?: Maybe<Scalars['ID']>
  contractId: Scalars['ID']
  quantity: Scalars['Int']
  settings: SessionSettings
  sku: Scalars['SKU']
}
export type MutationAddProductToWaitlistArgs = {
  email: Scalars['String']
  marketingConsentAuditData?: Maybe<MarketingConsentAuditData>
  sku: Scalars['SKU']
}

export type MutationAddProductToWaitlistByTypeArgs = {
  input: AddProductWaitlistInput
}

export type MutationAddProductToWishlistArgs = {
  sku: Scalars['SKU']
}

export type MutationAddProductsToBasketArgs = {
  basketId?: Maybe<Scalars['ID']>
  fulfilmentInput: BasketAddFulfilmentInput
  items: Array<AddProductToBasketItem>
  settings: SessionSettings
}

export type MutationAddReviewArgs = {
  input: AddReviewInput
}

export type MutationAddSelectYourSampleProductToBasketArgs = {
  basketId?: Maybe<Scalars['ID']>
  selectYourSampleId: Scalars['ID']
  settings: SessionSettings
  sku?: Maybe<Scalars['SKU']>
  tierId: Scalars['ID']
}

export type MutationAddSubscriptionProductsArgs = {
  addOns: Array<ProductAddOnInput>
  subscriptionId: Scalars['ID']
}

export type MutationApplyCodeToBasketArgs = {
  basketId?: Maybe<Scalars['ID']>
  code: Scalars['String']
  settings: SessionSettings
}

export type MutationApproveSocialLinkArgs = {
  input?: Maybe<ApproveSocialLinkInput>
}

export type MutationCancelOrderArgs = {
  input: CancelOrderInput
}

export type MutationCancelOrderProductsArgs = {
  input: CancelOrderProductsInput
}

export type MutationCancelOrderSpecialOfferGroupsArgs = {
  input: CancelOrderSpecialOfferGroupsInput
}

export type MutationCancelReturnArgs = {
  returnNumber: Scalars['Int']
}

export type MutationCancelSubscriptionArgs = {
  id: Scalars['ID']
}

export type MutationUpdateSubscriptionAutoRenewalOptInTypeArgs = {
  id: Scalars['ID']
  optInTypeInput: SubscriptionAutoRenewalOptInTypeInput
}

export type MutationCancelSubscriptionDelayArgs = {
  id: Scalars['ID']
}

export type MutationChangeFulfilmentTypeArgs = {
  basketId?: Maybe<Scalars['ID']>
  itemId: Scalars['ID']
  settings: SessionSettings
  fulfilmentInput: BasketAddFulfilmentInput
}

export type MutationChangeProductSubscriptionContractInBasketArgs = {
  basketId?: Maybe<Scalars['ID']>
  contractId?: Maybe<Scalars['ID']>
  settings: SessionSettings
  sku: Scalars['SKU']
  toSubscription: Scalars['Boolean']
}

export type MutationCheckoutArgs = {
  input: CheckoutStartInput
}

export type MutationCreateDiscussionArgs = {
  input: CreateDiscussionInput
}

export type MutationDelaySubscriptionArgs = {
  id: Scalars['ID']
}

export type MutationDeleteAddressArgs = {
  id: Scalars['ID']
}

export type MutationDeletePaymentCardArgs = {
  cardId: Scalars['ID']
}

export type MutationDeleteProfileSubmissionArgs = {
  submissionId: Scalars['ID']
}

export type MutationEmptyBasketArgs = {
  basketId?: Maybe<Scalars['ID']>
  settings: SessionSettings
}

export type MutationForgottenPasswordArgs = {
  input: ForgottenPasswordInput
}

export type MutationGetAccountExtensionTokenArgs = {
  input: GetAccountExtensionTokenInput
}

export type MutationGuestCheckoutArgs = {
  input: GuestCheckoutStartInput
}

export type MutationGuestCheckoutWithoutEmailArgs = {
  input: CheckoutStartInput
}

export type MutationImpersonateLoginArgs = {
  impersonationToken: Scalars['String']
}

export type MutationLinkAccountsArgs = {
  input: LinkAccountsInput
}

export type MutationLoginArgs = {
  input: LoginInput
}

export type MutationLoginAndApproveSocialLinkArgs = {
  input: LoginAndApproveSocialLinkInput
}

export type MutationMarkDiscussionMessagesAsReadArgs = {
  input?: Maybe<MarkDiscussionMessagesAsReadInput>
}

export type MutationRegisterArgs = {
  input: RegistrationInput
}

export type MutationRemoveCodeFromBasketArgs = {
  basketId?: Maybe<Scalars['ID']>
  settings: SessionSettings
}

export type MutationRemoveProductFromBasketArgs = {
  basketId?: Maybe<Scalars['ID']>
  itemId: Scalars['ID']
  settings: SessionSettings
}

export type MutationRemoveProductFromWaitlistByTypeArgs = {
  input: ProductWaitlistInput
}

export type MutationRemoveProductFromWishlistArgs = {
  sku: Scalars['SKU']
}

export type MutationRemoveSelectYourSampleProductFromBasketArgs = {
  basketId?: Maybe<Scalars['ID']>
  selectYourSampleId: Scalars['ID']
  settings: SessionSettings
  sku?: Maybe<Scalars['SKU']>
  tierId: Scalars['ID']
}

export type MutationRemoveSocialLinkArgs = {
  input?: Maybe<RemoveSocialLinkInput>
}

export type MutationRemoveSubscriptionProductsArgs = {
  removals: Array<Scalars['SKU']>
  subscriptionId: Scalars['ID']
}

export type MutationReplaceAddressArgs = {
  input: ReplaceAddressInput
}

export type MutationReplyToDiscussionArgs = {
  discussionId: Scalars['ID']
  input: AddDiscussionMessageInput
}

export type MutationReportReviewArgs = {
  input: ReviewVotingInput
}

export type MutationRequestSetPasswordEmailArgs = {
  input: RequestSetPasswordEmailInput
}

export type MutationRequestSocialLinkVerificationEmailArgs = {
  input: RequestSocialLinkVerificationEmailInput
}

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput
}

export type MutationResolveOrderPaymentProblemArgs = {
  orderNumber: Scalars['OrderNumber']
}

export type MutationResolveSubscriptionPaymentProblemArgs = {
  subscriptionId: Scalars['ID']
}

export type MutationSendReferralEmailArgs = {
  emailAddresses: Array<Scalars['String']>
}

export type MutationSetDefaultBillingAddressArgs = {
  id: Scalars['ID']
}

export type MutationSetDefaultDeliveryAddressArgs = {
  id: Scalars['ID']
}

export type MutationSignUpForEmailMarketingCampaignArgs = {
  input: SignUpForEmailMarketingCampaignInput
}

export type MutationSignUpForMarketingArgs = {
  input: SignUpForMarketingInput
}

export type MutationSignUpForPushNotificationsArgs = {
  input: PushSubscriptionInput
}

export type MutationSocialLoginArgs = {
  input?: Maybe<SocialLoginInput>
  missingInformation?: Maybe<SocialLoginMissingInformation>
  socialAuthenticationToken?: Maybe<Scalars['SocialAuthenticationToken']>
}

export type MutationSubmitProfileArgs = {
  input: FormSubmissionInput
}

export type MutationSubmitSurveyArgs = {
  input: FormSubmissionInput
}

export type MutationSupersizeProductInBasketArgs = {
  basketId?: Maybe<Scalars['ID']>
  itemId: Scalars['ID']
  settings: SessionSettings
}

export type MutationSwapSubscriptionProductsArgs = {
  subscriptionId: Scalars['ID']
  swaps: Array<ProductSwapInput>
}

export type MutationTestCheckoutArgs = {
  input: TestCheckoutStartInput
}

export type MutationTestGuestCheckoutArgs = {
  input: TestGuestCheckoutStartInput
}

export type MutationTestGuestCheckoutWithoutEmailArgs = {
  input: TestCheckoutStartInput
}

export type MutationUnsubscribeMarketingArgs = {
  input: TokenMarketingInput
}

export type MutationUnsubscribeSmsMarketingArgs = {
  phoneNumber: Scalars['String']
}

export type MutationUpdateAccountSettingsArgs = {
  input: Array<SettingsFieldInput>
}

export type MutationUpdateDateOfBirthArgs = {
  changes: UpdateDateOfBirthInput
}

export type MutationUpdateEmailAddressArgs = {
  changes: UpdateEmailAddressInput
}

export type MutationUpdateLoyaltyOptInArgs = {
  newValue: Scalars['Boolean']
}

export type MutationUpdateMarketingPreferencesArgs = {
  input: UpdateMarketingPreferencesInput
}

export type MutationUpdateMobilePhoneNumberArgs = {
  changes: UpdateMobilePhoneNumberInput
}

export type MutationUpdateNameArgs = {
  changes: UpdateNameInput
}

export type MutationUpdatePasswordArgs = {
  changes: UpdatePasswordInput
}

export type MutationUpdateProductQuantityInBasketArgs = {
  basketId?: Maybe<Scalars['ID']>
  itemId: Scalars['ID']
  quantity: Scalars['Int']
  settings: SessionSettings
}

export type MutationUpdateSubscriptionAddressArgs = {
  addressId: Scalars['ID']
  subscriptionId: Scalars['ID']
}

export type MutationUpdateSubscriptionBillingDateArgs = {
  newBillingDate: Scalars['Date']
  subscriptionId: Scalars['ID']
}

export type MutationUpdateSubscriptionFrequencyArgs = {
  newFrequency: SubscriptionFrequencyDurationInput
  subscriptionId: Scalars['ID']
}

export type MutationUpdateSubscriptionPaymentMethodArgs = {
  subscriptionId: Scalars['ID']
}

export type MutationVerifyAndAddRewardCardArgs = {
  rewardCardNumber: Scalars['String']
}

export type MutationVoteReviewNegativeArgs = {
  input: ReviewVotingInput
}

export type MutationVoteReviewPositiveArgs = {
  input: ReviewVotingInput
}

export type Navigation = {
  __typename?: 'Navigation'
  topLevel: Array<NavigationItem>
}

export type NavigationItem = {
  __typename?: 'NavigationItem'
  displayName: Scalars['DisplayString']
  iconName?: Maybe<Scalars['String']>
  image?: Maybe<Image>
  link?: Maybe<Hyperlink>
  subNavigation?: Maybe<Array<NavigationItem>>
  /** Null if only a heading */
  type: NavigationItemType
}

export enum NavigationItemType {
  /**
   * This navigation item can only appear at the top level.  This navigation item can have no
   * children.  When hovered over in the top-level nav,  this item should cause a new menu to
   * appear below it.  This menu contains a brand selector.  This contains a list of brands in
   * alphabetical order, each acting as a link to the brand's landing page.  At the top of the menu
   * should be buttons for each letter of the alphabet, with the menu only showing brands that begin
   * with the selected letter.
   *
   * The brands can be accessed via the `brands` query.
   *
   * For an example, see the documentation
   */
  Brands = 'BRANDS',
  /**
   * This navigation item can only appear at the second level, and only on the alternative navigation
   * display.  The detached navigation type is used where a separate section of the navigation tree
   * breaks off from the core tree at the second level, appearing to the right of its sibling items,
   * creating its own navigation tree.
   *
   * For an example, see the documentation
   */
  Detached = 'DETACHED',
  /**
   * This navigation item can only appear at the second level.  This type is used where a separate
   * section of the navigation tree breaks off from an existing TEXT tree.  This navigation item
   * appears to the left of all of the other sibling items.  This item is rendered as a list of its
   * children, with no header.
   *
   * For an example, see the documentation
   */
  Featured = 'FEATURED',
  /**
   * This navigation item can only appear at the top level.  The children of this navigation item
   * should each be displayed as a box with a large central image, with bold text below it.
   *
   * For an example, see the documentation
   */
  ImageCard = 'IMAGE_CARD',
  /**
   * Depending on platform and level, render as either a simple text link, a list of links containing
   * its children, a top-level button which causes its children to appear below when hovered over, or
   * a button which causes the children to open over the current nav as a new panel when tapped.
   *
   * For examples of each of these states, see the documentation.
   */
  Text = 'TEXT',
  /**
   * This navigation item can only appear at the top level.  The children of this navigation item
   * should each be displayed as a box with bold text in it.  On mobile apps, this should render the
   * same as TEXT.
   *
   * For an example, see the documentation.
   */
  TextCard = 'TEXT_CARD',
  /**
   * This navigation item can only appear at the top level.  The children of this navigation item
   * should each be displayed as a box with bold text in it, with an image to the left, if one is
   * provided.  If no image is provided, this should render the same as TEXT_CARD.
   *
   * For an example, see the documentation.
   */
  ThumbnailCard = 'THUMBNAIL_CARD',
  /**
   * This navigation item signifies that at least one of the children of this item will be of type DETACHED.
   * This allows the client to render a different view without needing to traverse all children of a top
   * level item to check for a child of type DETACHED to know whether to render a special view that
   * supports the detached sections.
   *
   * For an example, see the documentation
   */
  Waterfall = 'WATERFALL',
}

export type ObsessVr = Widget & {
  __typename?: 'obsessVR'
  id: Scalars['ID']
  ingetgrityHash?: Maybe<Scalars['String']>
  obsessVRclient?: Maybe<Scalars['String']>
  obsessVRhideLogo?: Maybe<Scalars['Boolean']>
  obsessVRstore?: Maybe<Scalars['String']>
  query: Query
  storeId?: Maybe<Scalars['String']>
}

export type OptInForm = {
  __typename?: 'OptInForm'
  showForm: Scalars['Boolean']
}

/** A single choice for a single option, such as "Chocolate" for the "Flavour" option. */
export type OptionChoice = {
  __typename?: 'OptionChoice'
  colour?: Maybe<Scalars['Colour']>
  key: Scalars['String']
  optionKey: Scalars['String']
  title: Scalars['String']
}

export type Order = {
  __typename?: 'Order'
  cancellable: Scalars['Boolean']
  /** This is when the order is created and the payment taken */
  createdAt: Scalars['Timestamp']
  deliveryAddress?: Maybe<Address>
  deliveryCost?: Maybe<MoneyValue>
  denialOfReceiptForm: Scalars['URL']
  discounts?: Maybe<Array<OrderDiscount>>
  /**
   * Returns a paginated list of discussions associated with this order.
   *
   * Equivalent to querying Customer.discussions with an order number filter
   */
  discussions?: Maybe<Discussions>
  /** If the order has been dispatched, this is the dispatched time */
  dispatchedAt?: Maybe<Scalars['Timestamp']>
  eligibleForSelfServiceDenialOfReceipt?: Maybe<Scalars['Boolean']>
  /** If no input provided, all skus in the product will be used */
  isReturnable?: Maybe<ReturnsEligibilityResult>
  orderNumber: Scalars['OrderNumber']
  /** @deprecated Use usedPaymentMethods -> paymentCard instead. */
  paymentCard?: Maybe<PaymentCard>
  /** @deprecated Use usedPaymentMethods -> paymentType instead. */
  paymentType?: Maybe<Scalars['String']>
  products: Array<OrderProduct>
  /** If no input provided, all skus in the product will be used */
  returnUrl?: Maybe<Scalars['URL']>
  status: OrderStatus
  totalCost: MoneyValue
  totalQuantity: Scalars['Int']
  usedPaymentMethods: Array<UsedPaymentMethod>
}

export type OrderDiscussionsArgs = {
  limit?: Scalars['Int']
  offset?: Scalars['Int']
}

export type OrderIsReturnableArgs = {
  input?: Maybe<ReturnsEligibilityInput>
}

export type OrderReturnUrlArgs = {
  input?: Maybe<ReturnUrlInput>
}

export enum OrderCancellationReason {
  AmendmentsNeeded = 'AMENDMENTS_NEEDED',
  DiscountCodeIssue = 'DISCOUNT_CODE_ISSUE',
  DuplicateOrder = 'DUPLICATE_ORDER',
  FoundCheaperElsewhere = 'FOUND_CHEAPER_ELSEWHERE',
  NoLongerRequired = 'NO_LONGER_REQUIRED',
  OrderedWrongItem = 'ORDERED_WRONG_ITEM',
  WaitedTooLong = 'WAITED_TOO_LONG',
}

export type OrderDiscount = {
  __typename?: 'OrderDiscount'
  amount: MoneyValue
  message?: Maybe<Scalars['DisplayString']>
}

export type OrderProduct = {
  __typename?: 'OrderProduct'
  cancellable: Scalars['Boolean']
  cancellableQuantity?: Maybe<Scalars['Int']>
  cancelledQuantity?: Maybe<Scalars['Int']>
  costPerUnit?: Maybe<MoneyValue>
  deliveryDateRange?: Maybe<DateRange>
  deliveryMethod?: Maybe<Scalars['DeliveryMethod']>
  /** For order products which are not yet dispatched, this is the estimated date */
  dispatchDate?: Maybe<Scalars['Date']>
  dispatchedQuantity?: Maybe<Scalars['Int']>
  fulfilmentMethod: FulfilmentMethod
  /** The named-day delivery date as entered on checkout */
  namedDeliveryDate?: Maybe<Scalars['Date']>
  pendingCancelQuantity?: Maybe<Scalars['Int']>
  pendingRefundQuantity?: Maybe<Scalars['Int']>
  pendingReplaceQuantity?: Maybe<Scalars['Int']>
  productVariant?: Maybe<ProductVariant>
  /**
   * The total requested quantity of this product in the order.
   *
   * This is unaffected by the status of the individual items (such as cancellation or refunds)
   */
  quantity?: Maybe<Scalars['Int']>
  refundedQuantity?: Maybe<Scalars['Int']>
  replacementQuantity?: Maybe<Scalars['Int']>
  /** If the product is no longer visible, sku will be non-null while product is null */
  sku: Scalars['SKU']
  /**
   * Products that are in a special offer group can only be cancelled as part of that group, and should be displayed
   * together in the cancellation UI
   */
  specialOfferGroup?: Maybe<Scalars['SpecialOfferGroup']>
  status?: Maybe<OrderStatus>
  store?: Maybe<Store>
  trackingUrls?: Maybe<Array<Scalars['URL']>>
}

export enum OrderQueryStatusFilter {
  /** The order has reached a terminal state, i.e. it has been dispatched or cancelled. */
  Completed = 'COMPLETED',
  /** The order has been dispatched (currently the final state). Can be used to show all successful orders that are completed. */
  Dispatched = 'DISPATCHED',
  /** The order has not reached a terminal state, i.e. processing, payment problem etc. */
  Outstanding = 'OUTSTANDING',
}

export type Orders = {
  __typename?: 'Orders'
  hasMore: Scalars['Boolean']
  orders: Array<Order>
  total?: Maybe<Scalars['Int']>
}

export type SubscriptionAutoRenewal = {
  __typename?: 'SubscriptionAutoRenewal'
  nextContractRenewalDate?: Maybe<Scalars['Date']>
  autoRenewalOverride?: Maybe<SubscriptionAutoRenewalOverride>
}

export type SubscriptionAutoRenewalOverride = {
  __typename?: 'SubscriptionAutoRenewalOverride'
  shouldContinueWithMonthlyRollingContractUnlessOptedIn: Scalars['Boolean']
  optInType: Scalars['String']
}

export type OrdersFilterInput = {
  orderNumber?: Maybe<Scalars['OrderNumber']>
  status?: Maybe<OrderQueryStatusFilter>
}

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Collected = 'COLLECTED',
  Dispatched = 'DISPATCHED',
  OrderPlaced = 'ORDER_PLACED',
  PaymentProblem = 'PAYMENT_PROBLEM',
  Processing = 'PROCESSING',
  ReadyToCollect = 'READY_TO_COLLECT',
  PurchasedInStore = 'PURCHASED_IN_STORE',
}

export type Outfit = {
  __typename?: 'Outfit'
  id: Scalars['Int']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  publishedAt: Scalars['Timestamp']
  images: Array<EntityImage>
  products: Array<Product>
  designers: Array<Designer>
  stories: Array<Story>
  brands: Array<BrandEntity>
  fullBrands: Array<FullBrandEntity>
}

export type OutfitImagesArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type OutfitProductsArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type OutfitDesignersArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type OutfitStoriesArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type OutfitBrandsArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type OutfitFullBrandsArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type Page = {
  __typename?: 'Page'
  alternateLinks: Array<PageAlternateLink>
  breadcrumbs: Array<Breadcrumb>
  canonicalUrl?: Maybe<Scalars['URL']>
  concession?: Maybe<Concession>
  flattenedChildWidgets?: Maybe<Array<Widget>>
  metaDescription?: Maybe<Scalars['String']>
  metaSearchKeywords: Array<Scalars['String']>
  path: Scalars['PagePath']
  title: Scalars['DisplayString']
  widget?: Maybe<Widget>
  widgets?: Maybe<Array<Widget>>
}

export type PageWidgetArgs = {
  id: Scalars['ID']
}

export type PageAlternateLink = {
  __typename?: 'PageAlternateLink'
  locale: Scalars['Locale']
  pagePath: Scalars['PagePath']
}

export type ParcelLabWidget = Widget & {
  __typename?: 'parcelLabWidget'
  id: Scalars['ID']
  parcelLabUserId?: Maybe<Scalars['Int']>
  query: Query
  showSearchForm?: Maybe<Scalars['String']>
  showZipCodeInput?: Maybe<Scalars['String']>
}

export enum PasswordResetTokenState {
  AlreadyUsed = 'ALREADY_USED',
  Expired = 'EXPIRED',
  Invalid = 'INVALID',
  Valid = 'VALID',
}

export type PasswordUpdateStatus = {
  __typename?: 'PasswordUpdateStatus'
  error?: Maybe<AuthenticationError>
  fieldErrors?: Maybe<Array<Maybe<FormFieldValidationError>>>
}

export type PaymentCard = {
  __typename?: 'PaymentCard'
  issueNumber?: Maybe<Scalars['String']>
  nameOnCard: Scalars['String']
  obfuscatedCardNumber: Scalars['String']
  type?: Maybe<PaymentCardType>
  validFromMonth?: Maybe<Scalars['Month']>
  validFromYear?: Maybe<Scalars['Year']>
  validToMonth: Scalars['Month']
  validToYear: Scalars['Year']
}

export type PaymentCardRecord = {
  __typename?: 'PaymentCardRecord'
  card: PaymentCard
  id: Scalars['ID']
}

export type PaymentCards = {
  __typename?: 'PaymentCards'
  cards: Array<PaymentCardRecord>
  hasMore: Scalars['Boolean']
  total: Scalars['Int']
}

export type PaymentCardsFilterInput = {
  id?: Maybe<Scalars['ID']>
}

export enum PaymentCardType {
  AmericanExpress = 'AMERICAN_EXPRESS',
  Jcb = 'JCB',
  Maestro = 'MAESTRO',
  Mastercard = 'MASTERCARD',
  Solo = 'SOLO',
  Visa = 'VISA',
}

export enum PaymentMethod {
  AdyenV2 = 'ADYEN_V2',
  AfterPay = 'AFTER_PAY',
  AliPay = 'ALI_PAY',
  AliPayPlus = 'ALI_PAY_PLUS',
  Amazonpay = 'AMAZONPAY',
  AmericanExpress = 'AMERICAN_EXPRESS',
  ApplePay = 'APPLE_PAY',
  Arvato = 'ARVATO',
  Atome = 'ATOME',
  Bancontact = 'BANCONTACT',
  ClearPay = 'CLEAR_PAY',
  DinersClub = 'DINERS_CLUB',
  Discover = 'DISCOVER',
  Elo = 'ELO',
  GiftCard = 'GIFT_CARD',
  GiroPay = 'GIRO_PAY',
  GooglePay = 'GOOGLE_PAY',
  Hiper = 'HIPER',
  Humm = 'HUMM',
  Ideal = 'IDEAL',
  Interac = 'INTERAC',
  Jcb = 'JCB',
  Kcp = 'KCP',
  Klarna = 'KLARNA',
  Laybuy = 'LAYBUY',
  Maestro = 'MAESTRO',
  Mastercard = 'MASTERCARD',
  Mode = 'MODE',
  MolPay = 'MOL_PAY',
  Multibanco = 'MULTIBANCO',
  Newpay = 'NEWPAY',
  One4All = 'ONE4ALL',
  OpenPay = 'OPEN_PAY',
  Paypal = 'PAYPAL',
  PaypalIn_3 = 'PAYPAL_IN_3',
  Paytm = 'PAYTM',
  Payvyne = 'PAYVYNE',
  PrimeiroPay = 'PRIMEIRO_PAY',
  QiwiWallet = 'QIWI_WALLET',
  Quadpay = 'QUADPAY',
  Rupay = 'RUPAY',
  Sepa = 'SEPA',
  Sezzle = 'SEZZLE',
  Sofort = 'SOFORT',
  Solo = 'SOLO',
  SplitIt = 'SPLIT_IT',
  Spotii = 'SPOTII',
  Tenpay = 'TENPAY',
  Trustly = 'TRUSTLY',
  TrustPay = 'TRUST_PAY',
  Union = 'UNION',
  VisaCredit = 'VISA_CREDIT',
  VisaDebit = 'VISA_DEBIT',
  VisaElectron = 'VISA_ELECTRON',
  WebMoney = 'WEB_MONEY',
  Wechat = 'WECHAT',
  Yandex = 'YANDEX',
  ZipPay = 'ZIP_PAY',
  RazorPay = 'RAZORPAY',
  FrasersPlus = 'FRASERS_PLUS',
}

export type PaymentOption = {
  option: Scalars['String']
  subOption?: Maybe<Scalars['String']>
}

/** The role of this is to represent the placement format map in HmacAppliedPlacement */
export type PlacementFormatElement = {
  __typename?: 'PlacementFormatElement'
  key?: Maybe<Scalars['String']>
  value?: Maybe<Array<Maybe<HmacAppliedProduct>>>
}

export type PriceType = {
  __typename?: 'PriceType'
  price?: Maybe<Scalars['String']>
  rrp?: Maybe<Scalars['String']>
}

export type Product = {
  __typename?: 'Product'
  alternateLinks: Array<AlternateLink>
  associatedProducts?: Maybe<Array<Product>>
  brand?: Maybe<Brand>
  brandEntity?: Maybe<BrandEntity>
  breadcrumbs?: Maybe<Array<Breadcrumb>>
  cheapestVariant?: Maybe<ProductVariant>
  /** @deprecated Use cheapestVariant instead */
  cheapestVariantPrice?: Maybe<ProductPrice>
  concession?: Maybe<Concession>
  content: Array<ProductContentItem>
  defaultVariant?: Maybe<ProductVariant>
  eligibleForWishlist: Scalars['Boolean']
  externalIdentifier?: Maybe<Scalars['String']>
  fullBrandEntity?: Maybe<FullBrandEntity>
  images: Array<ProductImage>
  /**
   * True if the wishlist contains this product with no selected variant,
   * if a variant is selected, the inWishlist flag on the variant will be true instead.
   */
  inWishlist?: Maybe<Scalars['Boolean']>
  /** If the product have linked SKUs, this will expose what option it is linked on */
  linkedOn?: Maybe<Scalars['String']>
  /** A marketed special offer to display on search results and product lists. */
  marketedSpecialOffer?: Maybe<ProductMarketedSpecialOffer>
  mostExpensiveVariant?: Maybe<ProductVariant>
  options: Array<VariantOption>
  outfits: Array<Outfit>
  /** (e.g. "DVD" or "Blu-Ray") */
  platform?: Maybe<Scalars['DisplayString']>
  preorder: Scalars['Boolean']
  preorderReleaseDate?: Maybe<Scalars['Date']>
  recommendations?: Maybe<Array<Product>>
  reviewTemplate?: Maybe<ReviewTemplate>
  reviews?: Maybe<ProductReviews>
  /** As a dynamic content */
  sizeGuide?: Maybe<RichContent>
  sku: Scalars['SKU']
  sponsoredAds?: Array<HmacAppliedPlacement>
  stories: Array<Story>
  title: Scalars['String']
  /** In the format '/sports-nutrition/impact-whey-protein/10530943.html' */
  url: Scalars['URL']
  variants: Array<ProductVariant>
}

export type ProductCheapestVariantArgs = {
  currency: Currency
  shippingDestination: Country
}

export type ProductCheapestVariantPriceArgs = {
  currency: Currency
  shippingDestination: Country
}

export type ProductContentArgs = {
  keys?: Maybe<Array<Scalars['String']>>
}

export type ProductDefaultVariantArgs = {
  options: VariantOptions
}

export type ProductImagesArgs = {
  limit?: Scalars['Int']
}

export type ProductMostExpensiveVariantArgs = {
  currency: Currency
  shippingDestination: Country
}

export type ProductRecommendationsArgs = {
  limit?: Scalars['Int']
  type: ProductRecommendationType
}

export type ProductAddOnInput = {
  newQuantity: Scalars['Int']
  newSku: Scalars['SKU']
}

export type ProductContentAttachmentValue = {
  __typename?: 'ProductContentAttachmentValue'
  value: Scalars['URL']
}

export type ProductContentDateValue = {
  __typename?: 'ProductContentDateValue'
  value: Scalars['Timestamp']
}

export type ProductContentIntListValue = {
  __typename?: 'ProductContentIntListValue'
  value: Array<Scalars['Int']>
}

export type ProductContentIntValue = {
  __typename?: 'ProductContentIntValue'
  value: Scalars['Int']
}

export type ProductContentItem = {
  __typename?: 'ProductContentItem'
  key: Scalars['String']
  value: ProductContentValue
}

export type ProductContentRichContentListValue = {
  __typename?: 'ProductContentRichContentListValue'
  value: Array<RichContent>
}

export type ProductContentRichContentValue = {
  __typename?: 'ProductContentRichContentValue'
  value: RichContent
}

export type ProductContentStringListValue = {
  __typename?: 'ProductContentStringListValue'
  value: Array<Scalars['String']>
}

export type ProductContentStringValue = {
  __typename?: 'ProductContentStringValue'
  value: Scalars['String']
}

export type ProductContentValue =
  | ProductContentAttachmentValue
  | ProductContentDateValue
  | ProductContentIntListValue
  | ProductContentIntValue
  | ProductContentRichContentListValue
  | ProductContentRichContentValue
  | ProductContentStringListValue
  | ProductContentStringValue

export type ProductImage = {
  __typename?: 'ProductImage'
  /** 300x300 Pixel Image */
  largeProduct?: Maybe<Scalars['URL']>
  /** Unaltered version of image uploaded to our system */
  original?: Maybe<Scalars['URL']>
  /** 70x70 Pixel Image */
  thumbnail?: Maybe<Scalars['URL']>
  /** 960x960 Pixel Image */
  zoom?: Maybe<Scalars['URL']>
}

export type ProductList = {
  __typename?: 'ProductList'
  /**
   * If this is non-null, 0 results were found, but results were found for a similar query,
   * which was returned instead.
   */
  correctedQuery?: Maybe<Scalars['DisplayString']>
  facets: Array<Facet>
  hasMore: Scalars['Boolean']
  products: Array<Product>
  sponsoredAds?: Array<HmacAppliedPlacement>
  total: Scalars['Int']
}

export type ProductListInput = {
  barcode?: Maybe<Scalars['String']>
  concessionCode?: Maybe<Scalars['String']>
  currency: Currency
  facets: Array<FacetInput>
  limit?: Scalars['Int']
  offset?: Scalars['Int']
  shippingDestination: Country
  sort?: ProductSort
}

export type ProductListWidget = Widget & {
  __typename?: 'ProductListWidget'
  descriptionHtml?: Maybe<RichContent>
  id: Scalars['ID']
  productList?: Maybe<ProductList>
  query: Query
  seoDescriptionHtml?: Maybe<RichContent>
  title?: Maybe<Scalars['String']>
}

export type ProductListWidgetProductListArgs = {
  input: ProductListInput
}

export type ProductMarketedSpecialOffer = {
  __typename?: 'ProductMarketedSpecialOffer'
  description?: Maybe<RichContent>
  /** This will only be non-null when the best special offer for this product is a free gift offer */
  freeGiftProduct?: Maybe<ProductVariant>
  landingPageLink?: Maybe<Hyperlink>
  title?: Maybe<RichContent>
}

export type ProductPrice = {
  __typename?: 'ProductPrice'
  /** @deprecated Use earnableLoyaltyPoints on the variant object instead */
  earnableLoyaltyPoints?: Maybe<Scalars['Int']>
  price: MoneyValue
  rrp: MoneyValue
  /**
   * This price will be provided only if a vip price offer is set on the site and following conditions
   * are met:
   *  - the customer is logged in and part of the customer list set on the offer
   *  - the product is set of the product list set on the offer
   *  - the vip pricing feature flag is enabled
   */
  vipPrice?: Maybe<MoneyValue>
}

export type ProductPriceEarnableLoyaltyPointsArgs = {
  sku: Scalars['SKU']
}

export type ProductPriceVipPriceArgs = {
  sku: Scalars['SKU']
}

export enum ProductRecommendationType {
  /**
   * This looks at what products other customers added to their basket when browsing the product(s)
   * currently being viewed. If the probability of adding a particular product to basket is high
   * then that's what will be recommended.
   */
  CustomersEventuallyBought = 'CUSTOMERS_EVENTUALLY_BOUGHT',
  FrequentlyBoughtTogether = 'FREQUENTLY_BOUGHT_TOGETHER',
  Manual = 'MANUAL',
  Personalised = 'PERSONALISED',
  PostAddToBasket = 'POST_ADD_TO_BASKET',
  /**
   * This returns products of a similar category that are in stock. This is used on THG sites
   * to suggest other products to a customer when the product they are viewing is out of stock.
   */
  SimilarProducts = 'SIMILAR_PRODUCTS',
}

export type ProductRecommenderItem = Widget & {
  __typename?: 'ProductRecommenderItem'
  id: Scalars['ID']
  itemNumber?: Maybe<Scalars['String']>
  itemSKU?: Maybe<Scalars['String']>
  itemTitle?: Maybe<Scalars['String']>
  query: Query
}

export type ProductReviews = {
  __typename?: 'ProductReviews'
  averageScore: Scalars['Float']
  count1Score: Scalars['Int']
  count2Score: Scalars['Int']
  count3Score: Scalars['Int']
  count4Score: Scalars['Int']
  count5Score: Scalars['Int']
  featureScores: Array<AverageFeatureScore>
  maxScore: Scalars['Float']
  /** @deprecated Field still valid. SKU no longer required as input as the SKU of the product this is attached to will be used */
  newReviewForm?: Maybe<Form>
  reviews?: Maybe<Reviews>
  total: Scalars['Int']
}

export type ProductReviewsNewReviewFormArgs = {
  sku?: Maybe<Scalars['SKU']>
}

export type ProductReviewsReviewsArgs = {
  filter?: Maybe<ReviewsFilterInput>
  limit?: Scalars['Int']
  offset?: Scalars['Int']
  sort?: ReviewSort
}

export enum ProductSort {
  DiscountPercentageHighToLow = 'DISCOUNT_PERCENTAGE_HIGH_TO_LOW',
  NameAlphabetically = 'NAME_ALPHABETICALLY',
  NewestToOldest = 'NEWEST_TO_OLDEST',
  Popularity = 'POPULARITY',
  PriceHighToLow = 'PRICE_HIGH_TO_LOW',
  PriceLowToHigh = 'PRICE_LOW_TO_HIGH',
  Relevance = 'RELEVANCE',
}

/** An e-commerce subscription, would be just a "Subscription" if it weren't for the GraphQL feature! */
export type ProductSubscription = {
  __typename?: 'ProductSubscription'
  availableAddOnProducts: Array<ProductVariant>
  availableBillingDateChanges?: Maybe<Array<DateRange>>
  availableFrequencyChanges?: Maybe<Array<SubscriptionFrequencyDuration>>
  billingAddress?: Maybe<Address>
  cancellable: Scalars['Boolean']
  /**
   * The orders created to fulfil this subscription.
   * @deprecated Use orders instead,which has support both Legacy orders and Pagination
   */
  childOrders?: Maybe<Array<Order>>
  costPerPaymentPeriod: MoneyValue
  createdAt: Scalars['Timestamp']
  delayCancellable: Scalars['Boolean']
  delayCount: Scalars['Int']
  /** @deprecated Use delayCount instead */
  delayStatus: SubscriptionDelay
  delayable: Scalars['Boolean']
  deliveryAddress?: Maybe<Address>
  id: Scalars['ID']
  nextBillingDate?: Maybe<Scalars['Date']>
  nextGiftProductId?: Maybe<Scalars['SKU']>
  /** The parent order that spawned this subscription. */
  order?: Maybe<Order>
  orders?: Maybe<Orders>
  autoRenewal: SubscriptionAutoRenewal
  paymentCard?: Maybe<PaymentCard>
  /** @deprecated Use subscriptionItems instead, which has support for both Legacy Subscriptions and Subs by Sku */
  product?: Maybe<ProductVariant>
  productAddOnAllowed: Scalars['Boolean']
  productRemovalAllowed: Scalars['Boolean']
  productSwapAllowed: Scalars['Boolean']
  schedule: SubscriptionSchedule
  /**
   * The product that represents the subscription as a whole, i.e. the one placed in basket when
   * initially signing-up, not the ones actually sent out.
   * @deprecated Use subscriptionItems instead, as this field is of no use on its own
   */
  sku?: Maybe<Scalars['SKU']>
  status: SubscriptionStatus
  subscriptionItems: Array<SubscriptionItem>
}

/** An e-commerce subscription, would be just a "Subscription" if it weren't for the GraphQL feature! */
export type ProductSubscriptionOrdersArgs = {
  endDate?: Maybe<Scalars['Timestamp']>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  startDate?: Maybe<Scalars['Timestamp']>
}

export type ProductSwapInput = {
  /** Quantity of newSku; oldSku will be removed from the subscription */
  newQuantity: Scalars['Int']
  newSku: Scalars['SKU']
  oldSku: Scalars['SKU']
}

export type ProductUnit = {
  __typename?: 'ProductUnit'
  /** E.g. "kg" in 5 kg or "servings" in 35 servings */
  unit: Scalars['String']
  /** E.g. "5" in 5 kg or "35" in 35 servings */
  value: Scalars['String']
}

export type ProductVariant = {
  __typename?: 'ProductVariant'
  availabilityMessage: Scalars['DisplayString']
  availabilitySource: AvailabilitySource
  barcode?: Maybe<Scalars['String']>
  buyNowPayLaterProviders?: Maybe<Array<BuyNowPayLaterProvider>>
  choices: Array<OptionChoice>
  /** Get stores near to the given coordinates, and their stock for this product */
  clickAndCollectStores: Array<RelativeStore>
  content: Array<ProductContentItem>
  deliveryInformation?: Maybe<RichContent>
  earnableLoyaltyPoints?: Maybe<Scalars['Int']>
  eligibleForFulfilmentMethods?: Maybe<Array<FulfilmentMethod>>
  inStockLocations?: Array<InStockLocation>
  eligibleForWishlist: Scalars['Boolean']
  externalIdentifier?: Maybe<Scalars['String']>
  images: Array<ProductImage>
  inStock: Scalars['Boolean']
  inWishlist?: Maybe<Scalars['Boolean']>
  isAutoRenewSubscription: Scalars['Boolean']
  isCheckStock: Scalars['Boolean']
  isOrderInStore: Scalars['Boolean']
  isComingSoon: Scalars['Boolean']
  /** Legacy subscription fields. This functionality is being replaced by Subs By Sku. */
  isSubscription: Scalars['Boolean']
  leadTime?: Scalars['Int']
  /** A marketed special offer to display on product pages. */
  marketedSpecialOffer?: Maybe<ProductMarketedSpecialOffer>
  maxPerOrder?: Maybe<Scalars['Int']>
  notifyWhenInStockEnabled: Scalars['Boolean']
  price?: Maybe<ProductPrice>
  /**
   * The main product which includes this variant.
   * May not necessarily be the product under which this was queried.
   */
  product?: Maybe<Product>
  returnsInformation?: Maybe<RichContent>
  sku: Scalars['SKU']
  subscriptionContracts: Array<SubscriptionContract>
  subscriptionFrequency?: Maybe<Scalars['String']>
  subscriptionPaymentType?: Maybe<Scalars['String']>
  subscriptionTerm?: Maybe<Scalars['Int']>
  supersize?: Maybe<Supersize>
  /** @deprecated Use supersize -> variant instead */
  supersizeVariant?: Maybe<ProductVariant>
  title: Scalars['String']
  weightGroups?: Array<Scalars['String']>
  isBookable?: Maybe<Scalars['Boolean']>
}

export type ProductVariantBuyNowPayLaterProvidersArgs = {
  settings: SessionSettings
}

export type ProductVariantClickAndCollectStoresArgs = {
  longitude: Scalars['Float']
  latitude: Scalars['Float']
  limit?: Scalars['Int']
}

export type ProductVariantContentArgs = {
  keys?: Maybe<Array<Scalars['String']>>
}

export type ProductVariantEarnableLoyaltyPointsArgs = {
  settings: SessionSettings
}

export type ProductVariantImagesArgs = {
  limit?: Scalars['Int']
}

export type ProductVariantPriceArgs = {
  currency: Currency
  shippingDestination: Country
}

export type ProductVariantSupersizeArgs = {
  settings: SessionSettings
}

export type ProductVariantCustom = {
  __typename?: 'ProductVariantCustom'
  title?: Maybe<ProductVariantTitle>
  url?: Maybe<Scalars['String']>
  sku?: Maybe<Scalars['String']>
  image?: Maybe<ProductVariantImage>
  review?: Maybe<ProductVariantReview>
  isCheckStock?: Maybe<Scalars['Boolean']>
  isOrderInStore?: Maybe<Scalars['Boolean']>
  inStock?: Maybe<Scalars['Boolean']>
  price?: Maybe<PriceType>
  marketedSpecialOffer?: Maybe<MarketedSpecialOfferType>
  icons?: Maybe<IconsType>
  eligibleForFulfilmentMethods?: Maybe<Scalars['Boolean']>
  externalIdentifier?: Maybe<Scalars['Boolean']>
}

export type ProductVariantImage = {
  __typename?: 'ProductVariantImage'
  urls?: Maybe<ProductVariantImageUrls>
  altText?: Maybe<Scalars['String']>
  isAmp?: Maybe<Scalars['Boolean']>
}

export type ProductVariantImageUrls = {
  __typename?: 'ProductVariantImageUrls'
  typename: Scalars['String']
  largeProduct?: Maybe<Scalars['String']>
}

export type ProductVariantReview = {
  __typename?: 'ProductVariantReview'
  typename: Scalars['String']
  starRating?: Maybe<Scalars['Int']>
  numberOfReviews?: Maybe<Scalars['Int']>
}

export type ProductVariantTitle = {
  __typename?: 'ProductVariantTitle'
  typename?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type ProductWaitlistInput = {
  deviceUuid?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  sku: Scalars['SKU']
  waitlistType: WaitlistType
}

export type ProfileWidget = Widget & {
  __typename?: 'ProfileWidget'
  hasProgressBar?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  isForWidget?: Maybe<Scalars['Boolean']>
  profileKey?: Maybe<Scalars['String']>
  query: Query
  startLayoutTwoCol?: Maybe<Scalars['Boolean']>
  useGridLayout?: Maybe<Scalars['Boolean']>
}

export type Promoproductslider = Widget & {
  __typename?: 'promoproductslider'
  featuredImage?: Maybe<Scalars['URL']>
  id: Scalars['ID']
  imageURL?: Maybe<Scalars['String']>
  imageAltText?: Maybe<Scalars['String']>
  productCount?: Maybe<Scalars['Int']>
  productList?: Maybe<ProductList>
  query: Query
  widgetSubtitle?: Maybe<Scalars['String']>
  widgetTitle?: Maybe<Scalars['String']>
}

export type PromoproductsliderProductListArgs = {
  input: ProductListInput
}

export type PropositionBar = Widget & {
  __typename?: 'propositionBar'
  id: Scalars['ID']
  query: Query
  textFive?: Maybe<Scalars['String']>
  textFour?: Maybe<Scalars['String']>
  textOne?: Maybe<Scalars['String']>
  textSix?: Maybe<Scalars['String']>
  textThree?: Maybe<Scalars['String']>
  textTwo?: Maybe<Scalars['String']>
}

export type PropositionBarIncorrect = Widget & {
  __typename?: 'propositionBarIncorrect'
  id: Scalars['ID']
  query: Query
}

export type ProvenanceBrandHeader = Widget & {
  __typename?: 'provenanceBrandHeader'
  brand?: Maybe<Scalars['String']>
  brandId?: Maybe<Scalars['String']>
  button2Text?: Maybe<Scalars['String']>
  button2Url?: Maybe<Scalars['String']>
  id: Scalars['ID']
  itemAlign?: Maybe<Scalars['String']>
  itemAlt?: Maybe<Scalars['String']>
  itemButton?: Maybe<Scalars['String']>
  itemImage?: Maybe<Scalars['URL']>
  itemImageSmall?: Maybe<Scalars['URL']>
  itemText?: Maybe<RichContent>
  itemTitle?: Maybe<Scalars['String']>
  itemURL?: Maybe<Scalars['String']>
  query: Query
  textAlign?: Maybe<Scalars['String']>
  transcript?: Maybe<RichContent>
  videoTitle?: Maybe<Scalars['String']>
  videoUrl?: Maybe<Scalars['String']>
}

export type PushSubscriptionInput = {
  deviceUuid?: Maybe<Scalars['String']>
  externalDeviceId?: Maybe<Scalars['String']>
  subscribed: Scalars['Boolean']
  subscriptionToken: Scalars['String']
  tokenType: PushTokenType
}

export type PushSubscriptionResponse = {
  __typename?: 'PushSubscriptionResponse'
  deviceUuid?: Maybe<Scalars['String']>
}

export enum PushTokenType {
  Apns = 'APNS',
  Firebase = 'FIREBASE',
}

export type QubitHomePageRecs = Widget & {
  __typename?: 'qubitHomePageRecs'
  id: Scalars['ID']
  numberOfProducts?: Maybe<Scalars['Int']>
  placementId?: Maybe<Scalars['String']>
  productList?: Maybe<ProductList>
  query: Query
  theme?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  titleAlign?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type QubitHomePageRecsProductListArgs = {
  input: ProductListInput
}

export type Query = {
  __typename?: 'Query'
  accountCreationForm: Form
  accountCreationMarketingPreferences?: Maybe<
    AccountCreationMarketingPreferences
  >
  accountLinkingTokenInfo?: Maybe<AccountLinkingTokenInfo>
  accountSettingsForm: Form
  attachmentUploaderConfig?: Maybe<AttachmentUploaderConfig>
  /**
   * If a null id is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  basket?: Maybe<Basket>
  /** @deprecated Use dynamicEntities query to get an entity list with pagination enabled */
  brandEntities: Array<BrandEntity>
  /** @deprecated Use fullBrandEntity */
  brandEntity?: Maybe<BrandEntity>
  brands: Array<Brand>
  /** @deprecated Included in extensions if applicable. */
  captchaConfiguration: Array<CaptchaConfiguration>
  check?: Maybe<Scalars['String']>
  /**
   * Widgets are usually associated with pages on site, but there is sometimes the
   * need to display widgets across other components such as a popup, or alongside
   * core e-commerce objects like the basket.
   *
   * These "special" sets of widgets can be queried through the componentWidgets
   * query and the supported components can be found using the
   * supportedComponentWidgetNames query.
   */
  componentWidgets: Array<Widget>
  concession?: Maybe<Concession>
  /** Returns the current customer if the customer is logged in, or null otherwise */
  customer?: Maybe<Customer>
  deliveryInfo?: Maybe<DeliveryInfo>
  designer?: Maybe<Designer>
  /** @deprecated Use dynamicEntities query to get an entity list with pagination enabled */
  designers: Array<Designer>
  dynamicEntities: DynamicEntityList
  eGiftSummary?: Maybe<EGiftSummary>
  emailField?: Maybe<FormField>
  /**
   * This is a query despite having side effects so it can be queried on the server-side at the same time as querying
   * other information to deliver the first page load
   *
   * token should be considered deprecated and will be removed in due course.
   * Please only submit the SocialLoginInput object
   */
  exchangeSocialAuthenticationToken?: Maybe<SocialAuthenticationResponse>
  features: Array<Feature>
  /** @deprecated Use features instead */
  flags: Array<Feature>
  footer?: Maybe<Footer>
  form?: Maybe<Form>
  forms?: Maybe<Array<Form>>
  fullBrandEntity?: Maybe<FullBrandEntity>
  header?: Maybe<Header>
  instantSearch?: Maybe<InstantSearchResult>
  /**
   * Interaction awards are points based awards given for action on site.
   * This includes things like leaving product reviews or completing your customer profile.
   * This data for all reward types can also be requested on the LoyaltyScheme object.
   */
  interactionAward?: Maybe<LoyaltyInteractionAward>
  isProductInWaitlist?: Maybe<Scalars['Boolean']>
  loyaltyScheme?: Maybe<LoyaltyScheme>
  /** @deprecated Use accountCreationMarketingPreferences instead */
  marketingPreferences?: Maybe<MarketingPreferences>
  nearbyStores: Array<Store>
  outfit?: Maybe<Outfit>
  /** @deprecated Use dynamicEntities query to get an entity list with pagination enabled */
  outfits: Array<Outfit>
  page?: Maybe<Page>
  passwordField?: Maybe<FormField>
  /** Returns information about a password reset token without consuming it. */
  passwordResetTokenValidity?: Maybe<PasswordResetTokenState>
  paymentMethods: Array<PaymentMethod>
  /**
   * If strict is false, the api is allowed to perform redirects, such as returning a master product when given the SKU
   * of one of its variants.
   *
   * If skipRedirects is set to true, redirects will be skipped but as long as strict is false,
   * master product will still be checked if a variant SKU is provided instead of a master SKU
   */
  product?: Maybe<Product>
  productVariant?: Maybe<ProductVariant>
  productVariants: Array<ProductVariant>
  products: Array<Product>
  profiles?: Maybe<Array<Form>>
  referralCodeField?: Maybe<FormField>
  /**
   * Default value of skipRedirects is true. When set to true, search results will be returned even
   * when there is a redirect and client can decide whether to redirect the customers or not
   */
  search?: Maybe<SearchResult>
  /** Search for known locations by name or postcode. */
  searchLocations: Array<Location>
  /**
   * Returns all site properties for the site.
   *
   * If propertyBucket is provided, that bucket will be used to retrieve properties.  Otherwise, it will default to
   * "frontend".
   *
   * Providing a preview ID allows property changes to be previewed before committing them.  The preview ID
   * should come from the Properties-Preview header.
   */
  siteProperties?: Maybe<SiteProperties>
  sitemap: Sitemap
  socialAccounts: Array<SocialAccount>
  socialLoginProviders?: Maybe<Array<SocialLoginProvider>>
  store?: Maybe<Store>
  stores: Array<Store>
  /** @deprecated Use dynamicEntities query to get an entity list with pagination enabled */
  stories: Array<Story>
  story?: Maybe<Story>
  supportedComponentWidgetNames: Array<Scalars['String']>
  supportedCurrencies: Array<Currency>
  supportedShippingDestinations: Array<Country>
  video?: Maybe<Video>
  voucherCodes?: Maybe<VoucherCodes>
}

export type QueryAccountCreationMarketingPreferencesArgs = {
  email: Scalars['String']
}

export type QueryAccountLinkingTokenInfoArgs = {
  accountLinkingToken: Scalars['AccountLinkingToken']
}

export type QueryBasketArgs = {
  acknowledgeMerge?: Scalars['Boolean']
  id?: Maybe<Scalars['ID']>
  settings: SessionSettings
}

export type QueryBrandEntitiesArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type QueryBrandEntityArgs = {
  id: Scalars['Int']
}

export type QueryComponentWidgetsArgs = {
  name: Scalars['String']
}

export type QueryConcessionArgs = {
  code: Scalars['String']
}

export type QueryDesignerArgs = {
  id: Scalars['Int']
}

export type QueryDesignersArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type QueryDynamicEntitiesArgs = {
  entityType: DynamicEntityType
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type QueryEGiftSummaryArgs = {
  code: Scalars['String']
}

export type QueryExchangeSocialAuthenticationTokenArgs = {
  input?: Maybe<SocialLoginInput>
  token?: Maybe<Scalars['SocialAuthenticationToken']>
}

export type QueryFormArgs = {
  input: FormInput
}

export type QueryFormsArgs = {
  input: FormFilterInput
}

export type QueryFullBrandEntityArgs = {
  id: Scalars['Int']
}

export type QueryInstantSearchArgs = {
  concessionCode?: Maybe<Scalars['String']>
  currency: Currency
  limit?: Scalars['Int']
  query: Scalars['String']
  shippingDestination: Country
}

export type QueryInteractionAwardArgs = {
  type: InteractionAwardType
}

export type QueryIsProductInWaitlistArgs = {
  input: ProductWaitlistInput
}

export type QueryMarketingPreferencesArgs = {
  input: MarketingPreferencesInput
}

export type QueryNearbyStoresArgs = {
  longitude: Scalars['Float']
  latitude: Scalars['Float']
  limit?: Scalars['Int']
}

export type QueryOutfitArgs = {
  id: Scalars['Int']
}

export type QueryOutfitsArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type QueryPageArgs = {
  path: Scalars['PagePath']
}

export type QueryPasswordResetTokenValidityArgs = {
  token: Scalars['PasswordResetToken']
}

export type QueryProductArgs = {
  skipRedirects?: Maybe<Scalars['Boolean']>
  sku: Scalars['SKU']
  strict: Scalars['Boolean']
}

export type QueryProductVariantArgs = {
  sku: Scalars['SKU']
}

export type QueryProductVariantsArgs = {
  skus: Array<Scalars['SKU']>
}

export type QueryProductsArgs = {
  skipRedirects?: Maybe<Scalars['Boolean']>
  skus: Array<Scalars['SKU']>
}

export type QueryProfilesArgs = {
  input: FormFilterInput
}

export type QuerySearchArgs = {
  options: ProductListInput
  query: Scalars['String']
  skipRedirects?: Maybe<Scalars['Boolean']>
}

export type QuerySearchLocationsArgs = {
  query: Scalars['String']
  limit?: Maybe<Scalars['Int']>
}

export type QuerySitePropertiesArgs = {
  previewId?: Maybe<Scalars['String']>
  propertyBucket?: Maybe<Scalars['String']>
}

export type QuerySitemapArgs = {
  limit?: Scalars['Int']
  offset?: Scalars['Int']
}

export type QueryStoreArgs = {
  id: Scalars['ID']
}

export type QueryStoriesArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type QueryStoryArgs = {
  id: Scalars['Int']
}

export type QueryVideoArgs = {
  id: Scalars['ID']
}

export type QueryVoucherCodesArgs = {
  limit?: Scalars['Int']
  offset?: Scalars['Int']
}

export type RangedFacet = Facet & {
  __typename?: 'RangedFacet'
  facetHeader: Scalars['DisplayString']
  facetName: Scalars['String']
  options: Array<RangedFacetOption>
}

export type RangedFacetOption = {
  __typename?: 'RangedFacetOption'
  displayName: Scalars['DisplayString']
  /** May be null if the range is unbounded (e.g. "Up to £5") */
  from?: Maybe<Scalars['Float']>
  matchedProductCount: Scalars['Int']
  /** May be null if the range is unbounded (e.g. "£50 and up") */
  to?: Maybe<Scalars['Float']>
}

export enum RateLimitingBucket {
  AddReview = 'ADD_REVIEW',
  ForgotPassword = 'FORGOT_PASSWORD',
  Login = 'LOGIN',
  MarketingMaterialSignUp = 'MARKETING_MATERIAL_SIGN_UP',
  MarketingPreferences = 'MARKETING_PREFERENCES',
  PushNotifications = 'PUSH_NOTIFICATIONS',
  ReferralEmail = 'REFERRAL_EMAIL',
  Registration = 'REGISTRATION',
  ResetPassword = 'RESET_PASSWORD',
  Waitlist = 'WAITLIST',
}

/** Represents a rating (for example out of 5 stars) */
export type RatingReviewElement = ReviewElement & {
  __typename?: 'RatingReviewElement'
  key: Scalars['String']
  maxScore: Scalars['Int']
  score: Scalars['Int']
}

export type ReferralCount = {
  __typename?: 'ReferralCount'
  count: Scalars['Int']
  siteKey: Scalars['String']
}

/**  Any additions to this should also be added to SocialLoginMissingInformation and maybe to the customer object */
export type RegistrationInput = {
  dateOfBirth?: Maybe<Scalars['Date']>
  email: Scalars['String']
  fullName: Scalars['String']
  loyaltyOptIn?: Maybe<Scalars['Boolean']>
  marketingConsent: Scalars['String']
  marketingConsentAuditData: MarketingConsentAuditData
  password: Scalars['String']
  phoneNumber?: Maybe<Scalars['String']>
  referralCode?: Maybe<Scalars['String']>
}

export enum Relationship {
  EqualTo = 'EQUAL_TO',
  NotEqualTo = 'NOT_EQUAL_TO',
}

export type RelativeStore = {
  __typename?: 'RelativeStore'
  store: Store
  distanceMiles: Scalars['Float']
  stock: Scalars['Int']
  eligibleFulfilmentMethods: Array<FulfilmentMethod>
  ranged?: Maybe<Scalars['Boolean']>
}

export type RemoveRewardCardResponse = {
  __typename?: 'RemoveRewardCardResponse'
  success: Scalars['Boolean']
  error?: Maybe<RewardCardError>
}

export type RemoveSocialLinkInput = {
  socialLinkId: Scalars['SocialLinkID']
}

export type RenderCondition = {
  __typename?: 'RenderCondition'
  /**
   * This condition is a requirement for a field to be rendered, eg: card_details may have a condition:
   * referenceFieldName: "age"
   * relationship: "GREATER_THAN"
   * value: "18"
   */
  referenceFieldName: Scalars['String']
  relationship: Relationship
  value: Scalars['String']
}

/** The role of this is to represent the rendering map in HmacAppliedPlacement */
export type RenderingElement = {
  __typename?: 'RenderingElement'
  key?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type ReplaceAddressInput = {
  addressInput: AddressInput
  id: Scalars['ID']
}

export type RequestSetPasswordEmailInput = {
  username: Scalars['String']
}

export type RequestSetPasswordEmailResponse = {
  __typename?: 'RequestSetPasswordEmailResponse'
  /** Check to make sure error is not set to confirm success */
  error?: Maybe<ForgottenPasswordError>
}

export enum RequestSocialLinkVerificationEmailError {
  InvalidToken = 'INVALID_TOKEN',
}

export type RequestSocialLinkVerificationEmailInput = {
  socialAuthenticationToken: Scalars['SocialAuthenticationToken']
}

/** The response will either contain the error field or the socialAuthenticationToken field */
export type RequestSocialLinkVerificationEmailResponse = {
  __typename?: 'RequestSocialLinkVerificationEmailResponse'
  error?: Maybe<RequestSocialLinkVerificationEmailError>
  /** A new token that can be used to resend the email */
  socialAuthenticationToken?: Maybe<Scalars['SocialAuthenticationToken']>
}

export type ResetPasswordInput = {
  newPassword: Scalars['String']
  token: Scalars['PasswordResetToken']
  username: Scalars['String']
}

export type ResponsiveBuildYourOwnBundle = Widget & {
  __typename?: 'ResponsiveBuildYourOwnBundle'
  banners?: Maybe<Array<Maybe<Widget>>>
  bundleSubTitle?: Maybe<Scalars['String']>
  expandAllCategories?: Maybe<Scalars['String']>
  id: Scalars['ID']
  maxProducts?: Maybe<Scalars['Int']>
  query: Query
  showBundlePageTitle?: Maybe<Scalars['String']>
  showCategoriesList?: Maybe<Scalars['String']>
  showInfoButton?: Maybe<Scalars['String']>
  showPrices?: Maybe<Scalars['String']>
  showProductRRP?: Maybe<Scalars['String']>
  showProductReviews?: Maybe<Scalars['String']>
  summaryAmount?: Maybe<Scalars['String']>
  summaryDisplayType?: Maybe<Scalars['String']>
  unlimitedProducts?: Maybe<Scalars['String']>
}

export type ResponsiveBuildYourOwnBundleProducts = Widget & {
  __typename?: 'ResponsiveBuildYourOwnBundleProducts'
  categoryTitle?: Maybe<Scalars['String']>
  enableQuantitySelector?: Maybe<Scalars['String']>
  id: Scalars['ID']
  maxProducts?: Maybe<Scalars['Int']>
  productList?: Maybe<Scalars['String']>
  productsToShow?: Maybe<Scalars['Int']>
  query: Query
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  skuList?: Maybe<Array<Maybe<ProductVariant>>>
  skuListNew?: Maybe<Array<Maybe<WidgetProductItem>>>
}

export type ResponsiveComparisonTable = Widget & {
  __typename?: 'ResponsiveComparisonTable'
  buttonText?: Maybe<Scalars['String']>
  buttonUrl?: Maybe<Scalars['String']>
  comparisonList?: Maybe<Scalars['String']>
  disableFeatureTag?: Maybe<Scalars['String']>
  featureTag?: Maybe<Scalars['URL']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['URL']>
  moreInfo?: Maybe<Scalars['String']>
  query: Query
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type ResponsiveComparisonTableContainer = Widget & {
  __typename?: 'ResponsiveComparisonTableContainer'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
}

export type ResponsiveProductBlockCarousel = Widget & {
  __typename?: 'ResponsiveProductBlockCarousel'
  displayStandaloneTitle?: Maybe<Scalars['String']>
  enableAsiaStyles?: Maybe<Scalars['String']>
  id: Scalars['ID']
  productList?: Maybe<ProductList>
  query: Query
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  skuList?: Maybe<Array<Maybe<ProductVariant>>>
  skuListNew?: Maybe<Array<Maybe<WidgetProductItem>>>
  title?: Maybe<Scalars['String']>
  viewAllButtonStyle?: Maybe<Scalars['String']>
  viewAllButtonText?: Maybe<Scalars['String']>
  viewAllButtonUrl?: Maybe<Scalars['URL']>
}

export type ResponsiveProductBlockCarouselProductListArgs = {
  input: ProductListInput
}

export type ResponsiveProductTabs = Widget & {
  __typename?: 'ResponsiveProductTabs'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
  responsiveProductTabsTitle?: Maybe<Scalars['String']>
  tabTitle_1?: Maybe<Scalars['String']>
  tabTitle_2?: Maybe<Scalars['String']>
  tabTitle_3?: Maybe<Scalars['String']>
}

export type ResponsiveSetSalon = Widget & {
  __typename?: 'ResponsiveSetSalon'
  banners?: Maybe<Array<Maybe<Widget>>>
  beauty?: Maybe<Scalars['String']>
  extensions?: Maybe<Scalars['String']>
  fridayHours?: Maybe<Scalars['String']>
  hair?: Maybe<Scalars['String']>
  id: Scalars['ID']
  mondayHours?: Maybe<Scalars['String']>
  query: Query
  salonBookUrl?: Maybe<Scalars['URL']>
  salonDescription?: Maybe<Scalars['String']>
  salonEmail?: Maybe<Scalars['String']>
  salonTel?: Maybe<Scalars['String']>
  salonTitle?: Maybe<Scalars['String']>
  saturdayHours?: Maybe<Scalars['String']>
  socialUrl?: Maybe<Scalars['String']>
  sprayTan?: Maybe<Scalars['String']>
  sundayHours?: Maybe<Scalars['String']>
  thursdayHours?: Maybe<Scalars['String']>
  tuesdayHours?: Maybe<Scalars['String']>
  wednesdayHours?: Maybe<Scalars['String']>
}

export type ResponsiveSetSalonWithSlides = Widget & {
  __typename?: 'ResponsiveSetSalonWithSlides'
  beauty?: Maybe<Scalars['String']>
  extensions?: Maybe<Scalars['String']>
  fridayHours?: Maybe<Scalars['String']>
  hair?: Maybe<Scalars['String']>
  id: Scalars['ID']
  mondayHours?: Maybe<Scalars['String']>
  query: Query
  salonBookUrl?: Maybe<Scalars['URL']>
  salonDescription?: Maybe<Scalars['String']>
  salonEmail?: Maybe<Scalars['String']>
  salonTel?: Maybe<Scalars['String']>
  salonTitle?: Maybe<Scalars['String']>
  saturdayHours?: Maybe<Scalars['String']>
  sliderImage1?: Maybe<Scalars['URL']>
  sliderImage1Alt?: Maybe<Scalars['String']>
  sliderImage2?: Maybe<Scalars['URL']>
  sliderImage2Alt?: Maybe<Scalars['String']>
  sliderImage3?: Maybe<Scalars['URL']>
  sliderImage3Alt?: Maybe<Scalars['String']>
  socialUrl?: Maybe<Scalars['URL']>
  sprayTan?: Maybe<Scalars['String']>
  sundayHours?: Maybe<Scalars['String']>
  thursdayHours?: Maybe<Scalars['String']>
  tuesdayHours?: Maybe<Scalars['String']>
  wednesdayHours?: Maybe<Scalars['String']>
}

export type ResponsiveSliderSet = Widget & {
  __typename?: 'ResponsiveSliderSet'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  isAutoSlideOn?: Maybe<Scalars['String']>
  isBulletNavigationHidden?: Maybe<Scalars['String']>
  isHeightVariable?: Maybe<Scalars['String']>
  query: Query
  slideDuration?: Maybe<Scalars['Int']>
}

export type ResponsiveSuccessStoriesWidgetContainer = Widget & {
  __typename?: 'ResponsiveSuccessStoriesWidgetContainer'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
}

export type ResponsiveSuccessStoryWidget = Widget & {
  __typename?: 'ResponsiveSuccessStoryWidget'
  caption?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageAfterAltText?: Maybe<Scalars['String']>
  imageAfterUrl?: Maybe<Scalars['URL']>
  imageAltText?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['URL']>
  linkUrl?: Maybe<Scalars['String']>
  profileCta?: Maybe<Scalars['String']>
  profileName?: Maybe<Scalars['String']>
  query: Query
  roundelTitle?: Maybe<Scalars['String']>
  weightLossStyle?: Maybe<Scalars['String']>
  weightLost?: Maybe<Scalars['String']>
}

export type ResponsiveTwoItemComparisonContainer = Widget & {
  __typename?: 'ResponsiveTwoItemComparisonContainer'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
  title1?: Maybe<Scalars['String']>
  title2?: Maybe<Scalars['String']>
}

export type ResponsiveTwoItemComparisonRow = Widget & {
  __typename?: 'ResponsiveTwoItemComparisonRow'
  firstItem?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  secondItem?: Maybe<Scalars['String']>
  valueToCompare?: Maybe<Scalars['String']>
  valueToCopmare?: Maybe<Scalars['String']>
}

export type ResponsiveUspBar = Widget & {
  __typename?: 'ResponsiveUSPBar'
  callIcon?: Maybe<Scalars['String']>
  callLink?: Maybe<Scalars['String']>
  callText?: Maybe<Scalars['String']>
  checkmarkLink?: Maybe<Scalars['String']>
  checkmarkText?: Maybe<Scalars['String']>
  clickAndCollectLink?: Maybe<Scalars['String']>
  clickAndCollectText?: Maybe<Scalars['String']>
  countDownLink?: Maybe<Scalars['String']>
  countDownText?: Maybe<Scalars['String']>
  deliveryLink?: Maybe<Scalars['String']>
  deliveryText?: Maybe<Scalars['String']>
  emailLink?: Maybe<Scalars['String']>
  emailText?: Maybe<Scalars['String']>
  fastTrackLink?: Maybe<Scalars['String']>
  fastTrackText?: Maybe<Scalars['String']>
  fridayCutoff?: Maybe<Scalars['String']>
  id: Scalars['ID']
  internationalDeliveryLink?: Maybe<Scalars['String']>
  internationalDeliveryText?: Maybe<Scalars['String']>
  mondayCutoff?: Maybe<Scalars['String']>
  qualityLink?: Maybe<Scalars['String']>
  qualityText?: Maybe<Scalars['String']>
  query: Query
  returnsLink?: Maybe<Scalars['String']>
  returnsText?: Maybe<Scalars['String']>
  rewardPointsLink?: Maybe<Scalars['String']>
  rewardPointsText?: Maybe<Scalars['String']>
  saturdayCutoff?: Maybe<Scalars['String']>
  storeLink?: Maybe<Scalars['String']>
  storeText?: Maybe<Scalars['String']>
  sundayCutoff?: Maybe<Scalars['String']>
  thursdayCutoff?: Maybe<Scalars['String']>
  timezoneOffset?: Maybe<Scalars['String']>
  trustPilotLink?: Maybe<Scalars['String']>
  trustPilotText?: Maybe<Scalars['String']>
  tuesdayCutoff?: Maybe<Scalars['String']>
  wednesdayCutoff?: Maybe<Scalars['String']>
}

export type ResponsiveUspBarCustomisable = Widget & {
  __typename?: 'ResponsiveUSPBarCustomisable'
  calendarLink?: Maybe<Scalars['String']>
  calendarPosition?: Maybe<Scalars['Int']>
  calendarText?: Maybe<Scalars['String']>
  callIcon?: Maybe<Scalars['String']>
  callLink?: Maybe<Scalars['String']>
  callPosition?: Maybe<Scalars['Int']>
  callText?: Maybe<Scalars['String']>
  checkmarkLink?: Maybe<Scalars['String']>
  checkmarkPosition?: Maybe<Scalars['Int']>
  checkmarkText?: Maybe<Scalars['String']>
  clickAndCollectLink?: Maybe<Scalars['String']>
  clickAndCollectPosition?: Maybe<Scalars['Int']>
  clickAndCollectText?: Maybe<Scalars['String']>
  countdownLink?: Maybe<Scalars['String']>
  countdownPosition?: Maybe<Scalars['Int']>
  countdownText?: Maybe<Scalars['String']>
  deliveryLink?: Maybe<Scalars['String']>
  deliveryPosition?: Maybe<Scalars['Int']>
  deliveryText?: Maybe<Scalars['String']>
  emailLink?: Maybe<Scalars['String']>
  emailPosition?: Maybe<Scalars['Int']>
  emailText?: Maybe<Scalars['String']>
  fastTrackLink?: Maybe<Scalars['String']>
  fastTrackPosition?: Maybe<Scalars['Int']>
  fastTrackText?: Maybe<Scalars['String']>
  fridayCutoff?: Maybe<Scalars['String']>
  id: Scalars['ID']
  internationalDeliveryLink?: Maybe<Scalars['String']>
  internationalDeliveryPosition?: Maybe<Scalars['Int']>
  internationalDeliveryText?: Maybe<Scalars['String']>
  mondayCutoff?: Maybe<Scalars['String']>
  qualityLink?: Maybe<Scalars['String']>
  qualityPosition?: Maybe<Scalars['Int']>
  qualityText?: Maybe<Scalars['String']>
  query: Query
  rewardPointsLink?: Maybe<Scalars['String']>
  rewardPointsPosition?: Maybe<Scalars['Int']>
  rewardPointsText?: Maybe<Scalars['String']>
  saturdayCutoff?: Maybe<Scalars['String']>
  storeLink?: Maybe<Scalars['String']>
  storePosition?: Maybe<Scalars['Int']>
  storeText?: Maybe<Scalars['String']>
  sundayCutoff?: Maybe<Scalars['String']>
  thursdayCutoff?: Maybe<Scalars['String']>
  timezoneOffset?: Maybe<Scalars['String']>
  trustPilotLink?: Maybe<Scalars['String']>
  trustPilotPosition?: Maybe<Scalars['Int']>
  trustPilotText?: Maybe<Scalars['String']>
  tuesdayCutoff?: Maybe<Scalars['String']>
  wednesdayCutoff?: Maybe<Scalars['String']>
  backgroundColor?: Maybe<Scalars['String']>
}

export type ReturnsEligibilityInput = {
  skus: Array<Scalars['SKU']>
}

export enum ReturnsEligibilityOrderError {
  /** The provided address is not valid */
  InvalidAddress = 'INVALID_ADDRESS',
  /** The selected products are invalid for return */
  OrderProductsInvalid = 'ORDER_PRODUCTS_INVALID',
  /** Unrecognised error found */
  UnknownError = 'UNKNOWN_ERROR',
  /** The value of the selected products is too high */
  ValueTooHigh = 'VALUE_TOO_HIGH',
}

export enum ReturnsEligibilityProductError {
  /** The product is a dropship */
  DropshipOrderProduct = 'DROPSHIP_ORDER_PRODUCT',
  /** The product's delivery date is unknown */
  ItemDeliveryDateUnknown = 'ITEM_DELIVERY_DATE_UNKNOWN',
  /** The product's return date has expired */
  ItemExpired = 'ITEM_EXPIRED',
  /** The product has not been dispatched yet */
  ItemNotDispatched = 'ITEM_NOT_DISPATCHED',
  /** The product is not available for return */
  OrderProductUnavailable = 'ORDER_PRODUCT_UNAVAILABLE',
  /** The product is not in the order */
  ProductNotInOrder = 'PRODUCT_NOT_IN_ORDER',
  /** The product type is not valid */
  ProductTypeInvalid = 'PRODUCT_TYPE_INVALID',
  /** Unrecognised error found */
  UnknownError = 'UNKNOWN_ERROR',
}

export type ReturnsEligibilityResult = {
  __typename?: 'ReturnsEligibilityResult'
  orderError?: Maybe<ReturnsEligibilityOrderError>
  productErrors?: Maybe<Array<ReturnsEligibilitySkuAndProductError>>
  success: Scalars['Boolean']
}

export type ReturnsEligibilitySkuAndProductError = {
  __typename?: 'ReturnsEligibilitySkuAndProductError'
  error?: Maybe<ReturnsEligibilityProductError>
  sku: Scalars['SKU']
}

export type ReturnUrlInput = {
  skus: Array<Scalars['SKU']>
}

export type RevieveWidget = Widget & {
  __typename?: 'revieveWidget'
  id: Scalars['ID']
  query: Query
}

export type Review = {
  __typename?: 'Review'
  authorName: Scalars['DisplayString']
  elements: Array<ReviewElement>
  id: Scalars['ID']
  negativeVotes?: Maybe<Scalars['Int']>
  positiveVotes?: Maybe<Scalars['Int']>
  posted: Scalars['Date']
  title: Scalars['DisplayString']
  verifiedPurchase: Scalars['Boolean']
}

export enum ReviewActionResult {
  AlreadyVoted = 'ALREADY_VOTED',
  InvalidReviewId = 'INVALID_REVIEW_ID',
  ReviewNotFound = 'REVIEW_NOT_FOUND',
  Successful = 'SUCCESSFUL',
}

export type ReviewElement = {
  key: Scalars['String']
}

export type ReviewFieldInput = {
  isProductSpecific?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  value: Scalars['String']
}

export type ReviewMeasurementValue = {
  __typename?: 'ReviewMeasurementValue'
  label: Scalars['DisplayString']
  mappingValue: Scalars['Int']
  order: Scalars['Int']
  value: Scalars['String']
}

export type Reviews = {
  __typename?: 'Reviews'
  hasMore: Scalars['Boolean']
  reviews: Array<Review>
  total: Scalars['Int']
}

export type ReviewsFilterInput = {
  scores: Array<Scalars['Int']>
}

export enum ReviewSort {
  HighestToLowestRating = 'HIGHEST_TO_LOWEST_RATING',
  MostToLeastPositiveVotes = 'MOST_TO_LEAST_POSITIVE_VOTES',
  NewestToOldest = 'NEWEST_TO_OLDEST',
}

export type ReviewTemplate = {
  __typename?: 'ReviewTemplate'
  features: Array<ReviewTemplateFeature>
  name: Scalars['ID']
}

export type ReviewTemplateFeature = {
  __typename?: 'ReviewTemplateFeature'
  hasDefaultMeasurement: Scalars['Boolean']
  label: Scalars['DisplayString']
  measurementValues: Array<ReviewMeasurementValue>
  name: Scalars['ID']
}

export type ReviewVotingInput = {
  reviewId: Scalars['ID']
  sku: Scalars['SKU']
}

export enum RewardCardError {
  InvalidCardNumber = 'INVALID_CARD_NUMBER',
  ExistingCardNumber = 'EXISTING_CARD_NUMBER',
  UnexpectedError = 'UNEXPECTED_ERROR',
}

export type RewardOffer = {
  __typename?: 'RewardOffer'
  offerTitle: Scalars['String']
  startDate?: Maybe<Scalars['Timestamp']>
  endDate: Scalars['Timestamp']
  offerMessage: Scalars['DisplayString']
  offerImageUrl: Scalars['URL']
  link: Scalars['URL']
  linkText: Scalars['DisplayString']
  onlineCode?: Maybe<Scalars['String']>
  storeCode?: Maybe<Scalars['String']>
  storeCodeType?: Maybe<Scalars['String']>
}

export type RichContent = {
  __typename?: 'RichContent'
  content: Array<RichContentItem>
}

export type RichContentContentArgs = {
  filter?: Maybe<Array<RichContentType>>
}

export type RichContentItem = {
  __typename?: 'RichContentItem'
  content: Scalars['DisplayString']
  type: RichContentType
}

export enum RichContentType {
  Html = 'HTML',
  Markdown = 'MARKDOWN',
}

export type SearchResult = {
  __typename?: 'SearchResult'
  /**
   * If this is non-null, 0 results were found, but results were found for a similar query,
   * which was returned instead.
   * @deprecated pulling in ProductList -> clients will need to migrate to new query
   */
  correctedQuery?: Maybe<Scalars['DisplayString']>
  /** @deprecated pulling in ProductList -> clients will need to migrate to new query */
  facets: Array<Facet>
  /** @deprecated pulling in ProductList -> clients will need to migrate to new query */
  hasMore: Scalars['Boolean']
  productList?: Maybe<ProductList>
  /** @deprecated pulling in ProductList -> clients will need to migrate to new query */
  products: Array<Product>
  searchRedirect?: Maybe<Scalars['URL']>
  /** @deprecated pulling in ProductList -> clients will need to migrate to new query */
  total: Scalars['Int']
}

/**
 * One of our offer types allows the customer to select a gift at the basket. These
 * can also be tiered to allow for multiple gifts at different thresholds.
 */
export type SelectYourSample = {
  __typename?: 'SelectYourSample'
  /** Total amount currently spent on products meeting the requirements for this Select Your Sample */
  currentAmountSpent: MoneyValue
  id: Scalars['ID']
  message?: Maybe<Scalars['DisplayString']>
  tiers: Array<SelectYourSampleTier>
  title?: Maybe<Scalars['DisplayString']>
}

export type SelectYourSampleTier = {
  __typename?: 'SelectYourSampleTier'
  /**
   * The amount of additional money the customer must spend on qualifying products to reach this
   * tier, or null if the tier is already reached.
   */
  additionalSpendToReach?: Maybe<MoneyValue>
  id: Scalars['ID']
  maxSelectedProducts: Scalars['Int']
  /** @deprecated Use sysProducts instead */
  products: Array<ProductVariant>
  /** @deprecated Use selectedSysProducts instead */
  selectedProducts: Array<ProductVariant>
  selectedSysProducts: SysProducts
  sysProducts: SysProducts
  thresholdAmountSpent: MoneyValue
}

export type SeoProductCategory = Widget & {
  __typename?: 'SeoProductCategory'
  buttonStyle?: Maybe<Scalars['String']>
  id: Scalars['ID']
  productCategoryName?: Maybe<Scalars['String']>
  productCategoryUrl?: Maybe<Scalars['String']>
  query: Query
}

export type SeoProductCategorySet = Widget & {
  __typename?: 'SeoProductCategorySet'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
  showDiscoverTag?: Maybe<Scalars['String']>
  showMore?: Maybe<Scalars['String']>
}

/** Currency and Shipping Destination are passed in as this can affect the price of products after offer evaluation */
export type SessionSettings = {
  currency: Currency
  shippingDestination: Country
}

export type SettingsFieldInput = {
  fieldName: Scalars['String']
  /** If the value is passed as null, it means it existed before and needs deleting. */
  value?: Maybe<Scalars['String']>
}

export type ShadeFinder = Widget & {
  __typename?: 'shadeFinder'
  buttonText?: Maybe<Scalars['String']>
  id: Scalars['ID']
  openOnLoad?: Maybe<Scalars['String']>
  query: Query
  text?: Maybe<RichContent>
  title?: Maybe<Scalars['String']>
  widgetAlignment?: Maybe<Scalars['String']>
}

export type ShopTheLook = Widget & {
  __typename?: 'shopTheLook'
  altImageLarge?: Maybe<Scalars['String']>
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  imageLarge?: Maybe<Scalars['URL']>
  imageMedium?: Maybe<Scalars['URL']>
  imageSmall?: Maybe<Scalars['URL']>
  itemDescription?: Maybe<Scalars['String']>
  itemTitle?: Maybe<Scalars['String']>
  query: Query
}

export type ShopTheLookHotSpot = Widget & {
  __typename?: 'ShopTheLookHotSpot'
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  SKU?: Maybe<ProductVariant>
  SKUNew?: Maybe<WidgetProductItem>
  hiddenMd?: Maybe<Scalars['String']>
  hiddenSm?: Maybe<Scalars['String']>
  id: Scalars['ID']
  itemLeft?: Maybe<Scalars['String']>
  itemLeftMd?: Maybe<Scalars['String']>
  itemLeftSm?: Maybe<Scalars['String']>
  itemTop?: Maybe<Scalars['String']>
  itemTopMd?: Maybe<Scalars['String']>
  itemTopSm?: Maybe<Scalars['String']>
  productDescription?: Maybe<Scalars['String']>
  query: Query
  rangeList?: Maybe<Scalars['String']>
}

export type SignUpForEmailMarketingCampaignInput = {
  auditData: MarketingConsentAuditData
  /** The ID of the marketing campaign being signed up to. Currently must be an Integer */
  campaignId: Scalars['ID']
  email: Scalars['String']
  name: Scalars['String']
  marketingOptIn: Scalars['Boolean']
}

export type SignUpForMarketingInput = {
  auditData: MarketingConsentAuditData
  /** Interpreted as a phone number or email address depending on the provided MarketingType */
  contactDetails: Scalars['String']
  type: MarketingType
}

export enum SignUpResult {
  Duplicate = 'DUPLICATE',
  NotFound = 'NOT_FOUND',
  Ok = 'OK',
  RequiresVerification = 'REQUIRES_VERIFICATION',
  UnknownError = 'UNKNOWN_ERROR',
}

export type SimpleCta = Widget & {
  __typename?: 'simpleCTA'
  CTAStyle?: Maybe<Scalars['String']>
  CTAText?: Maybe<Scalars['String']>
  CTAUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  secondCTAStyle?: Maybe<Scalars['String']>
  secondCTAText?: Maybe<Scalars['String']>
  secondCTAUrl?: Maybe<Scalars['String']>
  simpleCTABackgroundColour?: Maybe<Scalars['String']>
  simpleCTABackgroundIsFullWidth?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
}

export type SimpleDivider = Widget & {
  __typename?: 'simpleDivider'
  id: Scalars['ID']
  marginBottom?: Maybe<Scalars['Int']>
  marginTop?: Maybe<Scalars['Int']>
  maxWidth?: Maybe<Scalars['Int']>
  overrideStyles?: Maybe<Scalars['Boolean']>
  query: Query
}

export type SimpleFacet = Facet & {
  __typename?: 'SimpleFacet'
  facetHeader: Scalars['DisplayString']
  facetName: Scalars['String']
  options: Array<SimpleFacetOption>
}

export type SimpleFacetOption = {
  __typename?: 'SimpleFacetOption'
  displayName: Scalars['DisplayString']
  matchedProductCount: Scalars['Int']
  optionName: Scalars['String']
}

export type SimpleTextReadMore = Widget & {
  __typename?: 'SimpleTextReadMore'
  id: Scalars['ID']
  query: Query
  simpleTextReadMore_maxHeight?: Maybe<Scalars['Int']>
  simpleTextReadMore_text?: Maybe<RichContent>
  simpleTextReadMore_textAlign?: Maybe<Scalars['String']>
  simpleTextReadMore_title?: Maybe<Scalars['String']>
  simpleTextReadMore_titleIsH1?: Maybe<Scalars['Boolean']>
}

export type Simpletextwidget = Widget & {
  __typename?: 'simpletextwidget'
  displayOnDesktopOnly?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  query: Query
  text?: Maybe<RichContent>
  textAlign?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type SimpleTextWidgetSet = Widget & {
  __typename?: 'simpleTextWidgetSet'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
}

export type Simpletextwidgetwithh1 = Widget & {
  __typename?: 'simpletextwidgetwithh1'
  displayOnDesktopOnly?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  query: Query
  text?: Maybe<RichContent>
  textAlign?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type SimpleTitleWidget = Widget & {
  __typename?: 'simpleTitleWidget'
  additionalSubtitleStyles?: Maybe<Scalars['String']>
  additionalTitleStyles?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  subtitle?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
  textAlign?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type Sitemap = {
  __typename?: 'Sitemap'
  hasMore: Scalars['Boolean']
  paths: Array<Scalars['PagePath']>
  total: Scalars['Int']
}

export type SiteProperties = {
  __typename?: 'SiteProperties'
  /**
   * If keys is provided, the result will be limited to only the given property keys,
   * otherwise, all properties will be returned
   */
  properties?: Maybe<Array<Maybe<LocalizedString>>>
}

export type SitePropertiesPropertiesArgs = {
  keys?: Maybe<Array<Scalars['String']>>
}

export type SliderFacet = Facet & {
  __typename?: 'SliderFacet'
  facetHeader: Scalars['DisplayString']
  facetName: Scalars['String']
  maxValue: Scalars['Float']
  minValue: Scalars['Float']
}

export type SocialAccount = {
  __typename?: 'SocialAccount'
  socialNetwork: SocialNetwork
  url: Scalars['URL']
}

/**
 * This will be used on generated widgets with a UGC-type parameter.
 *
 * The field will look as follows:
 *
 * userGeneratedContent(offset: Int! = 0, limit: Int! = 5): SocialAssets
 */
export type SocialAsset = {
  __typename?: 'SocialAsset'
  caption: Scalars['String']
  hashtags: Array<Scalars['String']>
  largeImage: Scalars['URL']
  mediumImage: Scalars['URL']
  postUrl: Scalars['URL']
  providerName: Scalars['String']
  smallImage: Scalars['URL']
  thumbnailImage: Scalars['URL']
  user: SocialAssetUser
}

export type SocialAssets = {
  __typename?: 'SocialAssets'
  hasMore: Scalars['Boolean']
  socialAssets: Array<SocialAsset>
  total: Scalars['Int']
}

export type SocialAssetUser = {
  __typename?: 'SocialAssetUser'
  /** Full Name may or may not be provided based on the social network */
  fullName?: Maybe<Scalars['String']>
  profileImage: Scalars['URL']
  profileUrl: Scalars['URL']
  username: Scalars['String']
}

export type SocialAuthenticationResponse = {
  __typename?: 'SocialAuthenticationResponse'
  authenticationResponse: AuthenticationResponse
  /**
   * In the case that the social login failed due to an INVALID_DATA error, the 'form' field will be non-null,
   * and will contain a form containing only the missing or invalid fields required.
   */
  form?: Maybe<Form>
  /**
   * In the case that only Marketing Opt-In is required for Account Creation, when a social login is attempted
   * then the Account will be created successfully and this object will be non-null.
   * In which case you should render an opt-in page and submit the result with the updateMarketingPreferences mutation
   */
  optInForm: OptInForm
  socialIdentity?: Maybe<SocialIdentity>
  socialLinkId?: Maybe<Scalars['SocialLinkID']>
  /**
   * In the case that the social login failed, the 'socialLoginToken' field will be non-null, and will contain
   * a token which can be used in the socialLogin mutation to retry social login with additional or different data
   */
  socialLoginToken?: Maybe<Scalars['SocialAuthenticationToken']>
  /** @deprecated Use socialLinkID instead */
  socialUserId?: Maybe<Scalars['SocialLinkID']>
}

export type SocialIdentity = {
  __typename?: 'SocialIdentity'
  email?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
}

export type SocialLink = {
  __typename?: 'SocialLink'
  socialLinkId: Scalars['SocialLinkID']
  socialLoginProvider?: Maybe<SocialLoginProvider>
  status: SocialLinkStatus
  username?: Maybe<Scalars['String']>
}

export enum SocialLinkStatus {
  Linked = 'LINKED',
  Pending = 'PENDING',
}

export type SocialLoginInput = {
  missingInformation?: Maybe<SocialLoginMissingInformation>
  /** Should we offer opt-in before or after Account Creation */
  optInAfterAccountCreation?: Scalars['Boolean']
  socialAuthenticationToken: Scalars['SocialAuthenticationToken']
}

/**
 * All input fields for social login are optional.  Which ones are actually needed depends on the information provided
 * by the social provider.
 *
 * The required fields can be determined using the Form from the SocialAuthenticationResponse type.
 */
export type SocialLoginMissingInformation = {
  dateOfBirth?: Maybe<Scalars['Date']>
  email?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  loyaltyOptIn?: Maybe<Scalars['Boolean']>
  marketingConsent?: Maybe<Scalars['String']>
  marketingConsentAuditData?: Maybe<MarketingConsentAuditData>
  phoneNumber?: Maybe<Scalars['String']>
  referralCode?: Maybe<Scalars['String']>
}

export type SocialLoginProvider = {
  __typename?: 'SocialLoginProvider'
  /** A unique identifier for this social provider. */
  code: Scalars['String']
  colour: Scalars['String']
  iconUrl: Scalars['String']
  /**
   * A URL for the social login button to link to.  This URL comes with some already existing parameters,
   * but must be updated to add the following query parameters:
   *
   * clientState:  A string which will be returned once the user returns from the social login provider.  This can be
   *   used to store information that needs to be remembered from the start of their journey when they return, such as
   *   a CSRF token.
   *
   * returnUrl: A full URL (Including protocol) which the user will return to when they return from the social login
   *   provider (e.g. https://www.mydomain.com/socialLogin)
   *
   * The user will return from the social provider via a POST request to the returnUrl.  Their request body will be
   * form encoded, and contain the following data:
   *
   * token: A SocialAuthenticationToken, which can be used in this API via the exchangeSocialAuthenticationToken and
   * socialLogin fields.
   *
   * clientState: The same client state provided as a parameter to the URL.
   */
  loginUrl: Scalars['String']
  /** The text to display on the social login button, if applicable */
  name: Scalars['DisplayString']
}

export enum SocialNetwork {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  KakaoTalk = 'KAKAO_TALK',
  Line = 'LINE',
  Pinterest = 'PINTEREST',
  Tiktok = 'TIKTOK',
  TwentyoneButtons = 'TWENTYONE_BUTTONS',
  Twitter = 'TWITTER',
  Vk = 'VK',
  Weibo = 'WEIBO',
  Whatsapp = 'WHATSAPP',
  Youtube = 'YOUTUBE',
}

export type SocialReferralMethod = {
  __typename?: 'SocialReferralMethod'
  type: SocialReferralMethodType
  url?: Maybe<Scalars['String']>
}

export enum SocialReferralMethodType {
  Email = 'EMAIL',
  Facebook = 'FACEBOOK',
  Messenger = 'MESSENGER',
  Sms = 'SMS',
  Twitter = 'TWITTER',
  Vk = 'VK',
  Weibo = 'WEIBO',
  Whatsapp = 'WHATSAPP',
}

export type SponsoredProducts = Widget & {
  __typename?: 'sponsoredProducts'
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  ProductOne?: Maybe<ProductVariant>
  ProductOneNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  ProductTwo?: Maybe<ProductVariant>
  ProductTwoNew?: Maybe<WidgetProductItem>
  altLogoPng?: Maybe<Scalars['String']>
  contentTheme?: Maybe<Scalars['String']>
  ctaOne?: Maybe<Scalars['String']>
  ctaOneAriaLabel?: Maybe<Scalars['String']>
  ctaOneURL?: Maybe<Scalars['String']>
  headline?: Maybe<Scalars['String']>
  id: Scalars['ID']
  logopngImageBG?: Maybe<Scalars['URL']>
  logopngWhiteBG?: Maybe<Scalars['URL']>
  query: Query
  text?: Maybe<Scalars['String']>
  useH1?: Maybe<Scalars['String']>
}

export type SponsoredProductsCriteoHomepage = Widget & {
  __typename?: 'sponsoredProductsCriteoHomepage'
  id: Scalars['ID']
  query: Query
}

export type SponsoredProductsNew = Widget & {
  __typename?: 'sponsoredProductsNew'
  id: Scalars['ID']
  query: Query
  widgetVersion?: Maybe<Scalars['String']>
}

/**
 * [ISO_3166-2](https://en.wikipedia.org/wiki/ISO_3166-2) state code.
 * The non-standard codes `US_AA`, `US_AE` and `US_AP` represent Armed Forces locations, similar to BFPO.
 * Puerto Rico, US Virgin Islands etc. are not listed here as we treat them as separate countries.
 */
export enum State {
  /** Armed Forces Americas */
  UsAa = 'US_AA',
  /** Armed Forces Europe */
  UsAe = 'US_AE',
  UsAk = 'US_AK',
  UsAl = 'US_AL',
  /** Armed Forces Pacific */
  UsAp = 'US_AP',
  UsAr = 'US_AR',
  UsAz = 'US_AZ',
  UsCa = 'US_CA',
  UsCo = 'US_CO',
  UsCt = 'US_CT',
  UsDc = 'US_DC',
  UsDe = 'US_DE',
  UsFl = 'US_FL',
  UsGa = 'US_GA',
  UsHi = 'US_HI',
  UsIa = 'US_IA',
  UsId = 'US_ID',
  UsIl = 'US_IL',
  UsIn = 'US_IN',
  UsKs = 'US_KS',
  UsKy = 'US_KY',
  UsLa = 'US_LA',
  UsMa = 'US_MA',
  UsMd = 'US_MD',
  UsMe = 'US_ME',
  UsMi = 'US_MI',
  UsMn = 'US_MN',
  UsMo = 'US_MO',
  UsMs = 'US_MS',
  UsMt = 'US_MT',
  UsNc = 'US_NC',
  UsNd = 'US_ND',
  UsNe = 'US_NE',
  UsNh = 'US_NH',
  UsNj = 'US_NJ',
  UsNm = 'US_NM',
  UsNv = 'US_NV',
  UsNy = 'US_NY',
  UsOh = 'US_OH',
  UsOk = 'US_OK',
  UsOr = 'US_OR',
  UsPa = 'US_PA',
  UsRi = 'US_RI',
  UsSc = 'US_SC',
  UsSd = 'US_SD',
  UsTn = 'US_TN',
  UsTx = 'US_TX',
  UsUt = 'US_UT',
  UsVa = 'US_VA',
  UsVt = 'US_VT',
  UsWa = 'US_WA',
  UsWi = 'US_WI',
  UsWv = 'US_WV',
  UsWy = 'US_WY',
}

export enum StatusType {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
}

export type Store = {
  __typename?: 'Store'
  id: Scalars['ID']
  displayName: Scalars['DisplayString']
  urlTag: Scalars['String']
  phoneNumber: Scalars['String']
  openingTimes: Array<StoreOpeningTime>
  address: Address
  longitude: Scalars['Float']
  latitude: Scalars['Float']
  fulfilmentMethods: Array<FulfilmentMethod>
  nearbyStores: Array<Store>
}

export type StoreNearbyStoresArgs = {
  limit?: Scalars['Int']
}

export type StoreOpeningTime = {
  __typename?: 'StoreOpeningTime'
  day: DayOfWeek
  openingTime: Scalars['DisplayString']
  closingTime: Scalars['DisplayString']
}

export type Story = {
  __typename?: 'Story'
  id: Scalars['Int']
  title: Scalars['String']
  publishedAt: Scalars['String']
  outfits: Array<Outfit>
  products: Array<Product>
}

export type StoryOutfitsArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type StoryProductsArgs = {
  offset?: Scalars['Int']
  limit?: Scalars['Int']
}

export type StoryStreamWidget = Widget & {
  __typename?: 'storyStreamWidget'
  id: Scalars['ID']
  integrityHash?: Maybe<Scalars['String']>
  layoutClass?: Maybe<Scalars['String']>
  query: Query
  storyStreamId?: Maybe<Scalars['String']>
}

export type SubscribeAndSaveInformationModal = Widget & {
  __typename?: 'subscribeAndSaveInformationModal'
  id: Scalars['ID']
  imageAltText?: Maybe<Scalars['String']>
  imageLarge?: Maybe<Scalars['URL']>
  imageSmall?: Maybe<Scalars['URL']>
  modalContent?: Maybe<RichContent>
  modalTitle?: Maybe<Scalars['String']>
  query: Query
}

export type SubscriptionContract = {
  __typename?: 'SubscriptionContract'
  /** @deprecated Use frequencyDuration instead */
  frequency: SubscriptionFrequency
  frequencyDuration: SubscriptionFrequencyDuration
  id: Scalars['ID']
  initialDiscountPercentage: Scalars['Float']
  initialPrice?: Maybe<ProductPrice>
  /** This is the recommended contract and should probably be pre-selected in the client implementation */
  recommended: Scalars['Boolean']
  recurringDiscountPercentage: Scalars['Float']
  recurringPrice?: Maybe<ProductPrice>
  upsellMessage: Scalars['DisplayString']
}

export type SubscriptionContractInitialPriceArgs = {
  currency: Currency
  shippingDestination: Country
}

export type SubscriptionContractRecurringPriceArgs = {
  currency: Currency
  shippingDestination: Country
}

export enum SubscriptionDelay {
  DelayedOneMonth = 'DELAYED_ONE_MONTH',
  DelayedTwoMonths = 'DELAYED_TWO_MONTHS',
  NotDelayed = 'NOT_DELAYED',
  ProcessingDelayRequest = 'PROCESSING_DELAY_REQUEST',
}

/** The same day of the period each time (e.g. the nth day of each week/month/year) */
export enum SubscriptionFrequency {
  Annually = 'ANNUALLY',
  EveryFourMonths = 'EVERY_FOUR_MONTHS',
  EverySixMonths = 'EVERY_SIX_MONTHS',
  EveryTwoMonths = 'EVERY_TWO_MONTHS',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
  EveryTwoWeeks = 'EVERY_TWO_WEEKS',
  EveryThreeWeeks = 'EVERY_THREE_WEEKS',
}

export type SubscriptionFrequencyDuration = {
  __typename?: 'SubscriptionFrequencyDuration'
  duration: Scalars['Int']
  unit: SubscriptionFrequencyUnit
}

export type SubscriptionFrequencyDurationInput = {
  duration: Scalars['Int']
  unit: SubscriptionFrequencyUnit
}

export enum SubscriptionFrequencyUnit {
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR',
}

export type SubscriptionItem = {
  __typename?: 'SubscriptionItem'
  product: Product
  quantity: Scalars['Int']
  selectedVariant?: Maybe<ProductVariant>
}

export enum SubscriptionPaymentType {
  OnDispatch = 'ON_DISPATCH',
  OnOrder = 'ON_ORDER',
  Upfront = 'UPFRONT',
}

export type Subscriptions = {
  __typename?: 'Subscriptions'
  hasMore: Scalars['Boolean']
  subscriptions: Array<ProductSubscription>
  total: Scalars['Int']
}

export type SubscriptionSchedule = {
  __typename?: 'SubscriptionSchedule'
  autoRenew: Scalars['Boolean']
  /** @deprecated Use dispatchFrequencyDuration instead */
  dispatchFrequency: SubscriptionFrequency
  dispatchFrequencyDuration: SubscriptionFrequencyDuration
  /** @deprecated Use paymentFrequencyDuration instead */
  paymentFrequency?: Maybe<SubscriptionFrequency>
  paymentFrequencyDuration?: Maybe<SubscriptionFrequencyDuration>
  paymentType: SubscriptionPaymentType
  /** The number of orders to be placed in total. */
  totalDeliveries: Scalars['Int']
}

export type SubscriptionsFilterInput = {
  id?: Maybe<Scalars['ID']>
  status?: Maybe<SubscriptionStatus>
}

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Renewal = 'RENEWAL',
  Complete = 'COMPLETE',
  FailedPayment = 'FAILED_PAYMENT',
  New = 'NEW',
  NoticePeriod = 'NOTICE_PERIOD',
  OnHold = 'ON_HOLD',
  /** The subscription order has been placed, but has yet to be processed. */
  Preprocessed = 'PREPROCESSED',
}

export type Supersize = {
  __typename?: 'Supersize'
  saving: SupersizeSaving
  variant: ProductVariant
}

export type SupersizeSaving = {
  __typename?: 'SupersizeSaving'
  /** E.g. next supersize option is 10 kg */
  nextSupersizeOption: ProductUnit
  /** Saving for per unit, e.g. "£2" per 1 kg or per 20 servings */
  savingPerUnit: MoneyValue
  /** The unit for the saving, e.g. "1 kg" in saving £2 per 1 kg */
  savingUnit: ProductUnit
}

export type SwatchSelectSlider = Widget & {
  __typename?: 'swatchSelectSlider'
  altTextImageFour?: Maybe<Scalars['String']>
  altTextImageOne?: Maybe<Scalars['String']>
  altTextImageThree?: Maybe<Scalars['String']>
  altTextImageTwo?: Maybe<Scalars['String']>
  altTextLogoImage?: Maybe<Scalars['String']>
  altTextThumbnailImageFour?: Maybe<Scalars['String']>
  altTextThumbnailImageOne?: Maybe<Scalars['String']>
  altTextThumbnailImageThree?: Maybe<Scalars['String']>
  altTextThumbnailImageTwo?: Maybe<Scalars['String']>
  count?: Maybe<Scalars['String']>
  cta?: Maybe<Scalars['String']>
  ctaLink?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageFourLink?: Maybe<Scalars['String']>
  imageFourPath?: Maybe<Scalars['URL']>
  imageOneLink?: Maybe<Scalars['String']>
  imageOnePath?: Maybe<Scalars['URL']>
  imageThreeLink?: Maybe<Scalars['String']>
  imageThreePath?: Maybe<Scalars['URL']>
  imageTwoLink?: Maybe<Scalars['String']>
  imageTwoPath?: Maybe<Scalars['URL']>
  info?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  linkText?: Maybe<Scalars['String']>
  logoImagePath?: Maybe<Scalars['URL']>
  parentWidgetLink?: Maybe<Scalars['String']>
  query: Query
  thumbnailImageFourPath?: Maybe<Scalars['URL']>
  thumbnailImageOnePath?: Maybe<Scalars['URL']>
  thumbnailImageThreePath?: Maybe<Scalars['URL']>
  thumbnailImageTwoPath?: Maybe<Scalars['URL']>
  titleFour?: Maybe<Scalars['String']>
  titleOne?: Maybe<Scalars['String']>
  titleThree?: Maybe<Scalars['String']>
  titleTwo?: Maybe<Scalars['String']>
}

export type SwatchSelectSliderSet = Widget & {
  __typename?: 'swatchSelectSliderSet'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
}

export type SysProducts = {
  __typename?: 'SysProducts'
  productVariants: Array<ProductVariant>
  products: Array<Product>
}

export type TestCheckoutStartInput = {
  checkoutStartInput: CheckoutStartInput
  elysiumCheckoutOrder?: Maybe<Scalars['String']>
}

export type TestGuestCheckoutStartInput = {
  guestCheckoutEmailInput: GuestCheckoutEmailInput
  testCheckoutStartInput: TestCheckoutStartInput
}

export type TextReviewElement = ReviewElement & {
  __typename?: 'TextReviewElement'
  key: Scalars['String']
  value: Scalars['String']
}

export type TokenMarketingConsentAuditData = {
  landingUrl: Scalars['URL']
}

export type TokenMarketingInput = {
  auditData: TokenMarketingConsentAuditData
  /** This is actually a hash of the email address but is in the unsubscribe link as the email parameter */
  email: Scalars['String']
  /** This is the uuid parameter in the standard unsubscribe link */
  token: Scalars['MarketingToken']
}

export type TopBrandsWidget = Widget & {
  __typename?: 'TopBrandsWidget'
  BrandEightLogoPath?: Maybe<Scalars['URL']>
  BrandEightName?: Maybe<Scalars['String']>
  BrandFiveLogoPath?: Maybe<Scalars['URL']>
  BrandFiveName?: Maybe<Scalars['String']>
  BrandFourLogoPath?: Maybe<Scalars['URL']>
  BrandFourName?: Maybe<Scalars['String']>
  BrandOneLogoPath?: Maybe<Scalars['URL']>
  BrandOneName?: Maybe<Scalars['String']>
  BrandSevenLogoPath?: Maybe<Scalars['URL']>
  BrandSevenName?: Maybe<Scalars['String']>
  BrandSixLogoPath?: Maybe<Scalars['URL']>
  BrandSixName?: Maybe<Scalars['String']>
  BrandTabTitle?: Maybe<Scalars['String']>
  BrandThreeLogoPath?: Maybe<Scalars['URL']>
  BrandThreeName?: Maybe<Scalars['String']>
  BrandTwoLogoPath?: Maybe<Scalars['URL']>
  BrandTwoName?: Maybe<Scalars['String']>
  altTextBrandEightLogo?: Maybe<Scalars['String']>
  altTextBrandFiveLogo?: Maybe<Scalars['String']>
  altTextBrandFourLogo?: Maybe<Scalars['String']>
  altTextBrandOneLogo?: Maybe<Scalars['String']>
  altTextBrandSevenLogo?: Maybe<Scalars['String']>
  altTextBrandSixLogo?: Maybe<Scalars['String']>
  altTextBrandThreeLogo?: Maybe<Scalars['String']>
  altTextBrandTwoLogo?: Maybe<Scalars['String']>
  altTextFeaturedImage?: Maybe<Scalars['String']>
  altTextFeaturedLogo?: Maybe<Scalars['String']>
  featuredBrandName?: Maybe<Scalars['String']>
  featuredBrandPageLink?: Maybe<Scalars['String']>
  featuredBrandPageLinkName?: Maybe<Scalars['String']>
  featuredBrandText?: Maybe<Scalars['String']>
  featuredImagePath?: Maybe<Scalars['URL']>
  featuredLogoPath?: Maybe<Scalars['URL']>
  id: Scalars['ID']
  query: Query
}

export type TopProductCategory = Widget & {
  __typename?: 'TopProductCategory'
  buttonStyle?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  topProductCategoryName?: Maybe<Scalars['String']>
  topProductCategoryUrl?: Maybe<Scalars['String']>
}

export type TopProductCategorySet = Widget & {
  __typename?: 'TopProductCategorySet'
  DiscoverTitle?: Maybe<Scalars['String']>
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
  showDiscoverTag?: Maybe<Scalars['String']>
  showMore?: Maybe<Scalars['String']>
}

export type TrustPilotCarouselWidget = Widget & {
  __typename?: 'TrustPilotCarouselWidget'
  businessId?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['String']>
  id: Scalars['ID']
  languageCode?: Maybe<Scalars['String']>
  locale?: Maybe<Scalars['String']>
  query: Query
  templateId?: Maybe<Scalars['String']>
  theme?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['String']>
  dataStars?: Maybe<Scalars['String']>
}

export type TrustPilotWidget = Widget & {
  __typename?: 'TrustPilotWidget'
  businessId?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isCarousel?: Maybe<Scalars['String']>
  languageCode?: Maybe<Scalars['String']>
  locale?: Maybe<Scalars['String']>
  query: Query
  templateId?: Maybe<Scalars['String']>
  theme?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['String']>
}

export type TrustReinforcementContainer = Widget & {
  __typename?: 'trustReinforcementContainer'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']
  query: Query
  showStockInformation?: Maybe<Scalars['Boolean']>
}

export type TrustReinforcementSection = Widget & {
  __typename?: 'trustReinforcementSection'
  icon?: Maybe<Scalars['String']>
  id: Scalars['ID']
  modalBody?: Maybe<RichContent>
  modalTitle?: Maybe<Scalars['String']>
  query: Query
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type UpdateDateOfBirthInput = {
  /** New date of birth. Set to null to remove from account. */
  newDateOfBirth?: Maybe<Scalars['Date']>
}

export type UpdateEmailAddressInput = {
  currentPassword: Scalars['String']
  newEmailAddress: Scalars['String']
}

export enum UpdateMarketingPreferencesError {
  /**
   * There was insufficient data to update this marketing preference, such as
   * attempting to sign up for SMS marketing when the customer has no phone number.
   */
  InsufficientData = 'INSUFFICIENT_DATA',
}

export type UpdateMarketingPreferencesInput = {
  auditData: MarketingConsentAuditData
  newValue: Scalars['Boolean']
  type: MarketingType
}

export type UpdateMarketingPreferencesResponse = {
  __typename?: 'UpdateMarketingPreferencesResponse'
  error?: Maybe<UpdateMarketingPreferencesError>
}

export type UpdateMobilePhoneNumberInput = {
  /** New phone number. Set to null to remove from account. */
  newNumber?: Maybe<Scalars['String']>
}

export type UpdateNameInput = {
  newName?: Maybe<Scalars['String']>
}

export type UpdatePasswordInput = {
  currentPassword: Scalars['String']
  newPassword: Scalars['String']
}

export type UsedPaymentMethod = {
  __typename?: 'UsedPaymentMethod'
  amountSpent: MoneyValue
  giftCard?: Maybe<GiftCard>
  paymentCard?: Maybe<PaymentCard>
  paymentType?: Maybe<Scalars['String']>
}

export type Validator = {
  __typename?: 'Validator'
  argument?: Maybe<Scalars['String']>
  name: ValidatorName
}

export enum ValidatorName {
  /** The argument is an email domain. The field value should be accepted if it follows the format {any prefix}@{given domain} */
  EmailDomain = 'EMAIL_DOMAIN',
  /**
   * The argument is a password and the site enforces password history checks. A
   * password is being reused when a new one is required.
   */
  FailedHistoryRequirements = 'FAILED_HISTORY_REQUIREMENTS',
  /**
   * The argument is comparable with the expected date input in the context of the
   * field type.  The date field value will be rejected if it is older than the
   * date calculated with the argument.
   */
  MaxDaysOld = 'MAX_DAYS_OLD',
  /** The argument is an integer.  The field value should be rejected if its length is greater than the argument. */
  MaxLength = 'MAX_LENGTH',
  /**
   * The argument is an integer.  The field values will be rejected if there are more options selected than are allowed here.
   * This validator is mainly for use with multiselect and likert scale fields
   */
  MaxOptions = 'MAX_OPTIONS',
  MaxUploadSizeKb = 'MAX_UPLOAD_SIZE_KB',
  /**
   * The argument is comparable with the expected input in the context of the field
   * type.  The field value will be rejected if it is greater than the argument.
   */
  MaxValue = 'MAX_VALUE',
  /**
   * The argument is comparable with the expected date input in the context of the
   * field type.  The date field value will be rejected if it is newer than the
   * date calculated with the argument.
   */
  MinDaysOld = 'MIN_DAYS_OLD',
  /** The argument is an integer.  The field value should be rejected if its length is less than the argument. */
  MinLength = 'MIN_LENGTH',
  /**
   * The argument is an integer.  The field value should be rejected the number of lowercase letters is less than the
   * argument.
   */
  MinLowercase = 'MIN_LOWERCASE',
  /** The argument is an integer.  The field value should be rejected the number of digits is less than the argument. */
  MinNumbers = 'MIN_NUMBERS',
  /**
   * The argument is an integer.  The field values will be rejected if there are fewer options selected than are allowed here.
   * This validator is mainly for use with multiselect and likert scale fields
   */
  MinOptions = 'MIN_OPTIONS',
  /**
   * The argument is an integer.  The field value should be rejected the number of special characters is less than the
   * argument.
   */
  MinSpecialCharacters = 'MIN_SPECIAL_CHARACTERS',
  /**
   * The argument is an integer.  The field value should be rejected the number of uppercase letters is less than the
   * argument.
   */
  MinUppercase = 'MIN_UPPERCASE',
  /**
   * The argument is comparable with the expected input in the context of the field
   * type.  The field value will be rejected if it is less than the argument.
   */
  MinValue = 'MIN_VALUE',
  /**
   * For example, the email field might have the MUST_BE_UNIQUE validator, as the user must input an email that does not
   * match any existing customer's email address
   */
  MustBeUnique = 'MUST_BE_UNIQUE',
  /**
   * The argument is a comma-separated list of field names.  The field value should be rejected if it is equal to the
   * value of any of the fields specified.
   */
  NotEqualTo = 'NOT_EQUAL_TO',
  /** The field value should be rejected if it contains any digits (0-9) */
  NoDigits = 'NO_DIGITS',
  /** The field value should be rejected if it contains any of the following special characters: (?, \, ;, :. <, >, ", %, $) */
  NoSpecialCharacters = 'NO_SPECIAL_CHARACTERS',
  /** The argument is a string containing a Regex.  The field value should be rejected if it matches the given regex. */
  RegexDoesNotMatch = 'REGEX_DOES_NOT_MATCH',
  /** The argument is a string containing a Regex.  The field value should be rejected if it does not match the given regex. */
  RegexMatches = 'REGEX_MATCHES',
  /** Signals that this argument may be rejected by the backend for any reason. */
  ServerSideCheck = 'SERVER_SIDE_CHECK',
  /**
   * The argument is not required.  The field value should be rejected if the value is not one of the provided options.
   * This will almost always be set when there is an options list provided.
   */
  SpecifiedAnswersOnly = 'SPECIFIED_ANSWERS_ONLY',
  SupportedUploadExtensions = 'SUPPORTED_UPLOAD_EXTENSIONS',
}

/**
 * An axis on which a product can vary, such as colour or flavour.
 * A Product may have 0 or more options.
 */
export type VariantOption = {
  __typename?: 'VariantOption'
  choices: Array<OptionChoice>
  key: Scalars['String']
  localizedKey?: Maybe<Scalars['String']>
}

export type VariantOptions = {
  currency: Currency
  shippingDestination: Country
}

export type VerifyAndAddRewardCardResponse = {
  __typename?: 'VerifyAndAddRewardCardResponse'
  success: Scalars['Boolean']
  error?: Maybe<RewardCardError>
}

export type Video = {
  __typename?: 'Video'
  assets: Array<VideoAsset>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  status: StatusType
  thumbnailUrl?: Maybe<Scalars['URL']>
  title?: Maybe<Scalars['String']>
  transcript: Scalars['String']
  vttType: VttType
  vttUrl: Scalars['URL']
}

export type VideoAsset = {
  __typename?: 'VideoAsset'
  status: StatusType
  type: VideoAssetType
  url: Scalars['URL']
}

export enum VideoAssetType {
  Mp4 = 'MP4',
  Original = 'ORIGINAL',
  Webm = 'WEBM',
}

export type VideoEditorial = Widget & {
  __typename?: 'videoEditorial'
  alignment?: Maybe<Scalars['String']>
  ctaLink?: Maybe<Scalars['URL']>
  ctaText?: Maybe<Scalars['String']>
  desktopViewMode?: Maybe<Scalars['String']>
  id: Scalars['ID']
  query: Query
  subtitle?: Maybe<RichContent>
  thumbnail?: Maybe<Scalars['URL']>
  title?: Maybe<Scalars['String']>
  vpsVideoId?: Maybe<Video>
}

export type VoucherCode = {
  __typename?: 'VoucherCode'
  discountCode?: Maybe<Scalars['String']>
  expiresAt?: Maybe<Scalars['Timestamp']>
  link?: Maybe<Hyperlink>
  message: Scalars['String']
  title: Scalars['String']
}

export type VoucherCodes = {
  __typename?: 'VoucherCodes'
  hasMore: Scalars['Boolean']
  offers: Array<VoucherCode>
  total: Scalars['Int']
}

export type VoucherCodesWidget = Widget & {
  __typename?: 'VoucherCodesWidget'
  id: Scalars['ID']
  query: Query
}

export enum VttType {
  BackgroundMusic = 'BACKGROUND_MUSIC',
  Custom = 'CUSTOM',
  NoAudio = 'NO_AUDIO',
}

export enum WaitlistType {
  Email = 'EMAIL',
  PushNotification = 'PUSH_NOTIFICATION',
}

export type Widget = {
  id: Scalars['ID']
  query: Query
}

export type WidgetProductItem = {
  __typename?: 'WidgetProductItem'
  product?: Maybe<Product>
  productVariant?: Maybe<ProductVariant>
}

/**
 * There may be multiple wishlist items for the same product,
 * but none with the same product and selected variant
 */
export type WishlistItem = {
  __typename?: 'WishlistItem'
  product: Product
  selectedVariant?: Maybe<ProductVariant>
}

export type WishlistItems = {
  __typename?: 'WishlistItems'
  hasMore: Scalars['Boolean']
  items: Array<WishlistItem>
  total: Scalars['Int']
}

export enum WishlistSort {
  Availability = 'AVAILABILITY',
  Default = 'DEFAULT',
  DiscountHighestToLowest = 'DISCOUNT_HIGHEST_TO_LOWEST',
  PriceLowestToHighest = 'PRICE_LOWEST_TO_HIGHEST',
  Title = 'TITLE',
}

export type YotiAgeVerification = Widget & {
  __typename?: 'yotiAgeVerification'
  id: Scalars['ID']
  query: Query
  yotiDisplayText?: Maybe<Scalars['String']>
}

export type YotiCancelFrame = Widget & {
  __typename?: 'yotiCancelFrame'
  id: Scalars['ID']
  query: Query
}

export type AllFormFieldsFragment = { __typename?: 'Form' } & {
  fields: Array<
    { __typename?: 'FormField' } & Pick<
      FormField,
      'name' | 'type' | 'required' | 'confirmable' | 'options' | 'defaultValue'
    > & {
        validators: Array<
          { __typename?: 'Validator' } & Pick<Validator, 'name' | 'argument'>
        >
      }
  >
}

export type FieldFragment = { __typename?: 'FormField' } & Pick<
  FormField,
  'name' | 'type' | 'required' | 'confirmable' | 'defaultValue'
> & {
    validators: Array<
      { __typename?: 'Validator' } & Pick<Validator, 'name' | 'argument'>
    >
    answerOptions?: Maybe<
      Array<
        Maybe<
          { __typename?: 'AnswerOption' } & Pick<
            AnswerOption,
            'optionKey' | 'translation'
          >
        >
      >
    >
  }

export type AppliedOffersFragment = { __typename?: 'Basket' } & {
  appliedOffers: Array<
    { __typename?: 'AppliedOffer' } & Pick<
      AppliedOffer,
      'message' | 'removeable'
    > & {
        totalBasketDiscount?: Maybe<
          { __typename?: 'MoneyValue' } & Pick<
            MoneyValue,
            'displayValue' | 'amount'
          >
        >
      }
  >
}

export type AvailablePaymentOptionsFragment = { __typename?: 'Basket' } & {
  availablePaymentOptions: Array<
    { __typename?: 'AvailablePaymentOption' } & Pick<
      AvailablePaymentOption,
      'option' | 'subOption'
    >
  >
}

export type ChargePriceFragment = { __typename?: 'Basket' } & {
  chargePrice: { __typename?: 'MoneyValue' } & Pick<
    MoneyValue,
    'amount' | 'displayValue' | 'currency'
  >
}

export type DiscountFragment = { __typename?: 'Basket' } & {
  discount: { __typename?: 'MoneyValue' } & Pick<
    MoneyValue,
    'displayValue' | 'amount'
  >
}

export type BasketItemsFragment = { __typename?: 'Basket' } & {
  items: Array<
    { __typename?: 'BasketItem' } & MakeOptional<
      Pick<
        BasketItem,
        | 'id'
        | 'fulfilmentMethod'
        | 'quantity'
        | 'freeGift'
        | 'store'
        | 'storeStock'
        | 'subscriptionContract'
      >,
      'fulfilmentMethod' | 'storeStock'
    > & {
        store?: Maybe<
          { __typename: 'Store' } & Pick<Store, 'displayName' | 'id'>
        >
        product: { __typename?: 'ProductVariant' } & MakeOptional<
          Pick<
            ProductVariant,
            | 'sku'
            | 'inStock'
            | 'title'
            | 'maxPerOrder'
            | 'inWishlist'
            | 'leadTime'
            | 'weightGroups'
            | 'isBookable'
            | 'isCheckStock'
            | 'isOrderInStore'
            | 'inStockLocations'
            | 'eligibleForFulfilmentMethods'
            | 'isSubscription'
            | 'isAutoRenewSubscription'
            | 'subscriptionFrequency'
            | 'subscriptionTerm'
            | 'subscriptionPaymentType'
            | 'inWishlist'
            | 'externalIdentifier'
            | 'price'
          >,
          | 'eligibleForFulfilmentMethods'
          | 'leadTime'
          | 'isSubscription'
          | 'isAutoRenewSubscription'
          | 'subscriptionFrequency'
          | 'subscriptionTerm'
          | 'subscriptionPaymentType'
          | 'inWishlist'
        > & {
            product?: Maybe<
              { __typename?: 'Product' } & Pick<
                Product,
                'url' | 'sku' | 'externalIdentifier' | 'breadcrumbs'
              > & {
                  images: Array<
                    { __typename?: 'ProductImage' } & Pick<
                      ProductImage,
                      'largeProduct'
                    >
                  >
                  variants: Array<
                    { __typename?: 'ProductVariant' } & Pick<
                      ProductVariant,
                      'sku'
                    > & {
                        supersize?: Maybe<
                          { __typename?: 'Supersize' } & {
                            variant: { __typename?: 'ProductVariant' } & Pick<
                              ProductVariant,
                              'sku' | 'title'
                            >
                            saving: { __typename?: 'SupersizeSaving' } & {
                              nextSupersizeOption: {
                                __typename?: 'ProductUnit'
                              } & Pick<ProductUnit, 'value' | 'unit'>
                              savingUnit: { __typename?: 'ProductUnit' } & Pick<
                                ProductUnit,
                                'value' | 'unit'
                              >
                              savingPerUnit: {
                                __typename?: 'MoneyValue'
                              } & Pick<
                                MoneyValue,
                                | 'currency'
                                | 'amount'
                                | 'displayValue'
                                | 'scalarValue'
                              >
                            }
                          }
                        >
                      }
                  >
                  brand?: Maybe<
                    { __typename?: 'Brand' } & Pick<Brand, 'name' | 'imageUrl'>
                  >
                }
            >
            subscriptionContracts?: Maybe<
              Array<
                { __typename?: 'SubscriptionContract' } & Pick<
                  SubscriptionContract,
                  | 'id'
                  | 'upsellMessage'
                  | 'recommended'
                  | 'initialDiscountPercentage'
                > & {
                    frequencyDuration: {
                      __typename?: 'SubscriptionFrequencyDuration'
                    } & Pick<SubscriptionFrequencyDuration, 'unit' | 'duration'>
                    initialPrice?: Maybe<
                      { __typename?: 'ProductPrice' } & {
                        price: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'currency' | 'amount' | 'displayValue' | 'scalarValue'
                        >
                        rrp: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'currency' | 'amount' | 'displayValue' | 'scalarValue'
                        >
                      }
                    >
                    recurringPrice?: Maybe<
                      { __typename?: 'ProductPrice' } & {
                        price: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'currency' | 'amount' | 'displayValue' | 'scalarValue'
                        >
                        rrp: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'currency' | 'amount' | 'displayValue' | 'scalarValue'
                        >
                      }
                    >
                  }
              >
            >
            supersize?: Maybe<
              { __typename?: 'Supersize' } & {
                variant: { __typename?: 'ProductVariant' } & Pick<
                  ProductVariant,
                  'sku' | 'title'
                >
                saving: { __typename?: 'SupersizeSaving' } & {
                  nextSupersizeOption: { __typename?: 'ProductUnit' } & Pick<
                    ProductUnit,
                    'value' | 'unit'
                  >
                  savingPerUnit: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'amount' | 'displayValue' | 'currency' | 'scalarValue'
                  >
                  savingUnit: { __typename?: 'ProductUnit' } & Pick<
                    ProductUnit,
                    'value' | 'unit'
                  >
                }
              }
            >
            content: Array<
              { __typename?: 'ProductContentItem' } & Pick<
                ProductContentItem,
                'key'
              > & {
                  value:
                    | { __typename?: 'ProductContentAttachmentValue' }
                    | { __typename?: 'ProductContentDateValue' }
                    | { __typename?: 'ProductContentIntListValue' }
                    | { __typename?: 'ProductContentIntValue' }
                    | { __typename?: 'ProductContentRichContentListValue' }
                    | { __typename?: 'ProductContentRichContentValue' }
                    | ({ __typename: 'ProductContentStringListValue' } & {
                        stringListValue: ProductContentStringListValue['value']
                      })
                    | ({ __typename: 'ProductContentStringValue' } & {
                        stringValue: ProductContentStringValue['value']
                      })
                }
            >
          }
        chargePricePerUnit: { __typename?: 'MoneyValue' } & Pick<
          MoneyValue,
          'displayValue' | 'amount'
        >
        standardPricePerUnit: { __typename?: 'MoneyValue' } & Pick<
          MoneyValue,
          'displayValue' | 'amount' | 'currency'
        >
        totalChargePrice: { __typename?: 'MoneyValue' } & Pick<
          MoneyValue,
          'displayValue' | 'amount' | 'currency'
        >
        totalStandardPrice: { __typename?: 'MoneyValue' } & Pick<
          MoneyValue,
          'displayValue' | 'amount'
        >
        totalDiscount: { __typename?: 'MoneyValue' } & Pick<
          MoneyValue,
          'displayValue'
        >
        appliedOffers: Array<
          { __typename?: 'AppliedOffer' } & Pick<
            AppliedOffer,
            'message' | 'info'
          > & {
              totalBasketDiscount?: Maybe<
                { __typename?: 'MoneyValue' } & Pick<MoneyValue, 'displayValue'>
              >
            }
        >
        messages?: Maybe<
          Array<
            { __typename?: 'BasketItemMessage' } & Pick<
              BasketItemMessage,
              'type'
            >
          >
        >
        subscriptionContract?: SubscriptionContract
      }
  >
}

export type MessagesFragment = { __typename?: 'Basket' } & {
  messages: Array<
    { __typename?: 'BasketMessage' } & Pick<BasketMessage, 'type' | 'message'>
  >
}

export type RecommendationsFragment = { __typename?: 'Basket' } & {
  recommendations?: Maybe<
    Array<
      { __typename?: 'Product' } & Pick<Product, 'sku' | 'title' | 'url'> & {
          images: Array<
            { __typename?: 'ProductImage' } & Pick<ProductImage, 'largeProduct'>
          >
          reviews?: Maybe<
            { __typename?: 'ProductReviews' } & Pick<
              ProductReviews,
              'total' | 'averageScore'
            >
          >
          defaultVariant?: Maybe<
            { __typename?: 'ProductVariant' } & {
              price?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'amount' | 'displayValue'
                  >
                  rrp: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'amount' | 'displayValue'
                  >
                }
              >
            }
          >
          cheapestVariant?: Maybe<
            { __typename?: 'ProductVariant' } & {
              price?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'amount' | 'displayValue'
                  >
                  rrp: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'amount' | 'displayValue'
                  >
                }
              >
            }
          >
          mostExpensiveVariant?: Maybe<
            { __typename?: 'ProductVariant' } & {
              price?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'amount' | 'displayValue'
                  >
                  rrp: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'amount' | 'displayValue'
                  >
                }
              >
            }
          >
          variants: Array<
            { __typename?: 'ProductVariant' } & Pick<ProductVariant, 'inStock'>
          >
        }
    >
  >
}

export type SelectYourSampleFragment = { __typename?: 'Basket' } & {
  selectYourSample: Array<
    { __typename?: 'SelectYourSample' } & Pick<
      SelectYourSample,
      'id' | 'title' | 'message'
    > & {
        currentAmountSpent: { __typename?: 'MoneyValue' } & Pick<
          MoneyValue,
          'displayValue' | 'amount'
        >
        tiers: Array<
          { __typename?: 'SelectYourSampleTier' } & Pick<
            SelectYourSampleTier,
            'maxSelectedProducts'
          > & {
              additionalSpendToReach?: Maybe<
                { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'displayValue' | 'amount'
                >
              >
              thresholdAmountSpent: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue'
              >
              products: Array<
                { __typename?: 'ProductVariant' } & Pick<
                  ProductVariant,
                  'sku' | 'inStock' | 'title'
                > & {
                    images: Array<
                      { __typename?: 'ProductImage' } & Pick<
                        ProductImage,
                        'thumbnail' | 'largeProduct' | 'zoom'
                      >
                    >
                    content: Array<
                      { __typename?: 'ProductContentItem' } & Pick<
                        ProductContentItem,
                        'key'
                      > & {
                          value:
                            | { __typename?: 'ProductContentAttachmentValue' }
                            | { __typename?: 'ProductContentDateValue' }
                            | { __typename?: 'ProductContentIntListValue' }
                            | { __typename?: 'ProductContentIntValue' }
                            | ({
                                __typename: 'ProductContentRichContentListValue'
                              } & {
                                richContentListValue: Array<
                                  { __typename: 'RichContent' } & {
                                    content: Array<
                                      { __typename?: 'RichContentItem' } & Pick<
                                        RichContentItem,
                                        'content'
                                      >
                                    >
                                  }
                                >
                              })
                            | ({
                                __typename: 'ProductContentRichContentValue'
                              } & {
                                richContentValue: {
                                  __typename: 'RichContent'
                                } & {
                                  content: Array<
                                    { __typename?: 'RichContentItem' } & Pick<
                                      RichContentItem,
                                      'content'
                                    >
                                  >
                                }
                              })
                            | ({
                                __typename: 'ProductContentStringListValue'
                              } & {
                                stringListValue: ProductContentStringListValue['value']
                              })
                            | ({ __typename: 'ProductContentStringValue' } & {
                                stringValue: ProductContentStringValue['value']
                              })
                        }
                    >
                  }
              >
              selectedProducts: Array<
                { __typename?: 'ProductVariant' } & Pick<ProductVariant, 'sku'>
              >
            }
        >
      }
  >
}

export type SharedBasketFragment = { __typename?: 'Basket' } & MakeOptional<
  Pick<Basket, 'id' | 'totalQuantity' | 'earnableLoyaltyPoints'>,
  'earnableLoyaltyPoints'
> &
  BasketItemsFragment &
  AppliedOffersFragment &
  ChargePriceFragment &
  StandardPriceFragment &
  DiscountFragment &
  MessagesFragment &
  RecommendationsFragment &
  SelectYourSampleFragment &
  AvailablePaymentOptionsFragment

export type StandardPriceFragment = { __typename?: 'Basket' } & {
  standardPrice: { __typename?: 'MoneyValue' } & Pick<
    MoneyValue,
    'amount' | 'displayValue'
  >
}

export type ProductBlockFragmentFragment = { __typename?: 'Product' } & Pick<
  Product,
  'sku' | 'url' | 'title'
> & {
    brand?: Maybe<{ __typename?: 'Brand' } & Pick<Brand, 'name' | 'imageUrl'>>
    reviews?: Maybe<
      { __typename?: 'ProductReviews' } & Pick<
        ProductReviews,
        'total' | 'averageScore'
      >
    >
    images: Array<
      { __typename?: 'ProductImage' } & Pick<ProductImage, 'largeProduct'>
    >
    defaultVariant?: Maybe<
      { __typename?: 'ProductVariant' } & Pick<
        ProductVariant,
        | 'sku'
        | 'title'
        | 'eligibleForFulfilmentMethods'
        | 'inStock'
        | 'isCheckStock'
        | 'isOrderInStore'
        | 'leadTime'
        | 'weightGroups'
        | 'isBookable'
        | 'inStockLocations'
      > & {
          images: Array<
            { __typename?: 'ProductImage' } & Pick<ProductImage, 'largeProduct'>
          >
          marketedSpecialOffer?: Maybe<
            { __typename?: 'ProductMarketedSpecialOffer' } & {
              title?: Maybe<
                { __typename?: 'RichContent' } & {
                  content: Array<
                    { __typename?: 'RichContentItem' } & Pick<
                      RichContentItem,
                      'content'
                    >
                  >
                }
              >
              description?: Maybe<
                { __typename?: 'RichContent' } & {
                  content: Array<
                    { __typename?: 'RichContentItem' } & Pick<
                      RichContentItem,
                      'content'
                    >
                  >
                }
              >
              landingPageLink?: Maybe<
                { __typename?: 'Hyperlink' } & Pick<
                  Hyperlink,
                  'text' | 'url' | 'openExternally' | 'noFollow'
                >
              >
              freeGiftProduct?: Maybe<
                { __typename?: 'ProductVariant' } & Pick<
                  ProductVariant,
                  'sku' | 'title'
                > & {
                    images: Array<
                      { __typename?: 'ProductImage' } & Pick<
                        ProductImage,
                        'largeProduct'
                      >
                    >
                    price?: Maybe<
                      { __typename?: 'ProductPrice' } & {
                        rrp: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'displayValue'
                        >
                      }
                    >
                  }
              >
            }
          >
          price?: Maybe<
            { __typename?: 'ProductPrice' } & {
              price: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue' | 'amount'
              >
              rrp: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue' | 'amount'
              >
            }
          >
        }
    >
    variants: Array<
      { __typename?: 'ProductVariant' } & Pick<
        ProductVariant,
        'sku' | 'title' | 'inStock' | 'inStockLocations'
      > & {
          product?: Maybe<
            { __typename?: 'Product' } & Pick<Product, 'sku' | 'url'>
          >
          choices: Array<
            { __typename: 'OptionChoice' } & Pick<
              OptionChoice,
              'optionKey' | 'key'
            >
          >
          images: Array<
            { __typename?: 'ProductImage' } & Pick<ProductImage, 'largeProduct'>
          >
          marketedSpecialOffer?: Maybe<
            { __typename?: 'ProductMarketedSpecialOffer' } & {
              title?: Maybe<
                { __typename?: 'RichContent' } & {
                  content: Array<
                    { __typename?: 'RichContentItem' } & Pick<
                      RichContentItem,
                      'content'
                    >
                  >
                }
              >
              description?: Maybe<
                { __typename?: 'RichContent' } & {
                  content: Array<
                    { __typename?: 'RichContentItem' } & Pick<
                      RichContentItem,
                      'content'
                    >
                  >
                }
              >
              landingPageLink?: Maybe<
                { __typename?: 'Hyperlink' } & Pick<
                  Hyperlink,
                  'text' | 'url' | 'openExternally' | 'noFollow'
                >
              >
              freeGiftProduct?: Maybe<
                { __typename?: 'ProductVariant' } & Pick<
                  ProductVariant,
                  'sku' | 'title'
                > & {
                    images: Array<
                      { __typename?: 'ProductImage' } & Pick<
                        ProductImage,
                        'largeProduct'
                      >
                    >
                    price?: Maybe<
                      { __typename?: 'ProductPrice' } & {
                        rrp: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'displayValue'
                        >
                      }
                    >
                  }
              >
            }
          >
          content: Array<
            { __typename?: 'ProductContentItem' } & Pick<
              ProductContentItem,
              'key'
            > & {
                value: { __typename?: 'ProductContentStringValue' } & {
                  stringValue: ProductContentStringValue['value']
                }
              }
          >
        }
    >
    cheapestVariant?: Maybe<
      { __typename?: 'ProductVariant' } & {
        price?: Maybe<
          { __typename?: 'ProductPrice' } & {
            price: { __typename?: 'MoneyValue' } & Pick<
              MoneyValue,
              'displayValue' | 'amount'
            >
            rrp: { __typename?: 'MoneyValue' } & Pick<
              MoneyValue,
              'displayValue' | 'amount'
            >
          }
        >
      }
    >
    mostExpensiveVariant?: Maybe<
      { __typename?: 'ProductVariant' } & {
        price?: Maybe<
          { __typename?: 'ProductPrice' } & {
            price: { __typename?: 'MoneyValue' } & Pick<
              MoneyValue,
              'displayValue' | 'amount'
            >
            rrp: { __typename?: 'MoneyValue' } & Pick<
              MoneyValue,
              'displayValue' | 'amount'
            >
          }
        >
      }
    >
    options: Array<
      { __typename: 'VariantOption' } & Pick<VariantOption, 'key'> & {
          choices: Array<
            { __typename: 'OptionChoice' } & Pick<
              OptionChoice,
              'optionKey' | 'key' | 'colour' | 'title'
            >
          >
        }
    >
  }

type FacetFields_RangedFacet_Fragment = { __typename: 'RangedFacet' } & Pick<
  RangedFacet,
  'facetName' | 'facetHeader'
> & {
    options: Array<
      { __typename: 'RangedFacetOption' } & Pick<
        RangedFacetOption,
        'displayName' | 'from' | 'to' | 'matchedProductCount'
      >
    >
  }

type FacetFields_SimpleFacet_Fragment = { __typename: 'SimpleFacet' } & Pick<
  SimpleFacet,
  'facetName' | 'facetHeader'
> & {
    options: Array<
      { __typename: 'SimpleFacetOption' } & Pick<
        SimpleFacetOption,
        'optionName' | 'displayName' | 'matchedProductCount'
      >
    >
  }

type FacetFields_SliderFacet_Fragment = { __typename: 'SliderFacet' } & Pick<
  SliderFacet,
  'facetName' | 'facetHeader' | 'minValue' | 'maxValue'
>

export type FacetFieldsFragment =
  | FacetFields_RangedFacet_Fragment
  | FacetFields_SimpleFacet_Fragment
  | FacetFields_SliderFacet_Fragment

export type ProductListFieldsFragment = {
  __typename: 'Product'
} & MakeOptional<
  Pick<Product, 'sku' | 'url' | 'title' | 'externalIdentifier' | 'inWishlist'>,
  'inWishlist'
> & {
    brand?: Maybe<{ __typename?: 'Brand' } & Pick<Brand, 'name' | 'imageUrl'>>
    reviews?: Maybe<
      { __typename: 'ProductReviews' } & Pick<
        ProductReviews,
        'total' | 'averageScore'
      >
    >
    images: Array<
      { __typename: 'ProductImage' } & Pick<
        ProductImage,
        'thumbnail' | 'largeProduct' | 'zoom'
      >
    >
    options: Array<
      { __typename: 'VariantOption' } & Pick<VariantOption, 'key'> & {
          choices: Array<
            { __typename: 'OptionChoice' } & Pick<
              OptionChoice,
              'optionKey' | 'key' | 'colour' | 'title'
            >
          >
        }
    >
    variants: Array<
      { __typename: 'ProductVariant' } & Pick<ProductVariant, 'inStock'> & {
          product?: Maybe<
            { __typename: 'Product' } & Pick<Product, 'sku' | 'url'>
          >
          price?: Maybe<
            { __typename?: 'ProductPrice' } & {
              price: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue' | 'amount'
              >
              rrp: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue' | 'amount'
              >
            }
          >
          choices: Array<
            { __typename: 'OptionChoice' } & Pick<
              OptionChoice,
              'optionKey' | 'key'
            >
          >
        }
    >
    defaultVariant?: Maybe<
      { __typename: 'ProductVariant' } & MakeOptional<
        Pick<ProductVariant, 'inStock' | 'eligibleForFulfilmentMethods'>,
        'eligibleForFulfilmentMethods'
      > & {
          price?: Maybe<
            { __typename?: 'ProductPrice' } & {
              price: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue' | 'amount'
              >
              rrp: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue' | 'amount'
              >
            }
          >
        }
    >
    cheapestVariantPrice?: Maybe<
      { __typename?: 'ProductPrice' } & {
        price: { __typename?: 'MoneyValue' } & Pick<
          MoneyValue,
          'displayValue' | 'amount'
        >
        rrp: { __typename?: 'MoneyValue' } & Pick<
          MoneyValue,
          'displayValue' | 'amount'
        >
      }
    >
    cheapestVariant?: Maybe<
      { __typename?: 'ProductVariant' } & {
        price?: Maybe<
          { __typename?: 'ProductPrice' } & {
            price: { __typename?: 'MoneyValue' } & Pick<
              MoneyValue,
              'displayValue' | 'amount'
            >
            rrp: { __typename?: 'MoneyValue' } & Pick<
              MoneyValue,
              'displayValue' | 'amount'
            >
          }
        >
      }
    >
    mostExpensiveVariant?: Maybe<
      { __typename?: 'ProductVariant' } & {
        price?: Maybe<
          { __typename?: 'ProductPrice' } & {
            price: { __typename?: 'MoneyValue' } & Pick<
              MoneyValue,
              'displayValue' | 'amount'
            >
            rrp: { __typename?: 'MoneyValue' } & Pick<
              MoneyValue,
              'displayValue' | 'amount'
            >
          }
        >
      }
    >
    marketedSpecialOffer?: Maybe<
      { __typename: 'ProductMarketedSpecialOffer' } & {
        title?: Maybe<
          { __typename: 'RichContent' } & {
            content: Array<
              { __typename: 'RichContentItem' } & Pick<
                RichContentItem,
                'content'
              >
            >
          }
        >
        description?: Maybe<
          { __typename: 'RichContent' } & {
            content: Array<
              { __typename: 'RichContentItem' } & Pick<
                RichContentItem,
                'content'
              >
            >
          }
        >
        landingPageLink?: Maybe<
          { __typename: 'Hyperlink' } & Pick<
            Hyperlink,
            'text' | 'url' | 'openExternally' | 'noFollow'
          >
        >
        freeGiftProduct?: Maybe<
          { __typename: 'ProductVariant' } & Pick<
            ProductVariant,
            'sku' | 'title'
          > & {
              images: Array<
                { __typename: 'ProductImage' } & Pick<
                  ProductImage,
                  'largeProduct'
                >
              >
              price?: Maybe<
                { __typename: 'ProductPrice' } & {
                  rrp: { __typename: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue'
                  >
                }
              >
            }
        >
      }
    >
  }

export type GravityPictureFragmentFragment = {
  __typename?: 'GravityPicture'
} & Pick<GravityPicture, 'default' | 'alt'> & {
    sources: Array<
      { __typename?: 'GravityPictureSource' } & Pick<
        GravityPictureSource,
        'sourceSet' | 'media' | 'type'
      >
    >
  }

export type ProductVariantFragmentFragment = {
  __typename?: 'ProductVariantCustom'
} & MakeOptional<
  Pick<
    ProductVariantCustom,
    | 'url'
    | 'sku'
    | 'eligibleForFulfilmentMethods'
    | 'externalIdentifier'
    | 'inStock'
    | 'isCheckStock'
    | 'isOrderInStore'
  >,
  'eligibleForFulfilmentMethods' | 'isCheckStock' | 'isOrderInStore'
> & {
    title?: Maybe<
      { __typename: 'ProductVariantTitle' } & Pick<ProductVariantTitle, 'value'>
    >
    image?: Maybe<
      { __typename?: 'ProductVariantImage' } & Pick<
        ProductVariantImage,
        'altText' | 'isAmp'
      > & {
          urls?: Maybe<
            { __typename: 'ProductVariantImageUrls' } & Pick<
              ProductVariantImageUrls,
              'largeProduct'
            >
          >
        }
    >
    review?: Maybe<
      { __typename: 'ProductVariantReview' } & Pick<
        ProductVariantReview,
        'starRating' | 'numberOfReviews'
      >
    >
    price?: Maybe<
      { __typename?: 'PriceType' } & Pick<PriceType, 'price' | 'rrp'>
    >
    marketedSpecialOffer?: Maybe<
      { __typename?: 'MarketedSpecialOfferType' } & Pick<
        MarketedSpecialOfferType,
        | 'onlyDisplayOfferBadge'
        | 'shouldRemoveScrollLock'
        | 'title'
        | 'description'
      > & {
          landingPageLink?: Maybe<
            { __typename?: 'LandingPageLinkType' } & Pick<
              LandingPageLinkType,
              'text' | 'url' | 'openExternally' | 'noFollow'
            >
          >
        }
    >
    icons?: Maybe<
      { __typename?: 'IconsType' } & Pick<
        IconsType,
        'homeDelivery' | 'storeAvailable' | 'storeDelivery'
      >
    >
  }

export type ResponsiveProductBlockCarouselProductFieldsFragment = {
  __typename: 'Product'
} & Pick<Product, 'sku' | 'title' | 'url'> & {
    brand?: Maybe<{ __typename?: 'Brand' } & Pick<Brand, 'name' | 'imageUrl'>>
    options: Array<
      { __typename: 'VariantOption' } & Pick<VariantOption, 'key'> & {
          choices: Array<
            { __typename: 'OptionChoice' } & Pick<
              OptionChoice,
              'key' | 'colour' | 'title'
            >
          >
        }
    >
    marketedSpecialOffer?: Maybe<
      { __typename?: 'ProductMarketedSpecialOffer' } & {
        title?: Maybe<
          { __typename?: 'RichContent' } & {
            content: Array<
              { __typename?: 'RichContentItem' } & Pick<
                RichContentItem,
                'type' | 'content'
              >
            >
          }
        >
        description?: Maybe<
          { __typename?: 'RichContent' } & {
            content: Array<
              { __typename?: 'RichContentItem' } & Pick<
                RichContentItem,
                'type' | 'content'
              >
            >
          }
        >
        landingPageLink?: Maybe<
          { __typename?: 'Hyperlink' } & Pick<
            Hyperlink,
            'text' | 'url' | 'openExternally' | 'noFollow'
          >
        >
      }
    >
    images: Array<
      { __typename: 'ProductImage' } & Pick<
        ProductImage,
        'thumbnail' | 'largeProduct' | 'zoom'
      >
    >
    reviews?: Maybe<
      { __typename: 'ProductReviews' } & Pick<
        ProductReviews,
        'averageScore' | 'total'
      >
    >
    variants: Array<
      { __typename?: 'ProductVariant' } & Pick<
        ProductVariant,
        'sku' | 'title'
      > & {
          product?: Maybe<{ __typename?: 'Product' } & Pick<Product, 'sku'>>
          price?: Maybe<
            { __typename?: 'ProductPrice' } & {
              price: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue' | 'amount'
              >
              rrp: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue' | 'amount'
              >
            }
          >
          choices: Array<
            { __typename: 'OptionChoice' } & Pick<
              OptionChoice,
              'optionKey' | 'key' | 'colour'
            >
          >
        }
    >
    defaultVariant?: Maybe<
      { __typename?: 'ProductVariant' } & Pick<
        ProductVariant,
        | 'inStock'
        | 'eligibleForFulfilmentMethods'
        | 'isCheckStock'
        | 'isOrderInStore'
      > & {
          product?: Maybe<{ __typename?: 'Product' } & Pick<Product, 'sku'>>
          price?: Maybe<
            { __typename?: 'ProductPrice' } & {
              price: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue' | 'amount'
              >
              rrp: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue' | 'amount'
              >
            }
          >
        }
    >
  }

export type RichContentHtmlFragment = { __typename?: 'RichContent' } & {
  content: Array<
    { __typename?: 'RichContentItem' } & Pick<RichContentItem, 'content'>
  >
}

export type ProductVariantFieldsFragment = {
  __typename: 'ProductVariant'
} & MakeOptional<
  Pick<
    ProductVariant,
    | 'sku'
    | 'externalIdentifier'
    | 'eligibleForFulfilmentMethods'
    | 'isCheckStock'
    | 'isOrderInStore'
    | 'inStock'
    | 'title'
  >,
  'eligibleForFulfilmentMethods' | 'isCheckStock' | 'isOrderInStore'
> & {
    images: Array<
      { __typename: 'ProductImage' } & Pick<
        ProductImage,
        'thumbnail' | 'largeProduct' | 'zoom'
      >
    >
    price?: Maybe<
      { __typename?: 'ProductPrice' } & {
        price: { __typename?: 'MoneyValue' } & Pick<
          MoneyValue,
          'amount' | 'displayValue'
        >
        rrp: { __typename?: 'MoneyValue' } & Pick<
          MoneyValue,
          'amount' | 'displayValue'
        >
      }
    >
    product?: Maybe<
      { __typename: 'Product' } & Pick<Product, 'url'> & {
          cheapestVariant?: Maybe<
            { __typename?: 'ProductVariant' } & {
              price?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'amount' | 'displayValue'
                  >
                  rrp: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'amount' | 'displayValue'
                  >
                }
              >
            }
          >
          variants: Array<
            { __typename?: 'ProductVariant' } & Pick<
              ProductVariant,
              'inStock'
            > & {
                product?: Maybe<
                  { __typename?: 'Product' } & Pick<Product, 'url'>
                >
                choices: Array<
                  { __typename: 'OptionChoice' } & Pick<
                    OptionChoice,
                    'optionKey' | 'key' | 'colour' | 'title'
                  >
                >
              }
          >
          options: Array<
            { __typename: 'VariantOption' } & Pick<VariantOption, 'key'> & {
                choices: Array<
                  { __typename: 'OptionChoice' } & Pick<
                    OptionChoice,
                    'optionKey' | 'key' | 'colour' | 'title'
                  >
                >
              }
          >
          images: Array<
            { __typename: 'ProductImage' } & Pick<
              ProductImage,
              'thumbnail' | 'largeProduct' | 'zoom'
            >
          >
          reviews?: Maybe<
            { __typename: 'ProductReviews' } & Pick<
              ProductReviews,
              'averageScore' | 'total'
            >
          >
        }
    >
    marketedSpecialOffer?: Maybe<
      { __typename: 'ProductMarketedSpecialOffer' } & {
        title?: Maybe<
          { __typename: 'RichContent' } & {
            content: Array<
              { __typename: 'RichContentItem' } & Pick<
                RichContentItem,
                'content'
              >
            >
          }
        >
        description?: Maybe<
          { __typename: 'RichContent' } & {
            content: Array<
              { __typename: 'RichContentItem' } & Pick<
                RichContentItem,
                'content'
              >
            >
          }
        >
        landingPageLink?: Maybe<
          { __typename: 'Hyperlink' } & Pick<
            Hyperlink,
            'text' | 'url' | 'openExternally' | 'noFollow'
          >
        >
      }
    >
  }

type AllWidgets_AccordionWidget_Fragment = {
  __typename: 'AccordionWidget'
} & Pick<AccordionWidget, 'title' | 'id'> & {
    content?: Maybe<{ __typename: 'RichContent' } & RichContentHtmlFragment>
  }

type AllWidgets_AccordionWidgetContainer_Fragment = {
  __typename: 'AccordionWidgetContainer'
} & Pick<
  AccordionWidgetContainer,
  'titlePosition' | 'heading' | 'title' | 'id'
> & {
    banners?: Maybe<
      Array<
        Maybe<
          | ({ __typename: 'AccordionWidget' } & Pick<
              AccordionWidget,
              'title'
            > & {
                content?: Maybe<
                  { __typename: 'RichContent' } & RichContentHtmlFragment
                >
              })
          | { __typename?: 'AccordionWidgetContainer' }
          | { __typename?: 'BMICalculator' }
          | { __typename?: 'BMICalculatorV2' }
          | { __typename?: 'BrandsPageWidget' }
          | { __typename?: 'BuildYourOwnBundleProductList' }
          | { __typename?: 'CriteoSponsoredBannerAds' }
          | { __typename?: 'CriteoSponsoredBannerAdsFlagship' }
          | { __typename?: 'DeliveryInfoWidget' }
          | { __typename?: 'DeliveryInformationWidget' }
          | { __typename?: 'DynamicReferralWidget' }
          | { __typename?: 'Easiware' }
          | { __typename?: 'EditorialWidget' }
          | { __typename?: 'EmailReEngagementModal' }
          | { __typename?: 'FastTrackBanner' }
          | { __typename?: 'FoundationFinderLandingPageWidget' }
          | { __typename?: 'GlobalAboutUsVideo' }
          | { __typename?: 'GlobalAccreditationIcon' }
          | { __typename?: 'GlobalAccreditationIconCollection' }
          | { __typename?: 'GlobalBrandLogos' }
          | { __typename?: 'GlobalBuyingRightNow' }
          | { __typename?: 'GlobalBuyingRightNowManualProductURL' }
          | { __typename?: 'GlobalCardScrollerCard' }
          | { __typename?: 'GlobalCardScrollerSet' }
          | { __typename?: 'GlobalDispatchAndDateCountdownWidget' }
          | { __typename?: 'GlobalEditorialWithFeature' }
          | { __typename?: 'GlobalFooterAccreditationIcons' }
          | { __typename?: 'GlobalFooterContactUs' }
          | { __typename?: 'GlobalFourBestSellers' }
          | { __typename?: 'GlobalFourButtonLink' }
          | { __typename?: 'GlobalFourItemEditorial' }
          | { __typename?: 'GlobalGeneralImageBanner' }
          | { __typename?: 'GlobalHelpCentreAccordion' }
          | { __typename?: 'GlobalHelpCentreAccordion2' }
          | { __typename?: 'GlobalHelpCentreAccordionCollection' }
          | { __typename?: 'GlobalHelpCentreCollection' }
          | { __typename?: 'GlobalHeroCTABanner' }
          | { __typename?: 'GlobalImageCard' }
          | { __typename?: 'GlobalImageCardSet' }
          | { __typename?: 'GlobalMultiButton' }
          | { __typename?: 'GlobalPrimaryBanner' }
          | { __typename?: 'GlobalPrimaryBannerWithList' }
          | { __typename?: 'GlobalPrimaryBannerWithTextOverlay' }
          | { __typename?: 'GlobalProductCardScroller' }
          | { __typename?: 'GlobalReferral3StepGuide' }
          | { __typename?: 'GlobalScalableLogos' }
          | { __typename?: 'GlobalSectionPeek' }
          | { __typename?: 'GlobalSetAndromeda' }
          | { __typename?: 'GlobalSimpleTextCTAWidget' }
          | { __typename?: 'GlobalSixItemCategories' }
          | { __typename?: 'GlobalSocialIcon' }
          | { __typename?: 'GlobalSocialIconCollection' }
          | { __typename?: 'GlobalSocialIconCollectionv2' }
          | { __typename?: 'GlobalSocialIconv2' }
          | { __typename?: 'GlobalStripBanner' }
          | { __typename?: 'GlobalSubscriptionOptions' }
          | { __typename?: 'GlobalTabbedWidgetSet' }
          | { __typename?: 'GlobalThreeItemEditorial' }
          | { __typename?: 'GlobalThreeItemEditorialSubtitleBG' }
          | { __typename?: 'GlobalTransformationSlider' }
          | { __typename?: 'GlobalTrendingHashtagBlock' }
          | { __typename?: 'GlobalTrendingHashtagCollection' }
          | { __typename?: 'GlobalTwoBestSellers' }
          | { __typename?: 'GlobalTwoItemEditorial' }
          | { __typename?: 'GlobalTwoItemImageTextBlock' }
          | { __typename?: 'GlobalTwoItemImageTextCTA' }
          | { __typename?: 'GlobalTwoItemImageTextCTA3070' }
          | { __typename?: 'GlobalUGCCarousel' }
          | { __typename?: 'GlobalVideoGallery' }
          | { __typename?: 'GlobalVideoHeroBannerWidget' }
          | { __typename?: 'GlobalVideoTextImageCTA' }
          | { __typename?: 'GlobalWaitListSignUpFormResponses' }
          | { __typename?: 'GlobalWaitListSignUpWidget' }
          | { __typename?: 'GlobalWidgetSirius' }
          | { __typename?: 'GlobalWidgetVega' }
          | { __typename?: 'GlobalYoutubeGalleryItem' }
          | { __typename?: 'GlobalYoutubeGallerySet' }
          | { __typename?: 'LiveChatWidget' }
          | { __typename?: 'LoyaltyHubBirthdayGift' }
          | { __typename?: 'LoyaltyHubTier' }
          | { __typename?: 'LoyaltyRewardTier' }
          | { __typename?: 'LoyaltyRewardTiers' }
          | { __typename?: 'MailingList' }
          | { __typename?: 'MultipleCTABanner' }
          | { __typename?: 'ProductListWidget' }
          | { __typename?: 'ProductRecommenderItem' }
          | { __typename?: 'ProfileWidget' }
          | { __typename?: 'ResponsiveBuildYourOwnBundle' }
          | { __typename?: 'ResponsiveBuildYourOwnBundleProducts' }
          | { __typename?: 'ResponsiveComparisonTable' }
          | { __typename?: 'ResponsiveComparisonTableContainer' }
          | { __typename?: 'ResponsiveProductBlockCarousel' }
          | { __typename?: 'ResponsiveProductTabs' }
          | { __typename?: 'ResponsiveSetSalon' }
          | { __typename?: 'ResponsiveSetSalonWithSlides' }
          | { __typename?: 'ResponsiveSliderSet' }
          | { __typename?: 'ResponsiveSuccessStoriesWidgetContainer' }
          | { __typename?: 'ResponsiveSuccessStoryWidget' }
          | { __typename?: 'ResponsiveTwoItemComparisonContainer' }
          | { __typename?: 'ResponsiveTwoItemComparisonRow' }
          | { __typename?: 'ResponsiveUSPBar' }
          | { __typename?: 'ResponsiveUSPBarCustomisable' }
          | { __typename?: 'SeoProductCategory' }
          | { __typename?: 'SeoProductCategorySet' }
          | { __typename?: 'ShopTheLookHotSpot' }
          | { __typename?: 'SimpleTextReadMore' }
          | { __typename?: 'TopBrandsWidget' }
          | { __typename?: 'TopProductCategory' }
          | { __typename?: 'TopProductCategorySet' }
          | { __typename?: 'TrustPilotCarouselWidget' }
          | { __typename?: 'TrustPilotWidget' }
          | { __typename?: 'VoucherCodesWidget' }
          | { __typename?: 'asymmetricGrid' }
          | { __typename?: 'buildyourownbundle' }
          | { __typename?: 'buttonLink' }
          | { __typename?: 'categoryItemCard' }
          | { __typename?: 'categoryItemCardWithTextOverlay' }
          | { __typename?: 'coverageCalculator' }
          | { __typename?: 'eightLinkButtonWidget' }
          | { __typename?: 'fouritemwindowproductslider' }
          | { __typename?: 'generalIframe' }
          | { __typename?: 'generalQuoteBanner' }
          | { __typename?: 'imageSelectCard' }
          | { __typename?: 'imageSelectSlider' }
          | { __typename?: 'improvedSearchBestSellers' }
          | { __typename?: 'kitBuilder' }
          | { __typename?: 'loyaltyRewardsList' }
          | { __typename?: 'loyaltyWelcomeMessage' }
          | { __typename?: 'multiCategoryCardSet' }
          | { __typename?: 'multiCategoryCardSetV2' }
          | { __typename?: 'obsessVR' }
          | { __typename?: 'parcelLabWidget' }
          | { __typename?: 'promoproductslider' }
          | { __typename?: 'propositionBar' }
          | { __typename?: 'propositionBarIncorrect' }
          | { __typename?: 'provenanceBrandHeader' }
          | { __typename?: 'qubitHomePageRecs' }
          | { __typename?: 'revieveWidget' }
          | { __typename?: 'shadeFinder' }
          | { __typename?: 'shopTheLook' }
          | { __typename?: 'simpleCTA' }
          | { __typename?: 'simpleDivider' }
          | { __typename?: 'simpleTextWidgetSet' }
          | { __typename?: 'simpleTitleWidget' }
          | { __typename?: 'simpletextwidget' }
          | { __typename?: 'simpletextwidgetwithh1' }
          | { __typename?: 'sponsoredProducts' }
          | { __typename?: 'sponsoredProductsCriteoHomepage' }
          | { __typename?: 'sponsoredProductsNew' }
          | { __typename?: 'storyStreamWidget' }
          | { __typename?: 'subscribeAndSaveInformationModal' }
          | { __typename?: 'swatchSelectSlider' }
          | { __typename?: 'swatchSelectSliderSet' }
          | { __typename?: 'trustReinforcementContainer' }
          | { __typename?: 'trustReinforcementSection' }
          | { __typename?: 'videoEditorial' }
          | { __typename?: 'yotiAgeVerification' }
          | { __typename?: 'yotiCancelFrame' }
        >
      >
    >
  }

type AllWidgets_BmiCalculator_Fragment = {
  __typename?: 'BMICalculator'
} & Pick<
  BmiCalculator,
  | 'calculateButtonText'
  | 'imperialDefault'
  | 'formSummaryObeseWeightTitle'
  | 'formSummaryDefaultTitle'
  | 'nextButtonText'
  | 'formSummaryUnderWeightTitle'
  | 'nextButtonOverWeightLink'
  | 'formSummaryDefaultText'
  | 'widgetTitle'
  | 'formSummaryNormalWeightText'
  | 'nextButtonUnderWeightLink'
  | 'formSummaryOverWeightText'
  | 'nextButtonNormalWeightLink'
  | 'widgetSubtitle'
  | 'formSummaryNormalWeightTitle'
  | 'formSummaryOverWeightTitle'
  | 'nextButtonObeseWeightLink'
  | 'formSummaryUnderWeightText'
  | 'formSummaryObeseWeightText'
  | 'id'
>

type AllWidgets_BmiCalculatorV2_Fragment = {
  __typename?: 'BMICalculatorV2'
} & Pick<
  BmiCalculatorV2,
  | 'nextButtonUnderWeightLink'
  | 'imperialDefault'
  | 'nextButtonNormalWeightLink'
  | 'nextButtonText'
  | 'widgetSubtitle'
  | 'nextButtonOverWeightLink'
  | 'nextButtonObeseWeightLink'
  | 'widgetTitle'
  | 'id'
>

type AllWidgets_BrandsPageWidget_Fragment = {
  __typename?: 'BrandsPageWidget'
} & Pick<BrandsPageWidget, 'id'>

type AllWidgets_BuildYourOwnBundleProductList_Fragment = {
  __typename?: 'BuildYourOwnBundleProductList'
} & Pick<
  BuildYourOwnBundleProductList,
  | 'maxSelectableProducts'
  | 'disablePackQuantity'
  | 'quantitySelector'
  | 'productCount'
  | 'list_row_bundle_size'
  | 'maxSelectableErrorMessage'
  | 'widgetTitle'
  | 'id'
>

type AllWidgets_CriteoSponsoredBannerAds_Fragment = {
  __typename?: 'CriteoSponsoredBannerAds'
} & Pick<CriteoSponsoredBannerAds, 'id'>

type AllWidgets_CriteoSponsoredBannerAdsFlagship_Fragment = {
  __typename?: 'CriteoSponsoredBannerAdsFlagship'
} & Pick<CriteoSponsoredBannerAdsFlagship, 'id'>

type AllWidgets_DeliveryInfoWidget_Fragment = {
  __typename?: 'DeliveryInfoWidget'
} & Pick<DeliveryInfoWidget, 'id'>

type AllWidgets_DeliveryInformationWidget_Fragment = {
  __typename?: 'DeliveryInformationWidget'
} & Pick<
  DeliveryInformationWidget,
  'hasDetails' | 'sectionTitle' | 'deliveryPrice' | 'deliveryOption' | 'id'
> & {
    sectionDetails?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
  }

type AllWidgets_DynamicReferralWidget_Fragment = {
  __typename?: 'DynamicReferralWidget'
} & Pick<
  DynamicReferralWidget,
  | 'friendsNote'
  | 'codeSubtitle'
  | 'mainTitle'
  | 'codeTitle'
  | 'showYourCodeSection'
  | 'mainSubtitle'
  | 'sharingTitle'
  | 'balanceNote'
  | 'copyTitle'
  | 'copySubtitle'
  | 'sharingSubtitle'
  | 'id'
>

type AllWidgets_Easiware_Fragment = { __typename?: 'Easiware' } & Pick<
  Easiware,
  'id'
>

type AllWidgets_EditorialWidget_Fragment = {
  __typename?: 'EditorialWidget'
} & Pick<EditorialWidget, 'id'> & {
    editorialWidgetText?: Maybe<
      { __typename: 'RichContent' } & RichContentHtmlFragment
    >
  }

type AllWidgets_EmailReEngagementModal_Fragment = {
  __typename?: 'EmailReEngagementModal'
} & Pick<
  EmailReEngagementModal,
  | 'imageAltText'
  | 'imageMedium'
  | 'emailReEngagementTitleText'
  | 'emailReEngagementBulletText3'
  | 'emailReEngagementBulletText2'
  | 'emailReEngagementBulletText1'
  | 'imageSmall'
  | 'imageLarge'
  | 'emailReEngagementOptInDiscount'
  | 'id'
>

type AllWidgets_FastTrackBanner_Fragment = {
  __typename?: 'FastTrackBanner'
} & Pick<
  FastTrackBanner,
  'fastTrackUrl' | 'fastTrackContent' | 'fastTrackHeader' | 'id'
>

type AllWidgets_FoundationFinderLandingPageWidget_Fragment = {
  __typename?: 'FoundationFinderLandingPageWidget'
} & Pick<FoundationFinderLandingPageWidget, 'id'>

type AllWidgets_GlobalAboutUsVideo_Fragment = {
  __typename?: 'GlobalAboutUsVideo'
} & Pick<
  GlobalAboutUsVideo,
  'buttonText' | 'videoURL' | 'buttonLink' | 'title' | 'videoTitle' | 'id'
> & {
    transcript?: Maybe<{ __typename?: 'RichContent' } & RichContentHtmlFragment>
    globalAboutUsVideoTextBlock?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
  }

type AllWidgets_GlobalAccreditationIcon_Fragment = {
  __typename?: 'GlobalAccreditationIcon'
} & Pick<
  GlobalAccreditationIcon,
  | 'imageAltText'
  | 'altText'
  | 'imagePath'
  | 'title'
  | 'url'
  | 'openNewWindow'
  | 'id'
>

type AllWidgets_GlobalAccreditationIconCollection_Fragment = {
  __typename?: 'GlobalAccreditationIconCollection'
} & Pick<GlobalAccreditationIconCollection, 'id'>

type AllWidgets_GlobalBrandLogos_Fragment = {
  __typename?: 'GlobalBrandLogos'
} & Pick<
  GlobalBrandLogos,
  | 'itemTwoURL'
  | 'itemSixURL'
  | 'itemThreeImageAlt'
  | 'itemTwoImage'
  | 'itemFiveImage'
  | 'itemThreeURL'
  | 'itemFourImageAlt'
  | 'itemFiveURL'
  | 'itemSixImage'
  | 'itemFourImage'
  | 'title'
  | 'itemTwoImageAlt'
  | 'itemFourURL'
  | 'itemThreeImage'
  | 'brandLogosItemOneVariantURL'
  | 'titleAlign'
  | 'itemOneImageAlt'
  | 'itemFiveImageAlt'
  | 'itemSixImageAlt'
  | 'isScrollable'
  | 'itemOneURL'
  | 'brandLogosItemOneImageVariantAlt'
  | 'itemOneImage'
  | 'brandLogosItemOneImageVariant'
  | 'id'
>

type AllWidgets_GlobalBuyingRightNow_Fragment = {
  __typename?: 'GlobalBuyingRightNow'
} & Pick<
  GlobalBuyingRightNow,
  | 'productTextThree'
  | 'productTextTwo'
  | 'productTextFour'
  | 'productTextOne'
  | 'title'
  | 'id'
> & {
    skufour?: Maybe<
      { __typename?: 'ProductVariant' } & ProductVariantFieldsFragment
    >
    skutwo?: Maybe<
      { __typename?: 'ProductVariant' } & ProductVariantFieldsFragment
    >
    sku?: Maybe<
      { __typename?: 'ProductVariant' } & ProductVariantFieldsFragment
    >
    skuthree?: Maybe<
      { __typename?: 'ProductVariant' } & ProductVariantFieldsFragment
    >
  }

type AllWidgets_GlobalBuyingRightNowManualProductUrl_Fragment = {
  __typename?: 'GlobalBuyingRightNowManualProductURL'
} & Pick<
  GlobalBuyingRightNowManualProductUrl,
  | 'productTextThree'
  | 'productTextFour'
  | 'productTextOne'
  | 'title'
  | 'url3'
  | 'productTextTwo'
  | 'url4'
  | 'url1'
  | 'url2'
  | 'id'
> & {
    skufour?: Maybe<
      { __typename?: 'ProductVariant' } & ProductVariantFieldsFragment
    >
    skutwo?: Maybe<
      { __typename?: 'ProductVariant' } & ProductVariantFieldsFragment
    >
    sku?: Maybe<
      { __typename?: 'ProductVariant' } & ProductVariantFieldsFragment
    >
    skuthree?: Maybe<
      { __typename?: 'ProductVariant' } & ProductVariantFieldsFragment
    >
  }

type AllWidgets_GlobalCardScrollerCard_Fragment = {
  __typename?: 'GlobalCardScrollerCard'
} & Pick<
  GlobalCardScrollerCard,
  | 'image'
  | 'buttonText'
  | 'colorOfText'
  | 'altText'
  | 'verticalPositionOfText'
  | 'subtitle'
  | 'title'
  | 'url'
  | 'id'
>

type AllWidgets_GlobalCardScrollerSet_Fragment = {
  __typename?: 'GlobalCardScrollerSet'
} & Pick<GlobalCardScrollerSet, 'title' | 'cardStyle' | 'id'> & {
    banners?: Maybe<
      Array<
        Maybe<
          | { __typename?: 'AccordionWidget' }
          | { __typename?: 'AccordionWidgetContainer' }
          | { __typename?: 'BMICalculator' }
          | { __typename?: 'BMICalculatorV2' }
          | { __typename?: 'BrandsPageWidget' }
          | { __typename?: 'BuildYourOwnBundleProductList' }
          | { __typename?: 'CriteoSponsoredBannerAds' }
          | { __typename?: 'CriteoSponsoredBannerAdsFlagship' }
          | { __typename?: 'DeliveryInfoWidget' }
          | { __typename?: 'DeliveryInformationWidget' }
          | { __typename?: 'DynamicReferralWidget' }
          | { __typename?: 'Easiware' }
          | { __typename?: 'EditorialWidget' }
          | { __typename?: 'EmailReEngagementModal' }
          | { __typename?: 'FastTrackBanner' }
          | { __typename?: 'FoundationFinderLandingPageWidget' }
          | { __typename?: 'GlobalAboutUsVideo' }
          | { __typename?: 'GlobalAccreditationIcon' }
          | { __typename?: 'GlobalAccreditationIconCollection' }
          | { __typename?: 'GlobalBrandLogos' }
          | { __typename?: 'GlobalBuyingRightNow' }
          | { __typename?: 'GlobalBuyingRightNowManualProductURL' }
          | ({ __typename: 'GlobalCardScrollerCard' } & Pick<
              GlobalCardScrollerCard,
              | 'image'
              | 'buttonText'
              | 'colorOfText'
              | 'altText'
              | 'verticalPositionOfText'
              | 'subtitle'
              | 'title'
              | 'url'
            >)
          | { __typename?: 'GlobalCardScrollerSet' }
          | { __typename?: 'GlobalDispatchAndDateCountdownWidget' }
          | { __typename?: 'GlobalEditorialWithFeature' }
          | { __typename?: 'GlobalFooterAccreditationIcons' }
          | { __typename?: 'GlobalFooterContactUs' }
          | { __typename?: 'GlobalFourBestSellers' }
          | { __typename?: 'GlobalFourButtonLink' }
          | { __typename?: 'GlobalFourItemEditorial' }
          | { __typename?: 'GlobalGeneralImageBanner' }
          | { __typename?: 'GlobalHelpCentreAccordion' }
          | { __typename?: 'GlobalHelpCentreAccordion2' }
          | { __typename?: 'GlobalHelpCentreAccordionCollection' }
          | { __typename?: 'GlobalHelpCentreCollection' }
          | { __typename?: 'GlobalHeroCTABanner' }
          | { __typename?: 'GlobalImageCard' }
          | { __typename?: 'GlobalImageCardSet' }
          | { __typename?: 'GlobalMultiButton' }
          | { __typename?: 'GlobalPrimaryBanner' }
          | { __typename?: 'GlobalPrimaryBannerWithList' }
          | { __typename?: 'GlobalPrimaryBannerWithTextOverlay' }
          | { __typename?: 'GlobalProductCardScroller' }
          | { __typename?: 'GlobalReferral3StepGuide' }
          | { __typename?: 'GlobalScalableLogos' }
          | { __typename?: 'GlobalSectionPeek' }
          | { __typename?: 'GlobalSetAndromeda' }
          | { __typename?: 'GlobalSimpleTextCTAWidget' }
          | { __typename?: 'GlobalSixItemCategories' }
          | { __typename?: 'GlobalSocialIcon' }
          | { __typename?: 'GlobalSocialIconCollection' }
          | { __typename?: 'GlobalSocialIconCollectionv2' }
          | { __typename?: 'GlobalSocialIconv2' }
          | { __typename?: 'GlobalStripBanner' }
          | { __typename?: 'GlobalSubscriptionOptions' }
          | { __typename?: 'GlobalTabbedWidgetSet' }
          | { __typename?: 'GlobalThreeItemEditorial' }
          | { __typename?: 'GlobalThreeItemEditorialSubtitleBG' }
          | { __typename?: 'GlobalTransformationSlider' }
          | { __typename?: 'GlobalTrendingHashtagBlock' }
          | { __typename?: 'GlobalTrendingHashtagCollection' }
          | { __typename?: 'GlobalTwoBestSellers' }
          | { __typename?: 'GlobalTwoItemEditorial' }
          | { __typename?: 'GlobalTwoItemImageTextBlock' }
          | { __typename?: 'GlobalTwoItemImageTextCTA' }
          | { __typename?: 'GlobalTwoItemImageTextCTA3070' }
          | { __typename?: 'GlobalUGCCarousel' }
          | { __typename?: 'GlobalVideoGallery' }
          | { __typename?: 'GlobalVideoHeroBannerWidget' }
          | { __typename?: 'GlobalVideoTextImageCTA' }
          | { __typename?: 'GlobalWaitListSignUpFormResponses' }
          | { __typename?: 'GlobalWaitListSignUpWidget' }
          | { __typename?: 'GlobalWidgetSirius' }
          | { __typename?: 'GlobalWidgetVega' }
          | { __typename?: 'GlobalYoutubeGalleryItem' }
          | { __typename?: 'GlobalYoutubeGallerySet' }
          | { __typename?: 'LiveChatWidget' }
          | { __typename?: 'LoyaltyHubBirthdayGift' }
          | { __typename?: 'LoyaltyHubTier' }
          | { __typename?: 'LoyaltyRewardTier' }
          | { __typename?: 'LoyaltyRewardTiers' }
          | { __typename?: 'MailingList' }
          | { __typename?: 'MultipleCTABanner' }
          | { __typename?: 'ProductListWidget' }
          | { __typename?: 'ProductRecommenderItem' }
          | { __typename?: 'ProfileWidget' }
          | { __typename?: 'ResponsiveBuildYourOwnBundle' }
          | { __typename?: 'ResponsiveBuildYourOwnBundleProducts' }
          | { __typename?: 'ResponsiveComparisonTable' }
          | { __typename?: 'ResponsiveComparisonTableContainer' }
          | { __typename?: 'ResponsiveProductBlockCarousel' }
          | { __typename?: 'ResponsiveProductTabs' }
          | { __typename?: 'ResponsiveSetSalon' }
          | { __typename?: 'ResponsiveSetSalonWithSlides' }
          | { __typename?: 'ResponsiveSliderSet' }
          | { __typename?: 'ResponsiveSuccessStoriesWidgetContainer' }
          | { __typename?: 'ResponsiveSuccessStoryWidget' }
          | { __typename?: 'ResponsiveTwoItemComparisonContainer' }
          | { __typename?: 'ResponsiveTwoItemComparisonRow' }
          | { __typename?: 'ResponsiveUSPBar' }
          | { __typename?: 'ResponsiveUSPBarCustomisable' }
          | { __typename?: 'SeoProductCategory' }
          | { __typename?: 'SeoProductCategorySet' }
          | { __typename?: 'ShopTheLookHotSpot' }
          | { __typename?: 'SimpleTextReadMore' }
          | { __typename?: 'TopBrandsWidget' }
          | { __typename?: 'TopProductCategory' }
          | { __typename?: 'TopProductCategorySet' }
          | { __typename?: 'TrustPilotCarouselWidget' }
          | { __typename?: 'TrustPilotWidget' }
          | { __typename?: 'VoucherCodesWidget' }
          | { __typename?: 'asymmetricGrid' }
          | { __typename?: 'buildyourownbundle' }
          | { __typename?: 'buttonLink' }
          | { __typename?: 'categoryItemCard' }
          | { __typename?: 'categoryItemCardWithTextOverlay' }
          | { __typename?: 'coverageCalculator' }
          | { __typename?: 'eightLinkButtonWidget' }
          | { __typename?: 'fouritemwindowproductslider' }
          | { __typename?: 'generalIframe' }
          | { __typename?: 'generalQuoteBanner' }
          | { __typename?: 'imageSelectCard' }
          | { __typename?: 'imageSelectSlider' }
          | { __typename?: 'improvedSearchBestSellers' }
          | { __typename?: 'kitBuilder' }
          | { __typename?: 'loyaltyRewardsList' }
          | { __typename?: 'loyaltyWelcomeMessage' }
          | { __typename?: 'multiCategoryCardSet' }
          | { __typename?: 'multiCategoryCardSetV2' }
          | { __typename?: 'obsessVR' }
          | { __typename?: 'parcelLabWidget' }
          | { __typename?: 'promoproductslider' }
          | { __typename?: 'propositionBar' }
          | { __typename?: 'propositionBarIncorrect' }
          | { __typename?: 'provenanceBrandHeader' }
          | { __typename?: 'qubitHomePageRecs' }
          | { __typename?: 'revieveWidget' }
          | { __typename?: 'shadeFinder' }
          | { __typename?: 'shopTheLook' }
          | { __typename?: 'simpleCTA' }
          | { __typename?: 'simpleDivider' }
          | { __typename?: 'simpleTextWidgetSet' }
          | { __typename?: 'simpleTitleWidget' }
          | { __typename?: 'simpletextwidget' }
          | { __typename?: 'simpletextwidgetwithh1' }
          | { __typename?: 'sponsoredProducts' }
          | { __typename?: 'sponsoredProductsCriteoHomepage' }
          | { __typename?: 'sponsoredProductsNew' }
          | { __typename?: 'storyStreamWidget' }
          | { __typename?: 'subscribeAndSaveInformationModal' }
          | { __typename?: 'swatchSelectSlider' }
          | { __typename?: 'swatchSelectSliderSet' }
          | { __typename?: 'trustReinforcementContainer' }
          | { __typename?: 'trustReinforcementSection' }
          | { __typename?: 'videoEditorial' }
          | { __typename?: 'yotiAgeVerification' }
          | { __typename?: 'yotiCancelFrame' }
        >
      >
    >
  }

type AllWidgets_GlobalDispatchAndDateCountdownWidget_Fragment = {
  __typename?: 'GlobalDispatchAndDateCountdownWidget'
} & Pick<
  GlobalDispatchAndDateCountdownWidget,
  | 'secondsTitle'
  | 'dispatchIcon'
  | 'dispatchTimeForThursday'
  | 'removeOnEnd'
  | 'dispatchTimeForWednesday'
  | 'dispatchTimeForFriday'
  | 'hoursTitle'
  | 'minutesTitle'
  | 'countDownEndHour'
  | 'dispatchTimeForSunday'
  | 'countDownEndSeconds'
  | 'linkUrl'
  | 'defaultNextDayDeliveryCutoff'
  | 'dispatchTimeForSaturday'
  | 'dispatchTimer'
  | 'weeksTitle'
  | 'direction'
  | 'dispatchTimeForMonday'
  | 'showTimer'
  | 'countDownSubTitle'
  | 'dispatchTimeForTuesday'
  | 'countDownTitle'
  | 'countDownEndMonth'
  | 'countDownEndYear'
  | 'daysTitle'
  | 'countDownEndDay'
  | 'separator'
  | 'size'
  | 'decrement'
  | 'useLocal'
  | 'textAlignment'
  | 'countDownEndMinutes'
  | 'id'
>

type AllWidgets_GlobalEditorialWithFeature_Fragment = {
  __typename?: 'GlobalEditorialWithFeature'
} & Pick<
  GlobalEditorialWithFeature,
  | 'imageOneSmall'
  | 'imageFeaturedLarge'
  | 'linkItemOne'
  | 'imageFeaturedSmall'
  | 'linkItemTwo'
  | 'imageOneLarge'
  | 'titleItemTwo'
  | 'CTAFeatured'
  | 'altItemTwo'
  | 'linkFeatured'
  | 'description'
  | 'altTextFeatured'
  | 'title'
  | 'descriptionFeatured'
  | 'imageTwoLarge'
  | 'descriptionItemOne'
  | 'CTAItemOne'
  | 'altTextItemOne'
  | 'titleFeatured'
  | 'descriptionItemTwo'
  | 'imageTwoSmall'
  | 'CTAItemTwo'
  | 'titleItemOne'
  | 'id'
>

type AllWidgets_GlobalFooterAccreditationIcons_Fragment = {
  __typename?: 'GlobalFooterAccreditationIcons'
} & Pick<
  GlobalFooterAccreditationIcons,
  | 'image2Href'
  | 'Image3Href'
  | 'image3'
  | 'image1Alt'
  | 'image1Href'
  | 'Image2Alt'
  | 'Image3Alt'
  | 'image1'
  | 'image2'
  | 'id'
>

type AllWidgets_GlobalFooterContactUs_Fragment = {
  __typename?: 'GlobalFooterContactUs'
} & Pick<
  GlobalFooterContactUs,
  | 'line7'
  | 'line6'
  | 'line5'
  | 'line4'
  | 'line10'
  | 'line9'
  | 'line8'
  | 'title'
  | 'line3'
  | 'line2'
  | 'line1'
  | 'id'
>

type AllWidgets_GlobalFourBestSellers_Fragment = {
  __typename?: 'GlobalFourBestSellers'
} & Pick<
  GlobalFourBestSellers,
  | 'ProductTwoButtonText'
  | 'ProductThreeButtonText'
  | 'ProductFourButtonText'
  | 'ProductOneButtonText'
  | 'Title'
  | 'disableTitle'
  | 'id'
> & {
    ProductTwo?: Maybe<
      { __typename: 'ProductVariant' } & ProductVariantFieldsFragment
    >
    ProductOne?: Maybe<
      { __typename: 'ProductVariant' } & ProductVariantFieldsFragment
    >
    ProductThree?: Maybe<
      { __typename: 'ProductVariant' } & ProductVariantFieldsFragment
    >
    ProductFour?: Maybe<
      { __typename: 'ProductVariant' } & ProductVariantFieldsFragment
    >
  }

type AllWidgets_GlobalFourButtonLink_Fragment = {
  __typename?: 'GlobalFourButtonLink'
} & Pick<
  GlobalFourButtonLink,
  | 'buttonTextOne'
  | 'buttonTextThree'
  | 'buttonTextTwo'
  | 'buttonUrlTwo'
  | 'buttonUrlOne'
  | 'buttonUrlThree'
  | 'buttonTextFour'
  | 'buttonUrlFour'
  | 'id'
>

type AllWidgets_GlobalFourItemEditorial_Fragment = {
  __typename?: 'GlobalFourItemEditorial'
} & Pick<
  GlobalFourItemEditorial,
  | 'itemTwoCTA'
  | 'itemFourDescription'
  | 'itemTwoImage'
  | 'altItemTwo'
  | 'itemTwoSubtitle'
  | 'itemThreeCTA'
  | 'itemFourSubtitle'
  | 'itemThreeSubtitle'
  | 'altItemFour'
  | 'itemFourImage'
  | 'itemThreeImage'
  | 'itemFourCTA'
  | 'itemThreeDescription'
  | 'itemOneDescription'
  | 'itemOneUrl'
  | 'itemTwoDescription'
  | 'itemFourTitle'
  | 'itemOneImage'
  | 'itemTwoUrl'
  | 'itemTwoTitle'
  | 'altItemThree'
  | 'widgetLinkText'
  | 'itemThreeUrl'
  | 'itemOneTitle'
  | 'itemFourUrl'
  | 'widgetTitle'
  | 'noWrapItems'
  | 'styleModifier'
  | 'widgetLink'
  | 'itemThreeTitle'
  | 'itemOneSubtitle'
  | 'itemOneCTA'
  | 'textAlignment'
  | 'widgetSubtitle'
  | 'altItemOne'
  | 'itemsCTAAlignment'
  | 'id'
>

type AllWidgets_GlobalGeneralImageBanner_Fragment = {
  __typename?: 'GlobalGeneralImageBanner'
} & Pick<
  GlobalGeneralImageBanner,
  | 'imageAltText'
  | 'largeImage'
  | 'smallImage'
  | 'mediumImage'
  | 'linkUrl'
  | 'id'
>

type AllWidgets_GlobalHelpCentreAccordion_Fragment = {
  __typename?: 'GlobalHelpCentreAccordion'
} & Pick<GlobalHelpCentreAccordion, 'accordionHeading' | 'id'> & {
    accordionContent?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
  }

type AllWidgets_GlobalHelpCentreAccordion2_Fragment = {
  __typename?: 'GlobalHelpCentreAccordion2'
} & Pick<GlobalHelpCentreAccordion2, 'accordionHeading' | 'id'> & {
    accordionContent?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
  }

type AllWidgets_GlobalHelpCentreAccordionCollection_Fragment = {
  __typename?: 'GlobalHelpCentreAccordionCollection'
} & Pick<GlobalHelpCentreAccordionCollection, 'title' | 'id'>

type AllWidgets_GlobalHelpCentreCollection_Fragment = {
  __typename: 'GlobalHelpCentreCollection'
} & Pick<GlobalHelpCentreCollection, 'id'> & {
    banners?: Maybe<
      Array<
        Maybe<
          | { __typename: 'AccordionWidget' }
          | { __typename: 'AccordionWidgetContainer' }
          | { __typename: 'BMICalculator' }
          | { __typename: 'BMICalculatorV2' }
          | { __typename: 'BrandsPageWidget' }
          | { __typename: 'BuildYourOwnBundleProductList' }
          | { __typename: 'CriteoSponsoredBannerAds' }
          | { __typename: 'CriteoSponsoredBannerAdsFlagship' }
          | { __typename: 'DeliveryInfoWidget' }
          | { __typename: 'DeliveryInformationWidget' }
          | { __typename: 'DynamicReferralWidget' }
          | { __typename: 'Easiware' }
          | { __typename: 'EditorialWidget' }
          | { __typename: 'EmailReEngagementModal' }
          | { __typename: 'FastTrackBanner' }
          | { __typename: 'FoundationFinderLandingPageWidget' }
          | { __typename: 'GlobalAboutUsVideo' }
          | { __typename: 'GlobalAccreditationIcon' }
          | { __typename: 'GlobalAccreditationIconCollection' }
          | { __typename: 'GlobalBrandLogos' }
          | { __typename: 'GlobalBuyingRightNow' }
          | { __typename: 'GlobalBuyingRightNowManualProductURL' }
          | { __typename: 'GlobalCardScrollerCard' }
          | { __typename: 'GlobalCardScrollerSet' }
          | { __typename: 'GlobalDispatchAndDateCountdownWidget' }
          | { __typename: 'GlobalEditorialWithFeature' }
          | { __typename: 'GlobalFooterAccreditationIcons' }
          | { __typename: 'GlobalFooterContactUs' }
          | { __typename: 'GlobalFourBestSellers' }
          | { __typename: 'GlobalFourButtonLink' }
          | { __typename: 'GlobalFourItemEditorial' }
          | { __typename: 'GlobalGeneralImageBanner' }
          | { __typename: 'GlobalHelpCentreAccordion' }
          | { __typename: 'GlobalHelpCentreAccordion2' }
          | ({ __typename: 'GlobalHelpCentreAccordionCollection' } & Pick<
              GlobalHelpCentreAccordionCollection,
              'title'
            > & {
                banners?: Maybe<
                  Array<
                    Maybe<
                      | { __typename: 'AccordionWidget' }
                      | { __typename: 'AccordionWidgetContainer' }
                      | { __typename: 'BMICalculator' }
                      | { __typename: 'BMICalculatorV2' }
                      | { __typename: 'BrandsPageWidget' }
                      | { __typename: 'BuildYourOwnBundleProductList' }
                      | { __typename: 'CriteoSponsoredBannerAds' }
                      | { __typename: 'CriteoSponsoredBannerAdsFlagship' }
                      | { __typename: 'DeliveryInfoWidget' }
                      | { __typename: 'DeliveryInformationWidget' }
                      | { __typename: 'DynamicReferralWidget' }
                      | { __typename: 'Easiware' }
                      | { __typename: 'EditorialWidget' }
                      | { __typename: 'EmailReEngagementModal' }
                      | { __typename: 'FastTrackBanner' }
                      | { __typename: 'FoundationFinderLandingPageWidget' }
                      | { __typename: 'GlobalAboutUsVideo' }
                      | { __typename: 'GlobalAccreditationIcon' }
                      | { __typename: 'GlobalAccreditationIconCollection' }
                      | { __typename: 'GlobalBrandLogos' }
                      | { __typename: 'GlobalBuyingRightNow' }
                      | { __typename: 'GlobalBuyingRightNowManualProductURL' }
                      | { __typename: 'GlobalCardScrollerCard' }
                      | { __typename: 'GlobalCardScrollerSet' }
                      | { __typename: 'GlobalDispatchAndDateCountdownWidget' }
                      | { __typename: 'GlobalEditorialWithFeature' }
                      | { __typename: 'GlobalFooterAccreditationIcons' }
                      | { __typename: 'GlobalFooterContactUs' }
                      | { __typename: 'GlobalFourBestSellers' }
                      | { __typename: 'GlobalFourButtonLink' }
                      | { __typename: 'GlobalFourItemEditorial' }
                      | { __typename: 'GlobalGeneralImageBanner' }
                      | ({ __typename: 'GlobalHelpCentreAccordion' } & Pick<
                          GlobalHelpCentreAccordion,
                          'accordionHeading'
                        > & {
                            accordionContent?: Maybe<
                              { __typename?: 'RichContent' } & {
                                content: Array<
                                  { __typename?: 'RichContentItem' } & Pick<
                                    RichContentItem,
                                    'content'
                                  >
                                >
                              }
                            >
                          })
                      | ({ __typename: 'GlobalHelpCentreAccordion2' } & Pick<
                          GlobalHelpCentreAccordion2,
                          'accordionHeading'
                        > & {
                            accordionContent?: Maybe<
                              { __typename?: 'RichContent' } & {
                                content: Array<
                                  { __typename?: 'RichContentItem' } & Pick<
                                    RichContentItem,
                                    'content'
                                  >
                                >
                              }
                            >
                          })
                      | { __typename: 'GlobalHelpCentreAccordionCollection' }
                      | { __typename: 'GlobalHelpCentreCollection' }
                      | { __typename: 'GlobalHeroCTABanner' }
                      | { __typename: 'GlobalImageCard' }
                      | { __typename: 'GlobalImageCardSet' }
                      | { __typename: 'GlobalMultiButton' }
                      | { __typename: 'GlobalPrimaryBanner' }
                      | { __typename: 'GlobalPrimaryBannerWithList' }
                      | { __typename: 'GlobalPrimaryBannerWithTextOverlay' }
                      | { __typename: 'GlobalProductCardScroller' }
                      | { __typename: 'GlobalReferral3StepGuide' }
                      | { __typename: 'GlobalScalableLogos' }
                      | { __typename: 'GlobalSectionPeek' }
                      | { __typename: 'GlobalSetAndromeda' }
                      | { __typename: 'GlobalSimpleTextCTAWidget' }
                      | { __typename: 'GlobalSixItemCategories' }
                      | { __typename: 'GlobalSocialIcon' }
                      | { __typename: 'GlobalSocialIconCollection' }
                      | { __typename: 'GlobalSocialIconCollectionv2' }
                      | { __typename: 'GlobalSocialIconv2' }
                      | { __typename: 'GlobalStripBanner' }
                      | { __typename: 'GlobalSubscriptionOptions' }
                      | { __typename: 'GlobalTabbedWidgetSet' }
                      | { __typename: 'GlobalThreeItemEditorial' }
                      | { __typename: 'GlobalThreeItemEditorialSubtitleBG' }
                      | { __typename: 'GlobalTransformationSlider' }
                      | { __typename: 'GlobalTrendingHashtagBlock' }
                      | { __typename: 'GlobalTrendingHashtagCollection' }
                      | { __typename: 'GlobalTwoBestSellers' }
                      | { __typename: 'GlobalTwoItemEditorial' }
                      | { __typename: 'GlobalTwoItemImageTextBlock' }
                      | { __typename: 'GlobalTwoItemImageTextCTA' }
                      | { __typename: 'GlobalTwoItemImageTextCTA3070' }
                      | { __typename: 'GlobalUGCCarousel' }
                      | { __typename: 'GlobalVideoGallery' }
                      | { __typename: 'GlobalVideoHeroBannerWidget' }
                      | { __typename: 'GlobalVideoTextImageCTA' }
                      | { __typename: 'GlobalWaitListSignUpFormResponses' }
                      | { __typename: 'GlobalWaitListSignUpWidget' }
                      | { __typename: 'GlobalWidgetSirius' }
                      | { __typename: 'GlobalWidgetVega' }
                      | { __typename: 'GlobalYoutubeGalleryItem' }
                      | { __typename: 'GlobalYoutubeGallerySet' }
                      | { __typename: 'LiveChatWidget' }
                      | { __typename: 'LoyaltyHubBirthdayGift' }
                      | { __typename: 'LoyaltyHubTier' }
                      | { __typename: 'LoyaltyRewardTier' }
                      | { __typename: 'LoyaltyRewardTiers' }
                      | { __typename: 'MailingList' }
                      | { __typename: 'MultipleCTABanner' }
                      | { __typename: 'ProductListWidget' }
                      | { __typename: 'ProductRecommenderItem' }
                      | { __typename: 'ProfileWidget' }
                      | { __typename: 'ResponsiveBuildYourOwnBundle' }
                      | { __typename: 'ResponsiveBuildYourOwnBundleProducts' }
                      | { __typename: 'ResponsiveComparisonTable' }
                      | { __typename: 'ResponsiveComparisonTableContainer' }
                      | { __typename: 'ResponsiveProductBlockCarousel' }
                      | { __typename: 'ResponsiveProductTabs' }
                      | { __typename: 'ResponsiveSetSalon' }
                      | { __typename: 'ResponsiveSetSalonWithSlides' }
                      | { __typename: 'ResponsiveSliderSet' }
                      | {
                          __typename: 'ResponsiveSuccessStoriesWidgetContainer'
                        }
                      | { __typename: 'ResponsiveSuccessStoryWidget' }
                      | { __typename: 'ResponsiveTwoItemComparisonContainer' }
                      | { __typename: 'ResponsiveTwoItemComparisonRow' }
                      | { __typename: 'ResponsiveUSPBar' }
                      | { __typename: 'ResponsiveUSPBarCustomisable' }
                      | { __typename: 'SeoProductCategory' }
                      | { __typename: 'SeoProductCategorySet' }
                      | { __typename: 'ShopTheLookHotSpot' }
                      | { __typename: 'SimpleTextReadMore' }
                      | { __typename: 'TopBrandsWidget' }
                      | { __typename: 'TopProductCategory' }
                      | { __typename: 'TopProductCategorySet' }
                      | { __typename: 'TrustPilotCarouselWidget' }
                      | { __typename: 'TrustPilotWidget' }
                      | { __typename: 'VoucherCodesWidget' }
                      | { __typename: 'asymmetricGrid' }
                      | { __typename: 'buildyourownbundle' }
                      | { __typename: 'buttonLink' }
                      | { __typename: 'categoryItemCard' }
                      | { __typename: 'categoryItemCardWithTextOverlay' }
                      | { __typename: 'coverageCalculator' }
                      | { __typename: 'eightLinkButtonWidget' }
                      | { __typename: 'fouritemwindowproductslider' }
                      | { __typename: 'generalIframe' }
                      | { __typename: 'generalQuoteBanner' }
                      | { __typename: 'imageSelectCard' }
                      | { __typename: 'imageSelectSlider' }
                      | { __typename: 'improvedSearchBestSellers' }
                      | { __typename: 'kitBuilder' }
                      | { __typename: 'loyaltyRewardsList' }
                      | { __typename: 'loyaltyWelcomeMessage' }
                      | { __typename: 'multiCategoryCardSet' }
                      | { __typename: 'multiCategoryCardSetV2' }
                      | { __typename: 'obsessVR' }
                      | { __typename: 'parcelLabWidget' }
                      | { __typename: 'promoproductslider' }
                      | { __typename: 'propositionBar' }
                      | { __typename: 'propositionBarIncorrect' }
                      | { __typename: 'provenanceBrandHeader' }
                      | { __typename: 'qubitHomePageRecs' }
                      | { __typename: 'revieveWidget' }
                      | { __typename: 'shadeFinder' }
                      | { __typename: 'shopTheLook' }
                      | { __typename: 'simpleCTA' }
                      | { __typename: 'simpleDivider' }
                      | { __typename: 'simpleTextWidgetSet' }
                      | { __typename: 'simpleTitleWidget' }
                      | { __typename: 'simpletextwidget' }
                      | { __typename: 'simpletextwidgetwithh1' }
                      | { __typename: 'sponsoredProducts' }
                      | { __typename: 'sponsoredProductsCriteoHomepage' }
                      | { __typename: 'sponsoredProductsNew' }
                      | { __typename: 'storyStreamWidget' }
                      | { __typename: 'subscribeAndSaveInformationModal' }
                      | { __typename: 'swatchSelectSlider' }
                      | { __typename: 'swatchSelectSliderSet' }
                      | { __typename: 'trustReinforcementContainer' }
                      | { __typename: 'trustReinforcementSection' }
                      | { __typename: 'videoEditorial' }
                      | { __typename: 'yotiAgeVerification' }
                      | { __typename: 'yotiCancelFrame' }
                    >
                  >
                >
              })
          | { __typename: 'GlobalHelpCentreCollection' }
          | { __typename: 'GlobalHeroCTABanner' }
          | { __typename: 'GlobalImageCard' }
          | { __typename: 'GlobalImageCardSet' }
          | { __typename: 'GlobalMultiButton' }
          | { __typename: 'GlobalPrimaryBanner' }
          | { __typename: 'GlobalPrimaryBannerWithList' }
          | { __typename: 'GlobalPrimaryBannerWithTextOverlay' }
          | { __typename: 'GlobalProductCardScroller' }
          | { __typename: 'GlobalReferral3StepGuide' }
          | { __typename: 'GlobalScalableLogos' }
          | { __typename: 'GlobalSectionPeek' }
          | { __typename: 'GlobalSetAndromeda' }
          | { __typename: 'GlobalSimpleTextCTAWidget' }
          | { __typename: 'GlobalSixItemCategories' }
          | { __typename: 'GlobalSocialIcon' }
          | { __typename: 'GlobalSocialIconCollection' }
          | { __typename: 'GlobalSocialIconCollectionv2' }
          | { __typename: 'GlobalSocialIconv2' }
          | { __typename: 'GlobalStripBanner' }
          | { __typename: 'GlobalSubscriptionOptions' }
          | { __typename: 'GlobalTabbedWidgetSet' }
          | { __typename: 'GlobalThreeItemEditorial' }
          | { __typename: 'GlobalThreeItemEditorialSubtitleBG' }
          | { __typename: 'GlobalTransformationSlider' }
          | { __typename: 'GlobalTrendingHashtagBlock' }
          | { __typename: 'GlobalTrendingHashtagCollection' }
          | { __typename: 'GlobalTwoBestSellers' }
          | { __typename: 'GlobalTwoItemEditorial' }
          | { __typename: 'GlobalTwoItemImageTextBlock' }
          | { __typename: 'GlobalTwoItemImageTextCTA' }
          | { __typename: 'GlobalTwoItemImageTextCTA3070' }
          | { __typename: 'GlobalUGCCarousel' }
          | { __typename: 'GlobalVideoGallery' }
          | { __typename: 'GlobalVideoHeroBannerWidget' }
          | { __typename: 'GlobalVideoTextImageCTA' }
          | { __typename: 'GlobalWaitListSignUpFormResponses' }
          | { __typename: 'GlobalWaitListSignUpWidget' }
          | { __typename: 'GlobalWidgetSirius' }
          | { __typename: 'GlobalWidgetVega' }
          | { __typename: 'GlobalYoutubeGalleryItem' }
          | { __typename: 'GlobalYoutubeGallerySet' }
          | { __typename: 'LiveChatWidget' }
          | { __typename: 'LoyaltyHubBirthdayGift' }
          | { __typename: 'LoyaltyHubTier' }
          | { __typename: 'LoyaltyRewardTier' }
          | { __typename: 'LoyaltyRewardTiers' }
          | { __typename: 'MailingList' }
          | { __typename: 'MultipleCTABanner' }
          | { __typename: 'ProductListWidget' }
          | { __typename: 'ProductRecommenderItem' }
          | { __typename: 'ProfileWidget' }
          | { __typename: 'ResponsiveBuildYourOwnBundle' }
          | { __typename: 'ResponsiveBuildYourOwnBundleProducts' }
          | { __typename: 'ResponsiveComparisonTable' }
          | { __typename: 'ResponsiveComparisonTableContainer' }
          | { __typename: 'ResponsiveProductBlockCarousel' }
          | { __typename: 'ResponsiveProductTabs' }
          | { __typename: 'ResponsiveSetSalon' }
          | { __typename: 'ResponsiveSetSalonWithSlides' }
          | { __typename: 'ResponsiveSliderSet' }
          | { __typename: 'ResponsiveSuccessStoriesWidgetContainer' }
          | { __typename: 'ResponsiveSuccessStoryWidget' }
          | { __typename: 'ResponsiveTwoItemComparisonContainer' }
          | { __typename: 'ResponsiveTwoItemComparisonRow' }
          | { __typename: 'ResponsiveUSPBar' }
          | { __typename: 'ResponsiveUSPBarCustomisable' }
          | { __typename: 'SeoProductCategory' }
          | { __typename: 'SeoProductCategorySet' }
          | { __typename: 'ShopTheLookHotSpot' }
          | { __typename: 'SimpleTextReadMore' }
          | { __typename: 'TopBrandsWidget' }
          | { __typename: 'TopProductCategory' }
          | { __typename: 'TopProductCategorySet' }
          | { __typename: 'TrustPilotCarouselWidget' }
          | { __typename: 'TrustPilotWidget' }
          | { __typename: 'VoucherCodesWidget' }
          | { __typename: 'asymmetricGrid' }
          | { __typename: 'buildyourownbundle' }
          | { __typename: 'buttonLink' }
          | { __typename: 'categoryItemCard' }
          | { __typename: 'categoryItemCardWithTextOverlay' }
          | { __typename: 'coverageCalculator' }
          | { __typename: 'eightLinkButtonWidget' }
          | { __typename: 'fouritemwindowproductslider' }
          | { __typename: 'generalIframe' }
          | { __typename: 'generalQuoteBanner' }
          | { __typename: 'imageSelectCard' }
          | { __typename: 'imageSelectSlider' }
          | { __typename: 'improvedSearchBestSellers' }
          | { __typename: 'kitBuilder' }
          | { __typename: 'loyaltyRewardsList' }
          | { __typename: 'loyaltyWelcomeMessage' }
          | { __typename: 'multiCategoryCardSet' }
          | { __typename: 'multiCategoryCardSetV2' }
          | { __typename: 'obsessVR' }
          | { __typename: 'parcelLabWidget' }
          | { __typename: 'promoproductslider' }
          | { __typename: 'propositionBar' }
          | { __typename: 'propositionBarIncorrect' }
          | { __typename: 'provenanceBrandHeader' }
          | { __typename: 'qubitHomePageRecs' }
          | { __typename: 'revieveWidget' }
          | { __typename: 'shadeFinder' }
          | { __typename: 'shopTheLook' }
          | { __typename: 'simpleCTA' }
          | { __typename: 'simpleDivider' }
          | { __typename: 'simpleTextWidgetSet' }
          | { __typename: 'simpleTitleWidget' }
          | { __typename: 'simpletextwidget' }
          | { __typename: 'simpletextwidgetwithh1' }
          | { __typename: 'sponsoredProducts' }
          | { __typename: 'sponsoredProductsCriteoHomepage' }
          | { __typename: 'sponsoredProductsNew' }
          | { __typename: 'storyStreamWidget' }
          | { __typename: 'subscribeAndSaveInformationModal' }
          | { __typename: 'swatchSelectSlider' }
          | { __typename: 'swatchSelectSliderSet' }
          | { __typename: 'trustReinforcementContainer' }
          | { __typename: 'trustReinforcementSection' }
          | { __typename: 'videoEditorial' }
          | { __typename: 'yotiAgeVerification' }
          | { __typename: 'yotiCancelFrame' }
        >
      >
    >
  }

type AllWidgets_GlobalHeroCtaBanner_Fragment = {
  __typename?: 'GlobalHeroCTABanner'
} & Pick<
  GlobalHeroCtaBanner,
  | 'imageMedium'
  | 'buttonOne'
  | 'subtitle'
  | 'imageSmall'
  | 'imageDesktop'
  | 'buttonTwo'
  | 'imageExtraSmall'
  | 'headline'
  | 'buttonOneLink'
  | 'altTextImg'
  | 'buttonTwoLink'
  | 'id'
>

type AllWidgets_GlobalImageCard_Fragment = {
  __typename?: 'GlobalImageCard'
} & Pick<
  GlobalImageCard,
  | 'image'
  | 'buttonText'
  | 'colorOfText'
  | 'altText'
  | 'verticalPositionOfText'
  | 'subtitle'
  | 'noBorder'
  | 'title'
  | 'url'
  | 'id'
>

type AllWidgets_GlobalImageCardSet_Fragment = {
  __typename?: 'GlobalImageCardSet'
} & Pick<GlobalImageCardSet, 'noBorder' | 'title' | 'cardStyle' | 'id'> & {
    banners?: Maybe<
      Array<
        Maybe<
          | { __typename?: 'AccordionWidget' }
          | { __typename?: 'AccordionWidgetContainer' }
          | { __typename?: 'BMICalculator' }
          | { __typename?: 'BMICalculatorV2' }
          | { __typename?: 'BrandsPageWidget' }
          | { __typename?: 'BuildYourOwnBundleProductList' }
          | { __typename?: 'CriteoSponsoredBannerAds' }
          | { __typename?: 'CriteoSponsoredBannerAdsFlagship' }
          | { __typename?: 'DeliveryInfoWidget' }
          | { __typename?: 'DeliveryInformationWidget' }
          | { __typename?: 'DynamicReferralWidget' }
          | { __typename?: 'Easiware' }
          | { __typename?: 'EditorialWidget' }
          | { __typename?: 'EmailReEngagementModal' }
          | { __typename?: 'FastTrackBanner' }
          | { __typename?: 'FoundationFinderLandingPageWidget' }
          | { __typename?: 'GlobalAboutUsVideo' }
          | { __typename?: 'GlobalAccreditationIcon' }
          | { __typename?: 'GlobalAccreditationIconCollection' }
          | { __typename?: 'GlobalBrandLogos' }
          | { __typename?: 'GlobalBuyingRightNow' }
          | { __typename?: 'GlobalBuyingRightNowManualProductURL' }
          | { __typename?: 'GlobalCardScrollerCard' }
          | { __typename?: 'GlobalCardScrollerSet' }
          | { __typename?: 'GlobalDispatchAndDateCountdownWidget' }
          | { __typename?: 'GlobalEditorialWithFeature' }
          | { __typename?: 'GlobalFooterAccreditationIcons' }
          | { __typename?: 'GlobalFooterContactUs' }
          | { __typename?: 'GlobalFourBestSellers' }
          | { __typename?: 'GlobalFourButtonLink' }
          | { __typename?: 'GlobalFourItemEditorial' }
          | { __typename?: 'GlobalGeneralImageBanner' }
          | { __typename?: 'GlobalHelpCentreAccordion' }
          | { __typename?: 'GlobalHelpCentreAccordion2' }
          | { __typename?: 'GlobalHelpCentreAccordionCollection' }
          | { __typename?: 'GlobalHelpCentreCollection' }
          | { __typename?: 'GlobalHeroCTABanner' }
          | ({ __typename: 'GlobalImageCard' } & Pick<
              GlobalImageCard,
              | 'image'
              | 'buttonText'
              | 'colorOfText'
              | 'altText'
              | 'verticalPositionOfText'
              | 'subtitle'
              | 'noBorder'
              | 'title'
              | 'url'
            >)
          | { __typename?: 'GlobalImageCardSet' }
          | { __typename?: 'GlobalMultiButton' }
          | { __typename?: 'GlobalPrimaryBanner' }
          | { __typename?: 'GlobalPrimaryBannerWithList' }
          | { __typename?: 'GlobalPrimaryBannerWithTextOverlay' }
          | { __typename?: 'GlobalProductCardScroller' }
          | { __typename?: 'GlobalReferral3StepGuide' }
          | { __typename?: 'GlobalScalableLogos' }
          | { __typename?: 'GlobalSectionPeek' }
          | { __typename?: 'GlobalSetAndromeda' }
          | { __typename?: 'GlobalSimpleTextCTAWidget' }
          | { __typename?: 'GlobalSixItemCategories' }
          | { __typename?: 'GlobalSocialIcon' }
          | { __typename?: 'GlobalSocialIconCollection' }
          | { __typename?: 'GlobalSocialIconCollectionv2' }
          | { __typename?: 'GlobalSocialIconv2' }
          | { __typename?: 'GlobalStripBanner' }
          | { __typename?: 'GlobalSubscriptionOptions' }
          | { __typename?: 'GlobalTabbedWidgetSet' }
          | { __typename?: 'GlobalThreeItemEditorial' }
          | { __typename?: 'GlobalThreeItemEditorialSubtitleBG' }
          | { __typename?: 'GlobalTransformationSlider' }
          | { __typename?: 'GlobalTrendingHashtagBlock' }
          | { __typename?: 'GlobalTrendingHashtagCollection' }
          | { __typename?: 'GlobalTwoBestSellers' }
          | { __typename?: 'GlobalTwoItemEditorial' }
          | { __typename?: 'GlobalTwoItemImageTextBlock' }
          | { __typename?: 'GlobalTwoItemImageTextCTA' }
          | { __typename?: 'GlobalTwoItemImageTextCTA3070' }
          | { __typename?: 'GlobalUGCCarousel' }
          | { __typename?: 'GlobalVideoGallery' }
          | { __typename?: 'GlobalVideoHeroBannerWidget' }
          | { __typename?: 'GlobalVideoTextImageCTA' }
          | { __typename?: 'GlobalWaitListSignUpFormResponses' }
          | { __typename?: 'GlobalWaitListSignUpWidget' }
          | { __typename?: 'GlobalWidgetSirius' }
          | { __typename?: 'GlobalWidgetVega' }
          | { __typename?: 'GlobalYoutubeGalleryItem' }
          | { __typename?: 'GlobalYoutubeGallerySet' }
          | { __typename?: 'LiveChatWidget' }
          | { __typename?: 'LoyaltyHubBirthdayGift' }
          | { __typename?: 'LoyaltyHubTier' }
          | { __typename?: 'LoyaltyRewardTier' }
          | { __typename?: 'LoyaltyRewardTiers' }
          | { __typename?: 'MailingList' }
          | { __typename?: 'MultipleCTABanner' }
          | { __typename?: 'ProductListWidget' }
          | { __typename?: 'ProductRecommenderItem' }
          | { __typename?: 'ProfileWidget' }
          | { __typename?: 'ResponsiveBuildYourOwnBundle' }
          | { __typename?: 'ResponsiveBuildYourOwnBundleProducts' }
          | { __typename?: 'ResponsiveComparisonTable' }
          | { __typename?: 'ResponsiveComparisonTableContainer' }
          | { __typename?: 'ResponsiveProductBlockCarousel' }
          | { __typename?: 'ResponsiveProductTabs' }
          | { __typename?: 'ResponsiveSetSalon' }
          | { __typename?: 'ResponsiveSetSalonWithSlides' }
          | { __typename?: 'ResponsiveSliderSet' }
          | { __typename?: 'ResponsiveSuccessStoriesWidgetContainer' }
          | { __typename?: 'ResponsiveSuccessStoryWidget' }
          | { __typename?: 'ResponsiveTwoItemComparisonContainer' }
          | { __typename?: 'ResponsiveTwoItemComparisonRow' }
          | { __typename?: 'ResponsiveUSPBar' }
          | { __typename?: 'ResponsiveUSPBarCustomisable' }
          | { __typename?: 'SeoProductCategory' }
          | { __typename?: 'SeoProductCategorySet' }
          | { __typename?: 'ShopTheLookHotSpot' }
          | { __typename?: 'SimpleTextReadMore' }
          | { __typename?: 'TopBrandsWidget' }
          | { __typename?: 'TopProductCategory' }
          | { __typename?: 'TopProductCategorySet' }
          | { __typename?: 'TrustPilotCarouselWidget' }
          | { __typename?: 'TrustPilotWidget' }
          | { __typename?: 'VoucherCodesWidget' }
          | { __typename?: 'asymmetricGrid' }
          | { __typename?: 'buildyourownbundle' }
          | { __typename?: 'buttonLink' }
          | { __typename?: 'categoryItemCard' }
          | { __typename?: 'categoryItemCardWithTextOverlay' }
          | { __typename?: 'coverageCalculator' }
          | { __typename?: 'eightLinkButtonWidget' }
          | { __typename?: 'fouritemwindowproductslider' }
          | { __typename?: 'generalIframe' }
          | { __typename?: 'generalQuoteBanner' }
          | { __typename?: 'imageSelectCard' }
          | { __typename?: 'imageSelectSlider' }
          | { __typename?: 'improvedSearchBestSellers' }
          | { __typename?: 'kitBuilder' }
          | { __typename?: 'loyaltyRewardsList' }
          | { __typename?: 'loyaltyWelcomeMessage' }
          | { __typename?: 'multiCategoryCardSet' }
          | { __typename?: 'multiCategoryCardSetV2' }
          | { __typename?: 'obsessVR' }
          | { __typename?: 'parcelLabWidget' }
          | { __typename?: 'promoproductslider' }
          | { __typename?: 'propositionBar' }
          | { __typename?: 'propositionBarIncorrect' }
          | { __typename?: 'provenanceBrandHeader' }
          | { __typename?: 'qubitHomePageRecs' }
          | { __typename?: 'revieveWidget' }
          | { __typename?: 'shadeFinder' }
          | { __typename?: 'shopTheLook' }
          | { __typename?: 'simpleCTA' }
          | { __typename?: 'simpleDivider' }
          | { __typename?: 'simpleTextWidgetSet' }
          | { __typename?: 'simpleTitleWidget' }
          | { __typename?: 'simpletextwidget' }
          | { __typename?: 'simpletextwidgetwithh1' }
          | { __typename?: 'sponsoredProducts' }
          | { __typename?: 'sponsoredProductsCriteoHomepage' }
          | { __typename?: 'sponsoredProductsNew' }
          | { __typename?: 'storyStreamWidget' }
          | { __typename?: 'subscribeAndSaveInformationModal' }
          | { __typename?: 'swatchSelectSlider' }
          | { __typename?: 'swatchSelectSliderSet' }
          | { __typename?: 'trustReinforcementContainer' }
          | { __typename?: 'trustReinforcementSection' }
          | { __typename?: 'videoEditorial' }
          | { __typename?: 'yotiAgeVerification' }
          | { __typename?: 'yotiCancelFrame' }
        >
      >
    >
  }

type AllWidgets_GlobalMultiButton_Fragment = {
  __typename?: 'GlobalMultiButton'
} & Pick<
  GlobalMultiButton,
  | 'multiButtonWidget_itemTwoURL'
  | 'multiButtonWidget_itemOneCTAText'
  | 'multiButtonWidget_itemOneURL'
  | 'multiButtonWidget_buttonStyle'
  | 'multiButtonWidget_itemTwoCTAText'
  | 'multiButtonWidget_itemThreeCTAText'
  | 'multiButtonWidget_itemThreeURL'
  | 'multiButtonWidget_itemFourURL'
  | 'multiButtonWidget_itemFourCTAText'
  | 'multiButtonWidget_itemFiveURL'
  | 'multiButtonWidget_itemFiveCTAText'
  | 'multiButtonWidget_itemSixURL'
  | 'multiButtonWidget_itemSixCTAText'
  | 'id'
>

type AllWidgets_GlobalPrimaryBanner_Fragment = {
  __typename?: 'GlobalPrimaryBanner'
} & Pick<
  GlobalPrimaryBanner,
  | 'ctaTwoURL'
  | 'imageMedium'
  | 'ctaTwo'
  | 'contentTheme'
  | 'contentAlign'
  | 'bannerURL'
  | 'altLogoPng'
  | 'ctaTwoAriaLabel'
  | 'ctaOne'
  | 'logopngWhiteBG'
  | 'imageLarge'
  | 'ctaOneAriaLabel'
  | 'logopngImageBG'
  | 'useH1'
  | 'subtitle'
  | 'imageSmall'
  | 'contentBoxPosition'
  | 'headline'
  | 'ctaOneURL'
  | 'altImageLarge'
  | 'id'
>

type AllWidgets_GlobalPrimaryBannerWithList_Fragment = {
  __typename?: 'GlobalPrimaryBannerWithList'
} & Pick<
  GlobalPrimaryBannerWithList,
  | 'ctaTwoURL'
  | 'imageMedium'
  | 'ctaTwo'
  | 'contentTheme'
  | 'contentAlign'
  | 'bannerURL'
  | 'altLogoPng'
  | 'ctaTwoAriaLabel'
  | 'ctaOne'
  | 'listBrowserSection'
  | 'logopngWhiteBG'
  | 'imageLarge'
  | 'ctaOneAriaLabel'
  | 'logopngImageBG'
  | 'subtitle'
  | 'imageSmall'
  | 'contentBoxPosition'
  | 'headline'
  | 'ctaOneURL'
  | 'altImageLarge'
  | 'id'
>

type AllWidgets_GlobalPrimaryBannerWithTextOverlay_Fragment = {
  __typename?: 'GlobalPrimaryBannerWithTextOverlay'
} & Pick<
  GlobalPrimaryBannerWithTextOverlay,
  | 'ctaTwoURL'
  | 'imageMedium'
  | 'ctaTwo'
  | 'contentTheme'
  | 'contentAlign'
  | 'altLogoPng'
  | 'bannerURL'
  | 'ctaTwoAriaLabel'
  | 'ctaOne'
  | 'logopngWhiteBG'
  | 'imageLarge'
  | 'ctaOneAriaLabel'
  | 'openInTabTwo'
  | 'hasMargin'
  | 'openInTabOne'
  | 'logopngImageBG'
  | 'useH1'
  | 'subtitle'
  | 'imageSmall'
  | 'contentBoxPosition'
  | 'headline'
  | 'ctaOneURL'
  | 'altImageLarge'
  | 'id'
>

type AllWidgets_GlobalProductCardScroller_Fragment = {
  __typename?: 'GlobalProductCardScroller'
} & Pick<GlobalProductCardScroller, 'hasArrows' | 'title' | 'id'> & {
    productSkuList?: Maybe<
      Array<
        Maybe<{ __typename?: 'ProductVariant' } & ProductVariantFieldsFragment>
      >
    >
  }

type AllWidgets_GlobalReferral3StepGuide_Fragment = {
  __typename?: 'GlobalReferral3StepGuide'
} & Pick<
  GlobalReferral3StepGuide,
  | 'title'
  | 'subtitle'
  | 'smallprint'
  | 'step1icon'
  | 'step1title'
  | 'step1text'
  | 'step2text'
  | 'step2icon'
  | 'step2title'
  | 'step3icon'
  | 'step3text'
  | 'step3title'
  | 'id'
>

type AllWidgets_GlobalScalableLogos_Fragment = {
  __typename?: 'GlobalScalableLogos'
} & Pick<
  GlobalScalableLogos,
  | 'imagePathThree'
  | 'altTextOne'
  | 'urlFour'
  | 'urlTwo'
  | 'titleSix'
  | 'altTextThree'
  | 'urlThree'
  | 'altTextTwo'
  | 'titleFive'
  | 'titleOne'
  | 'urlOne'
  | 'titleThree'
  | 'urlSix'
  | 'imagePathFive'
  | 'altTextFive'
  | 'titleFour'
  | 'urlFive'
  | 'imagePathTwo'
  | 'imagePathSix'
  | 'imagePathFour'
  | 'altTextFour'
  | 'altTextSix'
  | 'imagePathOne'
  | 'titleTwo'
  | 'id'
>

type AllWidgets_GlobalSectionPeek_Fragment = {
  __typename: 'GlobalSectionPeek'
} & Pick<
  GlobalSectionPeek,
  | 'numberOfProducts'
  | 'title'
  | 'url'
  | 'titleAlign'
  | 'qubitPlacementId'
  | 'id'
>

type AllWidgets_GlobalSetAndromeda_Fragment = {
  __typename: 'GlobalSetAndromeda'
} & Pick<GlobalSetAndromeda, 'columns' | 'id'> & {
    banners?: Maybe<
      Array<
        Maybe<
          | { __typename?: 'AccordionWidget' }
          | { __typename?: 'AccordionWidgetContainer' }
          | { __typename?: 'BMICalculator' }
          | { __typename?: 'BMICalculatorV2' }
          | { __typename?: 'BrandsPageWidget' }
          | { __typename?: 'BuildYourOwnBundleProductList' }
          | { __typename?: 'CriteoSponsoredBannerAds' }
          | { __typename?: 'CriteoSponsoredBannerAdsFlagship' }
          | { __typename?: 'DeliveryInfoWidget' }
          | { __typename?: 'DeliveryInformationWidget' }
          | { __typename?: 'DynamicReferralWidget' }
          | { __typename?: 'Easiware' }
          | { __typename?: 'EditorialWidget' }
          | { __typename?: 'EmailReEngagementModal' }
          | { __typename?: 'FastTrackBanner' }
          | { __typename?: 'FoundationFinderLandingPageWidget' }
          | { __typename?: 'GlobalAboutUsVideo' }
          | { __typename?: 'GlobalAccreditationIcon' }
          | { __typename?: 'GlobalAccreditationIconCollection' }
          | { __typename?: 'GlobalBrandLogos' }
          | { __typename?: 'GlobalBuyingRightNow' }
          | { __typename?: 'GlobalBuyingRightNowManualProductURL' }
          | { __typename?: 'GlobalCardScrollerCard' }
          | { __typename?: 'GlobalCardScrollerSet' }
          | { __typename?: 'GlobalDispatchAndDateCountdownWidget' }
          | { __typename?: 'GlobalEditorialWithFeature' }
          | { __typename?: 'GlobalFooterAccreditationIcons' }
          | { __typename?: 'GlobalFooterContactUs' }
          | { __typename?: 'GlobalFourBestSellers' }
          | { __typename?: 'GlobalFourButtonLink' }
          | { __typename?: 'GlobalFourItemEditorial' }
          | { __typename?: 'GlobalGeneralImageBanner' }
          | { __typename?: 'GlobalHelpCentreAccordion' }
          | { __typename?: 'GlobalHelpCentreAccordion2' }
          | { __typename?: 'GlobalHelpCentreAccordionCollection' }
          | { __typename?: 'GlobalHelpCentreCollection' }
          | { __typename?: 'GlobalHeroCTABanner' }
          | { __typename?: 'GlobalImageCard' }
          | { __typename?: 'GlobalImageCardSet' }
          | { __typename?: 'GlobalMultiButton' }
          | { __typename?: 'GlobalPrimaryBanner' }
          | { __typename?: 'GlobalPrimaryBannerWithList' }
          | { __typename?: 'GlobalPrimaryBannerWithTextOverlay' }
          | { __typename?: 'GlobalProductCardScroller' }
          | { __typename?: 'GlobalReferral3StepGuide' }
          | { __typename?: 'GlobalScalableLogos' }
          | { __typename?: 'GlobalSectionPeek' }
          | { __typename?: 'GlobalSetAndromeda' }
          | { __typename?: 'GlobalSimpleTextCTAWidget' }
          | { __typename?: 'GlobalSixItemCategories' }
          | { __typename?: 'GlobalSocialIcon' }
          | { __typename?: 'GlobalSocialIconCollection' }
          | { __typename?: 'GlobalSocialIconCollectionv2' }
          | { __typename?: 'GlobalSocialIconv2' }
          | { __typename?: 'GlobalStripBanner' }
          | { __typename?: 'GlobalSubscriptionOptions' }
          | { __typename?: 'GlobalTabbedWidgetSet' }
          | { __typename?: 'GlobalThreeItemEditorial' }
          | { __typename?: 'GlobalThreeItemEditorialSubtitleBG' }
          | { __typename?: 'GlobalTransformationSlider' }
          | { __typename?: 'GlobalTrendingHashtagBlock' }
          | { __typename?: 'GlobalTrendingHashtagCollection' }
          | { __typename?: 'GlobalTwoBestSellers' }
          | { __typename?: 'GlobalTwoItemEditorial' }
          | { __typename?: 'GlobalTwoItemImageTextBlock' }
          | { __typename?: 'GlobalTwoItemImageTextCTA' }
          | { __typename?: 'GlobalTwoItemImageTextCTA3070' }
          | { __typename?: 'GlobalUGCCarousel' }
          | { __typename?: 'GlobalVideoGallery' }
          | { __typename?: 'GlobalVideoHeroBannerWidget' }
          | { __typename?: 'GlobalVideoTextImageCTA' }
          | { __typename?: 'GlobalWaitListSignUpFormResponses' }
          | { __typename?: 'GlobalWaitListSignUpWidget' }
          | ({ __typename: 'GlobalWidgetSirius' } & Pick<
              GlobalWidgetSirius,
              | 'id'
              | 'image'
              | 'widgetSiriusAltTextVariant'
              | 'altText'
              | 'widgetSiriusImageVariant'
              | 'imagePosition'
              | 'widgetSiriusLinkVariant'
              | 'link'
              | 'widgetSiriusButtonVariant'
              | 'widgetSiriusTitleVariant'
              | 'title'
              | 'widgetSiriusHighlightVariant'
              | 'button'
              | 'highlight'
              | 'subTitle'
              | 'widgetSiriusSubTitleVariant'
            >)
          | { __typename?: 'GlobalWidgetVega' }
          | { __typename?: 'GlobalYoutubeGalleryItem' }
          | { __typename?: 'GlobalYoutubeGallerySet' }
          | { __typename?: 'LiveChatWidget' }
          | { __typename?: 'LoyaltyHubBirthdayGift' }
          | { __typename?: 'LoyaltyHubTier' }
          | { __typename?: 'LoyaltyRewardTier' }
          | { __typename?: 'LoyaltyRewardTiers' }
          | { __typename?: 'MailingList' }
          | { __typename?: 'MultipleCTABanner' }
          | { __typename?: 'ProductListWidget' }
          | { __typename?: 'ProductRecommenderItem' }
          | { __typename?: 'ProfileWidget' }
          | { __typename?: 'ResponsiveBuildYourOwnBundle' }
          | { __typename?: 'ResponsiveBuildYourOwnBundleProducts' }
          | { __typename?: 'ResponsiveComparisonTable' }
          | { __typename?: 'ResponsiveComparisonTableContainer' }
          | { __typename?: 'ResponsiveProductBlockCarousel' }
          | { __typename?: 'ResponsiveProductTabs' }
          | { __typename?: 'ResponsiveSetSalon' }
          | { __typename?: 'ResponsiveSetSalonWithSlides' }
          | { __typename?: 'ResponsiveSliderSet' }
          | { __typename?: 'ResponsiveSuccessStoriesWidgetContainer' }
          | { __typename?: 'ResponsiveSuccessStoryWidget' }
          | { __typename?: 'ResponsiveTwoItemComparisonContainer' }
          | { __typename?: 'ResponsiveTwoItemComparisonRow' }
          | { __typename?: 'ResponsiveUSPBar' }
          | { __typename?: 'ResponsiveUSPBarCustomisable' }
          | { __typename?: 'SeoProductCategory' }
          | { __typename?: 'SeoProductCategorySet' }
          | { __typename?: 'ShopTheLookHotSpot' }
          | { __typename?: 'SimpleTextReadMore' }
          | { __typename?: 'TopBrandsWidget' }
          | { __typename?: 'TopProductCategory' }
          | { __typename?: 'TopProductCategorySet' }
          | { __typename?: 'TrustPilotCarouselWidget' }
          | { __typename?: 'TrustPilotWidget' }
          | { __typename?: 'VoucherCodesWidget' }
          | { __typename?: 'asymmetricGrid' }
          | { __typename?: 'buildyourownbundle' }
          | { __typename?: 'buttonLink' }
          | { __typename?: 'categoryItemCard' }
          | { __typename?: 'categoryItemCardWithTextOverlay' }
          | { __typename?: 'coverageCalculator' }
          | { __typename?: 'eightLinkButtonWidget' }
          | { __typename?: 'fouritemwindowproductslider' }
          | { __typename?: 'generalIframe' }
          | { __typename?: 'generalQuoteBanner' }
          | { __typename?: 'imageSelectCard' }
          | { __typename?: 'imageSelectSlider' }
          | { __typename?: 'improvedSearchBestSellers' }
          | { __typename?: 'kitBuilder' }
          | { __typename?: 'loyaltyRewardsList' }
          | { __typename?: 'loyaltyWelcomeMessage' }
          | { __typename?: 'multiCategoryCardSet' }
          | { __typename?: 'multiCategoryCardSetV2' }
          | { __typename?: 'obsessVR' }
          | { __typename?: 'parcelLabWidget' }
          | { __typename?: 'promoproductslider' }
          | { __typename?: 'propositionBar' }
          | { __typename?: 'propositionBarIncorrect' }
          | { __typename?: 'provenanceBrandHeader' }
          | { __typename?: 'qubitHomePageRecs' }
          | { __typename?: 'revieveWidget' }
          | { __typename?: 'shadeFinder' }
          | { __typename?: 'shopTheLook' }
          | { __typename?: 'simpleCTA' }
          | { __typename?: 'simpleDivider' }
          | { __typename?: 'simpleTextWidgetSet' }
          | { __typename?: 'simpleTitleWidget' }
          | { __typename?: 'simpletextwidget' }
          | { __typename?: 'simpletextwidgetwithh1' }
          | { __typename?: 'sponsoredProducts' }
          | { __typename?: 'sponsoredProductsCriteoHomepage' }
          | { __typename?: 'sponsoredProductsNew' }
          | { __typename?: 'storyStreamWidget' }
          | { __typename?: 'subscribeAndSaveInformationModal' }
          | { __typename?: 'swatchSelectSlider' }
          | { __typename?: 'swatchSelectSliderSet' }
          | { __typename?: 'trustReinforcementContainer' }
          | { __typename?: 'trustReinforcementSection' }
          | { __typename?: 'videoEditorial' }
          | { __typename?: 'yotiAgeVerification' }
          | { __typename?: 'yotiCancelFrame' }
        >
      >
    >
  }

type AllWidgets_GlobalSimpleTextCtaWidget_Fragment = {
  __typename?: 'GlobalSimpleTextCTAWidget'
} & Pick<
  GlobalSimpleTextCtaWidget,
  'itemOneCTAText' | 'textAlign' | 'itemOneURL' | 'title' | 'id'
> & { globalSimpleTextCTAWidgetText: GlobalSimpleTextCtaWidget['text'] }

type AllWidgets_GlobalSixItemCategories_Fragment = {
  __typename: 'GlobalSixItemCategories'
} & Pick<
  GlobalSixItemCategories,
  | 'itemTwoCTA'
  | 'itemTitleAlign'
  | 'itemTwoLink'
  | 'itemOneLink'
  | 'itemTwoImage'
  | 'altItemTwo'
  | 'altItemFive'
  | 'itemFiveImage'
  | 'itemThreeCTA'
  | 'itemSixImage'
  | 'altItemFour'
  | 'itemFourImage'
  | 'title'
  | 'itemCTAAlign'
  | 'itemThreeImage'
  | 'itemFourCTA'
  | 'itemFiveLink'
  | 'itemFourTitle'
  | 'itemThreeLink'
  | 'itemOneImage'
  | 'itemSixCTA'
  | 'itemTwoTitle'
  | 'itemFourLink'
  | 'altItemThree'
  | 'itemFiveCTA'
  | 'itemSixTitle'
  | 'itemOneTitle'
  | 'itemFiveTitle'
  | 'itemSixLink'
  | 'altItemSix'
  | 'itemThreeTitle'
  | 'itemOneCTA'
  | 'altItemOne'
  | 'id'
>

type AllWidgets_GlobalSocialIcon_Fragment = {
  __typename?: 'GlobalSocialIcon'
} & Pick<GlobalSocialIcon, 'icon' | 'url' | 'id'>

type AllWidgets_GlobalSocialIconCollection_Fragment = {
  __typename?: 'GlobalSocialIconCollection'
} & Pick<GlobalSocialIconCollection, 'title' | 'id'> & {
    banners?: Maybe<
      Array<
        Maybe<
          | { __typename?: 'AccordionWidget' }
          | { __typename?: 'AccordionWidgetContainer' }
          | { __typename?: 'BMICalculator' }
          | { __typename?: 'BMICalculatorV2' }
          | { __typename?: 'BrandsPageWidget' }
          | { __typename?: 'BuildYourOwnBundleProductList' }
          | { __typename?: 'CriteoSponsoredBannerAds' }
          | { __typename?: 'CriteoSponsoredBannerAdsFlagship' }
          | { __typename?: 'DeliveryInfoWidget' }
          | { __typename?: 'DeliveryInformationWidget' }
          | { __typename?: 'DynamicReferralWidget' }
          | { __typename?: 'Easiware' }
          | { __typename?: 'EditorialWidget' }
          | { __typename?: 'EmailReEngagementModal' }
          | { __typename?: 'FastTrackBanner' }
          | { __typename?: 'FoundationFinderLandingPageWidget' }
          | { __typename?: 'GlobalAboutUsVideo' }
          | { __typename?: 'GlobalAccreditationIcon' }
          | { __typename?: 'GlobalAccreditationIconCollection' }
          | { __typename?: 'GlobalBrandLogos' }
          | { __typename?: 'GlobalBuyingRightNow' }
          | { __typename?: 'GlobalBuyingRightNowManualProductURL' }
          | { __typename?: 'GlobalCardScrollerCard' }
          | { __typename?: 'GlobalCardScrollerSet' }
          | { __typename?: 'GlobalDispatchAndDateCountdownWidget' }
          | { __typename?: 'GlobalEditorialWithFeature' }
          | { __typename?: 'GlobalFooterAccreditationIcons' }
          | { __typename?: 'GlobalFooterContactUs' }
          | { __typename?: 'GlobalFourBestSellers' }
          | { __typename?: 'GlobalFourButtonLink' }
          | { __typename?: 'GlobalFourItemEditorial' }
          | { __typename?: 'GlobalGeneralImageBanner' }
          | { __typename?: 'GlobalHelpCentreAccordion' }
          | { __typename?: 'GlobalHelpCentreAccordion2' }
          | { __typename?: 'GlobalHelpCentreAccordionCollection' }
          | { __typename?: 'GlobalHelpCentreCollection' }
          | { __typename?: 'GlobalHeroCTABanner' }
          | { __typename?: 'GlobalImageCard' }
          | { __typename?: 'GlobalImageCardSet' }
          | { __typename?: 'GlobalMultiButton' }
          | { __typename?: 'GlobalPrimaryBanner' }
          | { __typename?: 'GlobalPrimaryBannerWithList' }
          | { __typename?: 'GlobalPrimaryBannerWithTextOverlay' }
          | { __typename?: 'GlobalProductCardScroller' }
          | { __typename?: 'GlobalReferral3StepGuide' }
          | { __typename?: 'GlobalScalableLogos' }
          | { __typename?: 'GlobalSectionPeek' }
          | { __typename?: 'GlobalSetAndromeda' }
          | { __typename?: 'GlobalSimpleTextCTAWidget' }
          | { __typename?: 'GlobalSixItemCategories' }
          | ({ __typename?: 'GlobalSocialIcon' } & Pick<
              GlobalSocialIcon,
              'icon' | 'url'
            >)
          | { __typename?: 'GlobalSocialIconCollection' }
          | { __typename?: 'GlobalSocialIconCollectionv2' }
          | { __typename?: 'GlobalSocialIconv2' }
          | { __typename?: 'GlobalStripBanner' }
          | { __typename?: 'GlobalSubscriptionOptions' }
          | { __typename?: 'GlobalTabbedWidgetSet' }
          | { __typename?: 'GlobalThreeItemEditorial' }
          | { __typename?: 'GlobalThreeItemEditorialSubtitleBG' }
          | { __typename?: 'GlobalTransformationSlider' }
          | { __typename?: 'GlobalTrendingHashtagBlock' }
          | { __typename?: 'GlobalTrendingHashtagCollection' }
          | { __typename?: 'GlobalTwoBestSellers' }
          | { __typename?: 'GlobalTwoItemEditorial' }
          | { __typename?: 'GlobalTwoItemImageTextBlock' }
          | { __typename?: 'GlobalTwoItemImageTextCTA' }
          | { __typename?: 'GlobalTwoItemImageTextCTA3070' }
          | { __typename?: 'GlobalUGCCarousel' }
          | { __typename?: 'GlobalVideoGallery' }
          | { __typename?: 'GlobalVideoHeroBannerWidget' }
          | { __typename?: 'GlobalVideoTextImageCTA' }
          | { __typename?: 'GlobalWaitListSignUpFormResponses' }
          | { __typename?: 'GlobalWaitListSignUpWidget' }
          | { __typename?: 'GlobalWidgetSirius' }
          | { __typename?: 'GlobalWidgetVega' }
          | { __typename?: 'GlobalYoutubeGalleryItem' }
          | { __typename?: 'GlobalYoutubeGallerySet' }
          | { __typename?: 'LiveChatWidget' }
          | { __typename?: 'LoyaltyHubBirthdayGift' }
          | { __typename?: 'LoyaltyHubTier' }
          | { __typename?: 'LoyaltyRewardTier' }
          | { __typename?: 'LoyaltyRewardTiers' }
          | { __typename?: 'MailingList' }
          | { __typename?: 'MultipleCTABanner' }
          | { __typename?: 'ProductListWidget' }
          | { __typename?: 'ProductRecommenderItem' }
          | { __typename?: 'ProfileWidget' }
          | { __typename?: 'ResponsiveBuildYourOwnBundle' }
          | { __typename?: 'ResponsiveBuildYourOwnBundleProducts' }
          | { __typename?: 'ResponsiveComparisonTable' }
          | { __typename?: 'ResponsiveComparisonTableContainer' }
          | { __typename?: 'ResponsiveProductBlockCarousel' }
          | { __typename?: 'ResponsiveProductTabs' }
          | { __typename?: 'ResponsiveSetSalon' }
          | { __typename?: 'ResponsiveSetSalonWithSlides' }
          | { __typename?: 'ResponsiveSliderSet' }
          | { __typename?: 'ResponsiveSuccessStoriesWidgetContainer' }
          | { __typename?: 'ResponsiveSuccessStoryWidget' }
          | { __typename?: 'ResponsiveTwoItemComparisonContainer' }
          | { __typename?: 'ResponsiveTwoItemComparisonRow' }
          | { __typename?: 'ResponsiveUSPBar' }
          | { __typename?: 'ResponsiveUSPBarCustomisable' }
          | { __typename?: 'SeoProductCategory' }
          | { __typename?: 'SeoProductCategorySet' }
          | { __typename?: 'ShopTheLookHotSpot' }
          | { __typename?: 'SimpleTextReadMore' }
          | { __typename?: 'TopBrandsWidget' }
          | { __typename?: 'TopProductCategory' }
          | { __typename?: 'TopProductCategorySet' }
          | { __typename?: 'TrustPilotCarouselWidget' }
          | { __typename?: 'TrustPilotWidget' }
          | { __typename?: 'VoucherCodesWidget' }
          | { __typename?: 'asymmetricGrid' }
          | { __typename?: 'buildyourownbundle' }
          | { __typename?: 'buttonLink' }
          | { __typename?: 'categoryItemCard' }
          | { __typename?: 'categoryItemCardWithTextOverlay' }
          | { __typename?: 'coverageCalculator' }
          | { __typename?: 'eightLinkButtonWidget' }
          | { __typename?: 'fouritemwindowproductslider' }
          | { __typename?: 'generalIframe' }
          | { __typename?: 'generalQuoteBanner' }
          | { __typename?: 'imageSelectCard' }
          | { __typename?: 'imageSelectSlider' }
          | { __typename?: 'improvedSearchBestSellers' }
          | { __typename?: 'kitBuilder' }
          | { __typename?: 'loyaltyRewardsList' }
          | { __typename?: 'loyaltyWelcomeMessage' }
          | { __typename?: 'multiCategoryCardSet' }
          | { __typename?: 'multiCategoryCardSetV2' }
          | { __typename?: 'obsessVR' }
          | { __typename?: 'parcelLabWidget' }
          | { __typename?: 'promoproductslider' }
          | { __typename?: 'propositionBar' }
          | { __typename?: 'propositionBarIncorrect' }
          | { __typename?: 'provenanceBrandHeader' }
          | { __typename?: 'qubitHomePageRecs' }
          | { __typename?: 'revieveWidget' }
          | { __typename?: 'shadeFinder' }
          | { __typename?: 'shopTheLook' }
          | { __typename?: 'simpleCTA' }
          | { __typename?: 'simpleDivider' }
          | { __typename?: 'simpleTextWidgetSet' }
          | { __typename?: 'simpleTitleWidget' }
          | { __typename?: 'simpletextwidget' }
          | { __typename?: 'simpletextwidgetwithh1' }
          | { __typename?: 'sponsoredProducts' }
          | { __typename?: 'sponsoredProductsCriteoHomepage' }
          | { __typename?: 'sponsoredProductsNew' }
          | { __typename?: 'storyStreamWidget' }
          | { __typename?: 'subscribeAndSaveInformationModal' }
          | { __typename?: 'swatchSelectSlider' }
          | { __typename?: 'swatchSelectSliderSet' }
          | { __typename?: 'trustReinforcementContainer' }
          | { __typename?: 'trustReinforcementSection' }
          | { __typename?: 'videoEditorial' }
          | { __typename?: 'yotiAgeVerification' }
          | { __typename?: 'yotiCancelFrame' }
        >
      >
    >
  }

type AllWidgets_GlobalSocialIconCollectionv2_Fragment = {
  __typename?: 'GlobalSocialIconCollectionv2'
} & Pick<GlobalSocialIconCollectionv2, 'title' | 'id'> & {
    banners?: Maybe<
      Array<
        Maybe<
          | { __typename?: 'AccordionWidget' }
          | { __typename?: 'AccordionWidgetContainer' }
          | { __typename?: 'BMICalculator' }
          | { __typename?: 'BMICalculatorV2' }
          | { __typename?: 'BrandsPageWidget' }
          | { __typename?: 'BuildYourOwnBundleProductList' }
          | { __typename?: 'CriteoSponsoredBannerAds' }
          | { __typename?: 'CriteoSponsoredBannerAdsFlagship' }
          | { __typename?: 'DeliveryInfoWidget' }
          | { __typename?: 'DeliveryInformationWidget' }
          | { __typename?: 'DynamicReferralWidget' }
          | { __typename?: 'Easiware' }
          | { __typename?: 'EditorialWidget' }
          | { __typename?: 'EmailReEngagementModal' }
          | { __typename?: 'FastTrackBanner' }
          | { __typename?: 'FoundationFinderLandingPageWidget' }
          | { __typename?: 'GlobalAboutUsVideo' }
          | { __typename?: 'GlobalAccreditationIcon' }
          | { __typename?: 'GlobalAccreditationIconCollection' }
          | { __typename?: 'GlobalBrandLogos' }
          | { __typename?: 'GlobalBuyingRightNow' }
          | { __typename?: 'GlobalBuyingRightNowManualProductURL' }
          | { __typename?: 'GlobalCardScrollerCard' }
          | { __typename?: 'GlobalCardScrollerSet' }
          | { __typename?: 'GlobalDispatchAndDateCountdownWidget' }
          | { __typename?: 'GlobalEditorialWithFeature' }
          | { __typename?: 'GlobalFooterAccreditationIcons' }
          | { __typename?: 'GlobalFooterContactUs' }
          | { __typename?: 'GlobalFourBestSellers' }
          | { __typename?: 'GlobalFourButtonLink' }
          | { __typename?: 'GlobalFourItemEditorial' }
          | { __typename?: 'GlobalGeneralImageBanner' }
          | { __typename?: 'GlobalHelpCentreAccordion' }
          | { __typename?: 'GlobalHelpCentreAccordion2' }
          | { __typename?: 'GlobalHelpCentreAccordionCollection' }
          | { __typename?: 'GlobalHelpCentreCollection' }
          | { __typename?: 'GlobalHeroCTABanner' }
          | { __typename?: 'GlobalImageCard' }
          | { __typename?: 'GlobalImageCardSet' }
          | { __typename?: 'GlobalMultiButton' }
          | { __typename?: 'GlobalPrimaryBanner' }
          | { __typename?: 'GlobalPrimaryBannerWithList' }
          | { __typename?: 'GlobalPrimaryBannerWithTextOverlay' }
          | { __typename?: 'GlobalProductCardScroller' }
          | { __typename?: 'GlobalReferral3StepGuide' }
          | { __typename?: 'GlobalScalableLogos' }
          | { __typename?: 'GlobalSectionPeek' }
          | { __typename?: 'GlobalSetAndromeda' }
          | { __typename?: 'GlobalSimpleTextCTAWidget' }
          | { __typename?: 'GlobalSixItemCategories' }
          | { __typename?: 'GlobalSocialIcon' }
          | { __typename?: 'GlobalSocialIconCollection' }
          | { __typename?: 'GlobalSocialIconCollectionv2' }
          | ({ __typename?: 'GlobalSocialIconv2' } & Pick<
              GlobalSocialIconv2,
              'icon' | 'url'
            >)
          | { __typename?: 'GlobalStripBanner' }
          | { __typename?: 'GlobalSubscriptionOptions' }
          | { __typename?: 'GlobalTabbedWidgetSet' }
          | { __typename?: 'GlobalThreeItemEditorial' }
          | { __typename?: 'GlobalThreeItemEditorialSubtitleBG' }
          | { __typename?: 'GlobalTransformationSlider' }
          | { __typename?: 'GlobalTrendingHashtagBlock' }
          | { __typename?: 'GlobalTrendingHashtagCollection' }
          | { __typename?: 'GlobalTwoBestSellers' }
          | { __typename?: 'GlobalTwoItemEditorial' }
          | { __typename?: 'GlobalTwoItemImageTextBlock' }
          | { __typename?: 'GlobalTwoItemImageTextCTA' }
          | { __typename?: 'GlobalTwoItemImageTextCTA3070' }
          | { __typename?: 'GlobalUGCCarousel' }
          | { __typename?: 'GlobalVideoGallery' }
          | { __typename?: 'GlobalVideoHeroBannerWidget' }
          | { __typename?: 'GlobalVideoTextImageCTA' }
          | { __typename?: 'GlobalWaitListSignUpFormResponses' }
          | { __typename?: 'GlobalWaitListSignUpWidget' }
          | { __typename?: 'GlobalWidgetSirius' }
          | { __typename?: 'GlobalWidgetVega' }
          | { __typename?: 'GlobalYoutubeGalleryItem' }
          | { __typename?: 'GlobalYoutubeGallerySet' }
          | { __typename?: 'LiveChatWidget' }
          | { __typename?: 'LoyaltyHubBirthdayGift' }
          | { __typename?: 'LoyaltyHubTier' }
          | { __typename?: 'LoyaltyRewardTier' }
          | { __typename?: 'LoyaltyRewardTiers' }
          | { __typename?: 'MailingList' }
          | { __typename?: 'MultipleCTABanner' }
          | { __typename?: 'ProductListWidget' }
          | { __typename?: 'ProductRecommenderItem' }
          | { __typename?: 'ProfileWidget' }
          | { __typename?: 'ResponsiveBuildYourOwnBundle' }
          | { __typename?: 'ResponsiveBuildYourOwnBundleProducts' }
          | { __typename?: 'ResponsiveComparisonTable' }
          | { __typename?: 'ResponsiveComparisonTableContainer' }
          | { __typename?: 'ResponsiveProductBlockCarousel' }
          | { __typename?: 'ResponsiveProductTabs' }
          | { __typename?: 'ResponsiveSetSalon' }
          | { __typename?: 'ResponsiveSetSalonWithSlides' }
          | { __typename?: 'ResponsiveSliderSet' }
          | { __typename?: 'ResponsiveSuccessStoriesWidgetContainer' }
          | { __typename?: 'ResponsiveSuccessStoryWidget' }
          | { __typename?: 'ResponsiveTwoItemComparisonContainer' }
          | { __typename?: 'ResponsiveTwoItemComparisonRow' }
          | { __typename?: 'ResponsiveUSPBar' }
          | { __typename?: 'ResponsiveUSPBarCustomisable' }
          | { __typename?: 'SeoProductCategory' }
          | { __typename?: 'SeoProductCategorySet' }
          | { __typename?: 'ShopTheLookHotSpot' }
          | { __typename?: 'SimpleTextReadMore' }
          | { __typename?: 'TopBrandsWidget' }
          | { __typename?: 'TopProductCategory' }
          | { __typename?: 'TopProductCategorySet' }
          | { __typename?: 'TrustPilotCarouselWidget' }
          | { __typename?: 'TrustPilotWidget' }
          | { __typename?: 'VoucherCodesWidget' }
          | { __typename?: 'asymmetricGrid' }
          | { __typename?: 'buildyourownbundle' }
          | { __typename?: 'buttonLink' }
          | { __typename?: 'categoryItemCard' }
          | { __typename?: 'categoryItemCardWithTextOverlay' }
          | { __typename?: 'coverageCalculator' }
          | { __typename?: 'eightLinkButtonWidget' }
          | { __typename?: 'fouritemwindowproductslider' }
          | { __typename?: 'generalIframe' }
          | { __typename?: 'generalQuoteBanner' }
          | { __typename?: 'imageSelectCard' }
          | { __typename?: 'imageSelectSlider' }
          | { __typename?: 'improvedSearchBestSellers' }
          | { __typename?: 'kitBuilder' }
          | { __typename?: 'loyaltyRewardsList' }
          | { __typename?: 'loyaltyWelcomeMessage' }
          | { __typename?: 'multiCategoryCardSet' }
          | { __typename?: 'multiCategoryCardSetV2' }
          | { __typename?: 'obsessVR' }
          | { __typename?: 'parcelLabWidget' }
          | { __typename?: 'promoproductslider' }
          | { __typename?: 'propositionBar' }
          | { __typename?: 'propositionBarIncorrect' }
          | { __typename?: 'provenanceBrandHeader' }
          | { __typename?: 'qubitHomePageRecs' }
          | { __typename?: 'revieveWidget' }
          | { __typename?: 'shadeFinder' }
          | { __typename?: 'shopTheLook' }
          | { __typename?: 'simpleCTA' }
          | { __typename?: 'simpleDivider' }
          | { __typename?: 'simpleTextWidgetSet' }
          | { __typename?: 'simpleTitleWidget' }
          | { __typename?: 'simpletextwidget' }
          | { __typename?: 'simpletextwidgetwithh1' }
          | { __typename?: 'sponsoredProducts' }
          | { __typename?: 'sponsoredProductsCriteoHomepage' }
          | { __typename?: 'sponsoredProductsNew' }
          | { __typename?: 'storyStreamWidget' }
          | { __typename?: 'subscribeAndSaveInformationModal' }
          | { __typename?: 'swatchSelectSlider' }
          | { __typename?: 'swatchSelectSliderSet' }
          | { __typename?: 'trustReinforcementContainer' }
          | { __typename?: 'trustReinforcementSection' }
          | { __typename?: 'videoEditorial' }
          | { __typename?: 'yotiAgeVerification' }
          | { __typename?: 'yotiCancelFrame' }
        >
      >
    >
  }

type AllWidgets_GlobalSocialIconv2_Fragment = {
  __typename?: 'GlobalSocialIconv2'
} & Pick<GlobalSocialIconv2, 'icon' | 'url' | 'id'>

type AllWidgets_GlobalStripBanner_Fragment = {
  __typename?: 'GlobalStripBanner'
} & Pick<
  GlobalStripBanner,
  | 'stripBannerTextPLP'
  | 'stripBannerURL'
  | 'stripBannerURLPDP'
  | 'stripBannerURLHomepage'
  | 'stripBannerText'
  | 'stripBannerURLBasket'
  | 'stripBannerTextPDP'
  | 'stripBannerURLAccount'
  | 'stripBannerTextBasket'
  | 'stripBannerTextAccount'
  | 'stripBannerURLPLP'
  | 'stripBannerTextHomepage'
  | 'id'
>

type AllWidgets_GlobalSubscriptionOptions_Fragment = {
  __typename?: 'GlobalSubscriptionOptions'
} & Pick<
  GlobalSubscriptionOptions,
  | 'subscriptionOptionsSkuFour'
  | 'subscriptionOptionsSkuTwoUspMessage'
  | 'subscriptionOptionsSkuFourDeliveryInfo'
  | 'subscriptionOptionsSkuTwoPrice'
  | 'subscriptionOptionsSkuOne'
  | 'subscriptionOptionsSkuOneTitle'
  | 'subscriptionOptionsSkuThreeTitle'
  | 'subscriptionOptionsSkuFourPriceAppend'
  | 'subscriptionOptionsSkuFourImage'
  | 'subscriptionOptionsSkuFourUspMessage'
  | 'subscriptionOptionsSkuTwoImage'
  | 'subscriptionOptionsSkuTwoSaveMessage'
  | 'subscriptionOptionsSkuFourSaveMessage'
  | 'subscriptionOptionsSkuThree'
  | 'subscriptionOptionsSkuThreePriceAppend'
  | 'subscriptionOptionsSkuOneDeliveryInfo'
  | 'subscriptionOptionsSkuThreeImage'
  | 'subscriptionOptionsSkuFourCTAText'
  | 'subscriptionOptionsSkuOneUspMessage'
  | 'subscriptionOptionsSkuOnePriceAppend'
  | 'subscriptionOptionsSkuThreePrice'
  | 'subscriptionOptionsShowRibbon'
  | 'subscriptionOptionsSkuTwoCTAText'
  | 'subscriptionOptionsSkuOneSaveMessage'
  | 'subscriptionOptionsSkuThreeDeliveryInfo'
  | 'subscriptionOptionsSkuFourTitle'
  | 'subscriptionOptionsSkuThreeCTAText'
  | 'subscriptionOptionsSkuTwoPriceAppend'
  | 'subscriptionOptionsSkuThreeUspMessage'
  | 'subscriptionOptionsSkuThreeSaveMessage'
  | 'subscriptionOptionsRibbonMessage'
  | 'subscriptionOptionsSkuOnePrice'
  | 'subscriptionOptionsSkuTwo'
  | 'subscriptionOptionsSkuOneImage'
  | 'subscriptionOptionsSkuTwoDeliveryInfo'
  | 'subscriptionOptionsSkuTwoTitle'
  | 'subscriptionOptionsSkuFourPrice'
  | 'subscriptionOptionsSkuOneCTAText'
  | 'id'
>

type AllWidgets_GlobalTabbedWidgetSet_Fragment = {
  __typename?: 'GlobalTabbedWidgetSet'
} & Pick<
  GlobalTabbedWidgetSet,
  | 'tabbedWidgetSetTitle'
  | 'tabbedWidgetTabTitle_1'
  | 'tabbedWidgetTabTitle_2'
  | 'tabbedWidgetSetSubTitle'
  | 'id'
>

type AllWidgets_GlobalThreeItemEditorial_Fragment = {
  __typename?: 'GlobalThreeItemEditorial'
} & Pick<
  GlobalThreeItemEditorial,
  | 'threeItemItemOneCTATextVariant'
  | 'itemOneCTAText'
  | 'itemThree_secondaryCTAUrl'
  | 'itemTwoImage'
  | 'altItemTwo'
  | 'itemTwoSubtitle'
  | 'threeItemItemOneSubtitleVariant'
  | 'itemTwoCTAText'
  | 'useCircularImages'
  | 'itemTwo_secondaryCTAUrl'
  | 'itemThreeSubtitle'
  | 'threeItemItemOneVariantUrl'
  | 'itemOne_secondaryCTAUrl'
  | 'itemThreeCTAText'
  | 'itemThreeImage'
  | 'itemThreeDescription'
  | 'itemOne_secondaryCTAText'
  | 'itemOneDescription'
  | 'itemOneUrl'
  | 'itemTwoDescription'
  | 'itemOneImage'
  | 'threeItemItemOneDescriptionVariant'
  | 'threeItemItemOneImageVariant'
  | 'itemTwoUrl'
  | 'itemTwoTitle'
  | 'altItemThree'
  | 'itemThreeUrl'
  | 'itemOneTitle'
  | 'threeItemItemOneTitleVariant'
  | 'threeItemItemOneAltTextVariant'
  | 'widgetHeadingSize'
  | 'widgetTitle'
  | 'itemTwo_secondaryCTAText'
  | 'itemThree_secondaryCTAText'
  | 'itemThreeTitle'
  | 'itemOneSubtitle'
  | 'widgetSubtitle'
  | 'altItemOne'
  | 'id'
>

type AllWidgets_GlobalThreeItemEditorialSubtitleBg_Fragment = {
  __typename?: 'GlobalThreeItemEditorialSubtitleBG'
} & Pick<
  GlobalThreeItemEditorialSubtitleBg,
  | 'itemOneCTAText'
  | 'itemTwoImage'
  | 'itemTwoSubtitle'
  | 'itemTwoCTAText'
  | 'description'
  | 'itemThreeSubtitle'
  | 'title'
  | 'itemTwoSubtitleBGColour'
  | 'itemThreeCTAText'
  | 'itemThreeImage'
  | 'itemThreeDescription'
  | 'itemOneDescription'
  | 'itemOneUrl'
  | 'itemTwoDescription'
  | 'itemOneImage'
  | 'itemTwoAltText'
  | 'itemTwoTitle'
  | 'itemTwoUrl'
  | 'itemOneAltText'
  | 'itemThreeUrl'
  | 'itemOneTitle'
  | 'itemOneSubtitleBGColour'
  | 'itemThreeSubtitleBGColour'
  | 'itemThreeTitle'
  | 'itemOneSubtitle'
  | 'itemThreeAltText'
  | 'id'
>

type AllWidgets_GlobalTransformationSlider_Fragment = {
  __typename?: 'GlobalTransformationSlider'
} & Pick<
  GlobalTransformationSlider,
  | 'beforeImageAltText'
  | 'beforeImageMedium'
  | 'titlePosition'
  | 'dividingLineColor'
  | 'dragButtonPosition'
  | 'dragLinePosition'
  | 'title'
  | 'afterImageMedium'
  | 'afterImageAltText'
  | 'afterImageLarge'
  | 'beforeImageLarge'
  | 'beforeImageSmall'
  | 'afterImageSmall'
  | 'id'
>

type AllWidgets_GlobalTrendingHashtagBlock_Fragment = {
  __typename?: 'GlobalTrendingHashtagBlock'
} & Pick<GlobalTrendingHashtagBlock, 'url' | 'hashtag' | 'id'>

type AllWidgets_GlobalTrendingHashtagCollection_Fragment = {
  __typename?: 'GlobalTrendingHashtagCollection'
} & Pick<GlobalTrendingHashtagCollection, 'id'>

type AllWidgets_GlobalTwoBestSellers_Fragment = {
  __typename?: 'GlobalTwoBestSellers'
} & Pick<
  GlobalTwoBestSellers,
  'ProductTwoButtonText' | 'ProductOneButtonText' | 'id'
> & {
    ProductTwo?: Maybe<
      { __typename: 'ProductVariant' } & ProductVariantFieldsFragment
    >
    ProductOne?: Maybe<
      { __typename: 'ProductVariant' } & ProductVariantFieldsFragment
    >
  }

type AllWidgets_GlobalTwoItemEditorial_Fragment = {
  __typename?: 'GlobalTwoItemEditorial'
} & Pick<
  GlobalTwoItemEditorial,
  | 'itemTwoTitle'
  | 'itemTwoURL'
  | 'itemOneCTAText'
  | 'itemTwoImage'
  | 'altItemTwo'
  | 'itemTwoCTAText'
  | 'itemOneTitle'
  | 'widgetTitle'
  | 'itemOneDescription'
  | 'itemOneURL'
  | 'widgetSubtitle'
  | 'itemTwoDescription'
  | 'altItemOne'
  | 'itemOneImage'
  | 'id'
>

type AllWidgets_GlobalTwoItemImageTextBlock_Fragment = {
  __typename?: 'GlobalTwoItemImageTextBlock'
} & Pick<
  GlobalTwoItemImageTextBlock,
  | 'button2Url'
  | 'itemButton'
  | 'itemAlign'
  | 'itemTitle'
  | 'textAlign'
  | 'itemImageSmall'
  | 'videoTitle'
  | 'itemImage'
  | 'itemAlt'
  | 'videoUrl'
  | 'button2Text'
  | 'itemURL'
  | 'imageAnnotationText'
  | 'imageProductURL'
  | 'imageProductText'
  | 'id'
> & {
    itemText?: Maybe<{ __typename: 'RichContent' } & RichContentHtmlFragment>
    transcript?: Maybe<{ __typename: 'RichContent' } & RichContentHtmlFragment>
  }

type AllWidgets_GlobalTwoItemImageTextCta_Fragment = {
  __typename?: 'GlobalTwoItemImageTextCTA'
} & Pick<
  GlobalTwoItemImageTextCta,
  | 'itemTwoURL'
  | 'itemTwoTitle'
  | 'itemTwoImageSmall'
  | 'itemOneCTAText'
  | 'contentTheme'
  | 'itemTwoImage'
  | 'itemTwoCTAText'
  | 'itemOneTitle'
  | 'itemOneDescription'
  | 'itemOneImageSmall'
  | 'itemOneURL'
  | 'itemOneImageAltText'
  | 'itemTwoDescription'
  | 'itemOneContentAlign'
  | 'itemTwoContentAlign'
  | 'itemOneImage'
  | 'itemTwoImageAltText'
  | 'id'
>

type AllWidgets_GlobalTwoItemImageTextCta3070_Fragment = {
  __typename?: 'GlobalTwoItemImageTextCTA3070'
} & Pick<
  GlobalTwoItemImageTextCta3070,
  | 'itemTwoURL'
  | 'itemTwoTitle'
  | 'itemTwoImageSmall'
  | 'itemOneCTAText'
  | 'contentTheme'
  | 'itemTwoImage'
  | 'itemTwoCTAText'
  | 'itemOneTitle'
  | 'itemOneDescription'
  | 'itemOneImageSmall'
  | 'itemOneURL'
  | 'itemOneImageAltText'
  | 'itemTwoDescription'
  | 'itemOneContentAlign'
  | 'itemTwoContentAlign'
  | 'itemOneImage'
  | 'itemTwoImageAltText'
  | 'id'
>

type AllWidgets_GlobalUgcCarousel_Fragment = {
  __typename?: 'GlobalUGCCarousel'
} & Pick<
  GlobalUgcCarousel,
  'ugcSubText' | 'tag' | 'ugcTitle' | 'category' | 'id'
>

type AllWidgets_GlobalVideoGallery_Fragment = {
  __typename?: 'GlobalVideoGallery'
} & Pick<
  GlobalVideoGallery,
  | 'itemTwoCTA'
  | 'useTitleHeadingLevel2'
  | 'itemSixVideoURL'
  | 'itemTwoImage'
  | 'itemFiveVideoURL'
  | 'itemSixButtonLink'
  | 'itemFiveImage'
  | 'itemOneVideoURL'
  | 'itemTwoButtonLink'
  | 'itemThreeCTA'
  | 'itemSixImage'
  | 'itemFourImage'
  | 'title'
  | 'itemThreeImage'
  | 'itemOneButtonLink'
  | 'itemFourCTA'
  | 'itemThreeImageAltText'
  | 'itemFourTitle'
  | 'itemOneImageAltText'
  | 'itemOneImage'
  | 'itemSixCTA'
  | 'itemTwoTitle'
  | 'itemFourVideoURL'
  | 'itemFiveButtonLink'
  | 'itemSixImageAltText'
  | 'textAlign'
  | 'itemTwoVideoURL'
  | 'itemThreeVideoURL'
  | 'itemFiveCTA'
  | 'itemSixTitle'
  | 'itemOneTitle'
  | 'textBlock'
  | 'itemFiveTitle'
  | 'itemFiveImageAltText'
  | 'itemFourImageAltText'
  | 'itemThreeTitle'
  | 'itemFourButtonLink'
  | 'itemOneCTA'
  | 'itemThreeButtonLink'
  | 'itemTwoImageAltText'
  | 'id'
> & {
    itemFiveTranscript?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
    itemOneTranscript?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
    itemFourTranscript?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
    itemSixTranscript?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
    itemThreeTranscript?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
    itemTwoTranscript?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
  }

type AllWidgets_GlobalVideoHeroBannerWidget_Fragment = {
  __typename?: 'GlobalVideoHeroBannerWidget'
} & Pick<
  GlobalVideoHeroBannerWidget,
  | 'videoPreviewImageMobile'
  | 'videoPreviewImage'
  | 'muteVideo'
  | 'videoAssetPathMp4'
  | 'videoAssetPathWebm'
  | 'autoplayVideo'
  | 'videoTitle'
  | 'VideoPreviewImageTablet'
  | 'id'
>

type AllWidgets_GlobalVideoTextImageCta_Fragment = {
  __typename?: 'GlobalVideoTextImageCTA'
} & Pick<
  GlobalVideoTextImageCta,
  | 'itemImageLink'
  | 'itemCTALink'
  | 'videoUrl'
  | 'itemDescription'
  | 'itemImage'
  | 'widgetTitle'
  | 'itemCTA'
  | 'id'
>

type AllWidgets_GlobalWaitListSignUpFormResponses_Fragment = {
  __typename?: 'GlobalWaitListSignUpFormResponses'
} & Pick<
  GlobalWaitListSignUpFormResponses,
  | 'responseTitle'
  | 'responseStartSubText'
  | 'errorButtonText'
  | 'registerButtonText'
  | 'continueButtonText'
  | 'formResponse'
  | 'id'
> & {
    responseSubText?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
  }

type AllWidgets_GlobalWaitListSignUpWidget_Fragment = {
  __typename?: 'GlobalWaitListSignUpWidget'
} & Pick<
  GlobalWaitListSignUpWidget,
  | 'buttonText'
  | 'registeredReturnUrl'
  | 'fullNamePlaceholderText'
  | 'enableSignUp'
  | 'gdprDisclaimerText'
  | 'title'
  | 'noAccountReturnUrl'
  | 'signUpText'
  | 'subtitle'
  | 'placeholderText'
  | 'enableNameField'
  | 'campaignCode'
  | 'errorReturnUrl'
  | 'id'
>

type AllWidgets_GlobalWidgetSirius_Fragment = {
  __typename?: 'GlobalWidgetSirius'
} & Pick<
  GlobalWidgetSirius,
  | 'image'
  | 'widgetSiriusAltTextVariant'
  | 'altText'
  | 'widgetSiriusImageVariant'
  | 'imagePosition'
  | 'widgetSiriusLinkVariant'
  | 'link'
  | 'widgetSiriusButtonVariant'
  | 'widgetSiriusTitleVariant'
  | 'title'
  | 'widgetSiriusHighlightVariant'
  | 'button'
  | 'highlight'
  | 'subTitle'
  | 'widgetSiriusSubTitleVariant'
  | 'id'
>

type AllWidgets_GlobalWidgetVega_Fragment = {
  __typename?: 'GlobalWidgetVega'
} & Pick<
  GlobalWidgetVega,
  | 'container'
  | 'button'
  | 'highlight'
  | 'imageMobile'
  | 'subTitle'
  | 'textAlign'
  | 'containerBg'
  | 'link'
  | 'imageDesktop'
  | 'title'
  | 'altTextImg'
  | 'id'
>

type AllWidgets_GlobalYoutubeGalleryItem_Fragment = {
  __typename?: 'GlobalYoutubeGalleryItem'
} & Pick<GlobalYoutubeGalleryItem, 'videoId' | 'title' | 'id'>

type AllWidgets_GlobalYoutubeGallerySet_Fragment = {
  __typename?: 'GlobalYoutubeGallerySet'
} & Pick<GlobalYoutubeGallerySet, 'id'>

type AllWidgets_LiveChatWidget_Fragment = {
  __typename?: 'LiveChatWidget'
} & Pick<LiveChatWidget, 'id'>

type AllWidgets_LoyaltyHubBirthdayGift_Fragment = {
  __typename?: 'LoyaltyHubBirthdayGift'
} & Pick<
  LoyaltyHubBirthdayGift,
  | 'banner'
  | 'description'
  | 'giftActive'
  | 'title'
  | 'userNotification'
  | 'customerLists'
  | 'modalHeading'
  | 'formInstruction'
  | 'id'
>

type AllWidgets_LoyaltyHubTier_Fragment = {
  __typename?: 'LoyaltyHubTier'
} & Pick<
  LoyaltyHubTier,
  | 'hidden'
  | 'rewardsHeader'
  | 'bannerAlt'
  | 'iconType'
  | 'banner'
  | 'title'
  | 'textPart1'
  | 'textPart2'
  | 'textPart3'
  | 'id'
>

type AllWidgets_LoyaltyRewardTier_Fragment = {
  __typename?: 'LoyaltyRewardTier'
} & Pick<
  LoyaltyRewardTier,
  | 'tierListItem3'
  | 'tierListItem4'
  | 'tierType'
  | 'tierListItem5'
  | 'tierName'
  | 'tierRange'
  | 'tierListItem1'
  | 'tierListItem2'
  | 'id'
>

type AllWidgets_LoyaltyRewardTiers_Fragment = {
  __typename?: 'LoyaltyRewardTiers'
} & Pick<LoyaltyRewardTiers, 'id'>

type AllWidgets_MailingList_Fragment = { __typename?: 'MailingList' } & Pick<
  MailingList,
  | 'enablefreeAnswerField'
  | 'emailPlaceholderText'
  | 'fullNamePlaceholderText'
  | 'enableSignUp'
  | 'enableDropdownAnswerField'
  | 'dropdownAnswer5'
  | 'dropdownAnswer6'
  | 'dropdownAnswer3'
  | 'dropdownAnswer4'
  | 'dropdownAnswer1'
  | 'dropdownAnswer10'
  | 'dropdownAnswer2'
  | 'dropdownAnswer9'
  | 'dropdownAnswer7'
  | 'emailLabelText'
  | 'campaignCode'
  | 'dropdownAnswer8'
  | 'buttonText'
  | 'registeredReturnUrl'
  | 'gdprDisclaimerText'
  | 'fullNameLabelText'
  | 'widgetTitle'
  | 'signUpText'
  | 'dropdownAnswerQuestionText'
  | 'widgetSubtitle'
  | 'freeAnswerQuestionText'
  | 'dropdownAnswerPlaceholder'
  | 'enableNameField'
  | 'errorReturnUrl'
  | 'id'
>

type AllWidgets_MultipleCtaBanner_Fragment = {
  __typename?: 'MultipleCTABanner'
} & Pick<
  MultipleCtaBanner,
  | 'ctaTwoURL'
  | 'imageMedium'
  | 'ctaTwo'
  | 'contentTheme'
  | 'altLogoPng'
  | 'bannerURL'
  | 'ctaOne'
  | 'imageLarge'
  | 'logopngImageBG'
  | 'subtitle'
  | 'imageSmall'
  | 'contentBoxPosition'
  | 'headline'
  | 'ctaOneURL'
  | 'altImageLarge'
  | 'id'
>

type AllWidgets_ProductListWidget_Fragment = {
  __typename?: 'ProductListWidget'
} & Pick<ProductListWidget, 'title' | 'id'> & {
    descriptionHtml?: Maybe<
      { __typename: 'RichContent' } & RichContentHtmlFragment
    >
  }

type AllWidgets_ProductRecommenderItem_Fragment = {
  __typename?: 'ProductRecommenderItem'
} & Pick<ProductRecommenderItem, 'id'>

type AllWidgets_ProfileWidget_Fragment = {
  __typename?: 'ProfileWidget'
} & Pick<ProfileWidget, 'id'>

type AllWidgets_ResponsiveBuildYourOwnBundle_Fragment = {
  __typename?: 'ResponsiveBuildYourOwnBundle'
} & Pick<
  ResponsiveBuildYourOwnBundle,
  | 'showInfoButton'
  | 'showCategoriesList'
  | 'expandAllCategories'
  | 'maxProducts'
  | 'showPrices'
  | 'summaryAmount'
  | 'bundleSubTitle'
  | 'showProductReviews'
  | 'summaryDisplayType'
  | 'showBundlePageTitle'
  | 'unlimitedProducts'
  | 'showProductRRP'
  | 'id'
> & {
    banners?: Maybe<
      Array<
        Maybe<
          | { __typename?: 'AccordionWidget' }
          | { __typename?: 'AccordionWidgetContainer' }
          | { __typename?: 'BMICalculator' }
          | { __typename?: 'BMICalculatorV2' }
          | { __typename?: 'BrandsPageWidget' }
          | { __typename?: 'BuildYourOwnBundleProductList' }
          | { __typename?: 'CriteoSponsoredBannerAds' }
          | { __typename?: 'CriteoSponsoredBannerAdsFlagship' }
          | { __typename?: 'DeliveryInfoWidget' }
          | { __typename?: 'DeliveryInformationWidget' }
          | { __typename?: 'DynamicReferralWidget' }
          | { __typename?: 'Easiware' }
          | { __typename?: 'EditorialWidget' }
          | { __typename?: 'EmailReEngagementModal' }
          | { __typename?: 'FastTrackBanner' }
          | { __typename?: 'FoundationFinderLandingPageWidget' }
          | { __typename?: 'GlobalAboutUsVideo' }
          | { __typename?: 'GlobalAccreditationIcon' }
          | { __typename?: 'GlobalAccreditationIconCollection' }
          | { __typename?: 'GlobalBrandLogos' }
          | { __typename?: 'GlobalBuyingRightNow' }
          | { __typename?: 'GlobalBuyingRightNowManualProductURL' }
          | { __typename?: 'GlobalCardScrollerCard' }
          | { __typename?: 'GlobalCardScrollerSet' }
          | { __typename?: 'GlobalDispatchAndDateCountdownWidget' }
          | { __typename?: 'GlobalEditorialWithFeature' }
          | { __typename?: 'GlobalFooterAccreditationIcons' }
          | { __typename?: 'GlobalFooterContactUs' }
          | { __typename?: 'GlobalFourBestSellers' }
          | { __typename?: 'GlobalFourButtonLink' }
          | { __typename?: 'GlobalFourItemEditorial' }
          | { __typename?: 'GlobalGeneralImageBanner' }
          | { __typename?: 'GlobalHelpCentreAccordion' }
          | { __typename?: 'GlobalHelpCentreAccordion2' }
          | { __typename?: 'GlobalHelpCentreAccordionCollection' }
          | { __typename?: 'GlobalHelpCentreCollection' }
          | { __typename?: 'GlobalHeroCTABanner' }
          | { __typename?: 'GlobalImageCard' }
          | { __typename?: 'GlobalImageCardSet' }
          | { __typename?: 'GlobalMultiButton' }
          | { __typename?: 'GlobalPrimaryBanner' }
          | { __typename?: 'GlobalPrimaryBannerWithList' }
          | { __typename?: 'GlobalPrimaryBannerWithTextOverlay' }
          | { __typename?: 'GlobalProductCardScroller' }
          | { __typename?: 'GlobalReferral3StepGuide' }
          | { __typename?: 'GlobalScalableLogos' }
          | { __typename?: 'GlobalSectionPeek' }
          | { __typename?: 'GlobalSetAndromeda' }
          | { __typename?: 'GlobalSimpleTextCTAWidget' }
          | { __typename?: 'GlobalSixItemCategories' }
          | { __typename?: 'GlobalSocialIcon' }
          | { __typename?: 'GlobalSocialIconCollection' }
          | { __typename?: 'GlobalSocialIconCollectionv2' }
          | { __typename?: 'GlobalSocialIconv2' }
          | { __typename?: 'GlobalStripBanner' }
          | { __typename?: 'GlobalSubscriptionOptions' }
          | { __typename?: 'GlobalTabbedWidgetSet' }
          | { __typename?: 'GlobalThreeItemEditorial' }
          | { __typename?: 'GlobalThreeItemEditorialSubtitleBG' }
          | { __typename?: 'GlobalTransformationSlider' }
          | { __typename?: 'GlobalTrendingHashtagBlock' }
          | { __typename?: 'GlobalTrendingHashtagCollection' }
          | { __typename?: 'GlobalTwoBestSellers' }
          | { __typename?: 'GlobalTwoItemEditorial' }
          | { __typename?: 'GlobalTwoItemImageTextBlock' }
          | { __typename?: 'GlobalTwoItemImageTextCTA' }
          | { __typename?: 'GlobalTwoItemImageTextCTA3070' }
          | { __typename?: 'GlobalUGCCarousel' }
          | { __typename?: 'GlobalVideoGallery' }
          | { __typename?: 'GlobalVideoHeroBannerWidget' }
          | { __typename?: 'GlobalVideoTextImageCTA' }
          | { __typename?: 'GlobalWaitListSignUpFormResponses' }
          | { __typename?: 'GlobalWaitListSignUpWidget' }
          | { __typename?: 'GlobalWidgetSirius' }
          | { __typename?: 'GlobalWidgetVega' }
          | { __typename?: 'GlobalYoutubeGalleryItem' }
          | { __typename?: 'GlobalYoutubeGallerySet' }
          | { __typename?: 'LiveChatWidget' }
          | { __typename?: 'LoyaltyHubBirthdayGift' }
          | { __typename?: 'LoyaltyHubTier' }
          | { __typename?: 'LoyaltyRewardTier' }
          | { __typename?: 'LoyaltyRewardTiers' }
          | { __typename?: 'MailingList' }
          | { __typename?: 'MultipleCTABanner' }
          | { __typename?: 'ProductListWidget' }
          | { __typename?: 'ProductRecommenderItem' }
          | { __typename?: 'ProfileWidget' }
          | { __typename?: 'ResponsiveBuildYourOwnBundle' }
          | ({ __typename?: 'ResponsiveBuildYourOwnBundleProducts' } & Pick<
              ResponsiveBuildYourOwnBundleProducts,
              | 'id'
              | 'enableQuantitySelector'
              | 'maxProducts'
              | 'productsToShow'
              | 'categoryTitle'
            > & {
                skuList?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'ProductVariant' } & Pick<
                        ProductVariant,
                        | 'sku'
                        | 'title'
                        | 'inStock'
                        | 'maxPerOrder'
                        | 'availabilityMessage'
                        | 'eligibleForWishlist'
                      > & {
                          images: Array<
                            { __typename?: 'ProductImage' } & Pick<
                              ProductImage,
                              'largeProduct'
                            >
                          >
                          price?: Maybe<
                            { __typename?: 'ProductPrice' } & {
                              price: { __typename?: 'MoneyValue' } & Pick<
                                MoneyValue,
                                'displayValue' | 'amount'
                              >
                              rrp: { __typename?: 'MoneyValue' } & Pick<
                                MoneyValue,
                                'displayValue' | 'amount'
                              >
                            }
                          >
                          product?: Maybe<
                            { __typename?: 'Product' } & {
                              content: Array<
                                { __typename?: 'ProductContentItem' } & Pick<
                                  ProductContentItem,
                                  'key'
                                > & {
                                    value:
                                      | {
                                          __typename: 'ProductContentAttachmentValue'
                                        }
                                      | {
                                          __typename: 'ProductContentDateValue'
                                        }
                                      | {
                                          __typename: 'ProductContentIntListValue'
                                        }
                                      | { __typename: 'ProductContentIntValue' }
                                      | ({
                                          __typename: 'ProductContentRichContentListValue'
                                        } & {
                                          richContentListValue: Array<
                                            { __typename: 'RichContent' } & {
                                              content: Array<
                                                {
                                                  __typename: 'RichContentItem'
                                                } & Pick<
                                                  RichContentItem,
                                                  'content'
                                                >
                                              >
                                            }
                                          >
                                        })
                                      | ({
                                          __typename: 'ProductContentRichContentValue'
                                        } & {
                                          richContentValue: {
                                            __typename: 'RichContent'
                                          } & {
                                            content: Array<
                                              {
                                                __typename: 'RichContentItem'
                                              } & Pick<
                                                RichContentItem,
                                                'content'
                                              >
                                            >
                                          }
                                        })
                                      | ({
                                          __typename: 'ProductContentStringListValue'
                                        } & {
                                          stringListValue: ProductContentStringListValue['value']
                                        })
                                      | ({
                                          __typename: 'ProductContentStringValue'
                                        } & {
                                          stringValue: ProductContentStringValue['value']
                                        })
                                  }
                              >
                              images: Array<
                                { __typename?: 'ProductImage' } & Pick<
                                  ProductImage,
                                  'largeProduct'
                                >
                              >
                              reviews?: Maybe<
                                { __typename?: 'ProductReviews' } & Pick<
                                  ProductReviews,
                                  'total' | 'averageScore' | 'maxScore'
                                >
                              >
                            }
                          >
                        }
                    >
                  >
                >
              })
          | { __typename?: 'ResponsiveComparisonTable' }
          | { __typename?: 'ResponsiveComparisonTableContainer' }
          | { __typename?: 'ResponsiveProductBlockCarousel' }
          | { __typename?: 'ResponsiveProductTabs' }
          | { __typename?: 'ResponsiveSetSalon' }
          | { __typename?: 'ResponsiveSetSalonWithSlides' }
          | { __typename?: 'ResponsiveSliderSet' }
          | { __typename?: 'ResponsiveSuccessStoriesWidgetContainer' }
          | { __typename?: 'ResponsiveSuccessStoryWidget' }
          | { __typename?: 'ResponsiveTwoItemComparisonContainer' }
          | { __typename?: 'ResponsiveTwoItemComparisonRow' }
          | { __typename?: 'ResponsiveUSPBar' }
          | { __typename?: 'ResponsiveUSPBarCustomisable' }
          | { __typename?: 'SeoProductCategory' }
          | { __typename?: 'SeoProductCategorySet' }
          | { __typename?: 'ShopTheLookHotSpot' }
          | { __typename?: 'SimpleTextReadMore' }
          | { __typename?: 'TopBrandsWidget' }
          | { __typename?: 'TopProductCategory' }
          | { __typename?: 'TopProductCategorySet' }
          | { __typename?: 'TrustPilotCarouselWidget' }
          | { __typename?: 'TrustPilotWidget' }
          | { __typename?: 'VoucherCodesWidget' }
          | { __typename?: 'asymmetricGrid' }
          | { __typename?: 'buildyourownbundle' }
          | { __typename?: 'buttonLink' }
          | { __typename?: 'categoryItemCard' }
          | { __typename?: 'categoryItemCardWithTextOverlay' }
          | { __typename?: 'coverageCalculator' }
          | { __typename?: 'eightLinkButtonWidget' }
          | { __typename?: 'fouritemwindowproductslider' }
          | { __typename?: 'generalIframe' }
          | { __typename?: 'generalQuoteBanner' }
          | { __typename?: 'imageSelectCard' }
          | { __typename?: 'imageSelectSlider' }
          | { __typename?: 'improvedSearchBestSellers' }
          | { __typename?: 'kitBuilder' }
          | { __typename?: 'loyaltyRewardsList' }
          | { __typename?: 'loyaltyWelcomeMessage' }
          | { __typename?: 'multiCategoryCardSet' }
          | { __typename?: 'multiCategoryCardSetV2' }
          | { __typename?: 'obsessVR' }
          | { __typename?: 'parcelLabWidget' }
          | { __typename?: 'promoproductslider' }
          | { __typename?: 'propositionBar' }
          | { __typename?: 'propositionBarIncorrect' }
          | { __typename?: 'provenanceBrandHeader' }
          | { __typename?: 'qubitHomePageRecs' }
          | { __typename?: 'revieveWidget' }
          | { __typename?: 'shadeFinder' }
          | { __typename?: 'shopTheLook' }
          | { __typename?: 'simpleCTA' }
          | { __typename?: 'simpleDivider' }
          | { __typename?: 'simpleTextWidgetSet' }
          | { __typename?: 'simpleTitleWidget' }
          | { __typename?: 'simpletextwidget' }
          | { __typename?: 'simpletextwidgetwithh1' }
          | { __typename?: 'sponsoredProducts' }
          | { __typename?: 'sponsoredProductsCriteoHomepage' }
          | { __typename?: 'sponsoredProductsNew' }
          | { __typename?: 'storyStreamWidget' }
          | { __typename?: 'subscribeAndSaveInformationModal' }
          | { __typename?: 'swatchSelectSlider' }
          | { __typename?: 'swatchSelectSliderSet' }
          | { __typename?: 'trustReinforcementContainer' }
          | { __typename?: 'trustReinforcementSection' }
          | { __typename?: 'videoEditorial' }
          | { __typename?: 'yotiAgeVerification' }
          | { __typename?: 'yotiCancelFrame' }
        >
      >
    >
  }

type AllWidgets_ResponsiveBuildYourOwnBundleProducts_Fragment = {
  __typename?: 'ResponsiveBuildYourOwnBundleProducts'
} & Pick<
  ResponsiveBuildYourOwnBundleProducts,
  | 'enableQuantitySelector'
  | 'maxProducts'
  | 'productsToShow'
  | 'categoryTitle'
  | 'id'
>

type AllWidgets_ResponsiveComparisonTable_Fragment = {
  __typename?: 'ResponsiveComparisonTable'
} & Pick<
  ResponsiveComparisonTable,
  | 'featureTag'
  | 'comparisonList'
  | 'buttonText'
  | 'subTitle'
  | 'imageUrl'
  | 'title'
  | 'moreInfo'
  | 'buttonUrl'
  | 'disableFeatureTag'
  | 'id'
>

type AllWidgets_ResponsiveComparisonTableContainer_Fragment = {
  __typename?: 'ResponsiveComparisonTableContainer'
} & Pick<ResponsiveComparisonTableContainer, 'id'>

type AllWidgets_ResponsiveProductBlockCarousel_Fragment = {
  __typename?: 'ResponsiveProductBlockCarousel'
} & Pick<
  ResponsiveProductBlockCarousel,
  'enableAsiaStyles' | 'title' | 'id'
> & {
    productList?: Maybe<
      { __typename?: 'ProductList' } & {
        products: Array<
          {
            __typename?: 'Product'
          } & ResponsiveProductBlockCarouselProductFieldsFragment
        >
      }
    >
    skuList?: Maybe<
      Array<
        Maybe<
          { __typename: 'ProductVariant' } & Pick<ProductVariant, 'inStock'> & {
              product?: Maybe<
                {
                  __typename?: 'Product'
                } & ResponsiveProductBlockCarouselProductFieldsFragment
              >
              price?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'amount'
                  >
                  rrp: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'amount'
                  >
                }
              >
              images: Array<
                { __typename: 'ProductImage' } & Pick<
                  ProductImage,
                  'thumbnail' | 'largeProduct' | 'zoom'
                >
              >
            }
        >
      >
    >
  }

type AllWidgets_ResponsiveProductTabs_Fragment = {
  __typename?: 'ResponsiveProductTabs'
} & Pick<
  ResponsiveProductTabs,
  | 'tabTitle_1'
  | 'responsiveProductTabsTitle'
  | 'tabTitle_2'
  | 'tabTitle_3'
  | 'id'
>

type AllWidgets_ResponsiveSetSalon_Fragment = {
  __typename?: 'ResponsiveSetSalon'
} & Pick<
  ResponsiveSetSalon,
  | 'salonDescription'
  | 'salonTel'
  | 'beauty'
  | 'mondayHours'
  | 'sprayTan'
  | 'thursdayHours'
  | 'fridayHours'
  | 'salonEmail'
  | 'tuesdayHours'
  | 'hair'
  | 'extensions'
  | 'sundayHours'
  | 'salonBookUrl'
  | 'wednesdayHours'
  | 'saturdayHours'
  | 'salonTitle'
  | 'id'
> & { responsiveSetSalonSocialUrl: ResponsiveSetSalon['socialUrl'] }

type AllWidgets_ResponsiveSetSalonWithSlides_Fragment = {
  __typename?: 'ResponsiveSetSalonWithSlides'
} & Pick<
  ResponsiveSetSalonWithSlides,
  | 'salonDescription'
  | 'salonTel'
  | 'beauty'
  | 'sliderImage3'
  | 'sliderImage2'
  | 'sliderImage1'
  | 'mondayHours'
  | 'sprayTan'
  | 'thursdayHours'
  | 'fridayHours'
  | 'sliderImage2Alt'
  | 'salonEmail'
  | 'sliderImage3Alt'
  | 'sliderImage1Alt'
  | 'tuesdayHours'
  | 'hair'
  | 'extensions'
  | 'sundayHours'
  | 'salonBookUrl'
  | 'wednesdayHours'
  | 'saturdayHours'
  | 'socialUrl'
  | 'salonTitle'
  | 'id'
>

type AllWidgets_ResponsiveSliderSet_Fragment = {
  __typename?: 'ResponsiveSliderSet'
} & Pick<
  ResponsiveSliderSet,
  | 'isHeightVariable'
  | 'slideDuration'
  | 'isBulletNavigationHidden'
  | 'isAutoSlideOn'
  | 'id'
> & {
    banners?: Maybe<
      Array<
        Maybe<
          | { __typename?: 'AccordionWidget' }
          | { __typename?: 'AccordionWidgetContainer' }
          | { __typename?: 'BMICalculator' }
          | { __typename?: 'BMICalculatorV2' }
          | { __typename?: 'BrandsPageWidget' }
          | { __typename?: 'BuildYourOwnBundleProductList' }
          | { __typename?: 'CriteoSponsoredBannerAds' }
          | { __typename?: 'CriteoSponsoredBannerAdsFlagship' }
          | { __typename?: 'DeliveryInfoWidget' }
          | { __typename?: 'DeliveryInformationWidget' }
          | { __typename?: 'DynamicReferralWidget' }
          | { __typename?: 'Easiware' }
          | { __typename?: 'EditorialWidget' }
          | { __typename?: 'EmailReEngagementModal' }
          | { __typename?: 'FastTrackBanner' }
          | { __typename?: 'FoundationFinderLandingPageWidget' }
          | { __typename?: 'GlobalAboutUsVideo' }
          | { __typename?: 'GlobalAccreditationIcon' }
          | { __typename?: 'GlobalAccreditationIconCollection' }
          | { __typename?: 'GlobalBrandLogos' }
          | { __typename?: 'GlobalBuyingRightNow' }
          | { __typename?: 'GlobalBuyingRightNowManualProductURL' }
          | { __typename?: 'GlobalCardScrollerCard' }
          | { __typename?: 'GlobalCardScrollerSet' }
          | { __typename?: 'GlobalDispatchAndDateCountdownWidget' }
          | { __typename?: 'GlobalEditorialWithFeature' }
          | { __typename?: 'GlobalFooterAccreditationIcons' }
          | { __typename?: 'GlobalFooterContactUs' }
          | { __typename?: 'GlobalFourBestSellers' }
          | { __typename?: 'GlobalFourButtonLink' }
          | { __typename?: 'GlobalFourItemEditorial' }
          | { __typename?: 'GlobalGeneralImageBanner' }
          | { __typename?: 'GlobalHelpCentreAccordion' }
          | { __typename?: 'GlobalHelpCentreAccordion2' }
          | { __typename?: 'GlobalHelpCentreAccordionCollection' }
          | { __typename?: 'GlobalHelpCentreCollection' }
          | { __typename?: 'GlobalHeroCTABanner' }
          | { __typename?: 'GlobalImageCard' }
          | { __typename?: 'GlobalImageCardSet' }
          | { __typename?: 'GlobalMultiButton' }
          | ({ __typename: 'GlobalPrimaryBanner' } & Pick<
              GlobalPrimaryBanner,
              | 'ctaTwoURL'
              | 'imageMedium'
              | 'ctaTwo'
              | 'contentTheme'
              | 'contentAlign'
              | 'bannerURL'
              | 'altLogoPng'
              | 'ctaTwoAriaLabel'
              | 'ctaOne'
              | 'logopngWhiteBG'
              | 'imageLarge'
              | 'ctaOneAriaLabel'
              | 'logopngImageBG'
              | 'useH1'
              | 'subtitle'
              | 'imageSmall'
              | 'contentBoxPosition'
              | 'headline'
              | 'ctaOneURL'
              | 'altImageLarge'
            >)
          | { __typename?: 'GlobalPrimaryBannerWithList' }
          | ({ __typename?: 'GlobalPrimaryBannerWithTextOverlay' } & Pick<
              GlobalPrimaryBannerWithTextOverlay,
              | 'ctaTwoURL'
              | 'imageMedium'
              | 'ctaTwo'
              | 'contentTheme'
              | 'contentAlign'
              | 'altLogoPng'
              | 'bannerURL'
              | 'ctaTwoAriaLabel'
              | 'ctaOne'
              | 'logopngWhiteBG'
              | 'imageLarge'
              | 'ctaOneAriaLabel'
              | 'openInTabTwo'
              | 'hasMargin'
              | 'openInTabOne'
              | 'logopngImageBG'
              | 'useH1'
              | 'subtitle'
              | 'imageSmall'
              | 'contentBoxPosition'
              | 'headline'
              | 'ctaOneURL'
              | 'altImageLarge'
            >)
          | { __typename?: 'GlobalProductCardScroller' }
          | { __typename?: 'GlobalReferral3StepGuide' }
          | { __typename?: 'GlobalScalableLogos' }
          | { __typename?: 'GlobalSectionPeek' }
          | { __typename?: 'GlobalSetAndromeda' }
          | { __typename?: 'GlobalSimpleTextCTAWidget' }
          | { __typename?: 'GlobalSixItemCategories' }
          | { __typename?: 'GlobalSocialIcon' }
          | { __typename?: 'GlobalSocialIconCollection' }
          | { __typename?: 'GlobalSocialIconCollectionv2' }
          | { __typename?: 'GlobalSocialIconv2' }
          | { __typename?: 'GlobalStripBanner' }
          | { __typename?: 'GlobalSubscriptionOptions' }
          | { __typename?: 'GlobalTabbedWidgetSet' }
          | { __typename?: 'GlobalThreeItemEditorial' }
          | { __typename?: 'GlobalThreeItemEditorialSubtitleBG' }
          | { __typename?: 'GlobalTransformationSlider' }
          | { __typename?: 'GlobalTrendingHashtagBlock' }
          | { __typename?: 'GlobalTrendingHashtagCollection' }
          | { __typename?: 'GlobalTwoBestSellers' }
          | { __typename?: 'GlobalTwoItemEditorial' }
          | { __typename?: 'GlobalTwoItemImageTextBlock' }
          | { __typename?: 'GlobalTwoItemImageTextCTA' }
          | { __typename?: 'GlobalTwoItemImageTextCTA3070' }
          | { __typename?: 'GlobalUGCCarousel' }
          | { __typename?: 'GlobalVideoGallery' }
          | { __typename?: 'GlobalVideoHeroBannerWidget' }
          | { __typename?: 'GlobalVideoTextImageCTA' }
          | { __typename?: 'GlobalWaitListSignUpFormResponses' }
          | { __typename?: 'GlobalWaitListSignUpWidget' }
          | { __typename?: 'GlobalWidgetSirius' }
          | { __typename?: 'GlobalWidgetVega' }
          | { __typename?: 'GlobalYoutubeGalleryItem' }
          | { __typename?: 'GlobalYoutubeGallerySet' }
          | { __typename?: 'LiveChatWidget' }
          | { __typename?: 'LoyaltyHubBirthdayGift' }
          | { __typename?: 'LoyaltyHubTier' }
          | { __typename?: 'LoyaltyRewardTier' }
          | { __typename?: 'LoyaltyRewardTiers' }
          | { __typename?: 'MailingList' }
          | { __typename?: 'MultipleCTABanner' }
          | { __typename?: 'ProductListWidget' }
          | { __typename?: 'ProductRecommenderItem' }
          | { __typename?: 'ProfileWidget' }
          | { __typename?: 'ResponsiveBuildYourOwnBundle' }
          | { __typename?: 'ResponsiveBuildYourOwnBundleProducts' }
          | { __typename?: 'ResponsiveComparisonTable' }
          | { __typename?: 'ResponsiveComparisonTableContainer' }
          | { __typename?: 'ResponsiveProductBlockCarousel' }
          | { __typename?: 'ResponsiveProductTabs' }
          | { __typename?: 'ResponsiveSetSalon' }
          | { __typename?: 'ResponsiveSetSalonWithSlides' }
          | { __typename?: 'ResponsiveSliderSet' }
          | { __typename?: 'ResponsiveSuccessStoriesWidgetContainer' }
          | { __typename?: 'ResponsiveSuccessStoryWidget' }
          | { __typename?: 'ResponsiveTwoItemComparisonContainer' }
          | { __typename?: 'ResponsiveTwoItemComparisonRow' }
          | { __typename?: 'ResponsiveUSPBar' }
          | { __typename?: 'ResponsiveUSPBarCustomisable' }
          | { __typename?: 'SeoProductCategory' }
          | { __typename?: 'SeoProductCategorySet' }
          | { __typename?: 'ShopTheLookHotSpot' }
          | { __typename?: 'SimpleTextReadMore' }
          | { __typename?: 'TopBrandsWidget' }
          | { __typename?: 'TopProductCategory' }
          | { __typename?: 'TopProductCategorySet' }
          | { __typename?: 'TrustPilotCarouselWidget' }
          | { __typename?: 'TrustPilotWidget' }
          | { __typename?: 'VoucherCodesWidget' }
          | { __typename?: 'asymmetricGrid' }
          | { __typename?: 'buildyourownbundle' }
          | { __typename?: 'buttonLink' }
          | { __typename?: 'categoryItemCard' }
          | { __typename?: 'categoryItemCardWithTextOverlay' }
          | { __typename?: 'coverageCalculator' }
          | { __typename?: 'eightLinkButtonWidget' }
          | { __typename?: 'fouritemwindowproductslider' }
          | { __typename?: 'generalIframe' }
          | { __typename?: 'generalQuoteBanner' }
          | { __typename?: 'imageSelectCard' }
          | { __typename?: 'imageSelectSlider' }
          | { __typename?: 'improvedSearchBestSellers' }
          | { __typename?: 'kitBuilder' }
          | { __typename?: 'loyaltyRewardsList' }
          | { __typename?: 'loyaltyWelcomeMessage' }
          | { __typename?: 'multiCategoryCardSet' }
          | { __typename?: 'multiCategoryCardSetV2' }
          | { __typename?: 'obsessVR' }
          | { __typename?: 'parcelLabWidget' }
          | { __typename?: 'promoproductslider' }
          | { __typename?: 'propositionBar' }
          | { __typename?: 'propositionBarIncorrect' }
          | { __typename?: 'provenanceBrandHeader' }
          | { __typename?: 'qubitHomePageRecs' }
          | { __typename?: 'revieveWidget' }
          | { __typename?: 'shadeFinder' }
          | { __typename?: 'shopTheLook' }
          | { __typename?: 'simpleCTA' }
          | { __typename?: 'simpleDivider' }
          | { __typename?: 'simpleTextWidgetSet' }
          | { __typename?: 'simpleTitleWidget' }
          | { __typename?: 'simpletextwidget' }
          | { __typename?: 'simpletextwidgetwithh1' }
          | { __typename?: 'sponsoredProducts' }
          | { __typename?: 'sponsoredProductsCriteoHomepage' }
          | { __typename?: 'sponsoredProductsNew' }
          | { __typename?: 'storyStreamWidget' }
          | { __typename?: 'subscribeAndSaveInformationModal' }
          | { __typename?: 'swatchSelectSlider' }
          | { __typename?: 'swatchSelectSliderSet' }
          | { __typename?: 'trustReinforcementContainer' }
          | { __typename?: 'trustReinforcementSection' }
          | { __typename?: 'videoEditorial' }
          | { __typename?: 'yotiAgeVerification' }
          | { __typename?: 'yotiCancelFrame' }
        >
      >
    >
  }

type AllWidgets_ResponsiveSuccessStoriesWidgetContainer_Fragment = {
  __typename?: 'ResponsiveSuccessStoriesWidgetContainer'
} & Pick<ResponsiveSuccessStoriesWidgetContainer, 'id'>

type AllWidgets_ResponsiveSuccessStoryWidget_Fragment = {
  __typename?: 'ResponsiveSuccessStoryWidget'
} & Pick<
  ResponsiveSuccessStoryWidget,
  | 'profileName'
  | 'imageAltText'
  | 'profileCta'
  | 'weightLost'
  | 'roundelTitle'
  | 'imageUrl'
  | 'linkUrl'
  | 'caption'
  | 'imageAfterUrl'
  | 'weightLossStyle'
  | 'imageAfterAltText'
  | 'id'
>

type AllWidgets_ResponsiveTwoItemComparisonContainer_Fragment = {
  __typename?: 'ResponsiveTwoItemComparisonContainer'
} & Pick<ResponsiveTwoItemComparisonContainer, 'title1' | 'title2' | 'id'>

type AllWidgets_ResponsiveTwoItemComparisonRow_Fragment = {
  __typename?: 'ResponsiveTwoItemComparisonRow'
} & Pick<
  ResponsiveTwoItemComparisonRow,
  'valueToCompare' | 'secondItem' | 'firstItem' | 'valueToCopmare' | 'id'
>

type AllWidgets_ResponsiveUspBar_Fragment = {
  __typename?: 'ResponsiveUSPBar'
} & Pick<
  ResponsiveUspBar,
  | 'clickAndCollectLink'
  | 'rewardPointsLink'
  | 'countDownLink'
  | 'internationalDeliveryLink'
  | 'qualityLink'
  | 'callIcon'
  | 'callLink'
  | 'emailText'
  | 'checkmarkLink'
  | 'internationalDeliveryText'
  | 'clickAndCollectText'
  | 'timezoneOffset'
  | 'saturdayCutoff'
  | 'callText'
  | 'fastTrackLink'
  | 'checkmarkText'
  | 'sundayCutoff'
  | 'deliveryText'
  | 'fastTrackText'
  | 'emailLink'
  | 'mondayCutoff'
  | 'deliveryLink'
  | 'countDownText'
  | 'qualityText'
  | 'storeLink'
  | 'rewardPointsText'
  | 'wednesdayCutoff'
  | 'fridayCutoff'
  | 'storeText'
  | 'tuesdayCutoff'
  | 'thursdayCutoff'
  | 'id'
>

type AllWidgets_ResponsiveUspBarCustomisable_Fragment = {
  __typename?: 'ResponsiveUSPBarCustomisable'
} & Pick<ResponsiveUspBarCustomisable, 'id'>

type AllWidgets_SeoProductCategory_Fragment = {
  __typename?: 'SeoProductCategory'
} & Pick<
  SeoProductCategory,
  'productCategoryUrl' | 'productCategoryName' | 'buttonStyle' | 'id'
>

type AllWidgets_SeoProductCategorySet_Fragment = {
  __typename?: 'SeoProductCategorySet'
} & Pick<SeoProductCategorySet, 'showDiscoverTag' | 'showMore' | 'id'>

type AllWidgets_ShopTheLookHotSpot_Fragment = {
  __typename?: 'ShopTheLookHotSpot'
} & Pick<
  ShopTheLookHotSpot,
  'itemTop' | 'itemLeft' | 'rangeList' | 'productDescription' | 'id'
> & {
    SKU?: Maybe<
      { __typename?: 'ProductVariant' } & ProductVariantFieldsFragment
    >
  }

type AllWidgets_SimpleTextReadMore_Fragment = {
  __typename?: 'SimpleTextReadMore'
} & Pick<
  SimpleTextReadMore,
  | 'simpleTextReadMore_maxHeight'
  | 'simpleTextReadMore_textAlign'
  | 'simpleTextReadMore_title'
  | 'simpleTextReadMore_titleIsH1'
  | 'id'
> & {
    simpleTextReadMoreText?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
  }

type AllWidgets_TopBrandsWidget_Fragment = {
  __typename?: 'TopBrandsWidget'
} & Pick<
  TopBrandsWidget,
  | 'altTextBrandSixLogo'
  | 'BrandFiveName'
  | 'BrandSixName'
  | 'BrandFiveLogoPath'
  | 'BrandSevenLogoPath'
  | 'BrandTabTitle'
  | 'featuredImagePath'
  | 'featuredBrandText'
  | 'BrandOneLogoPath'
  | 'BrandEightLogoPath'
  | 'altTextBrandThreeLogo'
  | 'BrandFourName'
  | 'BrandThreeLogoPath'
  | 'altTextBrandFiveLogo'
  | 'BrandSixLogoPath'
  | 'altTextBrandOneLogo'
  | 'BrandOneName'
  | 'BrandTwoLogoPath'
  | 'BrandTwoName'
  | 'featuredBrandPageLinkName'
  | 'featuredBrandPageLink'
  | 'altTextFeaturedLogo'
  | 'BrandEightName'
  | 'BrandFourLogoPath'
  | 'featuredBrandName'
  | 'BrandThreeName'
  | 'altTextBrandTwoLogo'
  | 'BrandSevenName'
  | 'altTextFeaturedImage'
  | 'altTextBrandEightLogo'
  | 'featuredLogoPath'
  | 'altTextBrandSevenLogo'
  | 'altTextBrandFourLogo'
  | 'id'
>

type AllWidgets_TopProductCategory_Fragment = {
  __typename?: 'TopProductCategory'
} & Pick<
  TopProductCategory,
  'buttonStyle' | 'topProductCategoryName' | 'topProductCategoryUrl' | 'id'
>

type AllWidgets_TopProductCategorySet_Fragment = {
  __typename?: 'TopProductCategorySet'
} & Pick<
  TopProductCategorySet,
  'showDiscoverTag' | 'showMore' | 'DiscoverTitle' | 'id'
> & {
    banners?: Maybe<
      Array<
        Maybe<
          | { __typename?: 'AccordionWidget' }
          | { __typename?: 'AccordionWidgetContainer' }
          | { __typename?: 'BMICalculator' }
          | { __typename?: 'BMICalculatorV2' }
          | { __typename?: 'BrandsPageWidget' }
          | { __typename?: 'BuildYourOwnBundleProductList' }
          | { __typename?: 'CriteoSponsoredBannerAds' }
          | { __typename?: 'CriteoSponsoredBannerAdsFlagship' }
          | { __typename?: 'DeliveryInfoWidget' }
          | { __typename?: 'DeliveryInformationWidget' }
          | { __typename?: 'DynamicReferralWidget' }
          | { __typename?: 'Easiware' }
          | { __typename?: 'EditorialWidget' }
          | { __typename?: 'EmailReEngagementModal' }
          | { __typename?: 'FastTrackBanner' }
          | { __typename?: 'FoundationFinderLandingPageWidget' }
          | { __typename?: 'GlobalAboutUsVideo' }
          | { __typename?: 'GlobalAccreditationIcon' }
          | { __typename?: 'GlobalAccreditationIconCollection' }
          | { __typename?: 'GlobalBrandLogos' }
          | { __typename?: 'GlobalBuyingRightNow' }
          | { __typename?: 'GlobalBuyingRightNowManualProductURL' }
          | { __typename?: 'GlobalCardScrollerCard' }
          | { __typename?: 'GlobalCardScrollerSet' }
          | { __typename?: 'GlobalDispatchAndDateCountdownWidget' }
          | { __typename?: 'GlobalEditorialWithFeature' }
          | { __typename?: 'GlobalFooterAccreditationIcons' }
          | { __typename?: 'GlobalFooterContactUs' }
          | { __typename?: 'GlobalFourBestSellers' }
          | { __typename?: 'GlobalFourButtonLink' }
          | { __typename?: 'GlobalFourItemEditorial' }
          | { __typename?: 'GlobalGeneralImageBanner' }
          | { __typename?: 'GlobalHelpCentreAccordion' }
          | { __typename?: 'GlobalHelpCentreAccordion2' }
          | { __typename?: 'GlobalHelpCentreAccordionCollection' }
          | { __typename?: 'GlobalHelpCentreCollection' }
          | { __typename?: 'GlobalHeroCTABanner' }
          | { __typename?: 'GlobalImageCard' }
          | { __typename?: 'GlobalImageCardSet' }
          | { __typename?: 'GlobalMultiButton' }
          | { __typename?: 'GlobalPrimaryBanner' }
          | { __typename?: 'GlobalPrimaryBannerWithList' }
          | { __typename?: 'GlobalPrimaryBannerWithTextOverlay' }
          | { __typename?: 'GlobalProductCardScroller' }
          | { __typename?: 'GlobalReferral3StepGuide' }
          | { __typename?: 'GlobalScalableLogos' }
          | { __typename?: 'GlobalSectionPeek' }
          | { __typename?: 'GlobalSetAndromeda' }
          | { __typename?: 'GlobalSimpleTextCTAWidget' }
          | { __typename?: 'GlobalSixItemCategories' }
          | { __typename?: 'GlobalSocialIcon' }
          | { __typename?: 'GlobalSocialIconCollection' }
          | { __typename?: 'GlobalSocialIconCollectionv2' }
          | { __typename?: 'GlobalSocialIconv2' }
          | { __typename?: 'GlobalStripBanner' }
          | { __typename?: 'GlobalSubscriptionOptions' }
          | { __typename?: 'GlobalTabbedWidgetSet' }
          | { __typename?: 'GlobalThreeItemEditorial' }
          | { __typename?: 'GlobalThreeItemEditorialSubtitleBG' }
          | { __typename?: 'GlobalTransformationSlider' }
          | { __typename?: 'GlobalTrendingHashtagBlock' }
          | { __typename?: 'GlobalTrendingHashtagCollection' }
          | { __typename?: 'GlobalTwoBestSellers' }
          | { __typename?: 'GlobalTwoItemEditorial' }
          | { __typename?: 'GlobalTwoItemImageTextBlock' }
          | { __typename?: 'GlobalTwoItemImageTextCTA' }
          | { __typename?: 'GlobalTwoItemImageTextCTA3070' }
          | { __typename?: 'GlobalUGCCarousel' }
          | { __typename?: 'GlobalVideoGallery' }
          | { __typename?: 'GlobalVideoHeroBannerWidget' }
          | { __typename?: 'GlobalVideoTextImageCTA' }
          | { __typename?: 'GlobalWaitListSignUpFormResponses' }
          | { __typename?: 'GlobalWaitListSignUpWidget' }
          | { __typename?: 'GlobalWidgetSirius' }
          | { __typename?: 'GlobalWidgetVega' }
          | { __typename?: 'GlobalYoutubeGalleryItem' }
          | { __typename?: 'GlobalYoutubeGallerySet' }
          | { __typename?: 'LiveChatWidget' }
          | { __typename?: 'LoyaltyHubBirthdayGift' }
          | { __typename?: 'LoyaltyHubTier' }
          | { __typename?: 'LoyaltyRewardTier' }
          | { __typename?: 'LoyaltyRewardTiers' }
          | { __typename?: 'MailingList' }
          | { __typename?: 'MultipleCTABanner' }
          | { __typename?: 'ProductListWidget' }
          | { __typename?: 'ProductRecommenderItem' }
          | { __typename?: 'ProfileWidget' }
          | { __typename?: 'ResponsiveBuildYourOwnBundle' }
          | { __typename?: 'ResponsiveBuildYourOwnBundleProducts' }
          | { __typename?: 'ResponsiveComparisonTable' }
          | { __typename?: 'ResponsiveComparisonTableContainer' }
          | { __typename?: 'ResponsiveProductBlockCarousel' }
          | { __typename?: 'ResponsiveProductTabs' }
          | { __typename?: 'ResponsiveSetSalon' }
          | { __typename?: 'ResponsiveSetSalonWithSlides' }
          | { __typename?: 'ResponsiveSliderSet' }
          | { __typename?: 'ResponsiveSuccessStoriesWidgetContainer' }
          | { __typename?: 'ResponsiveSuccessStoryWidget' }
          | { __typename?: 'ResponsiveTwoItemComparisonContainer' }
          | { __typename?: 'ResponsiveTwoItemComparisonRow' }
          | { __typename?: 'ResponsiveUSPBar' }
          | { __typename?: 'ResponsiveUSPBarCustomisable' }
          | { __typename?: 'SeoProductCategory' }
          | { __typename?: 'SeoProductCategorySet' }
          | { __typename?: 'ShopTheLookHotSpot' }
          | { __typename?: 'SimpleTextReadMore' }
          | { __typename?: 'TopBrandsWidget' }
          | ({ __typename: 'TopProductCategory' } & Pick<
              TopProductCategory,
              | 'id'
              | 'buttonStyle'
              | 'topProductCategoryName'
              | 'topProductCategoryUrl'
            >)
          | { __typename?: 'TopProductCategorySet' }
          | { __typename?: 'TrustPilotCarouselWidget' }
          | { __typename?: 'TrustPilotWidget' }
          | { __typename?: 'VoucherCodesWidget' }
          | { __typename?: 'asymmetricGrid' }
          | { __typename?: 'buildyourownbundle' }
          | { __typename?: 'buttonLink' }
          | { __typename?: 'categoryItemCard' }
          | { __typename?: 'categoryItemCardWithTextOverlay' }
          | { __typename?: 'coverageCalculator' }
          | { __typename?: 'eightLinkButtonWidget' }
          | { __typename?: 'fouritemwindowproductslider' }
          | { __typename?: 'generalIframe' }
          | { __typename?: 'generalQuoteBanner' }
          | { __typename?: 'imageSelectCard' }
          | { __typename?: 'imageSelectSlider' }
          | { __typename?: 'improvedSearchBestSellers' }
          | { __typename?: 'kitBuilder' }
          | { __typename?: 'loyaltyRewardsList' }
          | { __typename?: 'loyaltyWelcomeMessage' }
          | { __typename?: 'multiCategoryCardSet' }
          | { __typename?: 'multiCategoryCardSetV2' }
          | { __typename?: 'obsessVR' }
          | { __typename?: 'parcelLabWidget' }
          | { __typename?: 'promoproductslider' }
          | { __typename?: 'propositionBar' }
          | { __typename?: 'propositionBarIncorrect' }
          | { __typename?: 'provenanceBrandHeader' }
          | { __typename?: 'qubitHomePageRecs' }
          | { __typename?: 'revieveWidget' }
          | { __typename?: 'shadeFinder' }
          | { __typename?: 'shopTheLook' }
          | { __typename?: 'simpleCTA' }
          | { __typename?: 'simpleDivider' }
          | { __typename?: 'simpleTextWidgetSet' }
          | { __typename?: 'simpleTitleWidget' }
          | { __typename?: 'simpletextwidget' }
          | { __typename?: 'simpletextwidgetwithh1' }
          | { __typename?: 'sponsoredProducts' }
          | { __typename?: 'sponsoredProductsCriteoHomepage' }
          | { __typename?: 'sponsoredProductsNew' }
          | { __typename?: 'storyStreamWidget' }
          | { __typename?: 'subscribeAndSaveInformationModal' }
          | { __typename?: 'swatchSelectSlider' }
          | { __typename?: 'swatchSelectSliderSet' }
          | { __typename?: 'trustReinforcementContainer' }
          | { __typename?: 'trustReinforcementSection' }
          | { __typename?: 'videoEditorial' }
          | { __typename?: 'yotiAgeVerification' }
          | { __typename?: 'yotiCancelFrame' }
        >
      >
    >
  }

type AllWidgets_TrustPilotCarouselWidget_Fragment = {
  __typename?: 'TrustPilotCarouselWidget'
} & Pick<TrustPilotCarouselWidget, 'id'>

type AllWidgets_TrustPilotWidget_Fragment = {
  __typename?: 'TrustPilotWidget'
} & Pick<
  TrustPilotWidget,
  | 'businessId'
  | 'width'
  | 'theme'
  | 'locale'
  | 'templateId'
  | 'url'
  | 'height'
  | 'id'
>

type AllWidgets_VoucherCodesWidget_Fragment = {
  __typename?: 'VoucherCodesWidget'
} & Pick<VoucherCodesWidget, 'id'>

type AllWidgets_AsymmetricGrid_Fragment = {
  __typename?: 'asymmetricGrid'
} & Pick<
  AsymmetricGrid,
  | 'widthImageTwoMobile'
  | 'imageOneSubtitle'
  | 'linkOne'
  | 'widthImageOneMobile'
  | 'altTextImgOne'
  | 'title'
  | 'altTextImgTwo'
  | 'imageTwoSubtitle'
  | 'imageOnePath'
  | 'imageTwoPath'
  | 'widthImageTwo'
  | 'widthImageOne'
  | 'linkTwo'
  | 'id'
>

type AllWidgets_Buildyourownbundle_Fragment = {
  __typename?: 'buildyourownbundle'
} & Pick<
  Buildyourownbundle,
  | 'bundlePageTitle'
  | 'packSummary'
  | 'showPrices'
  | 'summaryAmount'
  | 'bundleSubTitle'
  | 'id'
> & { buildyourownbundleMaxProducts: Buildyourownbundle['maxProducts'] }

type AllWidgets_ButtonLink_Fragment = { __typename?: 'buttonLink' } & Pick<
  ButtonLink,
  'id'
>

type AllWidgets_CategoryItemCard_Fragment = {
  __typename?: 'categoryItemCard'
} & Pick<
  CategoryItemCard,
  | 'itemTitle'
  | 'textAlignment'
  | 'itemImagePath'
  | 'itemDescription'
  | 'altTextitem'
  | 'itemUrl'
  | 'itemCTA'
  | 'id'
>

type AllWidgets_CategoryItemCardWithTextOverlay_Fragment = {
  __typename?: 'categoryItemCardWithTextOverlay'
} & Pick<CategoryItemCardWithTextOverlay, 'id'>

type AllWidgets_CoverageCalculator_Fragment = {
  __typename?: 'coverageCalculator'
} & Pick<
  CoverageCalculator,
  | 'calculatorSubtitle'
  | 'additionalInfoTitle'
  | 'additionalInfoDisclaimerText'
  | 'paintCoverage'
  | 'calculatorType'
  | 'instructionsTitle'
  | 'buyButtonLink'
  | 'calculatorTitle'
  | 'wallpaperRollWidth'
  | 'wallpaperRollLength'
  | 'id'
> & {
    excludeAreaHelpText?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
    additionalInfoText?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
    paintCoatsHelpText?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
    instructionsText?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
  }

type AllWidgets_EightLinkButtonWidget_Fragment = {
  __typename?: 'eightLinkButtonWidget'
} & Pick<
  EightLinkButtonWidget,
  | 'buttonUrlSix'
  | 'buttonTextOne'
  | 'buttonTextSix'
  | 'buttonTextEight'
  | 'buttonTextTwo'
  | 'buttonUrlTwo'
  | 'buttonUrlOne'
  | 'title'
  | 'buttonUrlThree'
  | 'buttonUrlEight'
  | 'buttonTextFour'
  | 'buttonUrlSeven'
  | 'titleAlign'
  | 'buttonTextSeven'
  | 'buttonUrlFive'
  | 'buttonTextThree'
  | 'buttonTextFive'
  | 'buttonUrlFour'
  | 'id'
>

type AllWidgets_Fouritemwindowproductslider_Fragment = {
  __typename?: 'fouritemwindowproductslider'
} & Pick<Fouritemwindowproductslider, 'id'>

type AllWidgets_GeneralIframe_Fragment = {
  __typename?: 'generalIframe'
} & Pick<GeneralIframe, 'id'>

type AllWidgets_GeneralQuoteBanner_Fragment = {
  __typename?: 'generalQuoteBanner'
} & Pick<GeneralQuoteBanner, 'id'>

type AllWidgets_ImageSelectCard_Fragment = {
  __typename?: 'imageSelectCard'
} & Pick<
  ImageSelectCard,
  | 'altTextThumbnail'
  | 'itemImageLargePath'
  | 'altTextImage'
  | 'itemImageSmallPath'
  | 'itemImagePath'
  | 'ImageInformationText'
  | 'thumbnailImagePath'
  | 'id'
>

type AllWidgets_ImageSelectSlider_Fragment = {
  __typename?: 'imageSelectSlider'
} & Pick<
  ImageSelectSlider,
  'altTextLogoImage' | 'logoImagePath' | 'titleText' | 'id'
>

type AllWidgets_ImprovedSearchBestSellers_Fragment = {
  __typename?: 'improvedSearchBestSellers'
} & Pick<
  ImprovedSearchBestSellers,
  | 'productId2'
  | 'productId3'
  | 'productId4'
  | 'productId1'
  | 'widgetTitle'
  | 'id'
>

type AllWidgets_KitBuilder_Fragment = { __typename?: 'kitBuilder' } & Pick<
  KitBuilder,
  'kitBuilderDistributorId' | 'kitBuilderKey' | 'id'
>

type AllWidgets_LoyaltyRewardsList_Fragment = {
  __typename?: 'loyaltyRewardsList'
} & Pick<
  LoyaltyRewardsList,
  | 'listItem4'
  | 'listItem3'
  | 'listItem2'
  | 'listItem1'
  | 'listItem8'
  | 'listItem7'
  | 'listItem6'
  | 'listItem5'
  | 'title'
  | 'id'
>

type AllWidgets_LoyaltyWelcomeMessage_Fragment = {
  __typename?: 'loyaltyWelcomeMessage'
} & Pick<
  LoyaltyWelcomeMessage,
  | 'imageAltText'
  | 'subHeading'
  | 'heading'
  | 'imagePath'
  | 'accountCreationTextPart1'
  | 'accountCreationTextPart2'
  | 'profileCompletionTextPart1'
  | 'profileCompletionTextPart2'
  | 'id'
>

type AllWidgets_MultiCategoryCardSet_Fragment = {
  __typename: 'multiCategoryCardSet'
} & Pick<
  MultiCategoryCardSet,
  'widgetSubtitle' | 'columnsNumber' | 'backgroundColour' | 'widgetTitle' | 'id'
> & {
    banners?: Maybe<
      Array<
        Maybe<
          | { __typename?: 'AccordionWidget' }
          | { __typename?: 'AccordionWidgetContainer' }
          | { __typename?: 'BMICalculator' }
          | { __typename?: 'BMICalculatorV2' }
          | { __typename?: 'BrandsPageWidget' }
          | { __typename?: 'BuildYourOwnBundleProductList' }
          | { __typename?: 'CriteoSponsoredBannerAds' }
          | { __typename?: 'CriteoSponsoredBannerAdsFlagship' }
          | { __typename?: 'DeliveryInfoWidget' }
          | { __typename?: 'DeliveryInformationWidget' }
          | { __typename?: 'DynamicReferralWidget' }
          | { __typename?: 'Easiware' }
          | { __typename?: 'EditorialWidget' }
          | { __typename?: 'EmailReEngagementModal' }
          | { __typename?: 'FastTrackBanner' }
          | { __typename?: 'FoundationFinderLandingPageWidget' }
          | { __typename?: 'GlobalAboutUsVideo' }
          | { __typename?: 'GlobalAccreditationIcon' }
          | { __typename?: 'GlobalAccreditationIconCollection' }
          | { __typename?: 'GlobalBrandLogos' }
          | { __typename?: 'GlobalBuyingRightNow' }
          | { __typename?: 'GlobalBuyingRightNowManualProductURL' }
          | { __typename?: 'GlobalCardScrollerCard' }
          | { __typename?: 'GlobalCardScrollerSet' }
          | { __typename?: 'GlobalDispatchAndDateCountdownWidget' }
          | { __typename?: 'GlobalEditorialWithFeature' }
          | { __typename?: 'GlobalFooterAccreditationIcons' }
          | { __typename?: 'GlobalFooterContactUs' }
          | { __typename?: 'GlobalFourBestSellers' }
          | { __typename?: 'GlobalFourButtonLink' }
          | { __typename?: 'GlobalFourItemEditorial' }
          | { __typename?: 'GlobalGeneralImageBanner' }
          | { __typename?: 'GlobalHelpCentreAccordion' }
          | { __typename?: 'GlobalHelpCentreAccordion2' }
          | { __typename?: 'GlobalHelpCentreAccordionCollection' }
          | { __typename?: 'GlobalHelpCentreCollection' }
          | { __typename?: 'GlobalHeroCTABanner' }
          | { __typename?: 'GlobalImageCard' }
          | { __typename?: 'GlobalImageCardSet' }
          | { __typename?: 'GlobalMultiButton' }
          | { __typename?: 'GlobalPrimaryBanner' }
          | { __typename?: 'GlobalPrimaryBannerWithList' }
          | { __typename?: 'GlobalPrimaryBannerWithTextOverlay' }
          | { __typename?: 'GlobalProductCardScroller' }
          | { __typename?: 'GlobalReferral3StepGuide' }
          | { __typename?: 'GlobalScalableLogos' }
          | { __typename?: 'GlobalSectionPeek' }
          | { __typename?: 'GlobalSetAndromeda' }
          | { __typename?: 'GlobalSimpleTextCTAWidget' }
          | { __typename?: 'GlobalSixItemCategories' }
          | { __typename?: 'GlobalSocialIcon' }
          | { __typename?: 'GlobalSocialIconCollection' }
          | { __typename?: 'GlobalSocialIconCollectionv2' }
          | { __typename?: 'GlobalSocialIconv2' }
          | { __typename?: 'GlobalStripBanner' }
          | { __typename?: 'GlobalSubscriptionOptions' }
          | { __typename?: 'GlobalTabbedWidgetSet' }
          | { __typename?: 'GlobalThreeItemEditorial' }
          | { __typename?: 'GlobalThreeItemEditorialSubtitleBG' }
          | { __typename?: 'GlobalTransformationSlider' }
          | { __typename?: 'GlobalTrendingHashtagBlock' }
          | { __typename?: 'GlobalTrendingHashtagCollection' }
          | { __typename?: 'GlobalTwoBestSellers' }
          | { __typename?: 'GlobalTwoItemEditorial' }
          | { __typename?: 'GlobalTwoItemImageTextBlock' }
          | { __typename?: 'GlobalTwoItemImageTextCTA' }
          | { __typename?: 'GlobalTwoItemImageTextCTA3070' }
          | { __typename?: 'GlobalUGCCarousel' }
          | { __typename?: 'GlobalVideoGallery' }
          | { __typename?: 'GlobalVideoHeroBannerWidget' }
          | { __typename?: 'GlobalVideoTextImageCTA' }
          | { __typename?: 'GlobalWaitListSignUpFormResponses' }
          | { __typename?: 'GlobalWaitListSignUpWidget' }
          | { __typename?: 'GlobalWidgetSirius' }
          | { __typename?: 'GlobalWidgetVega' }
          | { __typename?: 'GlobalYoutubeGalleryItem' }
          | { __typename?: 'GlobalYoutubeGallerySet' }
          | { __typename?: 'LiveChatWidget' }
          | { __typename?: 'LoyaltyHubBirthdayGift' }
          | { __typename?: 'LoyaltyHubTier' }
          | { __typename?: 'LoyaltyRewardTier' }
          | { __typename?: 'LoyaltyRewardTiers' }
          | { __typename?: 'MailingList' }
          | { __typename?: 'MultipleCTABanner' }
          | { __typename?: 'ProductListWidget' }
          | { __typename?: 'ProductRecommenderItem' }
          | { __typename?: 'ProfileWidget' }
          | { __typename?: 'ResponsiveBuildYourOwnBundle' }
          | { __typename?: 'ResponsiveBuildYourOwnBundleProducts' }
          | { __typename?: 'ResponsiveComparisonTable' }
          | { __typename?: 'ResponsiveComparisonTableContainer' }
          | { __typename?: 'ResponsiveProductBlockCarousel' }
          | { __typename?: 'ResponsiveProductTabs' }
          | { __typename?: 'ResponsiveSetSalon' }
          | { __typename?: 'ResponsiveSetSalonWithSlides' }
          | { __typename?: 'ResponsiveSliderSet' }
          | { __typename?: 'ResponsiveSuccessStoriesWidgetContainer' }
          | { __typename?: 'ResponsiveSuccessStoryWidget' }
          | { __typename?: 'ResponsiveTwoItemComparisonContainer' }
          | { __typename?: 'ResponsiveTwoItemComparisonRow' }
          | { __typename?: 'ResponsiveUSPBar' }
          | { __typename?: 'ResponsiveUSPBarCustomisable' }
          | { __typename?: 'SeoProductCategory' }
          | { __typename?: 'SeoProductCategorySet' }
          | { __typename?: 'ShopTheLookHotSpot' }
          | { __typename?: 'SimpleTextReadMore' }
          | { __typename?: 'TopBrandsWidget' }
          | { __typename?: 'TopProductCategory' }
          | { __typename?: 'TopProductCategorySet' }
          | { __typename?: 'TrustPilotCarouselWidget' }
          | { __typename?: 'TrustPilotWidget' }
          | { __typename?: 'VoucherCodesWidget' }
          | { __typename?: 'asymmetricGrid' }
          | { __typename?: 'buildyourownbundle' }
          | { __typename?: 'buttonLink' }
          | ({ __typename: 'categoryItemCard' } & Pick<
              CategoryItemCard,
              | 'itemTitle'
              | 'textAlignment'
              | 'itemImagePath'
              | 'itemDescription'
              | 'altTextitem'
              | 'itemUrl'
              | 'itemCTA'
            >)
          | { __typename?: 'categoryItemCardWithTextOverlay' }
          | { __typename?: 'coverageCalculator' }
          | { __typename?: 'eightLinkButtonWidget' }
          | { __typename?: 'fouritemwindowproductslider' }
          | { __typename?: 'generalIframe' }
          | { __typename?: 'generalQuoteBanner' }
          | { __typename?: 'imageSelectCard' }
          | { __typename?: 'imageSelectSlider' }
          | { __typename?: 'improvedSearchBestSellers' }
          | { __typename?: 'kitBuilder' }
          | { __typename?: 'loyaltyRewardsList' }
          | { __typename?: 'loyaltyWelcomeMessage' }
          | { __typename?: 'multiCategoryCardSet' }
          | { __typename?: 'multiCategoryCardSetV2' }
          | { __typename?: 'obsessVR' }
          | { __typename?: 'parcelLabWidget' }
          | { __typename?: 'promoproductslider' }
          | { __typename?: 'propositionBar' }
          | { __typename?: 'propositionBarIncorrect' }
          | { __typename?: 'provenanceBrandHeader' }
          | { __typename?: 'qubitHomePageRecs' }
          | { __typename?: 'revieveWidget' }
          | { __typename?: 'shadeFinder' }
          | { __typename?: 'shopTheLook' }
          | { __typename?: 'simpleCTA' }
          | { __typename?: 'simpleDivider' }
          | { __typename?: 'simpleTextWidgetSet' }
          | { __typename?: 'simpleTitleWidget' }
          | { __typename?: 'simpletextwidget' }
          | { __typename?: 'simpletextwidgetwithh1' }
          | { __typename?: 'sponsoredProducts' }
          | { __typename?: 'sponsoredProductsCriteoHomepage' }
          | { __typename?: 'sponsoredProductsNew' }
          | { __typename?: 'storyStreamWidget' }
          | { __typename?: 'subscribeAndSaveInformationModal' }
          | { __typename?: 'swatchSelectSlider' }
          | { __typename?: 'swatchSelectSliderSet' }
          | { __typename?: 'trustReinforcementContainer' }
          | { __typename?: 'trustReinforcementSection' }
          | { __typename?: 'videoEditorial' }
          | { __typename?: 'yotiAgeVerification' }
          | { __typename?: 'yotiCancelFrame' }
        >
      >
    >
  }

type AllWidgets_MultiCategoryCardSetV2_Fragment = {
  __typename?: 'multiCategoryCardSetV2'
} & Pick<MultiCategoryCardSetV2, 'id'>

type AllWidgets_ObsessVr_Fragment = { __typename?: 'obsessVR' } & Pick<
  ObsessVr,
  'id'
>

type AllWidgets_ParcelLabWidget_Fragment = {
  __typename?: 'parcelLabWidget'
} & Pick<
  ParcelLabWidget,
  'showZipCodeInput' | 'parcelLabUserId' | 'showSearchForm' | 'id'
>

type AllWidgets_Promoproductslider_Fragment = {
  __typename?: 'promoproductslider'
} & Pick<
  Promoproductslider,
  | 'featuredImage'
  | 'imageURL'
  | 'imageAltText'
  | 'widgetSubtitle'
  | 'productCount'
  | 'widgetTitle'
  | 'id'
>

type AllWidgets_PropositionBar_Fragment = {
  __typename?: 'propositionBar'
} & Pick<
  PropositionBar,
  | 'textOne'
  | 'textTwo'
  | 'textThree'
  | 'textFour'
  | 'textFive'
  | 'textSix'
  | 'id'
>

type AllWidgets_PropositionBarIncorrect_Fragment = {
  __typename?: 'propositionBarIncorrect'
} & Pick<PropositionBarIncorrect, 'id'>

type AllWidgets_ProvenanceBrandHeader_Fragment = {
  __typename?: 'provenanceBrandHeader'
} & Pick<
  ProvenanceBrandHeader,
  | 'brand'
  | 'brandId'
  | 'button2Text'
  | 'button2Url'
  | 'id'
  | 'itemAlign'
  | 'itemAlt'
  | 'itemButton'
  | 'itemImage'
  | 'itemImageSmall'
  | 'itemTitle'
  | 'itemURL'
  | 'textAlign'
  | 'videoTitle'
  | 'videoUrl'
> & {
    itemText?: Maybe<{ __typename: 'RichContent' } & RichContentHtmlFragment>
    transcript?: Maybe<{ __typename: 'RichContent' } & RichContentHtmlFragment>
  }

type AllWidgets_QubitHomePageRecs_Fragment = {
  __typename: 'qubitHomePageRecs'
} & Pick<
  QubitHomePageRecs,
  'numberOfProducts' | 'title' | 'url' | 'titleAlign' | 'placementId' | 'id'
>

type AllWidgets_RevieveWidget_Fragment = {
  __typename?: 'revieveWidget'
} & Pick<RevieveWidget, 'id'>

type AllWidgets_ShadeFinder_Fragment = { __typename?: 'shadeFinder' } & Pick<
  ShadeFinder,
  'id'
>

type AllWidgets_ShopTheLook_Fragment = { __typename?: 'shopTheLook' } & Pick<
  ShopTheLook,
  | 'imageMedium'
  | 'itemTitle'
  | 'imageSmall'
  | 'itemDescription'
  | 'imageLarge'
  | 'altImageLarge'
  | 'id'
> & {
    banners?: Maybe<
      Array<
        Maybe<
          | { __typename: 'AccordionWidget' }
          | { __typename: 'AccordionWidgetContainer' }
          | { __typename: 'BMICalculator' }
          | { __typename: 'BMICalculatorV2' }
          | { __typename: 'BrandsPageWidget' }
          | { __typename: 'BuildYourOwnBundleProductList' }
          | { __typename: 'CriteoSponsoredBannerAds' }
          | { __typename: 'CriteoSponsoredBannerAdsFlagship' }
          | { __typename: 'DeliveryInfoWidget' }
          | { __typename: 'DeliveryInformationWidget' }
          | { __typename: 'DynamicReferralWidget' }
          | { __typename: 'Easiware' }
          | { __typename: 'EditorialWidget' }
          | { __typename: 'EmailReEngagementModal' }
          | { __typename: 'FastTrackBanner' }
          | { __typename: 'FoundationFinderLandingPageWidget' }
          | { __typename: 'GlobalAboutUsVideo' }
          | { __typename: 'GlobalAccreditationIcon' }
          | { __typename: 'GlobalAccreditationIconCollection' }
          | { __typename: 'GlobalBrandLogos' }
          | { __typename: 'GlobalBuyingRightNow' }
          | { __typename: 'GlobalBuyingRightNowManualProductURL' }
          | { __typename: 'GlobalCardScrollerCard' }
          | { __typename: 'GlobalCardScrollerSet' }
          | { __typename: 'GlobalDispatchAndDateCountdownWidget' }
          | { __typename: 'GlobalEditorialWithFeature' }
          | { __typename: 'GlobalFooterAccreditationIcons' }
          | { __typename: 'GlobalFooterContactUs' }
          | { __typename: 'GlobalFourBestSellers' }
          | { __typename: 'GlobalFourButtonLink' }
          | { __typename: 'GlobalFourItemEditorial' }
          | { __typename: 'GlobalGeneralImageBanner' }
          | { __typename: 'GlobalHelpCentreAccordion' }
          | { __typename: 'GlobalHelpCentreAccordion2' }
          | { __typename: 'GlobalHelpCentreAccordionCollection' }
          | { __typename: 'GlobalHelpCentreCollection' }
          | { __typename: 'GlobalHeroCTABanner' }
          | { __typename: 'GlobalImageCard' }
          | { __typename: 'GlobalImageCardSet' }
          | { __typename: 'GlobalMultiButton' }
          | { __typename: 'GlobalPrimaryBanner' }
          | { __typename: 'GlobalPrimaryBannerWithList' }
          | { __typename: 'GlobalPrimaryBannerWithTextOverlay' }
          | { __typename: 'GlobalProductCardScroller' }
          | { __typename: 'GlobalReferral3StepGuide' }
          | { __typename: 'GlobalScalableLogos' }
          | { __typename: 'GlobalSectionPeek' }
          | { __typename: 'GlobalSetAndromeda' }
          | { __typename: 'GlobalSimpleTextCTAWidget' }
          | { __typename: 'GlobalSixItemCategories' }
          | { __typename: 'GlobalSocialIcon' }
          | { __typename: 'GlobalSocialIconCollection' }
          | { __typename: 'GlobalSocialIconCollectionv2' }
          | { __typename: 'GlobalSocialIconv2' }
          | { __typename: 'GlobalStripBanner' }
          | { __typename: 'GlobalSubscriptionOptions' }
          | { __typename: 'GlobalTabbedWidgetSet' }
          | { __typename: 'GlobalThreeItemEditorial' }
          | { __typename: 'GlobalThreeItemEditorialSubtitleBG' }
          | { __typename: 'GlobalTransformationSlider' }
          | { __typename: 'GlobalTrendingHashtagBlock' }
          | { __typename: 'GlobalTrendingHashtagCollection' }
          | { __typename: 'GlobalTwoBestSellers' }
          | { __typename: 'GlobalTwoItemEditorial' }
          | { __typename: 'GlobalTwoItemImageTextBlock' }
          | { __typename: 'GlobalTwoItemImageTextCTA' }
          | { __typename: 'GlobalTwoItemImageTextCTA3070' }
          | { __typename: 'GlobalUGCCarousel' }
          | { __typename: 'GlobalVideoGallery' }
          | { __typename: 'GlobalVideoHeroBannerWidget' }
          | { __typename: 'GlobalVideoTextImageCTA' }
          | { __typename: 'GlobalWaitListSignUpFormResponses' }
          | { __typename: 'GlobalWaitListSignUpWidget' }
          | { __typename: 'GlobalWidgetSirius' }
          | { __typename: 'GlobalWidgetVega' }
          | { __typename: 'GlobalYoutubeGalleryItem' }
          | { __typename: 'GlobalYoutubeGallerySet' }
          | { __typename: 'LiveChatWidget' }
          | { __typename: 'LoyaltyHubBirthdayGift' }
          | { __typename: 'LoyaltyHubTier' }
          | { __typename: 'LoyaltyRewardTier' }
          | { __typename: 'LoyaltyRewardTiers' }
          | { __typename: 'MailingList' }
          | { __typename: 'MultipleCTABanner' }
          | { __typename: 'ProductListWidget' }
          | { __typename: 'ProductRecommenderItem' }
          | { __typename: 'ProfileWidget' }
          | { __typename: 'ResponsiveBuildYourOwnBundle' }
          | { __typename: 'ResponsiveBuildYourOwnBundleProducts' }
          | { __typename: 'ResponsiveComparisonTable' }
          | { __typename: 'ResponsiveComparisonTableContainer' }
          | { __typename: 'ResponsiveProductBlockCarousel' }
          | { __typename: 'ResponsiveProductTabs' }
          | { __typename: 'ResponsiveSetSalon' }
          | { __typename: 'ResponsiveSetSalonWithSlides' }
          | { __typename: 'ResponsiveSliderSet' }
          | { __typename: 'ResponsiveSuccessStoriesWidgetContainer' }
          | { __typename: 'ResponsiveSuccessStoryWidget' }
          | { __typename: 'ResponsiveTwoItemComparisonContainer' }
          | { __typename: 'ResponsiveTwoItemComparisonRow' }
          | { __typename: 'ResponsiveUSPBar' }
          | { __typename: 'ResponsiveUSPBarCustomisable' }
          | { __typename: 'SeoProductCategory' }
          | { __typename: 'SeoProductCategorySet' }
          | ({ __typename: 'ShopTheLookHotSpot' } & Pick<
              ShopTheLookHotSpot,
              'itemTop' | 'itemLeft' | 'rangeList' | 'productDescription'
            > & {
                SKU?: Maybe<
                  { __typename?: 'ProductVariant' } & {
                    marketedSpecialOffer?: Maybe<
                      { __typename: 'ProductMarketedSpecialOffer' } & {
                        title?: Maybe<
                          { __typename?: 'RichContent' } & {
                            content: Array<
                              { __typename?: 'RichContentItem' } & Pick<
                                RichContentItem,
                                'content'
                              >
                            >
                          }
                        >
                        description?: Maybe<
                          { __typename?: 'RichContent' } & {
                            content: Array<
                              { __typename?: 'RichContentItem' } & Pick<
                                RichContentItem,
                                'content'
                              >
                            >
                          }
                        >
                        landingPageLink?: Maybe<
                          { __typename?: 'Hyperlink' } & Pick<
                            Hyperlink,
                            'text' | 'url' | 'openExternally' | 'noFollow'
                          >
                        >
                      }
                    >
                  } & ProductVariantFieldsFragment
                >
              })
          | { __typename: 'SimpleTextReadMore' }
          | { __typename: 'TopBrandsWidget' }
          | { __typename: 'TopProductCategory' }
          | { __typename: 'TopProductCategorySet' }
          | { __typename: 'TrustPilotCarouselWidget' }
          | { __typename: 'TrustPilotWidget' }
          | { __typename: 'VoucherCodesWidget' }
          | { __typename: 'asymmetricGrid' }
          | { __typename: 'buildyourownbundle' }
          | { __typename: 'buttonLink' }
          | { __typename: 'categoryItemCard' }
          | { __typename: 'categoryItemCardWithTextOverlay' }
          | { __typename: 'coverageCalculator' }
          | { __typename: 'eightLinkButtonWidget' }
          | { __typename: 'fouritemwindowproductslider' }
          | { __typename: 'generalIframe' }
          | { __typename: 'generalQuoteBanner' }
          | { __typename: 'imageSelectCard' }
          | { __typename: 'imageSelectSlider' }
          | { __typename: 'improvedSearchBestSellers' }
          | { __typename: 'kitBuilder' }
          | { __typename: 'loyaltyRewardsList' }
          | { __typename: 'loyaltyWelcomeMessage' }
          | { __typename: 'multiCategoryCardSet' }
          | { __typename: 'multiCategoryCardSetV2' }
          | { __typename: 'obsessVR' }
          | { __typename: 'parcelLabWidget' }
          | { __typename: 'promoproductslider' }
          | { __typename: 'propositionBar' }
          | { __typename: 'propositionBarIncorrect' }
          | { __typename: 'provenanceBrandHeader' }
          | { __typename: 'qubitHomePageRecs' }
          | { __typename: 'revieveWidget' }
          | { __typename: 'shadeFinder' }
          | { __typename: 'shopTheLook' }
          | { __typename: 'simpleCTA' }
          | { __typename: 'simpleDivider' }
          | { __typename: 'simpleTextWidgetSet' }
          | { __typename: 'simpleTitleWidget' }
          | { __typename: 'simpletextwidget' }
          | { __typename: 'simpletextwidgetwithh1' }
          | { __typename: 'sponsoredProducts' }
          | { __typename: 'sponsoredProductsCriteoHomepage' }
          | { __typename: 'sponsoredProductsNew' }
          | { __typename: 'storyStreamWidget' }
          | { __typename: 'subscribeAndSaveInformationModal' }
          | { __typename: 'swatchSelectSlider' }
          | { __typename: 'swatchSelectSliderSet' }
          | { __typename: 'trustReinforcementContainer' }
          | { __typename: 'trustReinforcementSection' }
          | { __typename: 'videoEditorial' }
          | { __typename: 'yotiAgeVerification' }
          | { __typename: 'yotiCancelFrame' }
        >
      >
    >
  }

type AllWidgets_SimpleCta_Fragment = { __typename?: 'simpleCTA' } & Pick<
  SimpleCta,
  | 'CTAText'
  | 'title'
  | 'CTAUrl'
  | 'CTAStyle'
  | 'secondCTAText'
  | 'secondCTAStyle'
  | 'secondCTAUrl'
  | 'id'
> & { backgroundColour: SimpleCta['simpleCTABackgroundColour'] }

type AllWidgets_SimpleDivider_Fragment = { __typename: 'simpleDivider' } & Pick<
  SimpleDivider,
  'marginBottom' | 'marginTop' | 'maxWidth' | 'overrideStyles' | 'id'
>

type AllWidgets_SimpleTextWidgetSet_Fragment = {
  __typename?: 'simpleTextWidgetSet'
} & Pick<SimpleTextWidgetSet, 'id'> & {
    banners?: Maybe<
      Array<
        Maybe<
          | { __typename?: 'AccordionWidget' }
          | { __typename?: 'AccordionWidgetContainer' }
          | { __typename?: 'BMICalculator' }
          | { __typename?: 'BMICalculatorV2' }
          | { __typename?: 'BrandsPageWidget' }
          | { __typename?: 'BuildYourOwnBundleProductList' }
          | { __typename?: 'CriteoSponsoredBannerAds' }
          | { __typename?: 'CriteoSponsoredBannerAdsFlagship' }
          | { __typename?: 'DeliveryInfoWidget' }
          | { __typename?: 'DeliveryInformationWidget' }
          | { __typename?: 'DynamicReferralWidget' }
          | { __typename?: 'Easiware' }
          | { __typename?: 'EditorialWidget' }
          | { __typename?: 'EmailReEngagementModal' }
          | { __typename?: 'FastTrackBanner' }
          | { __typename?: 'FoundationFinderLandingPageWidget' }
          | { __typename?: 'GlobalAboutUsVideo' }
          | { __typename?: 'GlobalAccreditationIcon' }
          | { __typename?: 'GlobalAccreditationIconCollection' }
          | { __typename?: 'GlobalBrandLogos' }
          | { __typename?: 'GlobalBuyingRightNow' }
          | { __typename?: 'GlobalBuyingRightNowManualProductURL' }
          | { __typename?: 'GlobalCardScrollerCard' }
          | { __typename?: 'GlobalCardScrollerSet' }
          | { __typename?: 'GlobalDispatchAndDateCountdownWidget' }
          | { __typename?: 'GlobalEditorialWithFeature' }
          | { __typename?: 'GlobalFooterAccreditationIcons' }
          | { __typename?: 'GlobalFooterContactUs' }
          | { __typename?: 'GlobalFourBestSellers' }
          | { __typename?: 'GlobalFourButtonLink' }
          | { __typename?: 'GlobalFourItemEditorial' }
          | { __typename?: 'GlobalGeneralImageBanner' }
          | { __typename?: 'GlobalHelpCentreAccordion' }
          | { __typename?: 'GlobalHelpCentreAccordion2' }
          | { __typename?: 'GlobalHelpCentreAccordionCollection' }
          | { __typename?: 'GlobalHelpCentreCollection' }
          | { __typename?: 'GlobalHeroCTABanner' }
          | { __typename?: 'GlobalImageCard' }
          | { __typename?: 'GlobalImageCardSet' }
          | { __typename?: 'GlobalMultiButton' }
          | { __typename?: 'GlobalPrimaryBanner' }
          | { __typename?: 'GlobalPrimaryBannerWithList' }
          | { __typename?: 'GlobalPrimaryBannerWithTextOverlay' }
          | { __typename?: 'GlobalProductCardScroller' }
          | { __typename?: 'GlobalReferral3StepGuide' }
          | { __typename?: 'GlobalScalableLogos' }
          | { __typename?: 'GlobalSectionPeek' }
          | { __typename?: 'GlobalSetAndromeda' }
          | { __typename?: 'GlobalSimpleTextCTAWidget' }
          | { __typename?: 'GlobalSixItemCategories' }
          | { __typename?: 'GlobalSocialIcon' }
          | { __typename?: 'GlobalSocialIconCollection' }
          | { __typename?: 'GlobalSocialIconCollectionv2' }
          | { __typename?: 'GlobalSocialIconv2' }
          | { __typename?: 'GlobalStripBanner' }
          | { __typename?: 'GlobalSubscriptionOptions' }
          | { __typename?: 'GlobalTabbedWidgetSet' }
          | { __typename?: 'GlobalThreeItemEditorial' }
          | { __typename?: 'GlobalThreeItemEditorialSubtitleBG' }
          | { __typename?: 'GlobalTransformationSlider' }
          | { __typename?: 'GlobalTrendingHashtagBlock' }
          | { __typename?: 'GlobalTrendingHashtagCollection' }
          | { __typename?: 'GlobalTwoBestSellers' }
          | { __typename?: 'GlobalTwoItemEditorial' }
          | { __typename?: 'GlobalTwoItemImageTextBlock' }
          | { __typename?: 'GlobalTwoItemImageTextCTA' }
          | { __typename?: 'GlobalTwoItemImageTextCTA3070' }
          | { __typename?: 'GlobalUGCCarousel' }
          | { __typename?: 'GlobalVideoGallery' }
          | { __typename?: 'GlobalVideoHeroBannerWidget' }
          | { __typename?: 'GlobalVideoTextImageCTA' }
          | { __typename?: 'GlobalWaitListSignUpFormResponses' }
          | { __typename?: 'GlobalWaitListSignUpWidget' }
          | { __typename?: 'GlobalWidgetSirius' }
          | { __typename?: 'GlobalWidgetVega' }
          | { __typename?: 'GlobalYoutubeGalleryItem' }
          | { __typename?: 'GlobalYoutubeGallerySet' }
          | { __typename?: 'LiveChatWidget' }
          | { __typename?: 'LoyaltyHubBirthdayGift' }
          | { __typename?: 'LoyaltyHubTier' }
          | { __typename?: 'LoyaltyRewardTier' }
          | { __typename?: 'LoyaltyRewardTiers' }
          | { __typename?: 'MailingList' }
          | { __typename?: 'MultipleCTABanner' }
          | { __typename?: 'ProductListWidget' }
          | { __typename?: 'ProductRecommenderItem' }
          | { __typename?: 'ProfileWidget' }
          | { __typename?: 'ResponsiveBuildYourOwnBundle' }
          | { __typename?: 'ResponsiveBuildYourOwnBundleProducts' }
          | { __typename?: 'ResponsiveComparisonTable' }
          | { __typename?: 'ResponsiveComparisonTableContainer' }
          | { __typename?: 'ResponsiveProductBlockCarousel' }
          | { __typename?: 'ResponsiveProductTabs' }
          | { __typename?: 'ResponsiveSetSalon' }
          | { __typename?: 'ResponsiveSetSalonWithSlides' }
          | { __typename?: 'ResponsiveSliderSet' }
          | { __typename?: 'ResponsiveSuccessStoriesWidgetContainer' }
          | { __typename?: 'ResponsiveSuccessStoryWidget' }
          | { __typename?: 'ResponsiveTwoItemComparisonContainer' }
          | { __typename?: 'ResponsiveTwoItemComparisonRow' }
          | { __typename?: 'ResponsiveUSPBar' }
          | { __typename?: 'ResponsiveUSPBarCustomisable' }
          | { __typename?: 'SeoProductCategory' }
          | { __typename?: 'SeoProductCategorySet' }
          | { __typename?: 'ShopTheLookHotSpot' }
          | { __typename?: 'SimpleTextReadMore' }
          | { __typename?: 'TopBrandsWidget' }
          | { __typename?: 'TopProductCategory' }
          | { __typename?: 'TopProductCategorySet' }
          | { __typename?: 'TrustPilotCarouselWidget' }
          | { __typename?: 'TrustPilotWidget' }
          | { __typename?: 'VoucherCodesWidget' }
          | { __typename?: 'asymmetricGrid' }
          | { __typename?: 'buildyourownbundle' }
          | { __typename?: 'buttonLink' }
          | { __typename?: 'categoryItemCard' }
          | { __typename?: 'categoryItemCardWithTextOverlay' }
          | { __typename?: 'coverageCalculator' }
          | { __typename?: 'eightLinkButtonWidget' }
          | { __typename?: 'fouritemwindowproductslider' }
          | { __typename?: 'generalIframe' }
          | { __typename?: 'generalQuoteBanner' }
          | { __typename?: 'imageSelectCard' }
          | { __typename?: 'imageSelectSlider' }
          | { __typename?: 'improvedSearchBestSellers' }
          | { __typename?: 'kitBuilder' }
          | { __typename?: 'loyaltyRewardsList' }
          | { __typename?: 'loyaltyWelcomeMessage' }
          | { __typename?: 'multiCategoryCardSet' }
          | { __typename?: 'multiCategoryCardSetV2' }
          | { __typename?: 'obsessVR' }
          | { __typename?: 'parcelLabWidget' }
          | { __typename?: 'promoproductslider' }
          | { __typename?: 'propositionBar' }
          | { __typename?: 'propositionBarIncorrect' }
          | { __typename?: 'provenanceBrandHeader' }
          | { __typename?: 'qubitHomePageRecs' }
          | { __typename?: 'revieveWidget' }
          | { __typename?: 'shadeFinder' }
          | { __typename?: 'shopTheLook' }
          | { __typename?: 'simpleCTA' }
          | { __typename?: 'simpleDivider' }
          | { __typename?: 'simpleTextWidgetSet' }
          | { __typename?: 'simpleTitleWidget' }
          | ({ __typename: 'simpletextwidget' } & Pick<
              Simpletextwidget,
              'textAlign' | 'title'
            > & {
                simpletextwidgetText?: Maybe<
                  { __typename: 'RichContent' } & RichContentHtmlFragment
                >
              })
          | { __typename?: 'simpletextwidgetwithh1' }
          | { __typename?: 'sponsoredProducts' }
          | { __typename?: 'sponsoredProductsCriteoHomepage' }
          | { __typename?: 'sponsoredProductsNew' }
          | { __typename?: 'storyStreamWidget' }
          | { __typename?: 'subscribeAndSaveInformationModal' }
          | { __typename?: 'swatchSelectSlider' }
          | { __typename?: 'swatchSelectSliderSet' }
          | { __typename?: 'trustReinforcementContainer' }
          | { __typename?: 'trustReinforcementSection' }
          | { __typename?: 'videoEditorial' }
          | { __typename?: 'yotiAgeVerification' }
          | { __typename?: 'yotiCancelFrame' }
        >
      >
    >
  }

type AllWidgets_SimpleTitleWidget_Fragment = {
  __typename?: 'simpleTitleWidget'
} & Pick<
  SimpleTitleWidget,
  | 'additionalTitleStyles'
  | 'textAlign'
  | 'subtitle'
  | 'text'
  | 'title'
  | 'additionalSubtitleStyles'
  | 'id'
>

type AllWidgets_Simpletextwidget_Fragment = {
  __typename?: 'simpletextwidget'
} & Pick<Simpletextwidget, 'textAlign' | 'title' | 'id'> & {
    simpletextwidgetText?: Maybe<
      { __typename: 'RichContent' } & RichContentHtmlFragment
    >
  }

type AllWidgets_Simpletextwidgetwithh1_Fragment = {
  __typename?: 'simpletextwidgetwithh1'
} & Pick<Simpletextwidgetwithh1, 'textAlign' | 'title' | 'id'> & {
    simpletextwidgetText?: Maybe<
      { __typename: 'RichContent' } & RichContentHtmlFragment
    >
  }

type AllWidgets_SponsoredProducts_Fragment = {
  __typename?: 'sponsoredProducts'
} & Pick<
  SponsoredProducts,
  | 'ctaOneAriaLabel'
  | 'contentTheme'
  | 'logopngImageBG'
  | 'useH1'
  | 'altLogoPng'
  | 'ctaOne'
  | 'logopngWhiteBG'
  | 'text'
  | 'headline'
  | 'ctaOneURL'
  | 'id'
> & {
    ProductTwo?: Maybe<
      { __typename?: 'ProductVariant' } & {
        marketedSpecialOffer?: Maybe<
          { __typename?: 'ProductMarketedSpecialOffer' } & {
            title?: Maybe<
              { __typename?: 'RichContent' } & {
                content: Array<
                  { __typename?: 'RichContentItem' } & Pick<
                    RichContentItem,
                    'type' | 'content'
                  >
                >
              }
            >
            description?: Maybe<
              { __typename?: 'RichContent' } & {
                content: Array<
                  { __typename?: 'RichContentItem' } & Pick<
                    RichContentItem,
                    'type' | 'content'
                  >
                >
              }
            >
            landingPageLink?: Maybe<
              { __typename?: 'Hyperlink' } & Pick<
                Hyperlink,
                'text' | 'url' | 'openExternally' | 'noFollow'
              >
            >
          }
        >
      } & ProductVariantFieldsFragment
    >
    ProductOne?: Maybe<
      { __typename?: 'ProductVariant' } & {
        marketedSpecialOffer?: Maybe<
          { __typename?: 'ProductMarketedSpecialOffer' } & {
            title?: Maybe<
              { __typename?: 'RichContent' } & {
                content: Array<
                  { __typename?: 'RichContentItem' } & Pick<
                    RichContentItem,
                    'type' | 'content'
                  >
                >
              }
            >
            description?: Maybe<
              { __typename?: 'RichContent' } & {
                content: Array<
                  { __typename?: 'RichContentItem' } & Pick<
                    RichContentItem,
                    'type' | 'content'
                  >
                >
              }
            >
            landingPageLink?: Maybe<
              { __typename?: 'Hyperlink' } & Pick<
                Hyperlink,
                'text' | 'url' | 'openExternally' | 'noFollow'
              >
            >
          }
        >
      } & ProductVariantFieldsFragment
    >
  }

type AllWidgets_SponsoredProductsCriteoHomepage_Fragment = {
  __typename?: 'sponsoredProductsCriteoHomepage'
} & Pick<SponsoredProductsCriteoHomepage, 'id'>

type AllWidgets_SponsoredProductsNew_Fragment = {
  __typename?: 'sponsoredProductsNew'
} & Pick<SponsoredProductsNew, 'widgetVersion' | 'id'>

type AllWidgets_StoryStreamWidget_Fragment = {
  __typename?: 'storyStreamWidget'
} & Pick<
  StoryStreamWidget,
  'id' | 'layoutClass' | 'storyStreamId' | 'integrityHash'
>

type AllWidgets_SubscribeAndSaveInformationModal_Fragment = {
  __typename?: 'subscribeAndSaveInformationModal'
} & Pick<
  SubscribeAndSaveInformationModal,
  'imageAltText' | 'modalTitle' | 'imageSmall' | 'imageLarge' | 'id'
> & {
    modalContent?: Maybe<
      { __typename?: 'RichContent' } & RichContentHtmlFragment
    >
  }

type AllWidgets_SwatchSelectSlider_Fragment = {
  __typename?: 'swatchSelectSlider'
} & Pick<
  SwatchSelectSlider,
  | 'imageOneLink'
  | 'altTextLogoImage'
  | 'altTextImageOne'
  | 'imageTwoLink'
  | 'link'
  | 'titleThree'
  | 'thumbnailImageFourPath'
  | 'imageThreeLink'
  | 'thumbnailImageOnePath'
  | 'cta'
  | 'altTextThumbnailImageOne'
  | 'imageThreePath'
  | 'ctaLink'
  | 'titleTwo'
  | 'altTextThumbnailImageThree'
  | 'info'
  | 'altTextImageTwo'
  | 'titleOne'
  | 'imageFourPath'
  | 'count'
  | 'linkText'
  | 'titleFour'
  | 'altTextThumbnailImageTwo'
  | 'altTextImageFour'
  | 'logoImagePath'
  | 'thumbnailImageThreePath'
  | 'imageOnePath'
  | 'parentWidgetLink'
  | 'imageFourLink'
  | 'imageTwoPath'
  | 'altTextImageThree'
  | 'altTextThumbnailImageFour'
  | 'thumbnailImageTwoPath'
  | 'id'
>

type AllWidgets_SwatchSelectSliderSet_Fragment = {
  __typename?: 'swatchSelectSliderSet'
} & Pick<SwatchSelectSliderSet, 'id'> & {
    banners?: Maybe<
      Array<
        Maybe<
          | { __typename?: 'AccordionWidget' }
          | { __typename?: 'AccordionWidgetContainer' }
          | { __typename?: 'BMICalculator' }
          | { __typename?: 'BMICalculatorV2' }
          | { __typename?: 'BrandsPageWidget' }
          | { __typename?: 'BuildYourOwnBundleProductList' }
          | { __typename?: 'CriteoSponsoredBannerAds' }
          | { __typename?: 'CriteoSponsoredBannerAdsFlagship' }
          | { __typename?: 'DeliveryInfoWidget' }
          | { __typename?: 'DeliveryInformationWidget' }
          | { __typename?: 'DynamicReferralWidget' }
          | { __typename?: 'Easiware' }
          | { __typename?: 'EditorialWidget' }
          | { __typename?: 'EmailReEngagementModal' }
          | { __typename?: 'FastTrackBanner' }
          | { __typename?: 'FoundationFinderLandingPageWidget' }
          | { __typename?: 'GlobalAboutUsVideo' }
          | { __typename?: 'GlobalAccreditationIcon' }
          | { __typename?: 'GlobalAccreditationIconCollection' }
          | { __typename?: 'GlobalBrandLogos' }
          | { __typename?: 'GlobalBuyingRightNow' }
          | { __typename?: 'GlobalBuyingRightNowManualProductURL' }
          | { __typename?: 'GlobalCardScrollerCard' }
          | { __typename?: 'GlobalCardScrollerSet' }
          | { __typename?: 'GlobalDispatchAndDateCountdownWidget' }
          | { __typename?: 'GlobalEditorialWithFeature' }
          | { __typename?: 'GlobalFooterAccreditationIcons' }
          | { __typename?: 'GlobalFooterContactUs' }
          | { __typename?: 'GlobalFourBestSellers' }
          | { __typename?: 'GlobalFourButtonLink' }
          | { __typename?: 'GlobalFourItemEditorial' }
          | { __typename?: 'GlobalGeneralImageBanner' }
          | { __typename?: 'GlobalHelpCentreAccordion' }
          | { __typename?: 'GlobalHelpCentreAccordion2' }
          | { __typename?: 'GlobalHelpCentreAccordionCollection' }
          | { __typename?: 'GlobalHelpCentreCollection' }
          | { __typename?: 'GlobalHeroCTABanner' }
          | { __typename?: 'GlobalImageCard' }
          | { __typename?: 'GlobalImageCardSet' }
          | { __typename?: 'GlobalMultiButton' }
          | { __typename?: 'GlobalPrimaryBanner' }
          | { __typename?: 'GlobalPrimaryBannerWithList' }
          | { __typename?: 'GlobalPrimaryBannerWithTextOverlay' }
          | { __typename?: 'GlobalProductCardScroller' }
          | { __typename?: 'GlobalReferral3StepGuide' }
          | { __typename?: 'GlobalScalableLogos' }
          | { __typename?: 'GlobalSectionPeek' }
          | { __typename?: 'GlobalSetAndromeda' }
          | { __typename?: 'GlobalSimpleTextCTAWidget' }
          | { __typename?: 'GlobalSixItemCategories' }
          | { __typename?: 'GlobalSocialIcon' }
          | { __typename?: 'GlobalSocialIconCollection' }
          | { __typename?: 'GlobalSocialIconCollectionv2' }
          | { __typename?: 'GlobalSocialIconv2' }
          | { __typename?: 'GlobalStripBanner' }
          | { __typename?: 'GlobalSubscriptionOptions' }
          | { __typename?: 'GlobalTabbedWidgetSet' }
          | { __typename?: 'GlobalThreeItemEditorial' }
          | { __typename?: 'GlobalThreeItemEditorialSubtitleBG' }
          | { __typename?: 'GlobalTransformationSlider' }
          | { __typename?: 'GlobalTrendingHashtagBlock' }
          | { __typename?: 'GlobalTrendingHashtagCollection' }
          | { __typename?: 'GlobalTwoBestSellers' }
          | { __typename?: 'GlobalTwoItemEditorial' }
          | { __typename?: 'GlobalTwoItemImageTextBlock' }
          | { __typename?: 'GlobalTwoItemImageTextCTA' }
          | { __typename?: 'GlobalTwoItemImageTextCTA3070' }
          | { __typename?: 'GlobalUGCCarousel' }
          | { __typename?: 'GlobalVideoGallery' }
          | { __typename?: 'GlobalVideoHeroBannerWidget' }
          | { __typename?: 'GlobalVideoTextImageCTA' }
          | { __typename?: 'GlobalWaitListSignUpFormResponses' }
          | { __typename?: 'GlobalWaitListSignUpWidget' }
          | { __typename?: 'GlobalWidgetSirius' }
          | { __typename?: 'GlobalWidgetVega' }
          | { __typename?: 'GlobalYoutubeGalleryItem' }
          | { __typename?: 'GlobalYoutubeGallerySet' }
          | { __typename?: 'LiveChatWidget' }
          | { __typename?: 'LoyaltyHubBirthdayGift' }
          | { __typename?: 'LoyaltyHubTier' }
          | { __typename?: 'LoyaltyRewardTier' }
          | { __typename?: 'LoyaltyRewardTiers' }
          | { __typename?: 'MailingList' }
          | { __typename?: 'MultipleCTABanner' }
          | { __typename?: 'ProductListWidget' }
          | { __typename?: 'ProductRecommenderItem' }
          | { __typename?: 'ProfileWidget' }
          | { __typename?: 'ResponsiveBuildYourOwnBundle' }
          | { __typename?: 'ResponsiveBuildYourOwnBundleProducts' }
          | { __typename?: 'ResponsiveComparisonTable' }
          | { __typename?: 'ResponsiveComparisonTableContainer' }
          | { __typename?: 'ResponsiveProductBlockCarousel' }
          | { __typename?: 'ResponsiveProductTabs' }
          | { __typename?: 'ResponsiveSetSalon' }
          | { __typename?: 'ResponsiveSetSalonWithSlides' }
          | { __typename?: 'ResponsiveSliderSet' }
          | { __typename?: 'ResponsiveSuccessStoriesWidgetContainer' }
          | { __typename?: 'ResponsiveSuccessStoryWidget' }
          | { __typename?: 'ResponsiveTwoItemComparisonContainer' }
          | { __typename?: 'ResponsiveTwoItemComparisonRow' }
          | { __typename?: 'ResponsiveUSPBar' }
          | { __typename?: 'ResponsiveUSPBarCustomisable' }
          | { __typename?: 'SeoProductCategory' }
          | { __typename?: 'SeoProductCategorySet' }
          | { __typename?: 'ShopTheLookHotSpot' }
          | { __typename?: 'SimpleTextReadMore' }
          | { __typename?: 'TopBrandsWidget' }
          | { __typename?: 'TopProductCategory' }
          | { __typename?: 'TopProductCategorySet' }
          | { __typename?: 'TrustPilotCarouselWidget' }
          | { __typename?: 'TrustPilotWidget' }
          | { __typename?: 'VoucherCodesWidget' }
          | { __typename?: 'asymmetricGrid' }
          | { __typename?: 'buildyourownbundle' }
          | { __typename?: 'buttonLink' }
          | { __typename?: 'categoryItemCard' }
          | { __typename?: 'categoryItemCardWithTextOverlay' }
          | { __typename?: 'coverageCalculator' }
          | { __typename?: 'eightLinkButtonWidget' }
          | { __typename?: 'fouritemwindowproductslider' }
          | { __typename?: 'generalIframe' }
          | { __typename?: 'generalQuoteBanner' }
          | { __typename?: 'imageSelectCard' }
          | { __typename?: 'imageSelectSlider' }
          | { __typename?: 'improvedSearchBestSellers' }
          | { __typename?: 'kitBuilder' }
          | { __typename?: 'loyaltyRewardsList' }
          | { __typename?: 'loyaltyWelcomeMessage' }
          | { __typename?: 'multiCategoryCardSet' }
          | { __typename?: 'multiCategoryCardSetV2' }
          | { __typename?: 'obsessVR' }
          | { __typename?: 'parcelLabWidget' }
          | { __typename?: 'promoproductslider' }
          | { __typename?: 'propositionBar' }
          | { __typename?: 'propositionBarIncorrect' }
          | { __typename?: 'provenanceBrandHeader' }
          | { __typename?: 'qubitHomePageRecs' }
          | { __typename?: 'revieveWidget' }
          | { __typename?: 'shadeFinder' }
          | { __typename?: 'shopTheLook' }
          | { __typename?: 'simpleCTA' }
          | { __typename?: 'simpleDivider' }
          | { __typename?: 'simpleTextWidgetSet' }
          | { __typename?: 'simpleTitleWidget' }
          | { __typename?: 'simpletextwidget' }
          | { __typename?: 'simpletextwidgetwithh1' }
          | { __typename?: 'sponsoredProducts' }
          | { __typename?: 'sponsoredProductsCriteoHomepage' }
          | { __typename?: 'sponsoredProductsNew' }
          | { __typename?: 'storyStreamWidget' }
          | { __typename?: 'subscribeAndSaveInformationModal' }
          | ({ __typename?: 'swatchSelectSlider' } & Pick<
              SwatchSelectSlider,
              | 'imageOneLink'
              | 'altTextLogoImage'
              | 'altTextImageOne'
              | 'imageTwoLink'
              | 'link'
              | 'titleThree'
              | 'thumbnailImageFourPath'
              | 'imageThreeLink'
              | 'thumbnailImageOnePath'
              | 'cta'
              | 'altTextThumbnailImageOne'
              | 'imageThreePath'
              | 'ctaLink'
              | 'titleTwo'
              | 'altTextThumbnailImageThree'
              | 'info'
              | 'altTextImageTwo'
              | 'titleOne'
              | 'imageFourPath'
              | 'count'
              | 'linkText'
              | 'titleFour'
              | 'altTextThumbnailImageTwo'
              | 'altTextImageFour'
              | 'logoImagePath'
              | 'thumbnailImageThreePath'
              | 'imageOnePath'
              | 'parentWidgetLink'
              | 'imageFourLink'
              | 'imageTwoPath'
              | 'altTextImageThree'
              | 'altTextThumbnailImageFour'
              | 'thumbnailImageTwoPath'
            >)
          | { __typename?: 'swatchSelectSliderSet' }
          | { __typename?: 'trustReinforcementContainer' }
          | { __typename?: 'trustReinforcementSection' }
          | { __typename?: 'videoEditorial' }
          | { __typename?: 'yotiAgeVerification' }
          | { __typename?: 'yotiCancelFrame' }
        >
      >
    >
  }

type AllWidgets_TrustReinforcementContainer_Fragment = {
  __typename?: 'trustReinforcementContainer'
} & Pick<TrustReinforcementContainer, 'showStockInformation' | 'id'>

type AllWidgets_TrustReinforcementSection_Fragment = {
  __typename?: 'trustReinforcementSection'
} & Pick<
  TrustReinforcementSection,
  'modalTitle' | 'subtitle' | 'icon' | 'title' | 'id'
> & {
    modalBody?: Maybe<{ __typename?: 'RichContent' } & RichContentHtmlFragment>
  }

type AllWidgets_VideoEditorial_Fragment = {
  __typename?: 'videoEditorial'
} & Pick<VideoEditorial, 'id'>

type AllWidgets_YotiAgeVerification_Fragment = {
  __typename?: 'yotiAgeVerification'
} & Pick<YotiAgeVerification, 'id'>

type AllWidgets_YotiCancelFrame_Fragment = {
  __typename?: 'yotiCancelFrame'
} & Pick<YotiCancelFrame, 'id'>

export type AllWidgetsFragment =
  | AllWidgets_AccordionWidget_Fragment
  | AllWidgets_AccordionWidgetContainer_Fragment
  | AllWidgets_BmiCalculator_Fragment
  | AllWidgets_BmiCalculatorV2_Fragment
  | AllWidgets_BrandsPageWidget_Fragment
  | AllWidgets_BuildYourOwnBundleProductList_Fragment
  | AllWidgets_CriteoSponsoredBannerAds_Fragment
  | AllWidgets_CriteoSponsoredBannerAdsFlagship_Fragment
  | AllWidgets_DeliveryInfoWidget_Fragment
  | AllWidgets_DeliveryInformationWidget_Fragment
  | AllWidgets_DynamicReferralWidget_Fragment
  | AllWidgets_Easiware_Fragment
  | AllWidgets_EditorialWidget_Fragment
  | AllWidgets_EmailReEngagementModal_Fragment
  | AllWidgets_FastTrackBanner_Fragment
  | AllWidgets_FoundationFinderLandingPageWidget_Fragment
  | AllWidgets_GlobalAboutUsVideo_Fragment
  | AllWidgets_GlobalAccreditationIcon_Fragment
  | AllWidgets_GlobalAccreditationIconCollection_Fragment
  | AllWidgets_GlobalBrandLogos_Fragment
  | AllWidgets_GlobalBuyingRightNow_Fragment
  | AllWidgets_GlobalBuyingRightNowManualProductUrl_Fragment
  | AllWidgets_GlobalCardScrollerCard_Fragment
  | AllWidgets_GlobalCardScrollerSet_Fragment
  | AllWidgets_GlobalDispatchAndDateCountdownWidget_Fragment
  | AllWidgets_GlobalEditorialWithFeature_Fragment
  | AllWidgets_GlobalFooterAccreditationIcons_Fragment
  | AllWidgets_GlobalFooterContactUs_Fragment
  | AllWidgets_GlobalFourBestSellers_Fragment
  | AllWidgets_GlobalFourButtonLink_Fragment
  | AllWidgets_GlobalFourItemEditorial_Fragment
  | AllWidgets_GlobalGeneralImageBanner_Fragment
  | AllWidgets_GlobalHelpCentreAccordion_Fragment
  | AllWidgets_GlobalHelpCentreAccordion2_Fragment
  | AllWidgets_GlobalHelpCentreAccordionCollection_Fragment
  | AllWidgets_GlobalHelpCentreCollection_Fragment
  | AllWidgets_GlobalHeroCtaBanner_Fragment
  | AllWidgets_GlobalImageCard_Fragment
  | AllWidgets_GlobalImageCardSet_Fragment
  | AllWidgets_GlobalMultiButton_Fragment
  | AllWidgets_GlobalPrimaryBanner_Fragment
  | AllWidgets_GlobalPrimaryBannerWithList_Fragment
  | AllWidgets_GlobalPrimaryBannerWithTextOverlay_Fragment
  | AllWidgets_GlobalProductCardScroller_Fragment
  | AllWidgets_GlobalReferral3StepGuide_Fragment
  | AllWidgets_GlobalScalableLogos_Fragment
  | AllWidgets_GlobalSectionPeek_Fragment
  | AllWidgets_GlobalSetAndromeda_Fragment
  | AllWidgets_GlobalSimpleTextCtaWidget_Fragment
  | AllWidgets_GlobalSixItemCategories_Fragment
  | AllWidgets_GlobalSocialIcon_Fragment
  | AllWidgets_GlobalSocialIconCollection_Fragment
  | AllWidgets_GlobalSocialIconCollectionv2_Fragment
  | AllWidgets_GlobalSocialIconv2_Fragment
  | AllWidgets_GlobalStripBanner_Fragment
  | AllWidgets_GlobalSubscriptionOptions_Fragment
  | AllWidgets_GlobalTabbedWidgetSet_Fragment
  | AllWidgets_GlobalThreeItemEditorial_Fragment
  | AllWidgets_GlobalThreeItemEditorialSubtitleBg_Fragment
  | AllWidgets_GlobalTransformationSlider_Fragment
  | AllWidgets_GlobalTrendingHashtagBlock_Fragment
  | AllWidgets_GlobalTrendingHashtagCollection_Fragment
  | AllWidgets_GlobalTwoBestSellers_Fragment
  | AllWidgets_GlobalTwoItemEditorial_Fragment
  | AllWidgets_GlobalTwoItemImageTextBlock_Fragment
  | AllWidgets_GlobalTwoItemImageTextCta_Fragment
  | AllWidgets_GlobalTwoItemImageTextCta3070_Fragment
  | AllWidgets_GlobalUgcCarousel_Fragment
  | AllWidgets_GlobalVideoGallery_Fragment
  | AllWidgets_GlobalVideoHeroBannerWidget_Fragment
  | AllWidgets_GlobalVideoTextImageCta_Fragment
  | AllWidgets_GlobalWaitListSignUpFormResponses_Fragment
  | AllWidgets_GlobalWaitListSignUpWidget_Fragment
  | AllWidgets_GlobalWidgetSirius_Fragment
  | AllWidgets_GlobalWidgetVega_Fragment
  | AllWidgets_GlobalYoutubeGalleryItem_Fragment
  | AllWidgets_GlobalYoutubeGallerySet_Fragment
  | AllWidgets_LiveChatWidget_Fragment
  | AllWidgets_LoyaltyHubBirthdayGift_Fragment
  | AllWidgets_LoyaltyHubTier_Fragment
  | AllWidgets_LoyaltyRewardTier_Fragment
  | AllWidgets_LoyaltyRewardTiers_Fragment
  | AllWidgets_MailingList_Fragment
  | AllWidgets_MultipleCtaBanner_Fragment
  | AllWidgets_ProductListWidget_Fragment
  | AllWidgets_ProductRecommenderItem_Fragment
  | AllWidgets_ProfileWidget_Fragment
  | AllWidgets_ResponsiveBuildYourOwnBundle_Fragment
  | AllWidgets_ResponsiveBuildYourOwnBundleProducts_Fragment
  | AllWidgets_ResponsiveComparisonTable_Fragment
  | AllWidgets_ResponsiveComparisonTableContainer_Fragment
  | AllWidgets_ResponsiveProductBlockCarousel_Fragment
  | AllWidgets_ResponsiveProductTabs_Fragment
  | AllWidgets_ResponsiveSetSalon_Fragment
  | AllWidgets_ResponsiveSetSalonWithSlides_Fragment
  | AllWidgets_ResponsiveSliderSet_Fragment
  | AllWidgets_ResponsiveSuccessStoriesWidgetContainer_Fragment
  | AllWidgets_ResponsiveSuccessStoryWidget_Fragment
  | AllWidgets_ResponsiveTwoItemComparisonContainer_Fragment
  | AllWidgets_ResponsiveTwoItemComparisonRow_Fragment
  | AllWidgets_ResponsiveUspBar_Fragment
  | AllWidgets_ResponsiveUspBarCustomisable_Fragment
  | AllWidgets_SeoProductCategory_Fragment
  | AllWidgets_SeoProductCategorySet_Fragment
  | AllWidgets_ShopTheLookHotSpot_Fragment
  | AllWidgets_SimpleTextReadMore_Fragment
  | AllWidgets_TopBrandsWidget_Fragment
  | AllWidgets_TopProductCategory_Fragment
  | AllWidgets_TopProductCategorySet_Fragment
  | AllWidgets_TrustPilotCarouselWidget_Fragment
  | AllWidgets_TrustPilotWidget_Fragment
  | AllWidgets_VoucherCodesWidget_Fragment
  | AllWidgets_AsymmetricGrid_Fragment
  | AllWidgets_Buildyourownbundle_Fragment
  | AllWidgets_ButtonLink_Fragment
  | AllWidgets_CategoryItemCard_Fragment
  | AllWidgets_CategoryItemCardWithTextOverlay_Fragment
  | AllWidgets_CoverageCalculator_Fragment
  | AllWidgets_EightLinkButtonWidget_Fragment
  | AllWidgets_Fouritemwindowproductslider_Fragment
  | AllWidgets_GeneralIframe_Fragment
  | AllWidgets_GeneralQuoteBanner_Fragment
  | AllWidgets_ImageSelectCard_Fragment
  | AllWidgets_ImageSelectSlider_Fragment
  | AllWidgets_ImprovedSearchBestSellers_Fragment
  | AllWidgets_KitBuilder_Fragment
  | AllWidgets_LoyaltyRewardsList_Fragment
  | AllWidgets_LoyaltyWelcomeMessage_Fragment
  | AllWidgets_MultiCategoryCardSet_Fragment
  | AllWidgets_MultiCategoryCardSetV2_Fragment
  | AllWidgets_ObsessVr_Fragment
  | AllWidgets_ParcelLabWidget_Fragment
  | AllWidgets_Promoproductslider_Fragment
  | AllWidgets_PropositionBar_Fragment
  | AllWidgets_PropositionBarIncorrect_Fragment
  | AllWidgets_ProvenanceBrandHeader_Fragment
  | AllWidgets_QubitHomePageRecs_Fragment
  | AllWidgets_RevieveWidget_Fragment
  | AllWidgets_ShadeFinder_Fragment
  | AllWidgets_ShopTheLook_Fragment
  | AllWidgets_SimpleCta_Fragment
  | AllWidgets_SimpleDivider_Fragment
  | AllWidgets_SimpleTextWidgetSet_Fragment
  | AllWidgets_SimpleTitleWidget_Fragment
  | AllWidgets_Simpletextwidget_Fragment
  | AllWidgets_Simpletextwidgetwithh1_Fragment
  | AllWidgets_SponsoredProducts_Fragment
  | AllWidgets_SponsoredProductsCriteoHomepage_Fragment
  | AllWidgets_SponsoredProductsNew_Fragment
  | AllWidgets_StoryStreamWidget_Fragment
  | AllWidgets_SubscribeAndSaveInformationModal_Fragment
  | AllWidgets_SwatchSelectSlider_Fragment
  | AllWidgets_SwatchSelectSliderSet_Fragment
  | AllWidgets_TrustReinforcementContainer_Fragment
  | AllWidgets_TrustReinforcementSection_Fragment
  | AllWidgets_VideoEditorial_Fragment
  | AllWidgets_YotiAgeVerification_Fragment
  | AllWidgets_YotiCancelFrame_Fragment

export type AccountAddAddressMutationVariables = Exact<{
  input: AddressInput
}>

export type AccountAddAddressMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'addAddress'
>

export type AccountApproveSocialLinkMutationVariables = Exact<{
  input?: Maybe<ApproveSocialLinkInput>
}>

export type AccountApproveSocialLinkMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'approveSocialLink'>

export type AccountDeleteAddressMutationVariables = Exact<{
  addressId: Scalars['ID']
}>

export type AccountDeleteAddressMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteAddress'
>

export type AccountRemoveSocialLinkMutationVariables = Exact<{
  input?: Maybe<RemoveSocialLinkInput>
}>

export type AccountRemoveSocialLinkMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'removeSocialLink'>

export type AccountReplaceAddressMutationVariables = Exact<{
  input: ReplaceAddressInput
}>

export type AccountReplaceAddressMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'replaceAddress'
>

export type AddOnSubscriptionProductsMutationVariables = Exact<{
  subscriptionId: Scalars['ID']
  addOns: Array<ProductAddOnInput> | ProductAddOnInput
}>

export type AddOnSubscriptionProductsMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'addSubscriptionProducts'>

export type CancelOrderProductsMutationVariables = Exact<{
  input: CancelOrderProductsInput
}>

export type CancelOrderProductsMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'cancelOrderProducts'
>

export type CancelOrderSpecialOfferGroupsMutationVariables = Exact<{
  input: CancelOrderSpecialOfferGroupsInput
}>

export type CancelOrderSpecialOfferGroupsMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'cancelOrderSpecialOfferGroups'>

export type CancelReturnMutationVariables = Exact<{
  returnNumber: Scalars['Int']
}>

export type CancelReturnMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'cancelReturn'
>

export type CancelSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['ID']
}>

export type CancelSubscriptionMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'cancelSubscription'
>

export type UpdateSubscriptionAutoRenewalOptInTypeVariables = Exact<{
  subscriptionId: Scalars['ID']
  optInTypeInput: SubscriptionAutoRenewalOptInTypeInput
}>

export type UpdateSubscriptionAutoRenewalOptInTypeMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'updateSubscriptionAutoRenewalOptInType'>

export type CreateDiscussionMutationVariables = Exact<{
  input: CreateDiscussionInput
}>

export type CreateDiscussionMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'createDiscussion'
>

export type DelaySubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['ID']
}>

export type DelaySubscriptionMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'delaySubscription'
>

export type DeleteAddressMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteAddressMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteAddress'
>

export type DeletePaymentCardMutationVariables = Exact<{
  cardId: Scalars['ID']
}>

export type DeletePaymentCardMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deletePaymentCard'
>

export type ForgottenPasswordMutationVariables = Exact<{
  input: ForgottenPasswordInput
}>

export type ForgottenPasswordMutation = { __typename?: 'Mutation' } & {
  forgottenPassword?: Maybe<
    { __typename?: 'ForgottenPasswordResponse' } & Pick<
      ForgottenPasswordResponse,
      'success'
    >
  >
}

export type GenerateAndAddRewardCardMutationVariables = Exact<{
  [key: string]: never
}>

export type GenerateAndAddRewardCardMutation = { __typename?: 'Mutation' } & {
  generateAndAddRewardCard: {
    __typename?: 'GenerateAndAddRewardCardResponse'
  } & Pick<GenerateAndAddRewardCardResponse, 'success' | 'error'>
}

export type LoginMutationVariables = Exact<{
  input: LoginInput
  alternateAuth?: Maybe<Scalars['Boolean']>
}>

export type LoginMutation = { __typename?: 'Mutation' } & {
  login?: Maybe<
    { __typename?: 'AuthenticationResponse' } & MakeOptional<
      Pick<AuthenticationResponse, 'error' | 'passwordResetToken'>,
      'passwordResetToken'
    >
  >
}

export type LoginAndApproveSocialLinkMutationVariables = Exact<{
  input: LoginAndApproveSocialLinkInput
}>

export type LoginAndApproveSocialLinkMutation = { __typename?: 'Mutation' } & {
  loginAndApproveSocialLink?: Maybe<
    { __typename?: 'AuthenticationResponse' } & Pick<
      AuthenticationResponse,
      'error'
    >
  >
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'logout'
>

export type RegisterMutationVariables = Exact<{
  input: RegistrationInput
}>

export type RegisterMutation = { __typename?: 'Mutation' } & {
  register: { __typename?: 'AuthenticationResponse' } & Pick<
    AuthenticationResponse,
    'error' | 'newCustomer'
  > & {
      fieldErrors?: Maybe<
        Array<
          Maybe<
            { __typename?: 'FormFieldValidationError' } & Pick<
              FormFieldValidationError,
              'fieldName' | 'validators'
            >
          >
        >
      >
      customer?: Maybe<
        { __typename?: 'Customer' } & Pick<Customer, 'fullName' | 'email'>
      >
    }
}

export type RemoveRewardCardMutationVariables = Exact<{ [key: string]: never }>

export type RemoveRewardCardMutation = { __typename?: 'Mutation' } & {
  removeRewardCard: { __typename?: 'RemoveRewardCardResponse' } & Pick<
    RemoveRewardCardResponse,
    'success' | 'error'
  >
}

export type RemoveSubscriptionProductsMutationVariables = Exact<{
  subscriptionId: Scalars['ID']
  removals: Array<Scalars['SKU']> | Scalars['SKU']
}>

export type RemoveSubscriptionProductsMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'removeSubscriptionProducts'>

export type ReplyToDiscussionMutationVariables = Exact<{
  discussionId: Scalars['ID']
  input: AddDiscussionMessageInput
}>

export type ReplyToDiscussionMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'replyToDiscussion'
>

export type RequestSocialLinkVerificationEmailMutationVariables = Exact<{
  input: RequestSocialLinkVerificationEmailInput
}>

export type RequestSocialLinkVerificationEmailMutation = {
  __typename?: 'Mutation'
} & {
  requestSocialLinkVerificationEmail?: Maybe<
    { __typename?: 'RequestSocialLinkVerificationEmailResponse' } & Pick<
      RequestSocialLinkVerificationEmailResponse,
      'error' | 'socialAuthenticationToken'
    >
  >
}

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput
}>

export type ResetPasswordMutation = { __typename?: 'Mutation' } & {
  resetPassword?: Maybe<
    { __typename?: 'AuthenticationResponse' } & Pick<
      AuthenticationResponse,
      'error'
    >
  >
}

export type SetDefaultBillingAddressMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type SetDefaultBillingAddressMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'setDefaultBillingAddress'>

export type SetDefaultDeliveryAddressMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type SetDefaultDeliveryAddressMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'setDefaultDeliveryAddress'>

export type SocialLoginMutationVariables = Exact<{
  input?: Maybe<SocialLoginInput>
}>

export type SocialLoginMutation = { __typename?: 'Mutation' } & {
  socialLogin?: Maybe<
    { __typename?: 'SocialAuthenticationResponse' } & Pick<
      SocialAuthenticationResponse,
      'socialUserId' | 'socialLoginToken'
    > & {
        authenticationResponse: {
          __typename?: 'AuthenticationResponse'
        } & Pick<AuthenticationResponse, 'newCustomer' | 'error'>
        form?: Maybe<{ __typename?: 'Form' } & AllFormFieldsFragment>
        optInForm: { __typename?: 'OptInForm' } & Pick<OptInForm, 'showForm'>
        socialIdentity?: Maybe<
          { __typename?: 'SocialIdentity' } & Pick<
            SocialIdentity,
            'fullName' | 'email'
          >
        >
      }
  >
}

export type SubmitProfileMutationVariables = Exact<{
  input: FormSubmissionInput
}>

export type SubmitProfileMutation = { __typename?: 'Mutation' } & {
  submitProfile?: Maybe<
    { __typename?: 'FormSubmissionResponse' } & Pick<
      FormSubmissionResponse,
      'status'
    > & {
        fieldErrors?: Maybe<
          Array<
            { __typename?: 'FormFieldValidationError' } & Pick<
              FormFieldValidationError,
              'fieldName' | 'invalidOption'
            >
          >
        >
      }
  >
}

export type SwapSubscriptionProductsMutationVariables = Exact<{
  subscriptionId: Scalars['ID']
  swaps: Array<ProductSwapInput> | ProductSwapInput
}>

export type SwapSubscriptionProductsMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'swapSubscriptionProducts'>

export type UnsubscribeMarketingMutationVariables = Exact<{
  input: TokenMarketingInput
}>

export type UnsubscribeMarketingMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'unsubscribeMarketing'
>

export type UnsubscribeSmsMarketingMutationVariables = Exact<{
  phoneNumber: Scalars['String']
}>

export type UnsubscribeSmsMarketingMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'unsubscribeSmsMarketing'>

export type UpdateAccountSettingsMutationVariables = Exact<{
  input: Array<SettingsFieldInput> | SettingsFieldInput
  mobileNumberOnRegistration?: Scalars['Boolean']
}>

export type UpdateAccountSettingsMutation = { __typename?: 'Mutation' } & {
  updateAccountSettings: { __typename?: 'AccountUpdateStatus' } & Pick<
    AccountUpdateStatus,
    'error'
  > & {
      customer?: Maybe<
        { __typename?: 'Customer' } & MakeOptional<
          Pick<Customer, 'fullName' | 'phoneNumber'>,
          'phoneNumber'
        >
      >
    }
}

export type UpdateEmailAddressMutationVariables = Exact<{
  changes: UpdateEmailAddressInput
}>

export type UpdateEmailAddressMutation = { __typename?: 'Mutation' } & {
  updateEmailAddress: { __typename?: 'AccountUpdateStatus' } & Pick<
    AccountUpdateStatus,
    'error'
  > & {
      customer?: Maybe<{ __typename?: 'Customer' } & Pick<Customer, 'email'>>
    }
}

export type UpdateMarketingPreferencesMutationVariables = Exact<{
  input: UpdateMarketingPreferencesInput
}>

export type UpdateMarketingPreferencesMutation = { __typename?: 'Mutation' } & {
  updateMarketingPreferences?: Maybe<
    { __typename?: 'UpdateMarketingPreferencesResponse' } & Pick<
      UpdateMarketingPreferencesResponse,
      'error'
    >
  >
}

export type UpdatePasswordMutationVariables = Exact<{
  changes: UpdatePasswordInput
}>

export type UpdatePasswordMutation = { __typename?: 'Mutation' } & {
  updatePassword: { __typename?: 'PasswordUpdateStatus' } & Pick<
    PasswordUpdateStatus,
    'error'
  >
}

export type UpdateSubscriptionAddressMutationVariables = Exact<{
  subscriptionId: Scalars['ID']
  addressId: Scalars['ID']
}>

export type UpdateSubscriptionAddressMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'updateSubscriptionAddress'>

export type UpdateSubscriptionBillingDateMutationVariables = Exact<{
  subscriptionId: Scalars['ID']
  newBillingDate: Scalars['Date']
}>

export type UpdateSubscriptionBillingDateMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'updateSubscriptionBillingDate'>

export type UpdateSubscriptionFrequencyMutationVariables = Exact<{
  subscriptionId: Scalars['ID']
  newFrequency: SubscriptionFrequencyDurationInput
}>

export type UpdateSubscriptionFrequencyMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'updateSubscriptionFrequency'>

export type VerifyAndAddRewardCardMutationVariables = Exact<{
  rewardCardNumber: Scalars['String']
}>

export type VerifyAndAddRewardCardMutation = { __typename?: 'Mutation' } & {
  verifyAndAddRewardCard: {
    __typename?: 'VerifyAndAddRewardCardResponse'
  } & Pick<VerifyAndAddRewardCardResponse, 'success' | 'error'>
}

export type AddProductsToBasketMutationVariables = Exact<{
  basketId?: Maybe<Scalars['ID']>
  items: Array<AddProductToBasketItem> | AddProductToBasketItem
  sessionSettings: SessionSettings
  fulfilmentInput: BasketAddFulfilmentInput
}>

export type AddProductsToBasketMutation = { __typename?: 'Mutation' } & {
  addProductsToBasket: { __typename?: 'Basket' } & Pick<
    Basket,
    'id' | 'totalQuantity'
  > & {
      items: Array<
        { __typename?: 'BasketItem' } & Pick<
          BasketItem,
          'quantity' | 'freeGift'
        > & {
            product: { __typename?: 'ProductVariant' } & Pick<
              ProductVariant,
              'sku' | 'title' | 'inStock'
            > & {
                product?: Maybe<
                  { __typename?: 'Product' } & Pick<Product, 'sku' | 'url'> & {
                      images: Array<
                        { __typename?: 'ProductImage' } & Pick<
                          ProductImage,
                          'largeProduct'
                        >
                      >
                      variants: Array<
                        { __typename?: 'ProductVariant' } & Pick<
                          ProductVariant,
                          'sku'
                        >
                      >
                    }
                >
              }
            totalStandardPrice: { __typename?: 'MoneyValue' } & Pick<
              MoneyValue,
              'displayValue' | 'amount'
            >
          }
      >
      standardPrice: { __typename?: 'MoneyValue' } & Pick<
        MoneyValue,
        'displayValue' | 'amount'
      >
    }
}

export type AddSelectYourSampleProductToBasketMutationVariables = Exact<{
  basketId?: Maybe<Scalars['ID']>
  selectYourSampleId: Scalars['ID']
  tierId: Scalars['ID']
  sku?: Maybe<Scalars['SKU']>
  settings: SessionSettings
}>

export type AddSelectYourSampleProductToBasketMutation = {
  __typename?: 'Mutation'
} & {
  addSelectYourSampleProductToBasket: { __typename?: 'Basket' } & Pick<
    Basket,
    'id'
  >
}

export type ApplyCodeToBasketMutationVariables = Exact<{
  basketId?: Maybe<Scalars['ID']>
  code: Scalars['String']
  sessionSettings: SessionSettings
}>

export type ApplyCodeToBasketMutation = { __typename?: 'Mutation' } & {
  applyCodeToBasket: { __typename?: 'Basket' } & Pick<Basket, 'id'> & {
      messages: Array<
        { __typename?: 'BasketMessage' } & Pick<
          BasketMessage,
          'type' | 'message'
        >
      >
    }
}

export type ChangeFulfilmentTypeMutationVariables = Exact<{
  basketId?: Maybe<Scalars['ID']>
  itemId: Scalars['ID']
  fulfilmentInput: BasketAddFulfilmentInput
  settings: SessionSettings
}>

export type ChangeFulfilmentTypeMutation = { __typename?: 'Mutation' } & {
  changeFulfilmentType: { __typename?: 'Basket' } & Pick<Basket, 'id'> & {
      items: Array<
        { __typename?: 'BasketItem' } & Pick<BasketItem, 'fulfilmentMethod'>
      >
    }
}

export type CheckoutMutationVariables = Exact<{
  input: CheckoutStartInput
}>

export type CheckoutMutation = { __typename?: 'Mutation' } & {
  checkout?: Maybe<
    { __typename?: 'CheckoutStartResponse' } & Pick<
      CheckoutStartResponse,
      'error' | 'checkoutUrl'
    >
  >
}

export type RemoveCodeFromBasketMutationVariables = Exact<{
  basketId?: Maybe<Scalars['ID']>
  sessionSettings: SessionSettings
}>

export type RemoveCodeFromBasketMutation = { __typename?: 'Mutation' } & {
  removeCodeFromBasket: { __typename?: 'Basket' } & Pick<Basket, 'id'>
}

export type RemoveProductFromBasketMutationVariables = Exact<{
  basketId?: Maybe<Scalars['ID']>
  itemId: Scalars['ID']
  settings: SessionSettings
}>

export type RemoveProductFromBasketMutation = { __typename?: 'Mutation' } & {
  removeProductFromBasket: { __typename?: 'Basket' } & Pick<Basket, 'id'>
}

export type RemoveSelectYourSampleProductFromBasketMutationVariables = Exact<{
  basketId?: Maybe<Scalars['ID']>
  selectYourSampleId: Scalars['ID']
  tierId: Scalars['ID']
  sku?: Maybe<Scalars['SKU']>
  settings: SessionSettings
}>

export type RemoveSelectYourSampleProductFromBasketMutation = {
  __typename?: 'Mutation'
} & {
  removeSelectYourSampleProductFromBasket: { __typename?: 'Basket' } & Pick<
    Basket,
    'id'
  >
}

export type SuperSizeProductInBasketMutationVariables = Exact<{
  basketId?: Maybe<Scalars['ID']>
  itemId: Scalars['ID']
  settings: SessionSettings
}>

export type SuperSizeProductInBasketMutation = { __typename?: 'Mutation' } & {
  supersizeProductInBasket: { __typename?: 'Basket' } & Pick<Basket, 'id'>
}

export type UpdateProductQuantityInBasketMutationVariables = Exact<{
  basketId?: Maybe<Scalars['ID']>
  itemId: Scalars['ID']
  quantity: Scalars['Int']
  settings: SessionSettings
}>

export type UpdateProductQuantityInBasketMutation = {
  __typename?: 'Mutation'
} & {
  updateProductQuantityInBasket: { __typename?: 'Basket' } & Pick<Basket, 'id'>
}

export type SendReferralEmailMutationVariables = Exact<{
  emailAddresses: Array<Scalars['String']> | Scalars['String']
}>

export type SendReferralEmailMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'sendReferralEmail'
>

export type SignUpForEmailMarketingCampaignMutationVariables = Exact<{
  input: SignUpForEmailMarketingCampaignInput
}>

export type SignUpForEmailMarketingCampaignMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'signUpForEmailMarketingCampaign'>

export type SignUpForMarketingMutationVariables = Exact<{
  input: SignUpForMarketingInput
}>

export type SignUpForMarketingMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'signUpForMarketing'
>

export type AddProductToWaitlistMutationVariables = Exact<{
  sku: Scalars['SKU']
  email: Scalars['String']
  marketingConsentAuditData?: Maybe<MarketingConsentAuditData>
}>

export type AddProductToWaitlistMutation = { __typename?: 'Mutation' } & {
  addProductToWaitlist?: Maybe<
    { __typename: 'AddToWaitlistResponse' } & Pick<
      AddToWaitlistResponse,
      'status'
    >
  >
}

export type AddProductToWaitlistByTypeMutationVariables = Exact<{
  input: AddProductWaitlistInput
}>

export type AddProductToWaitlistByTypeMutation = { __typename?: 'Mutation' } & {
  addProductToWaitlistByType?: Maybe<
    { __typename: 'AddToWaitlistResponse' } & Pick<
      AddToWaitlistResponse,
      'status'
    >
  >
}

export type AddProductToWishlistMutationVariables = Exact<{
  sku: Scalars['SKU']
}>

export type AddProductToWishlistMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'addProductToWishlist'
>

export type RemoveProductFromWishlistMutationVariables = Exact<{
  sku: Scalars['SKU']
}>

export type RemoveProductFromWishlistMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'removeProductFromWishlist'>

export type ReportReviewMutationVariables = Exact<{
  input: ReviewVotingInput
}>

export type ReportReviewMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'reportReview'
>

export type VoteReviewNegativeMutationVariables = Exact<{
  input: ReviewVotingInput
}>

export type VoteReviewNegativeMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'voteReviewNegative'
>

export type VoteReviewPositiveMutationVariables = Exact<{
  input: ReviewVotingInput
}>

export type VoteReviewPositiveMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'voteReviewPositive'
>

export type AddReviewMutationVariables = Exact<{
  input: AddReviewInput
}>

export type AddReviewMutation = { __typename?: 'Mutation' } & {
  addReview?: Maybe<
    { __typename: 'AddReviewResponse' } & Pick<AddReviewResponse, 'error'> & {
        fieldErrors: Array<
          { __typename: 'FormFieldValidationError' } & Pick<
            FormFieldValidationError,
            | 'fieldName'
            | 'validators'
            | 'requiredButNotProvided'
            | 'invalidOption'
          >
        >
      }
  >
}

export type AccountAddressesQueryVariables = Exact<{
  offset: Scalars['Int']
  limit: Scalars['Int']
  filter?: Maybe<AddressesFilterInput>
}>

export type AccountAddressesQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      addresses?: Maybe<
        { __typename?: 'Addresses' } & Pick<Addresses, 'hasMore' | 'total'> & {
            addresses: Array<
              { __typename?: 'AddressRecord' } & Pick<AddressRecord, 'id'> & {
                  address: { __typename?: 'Address' } & Pick<
                    Address,
                    | 'country'
                    | 'addresseeName'
                    | 'addressLine1'
                    | 'addressLine2'
                    | 'addressLine3'
                    | 'addressLine4'
                    | 'addressLine5'
                    | 'companyName'
                    | 'state'
                    | 'phoneNumber'
                    | 'clickAndCollect'
                    | 'postalCode'
                    | 'defaultBillingAddress'
                    | 'defaultDeliveryAddress'
                  >
                }
            >
          }
      >
    }
  >
}

export type AccountCreationMarketingPreferencesQueryVariables = Exact<{
  email: Scalars['String']
}>

export type AccountCreationMarketingPreferencesQuery = {
  __typename?: 'Query'
} & Pick<Query, 'accountCreationMarketingPreferences'>

export type AccountDetailsQueryVariables = Exact<{
  mobileNumberOnRegistration?: Scalars['Boolean']
}>

export type AccountDetailsQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & MakeOptional<
      Pick<Customer, 'fullName' | 'phoneNumber' | 'email'>,
      'phoneNumber'
    >
  >
}

export type AccountDiscussionsQueryVariables = Exact<{
  offset: Scalars['Int']
  limit: Scalars['Int']
  filter?: Maybe<DiscussionsFilterInput>
  messageLimit?: Maybe<Scalars['Int']>
}>

export type AccountDiscussionsQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      discussions?: Maybe<
        { __typename?: 'Discussions' } & Pick<
          Discussions,
          'total' | 'hasMore'
        > & {
            discussions: Array<
              { __typename?: 'Discussion' } & Pick<
                Discussion,
                | 'id'
                | 'category'
                | 'status'
                | 'updatedAt'
                | 'createdAt'
                | 'read'
              > & {
                  selection?: Maybe<
                    { __typename?: 'DiscussionSelection' } & {
                      selectedOrder?: Maybe<
                        { __typename?: 'Order' } & Pick<Order, 'orderNumber'>
                      >
                      selectedProducts: Array<
                        { __typename?: 'OrderProduct' } & Pick<
                          OrderProduct,
                          'sku'
                        > & {
                            productVariant?: Maybe<
                              { __typename?: 'ProductVariant' } & Pick<
                                ProductVariant,
                                'title'
                              >
                            >
                          }
                      >
                    }
                  >
                  messages: { __typename?: 'DiscussionMessages' } & Pick<
                    DiscussionMessages,
                    'hasMore' | 'total'
                  > & {
                      messages: Array<
                        { __typename?: 'DiscussionMessage' } & Pick<
                          DiscussionMessage,
                          'type' | 'createdAt' | 'message' | 'id'
                        >
                      >
                    }
                }
            >
          }
      >
    }
  >
}

export type AccountProfilesQueryVariables = Exact<{
  input: FormFilterInput
}>

export type AccountProfilesQuery = { __typename?: 'Query' } & {
  profiles?: Maybe<
    Array<
      { __typename?: 'Form' } & Pick<
        Form,
        'identifier' | 'type' | 'canSubmit'
      > & {
          fields: Array<
            { __typename?: 'FormField' } & Pick<
              FormField,
              'name' | 'disabled'
            > & {
                answerOptions?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'AnswerOption' } & Pick<
                        AnswerOption,
                        'optionKey' | 'translation'
                      >
                    >
                  >
                >
                validators: Array<
                  { __typename?: 'Validator' } & Pick<
                    Validator,
                    'name' | 'argument'
                  >
                >
              }
          >
        }
    >
  >
}

export type AccountSocialLinksQueryVariables = Exact<{ [key: string]: never }>

export type AccountSocialLinksQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      socialLinks?: Maybe<
        Array<
          { __typename?: 'SocialLink' } & Pick<
            SocialLink,
            'socialLinkId' | 'username' | 'status'
          > & {
              socialLoginProvider?: Maybe<
                { __typename?: 'SocialLoginProvider' } & Pick<
                  SocialLoginProvider,
                  'name'
                >
              >
            }
        >
      >
      pendingSocialLinks?: Maybe<
        Array<
          { __typename?: 'SocialLink' } & Pick<
            SocialLink,
            'socialLinkId' | 'username' | 'status'
          > & {
              socialLoginProvider?: Maybe<
                { __typename?: 'SocialLoginProvider' } & Pick<
                  SocialLoginProvider,
                  'name'
                >
              >
            }
        >
      >
    }
  >
}

export type AttachmentUploaderConfigQueryVariables = Exact<{
  [key: string]: never
}>

export type AttachmentUploaderConfigQuery = { __typename?: 'Query' } & {
  attachmentUploaderConfig?: Maybe<
    { __typename?: 'AttachmentUploaderConfig' } & Pick<
      AttachmentUploaderConfig,
      'uploadUrl' | 'authToken'
    >
  >
}

export type CaptchaConfigurationQueryVariables = Exact<{ [key: string]: never }>

export type CaptchaConfigurationQuery = { __typename?: 'Query' } & {
  captchaConfiguration: Array<
    { __typename?: 'CaptchaConfiguration' } & Pick<
      CaptchaConfiguration,
      'type' | 'siteKey'
    >
  >
}

export type CustomerQueryVariables = Exact<{
  currency?: Maybe<Currency>
  offset: Scalars['Int']
  limit: Scalars['Int']
}>

export type CustomerQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & Pick<Customer, 'email' | 'fullName'> & {
        orders?: Maybe<
          { __typename?: 'Orders' } & Pick<Orders, 'hasMore'> & {
              orders: Array<
                { __typename?: 'Order' } & Pick<
                  Order,
                  'status' | 'orderNumber' | 'createdAt'
                > & {
                    totalCost: { __typename?: 'MoneyValue' } & Pick<
                      MoneyValue,
                      'displayValue'
                    >
                    products: Array<
                      { __typename?: 'OrderProduct' } & Pick<
                        OrderProduct,
                        'dispatchDate'
                      > & {
                          deliveryDateRange?: Maybe<
                            { __typename?: 'DateRange' } & Pick<
                              DateRange,
                              'from' | 'to'
                            >
                          >
                          productVariant?: Maybe<
                            { __typename?: 'ProductVariant' } & Pick<
                              ProductVariant,
                              'title'
                            > & {
                                images: Array<
                                  { __typename?: 'ProductImage' } & Pick<
                                    ProductImage,
                                    'thumbnail'
                                  >
                                >
                                product?: Maybe<
                                  { __typename?: 'Product' } & {
                                    images: Array<
                                      { __typename?: 'ProductImage' } & Pick<
                                        ProductImage,
                                        'thumbnail'
                                      >
                                    >
                                  }
                                >
                              }
                          >
                        }
                    >
                  }
              >
            }
        >
        creditAccounts?: Maybe<
          Array<
            { __typename?: 'CreditAccount' } & {
              balance: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue'
              >
            }
          >
        >
        subscriptions?: Maybe<
          { __typename?: 'Subscriptions' } & Pick<Subscriptions, 'hasMore'> & {
              subscriptions: Array<
                { __typename?: 'ProductSubscription' } & Pick<
                  ProductSubscription,
                  'id' | 'status'
                > & {
                    product?: Maybe<
                      { __typename?: 'ProductVariant' } & Pick<
                        ProductVariant,
                        'title'
                      >
                    >
                    schedule: { __typename?: 'SubscriptionSchedule' } & Pick<
                      SubscriptionSchedule,
                      'paymentFrequency' | 'autoRenew' | 'paymentType'
                    >
                    costPerPaymentPeriod: { __typename?: 'MoneyValue' } & Pick<
                      MoneyValue,
                      'displayValue'
                    >
                  }
              >
            }
        >
      }
  >
}

export type CustomerMarketingPreferencesQueryVariables = Exact<{
  mobileNumberOnRegistration?: Scalars['Boolean']
}>

export type CustomerMarketingPreferencesQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & MakeOptional<
      Pick<Customer, 'phoneNumber'>,
      'phoneNumber'
    > & {
        email: Customer['marketingPreferences']
        sms: Customer['marketingPreferences']
      }
  >
}

export type CustomerPaymentCardsQueryVariables = Exact<{
  offset: Scalars['Int']
  limit: Scalars['Int']
}>

export type CustomerPaymentCardsQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      paymentCards?: Maybe<
        { __typename?: 'PaymentCards' } & Pick<
          PaymentCards,
          'total' | 'hasMore'
        > & {
            cards: Array<
              { __typename?: 'PaymentCardRecord' } & Pick<
                PaymentCardRecord,
                'id'
              > & {
                  card: { __typename?: 'PaymentCard' } & Pick<
                    PaymentCard,
                    | 'nameOnCard'
                    | 'obfuscatedCardNumber'
                    | 'validToYear'
                    | 'validToMonth'
                    | 'type'
                  >
                }
            >
          }
      >
    }
  >
}

export type CustomerReturnsQueryVariables = Exact<{ [key: string]: never }>

export type CustomerReturnsQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      customerReturns: Array<
        { __typename?: 'CustomerReturn' } & Pick<
          CustomerReturn,
          'trackingLink' | 'returnMethod'
        > & {
            customerReturnInfo: { __typename?: 'CustomerReturnInfo' } & Pick<
              CustomerReturnInfo,
              | 'returnNumber'
              | 'customerReturnStatus'
              | 'createdAt'
              | 'dispatchedAt'
              | 'completedAt'
              | 'cancelledAt'
              | 'tracking'
            > & {
                totalValue: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'currency' | 'amount' | 'displayValue' | 'scalarValue'
                >
                refundValue: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'currency' | 'amount' | 'displayValue' | 'scalarValue'
                >
                customerReturnInfoLines: Array<
                  { __typename?: 'CustomerReturnInfoLine' } & Pick<
                    CustomerReturnInfoLine,
                    'quantity'
                  > & {
                      product: { __typename?: 'ProductVariant' } & Pick<
                        ProductVariant,
                        | 'availabilityMessage'
                        | 'eligibleForWishlist'
                        | 'inStock'
                        | 'sku'
                        | 'title'
                      > & {
                          images: Array<
                            { __typename?: 'ProductImage' } & Pick<
                              ProductImage,
                              'largeProduct' | 'zoom'
                            >
                          >
                        }
                    }
                >
              }
            label?: Maybe<
              { __typename?: 'LabelWithDetails' } & {
                label: { __typename?: 'FileDownload' } & Pick<
                  FileDownload,
                  'mimeType' | 'content'
                >
              }
            >
          }
      >
    }
  >
}

export type CustomerRewardOffersQueryVariables = Exact<{ [key: string]: never }>

export type CustomerRewardOffersQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & Pick<
      Customer,
      'fullName' | 'rewardCardNumber'
    > & {
        offersWallet: Array<
          { __typename?: 'RewardOffer' } & Pick<
            RewardOffer,
            | 'offerTitle'
            | 'startDate'
            | 'endDate'
            | 'offerMessage'
            | 'offerImageUrl'
            | 'link'
            | 'linkText'
            | 'onlineCode'
            | 'storeCode'
            | 'storeCodeType'
          >
        >
      }
  >
}

export type EmailFieldQueryVariables = Exact<{ [key: string]: never }>

export type EmailFieldQuery = { __typename?: 'Query' } & {
  emailField?: Maybe<{ __typename?: 'FormField' } & FieldFragment>
}

export type FeaturesQueryVariables = Exact<{ [key: string]: never }>

export type FeaturesQuery = { __typename?: 'Query' } & Pick<Query, 'features'>

export type FormQueryVariables = Exact<{
  input: FormInput
}>

export type FormQuery = { __typename?: 'Query' } & {
  form?: Maybe<{ __typename?: 'Form' } & AllFormFieldsFragment>
}

export type IsReturnableBlockQueryVariables = Exact<{
  filter: OrdersFilterInput
  input: ReturnsEligibilityInput
}>

export type IsReturnableBlockQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      orders?: Maybe<
        { __typename?: 'Orders' } & {
          orders: Array<
            { __typename?: 'Order' } & {
              isReturnable?: Maybe<
                { __typename?: 'ReturnsEligibilityResult' } & Pick<
                  ReturnsEligibilityResult,
                  'success' | 'orderError'
                > & {
                    productErrors?: Maybe<
                      Array<
                        {
                          __typename?: 'ReturnsEligibilitySkuAndProductError'
                        } & Pick<
                          ReturnsEligibilitySkuAndProductError,
                          'sku' | 'error'
                        >
                      >
                    >
                  }
              >
            }
          >
        }
      >
    }
  >
}

export type MainProfileQueryVariables = Exact<{ [key: string]: never }>

export type MainProfileQuery = { __typename?: 'Query' } & {
  forms?: Maybe<Array<{ __typename: 'Form' } & Pick<Form, 'identifier'>>>
}

export type MarketingPreferencesQueryVariables = Exact<{
  email: Scalars['String']
}>

export type MarketingPreferencesQuery = { __typename?: 'Query' } & {
  marketingPreferences?: Maybe<
    { __typename?: 'MarketingPreferences' } & Pick<
      MarketingPreferences,
      'marketingConsent' | 'registered' | 'type'
    >
  >
}

export type MessageProductBlockQueryVariables = Exact<{
  filter: OrdersFilterInput
}>

export type MessageProductBlockQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      orders?: Maybe<
        { __typename?: 'Orders' } & {
          orders: Array<
            { __typename?: 'Order' } & {
              products: Array<
                { __typename?: 'OrderProduct' } & Pick<
                  OrderProduct,
                  'sku' | 'quantity' | 'status'
                > & {
                    costPerUnit?: Maybe<
                      { __typename?: 'MoneyValue' } & Pick<
                        MoneyValue,
                        'displayValue'
                      >
                    >
                    productVariant?: Maybe<
                      { __typename?: 'ProductVariant' } & Pick<
                        ProductVariant,
                        'title' | 'externalIdentifier'
                      > & {
                          images: Array<
                            { __typename?: 'ProductImage' } & Pick<
                              ProductImage,
                              'largeProduct'
                            >
                          >
                          product?: Maybe<
                            { __typename?: 'Product' } & Pick<
                              Product,
                              'url'
                            > & {
                                reviews?: Maybe<
                                  { __typename?: 'ProductReviews' } & {
                                    newReviewForm?: Maybe<
                                      { __typename?: 'Form' } & Pick<
                                        Form,
                                        'identifier' | 'type'
                                      > & {
                                          fields: Array<
                                            { __typename?: 'FormField' } & Pick<
                                              FormField,
                                              | 'name'
                                              | 'label'
                                              | 'type'
                                              | 'required'
                                              | 'defaultValue'
                                            > & {
                                                validators: Array<
                                                  {
                                                    __typename?: 'Validator'
                                                  } & Pick<
                                                    Validator,
                                                    'name' | 'argument'
                                                  >
                                                >
                                                answerOptions?: Maybe<
                                                  Array<
                                                    Maybe<
                                                      {
                                                        __typename?: 'AnswerOption'
                                                      } & Pick<
                                                        AnswerOption,
                                                        | 'optionKey'
                                                        | 'translation'
                                                      >
                                                    >
                                                  >
                                                >
                                              }
                                          >
                                        }
                                    >
                                  }
                                >
                                reviewTemplate?: Maybe<
                                  { __typename?: 'ReviewTemplate' } & Pick<
                                    ReviewTemplate,
                                    'name'
                                  > & {
                                      features: Array<
                                        {
                                          __typename?: 'ReviewTemplateFeature'
                                        } & Pick<
                                          ReviewTemplateFeature,
                                          | 'name'
                                          | 'label'
                                          | 'hasDefaultMeasurement'
                                        > & {
                                            measurementValues: Array<
                                              {
                                                __typename?: 'ReviewMeasurementValue'
                                              } & Pick<
                                                ReviewMeasurementValue,
                                                | 'value'
                                                | 'label'
                                                | 'order'
                                                | 'mappingValue'
                                              >
                                            >
                                          }
                                      >
                                    }
                                >
                                images: Array<
                                  { __typename?: 'ProductImage' } & Pick<
                                    ProductImage,
                                    'thumbnail' | 'largeProduct'
                                  >
                                >
                              }
                          >
                        }
                    >
                    deliveryDateRange?: Maybe<
                      { __typename?: 'DateRange' } & Pick<
                        DateRange,
                        'from' | 'to'
                      >
                    >
                  }
              >
            }
          >
        }
      >
    }
  >
}

export type OrderProductBlockQueryVariables = Exact<{
  offset: Scalars['Int']
  limit: Scalars['Int']
  filter?: Maybe<OrdersFilterInput>
}>

export type OrderProductBlockQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      orders?: Maybe<
        { __typename?: 'Orders' } & {
          orders: Array<
            { __typename?: 'Order' } & {
              products: Array<
                { __typename?: 'OrderProduct' } & Pick<
                  OrderProduct,
                  | 'specialOfferGroup'
                  | 'cancellable'
                  | 'sku'
                  | 'status'
                  | 'quantity'
                  | 'cancellableQuantity'
                > & {
                    productVariant?: Maybe<
                      { __typename?: 'ProductVariant' } & Pick<
                        ProductVariant,
                        'externalIdentifier' | 'title'
                      > & {
                          images: Array<
                            { __typename?: 'ProductImage' } & Pick<
                              ProductImage,
                              'thumbnail' | 'largeProduct'
                            >
                          >
                          marketedSpecialOffer?: Maybe<
                            { __typename?: 'ProductMarketedSpecialOffer' } & {
                              title?: Maybe<
                                { __typename?: 'RichContent' } & {
                                  content: Array<
                                    { __typename?: 'RichContentItem' } & Pick<
                                      RichContentItem,
                                      'type' | 'content'
                                    >
                                  >
                                }
                              >
                            }
                          >
                          product?: Maybe<
                            { __typename?: 'Product' } & Pick<
                              Product,
                              'url'
                            > & {
                                images: Array<
                                  { __typename?: 'ProductImage' } & Pick<
                                    ProductImage,
                                    'thumbnail' | 'largeProduct'
                                  >
                                >
                              }
                          >
                        }
                    >
                    costPerUnit?: Maybe<
                      { __typename?: 'MoneyValue' } & Pick<
                        MoneyValue,
                        'displayValue' | 'amount' | 'currency'
                      >
                    >
                  }
              >
            }
          >
        }
      >
    }
  >
}

export type PasswordFieldQueryVariables = Exact<{ [key: string]: never }>

export type PasswordFieldQuery = { __typename?: 'Query' } & {
  passwordField?: Maybe<{ __typename?: 'FormField' } & FieldFragment>
}

export type PasswordResetTokenValidityQueryVariables = Exact<{
  token: Scalars['PasswordResetToken']
}>

export type PasswordResetTokenValidityQuery = { __typename?: 'Query' } & Pick<
  Query,
  'passwordResetTokenValidity'
>

export type PaymentMethodsQueryVariables = Exact<{ [key: string]: never }>

export type PaymentMethodsQuery = { __typename?: 'Query' } & Pick<
  Query,
  'paymentMethods'
>

export type ProfileQueryVariables = Exact<{
  input: FormFilterInput
}>

export type ProfileQuery = { __typename?: 'Query' } & {
  profiles?: Maybe<
    Array<
      { __typename?: 'Form' } & Pick<
        Form,
        'identifier' | 'displayStyle' | 'isMultiSubmission' | 'canSubmit'
      > & {
          fields: Array<
            { __typename?: 'FormField' } & Pick<
              FormField,
              | 'name'
              | 'type'
              | 'required'
              | 'confirmable'
              | 'disabled'
              | 'defaultValue'
            > & {
                validators: Array<
                  { __typename?: 'Validator' } & Pick<
                    Validator,
                    'name' | 'argument'
                  >
                >
                answerOptions?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'AnswerOption' } & Pick<
                        AnswerOption,
                        'optionKey' | 'translation'
                      >
                    >
                  >
                >
              }
          >
        }
    >
  >
}

export type ReturnUrlQueryVariables = Exact<{
  filter: OrdersFilterInput
  input: ReturnUrlInput
}>

export type ReturnUrlQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      orders?: Maybe<
        { __typename?: 'Orders' } & {
          orders: Array<{ __typename?: 'Order' } & Pick<Order, 'returnUrl'>>
        }
      >
    }
  >
}

export type SitePropertiesQueryVariables = Exact<{
  propertyBucket?: Maybe<Scalars['String']>
  previewId?: Maybe<Scalars['String']>
  keys?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type SitePropertiesQuery = { __typename?: 'Query' } & {
  siteProperties?: Maybe<
    { __typename?: 'SiteProperties' } & {
      properties?: Maybe<
        Array<
          Maybe<
            { __typename?: 'LocalizedString' } & Pick<
              LocalizedString,
              'key' | 'value' | 'location'
            >
          >
        >
      >
    }
  >
}

export type SocialAccountsQueryVariables = Exact<{ [key: string]: never }>

export type SocialAccountsQuery = { __typename?: 'Query' } & {
  socialAccounts: Array<
    { __typename?: 'SocialAccount' } & Pick<
      SocialAccount,
      'url' | 'socialNetwork'
    >
  >
}

export type SocialLoginProvidersQueryVariables = Exact<{ [key: string]: never }>

export type SocialLoginProvidersQuery = { __typename?: 'Query' } & {
  socialLoginProviders?: Maybe<
    Array<
      { __typename?: 'SocialLoginProvider' } & Pick<
        SocialLoginProvider,
        'name' | 'code' | 'loginUrl' | 'iconUrl'
      >
    >
  >
}

export type WishlistQueryVariables = Exact<{
  currency: Currency
  shippingDestination: Country
  limit: Scalars['Int']
  offset: Scalars['Int']
  sort: WishlistSort
}>

export type WishlistQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      wishlist?: Maybe<
        { __typename?: 'WishlistItems' } & Pick<
          WishlistItems,
          'total' | 'hasMore'
        > & {
            items: Array<
              { __typename?: 'WishlistItem' } & {
                product: { __typename?: 'Product' } & Pick<
                  Product,
                  'sku' | 'title' | 'url'
                > & {
                    variants: Array<
                      { __typename?: 'ProductVariant' } & Pick<
                        ProductVariant,
                        'sku' | 'title' | 'availabilityMessage' | 'inStock'
                      > & {
                          images: Array<
                            { __typename?: 'ProductImage' } & Pick<
                              ProductImage,
                              'largeProduct'
                            >
                          >
                          choices: Array<
                            { __typename?: 'OptionChoice' } & Pick<
                              OptionChoice,
                              'optionKey' | 'key' | 'title'
                            >
                          >
                          price?: Maybe<
                            { __typename?: 'ProductPrice' } & {
                              price: { __typename?: 'MoneyValue' } & Pick<
                                MoneyValue,
                                'displayValue' | 'scalarValue'
                              >
                              rrp: { __typename?: 'MoneyValue' } & Pick<
                                MoneyValue,
                                'displayValue' | 'scalarValue'
                              >
                            }
                          >
                        }
                    >
                    images: Array<
                      { __typename?: 'ProductImage' } & Pick<
                        ProductImage,
                        'largeProduct'
                      >
                    >
                    cheapestVariantPrice?: Maybe<
                      { __typename?: 'ProductPrice' } & {
                        price: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'displayValue'
                        >
                        rrp: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'displayValue'
                        >
                      }
                    >
                    options: Array<
                      { __typename?: 'VariantOption' } & Pick<
                        VariantOption,
                        'key' | 'localizedKey'
                      > & {
                          choices: Array<
                            { __typename?: 'OptionChoice' } & Pick<
                              OptionChoice,
                              'optionKey' | 'key' | 'title'
                            >
                          >
                        }
                    >
                  }
                selectedVariant?: Maybe<
                  { __typename?: 'ProductVariant' } & Pick<
                    ProductVariant,
                    'sku' | 'inStock' | 'maxPerOrder' | 'inWishlist' | 'title'
                  > & {
                      price?: Maybe<
                        { __typename?: 'ProductPrice' } & {
                          price: { __typename?: 'MoneyValue' } & Pick<
                            MoneyValue,
                            'displayValue'
                          >
                          rrp: { __typename?: 'MoneyValue' } & Pick<
                            MoneyValue,
                            'displayValue'
                          >
                        }
                      >
                      images: Array<
                        { __typename?: 'ProductImage' } & Pick<
                          ProductImage,
                          'largeProduct'
                        >
                      >
                      choices: Array<
                        { __typename?: 'OptionChoice' } & Pick<
                          OptionChoice,
                          'optionKey' | 'key' | 'title'
                        >
                      >
                    }
                >
              }
            >
          }
      >
    }
  >
}

export type AddToBasketQueryVariables = Exact<{
  sku: Scalars['SKU']
  currency: Currency
  shippingDestination: Country
}>

export type AddToBasketQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename?: 'Product' } & Pick<
      Product,
      'sku' | 'url' | 'externalIdentifier'
    > & {
        brand?: Maybe<
          { __typename?: 'Brand' } & Pick<Brand, 'name' | 'imageUrl'>
        >
        variants: Array<
          { __typename?: 'ProductVariant' } & Pick<
            ProductVariant,
            'title' | 'sku' | 'inStock'
          > & {
              price?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'amount'
                  >
                }
              >
              gbpPrice?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'amount'
                  >
                }
              >
            }
        >
        options: Array<
          { __typename?: 'VariantOption' } & Pick<VariantOption, 'key'>
        >
        defaultVariant?: Maybe<
          { __typename?: 'ProductVariant' } & Pick<
            ProductVariant,
            'sku' | 'title' | 'inStock'
          > & {
              price?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'amount'
                  >
                }
              >
              gbpPrice?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'amount'
                  >
                }
              >
            }
        >
      }
  >
}

export type AddToBasketBasketQueryVariables = Exact<{
  id: Scalars['ID']
  settings: SessionSettings
}>

export type AddToBasketBasketQuery = { __typename?: 'Query' } & {
  basket?: Maybe<
    { __typename?: 'Basket' } & {
      items: Array<
        { __typename?: 'BasketItem' } & Pick<BasketItem, 'quantity'> & {
            product: { __typename?: 'ProductVariant' } & Pick<
              ProductVariant,
              'sku'
            >
          }
      >
    }
  >
}

export type AddedToBasketModalQueryVariables = Exact<{
  basketId?: Maybe<Scalars['ID']>
  sessionSettings: SessionSettings
  loyaltyEnabled?: Scalars['Boolean']
}>

export type AddedToBasketModalQuery = { __typename?: 'Query' } & {
  basket?: Maybe<
    { __typename?: 'Basket' } & MakeOptional<
      Pick<Basket, 'id' | 'totalQuantity' | 'earnableLoyaltyPoints'>,
      'earnableLoyaltyPoints'
    > & {
        standardPrice: { __typename?: 'MoneyValue' } & Pick<
          MoneyValue,
          'displayValue'
        >
      }
  >
}

export type BasketQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>
  currency: Currency
  shippingDestination: Country
  productContentKeys?: Maybe<Array<Scalars['String']> | Scalars['String']>
  wishlistEnabled?: Maybe<Scalars['Boolean']>
  subscriptionsEnabled?: Maybe<Scalars['Boolean']>
  loyaltyEnabled?: Maybe<Scalars['Boolean']>
  clickAndCollectEnabled?: Maybe<Scalars['Boolean']>
  acknowledgeMerge?: Scalars['Boolean']
  keys?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type BasketQuery = { __typename?: 'Query' } & {
  basket?: Maybe<{ __typename: 'Basket' } & SharedBasketFragment>
}

export type QuickBuyModalQueryVariables = Exact<{
  sku: Scalars['SKU']
  currency: Currency
  shippingDestination: Country
  tagKeys?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type QuickBuyModalQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename?: 'Product' } & Pick<Product, 'sku' | 'title' | 'url'> & {
        defaultVariant?: Maybe<
          { __typename?: 'ProductVariant' } & Pick<
            ProductVariant,
            'sku' | 'title' | 'availabilityMessage' | 'inStock' | 'maxPerOrder'
          > & {
              images: Array<
                { __typename?: 'ProductImage' } & Pick<
                  ProductImage,
                  'largeProduct'
                >
              >
              choices: Array<
                { __typename?: 'OptionChoice' } & Pick<
                  OptionChoice,
                  'optionKey' | 'key' | 'colour' | 'title'
                >
              >
              price?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'scalarValue'
                  >
                  rrp: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'scalarValue'
                  >
                }
              >
            }
        >
        images: Array<
          { __typename?: 'ProductImage' } & Pick<ProductImage, 'largeProduct'>
        >
        options: Array<
          { __typename?: 'VariantOption' } & Pick<VariantOption, 'key'> & {
              choices: Array<
                { __typename?: 'OptionChoice' } & Pick<
                  OptionChoice,
                  'optionKey' | 'key' | 'colour' | 'title'
                >
              >
            }
        >
        content: Array<
          { __typename?: 'ProductContentItem' } & {
            value:
              | { __typename?: 'ProductContentAttachmentValue' }
              | { __typename?: 'ProductContentDateValue' }
              | { __typename?: 'ProductContentIntListValue' }
              | { __typename?: 'ProductContentIntValue' }
              | { __typename?: 'ProductContentRichContentListValue' }
              | { __typename?: 'ProductContentRichContentValue' }
              | ({ __typename?: 'ProductContentStringListValue' } & Pick<
                  ProductContentStringListValue,
                  'value'
                >)
              | { __typename?: 'ProductContentStringValue' }
          }
        >
        variants: Array<
          { __typename?: 'ProductVariant' } & Pick<
            ProductVariant,
            'sku' | 'title' | 'availabilityMessage' | 'inStock' | 'maxPerOrder'
          > & {
              images: Array<
                { __typename?: 'ProductImage' } & Pick<
                  ProductImage,
                  'largeProduct'
                >
              >
              choices: Array<
                { __typename?: 'OptionChoice' } & Pick<
                  OptionChoice,
                  'optionKey' | 'key' | 'colour' | 'title'
                >
              >
              price?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'scalarValue'
                  >
                  rrp: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'scalarValue'
                  >
                }
              >
            }
        >
      }
  >
}

export type SearchLocationsQueryVariables = Exact<{
  query: Scalars['String']
  limit: Scalars['Int']
}>

export type SearchLocationsQuery = { __typename?: 'Query' } & {
  searchLocations: Array<
    { __typename?: 'Location' } & Pick<
      Location,
      'displayName' | 'longitude' | 'latitude' | 'postcode'
    >
  >
}

export type BrandsQueryVariables = Exact<{ [key: string]: never }>

export type BrandsQuery = { __typename?: 'Query' } & {
  brands: Array<
    { __typename?: 'Brand' } & Pick<Brand, 'name'> & {
        page?: Maybe<{ __typename?: 'BrandPage' } & Pick<BrandPage, 'path'>>
      }
  >
}

export type ComponentWidgetsQueryVariables = Exact<{
  name: Scalars['String']
  currency: Currency
  shippingDestination: Country
}>

export type ComponentWidgetsQuery = { __typename?: 'Query' } & {
  componentWidgets: Array<
    | ({ __typename?: 'AccordionWidget' } & AllWidgets_AccordionWidget_Fragment)
    | ({
        __typename?: 'AccordionWidgetContainer'
      } & AllWidgets_AccordionWidgetContainer_Fragment)
    | ({ __typename?: 'BMICalculator' } & AllWidgets_BmiCalculator_Fragment)
    | ({ __typename?: 'BMICalculatorV2' } & AllWidgets_BmiCalculatorV2_Fragment)
    | ({
        __typename?: 'BrandsPageWidget'
      } & AllWidgets_BrandsPageWidget_Fragment)
    | ({
        __typename?: 'BuildYourOwnBundleProductList'
      } & AllWidgets_BuildYourOwnBundleProductList_Fragment)
    | ({
        __typename?: 'CriteoSponsoredBannerAds'
      } & AllWidgets_CriteoSponsoredBannerAds_Fragment)
    | ({
        __typename?: 'CriteoSponsoredBannerAdsFlagship'
      } & AllWidgets_CriteoSponsoredBannerAdsFlagship_Fragment)
    | ({
        __typename?: 'DeliveryInfoWidget'
      } & AllWidgets_DeliveryInfoWidget_Fragment)
    | ({
        __typename?: 'DeliveryInformationWidget'
      } & AllWidgets_DeliveryInformationWidget_Fragment)
    | ({
        __typename?: 'DynamicReferralWidget'
      } & AllWidgets_DynamicReferralWidget_Fragment)
    | ({ __typename?: 'Easiware' } & AllWidgets_Easiware_Fragment)
    | ({ __typename?: 'EditorialWidget' } & AllWidgets_EditorialWidget_Fragment)
    | ({
        __typename?: 'EmailReEngagementModal'
      } & AllWidgets_EmailReEngagementModal_Fragment)
    | ({ __typename?: 'FastTrackBanner' } & AllWidgets_FastTrackBanner_Fragment)
    | ({
        __typename?: 'FoundationFinderLandingPageWidget'
      } & AllWidgets_FoundationFinderLandingPageWidget_Fragment)
    | ({
        __typename?: 'GlobalAboutUsVideo'
      } & AllWidgets_GlobalAboutUsVideo_Fragment)
    | ({
        __typename?: 'GlobalAccreditationIcon'
      } & AllWidgets_GlobalAccreditationIcon_Fragment)
    | ({
        __typename?: 'GlobalAccreditationIconCollection'
      } & AllWidgets_GlobalAccreditationIconCollection_Fragment)
    | ({
        __typename?: 'GlobalBrandLogos'
      } & AllWidgets_GlobalBrandLogos_Fragment)
    | ({
        __typename?: 'GlobalBuyingRightNow'
      } & AllWidgets_GlobalBuyingRightNow_Fragment)
    | ({
        __typename?: 'GlobalBuyingRightNowManualProductURL'
      } & AllWidgets_GlobalBuyingRightNowManualProductUrl_Fragment)
    | ({
        __typename?: 'GlobalCardScrollerCard'
      } & AllWidgets_GlobalCardScrollerCard_Fragment)
    | ({
        __typename?: 'GlobalCardScrollerSet'
      } & AllWidgets_GlobalCardScrollerSet_Fragment)
    | ({
        __typename?: 'GlobalDispatchAndDateCountdownWidget'
      } & AllWidgets_GlobalDispatchAndDateCountdownWidget_Fragment)
    | ({
        __typename?: 'GlobalEditorialWithFeature'
      } & AllWidgets_GlobalEditorialWithFeature_Fragment)
    | ({
        __typename?: 'GlobalFooterAccreditationIcons'
      } & AllWidgets_GlobalFooterAccreditationIcons_Fragment)
    | ({
        __typename?: 'GlobalFooterContactUs'
      } & AllWidgets_GlobalFooterContactUs_Fragment)
    | ({
        __typename?: 'GlobalFourBestSellers'
      } & AllWidgets_GlobalFourBestSellers_Fragment)
    | ({
        __typename?: 'GlobalFourButtonLink'
      } & AllWidgets_GlobalFourButtonLink_Fragment)
    | ({
        __typename?: 'GlobalFourItemEditorial'
      } & AllWidgets_GlobalFourItemEditorial_Fragment)
    | ({
        __typename?: 'GlobalGeneralImageBanner'
      } & AllWidgets_GlobalGeneralImageBanner_Fragment)
    | ({
        __typename?: 'GlobalHelpCentreAccordion'
      } & AllWidgets_GlobalHelpCentreAccordion_Fragment)
    | ({
        __typename?: 'GlobalHelpCentreAccordion2'
      } & AllWidgets_GlobalHelpCentreAccordion2_Fragment)
    | ({
        __typename?: 'GlobalHelpCentreAccordionCollection'
      } & AllWidgets_GlobalHelpCentreAccordionCollection_Fragment)
    | ({
        __typename?: 'GlobalHelpCentreCollection'
      } & AllWidgets_GlobalHelpCentreCollection_Fragment)
    | ({
        __typename?: 'GlobalHeroCTABanner'
      } & AllWidgets_GlobalHeroCtaBanner_Fragment)
    | ({ __typename?: 'GlobalImageCard' } & AllWidgets_GlobalImageCard_Fragment)
    | ({
        __typename?: 'GlobalImageCardSet'
      } & AllWidgets_GlobalImageCardSet_Fragment)
    | ({
        __typename?: 'GlobalMultiButton'
      } & AllWidgets_GlobalMultiButton_Fragment)
    | ({
        __typename?: 'GlobalPrimaryBanner'
      } & AllWidgets_GlobalPrimaryBanner_Fragment)
    | ({
        __typename?: 'GlobalPrimaryBannerWithList'
      } & AllWidgets_GlobalPrimaryBannerWithList_Fragment)
    | ({
        __typename?: 'GlobalPrimaryBannerWithTextOverlay'
      } & AllWidgets_GlobalPrimaryBannerWithTextOverlay_Fragment)
    | ({
        __typename?: 'GlobalProductCardScroller'
      } & AllWidgets_GlobalProductCardScroller_Fragment)
    | ({
        __typename?: 'GlobalReferral3StepGuide'
      } & AllWidgets_GlobalReferral3StepGuide_Fragment)
    | ({
        __typename?: 'GlobalScalableLogos'
      } & AllWidgets_GlobalScalableLogos_Fragment)
    | ({
        __typename?: 'GlobalSectionPeek'
      } & AllWidgets_GlobalSectionPeek_Fragment)
    | ({
        __typename?: 'GlobalSetAndromeda'
      } & AllWidgets_GlobalSetAndromeda_Fragment)
    | ({
        __typename?: 'GlobalSimpleTextCTAWidget'
      } & AllWidgets_GlobalSimpleTextCtaWidget_Fragment)
    | ({
        __typename?: 'GlobalSixItemCategories'
      } & AllWidgets_GlobalSixItemCategories_Fragment)
    | ({
        __typename?: 'GlobalSocialIcon'
      } & AllWidgets_GlobalSocialIcon_Fragment)
    | ({
        __typename?: 'GlobalSocialIconCollection'
      } & AllWidgets_GlobalSocialIconCollection_Fragment)
    | ({
        __typename?: 'GlobalSocialIconCollectionv2'
      } & AllWidgets_GlobalSocialIconCollectionv2_Fragment)
    | ({
        __typename?: 'GlobalSocialIconv2'
      } & AllWidgets_GlobalSocialIconv2_Fragment)
    | ({
        __typename?: 'GlobalStripBanner'
      } & AllWidgets_GlobalStripBanner_Fragment)
    | ({
        __typename?: 'GlobalSubscriptionOptions'
      } & AllWidgets_GlobalSubscriptionOptions_Fragment)
    | ({
        __typename?: 'GlobalTabbedWidgetSet'
      } & AllWidgets_GlobalTabbedWidgetSet_Fragment)
    | ({
        __typename?: 'GlobalThreeItemEditorial'
      } & AllWidgets_GlobalThreeItemEditorial_Fragment)
    | ({
        __typename?: 'GlobalThreeItemEditorialSubtitleBG'
      } & AllWidgets_GlobalThreeItemEditorialSubtitleBg_Fragment)
    | ({
        __typename?: 'GlobalTransformationSlider'
      } & AllWidgets_GlobalTransformationSlider_Fragment)
    | ({
        __typename?: 'GlobalTrendingHashtagBlock'
      } & AllWidgets_GlobalTrendingHashtagBlock_Fragment)
    | ({
        __typename?: 'GlobalTrendingHashtagCollection'
      } & AllWidgets_GlobalTrendingHashtagCollection_Fragment)
    | ({
        __typename?: 'GlobalTwoBestSellers'
      } & AllWidgets_GlobalTwoBestSellers_Fragment)
    | ({
        __typename?: 'GlobalTwoItemEditorial'
      } & AllWidgets_GlobalTwoItemEditorial_Fragment)
    | ({
        __typename?: 'GlobalTwoItemImageTextBlock'
      } & AllWidgets_GlobalTwoItemImageTextBlock_Fragment)
    | ({
        __typename?: 'GlobalTwoItemImageTextCTA'
      } & AllWidgets_GlobalTwoItemImageTextCta_Fragment)
    | ({
        __typename?: 'GlobalTwoItemImageTextCTA3070'
      } & AllWidgets_GlobalTwoItemImageTextCta3070_Fragment)
    | ({
        __typename?: 'GlobalUGCCarousel'
      } & AllWidgets_GlobalUgcCarousel_Fragment)
    | ({
        __typename?: 'GlobalVideoGallery'
      } & AllWidgets_GlobalVideoGallery_Fragment)
    | ({
        __typename?: 'GlobalVideoHeroBannerWidget'
      } & AllWidgets_GlobalVideoHeroBannerWidget_Fragment)
    | ({
        __typename?: 'GlobalVideoTextImageCTA'
      } & AllWidgets_GlobalVideoTextImageCta_Fragment)
    | ({
        __typename?: 'GlobalWaitListSignUpFormResponses'
      } & AllWidgets_GlobalWaitListSignUpFormResponses_Fragment)
    | ({
        __typename?: 'GlobalWaitListSignUpWidget'
      } & AllWidgets_GlobalWaitListSignUpWidget_Fragment)
    | ({
        __typename?: 'GlobalWidgetSirius'
      } & AllWidgets_GlobalWidgetSirius_Fragment)
    | ({
        __typename?: 'GlobalWidgetVega'
      } & AllWidgets_GlobalWidgetVega_Fragment)
    | ({
        __typename?: 'GlobalYoutubeGalleryItem'
      } & AllWidgets_GlobalYoutubeGalleryItem_Fragment)
    | ({
        __typename?: 'GlobalYoutubeGallerySet'
      } & AllWidgets_GlobalYoutubeGallerySet_Fragment)
    | ({ __typename?: 'LiveChatWidget' } & AllWidgets_LiveChatWidget_Fragment)
    | ({
        __typename?: 'LoyaltyHubBirthdayGift'
      } & AllWidgets_LoyaltyHubBirthdayGift_Fragment)
    | ({ __typename?: 'LoyaltyHubTier' } & AllWidgets_LoyaltyHubTier_Fragment)
    | ({
        __typename?: 'LoyaltyRewardTier'
      } & AllWidgets_LoyaltyRewardTier_Fragment)
    | ({
        __typename?: 'LoyaltyRewardTiers'
      } & AllWidgets_LoyaltyRewardTiers_Fragment)
    | ({ __typename?: 'MailingList' } & AllWidgets_MailingList_Fragment)
    | ({
        __typename?: 'MultipleCTABanner'
      } & AllWidgets_MultipleCtaBanner_Fragment)
    | ({
        __typename?: 'ProductListWidget'
      } & AllWidgets_ProductListWidget_Fragment)
    | ({
        __typename?: 'ProductRecommenderItem'
      } & AllWidgets_ProductRecommenderItem_Fragment)
    | ({ __typename?: 'ProfileWidget' } & AllWidgets_ProfileWidget_Fragment)
    | ({
        __typename?: 'ResponsiveBuildYourOwnBundle'
      } & AllWidgets_ResponsiveBuildYourOwnBundle_Fragment)
    | ({
        __typename?: 'ResponsiveBuildYourOwnBundleProducts'
      } & AllWidgets_ResponsiveBuildYourOwnBundleProducts_Fragment)
    | ({
        __typename?: 'ResponsiveComparisonTable'
      } & AllWidgets_ResponsiveComparisonTable_Fragment)
    | ({
        __typename?: 'ResponsiveComparisonTableContainer'
      } & AllWidgets_ResponsiveComparisonTableContainer_Fragment)
    | ({
        __typename?: 'ResponsiveProductBlockCarousel'
      } & AllWidgets_ResponsiveProductBlockCarousel_Fragment)
    | ({
        __typename?: 'ResponsiveProductTabs'
      } & AllWidgets_ResponsiveProductTabs_Fragment)
    | ({
        __typename?: 'ResponsiveSetSalon'
      } & AllWidgets_ResponsiveSetSalon_Fragment)
    | ({
        __typename?: 'ResponsiveSetSalonWithSlides'
      } & AllWidgets_ResponsiveSetSalonWithSlides_Fragment)
    | ({
        __typename?: 'ResponsiveSliderSet'
      } & AllWidgets_ResponsiveSliderSet_Fragment)
    | ({
        __typename?: 'ResponsiveSuccessStoriesWidgetContainer'
      } & AllWidgets_ResponsiveSuccessStoriesWidgetContainer_Fragment)
    | ({
        __typename?: 'ResponsiveSuccessStoryWidget'
      } & AllWidgets_ResponsiveSuccessStoryWidget_Fragment)
    | ({
        __typename?: 'ResponsiveTwoItemComparisonContainer'
      } & AllWidgets_ResponsiveTwoItemComparisonContainer_Fragment)
    | ({
        __typename?: 'ResponsiveTwoItemComparisonRow'
      } & AllWidgets_ResponsiveTwoItemComparisonRow_Fragment)
    | ({
        __typename?: 'ResponsiveUSPBar'
      } & AllWidgets_ResponsiveUspBar_Fragment)
    | ({
        __typename?: 'ResponsiveUSPBarCustomisable'
      } & AllWidgets_ResponsiveUspBarCustomisable_Fragment)
    | ({
        __typename?: 'SeoProductCategory'
      } & AllWidgets_SeoProductCategory_Fragment)
    | ({
        __typename?: 'SeoProductCategorySet'
      } & AllWidgets_SeoProductCategorySet_Fragment)
    | ({
        __typename?: 'ShopTheLookHotSpot'
      } & AllWidgets_ShopTheLookHotSpot_Fragment)
    | ({
        __typename?: 'SimpleTextReadMore'
      } & AllWidgets_SimpleTextReadMore_Fragment)
    | ({ __typename?: 'TopBrandsWidget' } & AllWidgets_TopBrandsWidget_Fragment)
    | ({
        __typename?: 'TopProductCategory'
      } & AllWidgets_TopProductCategory_Fragment)
    | ({
        __typename?: 'TopProductCategorySet'
      } & AllWidgets_TopProductCategorySet_Fragment)
    | ({
        __typename?: 'TrustPilotCarouselWidget'
      } & AllWidgets_TrustPilotCarouselWidget_Fragment)
    | ({
        __typename?: 'TrustPilotWidget'
      } & AllWidgets_TrustPilotWidget_Fragment)
    | ({
        __typename?: 'VoucherCodesWidget'
      } & AllWidgets_VoucherCodesWidget_Fragment)
    | ({ __typename?: 'asymmetricGrid' } & AllWidgets_AsymmetricGrid_Fragment)
    | ({
        __typename?: 'buildyourownbundle'
      } & AllWidgets_Buildyourownbundle_Fragment)
    | ({ __typename?: 'buttonLink' } & AllWidgets_ButtonLink_Fragment)
    | ({
        __typename?: 'categoryItemCard'
      } & AllWidgets_CategoryItemCard_Fragment)
    | ({
        __typename?: 'categoryItemCardWithTextOverlay'
      } & AllWidgets_CategoryItemCardWithTextOverlay_Fragment)
    | ({
        __typename?: 'coverageCalculator'
      } & AllWidgets_CoverageCalculator_Fragment)
    | ({
        __typename?: 'eightLinkButtonWidget'
      } & AllWidgets_EightLinkButtonWidget_Fragment)
    | ({
        __typename?: 'fouritemwindowproductslider'
      } & AllWidgets_Fouritemwindowproductslider_Fragment)
    | ({ __typename?: 'generalIframe' } & AllWidgets_GeneralIframe_Fragment)
    | ({
        __typename?: 'generalQuoteBanner'
      } & AllWidgets_GeneralQuoteBanner_Fragment)
    | ({ __typename?: 'imageSelectCard' } & AllWidgets_ImageSelectCard_Fragment)
    | ({
        __typename?: 'imageSelectSlider'
      } & AllWidgets_ImageSelectSlider_Fragment)
    | ({
        __typename?: 'improvedSearchBestSellers'
      } & AllWidgets_ImprovedSearchBestSellers_Fragment)
    | ({ __typename?: 'kitBuilder' } & AllWidgets_KitBuilder_Fragment)
    | ({
        __typename?: 'loyaltyRewardsList'
      } & AllWidgets_LoyaltyRewardsList_Fragment)
    | ({
        __typename?: 'loyaltyWelcomeMessage'
      } & AllWidgets_LoyaltyWelcomeMessage_Fragment)
    | ({
        __typename?: 'multiCategoryCardSet'
      } & AllWidgets_MultiCategoryCardSet_Fragment)
    | ({
        __typename?: 'multiCategoryCardSetV2'
      } & AllWidgets_MultiCategoryCardSetV2_Fragment)
    | ({ __typename?: 'obsessVR' } & AllWidgets_ObsessVr_Fragment)
    | ({ __typename?: 'parcelLabWidget' } & AllWidgets_ParcelLabWidget_Fragment)
    | ({
        __typename?: 'promoproductslider'
      } & AllWidgets_Promoproductslider_Fragment)
    | ({ __typename?: 'propositionBar' } & AllWidgets_PropositionBar_Fragment)
    | ({
        __typename?: 'propositionBarIncorrect'
      } & AllWidgets_PropositionBarIncorrect_Fragment)
    | ({
        __typename?: 'provenanceBrandHeader'
      } & AllWidgets_ProvenanceBrandHeader_Fragment)
    | ({
        __typename?: 'qubitHomePageRecs'
      } & AllWidgets_QubitHomePageRecs_Fragment)
    | ({ __typename?: 'revieveWidget' } & AllWidgets_RevieveWidget_Fragment)
    | ({ __typename?: 'shadeFinder' } & AllWidgets_ShadeFinder_Fragment)
    | ({ __typename?: 'shopTheLook' } & AllWidgets_ShopTheLook_Fragment)
    | ({ __typename?: 'simpleCTA' } & AllWidgets_SimpleCta_Fragment)
    | ({ __typename?: 'simpleDivider' } & AllWidgets_SimpleDivider_Fragment)
    | ({
        __typename?: 'simpleTextWidgetSet'
      } & AllWidgets_SimpleTextWidgetSet_Fragment)
    | ({
        __typename?: 'simpleTitleWidget'
      } & AllWidgets_SimpleTitleWidget_Fragment)
    | ({
        __typename?: 'simpletextwidget'
      } & AllWidgets_Simpletextwidget_Fragment)
    | ({
        __typename?: 'simpletextwidgetwithh1'
      } & AllWidgets_Simpletextwidgetwithh1_Fragment)
    | ({
        __typename?: 'sponsoredProducts'
      } & AllWidgets_SponsoredProducts_Fragment)
    | ({
        __typename?: 'sponsoredProductsCriteoHomepage'
      } & AllWidgets_SponsoredProductsCriteoHomepage_Fragment)
    | ({
        __typename?: 'sponsoredProductsNew'
      } & AllWidgets_SponsoredProductsNew_Fragment)
    | ({
        __typename?: 'storyStreamWidget'
      } & AllWidgets_StoryStreamWidget_Fragment)
    | ({
        __typename?: 'subscribeAndSaveInformationModal'
      } & AllWidgets_SubscribeAndSaveInformationModal_Fragment)
    | ({
        __typename?: 'swatchSelectSlider'
      } & AllWidgets_SwatchSelectSlider_Fragment)
    | ({
        __typename?: 'swatchSelectSliderSet'
      } & AllWidgets_SwatchSelectSliderSet_Fragment)
    | ({
        __typename?: 'trustReinforcementContainer'
      } & AllWidgets_TrustReinforcementContainer_Fragment)
    | ({
        __typename?: 'trustReinforcementSection'
      } & AllWidgets_TrustReinforcementSection_Fragment)
    | ({ __typename?: 'videoEditorial' } & AllWidgets_VideoEditorial_Fragment)
    | ({
        __typename?: 'yotiAgeVerification'
      } & AllWidgets_YotiAgeVerification_Fragment)
    | ({ __typename?: 'yotiCancelFrame' } & AllWidgets_YotiCancelFrame_Fragment)
  >
}

export type MainCustomerQueryVariables = Exact<{
  enableWishlists?: Scalars['Boolean']
  enableLoyaltyAccountDashboard?: Scalars['Boolean']
}>

export type MainCustomerQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & Pick<Customer, 'fullName' | 'email'> & {
        emailMarketingPreference: Customer['marketingPreferences']
        smsMarketingPreference: Customer['marketingPreferences']
        loyaltyAccountDashboard?: Maybe<
          { __typename?: 'LoyaltyAccountDashboard' } & {
            summary?: {
              pointsToNextTier?: number
            }
          }
        >
      } & {
        wishlist?: Maybe<
          { __typename?: 'WishlistItems' } & Pick<WishlistItems, 'total'> & {
              items: Array<
                { __typename?: 'WishlistItem' } & {
                  selectedVariant?: Maybe<
                    { __typename?: 'ProductVariant' } & Pick<
                      ProductVariant,
                      'sku'
                    >
                  >
                  product: { __typename?: 'Product' } & Pick<Product, 'sku'>
                }
              >
            }
        >
      }
  >
}

export type CreditAccountsQueryVariables = Exact<{ [key: string]: never }>

export type CreditAccountsQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      creditAccounts?: Maybe<
        Array<
          { __typename?: 'CreditAccount' } & Pick<CreditAccount, 'currency'> & {
              balance: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue'
              >
              expiringIn: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue'
              >
              actions: { __typename?: 'CreditActions' } & {
                actions: Array<
                  { __typename?: 'CreditAction' } & Pick<
                    CreditAction,
                    'type' | 'status' | 'message' | 'addedAt' | 'expiresAt'
                  > & {
                      amount: { __typename?: 'MoneyValue' } & Pick<
                        MoneyValue,
                        'displayValue'
                      >
                      amountUsed: { __typename?: 'MoneyValue' } & Pick<
                        MoneyValue,
                        'displayValue'
                      >
                      amountAvailable: { __typename?: 'MoneyValue' } & Pick<
                        MoneyValue,
                        'displayValue'
                      >
                      order?: Maybe<
                        { __typename?: 'Order' } & Pick<Order, 'orderNumber'>
                      >
                    }
                >
              }
            }
        >
      >
    }
  >
}

export type FullNameQueryVariables = Exact<{ [key: string]: never }>

export type FullNameQuery = { __typename?: 'Query' } & {
  customer?: Maybe<{ __typename?: 'Customer' } & Pick<Customer, 'fullName'>>
}

export type OrdersQueryVariables = Exact<{
  offset: Scalars['Int']
  limit: Scalars['Int']
  filter?: Maybe<OrdersFilterInput>
  clickAndCollectEnabled?: Maybe<Scalars['Boolean']>
}>

export type OrdersQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      orders?: Maybe<
        { __typename?: 'Orders' } & Pick<Orders, 'hasMore' | 'total'> & {
            orders: Array<
              { __typename?: 'Order' } & Pick<
                Order,
                | 'returnUrl'
                | 'status'
                | 'orderNumber'
                | 'paymentType'
                | 'createdAt'
              > & {
                  isReturnable?: Maybe<
                    { __typename?: 'ReturnsEligibilityResult' } & Pick<
                      ReturnsEligibilityResult,
                      'success'
                    >
                  >
                  totalCost: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'amount'
                  >
                  deliveryCost?: Maybe<
                    { __typename?: 'MoneyValue' } & Pick<
                      MoneyValue,
                      'currency' | 'amount' | 'displayValue'
                    >
                  >
                  discounts?: Maybe<
                    Array<
                      { __typename?: 'OrderDiscount' } & Pick<
                        OrderDiscount,
                        'message'
                      > & {
                          amount: { __typename?: 'MoneyValue' } & Pick<
                            MoneyValue,
                            'currency' | 'amount' | 'displayValue'
                          >
                        }
                    >
                  >
                  products: Array<
                    { __typename?: 'OrderProduct' } & MakeOptional<
                      Pick<
                        OrderProduct,
                        | 'sku'
                        | 'dispatchDate'
                        | 'pendingCancelQuantity'
                        | 'status'
                        | 'quantity'
                        | 'deliveryMethod'
                        | 'trackingUrls'
                        | 'fulfilmentMethod'
                      >,
                      'fulfilmentMethod'
                    > & {
                        deliveryDateRange?: Maybe<
                          { __typename?: 'DateRange' } & Pick<
                            DateRange,
                            'from' | 'to'
                          >
                        >
                        productVariant?: Maybe<
                          { __typename?: 'ProductVariant' } & Pick<
                            ProductVariant,
                            'externalIdentifier' | 'title'
                          > & {
                              images: Array<
                                { __typename?: 'ProductImage' } & Pick<
                                  ProductImage,
                                  'thumbnail' | 'largeProduct'
                                >
                              >
                              product?: Maybe<
                                { __typename?: 'Product' } & Pick<
                                  Product,
                                  'url'
                                > & {
                                    images: Array<
                                      { __typename?: 'ProductImage' } & Pick<
                                        ProductImage,
                                        'thumbnail' | 'largeProduct'
                                      >
                                    >
                                  }
                              >
                            }
                        >
                        costPerUnit?: Maybe<
                          { __typename?: 'MoneyValue' } & Pick<
                            MoneyValue,
                            'displayValue' | 'amount' | 'currency'
                          >
                        >
                        store?: Maybe<
                          { __typename?: 'Store' } & Pick<
                            Store,
                            'displayName'
                          > & {
                              address: { __typename?: 'Address' } & Pick<
                                Address,
                                'postalCode'
                              >
                            }
                        >
                      }
                  >
                  deliveryAddress?: Maybe<
                    { __typename?: 'Address' } & Pick<
                      Address,
                      | 'country'
                      | 'addresseeName'
                      | 'addressLine1'
                      | 'addressLine2'
                      | 'addressLine3'
                      | 'addressLine4'
                      | 'addressLine5'
                      | 'companyName'
                      | 'state'
                      | 'postalCode'
                    >
                  >
                  paymentCard?: Maybe<
                    { __typename?: 'PaymentCard' } & Pick<
                      PaymentCard,
                      | 'nameOnCard'
                      | 'obfuscatedCardNumber'
                      | 'validFromMonth'
                      | 'validFromYear'
                      | 'validToMonth'
                      | 'validToYear'
                      | 'issueNumber'
                      | 'type'
                    >
                  >
                  usedPaymentMethods: Array<
                    { __typename?: 'UsedPaymentMethod' } & Pick<
                      UsedPaymentMethod,
                      'paymentType'
                    > & {
                        paymentCard?: Maybe<
                          { __typename?: 'PaymentCard' } & Pick<
                            PaymentCard,
                            | 'nameOnCard'
                            | 'obfuscatedCardNumber'
                            | 'validFromMonth'
                            | 'validFromYear'
                            | 'validToMonth'
                            | 'validToYear'
                            | 'issueNumber'
                            | 'type'
                          >
                        >
                        giftCard?: Maybe<
                          { __typename?: 'GiftCard' } & Pick<
                            GiftCard,
                            'cardUuid' | 'obfuscatedCardNumber'
                          >
                        >
                        amountSpent: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'displayValue' | 'amount'
                        >
                      }
                  >
                }
            >
          }
      >
    }
  >
}

export type OrdersSpotlightQueryVariables = Exact<{
  offset: Scalars['Int']
  limit: Scalars['Int']
  filter?: Maybe<OrdersFilterInput>
}>

export type OrdersSpotlightQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      orders?: Maybe<
        { __typename?: 'Orders' } & Pick<Orders, 'total' | 'hasMore'> & {
            orders: Array<
              { __typename?: 'Order' } & Pick<
                Order,
                'status' | 'orderNumber' | 'createdAt'
              > & {
                  totalCost: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue'
                  >
                  products: Array<
                    { __typename?: 'OrderProduct' } & Pick<
                      OrderProduct,
                      'dispatchDate'
                    > & {
                        deliveryDateRange?: Maybe<
                          { __typename?: 'DateRange' } & Pick<
                            DateRange,
                            'from' | 'to'
                          >
                        >
                        productVariant?: Maybe<
                          { __typename?: 'ProductVariant' } & Pick<
                            ProductVariant,
                            'title'
                          > & {
                              images: Array<
                                { __typename?: 'ProductImage' } & Pick<
                                  ProductImage,
                                  'largeProduct'
                                >
                              >
                              product?: Maybe<
                                { __typename?: 'Product' } & {
                                  images: Array<
                                    { __typename?: 'ProductImage' } & Pick<
                                      ProductImage,
                                      'largeProduct'
                                    >
                                  >
                                }
                              >
                            }
                        >
                      }
                  >
                }
            >
          }
      >
    }
  >
}

export type ReferralCountQueryVariables = Exact<{ [key: string]: never }>

export type ReferralCountQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & Pick<Customer, 'referralCount'>
  >
}

export type ReferralsQueryVariables = Exact<{
  currency: Currency
}>

export type ReferralsQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & Pick<
      Customer,
      'fullName' | 'referralCode' | 'referralCount' | 'referralLink'
    > & {
        socialReferralMethods: Array<
          { __typename?: 'SocialReferralMethod' } & Pick<
            SocialReferralMethod,
            'type' | 'url'
          >
        >
        creditAccounts?: Maybe<
          Array<
            { __typename?: 'CreditAccount' } & Pick<
              CreditAccount,
              'currency'
            > & {
                balance: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'displayValue'
                >
              }
          >
        >
      }
  >
}

export type SubscriptionEditQueryVariables = Exact<{
  subscriptionId: Scalars['ID']
  currency: Currency
  shippingDestination: Country
  subscribeAndSaveEnabled?: Scalars['Boolean']
}>

export type SubscriptionEditQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      subscriptions?: Maybe<
        { __typename?: 'Subscriptions' } & {
          subscriptions: Array<
            { __typename?: 'ProductSubscription' } & Pick<
              ProductSubscription,
              | 'id'
              | 'nextBillingDate'
              | 'nextGiftProductId'
              | 'productSwapAllowed'
              | 'productRemovalAllowed'
            > & {
                availableFrequencyChanges?: Maybe<
                  Array<
                    { __typename?: 'SubscriptionFrequencyDuration' } & Pick<
                      SubscriptionFrequencyDuration,
                      'unit' | 'duration'
                    >
                  >
                >
                schedule: { __typename?: 'SubscriptionSchedule' } & {
                  dispatchFrequencyDuration: {
                    __typename?: 'SubscriptionFrequencyDuration'
                  } & Pick<SubscriptionFrequencyDuration, 'unit' | 'duration'>
                }
                childOrders?: Maybe<
                  Array<{ __typename?: 'Order' } & Pick<Order, 'orderNumber'>>
                >
                subscriptionItems: Array<
                  { __typename?: 'SubscriptionItem' } & Pick<
                    SubscriptionItem,
                    'quantity'
                  > & {
                      product: { __typename?: 'Product' } & Pick<
                        Product,
                        'sku' | 'title' | 'url'
                      > & {
                          images: Array<
                            { __typename?: 'ProductImage' } & Pick<
                              ProductImage,
                              'largeProduct'
                            >
                          >
                          variants: Array<
                            { __typename?: 'ProductVariant' } & Pick<
                              ProductVariant,
                              | 'sku'
                              | 'title'
                              | 'availabilityMessage'
                              | 'inStock'
                            > & {
                                subscriptionContracts: Array<
                                  {
                                    __typename?: 'SubscriptionContract'
                                  } & Pick<SubscriptionContract, 'id'> & {
                                      frequencyDuration: {
                                        __typename?: 'SubscriptionFrequencyDuration'
                                      } & Pick<
                                        SubscriptionFrequencyDuration,
                                        'unit' | 'duration'
                                      >
                                      initialPrice?: Maybe<
                                        { __typename?: 'ProductPrice' } & {
                                          price: {
                                            __typename?: 'MoneyValue'
                                          } & Pick<
                                            MoneyValue,
                                            | 'displayValue'
                                            | 'scalarValue'
                                            | 'amount'
                                          >
                                          rrp: {
                                            __typename?: 'MoneyValue'
                                          } & Pick<
                                            MoneyValue,
                                            | 'displayValue'
                                            | 'scalarValue'
                                            | 'amount'
                                          >
                                        }
                                      >
                                      recurringPrice?: Maybe<
                                        { __typename?: 'ProductPrice' } & {
                                          price: {
                                            __typename?: 'MoneyValue'
                                          } & Pick<
                                            MoneyValue,
                                            | 'displayValue'
                                            | 'scalarValue'
                                            | 'amount'
                                          >
                                          rrp: {
                                            __typename?: 'MoneyValue'
                                          } & Pick<
                                            MoneyValue,
                                            | 'displayValue'
                                            | 'scalarValue'
                                            | 'amount'
                                          >
                                        }
                                      >
                                    }
                                >
                                images: Array<
                                  { __typename?: 'ProductImage' } & Pick<
                                    ProductImage,
                                    'largeProduct'
                                  >
                                >
                                choices: Array<
                                  { __typename?: 'OptionChoice' } & Pick<
                                    OptionChoice,
                                    'key' | 'optionKey' | 'title'
                                  >
                                >
                                price?: Maybe<
                                  { __typename?: 'ProductPrice' } & {
                                    price: { __typename?: 'MoneyValue' } & Pick<
                                      MoneyValue,
                                      'displayValue' | 'scalarValue' | 'amount'
                                    >
                                    rrp: { __typename?: 'MoneyValue' } & Pick<
                                      MoneyValue,
                                      'displayValue' | 'scalarValue' | 'amount'
                                    >
                                  }
                                >
                              }
                          >
                          options: Array<
                            { __typename?: 'VariantOption' } & Pick<
                              VariantOption,
                              'key'
                            > & {
                                choices: Array<
                                  { __typename?: 'OptionChoice' } & Pick<
                                    OptionChoice,
                                    'optionKey' | 'title' | 'key'
                                  >
                                >
                              }
                          >
                        }
                      selectedVariant?: Maybe<
                        { __typename?: 'ProductVariant' } & Pick<
                          ProductVariant,
                          'sku' | 'title' | 'maxPerOrder' | 'inStock'
                        > & {
                            subscriptionContracts?: Maybe<
                              Array<
                                { __typename?: 'SubscriptionContract' } & {
                                  frequencyDuration: {
                                    __typename?: 'SubscriptionFrequencyDuration'
                                  } & Pick<
                                    SubscriptionFrequencyDuration,
                                    'unit' | 'duration'
                                  >
                                  initialPrice?: Maybe<
                                    { __typename?: 'ProductPrice' } & {
                                      price: {
                                        __typename?: 'MoneyValue'
                                      } & Pick<
                                        MoneyValue,
                                        'displayValue' | 'scalarValue'
                                      >
                                      rrp: { __typename?: 'MoneyValue' } & Pick<
                                        MoneyValue,
                                        'displayValue' | 'scalarValue'
                                      >
                                    }
                                  >
                                  recurringPrice?: Maybe<
                                    { __typename?: 'ProductPrice' } & {
                                      price: {
                                        __typename?: 'MoneyValue'
                                      } & Pick<
                                        MoneyValue,
                                        'displayValue' | 'scalarValue'
                                      >
                                      rrp: { __typename?: 'MoneyValue' } & Pick<
                                        MoneyValue,
                                        'displayValue' | 'scalarValue'
                                      >
                                    }
                                  >
                                }
                              >
                            >
                            price?: Maybe<
                              { __typename?: 'ProductPrice' } & {
                                price: { __typename?: 'MoneyValue' } & Pick<
                                  MoneyValue,
                                  'displayValue' | 'scalarValue'
                                >
                                rrp: { __typename?: 'MoneyValue' } & Pick<
                                  MoneyValue,
                                  'displayValue' | 'scalarValue'
                                >
                              }
                            >
                            choices: Array<
                              { __typename?: 'OptionChoice' } & Pick<
                                OptionChoice,
                                'key' | 'optionKey' | 'title'
                              >
                            >
                            images: Array<
                              { __typename?: 'ProductImage' } & Pick<
                                ProductImage,
                                'largeProduct'
                              >
                            >
                          }
                      >
                    }
                >
              }
          >
        }
      >
    }
  >
}

export type SubscriptionOrdersQueryVariables = Exact<{
  filter: SubscriptionsFilterInput
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  startDate?: Maybe<Scalars['Timestamp']>
  endDate?: Maybe<Scalars['Timestamp']>
}>

export type SubscriptionOrdersQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      subscriptions?: Maybe<
        { __typename?: 'Subscriptions' } & {
          subscriptions: Array<
            { __typename?: 'ProductSubscription' } & {
              orders?: Maybe<
                { __typename?: 'Orders' } & Pick<
                  Orders,
                  'hasMore' | 'total'
                > & {
                    orders: Array<
                      { __typename?: 'Order' } & Pick<
                        Order,
                        'orderNumber' | 'createdAt' | 'dispatchedAt' | 'status'
                      > & {
                          totalCost: { __typename?: 'MoneyValue' } & Pick<
                            MoneyValue,
                            'displayValue'
                          >
                          products: Array<
                            { __typename?: 'OrderProduct' } & {
                              productVariant?: Maybe<
                                { __typename?: 'ProductVariant' } & Pick<
                                  ProductVariant,
                                  'title'
                                > & {
                                    images: Array<
                                      { __typename?: 'ProductImage' } & Pick<
                                        ProductImage,
                                        'thumbnail'
                                      >
                                    >
                                    product?: Maybe<
                                      { __typename?: 'Product' } & {
                                        images: Array<
                                          {
                                            __typename?: 'ProductImage'
                                          } & Pick<ProductImage, 'thumbnail'>
                                        >
                                      }
                                    >
                                  }
                              >
                            }
                          >
                        }
                    >
                  }
              >
            }
          >
        }
      >
    }
  >
}

export type SubscriptionProductBlockQueryVariables = Exact<{
  subscriptionId: Scalars['ID']
  subscriptionDelaysEnabled?: Scalars['Boolean']
  subscribeAndSaveEnabled?: Scalars['Boolean']
  currency: Currency
  shippingDestination: Country
}>

export type SubscriptionProductBlockQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      subscriptions?: Maybe<
        { __typename?: 'Subscriptions' } & {
          subscriptions: Array<
            { __typename?: 'ProductSubscription' } & MakeOptional<
              Pick<
                ProductSubscription,
                | 'cancellable'
                | 'delayable'
                | 'delayCancellable'
                | 'delayCount'
                | 'productSwapAllowed'
                | 'nextBillingDate'
                | 'status'
              >,
              'delayable' | 'delayCancellable' | 'delayCount'
            > & {
                schedule: { __typename?: 'SubscriptionSchedule' } & Pick<
                  SubscriptionSchedule,
                  'dispatchFrequency'
                > & {
                    dispatchFrequencyDuration: {
                      __typename?: 'SubscriptionFrequencyDuration'
                    } & Pick<SubscriptionFrequencyDuration, 'unit' | 'duration'>
                  }
                availableAddOnProducts: Array<
                  { __typename?: 'ProductVariant' } & Pick<
                    ProductVariant,
                    'sku' | 'title' | 'inStock'
                  > & {
                      product?: Maybe<
                        { __typename?: 'Product' } & {
                          images: Array<
                            { __typename?: 'ProductImage' } & Pick<
                              ProductImage,
                              'largeProduct'
                            >
                          >
                        }
                      >
                      images: Array<
                        { __typename?: 'ProductImage' } & Pick<
                          ProductImage,
                          'largeProduct'
                        >
                      >
                      subscriptionContracts?: Maybe<
                        Array<
                          { __typename?: 'SubscriptionContract' } & {
                            frequencyDuration: {
                              __typename?: 'SubscriptionFrequencyDuration'
                            } & Pick<
                              SubscriptionFrequencyDuration,
                              'unit' | 'duration'
                            >
                            initialPrice?: Maybe<
                              { __typename?: 'ProductPrice' } & {
                                price: { __typename?: 'MoneyValue' } & Pick<
                                  MoneyValue,
                                  'displayValue' | 'scalarValue'
                                >
                                rrp: { __typename?: 'MoneyValue' } & Pick<
                                  MoneyValue,
                                  'displayValue' | 'scalarValue'
                                >
                              }
                            >
                          }
                        >
                      >
                      price?: Maybe<
                        { __typename?: 'ProductPrice' } & {
                          price: { __typename?: 'MoneyValue' } & Pick<
                            MoneyValue,
                            'displayValue' | 'scalarValue' | 'amount'
                          >
                          rrp: { __typename?: 'MoneyValue' } & Pick<
                            MoneyValue,
                            'displayValue' | 'scalarValue' | 'amount'
                          >
                        }
                      >
                      choices: Array<
                        { __typename?: 'OptionChoice' } & Pick<
                          OptionChoice,
                          'key' | 'optionKey' | 'title'
                        >
                      >
                    }
                >
                availableBillingDateChanges?: Maybe<
                  Array<
                    { __typename?: 'DateRange' } & Pick<
                      DateRange,
                      'to' | 'from'
                    >
                  >
                >
                subscriptionItems: Array<
                  { __typename?: 'SubscriptionItem' } & Pick<
                    SubscriptionItem,
                    'quantity'
                  > & {
                      selectedVariant?: Maybe<
                        { __typename?: 'ProductVariant' } & Pick<
                          ProductVariant,
                          'inStock' | 'title'
                        > & {
                            images: Array<
                              { __typename?: 'ProductImage' } & Pick<
                                ProductImage,
                                'largeProduct'
                              >
                            >
                          }
                      >
                      product: { __typename?: 'Product' } & Pick<
                        Product,
                        'url'
                      > & {
                          images: Array<
                            { __typename?: 'ProductImage' } & Pick<
                              ProductImage,
                              'largeProduct'
                            >
                          >
                        }
                    }
                >
                costPerPaymentPeriod: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'displayValue'
                >
                order?: Maybe<
                  { __typename?: 'Order' } & Pick<Order, 'orderNumber'>
                >
              }
          >
        }
      >
    }
  >
}

export type SubscriptionsQueryVariables = Exact<{
  offset: Scalars['Int']
  limit: Scalars['Int']
  currency: Currency
  shippingDestination: Country
  filter?: Maybe<SubscriptionsFilterInput>
  subscriptionDelaysEnabled?: Scalars['Boolean']
  subscribeAndSaveEnabled?: Scalars['Boolean']
}>

export type SubscriptionsQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      subscriptions?: Maybe<
        { __typename?: 'Subscriptions' } & Pick<
          Subscriptions,
          'hasMore' | 'total'
        > & {
            subscriptions: Array<
              { __typename?: 'ProductSubscription' } & MakeOptional<
                Pick<
                  ProductSubscription,
                  | 'id'
                  | 'status'
                  | 'createdAt'
                  | 'cancellable'
                  | 'nextBillingDate'
                  | 'productSwapAllowed'
                  | 'productAddOnAllowed'
                  | 'productRemovalAllowed'
                  | 'nextGiftProductId'
                  | 'delayable'
                  | 'delayCount'
                  | 'delayCancellable'
                >,
                'delayable' | 'delayCount' | 'delayCancellable'
              > & {
                  availableFrequencyChanges?: Maybe<
                    Array<
                      { __typename?: 'SubscriptionFrequencyDuration' } & Pick<
                        SubscriptionFrequencyDuration,
                        'unit' | 'duration'
                      >
                    >
                  >
                  availableAddOnProducts: Array<
                    { __typename?: 'ProductVariant' } & Pick<
                      ProductVariant,
                      'sku'
                    >
                  >
                  availableBillingDateChanges?: Maybe<
                    Array<
                      { __typename?: 'DateRange' } & Pick<
                        DateRange,
                        'to' | 'from'
                      >
                    >
                  >
                  subscriptionItems: Array<
                    { __typename?: 'SubscriptionItem' } & Pick<
                      SubscriptionItem,
                      'quantity'
                    > & {
                        selectedVariant?: Maybe<
                          { __typename?: 'ProductVariant' } & Pick<
                            ProductVariant,
                            'sku' | 'title'
                          > & {
                              subscriptionContracts?: Maybe<
                                Array<
                                  { __typename?: 'SubscriptionContract' } & {
                                    frequencyDuration: {
                                      __typename?: 'SubscriptionFrequencyDuration'
                                    } & Pick<
                                      SubscriptionFrequencyDuration,
                                      'duration' | 'unit'
                                    >
                                    initialPrice?: Maybe<
                                      { __typename?: 'ProductPrice' } & {
                                        price: {
                                          __typename?: 'MoneyValue'
                                        } & Pick<MoneyValue, 'displayValue'>
                                      }
                                    >
                                    recurringPrice?: Maybe<
                                      { __typename?: 'ProductPrice' } & {
                                        price: {
                                          __typename?: 'MoneyValue'
                                        } & Pick<MoneyValue, 'displayValue'>
                                      }
                                    >
                                  }
                                >
                              >
                              price?: Maybe<
                                { __typename?: 'ProductPrice' } & {
                                  price: { __typename?: 'MoneyValue' } & Pick<
                                    MoneyValue,
                                    | 'currency'
                                    | 'amount'
                                    | 'displayValue'
                                    | 'scalarValue'
                                  >
                                  rrp: { __typename?: 'MoneyValue' } & Pick<
                                    MoneyValue,
                                    | 'currency'
                                    | 'amount'
                                    | 'displayValue'
                                    | 'scalarValue'
                                  >
                                }
                              >
                              choices: Array<
                                { __typename?: 'OptionChoice' } & Pick<
                                  OptionChoice,
                                  'optionKey' | 'title' | 'key'
                                >
                              >
                              images: Array<
                                { __typename?: 'ProductImage' } & Pick<
                                  ProductImage,
                                  'thumbnail' | 'largeProduct'
                                >
                              >
                            }
                        >
                        product: { __typename?: 'Product' } & Pick<
                          Product,
                          'sku' | 'title' | 'url'
                        > & {
                            variants: Array<
                              { __typename?: 'ProductVariant' } & Pick<
                                ProductVariant,
                                | 'sku'
                                | 'title'
                                | 'availabilityMessage'
                                | 'inStock'
                              > & {
                                  images: Array<
                                    { __typename?: 'ProductImage' } & Pick<
                                      ProductImage,
                                      'largeProduct'
                                    >
                                  >
                                  choices: Array<
                                    { __typename?: 'OptionChoice' } & Pick<
                                      OptionChoice,
                                      'optionKey' | 'key' | 'title'
                                    >
                                  >
                                  price?: Maybe<
                                    { __typename?: 'ProductPrice' } & {
                                      price: {
                                        __typename?: 'MoneyValue'
                                      } & Pick<
                                        MoneyValue,
                                        'displayValue' | 'scalarValue'
                                      >
                                      rrp: { __typename?: 'MoneyValue' } & Pick<
                                        MoneyValue,
                                        'displayValue' | 'scalarValue'
                                      >
                                    }
                                  >
                                }
                            >
                            images: Array<
                              { __typename?: 'ProductImage' } & Pick<
                                ProductImage,
                                'thumbnail' | 'largeProduct'
                              >
                            >
                            cheapestVariantPrice?: Maybe<
                              { __typename?: 'ProductPrice' } & {
                                price: { __typename?: 'MoneyValue' } & Pick<
                                  MoneyValue,
                                  'displayValue'
                                >
                                rrp: { __typename?: 'MoneyValue' } & Pick<
                                  MoneyValue,
                                  'displayValue'
                                >
                              }
                            >
                            options: Array<
                              { __typename?: 'VariantOption' } & Pick<
                                VariantOption,
                                'key'
                              > & {
                                  choices: Array<
                                    { __typename?: 'OptionChoice' } & Pick<
                                      OptionChoice,
                                      'optionKey' | 'key' | 'title'
                                    >
                                  >
                                }
                            >
                          }
                      }
                  >
                  schedule: { __typename?: 'SubscriptionSchedule' } & Pick<
                    SubscriptionSchedule,
                    | 'dispatchFrequency'
                    | 'paymentFrequency'
                    | 'autoRenew'
                    | 'paymentType'
                    | 'totalDeliveries'
                  > & {
                      dispatchFrequencyDuration: {
                        __typename?: 'SubscriptionFrequencyDuration'
                      } & Pick<
                        SubscriptionFrequencyDuration,
                        'duration' | 'unit'
                      >
                    }
                  paymentCard?: Maybe<
                    { __typename?: 'PaymentCard' } & Pick<
                      PaymentCard,
                      | 'nameOnCard'
                      | 'obfuscatedCardNumber'
                      | 'validToYear'
                      | 'validToMonth'
                      | 'type'
                    >
                  >
                  costPerPaymentPeriod: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'amount' | 'currency'
                  >
                  childOrders?: Maybe<
                    Array<
                      { __typename?: 'Order' } & Pick<
                        Order,
                        'orderNumber' | 'createdAt' | 'dispatchedAt' | 'status'
                      > & {
                          totalCost: { __typename?: 'MoneyValue' } & Pick<
                            MoneyValue,
                            'displayValue'
                          >
                          products: Array<
                            { __typename?: 'OrderProduct' } & {
                              productVariant?: Maybe<
                                { __typename?: 'ProductVariant' } & Pick<
                                  ProductVariant,
                                  'title'
                                > & {
                                    images: Array<
                                      { __typename?: 'ProductImage' } & Pick<
                                        ProductImage,
                                        'thumbnail'
                                      >
                                    >
                                    product?: Maybe<
                                      { __typename?: 'Product' } & {
                                        images: Array<
                                          {
                                            __typename?: 'ProductImage'
                                          } & Pick<ProductImage, 'thumbnail'>
                                        >
                                      }
                                    >
                                  }
                              >
                            }
                          >
                        }
                    >
                  >
                  deliveryAddress?: Maybe<
                    { __typename?: 'Address' } & Pick<
                      Address,
                      | 'country'
                      | 'addresseeName'
                      | 'addressLine1'
                      | 'addressLine2'
                      | 'addressLine3'
                      | 'addressLine4'
                      | 'addressLine5'
                      | 'companyName'
                      | 'state'
                      | 'postalCode'
                    >
                  >
                  order?: Maybe<
                    { __typename?: 'Order' } & Pick<
                      Order,
                      'orderNumber' | 'createdAt' | 'status' | 'totalQuantity'
                    > & {
                        deliveryCost?: Maybe<
                          { __typename?: 'MoneyValue' } & Pick<
                            MoneyValue,
                            'amount'
                          >
                        >
                      }
                  >
                }
            >
          }
      >
    }
  >
}

export type WishlistCountQueryVariables = Exact<{
  currency: Currency
  shippingDestination: Country
}>

export type WishlistCountQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      wishlist?: Maybe<
        { __typename?: 'WishlistItems' } & Pick<WishlistItems, 'total'>
      >
    }
  >
}

export type InstantSearchQueryVariables = Exact<{
  query: Scalars['String']
  currency: Currency
  shippingDestination: Country
  limit: Scalars['Int']
  concessionCode?: Maybe<Scalars['String']>
  enableRecentSearches?: Maybe<Scalars['Boolean']>
}>

export type InstantSearchQuery = { __typename?: 'Query' } & {
  instantSearch?: Maybe<
    { __typename?: 'InstantSearchResult' } & Pick<
      InstantSearchResult,
      'suggestedSearchQueries'
    > & {
        products: Array<
          { __typename?: 'Product' } & Pick<
            Product,
            'sku' | 'title' | 'url'
          > & {
              reviews?: Maybe<
                { __typename?: 'ProductReviews' } & Pick<
                  ProductReviews,
                  'total' | 'averageScore'
                >
              >
              images: Array<
                { __typename?: 'ProductImage' } & Pick<
                  ProductImage,
                  'thumbnail' | 'largeProduct' | 'zoom'
                >
              >
              variants: Array<
                { __typename?: 'ProductVariant' } & {
                  price?: Maybe<
                    { __typename?: 'ProductPrice' } & {
                      price: { __typename?: 'MoneyValue' } & Pick<
                        MoneyValue,
                        'displayValue' | 'amount'
                      >
                      rrp: { __typename?: 'MoneyValue' } & Pick<
                        MoneyValue,
                        'displayValue' | 'amount'
                      >
                    }
                  >
                }
              >
            }
        >
        corrections: Array<
          { __typename?: 'InstantSearchCorrection' } & Pick<
            InstantSearchCorrection,
            'correction' | 'highlightedSearchCorrection'
          >
        >
      }
  >
}

export type FooterNavigationFragment = { __typename?: 'Footer' } & {
  navigation?: Maybe<
    { __typename?: 'Navigation' } & {
      topLevel: Array<
        { __typename?: 'NavigationItem' } & Pick<
          NavigationItem,
          'type' | 'displayName' | 'iconName'
        > & {
            link?: Maybe<
              { __typename?: 'Hyperlink' } & Pick<Hyperlink, 'url' | 'text'>
            >
            subNavigation?: Maybe<
              Array<
                { __typename?: 'NavigationItem' } & Pick<
                  NavigationItem,
                  'type' | 'displayName' | 'iconName'
                > & {
                    link?: Maybe<
                      { __typename?: 'Hyperlink' } & Pick<
                        Hyperlink,
                        'url' | 'text'
                      >
                    >
                  }
              >
            >
          }
      >
    }
  >
}

export type FooterQueryVariables = Exact<{
  code: Scalars['String']
  concessionEnabled?: Scalars['Boolean']
}>

export type FooterQuery = { __typename?: 'Query' } & {
  footer?: Maybe<{ __typename?: 'Footer' } & FooterNavigationFragment>
  concession?: Maybe<
    { __typename?: 'Concession' } & {
      footer: { __typename?: 'Footer' } & FooterNavigationFragment
    }
  >
}

export type FooterWidgetsQueryVariables = Exact<{ [key: string]: never }>

export type FooterWidgetsQuery = { __typename?: 'Query' } & {
  footer?: Maybe<
    { __typename?: 'Footer' } & {
      widgets?: Maybe<
        Array<
          | ({ __typename: 'AccordionWidget' } & Pick<AccordionWidget, 'id'>)
          | ({ __typename: 'AccordionWidgetContainer' } & Pick<
              AccordionWidgetContainer,
              'id'
            >)
          | ({ __typename: 'BMICalculator' } & Pick<BmiCalculator, 'id'>)
          | ({ __typename: 'BMICalculatorV2' } & Pick<BmiCalculatorV2, 'id'>)
          | ({ __typename: 'BrandsPageWidget' } & Pick<BrandsPageWidget, 'id'>)
          | ({ __typename: 'BuildYourOwnBundleProductList' } & Pick<
              BuildYourOwnBundleProductList,
              'id'
            >)
          | ({ __typename: 'CriteoSponsoredBannerAds' } & Pick<
              CriteoSponsoredBannerAds,
              'id'
            >)
          | ({ __typename: 'CriteoSponsoredBannerAdsFlagship' } & Pick<
              CriteoSponsoredBannerAdsFlagship,
              'id'
            >)
          | ({ __typename: 'DeliveryInfoWidget' } & Pick<
              DeliveryInfoWidget,
              'id'
            >)
          | ({ __typename: 'DeliveryInformationWidget' } & Pick<
              DeliveryInformationWidget,
              'id'
            >)
          | ({ __typename: 'DynamicReferralWidget' } & Pick<
              DynamicReferralWidget,
              'id'
            >)
          | ({ __typename: 'Easiware' } & Pick<Easiware, 'id'>)
          | ({ __typename: 'EditorialWidget' } & Pick<EditorialWidget, 'id'>)
          | ({ __typename: 'EmailReEngagementModal' } & Pick<
              EmailReEngagementModal,
              'id'
            >)
          | ({ __typename: 'FastTrackBanner' } & Pick<FastTrackBanner, 'id'>)
          | ({ __typename: 'FoundationFinderLandingPageWidget' } & Pick<
              FoundationFinderLandingPageWidget,
              'id'
            >)
          | ({ __typename: 'GlobalAboutUsVideo' } & Pick<
              GlobalAboutUsVideo,
              'id'
            >)
          | ({ __typename: 'GlobalAccreditationIcon' } & Pick<
              GlobalAccreditationIcon,
              'id'
            >)
          | ({ __typename: 'GlobalAccreditationIconCollection' } & Pick<
              GlobalAccreditationIconCollection,
              'id'
            >)
          | ({ __typename: 'GlobalBrandLogos' } & Pick<GlobalBrandLogos, 'id'>)
          | ({ __typename: 'GlobalBuyingRightNow' } & Pick<
              GlobalBuyingRightNow,
              'id'
            >)
          | ({ __typename: 'GlobalBuyingRightNowManualProductURL' } & Pick<
              GlobalBuyingRightNowManualProductUrl,
              'id'
            >)
          | ({ __typename: 'GlobalCardScrollerCard' } & Pick<
              GlobalCardScrollerCard,
              'id'
            >)
          | ({ __typename: 'GlobalCardScrollerSet' } & Pick<
              GlobalCardScrollerSet,
              'id'
            >)
          | ({ __typename: 'GlobalDispatchAndDateCountdownWidget' } & Pick<
              GlobalDispatchAndDateCountdownWidget,
              'id'
            >)
          | ({ __typename: 'GlobalEditorialWithFeature' } & Pick<
              GlobalEditorialWithFeature,
              'id'
            >)
          | ({ __typename: 'GlobalFooterAccreditationIcons' } & Pick<
              GlobalFooterAccreditationIcons,
              | 'id'
              | 'image1'
              | 'image1Alt'
              | 'image1Href'
              | 'image2'
              | 'image2Href'
              | 'Image2Alt'
              | 'image3'
              | 'Image3Href'
              | 'Image3Alt'
            >)
          | ({ __typename: 'GlobalFooterContactUs' } & Pick<
              GlobalFooterContactUs,
              'id'
            >)
          | ({ __typename: 'GlobalFourBestSellers' } & Pick<
              GlobalFourBestSellers,
              'id'
            >)
          | ({ __typename: 'GlobalFourButtonLink' } & Pick<
              GlobalFourButtonLink,
              'id'
            >)
          | ({ __typename: 'GlobalFourItemEditorial' } & Pick<
              GlobalFourItemEditorial,
              'id'
            >)
          | ({ __typename: 'GlobalGeneralImageBanner' } & Pick<
              GlobalGeneralImageBanner,
              'id'
            >)
          | ({ __typename: 'GlobalHelpCentreAccordion' } & Pick<
              GlobalHelpCentreAccordion,
              'id'
            >)
          | ({ __typename: 'GlobalHelpCentreAccordion2' } & Pick<
              GlobalHelpCentreAccordion2,
              'id'
            >)
          | ({ __typename: 'GlobalHelpCentreAccordionCollection' } & Pick<
              GlobalHelpCentreAccordionCollection,
              'id'
            >)
          | ({ __typename: 'GlobalHelpCentreCollection' } & Pick<
              GlobalHelpCentreCollection,
              'id'
            >)
          | ({ __typename: 'GlobalHeroCTABanner' } & Pick<
              GlobalHeroCtaBanner,
              'id'
            >)
          | ({ __typename: 'GlobalImageCard' } & Pick<GlobalImageCard, 'id'>)
          | ({ __typename: 'GlobalImageCardSet' } & Pick<
              GlobalImageCardSet,
              'id'
            >)
          | ({ __typename: 'GlobalMultiButton' } & Pick<
              GlobalMultiButton,
              'id'
            >)
          | ({ __typename: 'GlobalPrimaryBanner' } & Pick<
              GlobalPrimaryBanner,
              'id'
            >)
          | ({ __typename: 'GlobalPrimaryBannerWithList' } & Pick<
              GlobalPrimaryBannerWithList,
              'id'
            >)
          | ({ __typename: 'GlobalPrimaryBannerWithTextOverlay' } & Pick<
              GlobalPrimaryBannerWithTextOverlay,
              'id'
            >)
          | ({ __typename: 'GlobalProductCardScroller' } & Pick<
              GlobalProductCardScroller,
              'id'
            >)
          | ({ __typename: 'GlobalReferral3StepGuide' } & Pick<
              GlobalReferral3StepGuide,
              'id'
            >)
          | ({ __typename: 'GlobalScalableLogos' } & Pick<
              GlobalScalableLogos,
              'id'
            >)
          | ({ __typename: 'GlobalSectionPeek' } & Pick<
              GlobalSectionPeek,
              'id'
            >)
          | ({ __typename: 'GlobalSetAndromeda' } & Pick<
              GlobalSetAndromeda,
              'id'
            >)
          | ({ __typename: 'GlobalSimpleTextCTAWidget' } & Pick<
              GlobalSimpleTextCtaWidget,
              'id'
            >)
          | ({ __typename: 'GlobalSixItemCategories' } & Pick<
              GlobalSixItemCategories,
              'id'
            >)
          | ({ __typename: 'GlobalSocialIcon' } & Pick<GlobalSocialIcon, 'id'>)
          | ({ __typename: 'GlobalSocialIconCollection' } & Pick<
              GlobalSocialIconCollection,
              'id'
            >)
          | ({ __typename: 'GlobalSocialIconCollectionv2' } & Pick<
              GlobalSocialIconCollectionv2,
              'id'
            >)
          | ({ __typename: 'GlobalSocialIconv2' } & Pick<
              GlobalSocialIconv2,
              'id'
            >)
          | ({ __typename: 'GlobalStripBanner' } & Pick<
              GlobalStripBanner,
              'id'
            >)
          | ({ __typename: 'GlobalSubscriptionOptions' } & Pick<
              GlobalSubscriptionOptions,
              'id'
            >)
          | ({ __typename: 'GlobalTabbedWidgetSet' } & Pick<
              GlobalTabbedWidgetSet,
              'id'
            >)
          | ({ __typename: 'GlobalThreeItemEditorial' } & Pick<
              GlobalThreeItemEditorial,
              'id'
            >)
          | ({ __typename: 'GlobalThreeItemEditorialSubtitleBG' } & Pick<
              GlobalThreeItemEditorialSubtitleBg,
              'id'
            >)
          | ({ __typename: 'GlobalTransformationSlider' } & Pick<
              GlobalTransformationSlider,
              'id'
            >)
          | ({ __typename: 'GlobalTrendingHashtagBlock' } & Pick<
              GlobalTrendingHashtagBlock,
              'id'
            >)
          | ({ __typename: 'GlobalTrendingHashtagCollection' } & Pick<
              GlobalTrendingHashtagCollection,
              'id'
            >)
          | ({ __typename: 'GlobalTwoBestSellers' } & Pick<
              GlobalTwoBestSellers,
              'id'
            >)
          | ({ __typename: 'GlobalTwoItemEditorial' } & Pick<
              GlobalTwoItemEditorial,
              'id'
            >)
          | ({ __typename: 'GlobalTwoItemImageTextBlock' } & Pick<
              GlobalTwoItemImageTextBlock,
              'id'
            >)
          | ({ __typename: 'GlobalTwoItemImageTextCTA' } & Pick<
              GlobalTwoItemImageTextCta,
              'id'
            >)
          | ({ __typename: 'GlobalTwoItemImageTextCTA3070' } & Pick<
              GlobalTwoItemImageTextCta3070,
              'id'
            >)
          | ({ __typename: 'GlobalUGCCarousel' } & Pick<
              GlobalUgcCarousel,
              'id'
            >)
          | ({ __typename: 'GlobalVideoGallery' } & Pick<
              GlobalVideoGallery,
              'id'
            >)
          | ({ __typename: 'GlobalVideoHeroBannerWidget' } & Pick<
              GlobalVideoHeroBannerWidget,
              'id'
            >)
          | ({ __typename: 'GlobalVideoTextImageCTA' } & Pick<
              GlobalVideoTextImageCta,
              'id'
            >)
          | ({ __typename: 'GlobalWaitListSignUpFormResponses' } & Pick<
              GlobalWaitListSignUpFormResponses,
              'id'
            >)
          | ({ __typename: 'GlobalWaitListSignUpWidget' } & Pick<
              GlobalWaitListSignUpWidget,
              'id'
            >)
          | ({ __typename: 'GlobalWidgetSirius' } & Pick<
              GlobalWidgetSirius,
              'id'
            >)
          | ({ __typename: 'GlobalWidgetVega' } & Pick<GlobalWidgetVega, 'id'>)
          | ({ __typename: 'GlobalYoutubeGalleryItem' } & Pick<
              GlobalYoutubeGalleryItem,
              'id'
            >)
          | ({ __typename: 'GlobalYoutubeGallerySet' } & Pick<
              GlobalYoutubeGallerySet,
              'id'
            >)
          | ({ __typename: 'LiveChatWidget' } & Pick<LiveChatWidget, 'id'>)
          | ({ __typename: 'LoyaltyHubBirthdayGift' } & Pick<
              LoyaltyHubBirthdayGift,
              'id'
            >)
          | ({ __typename: 'LoyaltyHubTier' } & Pick<LoyaltyHubTier, 'id'>)
          | ({ __typename: 'LoyaltyRewardTier' } & Pick<
              LoyaltyRewardTier,
              'id'
            >)
          | ({ __typename: 'LoyaltyRewardTiers' } & Pick<
              LoyaltyRewardTiers,
              'id'
            >)
          | ({ __typename: 'MailingList' } & Pick<MailingList, 'id'>)
          | ({ __typename: 'MultipleCTABanner' } & Pick<
              MultipleCtaBanner,
              'id'
            >)
          | ({ __typename: 'ProductListWidget' } & Pick<
              ProductListWidget,
              'id'
            >)
          | ({ __typename: 'ProductRecommenderItem' } & Pick<
              ProductRecommenderItem,
              'id'
            >)
          | ({ __typename: 'ProfileWidget' } & Pick<ProfileWidget, 'id'>)
          | ({ __typename: 'ResponsiveBuildYourOwnBundle' } & Pick<
              ResponsiveBuildYourOwnBundle,
              'id'
            >)
          | ({ __typename: 'ResponsiveBuildYourOwnBundleProducts' } & Pick<
              ResponsiveBuildYourOwnBundleProducts,
              'id'
            >)
          | ({ __typename: 'ResponsiveComparisonTable' } & Pick<
              ResponsiveComparisonTable,
              'id'
            >)
          | ({ __typename: 'ResponsiveComparisonTableContainer' } & Pick<
              ResponsiveComparisonTableContainer,
              'id'
            >)
          | ({ __typename: 'ResponsiveProductBlockCarousel' } & Pick<
              ResponsiveProductBlockCarousel,
              'id'
            >)
          | ({ __typename: 'ResponsiveProductTabs' } & Pick<
              ResponsiveProductTabs,
              'id'
            >)
          | ({ __typename: 'ResponsiveSetSalon' } & Pick<
              ResponsiveSetSalon,
              'id'
            >)
          | ({ __typename: 'ResponsiveSetSalonWithSlides' } & Pick<
              ResponsiveSetSalonWithSlides,
              'id'
            >)
          | ({ __typename: 'ResponsiveSliderSet' } & Pick<
              ResponsiveSliderSet,
              'id'
            >)
          | ({ __typename: 'ResponsiveSuccessStoriesWidgetContainer' } & Pick<
              ResponsiveSuccessStoriesWidgetContainer,
              'id'
            >)
          | ({ __typename: 'ResponsiveSuccessStoryWidget' } & Pick<
              ResponsiveSuccessStoryWidget,
              'id'
            >)
          | ({ __typename: 'ResponsiveTwoItemComparisonContainer' } & Pick<
              ResponsiveTwoItemComparisonContainer,
              'id'
            >)
          | ({ __typename: 'ResponsiveTwoItemComparisonRow' } & Pick<
              ResponsiveTwoItemComparisonRow,
              'id'
            >)
          | ({ __typename: 'ResponsiveUSPBar' } & Pick<ResponsiveUspBar, 'id'>)
          | ({ __typename: 'ResponsiveUSPBarCustomisable' } & Pick<
              ResponsiveUspBarCustomisable,
              'id'
            >)
          | ({ __typename: 'SeoProductCategory' } & Pick<
              SeoProductCategory,
              'id'
            >)
          | ({ __typename: 'SeoProductCategorySet' } & Pick<
              SeoProductCategorySet,
              'id'
            >)
          | ({ __typename: 'ShopTheLookHotSpot' } & Pick<
              ShopTheLookHotSpot,
              'id'
            >)
          | ({ __typename: 'SimpleTextReadMore' } & Pick<
              SimpleTextReadMore,
              'id'
            >)
          | ({ __typename: 'TopBrandsWidget' } & Pick<TopBrandsWidget, 'id'>)
          | ({ __typename: 'TopProductCategory' } & Pick<
              TopProductCategory,
              'id'
            >)
          | ({ __typename: 'TopProductCategorySet' } & Pick<
              TopProductCategorySet,
              'id'
            >)
          | ({ __typename: 'TrustPilotCarouselWidget' } & Pick<
              TrustPilotCarouselWidget,
              'id'
            >)
          | ({ __typename: 'TrustPilotWidget' } & Pick<TrustPilotWidget, 'id'>)
          | ({ __typename: 'VoucherCodesWidget' } & Pick<
              VoucherCodesWidget,
              'id'
            >)
          | ({ __typename: 'asymmetricGrid' } & Pick<AsymmetricGrid, 'id'>)
          | ({ __typename: 'buildyourownbundle' } & Pick<
              Buildyourownbundle,
              'id'
            >)
          | ({ __typename: 'buttonLink' } & Pick<ButtonLink, 'id'>)
          | ({ __typename: 'categoryItemCard' } & Pick<CategoryItemCard, 'id'>)
          | ({ __typename: 'categoryItemCardWithTextOverlay' } & Pick<
              CategoryItemCardWithTextOverlay,
              'id'
            >)
          | ({ __typename: 'coverageCalculator' } & Pick<
              CoverageCalculator,
              'id'
            >)
          | ({ __typename: 'eightLinkButtonWidget' } & Pick<
              EightLinkButtonWidget,
              'id'
            >)
          | ({ __typename: 'fouritemwindowproductslider' } & Pick<
              Fouritemwindowproductslider,
              'id'
            >)
          | ({ __typename: 'generalIframe' } & Pick<GeneralIframe, 'id'>)
          | ({ __typename: 'generalQuoteBanner' } & Pick<
              GeneralQuoteBanner,
              'id'
            >)
          | ({ __typename: 'imageSelectCard' } & Pick<ImageSelectCard, 'id'>)
          | ({ __typename: 'imageSelectSlider' } & Pick<
              ImageSelectSlider,
              'id'
            >)
          | ({ __typename: 'improvedSearchBestSellers' } & Pick<
              ImprovedSearchBestSellers,
              'id'
            >)
          | ({ __typename: 'kitBuilder' } & Pick<KitBuilder, 'id'>)
          | ({ __typename: 'loyaltyRewardsList' } & Pick<
              LoyaltyRewardsList,
              'id'
            >)
          | ({ __typename: 'loyaltyWelcomeMessage' } & Pick<
              LoyaltyWelcomeMessage,
              'id'
            >)
          | ({ __typename: 'multiCategoryCardSet' } & Pick<
              MultiCategoryCardSet,
              'id'
            >)
          | ({ __typename: 'multiCategoryCardSetV2' } & Pick<
              MultiCategoryCardSetV2,
              'id'
            >)
          | ({ __typename: 'obsessVR' } & Pick<ObsessVr, 'id'>)
          | ({ __typename: 'parcelLabWidget' } & Pick<ParcelLabWidget, 'id'>)
          | ({ __typename: 'promoproductslider' } & Pick<
              Promoproductslider,
              'id'
            >)
          | ({ __typename: 'propositionBar' } & Pick<PropositionBar, 'id'>)
          | ({ __typename: 'propositionBarIncorrect' } & Pick<
              PropositionBarIncorrect,
              'id'
            >)
          | ({ __typename: 'provenanceBrandHeader' } & Pick<
              ProvenanceBrandHeader,
              'id'
            >)
          | ({ __typename: 'qubitHomePageRecs' } & Pick<
              QubitHomePageRecs,
              'id'
            >)
          | ({ __typename: 'revieveWidget' } & Pick<RevieveWidget, 'id'>)
          | ({ __typename: 'shadeFinder' } & Pick<ShadeFinder, 'id'>)
          | ({ __typename: 'shopTheLook' } & Pick<ShopTheLook, 'id'>)
          | ({ __typename: 'simpleCTA' } & Pick<SimpleCta, 'id'>)
          | ({ __typename: 'simpleDivider' } & Pick<SimpleDivider, 'id'>)
          | ({ __typename: 'simpleTextWidgetSet' } & Pick<
              SimpleTextWidgetSet,
              'id'
            >)
          | ({ __typename: 'simpleTitleWidget' } & Pick<
              SimpleTitleWidget,
              'id'
            >)
          | ({ __typename: 'simpletextwidget' } & Pick<Simpletextwidget, 'id'>)
          | ({ __typename: 'simpletextwidgetwithh1' } & Pick<
              Simpletextwidgetwithh1,
              'id'
            >)
          | ({ __typename: 'sponsoredProducts' } & Pick<
              SponsoredProducts,
              'id'
            >)
          | ({ __typename: 'sponsoredProductsCriteoHomepage' } & Pick<
              SponsoredProductsCriteoHomepage,
              'id'
            >)
          | ({ __typename: 'sponsoredProductsNew' } & Pick<
              SponsoredProductsNew,
              'id'
            >)
          | ({ __typename: 'storyStreamWidget' } & Pick<
              StoryStreamWidget,
              'id'
            >)
          | ({ __typename: 'subscribeAndSaveInformationModal' } & Pick<
              SubscribeAndSaveInformationModal,
              'id'
            >)
          | ({ __typename: 'swatchSelectSlider' } & Pick<
              SwatchSelectSlider,
              'id'
            >)
          | ({ __typename: 'swatchSelectSliderSet' } & Pick<
              SwatchSelectSliderSet,
              'id'
            >)
          | ({ __typename: 'trustReinforcementContainer' } & Pick<
              TrustReinforcementContainer,
              'id'
            >)
          | ({ __typename: 'trustReinforcementSection' } & Pick<
              TrustReinforcementSection,
              'id'
            >)
          | ({ __typename: 'videoEditorial' } & Pick<VideoEditorial, 'id'>)
          | ({ __typename: 'yotiAgeVerification' } & Pick<
              YotiAgeVerification,
              'id'
            >)
          | ({ __typename: 'yotiCancelFrame' } & Pick<YotiCancelFrame, 'id'>)
        >
      >
    }
  >
}

export type HeaderNavigationFragment = { __typename?: 'Header' } & {
  navigation?: Maybe<
    { __typename?: 'Navigation' } & {
      topLevel: Array<
        { __typename?: 'NavigationItem' } & Pick<
          NavigationItem,
          'displayName' | 'type'
        > & {
            link?: Maybe<
              { __typename?: 'Hyperlink' } & Pick<
                Hyperlink,
                'text' | 'url' | 'openExternally' | 'noFollow' | 'noIndex'
              >
            >
            image?: Maybe<{ __typename?: 'Image' } & Pick<Image, 'url' | 'alt'>>
            subNavigation?: Maybe<
              Array<
                { __typename?: 'NavigationItem' } & Pick<
                  NavigationItem,
                  'displayName' | 'type'
                > & {
                    link?: Maybe<
                      { __typename?: 'Hyperlink' } & Pick<
                        Hyperlink,
                        | 'text'
                        | 'url'
                        | 'openExternally'
                        | 'noFollow'
                        | 'noIndex'
                      >
                    >
                    image?: Maybe<
                      { __typename?: 'Image' } & Pick<Image, 'url' | 'alt'>
                    >
                    subNavigation?: Maybe<
                      Array<
                        { __typename?: 'NavigationItem' } & Pick<
                          NavigationItem,
                          'displayName' | 'type'
                        > & {
                            link?: Maybe<
                              { __typename?: 'Hyperlink' } & Pick<
                                Hyperlink,
                                | 'text'
                                | 'url'
                                | 'openExternally'
                                | 'noFollow'
                                | 'noIndex'
                              >
                            >
                            image?: Maybe<
                              { __typename?: 'Image' } & Pick<
                                Image,
                                'url' | 'alt'
                              >
                            >
                          }
                      >
                    >
                  }
              >
            >
          }
      >
    }
  >
}

export type HeaderQueryVariables = Exact<{
  code: Scalars['String']
  concessionEnabled?: Scalars['Boolean']
}>

export type HeaderQuery = { __typename?: 'Query' } & {
  header?: Maybe<{ __typename?: 'Header' } & HeaderNavigationFragment>
  concession?: Maybe<
    { __typename?: 'Concession' } & {
      homepage?: Maybe<{ __typename?: 'Page' } & Pick<Page, 'path' | 'title'>>
      header: { __typename?: 'Header' } & HeaderNavigationFragment
    }
  >
}

export type WidgetsQueryVariables = Exact<{ [key: string]: never }>

export type WidgetsQuery = { __typename?: 'Query' } & {
  header?: Maybe<
    { __typename?: 'Header' } & {
      widgets?: Maybe<
        Array<
          | ({ __typename: 'AccordionWidget' } & Pick<AccordionWidget, 'id'>)
          | ({ __typename: 'AccordionWidgetContainer' } & Pick<
              AccordionWidgetContainer,
              'id'
            >)
          | ({ __typename: 'BMICalculator' } & Pick<BmiCalculator, 'id'>)
          | ({ __typename: 'BMICalculatorV2' } & Pick<BmiCalculatorV2, 'id'>)
          | ({ __typename: 'BrandsPageWidget' } & Pick<BrandsPageWidget, 'id'>)
          | ({ __typename: 'BuildYourOwnBundleProductList' } & Pick<
              BuildYourOwnBundleProductList,
              'id'
            >)
          | ({ __typename: 'CriteoSponsoredBannerAds' } & Pick<
              CriteoSponsoredBannerAds,
              'id'
            >)
          | ({ __typename: 'CriteoSponsoredBannerAdsFlagship' } & Pick<
              CriteoSponsoredBannerAdsFlagship,
              'id'
            >)
          | ({ __typename: 'DeliveryInfoWidget' } & Pick<
              DeliveryInfoWidget,
              'id'
            >)
          | ({ __typename: 'DeliveryInformationWidget' } & Pick<
              DeliveryInformationWidget,
              'id'
            >)
          | ({ __typename: 'DynamicReferralWidget' } & Pick<
              DynamicReferralWidget,
              'id'
            >)
          | ({ __typename: 'Easiware' } & Pick<Easiware, 'id'>)
          | ({ __typename: 'EditorialWidget' } & Pick<EditorialWidget, 'id'>)
          | ({ __typename: 'EmailReEngagementModal' } & Pick<
              EmailReEngagementModal,
              'id'
            >)
          | ({ __typename: 'FastTrackBanner' } & Pick<FastTrackBanner, 'id'>)
          | ({ __typename: 'FoundationFinderLandingPageWidget' } & Pick<
              FoundationFinderLandingPageWidget,
              'id'
            >)
          | ({ __typename: 'GlobalAboutUsVideo' } & Pick<
              GlobalAboutUsVideo,
              'id'
            >)
          | ({ __typename: 'GlobalAccreditationIcon' } & Pick<
              GlobalAccreditationIcon,
              'id'
            >)
          | ({ __typename: 'GlobalAccreditationIconCollection' } & Pick<
              GlobalAccreditationIconCollection,
              'id'
            >)
          | ({ __typename: 'GlobalBrandLogos' } & Pick<GlobalBrandLogos, 'id'>)
          | ({ __typename: 'GlobalBuyingRightNow' } & Pick<
              GlobalBuyingRightNow,
              'id'
            >)
          | ({ __typename: 'GlobalBuyingRightNowManualProductURL' } & Pick<
              GlobalBuyingRightNowManualProductUrl,
              'id'
            >)
          | ({ __typename: 'GlobalCardScrollerCard' } & Pick<
              GlobalCardScrollerCard,
              'id'
            >)
          | ({ __typename: 'GlobalCardScrollerSet' } & Pick<
              GlobalCardScrollerSet,
              'id'
            >)
          | ({ __typename: 'GlobalDispatchAndDateCountdownWidget' } & Pick<
              GlobalDispatchAndDateCountdownWidget,
              'id'
            >)
          | ({ __typename: 'GlobalEditorialWithFeature' } & Pick<
              GlobalEditorialWithFeature,
              'id'
            >)
          | ({ __typename: 'GlobalFooterAccreditationIcons' } & Pick<
              GlobalFooterAccreditationIcons,
              'id'
            >)
          | ({ __typename: 'GlobalFooterContactUs' } & Pick<
              GlobalFooterContactUs,
              'id'
            >)
          | ({ __typename: 'GlobalFourBestSellers' } & Pick<
              GlobalFourBestSellers,
              'id'
            >)
          | ({ __typename: 'GlobalFourButtonLink' } & Pick<
              GlobalFourButtonLink,
              'id'
            >)
          | ({ __typename: 'GlobalFourItemEditorial' } & Pick<
              GlobalFourItemEditorial,
              'id'
            >)
          | ({ __typename: 'GlobalGeneralImageBanner' } & Pick<
              GlobalGeneralImageBanner,
              'id'
            >)
          | ({ __typename: 'GlobalHelpCentreAccordion' } & Pick<
              GlobalHelpCentreAccordion,
              'id'
            >)
          | ({ __typename: 'GlobalHelpCentreAccordion2' } & Pick<
              GlobalHelpCentreAccordion2,
              'id'
            >)
          | ({ __typename: 'GlobalHelpCentreAccordionCollection' } & Pick<
              GlobalHelpCentreAccordionCollection,
              'id'
            >)
          | ({ __typename: 'GlobalHelpCentreCollection' } & Pick<
              GlobalHelpCentreCollection,
              'id'
            >)
          | ({ __typename: 'GlobalHeroCTABanner' } & Pick<
              GlobalHeroCtaBanner,
              'id'
            >)
          | ({ __typename: 'GlobalImageCard' } & Pick<GlobalImageCard, 'id'>)
          | ({ __typename: 'GlobalImageCardSet' } & Pick<
              GlobalImageCardSet,
              'id'
            >)
          | ({ __typename: 'GlobalMultiButton' } & Pick<
              GlobalMultiButton,
              'id'
            >)
          | ({ __typename: 'GlobalPrimaryBanner' } & Pick<
              GlobalPrimaryBanner,
              'id'
            >)
          | ({ __typename: 'GlobalPrimaryBannerWithList' } & Pick<
              GlobalPrimaryBannerWithList,
              'id'
            >)
          | ({ __typename: 'GlobalPrimaryBannerWithTextOverlay' } & Pick<
              GlobalPrimaryBannerWithTextOverlay,
              'id'
            >)
          | ({ __typename: 'GlobalProductCardScroller' } & Pick<
              GlobalProductCardScroller,
              'id'
            >)
          | ({ __typename: 'GlobalReferral3StepGuide' } & Pick<
              GlobalReferral3StepGuide,
              'id'
            >)
          | ({ __typename: 'GlobalScalableLogos' } & Pick<
              GlobalScalableLogos,
              'id'
            >)
          | ({ __typename: 'GlobalSectionPeek' } & Pick<
              GlobalSectionPeek,
              'id'
            >)
          | ({ __typename: 'GlobalSetAndromeda' } & Pick<
              GlobalSetAndromeda,
              'id'
            >)
          | ({ __typename: 'GlobalSimpleTextCTAWidget' } & Pick<
              GlobalSimpleTextCtaWidget,
              'id'
            >)
          | ({ __typename: 'GlobalSixItemCategories' } & Pick<
              GlobalSixItemCategories,
              'id'
            >)
          | ({ __typename: 'GlobalSocialIcon' } & Pick<GlobalSocialIcon, 'id'>)
          | ({ __typename: 'GlobalSocialIconCollection' } & Pick<
              GlobalSocialIconCollection,
              'id'
            >)
          | ({ __typename: 'GlobalSocialIconCollectionv2' } & Pick<
              GlobalSocialIconCollectionv2,
              'id'
            >)
          | ({ __typename: 'GlobalSocialIconv2' } & Pick<
              GlobalSocialIconv2,
              'id'
            >)
          | ({ __typename: 'GlobalStripBanner' } & Pick<
              GlobalStripBanner,
              | 'stripBannerTextPLP'
              | 'stripBannerURL'
              | 'stripBannerURLPDP'
              | 'stripBannerURLHomepage'
              | 'stripBannerText'
              | 'stripBannerURLBasket'
              | 'stripBannerTextPDP'
              | 'stripBannerURLAccount'
              | 'stripBannerTextBasket'
              | 'stripBannerTextAccount'
              | 'stripBannerURLPLP'
              | 'stripBannerTextHomepage'
              | 'id'
            >)
          | ({ __typename: 'GlobalSubscriptionOptions' } & Pick<
              GlobalSubscriptionOptions,
              'id'
            >)
          | ({ __typename: 'GlobalTabbedWidgetSet' } & Pick<
              GlobalTabbedWidgetSet,
              'id'
            >)
          | ({ __typename: 'GlobalThreeItemEditorial' } & Pick<
              GlobalThreeItemEditorial,
              'id'
            >)
          | ({ __typename: 'GlobalThreeItemEditorialSubtitleBG' } & Pick<
              GlobalThreeItemEditorialSubtitleBg,
              'id'
            >)
          | ({ __typename: 'GlobalTransformationSlider' } & Pick<
              GlobalTransformationSlider,
              'id'
            >)
          | ({ __typename: 'GlobalTrendingHashtagBlock' } & Pick<
              GlobalTrendingHashtagBlock,
              'id'
            >)
          | ({ __typename: 'GlobalTrendingHashtagCollection' } & Pick<
              GlobalTrendingHashtagCollection,
              'id'
            >)
          | ({ __typename: 'GlobalTwoBestSellers' } & Pick<
              GlobalTwoBestSellers,
              'id'
            >)
          | ({ __typename: 'GlobalTwoItemEditorial' } & Pick<
              GlobalTwoItemEditorial,
              'id'
            >)
          | ({ __typename: 'GlobalTwoItemImageTextBlock' } & Pick<
              GlobalTwoItemImageTextBlock,
              'id'
            >)
          | ({ __typename: 'GlobalTwoItemImageTextCTA' } & Pick<
              GlobalTwoItemImageTextCta,
              'id'
            >)
          | ({ __typename: 'GlobalTwoItemImageTextCTA3070' } & Pick<
              GlobalTwoItemImageTextCta3070,
              'id'
            >)
          | ({ __typename: 'GlobalUGCCarousel' } & Pick<
              GlobalUgcCarousel,
              'id'
            >)
          | ({ __typename: 'GlobalVideoGallery' } & Pick<
              GlobalVideoGallery,
              'id'
            >)
          | ({ __typename: 'GlobalVideoHeroBannerWidget' } & Pick<
              GlobalVideoHeroBannerWidget,
              'id'
            >)
          | ({ __typename: 'GlobalVideoTextImageCTA' } & Pick<
              GlobalVideoTextImageCta,
              'id'
            >)
          | ({ __typename: 'GlobalWaitListSignUpFormResponses' } & Pick<
              GlobalWaitListSignUpFormResponses,
              'id'
            >)
          | ({ __typename: 'GlobalWaitListSignUpWidget' } & Pick<
              GlobalWaitListSignUpWidget,
              'id'
            >)
          | ({ __typename: 'GlobalWidgetSirius' } & Pick<
              GlobalWidgetSirius,
              'id'
            >)
          | ({ __typename: 'GlobalWidgetVega' } & Pick<GlobalWidgetVega, 'id'>)
          | ({ __typename: 'GlobalYoutubeGalleryItem' } & Pick<
              GlobalYoutubeGalleryItem,
              'id'
            >)
          | ({ __typename: 'GlobalYoutubeGallerySet' } & Pick<
              GlobalYoutubeGallerySet,
              'id'
            >)
          | ({ __typename: 'LiveChatWidget' } & Pick<LiveChatWidget, 'id'>)
          | ({ __typename: 'LoyaltyHubBirthdayGift' } & Pick<
              LoyaltyHubBirthdayGift,
              'id'
            >)
          | ({ __typename: 'LoyaltyHubTier' } & Pick<LoyaltyHubTier, 'id'>)
          | ({ __typename: 'LoyaltyRewardTier' } & Pick<
              LoyaltyRewardTier,
              'id'
            >)
          | ({ __typename: 'LoyaltyRewardTiers' } & Pick<
              LoyaltyRewardTiers,
              'id'
            >)
          | ({ __typename: 'MailingList' } & Pick<MailingList, 'id'>)
          | ({ __typename: 'MultipleCTABanner' } & Pick<
              MultipleCtaBanner,
              'id'
            >)
          | ({ __typename: 'ProductListWidget' } & Pick<
              ProductListWidget,
              'id'
            >)
          | ({ __typename: 'ProductRecommenderItem' } & Pick<
              ProductRecommenderItem,
              'id'
            >)
          | ({ __typename: 'ProfileWidget' } & Pick<ProfileWidget, 'id'>)
          | ({ __typename: 'ResponsiveBuildYourOwnBundle' } & Pick<
              ResponsiveBuildYourOwnBundle,
              'id'
            >)
          | ({ __typename: 'ResponsiveBuildYourOwnBundleProducts' } & Pick<
              ResponsiveBuildYourOwnBundleProducts,
              'id'
            >)
          | ({ __typename: 'ResponsiveComparisonTable' } & Pick<
              ResponsiveComparisonTable,
              'id'
            >)
          | ({ __typename: 'ResponsiveComparisonTableContainer' } & Pick<
              ResponsiveComparisonTableContainer,
              'id'
            >)
          | ({ __typename: 'ResponsiveProductBlockCarousel' } & Pick<
              ResponsiveProductBlockCarousel,
              'id'
            >)
          | ({ __typename: 'ResponsiveProductTabs' } & Pick<
              ResponsiveProductTabs,
              'id'
            >)
          | ({ __typename: 'ResponsiveSetSalon' } & Pick<
              ResponsiveSetSalon,
              'id'
            >)
          | ({ __typename: 'ResponsiveSetSalonWithSlides' } & Pick<
              ResponsiveSetSalonWithSlides,
              'id'
            >)
          | ({ __typename: 'ResponsiveSliderSet' } & Pick<
              ResponsiveSliderSet,
              'id'
            >)
          | ({ __typename: 'ResponsiveSuccessStoriesWidgetContainer' } & Pick<
              ResponsiveSuccessStoriesWidgetContainer,
              'id'
            >)
          | ({ __typename: 'ResponsiveSuccessStoryWidget' } & Pick<
              ResponsiveSuccessStoryWidget,
              'id'
            >)
          | ({ __typename: 'ResponsiveTwoItemComparisonContainer' } & Pick<
              ResponsiveTwoItemComparisonContainer,
              'id'
            >)
          | ({ __typename: 'ResponsiveTwoItemComparisonRow' } & Pick<
              ResponsiveTwoItemComparisonRow,
              'id'
            >)
          | ({ __typename: 'ResponsiveUSPBar' } & Pick<
              ResponsiveUspBar,
              | 'rewardPointsLink'
              | 'countDownLink'
              | 'countDownText'
              | 'internationalDeliveryLink'
              | 'qualityLink'
              | 'callIcon'
              | 'callLink'
              | 'emailText'
              | 'checkmarkLink'
              | 'internationalDeliveryText'
              | 'timezoneOffset'
              | 'saturdayCutoff'
              | 'callText'
              | 'checkmarkText'
              | 'sundayCutoff'
              | 'deliveryText'
              | 'deliveryLink'
              | 'emailLink'
              | 'qualityText'
              | 'rewardPointsText'
              | 'wednesdayCutoff'
              | 'fridayCutoff'
              | 'tuesdayCutoff'
              | 'mondayCutoff'
              | 'thursdayCutoff'
              | 'id'
            >)
          | ({ __typename: 'ResponsiveUSPBarCustomisable' } & Pick<
              ResponsiveUspBarCustomisable,
              'id'
            >)
          | ({ __typename: 'SeoProductCategory' } & Pick<
              SeoProductCategory,
              'id'
            >)
          | ({ __typename: 'SeoProductCategorySet' } & Pick<
              SeoProductCategorySet,
              'id'
            >)
          | ({ __typename: 'ShopTheLookHotSpot' } & Pick<
              ShopTheLookHotSpot,
              'id'
            >)
          | ({ __typename: 'SimpleTextReadMore' } & Pick<
              SimpleTextReadMore,
              'id'
            >)
          | ({ __typename: 'TopBrandsWidget' } & Pick<TopBrandsWidget, 'id'>)
          | ({ __typename: 'TopProductCategory' } & Pick<
              TopProductCategory,
              'id'
            >)
          | ({ __typename: 'TopProductCategorySet' } & Pick<
              TopProductCategorySet,
              'id'
            >)
          | ({ __typename: 'TrustPilotCarouselWidget' } & Pick<
              TrustPilotCarouselWidget,
              'id'
            >)
          | ({ __typename: 'TrustPilotWidget' } & Pick<TrustPilotWidget, 'id'>)
          | ({ __typename: 'VoucherCodesWidget' } & Pick<
              VoucherCodesWidget,
              'id'
            >)
          | ({ __typename: 'asymmetricGrid' } & Pick<AsymmetricGrid, 'id'>)
          | ({ __typename: 'buildyourownbundle' } & Pick<
              Buildyourownbundle,
              'id'
            >)
          | ({ __typename: 'buttonLink' } & Pick<ButtonLink, 'id'>)
          | ({ __typename: 'categoryItemCard' } & Pick<CategoryItemCard, 'id'>)
          | ({ __typename: 'categoryItemCardWithTextOverlay' } & Pick<
              CategoryItemCardWithTextOverlay,
              'id'
            >)
          | ({ __typename: 'coverageCalculator' } & Pick<
              CoverageCalculator,
              'id'
            >)
          | ({ __typename: 'eightLinkButtonWidget' } & Pick<
              EightLinkButtonWidget,
              'id'
            >)
          | ({ __typename: 'fouritemwindowproductslider' } & Pick<
              Fouritemwindowproductslider,
              'id'
            >)
          | ({ __typename: 'generalIframe' } & Pick<GeneralIframe, 'id'>)
          | ({ __typename: 'generalQuoteBanner' } & Pick<
              GeneralQuoteBanner,
              'id'
            >)
          | ({ __typename: 'imageSelectCard' } & Pick<ImageSelectCard, 'id'>)
          | ({ __typename: 'imageSelectSlider' } & Pick<
              ImageSelectSlider,
              'id'
            >)
          | ({ __typename: 'improvedSearchBestSellers' } & Pick<
              ImprovedSearchBestSellers,
              'id'
            >)
          | ({ __typename: 'kitBuilder' } & Pick<KitBuilder, 'id'>)
          | ({ __typename: 'loyaltyRewardsList' } & Pick<
              LoyaltyRewardsList,
              'id'
            >)
          | ({ __typename: 'loyaltyWelcomeMessage' } & Pick<
              LoyaltyWelcomeMessage,
              'id'
            >)
          | ({ __typename: 'multiCategoryCardSet' } & Pick<
              MultiCategoryCardSet,
              'id'
            >)
          | ({ __typename: 'multiCategoryCardSetV2' } & Pick<
              MultiCategoryCardSetV2,
              'id'
            >)
          | ({ __typename: 'obsessVR' } & Pick<ObsessVr, 'id'>)
          | ({ __typename: 'parcelLabWidget' } & Pick<ParcelLabWidget, 'id'>)
          | ({ __typename: 'promoproductslider' } & Pick<
              Promoproductslider,
              'id'
            >)
          | ({ __typename: 'propositionBar' } & Pick<PropositionBar, 'id'>)
          | ({ __typename: 'propositionBarIncorrect' } & Pick<
              PropositionBarIncorrect,
              'id'
            >)
          | ({ __typename: 'provenanceBrandHeader' } & Pick<
              ProvenanceBrandHeader,
              'id'
            >)
          | ({ __typename: 'qubitHomePageRecs' } & Pick<
              QubitHomePageRecs,
              'id'
            >)
          | ({ __typename: 'revieveWidget' } & Pick<RevieveWidget, 'id'>)
          | ({ __typename: 'shadeFinder' } & Pick<ShadeFinder, 'id'>)
          | ({ __typename: 'shopTheLook' } & Pick<ShopTheLook, 'id'>)
          | ({ __typename: 'simpleCTA' } & Pick<SimpleCta, 'id'>)
          | ({ __typename: 'simpleDivider' } & Pick<SimpleDivider, 'id'>)
          | ({ __typename: 'simpleTextWidgetSet' } & Pick<
              SimpleTextWidgetSet,
              'id'
            >)
          | ({ __typename: 'simpleTitleWidget' } & Pick<
              SimpleTitleWidget,
              'id'
            >)
          | ({ __typename: 'simpletextwidget' } & Pick<Simpletextwidget, 'id'>)
          | ({ __typename: 'simpletextwidgetwithh1' } & Pick<
              Simpletextwidgetwithh1,
              'id'
            >)
          | ({ __typename: 'sponsoredProducts' } & Pick<
              SponsoredProducts,
              'id'
            >)
          | ({ __typename: 'sponsoredProductsCriteoHomepage' } & Pick<
              SponsoredProductsCriteoHomepage,
              'id'
            >)
          | ({ __typename: 'sponsoredProductsNew' } & Pick<
              SponsoredProductsNew,
              'id'
            >)
          | ({ __typename: 'storyStreamWidget' } & Pick<
              StoryStreamWidget,
              'id'
            >)
          | ({ __typename: 'subscribeAndSaveInformationModal' } & Pick<
              SubscribeAndSaveInformationModal,
              'id'
            >)
          | ({ __typename: 'swatchSelectSlider' } & Pick<
              SwatchSelectSlider,
              'id'
            >)
          | ({ __typename: 'swatchSelectSliderSet' } & Pick<
              SwatchSelectSliderSet,
              'id'
            >)
          | ({ __typename: 'trustReinforcementContainer' } & Pick<
              TrustReinforcementContainer,
              'id'
            >)
          | ({ __typename: 'trustReinforcementSection' } & Pick<
              TrustReinforcementSection,
              'id'
            >)
          | ({ __typename: 'videoEditorial' } & Pick<VideoEditorial, 'id'>)
          | ({ __typename: 'yotiAgeVerification' } & Pick<
              YotiAgeVerification,
              'id'
            >)
          | ({ __typename: 'yotiCancelFrame' } & Pick<YotiCancelFrame, 'id'>)
        >
      >
    }
  >
}

export type PageQueryVariables = Exact<{
  path: Scalars['PagePath']
  currency: Currency
  shippingDestination: Country
  concessionEnabled?: Scalars['Boolean']
}>

export type PageQuery = { __typename?: 'Query' } & {
  page?: Maybe<
    { __typename?: 'Page' } & Pick<
      Page,
      'path' | 'title' | 'metaDescription' | 'canonicalUrl'
    > & {
        concession?: Maybe<
          { __typename: 'Concession' } & Pick<Concession, 'concessionCode'> & {
              footer: { __typename: 'Footer' } & {
                navigation?: Maybe<
                  { __typename: 'Navigation' } & {
                    topLevel: Array<
                      { __typename: 'NavigationItem' } & {
                        link?: Maybe<
                          { __typename: 'Hyperlink' } & Pick<
                            Hyperlink,
                            'url' | 'text'
                          >
                        >
                      }
                    >
                  }
                >
              }
              header: { __typename: 'Header' } & {
                navigation?: Maybe<
                  { __typename: 'Navigation' } & {
                    topLevel: Array<
                      { __typename: 'NavigationItem' } & {
                        link?: Maybe<
                          { __typename: 'Hyperlink' } & Pick<
                            Hyperlink,
                            'url' | 'text'
                          >
                        >
                      }
                    >
                  }
                >
              }
            }
        >
        breadcrumbs: Array<
          { __typename: 'Breadcrumb' } & Pick<
            Breadcrumb,
            'displayName' | 'pagePath'
          >
        >
        widgets?: Maybe<
          Array<
            | ({
                __typename: 'AccordionWidget'
              } & AllWidgets_AccordionWidget_Fragment)
            | ({
                __typename: 'AccordionWidgetContainer'
              } & AllWidgets_AccordionWidgetContainer_Fragment)
            | ({
                __typename: 'BMICalculator'
              } & AllWidgets_BmiCalculator_Fragment)
            | ({
                __typename: 'BMICalculatorV2'
              } & AllWidgets_BmiCalculatorV2_Fragment)
            | ({
                __typename: 'BrandsPageWidget'
              } & AllWidgets_BrandsPageWidget_Fragment)
            | ({
                __typename: 'BuildYourOwnBundleProductList'
              } & AllWidgets_BuildYourOwnBundleProductList_Fragment)
            | ({
                __typename: 'CriteoSponsoredBannerAds'
              } & AllWidgets_CriteoSponsoredBannerAds_Fragment)
            | ({
                __typename: 'CriteoSponsoredBannerAdsFlagship'
              } & AllWidgets_CriteoSponsoredBannerAdsFlagship_Fragment)
            | ({
                __typename: 'DeliveryInfoWidget'
              } & AllWidgets_DeliveryInfoWidget_Fragment)
            | ({
                __typename: 'DeliveryInformationWidget'
              } & AllWidgets_DeliveryInformationWidget_Fragment)
            | ({
                __typename: 'DynamicReferralWidget'
              } & AllWidgets_DynamicReferralWidget_Fragment)
            | ({ __typename: 'Easiware' } & AllWidgets_Easiware_Fragment)
            | ({
                __typename: 'EditorialWidget'
              } & AllWidgets_EditorialWidget_Fragment)
            | ({
                __typename: 'EmailReEngagementModal'
              } & AllWidgets_EmailReEngagementModal_Fragment)
            | ({
                __typename: 'FastTrackBanner'
              } & AllWidgets_FastTrackBanner_Fragment)
            | ({
                __typename: 'FoundationFinderLandingPageWidget'
              } & AllWidgets_FoundationFinderLandingPageWidget_Fragment)
            | ({
                __typename: 'GlobalAboutUsVideo'
              } & AllWidgets_GlobalAboutUsVideo_Fragment)
            | ({
                __typename: 'GlobalAccreditationIcon'
              } & AllWidgets_GlobalAccreditationIcon_Fragment)
            | ({
                __typename: 'GlobalAccreditationIconCollection'
              } & AllWidgets_GlobalAccreditationIconCollection_Fragment)
            | ({
                __typename: 'GlobalBrandLogos'
              } & AllWidgets_GlobalBrandLogos_Fragment)
            | ({
                __typename: 'GlobalBuyingRightNow'
              } & AllWidgets_GlobalBuyingRightNow_Fragment)
            | ({
                __typename: 'GlobalBuyingRightNowManualProductURL'
              } & AllWidgets_GlobalBuyingRightNowManualProductUrl_Fragment)
            | ({
                __typename: 'GlobalCardScrollerCard'
              } & AllWidgets_GlobalCardScrollerCard_Fragment)
            | ({
                __typename: 'GlobalCardScrollerSet'
              } & AllWidgets_GlobalCardScrollerSet_Fragment)
            | ({
                __typename: 'GlobalDispatchAndDateCountdownWidget'
              } & AllWidgets_GlobalDispatchAndDateCountdownWidget_Fragment)
            | ({
                __typename: 'GlobalEditorialWithFeature'
              } & AllWidgets_GlobalEditorialWithFeature_Fragment)
            | ({
                __typename: 'GlobalFooterAccreditationIcons'
              } & AllWidgets_GlobalFooterAccreditationIcons_Fragment)
            | ({
                __typename: 'GlobalFooterContactUs'
              } & AllWidgets_GlobalFooterContactUs_Fragment)
            | ({
                __typename: 'GlobalFourBestSellers'
              } & AllWidgets_GlobalFourBestSellers_Fragment)
            | ({
                __typename: 'GlobalFourButtonLink'
              } & AllWidgets_GlobalFourButtonLink_Fragment)
            | ({
                __typename: 'GlobalFourItemEditorial'
              } & AllWidgets_GlobalFourItemEditorial_Fragment)
            | ({
                __typename: 'GlobalGeneralImageBanner'
              } & AllWidgets_GlobalGeneralImageBanner_Fragment)
            | ({
                __typename: 'GlobalHelpCentreAccordion'
              } & AllWidgets_GlobalHelpCentreAccordion_Fragment)
            | ({
                __typename: 'GlobalHelpCentreAccordion2'
              } & AllWidgets_GlobalHelpCentreAccordion2_Fragment)
            | ({
                __typename: 'GlobalHelpCentreAccordionCollection'
              } & AllWidgets_GlobalHelpCentreAccordionCollection_Fragment)
            | ({
                __typename: 'GlobalHelpCentreCollection'
              } & AllWidgets_GlobalHelpCentreCollection_Fragment)
            | ({
                __typename: 'GlobalHeroCTABanner'
              } & AllWidgets_GlobalHeroCtaBanner_Fragment)
            | ({
                __typename: 'GlobalImageCard'
              } & AllWidgets_GlobalImageCard_Fragment)
            | ({
                __typename: 'GlobalImageCardSet'
              } & AllWidgets_GlobalImageCardSet_Fragment)
            | ({
                __typename: 'GlobalMultiButton'
              } & AllWidgets_GlobalMultiButton_Fragment)
            | ({
                __typename: 'GlobalPrimaryBanner'
              } & AllWidgets_GlobalPrimaryBanner_Fragment)
            | ({
                __typename: 'GlobalPrimaryBannerWithList'
              } & AllWidgets_GlobalPrimaryBannerWithList_Fragment)
            | ({
                __typename: 'GlobalPrimaryBannerWithTextOverlay'
              } & AllWidgets_GlobalPrimaryBannerWithTextOverlay_Fragment)
            | ({
                __typename: 'GlobalProductCardScroller'
              } & AllWidgets_GlobalProductCardScroller_Fragment)
            | ({
                __typename: 'GlobalReferral3StepGuide'
              } & AllWidgets_GlobalReferral3StepGuide_Fragment)
            | ({
                __typename: 'GlobalScalableLogos'
              } & AllWidgets_GlobalScalableLogos_Fragment)
            | ({
                __typename: 'GlobalSectionPeek'
              } & AllWidgets_GlobalSectionPeek_Fragment)
            | ({
                __typename: 'GlobalSetAndromeda'
              } & AllWidgets_GlobalSetAndromeda_Fragment)
            | ({
                __typename: 'GlobalSimpleTextCTAWidget'
              } & AllWidgets_GlobalSimpleTextCtaWidget_Fragment)
            | ({
                __typename: 'GlobalSixItemCategories'
              } & AllWidgets_GlobalSixItemCategories_Fragment)
            | ({
                __typename: 'GlobalSocialIcon'
              } & AllWidgets_GlobalSocialIcon_Fragment)
            | ({
                __typename: 'GlobalSocialIconCollection'
              } & AllWidgets_GlobalSocialIconCollection_Fragment)
            | ({
                __typename: 'GlobalSocialIconCollectionv2'
              } & AllWidgets_GlobalSocialIconCollectionv2_Fragment)
            | ({
                __typename: 'GlobalSocialIconv2'
              } & AllWidgets_GlobalSocialIconv2_Fragment)
            | ({
                __typename: 'GlobalStripBanner'
              } & AllWidgets_GlobalStripBanner_Fragment)
            | ({
                __typename: 'GlobalSubscriptionOptions'
              } & AllWidgets_GlobalSubscriptionOptions_Fragment)
            | ({
                __typename: 'GlobalTabbedWidgetSet'
              } & AllWidgets_GlobalTabbedWidgetSet_Fragment)
            | ({
                __typename: 'GlobalThreeItemEditorial'
              } & AllWidgets_GlobalThreeItemEditorial_Fragment)
            | ({
                __typename: 'GlobalThreeItemEditorialSubtitleBG'
              } & AllWidgets_GlobalThreeItemEditorialSubtitleBg_Fragment)
            | ({
                __typename: 'GlobalTransformationSlider'
              } & AllWidgets_GlobalTransformationSlider_Fragment)
            | ({
                __typename: 'GlobalTrendingHashtagBlock'
              } & AllWidgets_GlobalTrendingHashtagBlock_Fragment)
            | ({
                __typename: 'GlobalTrendingHashtagCollection'
              } & AllWidgets_GlobalTrendingHashtagCollection_Fragment)
            | ({
                __typename: 'GlobalTwoBestSellers'
              } & AllWidgets_GlobalTwoBestSellers_Fragment)
            | ({
                __typename: 'GlobalTwoItemEditorial'
              } & AllWidgets_GlobalTwoItemEditorial_Fragment)
            | ({
                __typename: 'GlobalTwoItemImageTextBlock'
              } & AllWidgets_GlobalTwoItemImageTextBlock_Fragment)
            | ({
                __typename: 'GlobalTwoItemImageTextCTA'
              } & AllWidgets_GlobalTwoItemImageTextCta_Fragment)
            | ({
                __typename: 'GlobalTwoItemImageTextCTA3070'
              } & AllWidgets_GlobalTwoItemImageTextCta3070_Fragment)
            | ({
                __typename: 'GlobalUGCCarousel'
              } & AllWidgets_GlobalUgcCarousel_Fragment)
            | ({
                __typename: 'GlobalVideoGallery'
              } & AllWidgets_GlobalVideoGallery_Fragment)
            | ({
                __typename: 'GlobalVideoHeroBannerWidget'
              } & AllWidgets_GlobalVideoHeroBannerWidget_Fragment)
            | ({
                __typename: 'GlobalVideoTextImageCTA'
              } & AllWidgets_GlobalVideoTextImageCta_Fragment)
            | ({
                __typename: 'GlobalWaitListSignUpFormResponses'
              } & AllWidgets_GlobalWaitListSignUpFormResponses_Fragment)
            | ({
                __typename: 'GlobalWaitListSignUpWidget'
              } & AllWidgets_GlobalWaitListSignUpWidget_Fragment)
            | ({
                __typename: 'GlobalWidgetSirius'
              } & AllWidgets_GlobalWidgetSirius_Fragment)
            | ({
                __typename: 'GlobalWidgetVega'
              } & AllWidgets_GlobalWidgetVega_Fragment)
            | ({
                __typename: 'GlobalYoutubeGalleryItem'
              } & AllWidgets_GlobalYoutubeGalleryItem_Fragment)
            | ({
                __typename: 'GlobalYoutubeGallerySet'
              } & AllWidgets_GlobalYoutubeGallerySet_Fragment)
            | ({
                __typename: 'LiveChatWidget'
              } & AllWidgets_LiveChatWidget_Fragment)
            | ({
                __typename: 'LoyaltyHubBirthdayGift'
              } & AllWidgets_LoyaltyHubBirthdayGift_Fragment)
            | ({
                __typename: 'LoyaltyHubTier'
              } & AllWidgets_LoyaltyHubTier_Fragment)
            | ({
                __typename: 'LoyaltyRewardTier'
              } & AllWidgets_LoyaltyRewardTier_Fragment)
            | ({
                __typename: 'LoyaltyRewardTiers'
              } & AllWidgets_LoyaltyRewardTiers_Fragment)
            | ({ __typename: 'MailingList' } & AllWidgets_MailingList_Fragment)
            | ({
                __typename: 'MultipleCTABanner'
              } & AllWidgets_MultipleCtaBanner_Fragment)
            | ({
                __typename: 'ProductListWidget'
              } & AllWidgets_ProductListWidget_Fragment)
            | ({
                __typename: 'ProductRecommenderItem'
              } & AllWidgets_ProductRecommenderItem_Fragment)
            | ({
                __typename: 'ProfileWidget'
              } & AllWidgets_ProfileWidget_Fragment)
            | ({
                __typename: 'ResponsiveBuildYourOwnBundle'
              } & AllWidgets_ResponsiveBuildYourOwnBundle_Fragment)
            | ({
                __typename: 'ResponsiveBuildYourOwnBundleProducts'
              } & AllWidgets_ResponsiveBuildYourOwnBundleProducts_Fragment)
            | ({
                __typename: 'ResponsiveComparisonTable'
              } & AllWidgets_ResponsiveComparisonTable_Fragment)
            | ({
                __typename: 'ResponsiveComparisonTableContainer'
              } & AllWidgets_ResponsiveComparisonTableContainer_Fragment)
            | ({
                __typename: 'ResponsiveProductBlockCarousel'
              } & AllWidgets_ResponsiveProductBlockCarousel_Fragment)
            | ({
                __typename: 'ResponsiveProductTabs'
              } & AllWidgets_ResponsiveProductTabs_Fragment)
            | ({
                __typename: 'ResponsiveSetSalon'
              } & AllWidgets_ResponsiveSetSalon_Fragment)
            | ({
                __typename: 'ResponsiveSetSalonWithSlides'
              } & AllWidgets_ResponsiveSetSalonWithSlides_Fragment)
            | ({
                __typename: 'ResponsiveSliderSet'
              } & AllWidgets_ResponsiveSliderSet_Fragment)
            | ({
                __typename: 'ResponsiveSuccessStoriesWidgetContainer'
              } & AllWidgets_ResponsiveSuccessStoriesWidgetContainer_Fragment)
            | ({
                __typename: 'ResponsiveSuccessStoryWidget'
              } & AllWidgets_ResponsiveSuccessStoryWidget_Fragment)
            | ({
                __typename: 'ResponsiveTwoItemComparisonContainer'
              } & AllWidgets_ResponsiveTwoItemComparisonContainer_Fragment)
            | ({
                __typename: 'ResponsiveTwoItemComparisonRow'
              } & AllWidgets_ResponsiveTwoItemComparisonRow_Fragment)
            | ({
                __typename: 'ResponsiveUSPBar'
              } & AllWidgets_ResponsiveUspBar_Fragment)
            | ({
                __typename: 'ResponsiveUSPBarCustomisable'
              } & AllWidgets_ResponsiveUspBarCustomisable_Fragment)
            | ({
                __typename: 'SeoProductCategory'
              } & AllWidgets_SeoProductCategory_Fragment)
            | ({
                __typename: 'SeoProductCategorySet'
              } & AllWidgets_SeoProductCategorySet_Fragment)
            | ({
                __typename: 'ShopTheLookHotSpot'
              } & AllWidgets_ShopTheLookHotSpot_Fragment)
            | ({
                __typename: 'SimpleTextReadMore'
              } & AllWidgets_SimpleTextReadMore_Fragment)
            | ({
                __typename: 'TopBrandsWidget'
              } & AllWidgets_TopBrandsWidget_Fragment)
            | ({
                __typename: 'TopProductCategory'
              } & AllWidgets_TopProductCategory_Fragment)
            | ({
                __typename: 'TopProductCategorySet'
              } & AllWidgets_TopProductCategorySet_Fragment)
            | ({
                __typename: 'TrustPilotCarouselWidget'
              } & AllWidgets_TrustPilotCarouselWidget_Fragment)
            | ({
                __typename: 'TrustPilotWidget'
              } & AllWidgets_TrustPilotWidget_Fragment)
            | ({
                __typename: 'VoucherCodesWidget'
              } & AllWidgets_VoucherCodesWidget_Fragment)
            | ({
                __typename: 'asymmetricGrid'
              } & AllWidgets_AsymmetricGrid_Fragment)
            | ({
                __typename: 'buildyourownbundle'
              } & AllWidgets_Buildyourownbundle_Fragment)
            | ({ __typename: 'buttonLink' } & AllWidgets_ButtonLink_Fragment)
            | ({
                __typename: 'categoryItemCard'
              } & AllWidgets_CategoryItemCard_Fragment)
            | ({
                __typename: 'categoryItemCardWithTextOverlay'
              } & AllWidgets_CategoryItemCardWithTextOverlay_Fragment)
            | ({
                __typename: 'coverageCalculator'
              } & AllWidgets_CoverageCalculator_Fragment)
            | ({
                __typename: 'eightLinkButtonWidget'
              } & AllWidgets_EightLinkButtonWidget_Fragment)
            | ({
                __typename: 'fouritemwindowproductslider'
              } & AllWidgets_Fouritemwindowproductslider_Fragment)
            | ({
                __typename: 'generalIframe'
              } & AllWidgets_GeneralIframe_Fragment)
            | ({
                __typename: 'generalQuoteBanner'
              } & AllWidgets_GeneralQuoteBanner_Fragment)
            | ({
                __typename: 'imageSelectCard'
              } & AllWidgets_ImageSelectCard_Fragment)
            | ({
                __typename: 'imageSelectSlider'
              } & AllWidgets_ImageSelectSlider_Fragment)
            | ({
                __typename: 'improvedSearchBestSellers'
              } & AllWidgets_ImprovedSearchBestSellers_Fragment)
            | ({ __typename: 'kitBuilder' } & AllWidgets_KitBuilder_Fragment)
            | ({
                __typename: 'loyaltyRewardsList'
              } & AllWidgets_LoyaltyRewardsList_Fragment)
            | ({
                __typename: 'loyaltyWelcomeMessage'
              } & AllWidgets_LoyaltyWelcomeMessage_Fragment)
            | ({
                __typename: 'multiCategoryCardSet'
              } & AllWidgets_MultiCategoryCardSet_Fragment)
            | ({
                __typename: 'multiCategoryCardSetV2'
              } & AllWidgets_MultiCategoryCardSetV2_Fragment)
            | ({ __typename: 'obsessVR' } & AllWidgets_ObsessVr_Fragment)
            | ({
                __typename: 'parcelLabWidget'
              } & AllWidgets_ParcelLabWidget_Fragment)
            | ({
                __typename: 'promoproductslider'
              } & AllWidgets_Promoproductslider_Fragment)
            | ({
                __typename: 'propositionBar'
              } & AllWidgets_PropositionBar_Fragment)
            | ({
                __typename: 'propositionBarIncorrect'
              } & AllWidgets_PropositionBarIncorrect_Fragment)
            | ({
                __typename: 'provenanceBrandHeader'
              } & AllWidgets_ProvenanceBrandHeader_Fragment)
            | ({
                __typename: 'qubitHomePageRecs'
              } & AllWidgets_QubitHomePageRecs_Fragment)
            | ({
                __typename: 'revieveWidget'
              } & AllWidgets_RevieveWidget_Fragment)
            | ({ __typename: 'shadeFinder' } & AllWidgets_ShadeFinder_Fragment)
            | ({ __typename: 'shopTheLook' } & AllWidgets_ShopTheLook_Fragment)
            | ({ __typename: 'simpleCTA' } & AllWidgets_SimpleCta_Fragment)
            | ({
                __typename: 'simpleDivider'
              } & AllWidgets_SimpleDivider_Fragment)
            | ({
                __typename: 'simpleTextWidgetSet'
              } & AllWidgets_SimpleTextWidgetSet_Fragment)
            | ({
                __typename: 'simpleTitleWidget'
              } & AllWidgets_SimpleTitleWidget_Fragment)
            | ({
                __typename: 'simpletextwidget'
              } & AllWidgets_Simpletextwidget_Fragment)
            | ({
                __typename: 'simpletextwidgetwithh1'
              } & AllWidgets_Simpletextwidgetwithh1_Fragment)
            | ({
                __typename: 'sponsoredProducts'
              } & AllWidgets_SponsoredProducts_Fragment)
            | ({
                __typename: 'sponsoredProductsCriteoHomepage'
              } & AllWidgets_SponsoredProductsCriteoHomepage_Fragment)
            | ({
                __typename: 'sponsoredProductsNew'
              } & AllWidgets_SponsoredProductsNew_Fragment)
            | ({
                __typename: 'storyStreamWidget'
              } & AllWidgets_StoryStreamWidget_Fragment)
            | ({
                __typename: 'subscribeAndSaveInformationModal'
              } & AllWidgets_SubscribeAndSaveInformationModal_Fragment)
            | ({
                __typename: 'swatchSelectSlider'
              } & AllWidgets_SwatchSelectSlider_Fragment)
            | ({
                __typename: 'swatchSelectSliderSet'
              } & AllWidgets_SwatchSelectSliderSet_Fragment)
            | ({
                __typename: 'trustReinforcementContainer'
              } & AllWidgets_TrustReinforcementContainer_Fragment)
            | ({
                __typename: 'trustReinforcementSection'
              } & AllWidgets_TrustReinforcementSection_Fragment)
            | ({
                __typename: 'videoEditorial'
              } & AllWidgets_VideoEditorial_Fragment)
            | ({
                __typename: 'yotiAgeVerification'
              } & AllWidgets_YotiAgeVerification_Fragment)
            | ({
                __typename: 'yotiCancelFrame'
              } & AllWidgets_YotiCancelFrame_Fragment)
          >
        >
        alternateLinks: Array<
          { __typename: 'PageAlternateLink' } & Pick<
            PageAlternateLink,
            'locale' | 'pagePath'
          >
        >
      }
  >
}

export type ProductbnplVariantQueryVariables = Exact<{
  sku: Scalars['SKU']
  currency: Currency
  shippingDestination: Country
}>

export type ProductbnplVariantQuery = { __typename?: 'Query' } & {
  productVariant?: Maybe<
    { __typename: 'ProductVariant' } & {
      buyNowPayLaterProviders?: Maybe<
        Array<
          { __typename: 'BuyNowPayLaterProvider' } & Pick<
            BuyNowPayLaterProvider,
            | 'providerName'
            | 'displayName'
            | 'numberOfInstalments'
            | 'landingPageLink'
          > & {
              instalmentAmount: { __typename: 'MoneyValue' } & Pick<
                MoneyValue,
                'currency' | 'amount' | 'displayValue' | 'scalarValue'
              >
            }
        >
      >
    }
  >
}

export type FrequentlyBoughtTogetherQueryVariables = Exact<{
  sku: Scalars['SKU']
  recommendation: ProductRecommendationType
  currency: Currency
  shippingDestination: Country
  limit: Scalars['Int']
}>

export type FrequentlyBoughtTogetherQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename?: 'Product' } & Pick<Product, 'sku' | 'title' | 'url'> & {
        images: Array<
          { __typename?: 'ProductImage' } & Pick<ProductImage, 'largeProduct'>
        >
        options: Array<
          { __typename?: 'VariantOption' } & Pick<VariantOption, 'key'> & {
              choices: Array<
                { __typename?: 'OptionChoice' } & Pick<
                  OptionChoice,
                  'optionKey' | 'key' | 'colour' | 'title'
                >
              >
            }
        >
        variants: Array<
          { __typename?: 'ProductVariant' } & Pick<
            ProductVariant,
            'sku' | 'title' | 'inStock' | 'maxPerOrder'
          > & {
              images: Array<
                { __typename?: 'ProductImage' } & Pick<
                  ProductImage,
                  'largeProduct'
                >
              >
              choices: Array<
                { __typename?: 'OptionChoice' } & Pick<
                  OptionChoice,
                  'optionKey' | 'key' | 'colour' | 'title'
                >
              >
              price?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'amount' | 'currency' | 'displayValue' | 'scalarValue'
                  >
                }
              >
            }
        >
        cheapestVariantPrice?: Maybe<
          { __typename?: 'ProductPrice' } & {
            price: { __typename?: 'MoneyValue' } & Pick<
              MoneyValue,
              'amount' | 'currency' | 'displayValue' | 'scalarValue'
            >
          }
        >
        recommendations?: Maybe<
          Array<
            { __typename?: 'Product' } & Pick<
              Product,
              'sku' | 'title' | 'url'
            > & {
                images: Array<
                  { __typename?: 'ProductImage' } & Pick<
                    ProductImage,
                    'largeProduct'
                  >
                >
                options: Array<
                  { __typename?: 'VariantOption' } & Pick<
                    VariantOption,
                    'key'
                  > & {
                      choices: Array<
                        { __typename?: 'OptionChoice' } & Pick<
                          OptionChoice,
                          'optionKey' | 'key' | 'colour' | 'title'
                        >
                      >
                    }
                >
                variants: Array<
                  { __typename?: 'ProductVariant' } & Pick<
                    ProductVariant,
                    'sku' | 'title' | 'inStock' | 'maxPerOrder'
                  > & {
                      images: Array<
                        { __typename?: 'ProductImage' } & Pick<
                          ProductImage,
                          'largeProduct'
                        >
                      >
                      choices: Array<
                        { __typename?: 'OptionChoice' } & Pick<
                          OptionChoice,
                          'optionKey' | 'key' | 'colour' | 'title'
                        >
                      >
                      price?: Maybe<
                        { __typename?: 'ProductPrice' } & {
                          price: { __typename?: 'MoneyValue' } & Pick<
                            MoneyValue,
                            | 'amount'
                            | 'currency'
                            | 'displayValue'
                            | 'scalarValue'
                          >
                          rrp: { __typename?: 'MoneyValue' } & Pick<
                            MoneyValue,
                            | 'amount'
                            | 'currency'
                            | 'displayValue'
                            | 'scalarValue'
                          >
                        }
                      >
                    }
                >
                cheapestVariantPrice?: Maybe<
                  { __typename?: 'ProductPrice' } & {
                    price: { __typename?: 'MoneyValue' } & Pick<
                      MoneyValue,
                      'amount' | 'currency' | 'displayValue' | 'scalarValue'
                    >
                  }
                >
              }
          >
        >
      }
  >
}

export type LoyaltyPointsQueryVariables = Exact<{
  sku: Scalars['SKU']
  currency: Currency
  shippingDestination: Country
}>

export type LoyaltyPointsQuery = { __typename?: 'Query' } & {
  productVariant?: Maybe<
    { __typename?: 'ProductVariant' } & Pick<
      ProductVariant,
      'earnableLoyaltyPoints'
    >
  >
}

export type VariantFieldsFragment = { __typename: 'ProductVariant' } & Pick<
  ProductVariant,
  'sku' | 'title' | 'inStock' | 'externalIdentifier'
> & {
    images: Array<
      { __typename: 'ProductImage' } & Pick<
        ProductImage,
        'thumbnail' | 'largeProduct' | 'zoom'
      >
    >
    price?: Maybe<
      { __typename: 'ProductPrice' } & {
        price: { __typename: 'MoneyValue' } & Pick<
          MoneyValue,
          'displayValue' | 'amount'
        >
        rrp: { __typename: 'MoneyValue' } & Pick<
          MoneyValue,
          'displayValue' | 'amount'
        >
      }
    >
    gbpPrice?: Maybe<
      { __typename: 'ProductPrice' } & {
        price: { __typename: 'MoneyValue' } & Pick<
          MoneyValue,
          'displayValue' | 'amount'
        >
        rrp: { __typename: 'MoneyValue' } & Pick<
          MoneyValue,
          'displayValue' | 'amount'
        >
      }
    >
    content: Array<
      { __typename: 'ProductContentItem' } & Pick<ProductContentItem, 'key'> & {
          value:
            | { __typename: 'ProductContentAttachmentValue' }
            | { __typename: 'ProductContentDateValue' }
            | { __typename: 'ProductContentIntListValue' }
            | { __typename: 'ProductContentIntValue' }
            | ({ __typename: 'ProductContentRichContentListValue' } & {
                value: Array<
                  { __typename: 'RichContent' } & {
                    content: Array<
                      { __typename: 'RichContentItem' } & Pick<
                        RichContentItem,
                        'content'
                      >
                    >
                  }
                >
              })
            | { __typename: 'ProductContentRichContentValue' }
            | { __typename: 'ProductContentStringListValue' }
            | { __typename: 'ProductContentStringValue' }
        }
    >
  }

export type ProductQueryVariables = Exact<{
  sku: Scalars['SKU']
  strict: Scalars['Boolean']
  currency: Currency
  shippingDestination: Country
  loadProductRecommendations?: Maybe<Scalars['Boolean']>
  recommendationsType?: ProductRecommendationType
  recommendationsLimit?: Scalars['Int']
}>

export type ProductQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename: 'Product' } & Pick<
      Product,
      'sku' | 'title' | 'url' | 'externalIdentifier' | 'linkedOn'
    > & {
        brand?: Maybe<
          { __typename?: 'Brand' } & Pick<Brand, 'name' | 'imageUrl'>
        >
        images: Array<
          { __typename: 'ProductImage' } & Pick<
            ProductImage,
            'thumbnail' | 'largeProduct' | 'zoom'
          >
        >
        variants: Array<
          { __typename?: 'ProductVariant' } & VariantFieldsFragment
        >
        defaultVariant?: Maybe<
          { __typename?: 'ProductVariant' } & VariantFieldsFragment
        >
        cheapestVariant?: Maybe<
          { __typename?: 'ProductVariant' } & {
            price?: Maybe<
              { __typename?: 'ProductPrice' } & {
                price: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'amount' | 'displayValue'
                >
                rrp: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'amount' | 'displayValue'
                >
              }
            >
          }
        >
        mostExpensiveVariant?: Maybe<
          { __typename?: 'ProductVariant' } & {
            price?: Maybe<
              { __typename?: 'ProductPrice' } & {
                price: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'amount' | 'displayValue'
                >
                rrp: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'amount' | 'displayValue'
                >
              }
            >
          }
        >
        reviews?: Maybe<
          { __typename: 'ProductReviews' } & Pick<
            ProductReviews,
            'total' | 'averageScore'
          >
        >
        content: Array<
          { __typename: 'ProductContentItem' } & Pick<
            ProductContentItem,
            'key'
          > & {
              value:
                | { __typename: 'ProductContentAttachmentValue' }
                | { __typename: 'ProductContentDateValue' }
                | { __typename: 'ProductContentIntListValue' }
                | { __typename: 'ProductContentIntValue' }
                | ({ __typename: 'ProductContentRichContentListValue' } & {
                    value: Array<
                      { __typename: 'RichContent' } & {
                        content: Array<
                          { __typename: 'RichContentItem' } & Pick<
                            RichContentItem,
                            'content'
                          >
                        >
                      }
                    >
                  })
                | ({ __typename: 'ProductContentRichContentValue' } & {
                    richContentValue: { __typename: 'RichContent' } & {
                      content: Array<
                        { __typename: 'RichContentItem' } & Pick<
                          RichContentItem,
                          'content'
                        >
                      >
                    }
                  })
                | { __typename: 'ProductContentStringListValue' }
                | { __typename: 'ProductContentStringValue' }
            }
        >
        recommendations?: Maybe<
          Array<
            { __typename: 'Product' } & Pick<
              Product,
              'sku' | 'url' | 'title'
            > & {
                brand?: Maybe<
                  { __typename?: 'Brand' } & Pick<Brand, 'name' | 'imageUrl'>
                >
                images: Array<
                  { __typename: 'ProductImage' } & Pick<
                    ProductImage,
                    'largeProduct'
                  >
                >
                variants: Array<
                  { __typename: 'ProductVariant' } & {
                    price?: Maybe<
                      { __typename: 'ProductPrice' } & {
                        price: { __typename: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'displayValue' | 'amount'
                        >
                        rrp: { __typename: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'displayValue' | 'amount'
                        >
                      }
                    >
                  }
                >
                reviews?: Maybe<
                  { __typename: 'ProductReviews' } & Pick<
                    ProductReviews,
                    'total' | 'averageScore'
                  >
                >
              }
          >
        >
        alternateLinks: Array<
          { __typename: 'AlternateLink' } & Pick<
            AlternateLink,
            'locale' | 'url'
          >
        >
      }
  >
}

export type ProductAvailabilityQueryVariables = Exact<{
  sku: Scalars['SKU']
}>

export type ProductAvailabilityQuery = { __typename?: 'Query' } & {
  productVariant?: Maybe<
    { __typename?: 'ProductVariant' } & Pick<
      ProductVariant,
      'availabilityMessage'
    >
  >
}

export type ProductBreadcrumbsQueryVariables = Exact<{
  sku: Scalars['SKU']
}>

export type ProductBreadcrumbsQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename?: 'Product' } & Pick<Product, 'url' | 'title'> & {
        breadcrumbs?: Maybe<
          Array<
            { __typename?: 'Breadcrumb' } & Pick<
              Breadcrumb,
              'displayName' | 'pagePath'
            >
          >
        >
      }
  >
}

export type ProductContentQueryVariables = Exact<{
  sku: Scalars['SKU']
  productContentKeys?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type ProductContentQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename: 'Product' } & {
      content: Array<
        { __typename: 'ProductContentItem' } & Pick<
          ProductContentItem,
          'key'
        > & {
            value:
              | { __typename: 'ProductContentAttachmentValue' }
              | { __typename: 'ProductContentDateValue' }
              | { __typename: 'ProductContentIntListValue' }
              | { __typename: 'ProductContentIntValue' }
              | ({ __typename: 'ProductContentRichContentListValue' } & {
                  richContentListValue: Array<
                    { __typename: 'RichContent' } & {
                      content: Array<
                        { __typename: 'RichContentItem' } & Pick<
                          RichContentItem,
                          'content'
                        >
                      >
                    }
                  >
                })
              | ({ __typename: 'ProductContentRichContentValue' } & {
                  richContentValue: { __typename: 'RichContent' } & {
                    content: Array<
                      { __typename: 'RichContentItem' } & Pick<
                        RichContentItem,
                        'content'
                      >
                    >
                  }
                })
              | ({ __typename: 'ProductContentStringListValue' } & {
                  stringListValue: ProductContentStringListValue['value']
                })
              | ({ __typename: 'ProductContentStringValue' } & {
                  stringValue: ProductContentStringValue['value']
                })
          }
      >
    }
  >
}

export type ProductDefaultAndVariantsQueryVariables = Exact<{
  sku: Scalars['SKU']
  currency: Currency
  shippingDestination: Country
}>

export type ProductDefaultAndVariantsQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename?: 'Product' } & Pick<Product, 'url'> & {
        options: Array<
          { __typename?: 'VariantOption' } & Pick<VariantOption, 'key'> & {
              choices: Array<
                { __typename?: 'OptionChoice' } & Pick<
                  OptionChoice,
                  'optionKey' | 'key' | 'colour' | 'title'
                >
              >
            }
        >
        variants: Array<
          { __typename?: 'ProductVariant' } & Pick<
            ProductVariant,
            'sku' | 'title' | 'inStock' | 'maxPerOrder'
          > & {
              images: Array<
                { __typename?: 'ProductImage' } & Pick<
                  ProductImage,
                  'largeProduct'
                >
              >
              choices: Array<
                { __typename?: 'OptionChoice' } & Pick<
                  OptionChoice,
                  'optionKey' | 'key' | 'colour' | 'title'
                >
              >
              price?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'amount' | 'currency' | 'displayValue' | 'scalarValue'
                  >
                  rrp: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'amount' | 'currency' | 'displayValue' | 'scalarValue'
                  >
                }
              >
            }
        >
        defaultVariant?: Maybe<
          { __typename?: 'ProductVariant' } & Pick<
            ProductVariant,
            'sku' | 'title' | 'inStock' | 'maxPerOrder'
          > & {
              images: Array<
                { __typename?: 'ProductImage' } & Pick<
                  ProductImage,
                  'largeProduct'
                >
              >
              choices: Array<
                { __typename?: 'OptionChoice' } & Pick<
                  OptionChoice,
                  'optionKey' | 'key' | 'colour' | 'title'
                >
              >
              price?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'amount' | 'currency' | 'displayValue' | 'scalarValue'
                  >
                  rrp: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'amount' | 'currency' | 'displayValue' | 'scalarValue'
                  >
                }
              >
            }
        >
      }
  >
}

export type ProductImagesQueryVariables = Exact<{
  sku: Scalars['SKU']
}>

export type ProductImagesQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename: 'Product' } & Pick<Product, 'title'> & {
        images: Array<
          { __typename: 'ProductImage' } & Pick<
            ProductImage,
            'thumbnail' | 'largeProduct' | 'zoom'
          >
        >
        variants: Array<
          { __typename: 'ProductVariant' } & Pick<ProductVariant, 'sku'> & {
              images: Array<
                { __typename: 'ProductImage' } & Pick<
                  ProductImage,
                  'thumbnail' | 'largeProduct' | 'zoom'
                >
              >
            }
        >
      }
  >
}

export type ProductInWishlistQueryVariables = Exact<{
  sku: Scalars['SKU']
}>

export type ProductInWishlistQuery = { __typename?: 'Query' } & {
  productVariant?: Maybe<
    { __typename?: 'ProductVariant' } & Pick<ProductVariant, 'inWishlist'>
  >
}

export type ProductOptionsQueryVariables = Exact<{
  sku: Scalars['SKU']
}>

export type ProductOptionsQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename?: 'Product' } & {
      options: Array<
        { __typename?: 'VariantOption' } & Pick<VariantOption, 'key'> & {
            choices: Array<
              { __typename?: 'OptionChoice' } & Pick<
                OptionChoice,
                'optionKey' | 'key' | 'colour' | 'title'
              >
            >
          }
      >
    }
  >
}

export type ProductOverviewQueryVariables = Exact<{
  sku: Scalars['SKU']
}>

export type ProductOverviewQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename: 'Product' } & Pick<Product, 'title' | 'url'> & {
        images: Array<
          { __typename?: 'ProductImage' } & Pick<ProductImage, 'largeProduct'>
        >
        content: Array<
          { __typename: 'ProductContentItem' } & {
            value:
              | { __typename: 'ProductContentAttachmentValue' }
              | { __typename: 'ProductContentDateValue' }
              | { __typename: 'ProductContentIntListValue' }
              | { __typename: 'ProductContentIntValue' }
              | ({ __typename: 'ProductContentRichContentListValue' } & {
                  value: Array<
                    { __typename: 'RichContent' } & {
                      content: Array<
                        { __typename?: 'RichContentItem' } & Pick<
                          RichContentItem,
                          'content'
                        >
                      >
                    }
                  >
                })
              | { __typename: 'ProductContentRichContentValue' }
              | { __typename: 'ProductContentStringListValue' }
              | { __typename: 'ProductContentStringValue' }
          }
        >
      }
  >
}

export type ResolverVariantFieldsFragment = {
  __typename?: 'ProductVariant'
} & MakeOptional<
  Pick<
    ProductVariant,
    | 'sku'
    | 'title'
    | 'externalIdentifier'
    | 'inStock'
    | 'maxPerOrder'
    | 'eligibleForFulfilmentMethods'
    | 'availabilityMessage'
    | 'notifyWhenInStockEnabled'
    | 'earnableLoyaltyPoints'
    | 'isSubscription'
    | 'isAutoRenewSubscription'
    | 'subscriptionFrequency'
    | 'subscriptionTerm'
    | 'subscriptionPaymentType'
    | 'availabilitySource'
    | 'isCheckStock'
    | 'isOrderInStore'
    | 'leadTime'
    | 'weightGroups'
    | 'isBookable'
    | 'inStockLocations'
    | 'barcode'
  >,
  | 'eligibleForFulfilmentMethods'
  | 'notifyWhenInStockEnabled'
  | 'earnableLoyaltyPoints'
  | 'isSubscription'
  | 'isAutoRenewSubscription'
  | 'subscriptionFrequency'
  | 'subscriptionTerm'
  | 'subscriptionPaymentType'
  | 'availabilitySource'
> & {
    price?: Maybe<
      { __typename?: 'ProductPrice' } & {
        price: { __typename?: 'MoneyValue' } & Pick<
          MoneyValue,
          'amount' | 'displayValue' | 'scalarValue'
        >
        rrp: { __typename?: 'MoneyValue' } & Pick<
          MoneyValue,
          'amount' | 'displayValue' | 'scalarValue'
        >
      }
    >
    images: Array<
      { __typename?: 'ProductImage' } & Pick<
        ProductImage,
        'thumbnail' | 'largeProduct' | 'zoom'
      >
    >
    choices: Array<
      { __typename?: 'OptionChoice' } & Pick<
        OptionChoice,
        'optionKey' | 'key' | 'colour' | 'title'
      >
    >
    buyNowPayLaterProviders?: Maybe<
      Array<
        { __typename?: 'BuyNowPayLaterProvider' } & Pick<
          BuyNowPayLaterProvider,
          | 'providerName'
          | 'displayName'
          | 'numberOfInstalments'
          | 'landingPageLink'
        > & {
            instalmentAmount: { __typename?: 'MoneyValue' } & Pick<
              MoneyValue,
              'currency' | 'amount' | 'displayValue' | 'scalarValue'
            >
          }
      >
    >
    variants: Array<
      { __typename?: 'ProductVariant' } & Pick<
        ProductVariant,
        'inStock' | 'inStockLocations' | 'title'
      > & {
          product?: Maybe<
            { __typename?: 'Product' } & Pick<Product, 'sku' | 'url'>
          >
          content: Array<
            { __typename?: 'ProductContentItem' } & Pick<
              ProductContentItem,
              'key'
            > & {
                value: {
                  __typename?: 'ProductContentStringValue'
                } & {
                  stringValue: ProductContentStringValue['value']
                }
              }
          >
          choices: Array<
            { __typename: 'OptionChoice' } & Pick<
              OptionChoice,
              'optionKey' | 'key'
            >
          >
        }
    >
    product?: Maybe<
      { __typename?: 'Product' } & Pick<Product, 'sku' | 'url' | 'title'> & {
          options: Array<
            { __typename?: 'VariantOption' } & Pick<VariantOption, 'key'> & {
                choices: Array<
                  { __typename?: 'OptionChoice' } & Pick<
                    OptionChoice,
                    'optionKey' | 'key' | 'colour' | 'title'
                  >
                >
              }
          >
          reviews?: Maybe<
            { __typename?: 'ProductReviews' } & Pick<
              ProductReviews,
              'total' | 'averageScore'
            >
          >
          breadcrumbs?: Maybe<
            Array<
              { __typename?: 'Breadcrumb' } & Pick<
                Breadcrumb,
                'displayName' | 'pagePath'
              >
            >
          >
          brand?: Maybe<
            { __typename?: 'Brand' } & Pick<Brand, 'name' | 'imageUrl'>
          >
          mostExpensiveVariant?: Maybe<
            { __typename?: 'ProductVariant' } & {
              price?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'amount'
                  >
                  rrp: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'amount'
                  >
                }
              >
            }
          >
          cheapestVariant?: Maybe<
            { __typename?: 'ProductVariant' } & {
              price?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'amount' | 'scalarValue'
                  >
                  rrp: { __typename: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'amount' | 'scalarValue'
                  >
                }
              >
            }
          >
          content: Array<
            { __typename?: 'ProductContentItem' } & Pick<
              ProductContentItem,
              'key'
            > & {
                value:
                  | ({ __typename?: 'ProductContentAttachmentValue' } & {
                      url: ProductContentAttachmentValue['value']
                    })
                  | { __typename?: 'ProductContentDateValue' }
                  | ({ __typename?: 'ProductContentIntListValue' } & {
                      intListValue: ProductContentIntListValue['value']
                    })
                  | ({ __typename?: 'ProductContentIntValue' } & {
                      intValue: ProductContentIntValue['value']
                    })
                  | ({ __typename?: 'ProductContentRichContentListValue' } & {
                      richContentListValue: Array<
                        { __typename?: 'RichContent' } & {
                          content: Array<
                            { __typename?: 'RichContentItem' } & Pick<
                              RichContentItem,
                              'content'
                            >
                          >
                        }
                      >
                    })
                  | ({ __typename?: 'ProductContentRichContentValue' } & {
                      richContentValue: { __typename?: 'RichContent' } & {
                        content: Array<
                          { __typename?: 'RichContentItem' } & Pick<
                            RichContentItem,
                            'content'
                          >
                        >
                      }
                    })
                  | ({ __typename?: 'ProductContentStringListValue' } & {
                      stringListValue: ProductContentStringListValue['value']
                    })
                  | ({ __typename?: 'ProductContentStringValue' } & {
                      stringValue: ProductContentStringValue['value']
                    })
              }
          >
        }
    >
    subscriptionContracts?: Maybe<
      Array<
        { __typename?: 'SubscriptionContract' } & Pick<
          SubscriptionContract,
          'id' | 'upsellMessage' | 'recommended' | 'initialDiscountPercentage'
        > & {
            frequencyDuration: {
              __typename?: 'SubscriptionFrequencyDuration'
            } & Pick<SubscriptionFrequencyDuration, 'unit' | 'duration'>
            initialPrice?: Maybe<
              { __typename?: 'ProductPrice' } & {
                price: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'currency' | 'amount' | 'displayValue' | 'scalarValue'
                >
                rrp: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'currency' | 'amount' | 'displayValue' | 'scalarValue'
                >
              }
            >
            recurringPrice?: Maybe<
              { __typename?: 'ProductPrice' } & {
                price: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'currency' | 'amount' | 'displayValue' | 'scalarValue'
                >
                rrp: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'currency' | 'amount' | 'displayValue' | 'scalarValue'
                >
              }
            >
          }
      >
    >
    returnsInformation?: Maybe<
      { __typename?: 'RichContent' } & {
        content: Array<
          { __typename?: 'RichContentItem' } & Pick<RichContentItem, 'content'>
        >
      }
    >
    deliveryInformation?: Maybe<
      { __typename?: 'RichContent' } & {
        content: Array<
          { __typename?: 'RichContentItem' } & Pick<RichContentItem, 'content'>
        >
      }
    >
    options: Array<
      { __typename: 'VariantOption' } & Pick<VariantOption, 'key'> & {
          choices: Array<
            { __typename: 'OptionChoice' } & Pick<
              OptionChoice,
              'optionKey' | 'key' | 'colour' | 'title'
            >
          >
        }
    >
  }

export type ProductPageQueryVariables = Exact<{
  sku: Scalars['SKU']
  currency: Currency
  shippingDestination: Country
  productContentKeys?: Maybe<Array<Scalars['String']> | Scalars['String']>
  buyNowPayLaterEnabled?: Maybe<Scalars['Boolean']>
  loyaltyEnabled?: Maybe<Scalars['Boolean']>
  notifyWhenInStockEnabled?: Maybe<Scalars['Boolean']>
  clickAndCollectEnabled?: Maybe<Scalars['Boolean']>
  manualRecommendationEnabled?: Maybe<Scalars['Boolean']>
  manualRecommendationType?: Maybe<ProductRecommendationType>
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  subscription?: Maybe<Scalars['Boolean']>
  sort?: ReviewSort
  recommendationLimit?: Scalars['Int']
  manualRecommendationLimit?: Scalars['Int']
  recommendation: ProductRecommendationType
  reviewsFilter?: Maybe<ReviewsFilterInput>
  vipPriceEnabled?: Maybe<Scalars['Boolean']>
  hasMultipleAvailabilitySources?: Scalars['Boolean']
}>

export type ProductPageQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename?: 'Product' } & Pick<
      Product,
      'title' | 'sku' | 'linkedOn' | 'url' | 'preorder' | 'externalIdentifier'
    > & {
        brand?: Maybe<
          { __typename?: 'Brand' } & Pick<Brand, 'name' | 'imageUrl' | 'page'>
        >
        sizeGuide?: Maybe<
          { __typename?: 'RichContent' } & {
            content: Array<
              { __typename?: 'RichContentItem' } & Pick<
                RichContentItem,
                'content'
              >
            >
          }
        >
        marketedSpecialOffer?: Maybe<
          { __typename?: 'ProductMarketedSpecialOffer' } & {
            title?: Maybe<
              { __typename?: 'RichContent' } & {
                content: Array<
                  { __typename?: 'RichContentItem' } & Pick<
                    RichContentItem,
                    'type' | 'content'
                  >
                >
              }
            >
            description?: Maybe<
              { __typename?: 'RichContent' } & {
                content: Array<
                  { __typename?: 'RichContentItem' } & Pick<
                    RichContentItem,
                    'type' | 'content'
                  >
                >
              }
            >
            landingPageLink?: Maybe<
              { __typename?: 'Hyperlink' } & Pick<
                Hyperlink,
                'text' | 'url' | 'openExternally' | 'noFollow'
              >
            >
            freeGiftProduct?: Maybe<
              { __typename?: 'ProductVariant' } & Pick<
                ProductVariant,
                'title'
              > & {
                  images: Array<
                    { __typename?: 'ProductImage' } & Pick<
                      ProductImage,
                      'largeProduct'
                    >
                  >
                  price?: Maybe<
                    { __typename?: 'ProductPrice' } & {
                      rrp: { __typename?: 'MoneyValue' } & Pick<
                        MoneyValue,
                        'displayValue'
                      >
                    }
                  >
                }
            >
          }
        >
        reviewTemplate?: Maybe<
          { __typename?: 'ReviewTemplate' } & Pick<ReviewTemplate, 'name'> & {
              features: Array<
                { __typename?: 'ReviewTemplateFeature' } & Pick<
                  ReviewTemplateFeature,
                  'name' | 'label' | 'hasDefaultMeasurement'
                > & {
                    measurementValues: Array<
                      { __typename?: 'ReviewMeasurementValue' } & Pick<
                        ReviewMeasurementValue,
                        'value' | 'label' | 'order' | 'mappingValue'
                      >
                    >
                  }
              >
            }
        >
        reviews?: Maybe<
          { __typename?: 'ProductReviews' } & Pick<
            ProductReviews,
            | 'averageScore'
            | 'maxScore'
            | 'total'
            | 'count1Score'
            | 'count2Score'
            | 'count3Score'
            | 'count4Score'
            | 'count5Score'
          > & {
              featureScores: Array<
                { __typename?: 'AverageFeatureScore' } & Pick<
                  AverageFeatureScore,
                  'name' | 'value'
                >
              >
              reviews?: Maybe<
                { __typename?: 'Reviews' } & Pick<Reviews, 'total'> & {
                    reviews: Array<
                      { __typename?: 'Review' } & Pick<
                        Review,
                        | 'id'
                        | 'title'
                        | 'authorName'
                        | 'verifiedPurchase'
                        | 'posted'
                        | 'positiveVotes'
                        | 'negativeVotes'
                      > & {
                          elements: Array<
                            | ({ __typename?: 'RatingReviewElement' } & Pick<
                                RatingReviewElement,
                                'key' | 'maxScore' | 'score'
                              >)
                            | ({ __typename?: 'TextReviewElement' } & Pick<
                                TextReviewElement,
                                'key' | 'value'
                              >)
                          >
                        }
                    >
                  }
              >
            }
        >
        images: Array<
          { __typename?: 'ProductImage' } & Pick<
            ProductImage,
            'thumbnail' | 'largeProduct' | 'zoom'
          >
        >
        options: Array<
          { __typename?: 'VariantOption' } & Pick<VariantOption, 'key'> & {
              choices: Array<
                { __typename?: 'OptionChoice' } & Pick<
                  OptionChoice,
                  'optionKey' | 'key' | 'colour' | 'title'
                >
              >
            }
        >
        breadcrumbs?: Maybe<
          Array<
            { __typename?: 'Breadcrumb' } & Pick<
              Breadcrumb,
              'displayName' | 'pagePath'
            >
          >
        >
        otherCustomersBought?: Maybe<
          Array<
            { __typename?: 'Product' } & Pick<
              Product,
              'sku' | 'url' | 'title' | 'externalIdentifier'
            > & {
                brand?: Maybe<
                  { __typename?: 'Brand' } & Pick<Brand, 'name' | 'imageUrl'>
                >
                images: Array<
                  { __typename?: 'ProductImage' } & Pick<
                    ProductImage,
                    'largeProduct'
                  >
                >
                cheapestVariant?: Maybe<
                  { __typename?: 'ProductVariant' } & {
                    price?: Maybe<
                      { __typename?: 'ProductPrice' } & {
                        price: { __typename: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'displayValue' | 'amount' | 'scalarValue'
                        >
                        rrp: { __typename: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'displayValue' | 'amount' | 'scalarValue'
                        >
                      }
                    >
                  }
                >
                mostExpensiveVariant?: Maybe<
                  { __typename?: 'ProductVariant' } & {
                    price?: Maybe<
                      { __typename?: 'ProductPrice' } & {
                        price: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'displayValue' | 'amount'
                        >
                        rrp: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'displayValue' | 'amount'
                        >
                      }
                    >
                  }
                >
                defaultVariant?: Maybe<
                  { __typename?: 'ProductVariant' } & MakeOptional<
                    Pick<
                      ProductVariant,
                      | 'eligibleForFulfilmentMethods'
                      | 'inStock'
                      | 'isCheckStock'
                      | 'isOrderInStore'
                      | 'inStockLocations'
                      | 'isBookable'
                      | 'leadTime'
                      | 'weightGroups'
                    >,
                    'eligibleForFulfilmentMethods'
                  > & {
                      images: Array<
                        { __typename?: 'ProductImage' } & Pick<
                          ProductImage,
                          'thumbnail' | 'largeProduct' | 'zoom'
                        >
                      >
                      price?: Maybe<
                        { __typename?: 'ProductPrice' } & {
                          price: { __typename: 'MoneyValue' } & Pick<
                            MoneyValue,
                            | 'displayValue'
                            | 'amount'
                            | 'scalarValue'
                            | 'currency'
                          >
                          rrp: { __typename: 'MoneyValue' } & Pick<
                            MoneyValue,
                            | 'displayValue'
                            | 'amount'
                            | 'scalarValue'
                            | 'currency'
                          >
                        }
                      >
                    }
                >
                variants: Array<
                  { __typename?: 'ProductVariant' } & MakeOptional<
                    Pick<
                      ProductVariant,
                      | 'sku'
                      | 'title'
                      | 'inStock'
                      | 'maxPerOrder'
                      | 'eligibleForFulfilmentMethods'
                      | 'leadTime'
                      | 'weightGroups'
                      | 'inStockLocations'
                      | 'isBookable'
                      | 'isCheckStock'
                      | 'isOrderInStore'
                    >,
                    'eligibleForFulfilmentMethods'
                  > & {
                      images: Array<
                        { __typename?: 'ProductImage' } & Pick<
                          ProductImage,
                          'thumbnail' | 'largeProduct' | 'zoom'
                        >
                      >
                      product?: Maybe<
                        { __typename?: 'Product' } & Pick<
                          Product,
                          'url' | 'sku'
                        >
                      >
                      choices: Array<
                        { __typename?: 'OptionChoice' } & Pick<
                          OptionChoice,
                          'optionKey' | 'key' | 'colour' | 'title'
                        >
                      >
                      price?: Maybe<
                        { __typename?: 'ProductPrice' } & {
                          price: { __typename: 'MoneyValue' } & Pick<
                            MoneyValue,
                            'displayValue' | 'amount' | 'scalarValue'
                          >
                          rrp: { __typename: 'MoneyValue' } & Pick<
                            MoneyValue,
                            'displayValue' | 'amount' | 'scalarValue'
                          >
                        }
                      >
                      content: Array<
                        { __typename?: 'ProductContentItem' } & Pick<
                          ProductContentItem,
                          'key'
                        > & {
                            value: {
                              __typename?: 'ProductContentStringValue'
                            } & {
                              stringValue: ProductContentStringValue['value']
                            }
                          }
                      >
                    }
                >
                reviews?: Maybe<
                  { __typename?: 'ProductReviews' } & Pick<
                    ProductReviews,
                    'total' | 'averageScore'
                  >
                >
                options: Array<
                  { __typename?: 'VariantOption' } & Pick<
                    VariantOption,
                    'key'
                  > & {
                      choices: Array<
                        { __typename?: 'OptionChoice' } & Pick<
                          OptionChoice,
                          'optionKey' | 'key' | 'colour' | 'title'
                        >
                      >
                    }
                >
                content: Array<
                  { __typename?: 'ProductContentItem' } & Pick<
                    ProductContentItem,
                    'key'
                  > & {
                      value:
                        | { __typename?: 'ProductContentAttachmentValue' }
                        | { __typename?: 'ProductContentDateValue' }
                        | { __typename?: 'ProductContentIntListValue' }
                        | { __typename?: 'ProductContentIntValue' }
                        | ({
                            __typename: 'ProductContentRichContentListValue'
                          } & {
                            richContentListValue: Array<
                              { __typename: 'RichContent' } & {
                                content: Array<
                                  { __typename?: 'RichContentItem' } & Pick<
                                    RichContentItem,
                                    'content'
                                  >
                                >
                              }
                            >
                          })
                        | ({
                            __typename: 'ProductContentRichContentValue'
                          } & {
                            richContentValue: {
                              __typename: 'RichContent'
                            } & {
                              content: Array<
                                { __typename?: 'RichContentItem' } & Pick<
                                  RichContentItem,
                                  'content'
                                >
                              >
                            }
                          })
                        | ({
                            __typename: 'ProductContentStringListValue'
                          } & {
                            stringListValue: ProductContentStringListValue['value']
                          })
                        | ({ __typename: 'ProductContentStringValue' } & {
                            stringValue: ProductContentStringValue['value']
                          })
                    }
                >
              }
          >
        >
        manualRecommendations?: Maybe<
          Array<
            { __typename?: 'Product' } & Pick<
              Product,
              'sku' | 'url' | 'title' | 'linkedOn' | 'preorder' | 'content'
            > & {
                brand?: Maybe<
                  { __typename?: 'Brand' } & Pick<Brand, 'name' | 'imageUrl'>
                >
                images: Array<
                  { __typename?: 'ProductImage' } & Pick<
                    ProductImage,
                    'thumbnail' | 'largeProduct'
                  >
                >
                sizeGuide?: Maybe<
                  { __typename?: 'RichContent' } & {
                    content: Array<
                      { __typename?: 'RichContentItem' } & Pick<
                        RichContentItem,
                        'content'
                      >
                    >
                  }
                >
                variants: Array<
                  {
                    __typename?: 'ProductVariant'
                  } & ResolverVariantFieldsFragment
                >
                defaultVariant?: Maybe<
                  { __typename?: 'ProductVariant' } & MakeOptional<
                    Pick<
                      ProductVariant,
                      | 'eligibleForFulfilmentMethods'
                      | 'inStock'
                      | 'isCheckStock'
                      | 'isOrderInStore'
                      | 'isBookable'
                      | 'weightGroups'
                      | 'inStockLocations'
                      | 'leadTime'
                    >,
                    'eligibleForFulfilmentMethods'
                  > &
                    ResolverVariantFieldsFragment
                >
                mostExpensiveVariant?: Maybe<
                  { __typename?: 'ProductVariant' } & {
                    price?: Maybe<
                      { __typename?: 'ProductPrice' } & {
                        price: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'displayValue' | 'amount'
                        >
                        rrp: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'displayValue' | 'amount'
                        >
                      }
                    >
                  }
                >
                cheapestVariant?: Maybe<
                  {
                    __typename?: 'ProductVariant'
                  } & ResolverVariantFieldsFragment
                >
                reviews?: Maybe<
                  { __typename?: 'ProductReviews' } & Pick<
                    ProductReviews,
                    'total' | 'averageScore'
                  >
                >
                options: Array<
                  { __typename?: 'VariantOption' } & Pick<
                    VariantOption,
                    'key' | 'localizedKey'
                  > & {
                      choices: Array<
                        { __typename?: 'OptionChoice' } & Pick<
                          OptionChoice,
                          'optionKey' | 'key' | 'colour' | 'title'
                        >
                      >
                    }
                >
              }
          >
        >
        recommendations?: Maybe<
          Array<
            { __typename?: 'Product' } & Pick<
              Product,
              'sku' | 'url' | 'title' | 'externalIdentifier'
            > & {
                brand?: Maybe<
                  { __typename?: 'Brand' } & Pick<Brand, 'name' | 'imageUrl'>
                >
                images: Array<
                  { __typename?: 'ProductImage' } & Pick<
                    ProductImage,
                    'largeProduct'
                  >
                >
                cheapestVariant?: Maybe<
                  { __typename?: 'ProductVariant' } & {
                    images: Array<
                      { __typename?: 'ProductImage' } & Pick<
                        ProductImage,
                        'thumbnail' | 'largeProduct' | 'zoom'
                      >
                    >
                    price?: Maybe<
                      { __typename?: 'ProductPrice' } & {
                        rrp: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'amount' | 'displayValue' | 'scalarValue' | 'currency'
                        >
                        price: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'amount' | 'displayValue' | 'scalarValue' | 'currency'
                        >
                      }
                    >
                  }
                >
                mostExpensiveVariant?: Maybe<
                  { __typename?: 'ProductVariant' } & {
                    price?: Maybe<
                      { __typename?: 'ProductPrice' } & {
                        price: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'displayValue' | 'amount' | 'currency'
                        >
                        rrp: { __typename?: 'MoneyValue' } & Pick<
                          MoneyValue,
                          'displayValue' | 'amount' | 'currency'
                        >
                      }
                    >
                  }
                >
                defaultVariant?: Maybe<
                  { __typename?: 'ProductVariant' } & MakeOptional<
                    Pick<
                      ProductVariant,
                      | 'eligibleForFulfilmentMethods'
                      | 'inStock'
                      | 'isCheckStock'
                      | 'isOrderInStore'
                      | 'isBookable'
                      | 'weightGroups'
                      | 'inStockLocations'
                      | 'leadTime'
                      | 'availabilityMessage'
                      | 'availabilitySource'
                      | 'choices'
                      | 'clickAndCollectStores'
                      | 'eligibleForWishlist'
                      | 'isAutoRenewSubscription'
                      | 'isComingSoon'
                      | 'isSubscription'
                      | 'notifyWhenInStockEnabled'
                      | 'sku'
                      | 'subscriptionContracts'
                      | 'title'
                      | 'externalIdentifier'
                    >,
                    'eligibleForFulfilmentMethods'
                  > & {
                      images: Array<
                        { __typename?: 'ProductImage' } & Pick<
                          ProductImage,
                          'thumbnail' | 'largeProduct' | 'zoom'
                        >
                      >
                      content: Array<
                        { __typename?: 'ProductContentItem' } & Pick<
                          ProductContentItem,
                          'key'
                        > & {
                            value: {
                              __typename?: 'ProductContentStringValue'
                            } & {
                              stringValue: ProductContentStringValue['value']
                            }
                          }
                      >
                      price?: Maybe<
                        { __typename?: 'ProductPrice' } & {
                          price: { __typename?: 'MoneyValue' } & Pick<
                            MoneyValue,
                            | 'amount'
                            | 'displayValue'
                            | 'scalarValue'
                            | 'currency'
                          >
                          rrp: { __typename?: 'MoneyValue' } & Pick<
                            MoneyValue,
                            | 'amount'
                            | 'displayValue'
                            | 'scalarValue'
                            | 'currency'
                          >
                        }
                      >
                    }
                >
                variants: Array<
                  { __typename?: 'ProductVariant' } & Pick<
                    ProductVariant,
                    | 'sku'
                    | 'title'
                    | 'inStock'
                    | 'maxPerOrder'
                    | 'isCheckStock'
                    | 'isOrderInStore'
                    | 'inStockLocations'
                  > & {
                      images: Array<
                        { __typename?: 'ProductImage' } & Pick<
                          ProductImage,
                          'largeProduct'
                        >
                      >
                      product?: Maybe<
                        { __typename?: 'Product' } & Pick<
                          Product,
                          'url' | 'sku'
                        >
                      >
                      choices: Array<
                        { __typename?: 'OptionChoice' } & Pick<
                          OptionChoice,
                          'optionKey' | 'key' | 'colour' | 'title'
                        >
                      >
                      price?: Maybe<
                        { __typename?: 'ProductPrice' } & {
                          price: { __typename: 'MoneyValue' } & Pick<
                            MoneyValue,
                            'displayValue' | 'amount' | 'scalarValue'
                          >
                          rrp: { __typename: 'MoneyValue' } & Pick<
                            MoneyValue,
                            'displayValue' | 'amount' | 'scalarValue'
                          >
                        }
                      >
                      content: Array<
                        { __typename?: 'ProductContentItem' } & Pick<
                          ProductContentItem,
                          'key'
                        > & {
                            value: {
                              __typename?: 'ProductContentStringValue'
                            } & {
                              stringValue: ProductContentStringValue['value']
                            }
                          }
                      >
                    }
                >
                reviews?: Maybe<
                  { __typename?: 'ProductReviews' } & Pick<
                    ProductReviews,
                    | 'averageScore'
                    | 'total'
                    | 'count1Score'
                    | 'count2Score'
                    | 'count3Score'
                    | 'count4Score'
                    | 'count5Score'
                  >
                >
                options: Array<
                  { __typename?: 'VariantOption' } & Pick<
                    VariantOption,
                    'key'
                  > & {
                      choices: Array<
                        { __typename?: 'OptionChoice' } & Pick<
                          OptionChoice,
                          'optionKey' | 'key' | 'colour' | 'title'
                        >
                      >
                    }
                >
                content: Array<
                  { __typename?: 'ProductContentItem' } & Pick<
                    ProductContentItem,
                    'key'
                  > & {
                      value:
                        | ({ __typename?: 'ProductContentAttachmentValue' } & {
                            url: ProductContentAttachmentValue['value']
                          })
                        | { __typename?: 'ProductContentDateValue' }
                        | ({ __typename?: 'ProductContentIntListValue' } & {
                            intListValue: ProductContentIntListValue['value']
                          })
                        | ({ __typename?: 'ProductContentIntValue' } & {
                            intValue: ProductContentIntValue['value']
                          })
                        | ({
                            __typename?: 'ProductContentRichContentListValue'
                          } & {
                            richContentListValue: Array<
                              { __typename?: 'RichContent' } & {
                                content: Array<
                                  { __typename?: 'RichContentItem' } & Pick<
                                    RichContentItem,
                                    'content'
                                  >
                                >
                              }
                            >
                          })
                        | ({ __typename?: 'ProductContentRichContentValue' } & {
                            richContentValue: { __typename?: 'RichContent' } & {
                              content: Array<
                                { __typename?: 'RichContentItem' } & Pick<
                                  RichContentItem,
                                  'content'
                                >
                              >
                            }
                          })
                        | ({ __typename?: 'ProductContentStringListValue' } & {
                            stringListValue: ProductContentStringListValue['value']
                          })
                        | ({ __typename?: 'ProductContentStringValue' } & {
                            stringValue: ProductContentStringValue['value']
                          })
                    }
                >
              }
          >
        >
        variants: Array<
          { __typename?: 'ProductVariant' } & ResolverVariantFieldsFragment
        >
        defaultVariant?: Maybe<
          { __typename?: 'ProductVariant' } & MakeOptional<
            Pick<
              ProductVariant,
              | 'inStock'
              | 'isCheckStock'
              | 'isOrderInStore'
              | 'isBookable'
              | 'weightGroups'
              | 'inStockLocations'
              | 'leadTime'
              | 'eligibleForFulfilmentMethods'
            >,
            'eligibleForFulfilmentMethods'
          > &
            ResolverVariantFieldsFragment
        >
        cheapestVariant?: Maybe<
          { __typename?: 'ProductVariant' } & ResolverVariantFieldsFragment
        >
        mostExpensiveVariant?: Maybe<
          { __typename?: 'ProductVariant' } & ResolverVariantFieldsFragment
        >
        content: Array<
          { __typename?: 'ProductContentItem' } & Pick<
            ProductContentItem,
            'key'
          > & {
              value:
                | ({ __typename?: 'ProductContentAttachmentValue' } & {
                    url: ProductContentAttachmentValue['value']
                  })
                | { __typename?: 'ProductContentDateValue' }
                | ({ __typename?: 'ProductContentIntListValue' } & {
                    intListValue: ProductContentIntListValue['value']
                  })
                | ({ __typename?: 'ProductContentIntValue' } & {
                    intValue: ProductContentIntValue['value']
                  })
                | ({ __typename?: 'ProductContentRichContentListValue' } & {
                    richContentListValue: Array<
                      { __typename?: 'RichContent' } & {
                        content: Array<
                          { __typename?: 'RichContentItem' } & Pick<
                            RichContentItem,
                            'content'
                          >
                        >
                      }
                    >
                  })
                | ({ __typename?: 'ProductContentRichContentValue' } & {
                    richContentValue: { __typename?: 'RichContent' } & {
                      content: Array<
                        { __typename?: 'RichContentItem' } & Pick<
                          RichContentItem,
                          'content'
                        >
                      >
                    }
                  })
                | ({ __typename?: 'ProductContentStringListValue' } & {
                    stringListValue: ProductContentStringListValue['value']
                  })
                | ({ __typename?: 'ProductContentStringValue' } & {
                    stringValue: ProductContentStringValue['value']
                  })
            }
        >
        alternateLinks: Array<
          { __typename?: 'AlternateLink' } & Pick<
            AlternateLink,
            'locale' | 'url'
          >
        >
      }
  >
}

export type ProductPriceQueryVariables = Exact<{
  sku: Scalars['SKU']
  currency: Currency
  shippingDestination: Country
}>

export type ProductPriceQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename?: 'Product' } & {
      variants: Array<
        { __typename?: 'ProductVariant' } & {
          product?: Maybe<{ __typename?: 'Product' } & Pick<Product, 'sku'>>
          price?: Maybe<
            { __typename?: 'ProductPrice' } & {
              price: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue' | 'amount'
              >
            }
          >
        }
      >
      defaultVariant?: Maybe<
        { __typename?: 'ProductVariant' } & {
          price?: Maybe<
            { __typename?: 'ProductPrice' } & {
              price: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue' | 'amount'
              >
              rrp: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'displayValue' | 'amount'
              >
            }
          >
        }
      >
      cheapestVariantPrice?: Maybe<
        { __typename?: 'ProductPrice' } & {
          price: { __typename?: 'MoneyValue' } & Pick<
            MoneyValue,
            'displayValue' | 'amount'
          >
          rrp: { __typename?: 'MoneyValue' } & Pick<
            MoneyValue,
            'displayValue' | 'amount'
          >
        }
      >
    }
  >
}

export type ProductRecommendationsQueryVariables = Exact<{
  sku: Scalars['SKU']
  recommendation: ProductRecommendationType
  currency: Currency
  shippingDestination: Country
  limit: Scalars['Int']
}>

export type ProductRecommendationsQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename?: 'Product' } & {
      recommendations?: Maybe<
        Array<
          { __typename?: 'Product' } & Pick<
            Product,
            'sku' | 'title' | 'url'
          > & {
              brand?: Maybe<
                { __typename?: 'Brand' } & Pick<Brand, 'name' | 'imageUrl'>
              >
              images: Array<
                { __typename?: 'ProductImage' } & Pick<
                  ProductImage,
                  'largeProduct'
                >
              >
              reviews?: Maybe<
                { __typename?: 'ProductReviews' } & Pick<
                  ProductReviews,
                  'averageScore' | 'total'
                >
              >
              options: Array<
                { __typename?: 'VariantOption' } & Pick<
                  VariantOption,
                  'key'
                > & {
                    choices: Array<
                      { __typename?: 'OptionChoice' } & Pick<
                        OptionChoice,
                        'optionKey' | 'key' | 'colour' | 'title'
                      >
                    >
                  }
              >
              variants: Array<
                { __typename?: 'ProductVariant' } & Pick<
                  ProductVariant,
                  'inStock'
                > & {
                    product?: Maybe<
                      { __typename?: 'Product' } & Pick<Product, 'url'>
                    >
                    choices: Array<
                      { __typename?: 'OptionChoice' } & Pick<
                        OptionChoice,
                        'optionKey' | 'key' | 'colour' | 'title'
                      >
                    >
                  }
              >
              cheapestVariantPrice?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue'
                  >
                  rrp: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue'
                  >
                }
              >
            }
        >
      >
    }
  >
}

export type ProductReviewContentQueryVariables = Exact<{
  sku: Scalars['SKU']
  offset: Scalars['Int']
  limit: Scalars['Int']
  sort: ReviewSort
}>

export type ProductReviewContentQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename: 'Product' } & Pick<Product, 'title' | 'url'> & {
        reviews?: Maybe<
          { __typename: 'ProductReviews' } & Pick<
            ProductReviews,
            | 'averageScore'
            | 'maxScore'
            | 'total'
            | 'count1Score'
            | 'count2Score'
            | 'count3Score'
            | 'count4Score'
            | 'count5Score'
          > & {
              reviews?: Maybe<
                { __typename: 'Reviews' } & Pick<Reviews, 'total'> & {
                    reviews: Array<
                      { __typename: 'Review' } & Pick<
                        Review,
                        | 'id'
                        | 'title'
                        | 'authorName'
                        | 'verifiedPurchase'
                        | 'posted'
                        | 'positiveVotes'
                        | 'negativeVotes'
                      > & {
                          elements: Array<
                            | ({ __typename: 'RatingReviewElement' } & Pick<
                                RatingReviewElement,
                                'key' | 'maxScore' | 'score'
                              >)
                            | ({ __typename: 'TextReviewElement' } & Pick<
                                TextReviewElement,
                                'key' | 'value'
                              >)
                          >
                        }
                    >
                  }
              >
            }
        >
      }
  >
}

export type ProductShoeSizeMeQueryVariables = Exact<{
  sku: Scalars['SKU']
}>

export type ProductShoeSizeMeQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename?: 'Product' } & {
      variants: Array<
        { __typename?: 'ProductVariant' } & Pick<ProductVariant, 'inStock'> & {
            choices: Array<
              { __typename?: 'OptionChoice' } & Pick<
                OptionChoice,
                'optionKey' | 'title'
              >
            >
          }
      >
    }
  >
}

export type ProductSummaryDetailsQueryVariables = Exact<{
  sku: Scalars['SKU']
}>

export type ProductSummaryDetailsQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename: 'Product' } & Pick<Product, 'title'> & {
        brand?: Maybe<
          { __typename?: 'Brand' } & Pick<Brand, 'name' | 'imageUrl'>
        >
        reviews?: Maybe<
          { __typename: 'ProductReviews' } & Pick<
            ProductReviews,
            | 'averageScore'
            | 'total'
            | 'count1Score'
            | 'count2Score'
            | 'count3Score'
            | 'count4Score'
            | 'count5Score'
          >
        >
        content: Array<
          { __typename: 'ProductContentItem' } & {
            value:
              | { __typename?: 'ProductContentAttachmentValue' }
              | { __typename?: 'ProductContentDateValue' }
              | { __typename?: 'ProductContentIntListValue' }
              | { __typename?: 'ProductContentIntValue' }
              | { __typename?: 'ProductContentRichContentListValue' }
              | ({ __typename: 'ProductContentRichContentValue' } & {
                  value: { __typename: 'RichContent' } & {
                    content: Array<
                      { __typename: 'RichContentItem' } & Pick<
                        RichContentItem,
                        'content'
                      >
                    >
                  }
                })
              | { __typename?: 'ProductContentStringListValue' }
              | { __typename?: 'ProductContentStringValue' }
          }
        >
      }
  >
}

export type ProductSummaryPriceQueryVariables = Exact<{
  sku: Scalars['SKU']
  currency: Currency
  shippingDestination: Country
}>

export type ProductSummaryPriceQuery = { __typename?: 'Query' } & {
  productVariant?: Maybe<
    { __typename?: 'ProductVariant' } & {
      price?: Maybe<
        { __typename?: 'ProductPrice' } & {
          price: { __typename?: 'MoneyValue' } & Pick<
            MoneyValue,
            'amount' | 'displayValue'
          >
          rrp: { __typename?: 'MoneyValue' } & Pick<
            MoneyValue,
            'amount' | 'displayValue'
          >
        }
      >
    }
  >
  product?: Maybe<
    { __typename: 'Product' } & {
      variants: Array<
        { __typename?: 'ProductVariant' } & Pick<ProductVariant, 'sku'> & {
            price?: Maybe<
              { __typename?: 'ProductPrice' } & {
                price: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'amount' | 'displayValue'
                >
                rrp: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'amount' | 'displayValue'
                >
              }
            >
          }
      >
      defaultVariant?: Maybe<
        { __typename?: 'ProductVariant' } & {
          price?: Maybe<
            { __typename?: 'ProductPrice' } & {
              price: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'amount' | 'displayValue'
              >
              rrp: { __typename?: 'MoneyValue' } & Pick<
                MoneyValue,
                'amount' | 'displayValue'
              >
            }
          >
        }
      >
      cheapestVariantPrice?: Maybe<
        { __typename?: 'ProductPrice' } & {
          price: { __typename?: 'MoneyValue' } & Pick<
            MoneyValue,
            'amount' | 'displayValue'
          >
          rrp: { __typename?: 'MoneyValue' } & Pick<
            MoneyValue,
            'amount' | 'displayValue'
          >
        }
      >
    }
  >
}

export type ProductTagsQueryVariables = Exact<{
  sku: Scalars['SKU']
  productContentKeys?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type ProductTagsQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename: 'Product' } & {
      content: Array<
        { __typename: 'ProductContentItem' } & Pick<
          ProductContentItem,
          'key'
        > & {
            value:
              | { __typename: 'ProductContentAttachmentValue' }
              | { __typename: 'ProductContentDateValue' }
              | { __typename: 'ProductContentIntListValue' }
              | { __typename: 'ProductContentIntValue' }
              | { __typename: 'ProductContentRichContentListValue' }
              | { __typename: 'ProductContentRichContentValue' }
              | ({ __typename: 'ProductContentStringListValue' } & Pick<
                  ProductContentStringListValue,
                  'value'
                >)
              | { __typename: 'ProductContentStringValue' }
          }
      >
    }
  >
}

export type ProductVariantQueryVariables = Exact<{
  sku: Scalars['SKU']
  currency: Currency
  shippingDestination: Country
}>

export type ProductVariantQuery = { __typename?: 'Query' } & {
  productVariant?: Maybe<
    { __typename?: 'ProductVariant' } & Pick<
      ProductVariant,
      'sku' | 'title' | 'inStock'
    > & {
        images: Array<
          { __typename?: 'ProductImage' } & Pick<
            ProductImage,
            'thumbnail' | 'largeProduct' | 'zoom'
          >
        >
        price?: Maybe<
          { __typename?: 'ProductPrice' } & {
            price: { __typename?: 'MoneyValue' } & Pick<
              MoneyValue,
              'displayValue' | 'amount'
            >
            rrp: { __typename?: 'MoneyValue' } & Pick<
              MoneyValue,
              'displayValue' | 'amount'
            >
          }
        >
      }
  >
}

export type ProductSizeGuideQueryVariables = Exact<{
  sku: Scalars['SKU']
}>

export type ProductSizeGuideQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename?: 'Product' } & {
      sizeGuide?: Maybe<
        { __typename?: 'RichContent' } & {
          content: Array<
            { __typename?: 'RichContentItem' } & Pick<
              RichContentItem,
              'type' | 'content'
            >
          >
        }
      >
    }
  >
}

export type ProductBlockQueryVariables = Exact<{
  sku: Scalars['SKU']
  currency: Currency
  shippingDestination: Country
}>

export type ProductBlockQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename?: 'Product' } & Pick<Product, 'sku' | 'title' | 'url'> & {
        images: Array<
          { __typename?: 'ProductImage' } & Pick<ProductImage, 'largeProduct'>
        >
        brand?: Maybe<
          { __typename?: 'Brand' } & Pick<Brand, 'name' | 'imageUrl'>
        >
        options: Array<
          { __typename: 'VariantOption' } & Pick<VariantOption, 'key'> & {
              choices: Array<
                { __typename: 'OptionChoice' } & Pick<
                  OptionChoice,
                  'key' | 'colour' | 'title'
                >
              >
            }
        >
        reviews?: Maybe<
          { __typename?: 'ProductReviews' } & Pick<
            ProductReviews,
            'total' | 'averageScore'
          >
        >
        defaultVariant?: Maybe<
          { __typename?: 'ProductVariant' } & {
            price?: Maybe<
              { __typename?: 'ProductPrice' } & {
                price: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'displayValue' | 'amount'
                >
                rrp: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'displayValue' | 'amount'
                >
              }
            >
            marketedSpecialOffer?: Maybe<
              { __typename?: 'ProductMarketedSpecialOffer' } & {
                title?: Maybe<
                  { __typename?: 'RichContent' } & {
                    content: Array<
                      { __typename?: 'RichContentItem' } & Pick<
                        RichContentItem,
                        'type' | 'content'
                      >
                    >
                  }
                >
                description?: Maybe<
                  { __typename?: 'RichContent' } & {
                    content: Array<
                      { __typename?: 'RichContentItem' } & Pick<
                        RichContentItem,
                        'type' | 'content'
                      >
                    >
                  }
                >
                landingPageLink?: Maybe<
                  { __typename?: 'Hyperlink' } & Pick<
                    Hyperlink,
                    'text' | 'url' | 'openExternally' | 'noFollow'
                  >
                >
                freeGiftProduct?: Maybe<
                  { __typename?: 'ProductVariant' } & Pick<
                    ProductVariant,
                    'sku' | 'title'
                  > & {
                      images: Array<
                        { __typename?: 'ProductImage' } & Pick<
                          ProductImage,
                          'largeProduct'
                        >
                      >
                      price?: Maybe<
                        { __typename?: 'ProductPrice' } & {
                          rrp: { __typename?: 'MoneyValue' } & Pick<
                            MoneyValue,
                            'displayValue'
                          >
                        }
                      >
                    }
                >
              }
            >
          }
        >
        cheapestVariant?: Maybe<
          { __typename?: 'ProductVariant' } & {
            price?: Maybe<
              { __typename?: 'ProductPrice' } & {
                price: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'displayValue' | 'amount'
                >
                rrp: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'displayValue' | 'amount'
                >
              }
            >
          }
        >
        mostExpensiveVariant?: Maybe<
          { __typename?: 'ProductVariant' } & {
            price?: Maybe<
              { __typename?: 'ProductPrice' } & {
                price: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'displayValue' | 'amount'
                >
                rrp: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'displayValue' | 'amount'
                >
              }
            >
          }
        >
        variants: Array<
          { __typename?: 'ProductVariant' } & Pick<
            ProductVariant,
            'inStock' | 'isCheckStock' | 'isOrderInStore'
          > & {
              choices: Array<
                { __typename: 'OptionChoice' } & Pick<
                  OptionChoice,
                  'optionKey' | 'key' | 'colour'
                >
              >
              product?: Maybe<
                { __typename?: 'Product' } & Pick<Product, 'sku' | 'url'>
              >
              price?: Maybe<
                { __typename?: 'ProductPrice' } & {
                  price: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'amount'
                  >
                  rrp: { __typename?: 'MoneyValue' } & Pick<
                    MoneyValue,
                    'displayValue' | 'amount'
                  >
                }
              >
            }
        >
      }
  >
}

export type ProductBlockListQueryVariables = Exact<{
  skus: Array<Scalars['SKU']> | Scalars['SKU']
  currency: Currency
  shippingDestination: Country
}>

export type ProductBlockListQuery = { __typename?: 'Query' } & {
  products: Array<{ __typename?: 'Product' } & ProductBlockFragmentFragment>
}

export type ProductRecommendationsProductBlockListQueryVariables = Exact<{
  sku: Scalars['SKU']
  currency: Currency
  shippingDestination: Country
  recommendationsType: ProductRecommendationType
  limit?: Scalars['Int']
  productContentKeys?: Maybe<Array<Scalars['String']>>
}>

export type ProductRecommendationsProductBlockListQuery = {
  __typename?: 'Query'
} & {
  product?: Maybe<
    { __typename?: 'Product' } & {
      recommendations?: Maybe<
        Array<{ __typename?: 'Product' } & ProductBlockFragmentFragment>
      >
    }
  >
}

export type QubitHomePageRecsWidgetProductBlockListQueryVariables = Exact<{
  path: Scalars['PagePath']
  widgetId: Scalars['ID']
  currency: Currency
  shippingDestination: Country
  limit?: Scalars['Int']
}>

export type QubitHomePageRecsWidgetProductBlockListQuery = {
  __typename?: 'Query'
} & {
  page?: Maybe<
    { __typename?: 'Page' } & {
      widget?: Maybe<
        | { __typename?: 'AccordionWidget' }
        | { __typename?: 'AccordionWidgetContainer' }
        | { __typename?: 'BMICalculator' }
        | { __typename?: 'BMICalculatorV2' }
        | { __typename?: 'BrandsPageWidget' }
        | { __typename?: 'BuildYourOwnBundleProductList' }
        | { __typename?: 'CriteoSponsoredBannerAds' }
        | { __typename?: 'CriteoSponsoredBannerAdsFlagship' }
        | { __typename?: 'DeliveryInfoWidget' }
        | { __typename?: 'DeliveryInformationWidget' }
        | { __typename?: 'DynamicReferralWidget' }
        | { __typename?: 'Easiware' }
        | { __typename?: 'EditorialWidget' }
        | { __typename?: 'EmailReEngagementModal' }
        | { __typename?: 'FastTrackBanner' }
        | { __typename?: 'FoundationFinderLandingPageWidget' }
        | { __typename?: 'GlobalAboutUsVideo' }
        | { __typename?: 'GlobalAccreditationIcon' }
        | { __typename?: 'GlobalAccreditationIconCollection' }
        | { __typename?: 'GlobalBrandLogos' }
        | { __typename?: 'GlobalBuyingRightNow' }
        | { __typename?: 'GlobalBuyingRightNowManualProductURL' }
        | { __typename?: 'GlobalCardScrollerCard' }
        | { __typename?: 'GlobalCardScrollerSet' }
        | { __typename?: 'GlobalDispatchAndDateCountdownWidget' }
        | { __typename?: 'GlobalEditorialWithFeature' }
        | { __typename?: 'GlobalFooterAccreditationIcons' }
        | { __typename?: 'GlobalFooterContactUs' }
        | { __typename?: 'GlobalFourBestSellers' }
        | { __typename?: 'GlobalFourButtonLink' }
        | { __typename?: 'GlobalFourItemEditorial' }
        | { __typename?: 'GlobalGeneralImageBanner' }
        | { __typename?: 'GlobalHelpCentreAccordion' }
        | { __typename?: 'GlobalHelpCentreAccordion2' }
        | { __typename?: 'GlobalHelpCentreAccordionCollection' }
        | { __typename?: 'GlobalHelpCentreCollection' }
        | { __typename?: 'GlobalHeroCTABanner' }
        | { __typename?: 'GlobalImageCard' }
        | { __typename?: 'GlobalImageCardSet' }
        | { __typename?: 'GlobalMultiButton' }
        | { __typename?: 'GlobalPrimaryBanner' }
        | { __typename?: 'GlobalPrimaryBannerWithList' }
        | { __typename?: 'GlobalPrimaryBannerWithTextOverlay' }
        | { __typename?: 'GlobalProductCardScroller' }
        | { __typename?: 'GlobalReferral3StepGuide' }
        | { __typename?: 'GlobalScalableLogos' }
        | { __typename?: 'GlobalSectionPeek' }
        | { __typename?: 'GlobalSetAndromeda' }
        | { __typename?: 'GlobalSimpleTextCTAWidget' }
        | { __typename?: 'GlobalSixItemCategories' }
        | { __typename?: 'GlobalSocialIcon' }
        | { __typename?: 'GlobalSocialIconCollection' }
        | { __typename?: 'GlobalSocialIconCollectionv2' }
        | { __typename?: 'GlobalSocialIconv2' }
        | { __typename?: 'GlobalStripBanner' }
        | { __typename?: 'GlobalSubscriptionOptions' }
        | { __typename?: 'GlobalTabbedWidgetSet' }
        | { __typename?: 'GlobalThreeItemEditorial' }
        | { __typename?: 'GlobalThreeItemEditorialSubtitleBG' }
        | { __typename?: 'GlobalTransformationSlider' }
        | { __typename?: 'GlobalTrendingHashtagBlock' }
        | { __typename?: 'GlobalTrendingHashtagCollection' }
        | { __typename?: 'GlobalTwoBestSellers' }
        | { __typename?: 'GlobalTwoItemEditorial' }
        | { __typename?: 'GlobalTwoItemImageTextBlock' }
        | { __typename?: 'GlobalTwoItemImageTextCTA' }
        | { __typename?: 'GlobalTwoItemImageTextCTA3070' }
        | { __typename?: 'GlobalUGCCarousel' }
        | { __typename?: 'GlobalVideoGallery' }
        | { __typename?: 'GlobalVideoHeroBannerWidget' }
        | { __typename?: 'GlobalVideoTextImageCTA' }
        | { __typename?: 'GlobalWaitListSignUpFormResponses' }
        | { __typename?: 'GlobalWaitListSignUpWidget' }
        | { __typename?: 'GlobalWidgetSirius' }
        | { __typename?: 'GlobalWidgetVega' }
        | { __typename?: 'GlobalYoutubeGalleryItem' }
        | { __typename?: 'GlobalYoutubeGallerySet' }
        | { __typename?: 'LiveChatWidget' }
        | { __typename?: 'LoyaltyHubBirthdayGift' }
        | { __typename?: 'LoyaltyHubTier' }
        | { __typename?: 'LoyaltyRewardTier' }
        | { __typename?: 'LoyaltyRewardTiers' }
        | { __typename?: 'MailingList' }
        | { __typename?: 'MultipleCTABanner' }
        | { __typename?: 'ProductListWidget' }
        | { __typename?: 'ProductRecommenderItem' }
        | { __typename?: 'ProfileWidget' }
        | { __typename?: 'ResponsiveBuildYourOwnBundle' }
        | { __typename?: 'ResponsiveBuildYourOwnBundleProducts' }
        | { __typename?: 'ResponsiveComparisonTable' }
        | { __typename?: 'ResponsiveComparisonTableContainer' }
        | { __typename?: 'ResponsiveProductBlockCarousel' }
        | { __typename?: 'ResponsiveProductTabs' }
        | { __typename?: 'ResponsiveSetSalon' }
        | { __typename?: 'ResponsiveSetSalonWithSlides' }
        | { __typename?: 'ResponsiveSliderSet' }
        | { __typename?: 'ResponsiveSuccessStoriesWidgetContainer' }
        | { __typename?: 'ResponsiveSuccessStoryWidget' }
        | { __typename?: 'ResponsiveTwoItemComparisonContainer' }
        | { __typename?: 'ResponsiveTwoItemComparisonRow' }
        | { __typename?: 'ResponsiveUSPBar' }
        | { __typename?: 'ResponsiveUSPBarCustomisable' }
        | { __typename?: 'SeoProductCategory' }
        | { __typename?: 'SeoProductCategorySet' }
        | { __typename?: 'ShopTheLookHotSpot' }
        | { __typename?: 'SimpleTextReadMore' }
        | { __typename?: 'TopBrandsWidget' }
        | { __typename?: 'TopProductCategory' }
        | { __typename?: 'TopProductCategorySet' }
        | { __typename?: 'TrustPilotCarouselWidget' }
        | { __typename?: 'TrustPilotWidget' }
        | { __typename?: 'VoucherCodesWidget' }
        | { __typename?: 'asymmetricGrid' }
        | { __typename?: 'buildyourownbundle' }
        | { __typename?: 'buttonLink' }
        | { __typename?: 'categoryItemCard' }
        | { __typename?: 'categoryItemCardWithTextOverlay' }
        | { __typename?: 'coverageCalculator' }
        | { __typename?: 'eightLinkButtonWidget' }
        | { __typename?: 'fouritemwindowproductslider' }
        | { __typename?: 'generalIframe' }
        | { __typename?: 'generalQuoteBanner' }
        | { __typename?: 'imageSelectCard' }
        | { __typename?: 'imageSelectSlider' }
        | { __typename?: 'improvedSearchBestSellers' }
        | { __typename?: 'kitBuilder' }
        | { __typename?: 'loyaltyRewardsList' }
        | { __typename?: 'loyaltyWelcomeMessage' }
        | { __typename?: 'multiCategoryCardSet' }
        | { __typename?: 'multiCategoryCardSetV2' }
        | { __typename?: 'obsessVR' }
        | { __typename?: 'parcelLabWidget' }
        | { __typename?: 'promoproductslider' }
        | { __typename?: 'propositionBar' }
        | { __typename?: 'propositionBarIncorrect' }
        | { __typename?: 'provenanceBrandHeader' }
        | ({ __typename?: 'qubitHomePageRecs' } & {
            productList?: Maybe<
              { __typename?: 'ProductList' } & {
                products: Array<
                  { __typename?: 'Product' } & ProductBlockFragmentFragment
                >
              }
            >
          })
        | { __typename?: 'revieveWidget' }
        | { __typename?: 'shadeFinder' }
        | { __typename?: 'shopTheLook' }
        | { __typename?: 'simpleCTA' }
        | { __typename?: 'simpleDivider' }
        | { __typename?: 'simpleTextWidgetSet' }
        | { __typename?: 'simpleTitleWidget' }
        | { __typename?: 'simpletextwidget' }
        | { __typename?: 'simpletextwidgetwithh1' }
        | { __typename?: 'sponsoredProducts' }
        | { __typename?: 'sponsoredProductsCriteoHomepage' }
        | { __typename?: 'sponsoredProductsNew' }
        | { __typename?: 'storyStreamWidget' }
        | { __typename?: 'subscribeAndSaveInformationModal' }
        | { __typename?: 'swatchSelectSlider' }
        | { __typename?: 'swatchSelectSliderSet' }
        | { __typename?: 'trustReinforcementContainer' }
        | { __typename?: 'trustReinforcementSection' }
        | { __typename?: 'videoEditorial' }
        | { __typename?: 'yotiAgeVerification' }
        | { __typename?: 'yotiCancelFrame' }
      >
    }
  >
}

export type AddReviewFormQueryVariables = Exact<{
  sku: Scalars['SKU']
}>

export type AddReviewFormQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename: 'Product' } & Pick<Product, 'sku' | 'title'> & {
        images: Array<
          { __typename: 'ProductImage' } & Pick<ProductImage, 'largeProduct'>
        >
        content: Array<
          { __typename: 'ProductContentItem' } & Pick<
            ProductContentItem,
            'key'
          > & {
              value:
                | { __typename: 'ProductContentAttachmentValue' }
                | { __typename: 'ProductContentDateValue' }
                | { __typename: 'ProductContentIntListValue' }
                | { __typename: 'ProductContentIntValue' }
                | ({ __typename: 'ProductContentRichContentListValue' } & {
                    richContentListValue: Array<
                      { __typename: 'RichContent' } & {
                        content: Array<
                          { __typename: 'RichContentItem' } & Pick<
                            RichContentItem,
                            'type' | 'content'
                          >
                        >
                      }
                    >
                  })
                | { __typename: 'ProductContentRichContentValue' }
                | { __typename: 'ProductContentStringListValue' }
                | { __typename: 'ProductContentStringValue' }
            }
        >
        reviews?: Maybe<
          { __typename: 'ProductReviews' } & {
            newReviewForm?: Maybe<
              { __typename: 'Form' } & Pick<Form, 'identifier' | 'type'> & {
                  fields: Array<
                    { __typename: 'FormField' } & Pick<
                      FormField,
                      'name' | 'label' | 'type' | 'required' | 'defaultValue'
                    > & {
                        validators: Array<
                          { __typename: 'Validator' } & Pick<
                            Validator,
                            'name' | 'argument'
                          >
                        >
                        answerOptions?: Maybe<
                          Array<
                            Maybe<
                              { __typename: 'AnswerOption' } & Pick<
                                AnswerOption,
                                'optionKey' | 'translation'
                              >
                            >
                          >
                        >
                      }
                  >
                }
            >
          }
        >
        reviewTemplate?: Maybe<
          { __typename?: 'ReviewTemplate' } & Pick<ReviewTemplate, 'name'> & {
              features: Array<
                { __typename?: 'ReviewTemplateFeature' } & Pick<
                  ReviewTemplateFeature,
                  'name' | 'label' | 'hasDefaultMeasurement'
                > & {
                    measurementValues: Array<
                      { __typename?: 'ReviewMeasurementValue' } & Pick<
                        ReviewMeasurementValue,
                        'value' | 'label' | 'order' | 'mappingValue'
                      >
                    >
                  }
              >
            }
        >
      }
  >
}

export type SectionPeekWidgetProductBlockListQueryVariables = Exact<{
  path: Scalars['PagePath']
  widgetId: Scalars['ID']
  currency: Currency
  shippingDestination: Country
  limit?: Scalars['Int']
}>

export type SectionPeekWidgetProductBlockListQuery = {
  __typename?: 'Query'
} & {
  page?: Maybe<
    { __typename?: 'Page' } & {
      widget?: Maybe<
        | { __typename?: 'AccordionWidget' }
        | { __typename?: 'AccordionWidgetContainer' }
        | { __typename?: 'BMICalculator' }
        | { __typename?: 'BMICalculatorV2' }
        | { __typename?: 'BrandsPageWidget' }
        | { __typename?: 'BuildYourOwnBundleProductList' }
        | { __typename?: 'CriteoSponsoredBannerAds' }
        | { __typename?: 'CriteoSponsoredBannerAdsFlagship' }
        | { __typename?: 'DeliveryInfoWidget' }
        | { __typename?: 'DeliveryInformationWidget' }
        | { __typename?: 'DynamicReferralWidget' }
        | { __typename?: 'Easiware' }
        | { __typename?: 'EditorialWidget' }
        | { __typename?: 'EmailReEngagementModal' }
        | { __typename?: 'FastTrackBanner' }
        | { __typename?: 'FoundationFinderLandingPageWidget' }
        | { __typename?: 'GlobalAboutUsVideo' }
        | { __typename?: 'GlobalAccreditationIcon' }
        | { __typename?: 'GlobalAccreditationIconCollection' }
        | { __typename?: 'GlobalBrandLogos' }
        | { __typename?: 'GlobalBuyingRightNow' }
        | { __typename?: 'GlobalBuyingRightNowManualProductURL' }
        | { __typename?: 'GlobalCardScrollerCard' }
        | { __typename?: 'GlobalCardScrollerSet' }
        | { __typename?: 'GlobalDispatchAndDateCountdownWidget' }
        | { __typename?: 'GlobalEditorialWithFeature' }
        | { __typename?: 'GlobalFooterAccreditationIcons' }
        | { __typename?: 'GlobalFooterContactUs' }
        | { __typename?: 'GlobalFourBestSellers' }
        | { __typename?: 'GlobalFourButtonLink' }
        | { __typename?: 'GlobalFourItemEditorial' }
        | { __typename?: 'GlobalGeneralImageBanner' }
        | { __typename?: 'GlobalHelpCentreAccordion' }
        | { __typename?: 'GlobalHelpCentreAccordion2' }
        | { __typename?: 'GlobalHelpCentreAccordionCollection' }
        | { __typename?: 'GlobalHelpCentreCollection' }
        | { __typename?: 'GlobalHeroCTABanner' }
        | { __typename?: 'GlobalImageCard' }
        | { __typename?: 'GlobalImageCardSet' }
        | { __typename?: 'GlobalMultiButton' }
        | { __typename?: 'GlobalPrimaryBanner' }
        | { __typename?: 'GlobalPrimaryBannerWithList' }
        | { __typename?: 'GlobalPrimaryBannerWithTextOverlay' }
        | { __typename?: 'GlobalProductCardScroller' }
        | { __typename?: 'GlobalReferral3StepGuide' }
        | { __typename?: 'GlobalScalableLogos' }
        | ({ __typename?: 'GlobalSectionPeek' } & {
            productList?: Maybe<
              { __typename?: 'ProductList' } & {
                products: Array<
                  { __typename?: 'Product' } & ProductBlockFragmentFragment
                >
              }
            >
          })
        | { __typename?: 'GlobalSetAndromeda' }
        | { __typename?: 'GlobalSimpleTextCTAWidget' }
        | { __typename?: 'GlobalSixItemCategories' }
        | { __typename?: 'GlobalSocialIcon' }
        | { __typename?: 'GlobalSocialIconCollection' }
        | { __typename?: 'GlobalSocialIconCollectionv2' }
        | { __typename?: 'GlobalSocialIconv2' }
        | { __typename?: 'GlobalStripBanner' }
        | { __typename?: 'GlobalSubscriptionOptions' }
        | { __typename?: 'GlobalTabbedWidgetSet' }
        | { __typename?: 'GlobalThreeItemEditorial' }
        | { __typename?: 'GlobalThreeItemEditorialSubtitleBG' }
        | { __typename?: 'GlobalTransformationSlider' }
        | { __typename?: 'GlobalTrendingHashtagBlock' }
        | { __typename?: 'GlobalTrendingHashtagCollection' }
        | { __typename?: 'GlobalTwoBestSellers' }
        | { __typename?: 'GlobalTwoItemEditorial' }
        | { __typename?: 'GlobalTwoItemImageTextBlock' }
        | { __typename?: 'GlobalTwoItemImageTextCTA' }
        | { __typename?: 'GlobalTwoItemImageTextCTA3070' }
        | { __typename?: 'GlobalUGCCarousel' }
        | { __typename?: 'GlobalVideoGallery' }
        | { __typename?: 'GlobalVideoHeroBannerWidget' }
        | { __typename?: 'GlobalVideoTextImageCTA' }
        | { __typename?: 'GlobalWaitListSignUpFormResponses' }
        | { __typename?: 'GlobalWaitListSignUpWidget' }
        | { __typename?: 'GlobalWidgetSirius' }
        | { __typename?: 'GlobalWidgetVega' }
        | { __typename?: 'GlobalYoutubeGalleryItem' }
        | { __typename?: 'GlobalYoutubeGallerySet' }
        | { __typename?: 'LiveChatWidget' }
        | { __typename?: 'LoyaltyHubBirthdayGift' }
        | { __typename?: 'LoyaltyHubTier' }
        | { __typename?: 'LoyaltyRewardTier' }
        | { __typename?: 'LoyaltyRewardTiers' }
        | { __typename?: 'MailingList' }
        | { __typename?: 'MultipleCTABanner' }
        | { __typename?: 'ProductListWidget' }
        | { __typename?: 'ProductRecommenderItem' }
        | { __typename?: 'ProfileWidget' }
        | { __typename?: 'ResponsiveBuildYourOwnBundle' }
        | { __typename?: 'ResponsiveBuildYourOwnBundleProducts' }
        | { __typename?: 'ResponsiveComparisonTable' }
        | { __typename?: 'ResponsiveComparisonTableContainer' }
        | { __typename?: 'ResponsiveProductBlockCarousel' }
        | { __typename?: 'ResponsiveProductTabs' }
        | { __typename?: 'ResponsiveSetSalon' }
        | { __typename?: 'ResponsiveSetSalonWithSlides' }
        | { __typename?: 'ResponsiveSliderSet' }
        | { __typename?: 'ResponsiveSuccessStoriesWidgetContainer' }
        | { __typename?: 'ResponsiveSuccessStoryWidget' }
        | { __typename?: 'ResponsiveTwoItemComparisonContainer' }
        | { __typename?: 'ResponsiveTwoItemComparisonRow' }
        | { __typename?: 'ResponsiveUSPBar' }
        | { __typename?: 'ResponsiveUSPBarCustomisable' }
        | { __typename?: 'SeoProductCategory' }
        | { __typename?: 'SeoProductCategorySet' }
        | { __typename?: 'ShopTheLookHotSpot' }
        | { __typename?: 'SimpleTextReadMore' }
        | { __typename?: 'TopBrandsWidget' }
        | { __typename?: 'TopProductCategory' }
        | { __typename?: 'TopProductCategorySet' }
        | { __typename?: 'TrustPilotCarouselWidget' }
        | { __typename?: 'TrustPilotWidget' }
        | { __typename?: 'VoucherCodesWidget' }
        | { __typename?: 'asymmetricGrid' }
        | { __typename?: 'buildyourownbundle' }
        | { __typename?: 'buttonLink' }
        | { __typename?: 'categoryItemCard' }
        | { __typename?: 'categoryItemCardWithTextOverlay' }
        | { __typename?: 'coverageCalculator' }
        | { __typename?: 'eightLinkButtonWidget' }
        | { __typename?: 'fouritemwindowproductslider' }
        | { __typename?: 'generalIframe' }
        | { __typename?: 'generalQuoteBanner' }
        | { __typename?: 'imageSelectCard' }
        | { __typename?: 'imageSelectSlider' }
        | { __typename?: 'improvedSearchBestSellers' }
        | { __typename?: 'kitBuilder' }
        | { __typename?: 'loyaltyRewardsList' }
        | { __typename?: 'loyaltyWelcomeMessage' }
        | { __typename?: 'multiCategoryCardSet' }
        | { __typename?: 'multiCategoryCardSetV2' }
        | { __typename?: 'obsessVR' }
        | { __typename?: 'parcelLabWidget' }
        | { __typename?: 'promoproductslider' }
        | { __typename?: 'propositionBar' }
        | { __typename?: 'propositionBarIncorrect' }
        | { __typename?: 'provenanceBrandHeader' }
        | { __typename?: 'qubitHomePageRecs' }
        | { __typename?: 'revieveWidget' }
        | { __typename?: 'shadeFinder' }
        | { __typename?: 'shopTheLook' }
        | { __typename?: 'simpleCTA' }
        | { __typename?: 'simpleDivider' }
        | { __typename?: 'simpleTextWidgetSet' }
        | { __typename?: 'simpleTitleWidget' }
        | { __typename?: 'simpletextwidget' }
        | { __typename?: 'simpletextwidgetwithh1' }
        | { __typename?: 'sponsoredProducts' }
        | { __typename?: 'sponsoredProductsCriteoHomepage' }
        | { __typename?: 'sponsoredProductsNew' }
        | { __typename?: 'storyStreamWidget' }
        | { __typename?: 'subscribeAndSaveInformationModal' }
        | { __typename?: 'swatchSelectSlider' }
        | { __typename?: 'swatchSelectSliderSet' }
        | { __typename?: 'trustReinforcementContainer' }
        | { __typename?: 'trustReinforcementSection' }
        | { __typename?: 'videoEditorial' }
        | { __typename?: 'yotiAgeVerification' }
        | { __typename?: 'yotiCancelFrame' }
      >
    }
  >
}

export type ProductsShowcaseListQueryVariables = Exact<{
  path: Scalars['PagePath']
  id: Scalars['ID']
  input: ProductListInput
  currency: Currency
  shippingDestination: Country
}>

export type ProductsShowcaseListQuery = { __typename?: 'Query' } & {
  page?: Maybe<
    { __typename: 'Page' } & {
      widget?: Maybe<
        | { __typename?: 'AccordionWidget' }
        | { __typename?: 'AccordionWidgetContainer' }
        | { __typename?: 'BMICalculator' }
        | { __typename?: 'BMICalculatorV2' }
        | { __typename?: 'BrandsPageWidget' }
        | { __typename?: 'BuildYourOwnBundleProductList' }
        | { __typename?: 'CriteoSponsoredBannerAds' }
        | { __typename?: 'CriteoSponsoredBannerAdsFlagship' }
        | { __typename?: 'DeliveryInfoWidget' }
        | { __typename?: 'DeliveryInformationWidget' }
        | { __typename?: 'DynamicReferralWidget' }
        | { __typename?: 'Easiware' }
        | { __typename?: 'EditorialWidget' }
        | { __typename?: 'EmailReEngagementModal' }
        | { __typename?: 'FastTrackBanner' }
        | { __typename?: 'FoundationFinderLandingPageWidget' }
        | { __typename?: 'GlobalAboutUsVideo' }
        | { __typename?: 'GlobalAccreditationIcon' }
        | { __typename?: 'GlobalAccreditationIconCollection' }
        | { __typename?: 'GlobalBrandLogos' }
        | { __typename?: 'GlobalBuyingRightNow' }
        | { __typename?: 'GlobalBuyingRightNowManualProductURL' }
        | { __typename?: 'GlobalCardScrollerCard' }
        | { __typename?: 'GlobalCardScrollerSet' }
        | { __typename?: 'GlobalDispatchAndDateCountdownWidget' }
        | { __typename?: 'GlobalEditorialWithFeature' }
        | { __typename?: 'GlobalFooterAccreditationIcons' }
        | { __typename?: 'GlobalFooterContactUs' }
        | { __typename?: 'GlobalFourBestSellers' }
        | { __typename?: 'GlobalFourButtonLink' }
        | { __typename?: 'GlobalFourItemEditorial' }
        | { __typename?: 'GlobalGeneralImageBanner' }
        | { __typename?: 'GlobalHelpCentreAccordion' }
        | { __typename?: 'GlobalHelpCentreAccordion2' }
        | { __typename?: 'GlobalHelpCentreAccordionCollection' }
        | { __typename?: 'GlobalHelpCentreCollection' }
        | { __typename?: 'GlobalHeroCTABanner' }
        | { __typename?: 'GlobalImageCard' }
        | { __typename?: 'GlobalImageCardSet' }
        | { __typename?: 'GlobalMultiButton' }
        | { __typename?: 'GlobalPrimaryBanner' }
        | { __typename?: 'GlobalPrimaryBannerWithList' }
        | { __typename?: 'GlobalPrimaryBannerWithTextOverlay' }
        | { __typename?: 'GlobalProductCardScroller' }
        | { __typename?: 'GlobalReferral3StepGuide' }
        | { __typename?: 'GlobalScalableLogos' }
        | { __typename?: 'GlobalSectionPeek' }
        | { __typename?: 'GlobalSetAndromeda' }
        | { __typename?: 'GlobalSimpleTextCTAWidget' }
        | { __typename?: 'GlobalSixItemCategories' }
        | { __typename?: 'GlobalSocialIcon' }
        | { __typename?: 'GlobalSocialIconCollection' }
        | { __typename?: 'GlobalSocialIconCollectionv2' }
        | { __typename?: 'GlobalSocialIconv2' }
        | { __typename?: 'GlobalStripBanner' }
        | { __typename?: 'GlobalSubscriptionOptions' }
        | { __typename?: 'GlobalTabbedWidgetSet' }
        | { __typename?: 'GlobalThreeItemEditorial' }
        | { __typename?: 'GlobalThreeItemEditorialSubtitleBG' }
        | { __typename?: 'GlobalTransformationSlider' }
        | { __typename?: 'GlobalTrendingHashtagBlock' }
        | { __typename?: 'GlobalTrendingHashtagCollection' }
        | { __typename?: 'GlobalTwoBestSellers' }
        | { __typename?: 'GlobalTwoItemEditorial' }
        | { __typename?: 'GlobalTwoItemImageTextBlock' }
        | { __typename?: 'GlobalTwoItemImageTextCTA' }
        | { __typename?: 'GlobalTwoItemImageTextCTA3070' }
        | { __typename?: 'GlobalUGCCarousel' }
        | { __typename?: 'GlobalVideoGallery' }
        | { __typename?: 'GlobalVideoHeroBannerWidget' }
        | { __typename?: 'GlobalVideoTextImageCTA' }
        | { __typename?: 'GlobalWaitListSignUpFormResponses' }
        | { __typename?: 'GlobalWaitListSignUpWidget' }
        | { __typename?: 'GlobalWidgetSirius' }
        | { __typename?: 'GlobalWidgetVega' }
        | { __typename?: 'GlobalYoutubeGalleryItem' }
        | { __typename?: 'GlobalYoutubeGallerySet' }
        | { __typename?: 'LiveChatWidget' }
        | { __typename?: 'LoyaltyHubBirthdayGift' }
        | { __typename?: 'LoyaltyHubTier' }
        | { __typename?: 'LoyaltyRewardTier' }
        | { __typename?: 'LoyaltyRewardTiers' }
        | { __typename?: 'MailingList' }
        | { __typename?: 'MultipleCTABanner' }
        | { __typename?: 'ProductListWidget' }
        | { __typename?: 'ProductRecommenderItem' }
        | { __typename?: 'ProfileWidget' }
        | { __typename?: 'ResponsiveBuildYourOwnBundle' }
        | { __typename?: 'ResponsiveBuildYourOwnBundleProducts' }
        | { __typename?: 'ResponsiveComparisonTable' }
        | { __typename?: 'ResponsiveComparisonTableContainer' }
        | { __typename?: 'ResponsiveProductBlockCarousel' }
        | { __typename?: 'ResponsiveProductTabs' }
        | { __typename?: 'ResponsiveSetSalon' }
        | { __typename?: 'ResponsiveSetSalonWithSlides' }
        | { __typename?: 'ResponsiveSliderSet' }
        | { __typename?: 'ResponsiveSuccessStoriesWidgetContainer' }
        | { __typename?: 'ResponsiveSuccessStoryWidget' }
        | { __typename?: 'ResponsiveTwoItemComparisonContainer' }
        | { __typename?: 'ResponsiveTwoItemComparisonRow' }
        | { __typename?: 'ResponsiveUSPBar' }
        | { __typename?: 'ResponsiveUSPBarCustomisable' }
        | { __typename?: 'SeoProductCategory' }
        | { __typename?: 'SeoProductCategorySet' }
        | { __typename?: 'ShopTheLookHotSpot' }
        | { __typename?: 'SimpleTextReadMore' }
        | { __typename?: 'TopBrandsWidget' }
        | { __typename?: 'TopProductCategory' }
        | { __typename?: 'TopProductCategorySet' }
        | { __typename?: 'TrustPilotCarouselWidget' }
        | { __typename?: 'TrustPilotWidget' }
        | { __typename?: 'VoucherCodesWidget' }
        | { __typename?: 'asymmetricGrid' }
        | { __typename?: 'buildyourownbundle' }
        | { __typename?: 'buttonLink' }
        | { __typename?: 'categoryItemCard' }
        | { __typename?: 'categoryItemCardWithTextOverlay' }
        | { __typename?: 'coverageCalculator' }
        | { __typename?: 'eightLinkButtonWidget' }
        | { __typename?: 'fouritemwindowproductslider' }
        | { __typename?: 'generalIframe' }
        | { __typename?: 'generalQuoteBanner' }
        | { __typename?: 'imageSelectCard' }
        | { __typename?: 'imageSelectSlider' }
        | { __typename?: 'improvedSearchBestSellers' }
        | { __typename?: 'kitBuilder' }
        | { __typename?: 'loyaltyRewardsList' }
        | { __typename?: 'loyaltyWelcomeMessage' }
        | { __typename?: 'multiCategoryCardSet' }
        | { __typename?: 'multiCategoryCardSetV2' }
        | { __typename?: 'obsessVR' }
        | { __typename?: 'parcelLabWidget' }
        | ({ __typename?: 'promoproductslider' } & {
            productList?: Maybe<
              { __typename: 'ProductList' } & {
                products: Array<
                  { __typename: 'Product' } & Pick<
                    Product,
                    'sku' | 'url' | 'title'
                  > & {
                      defaultVariant?: Maybe<
                        { __typename?: 'ProductVariant' } & {
                          price?: Maybe<
                            { __typename?: 'ProductPrice' } & {
                              price: { __typename?: 'MoneyValue' } & Pick<
                                MoneyValue,
                                'amount' | 'displayValue'
                              >
                              rrp: { __typename?: 'MoneyValue' } & Pick<
                                MoneyValue,
                                'amount' | 'displayValue'
                              >
                            }
                          >
                        }
                      >
                      cheapestVariant?: Maybe<
                        { __typename?: 'ProductVariant' } & {
                          price?: Maybe<
                            { __typename?: 'ProductPrice' } & {
                              price: { __typename?: 'MoneyValue' } & Pick<
                                MoneyValue,
                                'amount' | 'displayValue'
                              >
                              rrp: { __typename?: 'MoneyValue' } & Pick<
                                MoneyValue,
                                'amount' | 'displayValue'
                              >
                            }
                          >
                        }
                      >
                      mostExpensiveVariant?: Maybe<
                        { __typename?: 'ProductVariant' } & {
                          price?: Maybe<
                            { __typename?: 'ProductPrice' } & {
                              price: { __typename?: 'MoneyValue' } & Pick<
                                MoneyValue,
                                'amount' | 'displayValue'
                              >
                              rrp: { __typename?: 'MoneyValue' } & Pick<
                                MoneyValue,
                                'amount' | 'displayValue'
                              >
                            }
                          >
                        }
                      >
                      reviews?: Maybe<
                        { __typename: 'ProductReviews' } & Pick<
                          ProductReviews,
                          'total' | 'averageScore'
                        >
                      >
                      images: Array<
                        { __typename: 'ProductImage' } & Pick<
                          ProductImage,
                          'largeProduct'
                        >
                      >
                      marketedSpecialOffer?: Maybe<
                        { __typename?: 'ProductMarketedSpecialOffer' } & {
                          title?: Maybe<
                            { __typename?: 'RichContent' } & {
                              content: Array<
                                { __typename?: 'RichContentItem' } & Pick<
                                  RichContentItem,
                                  'type' | 'content'
                                >
                              >
                            }
                          >
                          description?: Maybe<
                            { __typename?: 'RichContent' } & {
                              content: Array<
                                { __typename?: 'RichContentItem' } & Pick<
                                  RichContentItem,
                                  'type' | 'content'
                                >
                              >
                            }
                          >
                          landingPageLink?: Maybe<
                            { __typename?: 'Hyperlink' } & Pick<
                              Hyperlink,
                              'text' | 'url' | 'openExternally' | 'noFollow'
                            >
                          >
                          freeGiftProduct?: Maybe<
                            { __typename?: 'ProductVariant' } & Pick<
                              ProductVariant,
                              'sku' | 'title'
                            > & {
                                images: Array<
                                  { __typename?: 'ProductImage' } & Pick<
                                    ProductImage,
                                    'largeProduct'
                                  >
                                >
                                price?: Maybe<
                                  { __typename?: 'ProductPrice' } & {
                                    rrp: { __typename?: 'MoneyValue' } & Pick<
                                      MoneyValue,
                                      'displayValue'
                                    >
                                  }
                                >
                              }
                          >
                        }
                      >
                      variants: Array<
                        { __typename: 'ProductVariant' } & Pick<
                          ProductVariant,
                          'inStock'
                        > & {
                            product?: Maybe<
                              { __typename?: 'Product' } & Pick<Product, 'sku'>
                            >
                          }
                      >
                    }
                >
              }
            >
          })
        | { __typename?: 'propositionBar' }
        | { __typename?: 'propositionBarIncorrect' }
        | { __typename?: 'provenanceBrandHeader' }
        | { __typename?: 'qubitHomePageRecs' }
        | { __typename?: 'revieveWidget' }
        | { __typename?: 'shadeFinder' }
        | { __typename?: 'shopTheLook' }
        | { __typename?: 'simpleCTA' }
        | { __typename?: 'simpleDivider' }
        | { __typename?: 'simpleTextWidgetSet' }
        | { __typename?: 'simpleTitleWidget' }
        | { __typename?: 'simpletextwidget' }
        | { __typename?: 'simpletextwidgetwithh1' }
        | { __typename?: 'sponsoredProducts' }
        | { __typename?: 'sponsoredProductsCriteoHomepage' }
        | { __typename?: 'sponsoredProductsNew' }
        | { __typename?: 'storyStreamWidget' }
        | { __typename?: 'subscribeAndSaveInformationModal' }
        | { __typename?: 'swatchSelectSlider' }
        | { __typename?: 'swatchSelectSliderSet' }
        | { __typename?: 'trustReinforcementContainer' }
        | { __typename?: 'trustReinforcementSection' }
        | { __typename?: 'videoEditorial' }
        | { __typename?: 'yotiAgeVerification' }
        | { __typename?: 'yotiCancelFrame' }
      >
    }
  >
}

export type QubitSectionPeekProductsQueryVariables = Exact<{
  skus: Array<Scalars['SKU']> | Scalars['SKU']
  currency: Currency
  shippingDestination: Country
}>

export type QubitSectionPeekProductsQuery = { __typename?: 'Query' } & {
  products: Array<
    { __typename?: 'Product' } & Pick<Product, 'sku' | 'url' | 'title'> & {
        reviews?: Maybe<
          { __typename?: 'ProductReviews' } & Pick<
            ProductReviews,
            'total' | 'averageScore'
          >
        >
        images: Array<
          { __typename?: 'ProductImage' } & Pick<ProductImage, 'largeProduct'>
        >
        defaultVariant?: Maybe<
          { __typename?: 'ProductVariant' } & {
            price?: Maybe<
              { __typename?: 'ProductPrice' } & {
                price: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'displayValue' | 'amount'
                >
                rrp: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'displayValue' | 'amount'
                >
              }
            >
          }
        >
        cheapestVariant?: Maybe<
          { __typename?: 'ProductVariant' } & {
            price?: Maybe<
              { __typename?: 'ProductPrice' } & {
                price: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'displayValue' | 'amount'
                >
                rrp: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'displayValue' | 'amount'
                >
              }
            >
          }
        >
        mostExpensiveVariant?: Maybe<
          { __typename?: 'ProductVariant' } & {
            price?: Maybe<
              { __typename?: 'ProductPrice' } & {
                price: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'displayValue' | 'amount'
                >
                rrp: { __typename?: 'MoneyValue' } & Pick<
                  MoneyValue,
                  'displayValue' | 'amount'
                >
              }
            >
          }
        >
        variants: Array<
          { __typename?: 'ProductVariant' } & Pick<ProductVariant, 'inStock'>
        >
      }
  >
}

export type SectionPeekProductsQueryVariables = Exact<{
  path: Scalars['PagePath']
  id: Scalars['ID']
  input: ProductListInput
  currency: Currency
  shippingDestination: Country
}>

export type SectionPeekProductsQuery = { __typename?: 'Query' } & {
  page?: Maybe<
    { __typename: 'Page' } & {
      widget?: Maybe<
        | { __typename?: 'AccordionWidget' }
        | { __typename?: 'AccordionWidgetContainer' }
        | { __typename?: 'BMICalculator' }
        | { __typename?: 'BMICalculatorV2' }
        | { __typename?: 'BrandsPageWidget' }
        | { __typename?: 'BuildYourOwnBundleProductList' }
        | { __typename?: 'CriteoSponsoredBannerAds' }
        | { __typename?: 'CriteoSponsoredBannerAdsFlagship' }
        | { __typename?: 'DeliveryInfoWidget' }
        | { __typename?: 'DeliveryInformationWidget' }
        | { __typename?: 'DynamicReferralWidget' }
        | { __typename?: 'Easiware' }
        | { __typename?: 'EditorialWidget' }
        | { __typename?: 'EmailReEngagementModal' }
        | { __typename?: 'FastTrackBanner' }
        | { __typename?: 'FoundationFinderLandingPageWidget' }
        | { __typename?: 'GlobalAboutUsVideo' }
        | { __typename?: 'GlobalAccreditationIcon' }
        | { __typename?: 'GlobalAccreditationIconCollection' }
        | { __typename?: 'GlobalBrandLogos' }
        | { __typename?: 'GlobalBuyingRightNow' }
        | { __typename?: 'GlobalBuyingRightNowManualProductURL' }
        | { __typename?: 'GlobalCardScrollerCard' }
        | { __typename?: 'GlobalCardScrollerSet' }
        | { __typename?: 'GlobalDispatchAndDateCountdownWidget' }
        | { __typename?: 'GlobalEditorialWithFeature' }
        | { __typename?: 'GlobalFooterAccreditationIcons' }
        | { __typename?: 'GlobalFooterContactUs' }
        | { __typename?: 'GlobalFourBestSellers' }
        | { __typename?: 'GlobalFourButtonLink' }
        | { __typename?: 'GlobalFourItemEditorial' }
        | { __typename?: 'GlobalGeneralImageBanner' }
        | { __typename?: 'GlobalHelpCentreAccordion' }
        | { __typename?: 'GlobalHelpCentreAccordion2' }
        | { __typename?: 'GlobalHelpCentreAccordionCollection' }
        | { __typename?: 'GlobalHelpCentreCollection' }
        | { __typename?: 'GlobalHeroCTABanner' }
        | { __typename?: 'GlobalImageCard' }
        | { __typename?: 'GlobalImageCardSet' }
        | { __typename?: 'GlobalMultiButton' }
        | { __typename?: 'GlobalPrimaryBanner' }
        | { __typename?: 'GlobalPrimaryBannerWithList' }
        | { __typename?: 'GlobalPrimaryBannerWithTextOverlay' }
        | { __typename?: 'GlobalProductCardScroller' }
        | { __typename?: 'GlobalReferral3StepGuide' }
        | { __typename?: 'GlobalScalableLogos' }
        | ({ __typename: 'GlobalSectionPeek' } & Pick<
            GlobalSectionPeek,
            'id'
          > & {
              productList?: Maybe<
                { __typename: 'ProductList' } & {
                  products: Array<
                    { __typename: 'Product' } & Pick<
                      Product,
                      'sku' | 'url' | 'title'
                    > & {
                        brand?: Maybe<
                          { __typename?: 'Brand' } & Pick<
                            Brand,
                            'name' | 'imageUrl'
                          >
                        >
                        reviews?: Maybe<
                          { __typename: 'ProductReviews' } & Pick<
                            ProductReviews,
                            'total' | 'averageScore'
                          >
                        >
                        images: Array<
                          { __typename: 'ProductImage' } & Pick<
                            ProductImage,
                            'largeProduct'
                          >
                        >
                        defaultVariant?: Maybe<
                          { __typename: 'ProductVariant' } & {
                            price?: Maybe<
                              { __typename: 'ProductPrice' } & {
                                price: { __typename: 'MoneyValue' } & Pick<
                                  MoneyValue,
                                  'displayValue' | 'amount'
                                >
                                rrp: { __typename: 'MoneyValue' } & Pick<
                                  MoneyValue,
                                  'displayValue' | 'amount'
                                >
                              }
                            >
                          }
                        >
                        cheapestVariant?: Maybe<
                          { __typename: 'ProductVariant' } & {
                            price?: Maybe<
                              { __typename: 'ProductPrice' } & {
                                price: { __typename: 'MoneyValue' } & Pick<
                                  MoneyValue,
                                  'displayValue' | 'amount'
                                >
                                rrp: { __typename: 'MoneyValue' } & Pick<
                                  MoneyValue,
                                  'displayValue' | 'amount'
                                >
                              }
                            >
                          }
                        >
                        mostExpensiveVariant?: Maybe<
                          { __typename: 'ProductVariant' } & {
                            price?: Maybe<
                              { __typename: 'ProductPrice' } & {
                                price: { __typename: 'MoneyValue' } & Pick<
                                  MoneyValue,
                                  'displayValue' | 'amount'
                                >
                                rrp: { __typename: 'MoneyValue' } & Pick<
                                  MoneyValue,
                                  'displayValue' | 'amount'
                                >
                              }
                            >
                          }
                        >
                        options: Array<
                          { __typename: 'VariantOption' } & Pick<
                            VariantOption,
                            'key'
                          > & {
                              choices: Array<
                                { __typename: 'OptionChoice' } & Pick<
                                  OptionChoice,
                                  'optionKey' | 'key' | 'colour' | 'title'
                                >
                              >
                            }
                        >
                        variants: Array<
                          { __typename?: 'ProductVariant' } & Pick<
                            ProductVariant,
                            'inStock' | 'inStockLocations' | 'title'
                          > & {
                              product?: Maybe<
                                { __typename?: 'Product' } & Pick<
                                  Product,
                                  'sku' | 'url'
                                >
                              >
                              content: Array<
                                { __typename?: 'ProductContentItem' } & Pick<
                                  ProductContentItem,
                                  'key'
                                > & {
                                    value: {
                                      __typename?: 'ProductContentStringValue'
                                    } & {
                                      stringValue: ProductContentStringValue['value']
                                    }
                                  }
                              >
                              choices: Array<
                                { __typename: 'OptionChoice' } & Pick<
                                  OptionChoice,
                                  'optionKey' | 'key'
                                >
                              >
                            }
                        >
                      }
                  >
                }
              >
            })
        | { __typename?: 'GlobalSetAndromeda' }
        | { __typename?: 'GlobalSimpleTextCTAWidget' }
        | { __typename?: 'GlobalSixItemCategories' }
        | { __typename?: 'GlobalSocialIcon' }
        | { __typename?: 'GlobalSocialIconCollection' }
        | { __typename?: 'GlobalSocialIconCollectionv2' }
        | { __typename?: 'GlobalSocialIconv2' }
        | { __typename?: 'GlobalStripBanner' }
        | { __typename?: 'GlobalSubscriptionOptions' }
        | { __typename?: 'GlobalTabbedWidgetSet' }
        | { __typename?: 'GlobalThreeItemEditorial' }
        | { __typename?: 'GlobalThreeItemEditorialSubtitleBG' }
        | { __typename?: 'GlobalTransformationSlider' }
        | { __typename?: 'GlobalTrendingHashtagBlock' }
        | { __typename?: 'GlobalTrendingHashtagCollection' }
        | { __typename?: 'GlobalTwoBestSellers' }
        | { __typename?: 'GlobalTwoItemEditorial' }
        | { __typename?: 'GlobalTwoItemImageTextBlock' }
        | { __typename?: 'GlobalTwoItemImageTextCTA' }
        | { __typename?: 'GlobalTwoItemImageTextCTA3070' }
        | { __typename?: 'GlobalUGCCarousel' }
        | { __typename?: 'GlobalVideoGallery' }
        | { __typename?: 'GlobalVideoHeroBannerWidget' }
        | { __typename?: 'GlobalVideoTextImageCTA' }
        | { __typename?: 'GlobalWaitListSignUpFormResponses' }
        | { __typename?: 'GlobalWaitListSignUpWidget' }
        | { __typename?: 'GlobalWidgetSirius' }
        | { __typename?: 'GlobalWidgetVega' }
        | { __typename?: 'GlobalYoutubeGalleryItem' }
        | { __typename?: 'GlobalYoutubeGallerySet' }
        | { __typename?: 'LiveChatWidget' }
        | { __typename?: 'LoyaltyHubBirthdayGift' }
        | { __typename?: 'LoyaltyHubTier' }
        | { __typename?: 'LoyaltyRewardTier' }
        | { __typename?: 'LoyaltyRewardTiers' }
        | { __typename?: 'MailingList' }
        | { __typename?: 'MultipleCTABanner' }
        | { __typename?: 'ProductListWidget' }
        | { __typename?: 'ProductRecommenderItem' }
        | { __typename?: 'ProfileWidget' }
        | { __typename?: 'ResponsiveBuildYourOwnBundle' }
        | { __typename?: 'ResponsiveBuildYourOwnBundleProducts' }
        | { __typename?: 'ResponsiveComparisonTable' }
        | { __typename?: 'ResponsiveComparisonTableContainer' }
        | { __typename?: 'ResponsiveProductBlockCarousel' }
        | { __typename?: 'ResponsiveProductTabs' }
        | { __typename?: 'ResponsiveSetSalon' }
        | { __typename?: 'ResponsiveSetSalonWithSlides' }
        | { __typename?: 'ResponsiveSliderSet' }
        | { __typename?: 'ResponsiveSuccessStoriesWidgetContainer' }
        | { __typename?: 'ResponsiveSuccessStoryWidget' }
        | { __typename?: 'ResponsiveTwoItemComparisonContainer' }
        | { __typename?: 'ResponsiveTwoItemComparisonRow' }
        | { __typename?: 'ResponsiveUSPBar' }
        | { __typename?: 'ResponsiveUSPBarCustomisable' }
        | { __typename?: 'SeoProductCategory' }
        | { __typename?: 'SeoProductCategorySet' }
        | { __typename?: 'ShopTheLookHotSpot' }
        | { __typename?: 'SimpleTextReadMore' }
        | { __typename?: 'TopBrandsWidget' }
        | { __typename?: 'TopProductCategory' }
        | { __typename?: 'TopProductCategorySet' }
        | { __typename?: 'TrustPilotCarouselWidget' }
        | { __typename?: 'TrustPilotWidget' }
        | { __typename?: 'VoucherCodesWidget' }
        | { __typename?: 'asymmetricGrid' }
        | { __typename?: 'buildyourownbundle' }
        | { __typename?: 'buttonLink' }
        | { __typename?: 'categoryItemCard' }
        | { __typename?: 'categoryItemCardWithTextOverlay' }
        | { __typename?: 'coverageCalculator' }
        | { __typename?: 'eightLinkButtonWidget' }
        | { __typename?: 'fouritemwindowproductslider' }
        | { __typename?: 'generalIframe' }
        | { __typename?: 'generalQuoteBanner' }
        | { __typename?: 'imageSelectCard' }
        | { __typename?: 'imageSelectSlider' }
        | { __typename?: 'improvedSearchBestSellers' }
        | { __typename?: 'kitBuilder' }
        | { __typename?: 'loyaltyRewardsList' }
        | { __typename?: 'loyaltyWelcomeMessage' }
        | { __typename?: 'multiCategoryCardSet' }
        | { __typename?: 'multiCategoryCardSetV2' }
        | { __typename?: 'obsessVR' }
        | { __typename?: 'parcelLabWidget' }
        | { __typename?: 'promoproductslider' }
        | { __typename?: 'propositionBar' }
        | { __typename?: 'propositionBarIncorrect' }
        | { __typename?: 'provenanceBrandHeader' }
        | { __typename?: 'qubitHomePageRecs' }
        | { __typename?: 'revieveWidget' }
        | { __typename?: 'shadeFinder' }
        | { __typename?: 'shopTheLook' }
        | { __typename?: 'simpleCTA' }
        | { __typename?: 'simpleDivider' }
        | { __typename?: 'simpleTextWidgetSet' }
        | { __typename?: 'simpleTitleWidget' }
        | { __typename?: 'simpletextwidget' }
        | { __typename?: 'simpletextwidgetwithh1' }
        | { __typename?: 'sponsoredProducts' }
        | { __typename?: 'sponsoredProductsCriteoHomepage' }
        | { __typename?: 'sponsoredProductsNew' }
        | { __typename?: 'storyStreamWidget' }
        | { __typename?: 'subscribeAndSaveInformationModal' }
        | { __typename?: 'swatchSelectSlider' }
        | { __typename?: 'swatchSelectSliderSet' }
        | { __typename?: 'trustReinforcementContainer' }
        | { __typename?: 'trustReinforcementSection' }
        | { __typename?: 'videoEditorial' }
        | { __typename?: 'yotiAgeVerification' }
        | { __typename?: 'yotiCancelFrame' }
      >
    }
  >
}
