import * as React from 'react'
import { InView } from 'react-intersection-observer'
import Media from 'react-media'

import {
  BrandLogo,
  ProductButton,
  ProductImageUrls,
  ProductPrice,
  ProductPriceProps,
} from '@thg-commerce/gravity-elements'
import { TagStyle } from '@thg-commerce/gravity-elements/Image/Image'
import { ProductQuantityProps } from '@thg-commerce/gravity-elements/Product/ProductQuantity/ProductQuantity'
import { ProductReviewProps } from '@thg-commerce/gravity-elements/Product/ProductReview/ProductReview'
import { SwatchProps } from '@thg-commerce/gravity-elements/Swatch'
import { MarketedSpecialOfferSummaryContainerProps } from '@thg-commerce/gravity-patterns/MarketedSpecialOfferSummary'
import {
  ProductOptions,
  ProductOptionsProps,
} from '@thg-commerce/gravity-patterns/ProductOptions'
import { useTheme } from '@thg-commerce/gravity-patterns/theme'
import {
  BreakpointVisibility,
  PictureProps,
  Visibility,
  VisibilityHelper,
} from '@thg-commerce/gravity-system'
import { usePrefetch } from '@thg-commerce/gravity-system/prefetch'
import { BreakpointArray, mq } from '@thg-commerce/gravity-theme'
import { Margin } from '@thg-commerce/gravity-theme/margin'
import { TextStyling } from '@thg-commerce/gravity-theme/typography/typography'

import { Carousel, CarouselButtonPlacement } from '../Carousel'

import { ProductBlockPicture } from './ProductBlockPicture'
import {
  AttributesContainer,
  Brand,
  ButtonContainer,
  Container,
  Description,
  IconsAndPriceWrapper,
  IconsWrapper,
  ImageContainer,
  Label,
  PowerReviewCategorySnippetContainer,
  ProductOptionsContainer,
  StyledAnchor,
  StyledButton,
  StyledHoverImage,
  StyledHoverImageContainer,
  StyledImage,
  StyledMarketedSpecialOfferSummaryContainer,
  StyledProductButton,
  StyledProductQuantity,
  StyledProductReview,
  StyledSwatch,
  Title,
  TitleWrapper,
} from './styles'
import { Directions, FlexAlignments, HorizontalAlignment } from './types'

export interface ProductBlockTitleProps {
  value: string
  hideLabel?: boolean
  useAlternateStyle?: boolean
}

export interface ProductBlockButtonProps {
  title: string
  ariaLabel?: string
  disabled?: boolean
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void
  dataTestId?: string
  disableHref?: boolean
  enableQuickbuyOnlyOnMobile?: boolean
  mobileSingleColumn?: boolean
  buttonStyle?: {
    alignItem: FlexAlignments
    isFullWidth: boolean
    desktopWidth: string
  }
}

interface ProductBlockAttributesProps {
  justifyContent?: FlexAlignments
  alignItems?: FlexAlignments
  gap?: BreakpointArray<number>
  maxWidth?: string
}

export interface ProductBlockImageProps {
  urls: ProductImageUrls
  lazy?: boolean
  altText?: string
  maxWidths?: string[]
  width?: number | string | number[] | string[]
  height?: number | string | number[] | string[]
  fetchPriority?: 'low' | 'high' | 'auto'
}

export interface ProductBlockProps {
  title: ProductBlockTitleProps
  brand?: {
    name: string
    imageUrl?: string
  }
  url: string
  review?: ProductReviewProps | null
  price?: ProductPriceProps
  description?: string
  image: ProductBlockImageProps
  productImages?: ProductBlockImageProps[]
  annotateImageComponent?: React.ComponentType<{
    children: React.ReactNode
  }>
  powerReviewCategorySnippetComponent?: React.ReactNode
  marketedSpecialOffer?: MarketedSpecialOfferSummaryContainerProps | null
  picture?: PictureProps
  hoverPicture?: PictureProps
  pictures?: PictureProps[]
  hoverImage?: ProductBlockImageProps
  button?: ProductBlockButtonProps
  options?: ProductOptionsProps
  quantity?: ProductQuantityProps
  attributes?: ProductBlockAttributesProps
  condensed?: boolean
  directions?: Directions
  reversePriceAndQuantity?: boolean
  swatch?: SwatchProps
  icons?: React.ReactNode
  className?: string
  titleAlignment?: HorizontalAlignment
  onClickEventEmitter?: () => void
  showBrandTitle?: boolean
  showBrandLogo?: boolean
  hideProductListSwatch?: boolean
  attributesInsetSpacing?: BreakpointArray<number>
  externalIdentifier?: string
  showPowerReview?: boolean
  disableUrl?: boolean
  imagesCarousel?: boolean
  label?: string
  pricePerUnitContent?: React.ReactNode
  enableSmallQuickBuyButton?: boolean
  customStyling?: {
    productTitleMargin?: Margin
    quantityText?: TextStyling
    basketInformation?: { spacing: Margin }
    containerHeight?: string
  }
  content?: React.ReactNode
  inlineQuickBuyButton?: boolean
  loopSlides?: boolean
  removeATags?: boolean
  enablePapOverlay?: boolean
  rrpRange?: string
  priceRange?: string
  handleEvents?: () => void
  tagStyle?: TagStyle
  productVariant?: string
  disableHeightPowerReviews?: boolean
}

const getUseAlternateGap = (
  enableSmallQuickBuyButton: boolean | undefined,
  imagesCarousel: boolean | undefined,
) => {
  return enableSmallQuickBuyButton
    ? !imagesCarousel && enableSmallQuickBuyButton
    : !imagesCarousel
}

export const ProductBlock = ({
  image,
  annotateImageComponent,
  hoverImage,
  url,
  title: {
    value: titleValue,
    hideLabel: hideTitleLabel = false,
    useAlternateStyle = false,
  },
  brand,
  price,
  description,
  review,
  button,
  options,
  quantity,
  attributes,
  condensed = false,
  directions = ['column'],
  reversePriceAndQuantity = false,
  swatch,
  icons,
  className,
  onClickEventEmitter,
  titleAlignment,
  showBrandTitle = false,
  showBrandLogo = false,
  hideProductListSwatch = false,
  attributesInsetSpacing,
  picture,
  hoverPicture,
  pictures,
  enableSmallQuickBuyButton,
  powerReviewCategorySnippetComponent,
  marketedSpecialOffer,
  showPowerReview,
  label,
  pricePerUnitContent,
  disableUrl = false,
  customStyling,
  productImages,
  imagesCarousel,
  content,
  inlineQuickBuyButton,
  loopSlides,
  removeATags,
  enablePapOverlay,
  priceRange,
  rrpRange,
  handleEvents,
  tagStyle,
  productVariant,
  disableHeightPowerReviews,
}: ProductBlockProps) => {
  const theme = useTheme()
  const prefetchRef = usePrefetch({ url })

  const priceAndQuantity = [
    ...(price
      ? [
          <ProductPrice
            {...price}
            priceRange={priceRange}
            rrpRange={rrpRange}
            key="productblock-price"
          />,
        ]
      : []),
    ...(quantity
      ? [
          <StyledProductQuantity
            {...quantity}
            customStyling={customStyling?.quantityText}
            key="productblock-quantity"
          />,
        ]
      : []),
  ]
  const AnnotateImageContainer = annotateImageComponent ?? React.Fragment

  const visibility: BreakpointVisibility = {
    xs:
      (button?.enableQuickbuyOnlyOnMobile && !button?.mobileSingleColumn) ||
      inlineQuickBuyButton
        ? Visibility.HIDE
        : Visibility.SHOW,
    sm:
      button?.enableQuickbuyOnlyOnMobile || inlineQuickBuyButton
        ? Visibility.HIDE
        : Visibility.SHOW,
    md: button?.enableQuickbuyOnlyOnMobile ? Visibility.HIDE : Visibility.SHOW,
    lg: button?.enableQuickbuyOnlyOnMobile ? Visibility.HIDE : Visibility.SHOW,
  }
  const imageComponents = productImages?.map((image, index) => {
    return (
      <StyledImage
        {...image}
        alt={titleValue}
        fetchpriority={index === 0 ? 'high' : 'auto'}
        urls={{
          ...image.urls,
          tag: index === 0 ? image.urls.tag : '',
        }}
        {...(button && { onClick: () => window.location.assign(url) })}
        tagStyle={tagStyle}
      />
    )
  })

  const pictureComponents = pictures?.map((image) => {
    return (
      <ProductBlockPicture
        picture={image}
        {...(button && { onClick: () => window.location.assign(url) })}
        tagStyle={tagStyle}
      />
    )
  })

  return (
    <Container
      {...{
        directions,
      }}
      condensed={condensed}
      className={className}
      height={customStyling?.containerHeight}
      as={!button ? 'a' : undefined}
      ref={prefetchRef}
      href={!button ? url : undefined}
      title={!button ? titleValue : undefined}
      onClick={onClickEventEmitter}
      data-testid="product-block"
      useAlternateGap={getUseAlternateGap(
        enableSmallQuickBuyButton,
        imagesCarousel,
      )}
    >
      <Media query={mq(theme.breakpointUtils.map, 'sm', true)}>
        {(isDesktop) => (
          <div style={{ position: 'relative' }}>
            {!isDesktop && imagesCarousel ? (
              <Carousel
                data-testid={'image-carousel-element'}
                items={
                  pictureComponents ? pictureComponents : imageComponents || []
                }
                itemsPerSlide={[1]}
                hideControls={true}
                isAmp={false}
                hideSlidePreview={true}
                buttonPlacement={CarouselButtonPlacement.Split}
                {...(theme.patterns.productBlock.carousel && {
                  controls: theme.patterns.productBlock.carousel.controls,
                  indicatorStyle:
                    theme.patterns.productBlock.carousel.pageIndicator?.style,
                  dotHorizontalMargin:
                    theme.patterns.productBlock.carousel.pageIndicator
                      ?.dotHorizontalMargin,
                  indicatorWrapperMargin:
                    theme.patterns.productBlock.carousel.pageIndicator?.wrapper
                      .margin,
                })}
                fullWidthCarousel={true}
                loopSlides={loopSlides}
                swipeable={true}
              />
            ) : (
              <React.Fragment>
                {brand?.imageUrl && showBrandLogo && (
                  <BrandLogo
                    brandLabel={brand.name}
                    imageUrl={brand.imageUrl}
                  />
                )}
                <AnnotateImageContainer>
                  {picture ? (
                    <ProductBlockPicture
                      url={button && url}
                      picture={picture}
                      hoverPicture={hoverPicture}
                      focusStyle={theme.patterns.productBlock.focusStyle}
                      tagStyle={tagStyle}
                    />
                  ) : hoverImage ? (
                    <ImageContainer as={button && 'a'} href={button && url}>
                      <StyledImage
                        {...image}
                        alt={image?.altText || titleValue}
                        {...(button && {
                          onClick: () => window.location.assign(url),
                        })}
                        focusStyle={theme.patterns.productBlock.focusStyle}
                        tagStyle={tagStyle}
                      />
                      <StyledHoverImageContainer>
                        <StyledHoverImage
                          {...hoverImage}
                          lazy={hoverImage.lazy ?? true}
                          alt={hoverImage.altText || titleValue}
                          {...(button && {
                            onClick: () => window.location.assign(url),
                          })}
                          tagStyle={tagStyle}
                        />
                      </StyledHoverImageContainer>
                    </ImageContainer>
                  ) : (
                    <StyledAnchor
                      as={button ? 'a' : undefined}
                      role="link"
                      aria-label={
                        button ? image?.altText || titleValue : undefined
                      }
                      href={button && !disableUrl ? url : undefined}
                      focusStyle={theme.patterns.productBlock.focusStyle}
                    >
                      <StyledImage
                        {...image}
                        alt={image?.altText || titleValue}
                        onClick={
                          button && !disableUrl
                            ? () => window.location.assign(url)
                            : undefined
                        }
                        focusStyle={theme.patterns.productBlock.focusStyle}
                        tagStyle={tagStyle}
                      />
                    </StyledAnchor>
                  )}
                </AnnotateImageContainer>
              </React.Fragment>
            )}
            <VisibilityHelper key={button?.title} visibilityStatus={visibility}>
              {(enableSmallQuickBuyButton || inlineQuickBuyButton) &&
                button && (
                  <ProductButton
                    {...button}
                    roundButton={enableSmallQuickBuyButton}
                    href={button.disableHref ? undefined : url}
                    isCarouselButton={!isDesktop && imagesCarousel}
                    inlineQuickBuyButton={inlineQuickBuyButton}
                    emphasis={
                      theme.patterns.productBlock.components.productButton
                        .emphasis
                    }
                  />
                )}
            </VisibilityHelper>
          </div>
        )}
      </Media>
      {content && <React.Fragment>{content}</React.Fragment>}
      <AttributesContainer
        {...attributes}
        directions={directions}
        attributesInsetSpacing={attributesInsetSpacing}
      >
        {label && <Label>{label}</Label>}
        {!hideTitleLabel && button ? (
          <StyledButton
            sizing="regular"
            emphasis="low"
            renderedAs={!disableUrl ? 'a' : undefined}
            href={url}
            focusStyle={theme.patterns.productBlock.focusStyle}
            removePadding={button?.mobileSingleColumn ?? false}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {brand?.name && showBrandTitle && (
                <Brand
                  useAlternateStyle={useAlternateStyle}
                  alignment={titleAlignment}
                >
                  {brand.name}
                </Brand>
              )}
              <Title
                data-testid="productblock-title"
                useAlternateStyle={useAlternateStyle}
                alignment={titleAlignment}
                margin={customStyling?.productTitleMargin}
              >
                {titleValue}
              </Title>
            </div>
          </StyledButton>
        ) : (
          <TitleWrapper>
            {brand?.name && showBrandTitle && (
              <Brand
                useAlternateStyle={useAlternateStyle}
                alignment={titleAlignment}
              >
                {brand.name}
              </Brand>
            )}
            <Title
              data-testid="productblock-title"
              useAlternateStyle={false}
              alignment={titleAlignment}
            >
              {titleValue}
            </Title>
          </TitleWrapper>
        )}
        {!showPowerReview && review && review.numberOfReviews !== 0 && (
          <StyledProductReview
            {...review}
            url={removeATags ? undefined : url}
            focusStyle={theme.patterns.productBlock.focusStyle}
            colorFills={
              theme.patterns.productBlock.components.productReview.colorFills ||
              undefined
            }
          />
        )}
        {powerReviewCategorySnippetComponent && showPowerReview && (
          <PowerReviewCategorySnippetContainer
            disableHeightPowerReviews={disableHeightPowerReviews}
          >
            {powerReviewCategorySnippetComponent}
          </PowerReviewCategorySnippetContainer>
        )}
        {marketedSpecialOffer && !enablePapOverlay && (
          <InView triggerOnce={true}>
            {({ inView, ref }) => (
              <StyledMarketedSpecialOfferSummaryContainer
                badgeStyleOverride={{
                  border: theme.patterns.marketedSpecialOffer.badge.border,
                  textAlign:
                    theme.patterns.marketedSpecialOffer.badge.textAlign,
                }}
                modalStyleOverride={{
                  display: theme.patterns.marketedSpecialOffer.modal?.display,
                  textAlign:
                    theme.patterns.marketedSpecialOffer.modal?.textAlign,
                  descriptionColorText:
                    theme.patterns.marketedSpecialOffer.modal
                      ?.descriptionColorText,
                }}
                {...marketedSpecialOffer}
                focusStyle={theme.patterns.productBlock.focusStyle}
                handleEvents={(event) =>
                  handleEvents && handleEvents(event || '')
                }
                isInView={inView}
                ref={ref}
              />
            )}
          </InView>
        )}
        {swatch && swatch.colours?.length > 0 && !hideProductListSwatch && (
          <StyledSwatch
            {...swatch}
            shape={theme.patterns.productBlock.components.swatch.shape}
            borderStyle={{
              color:
                theme.patterns.productBlock.components.swatch.border?.color,
            }}
            focusStyle={theme.patterns.productBlock.focusStyle}
            paddingBottom={
              theme.patterns.productBlock.components.swatch.padding?.bottom
            }
          />
        )}
        <IconsAndPriceWrapper
          withIcons={!!icons}
          data-testid="icons-and-price-container"
          as={removeATags ? undefined : 'a'}
          href={removeATags ? undefined : url}
          focusStyle={theme.patterns.productBlock.focusStyle}
        >
          {reversePriceAndQuantity
            ? priceAndQuantity.reverse()
            : priceAndQuantity}
          {pricePerUnitContent}
          {icons && <IconsWrapper>{icons}</IconsWrapper>}
        </IconsAndPriceWrapper>
        {description && <Description content={description} />}
        {options && options?.options?.length > 0 && (
          <ProductOptionsContainer>
            <ProductOptions {...options} />
          </ProductOptionsContainer>
        )}
        {button && !disableUrl && (
          <ButtonContainer
            alignItem={button.buttonStyle?.alignItem}
            isFullWidth={button.buttonStyle?.isFullWidth}
            desktopWidth={button.buttonStyle?.desktopWidth}
          >
            {!enableSmallQuickBuyButton && !inlineQuickBuyButton && button && (
              <StyledProductButton
                {...button}
                href={button.disableHref ? undefined : url}
                emphasis={
                  theme.patterns.productBlock.components.productButton.emphasis
                }
              />
            )}
          </ButtonContainer>
        )}
      </AttributesContainer>
    </Container>
  )
}
