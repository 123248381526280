import * as React from 'react'

import { styled } from '@thg-commerce/gravity-elements/theme'
import { ProductBlockPriceDisplayStyle } from '@thg-commerce/gravity-patterns/ProductBlock/theme'
import { useTheme } from '@thg-commerce/gravity-patterns/theme'
import {
  css,
  Orientation,
  spacing,
  Text,
  TextStyling,
} from '@thg-commerce/gravity-theme'

export interface ProductPriceProps {
  price: {
    defaultPrice: string
    minPrice?: string | null
    maxPrice?: string | null
  }
  rrp?: {
    defaultPrice?: string | null
    minPrice?: string | null
    maxPrice?: string | null
  } | null
  freeText?: string
  rrpText?: string
  discountAmount?: string
  vipPrice?: string
  hasFromPrice?: boolean
  fromText?: string
  className?: string
  saveUpToText?: string
  maxSavings?: string
  rrpRange?: string
  priceRange?: string
  showRrpInline?: boolean
  savings?: {
    show?: boolean
    textStyle?: TextStyling
  }
  i18nText?: {
    saveLabel?: string
    offLabel?: string
  }
  savingsPercentage?: {
    show?: boolean
    showPercentageOnly?: boolean
  }
}

const Container = styled.div<{
  displayStyle?: string
  orientation?: Orientation
}>`
  display: flex;
  align-items: ${(props) =>
    props.theme.elements.productPrice.align ||
    (props.orientation === Orientation.HORIZONTAL ? 'flex-end' : 'flex-start')};
  flex-direction: ${(props) =>
    props.theme.elements.productPrice.direction ||
    (props.orientation === Orientation.HORIZONTAL ? 'row' : 'column')};
  ${(props) =>
    Text(
      props.theme.elements.productPrice.priceWithRRP.entry,
      props.theme.elements.productPrice.priceWithRRP.style,
    )};
  ${(props) =>
    props.orientation === Orientation.HORIZONTAL && `gap: ${spacing(1)};`}
`
const Price = styled.div<{
  hasRrp: boolean
}>`
  ${(props) =>
    Text(
      props.hasRrp
        ? props.theme.elements.productPrice.priceWithRRP.entry
        : props.theme.elements.productPrice.price.entry,
      props.hasRrp
        ? props.theme.elements.productPrice.priceWithRRP.style
        : props.theme.elements.productPrice.price.style,
    )}
  color: ${(props) =>
    props.hasRrp
      ? props.theme.elements.productPrice.priceWithRRP.textColor
      : props.theme.elements.productPrice.price.textColor};
`

const RrpContainer = styled.div<{ showInline?: boolean }>`
  ${Text('xsmall', 'default')};
  display: ${(props) => (props.showInline ? 'inline' : 'flex')};
  align-items: center;
  ${(props) => props.showInline && `padding-left: ${spacing(1)};`}
`

const RrpPrice = styled.div<{ showInline?: boolean }>`
  ${(props) =>
    Text(
      props.theme.elements.productPrice.rrp.entry,
      props.theme.elements.productPrice.rrp.style,
    )};
  color: ${(props) => props.theme.elements.productPrice.rrp.textColor};
  text-decoration: ${(props) =>
    props.theme.elements.productPrice.rrp.textDecoration};
  ${(props) => props.showInline && `display: inline;`}
`

const RrpText = styled.span`
  margin-right: ${spacing(0.5)};
  ${(props) =>
    Text(
      props.theme.elements.productPrice.rrp.entry,
      props.theme.elements.productPrice.rrp.style,
    )};
  color: ${(props) => props.theme.elements.productPrice.rrp.textColor};
`

const SaveUpToContainer = styled.div`
  ${(props) =>
    Text(
      props.theme.elements.productPrice.saveUpTo.entry,
      props.theme.elements.productPrice.saveUpTo.style,
    )};
  color: ${(props) => props.theme.elements.productPrice.saveUpTo.textColor};
  text-decoration: ${(props) =>
    props.theme.elements.productPrice.saveUpTo.textDecoration};
`

const createPriceValue = (config: {
  displayStyle: ProductBlockPriceDisplayStyle
  defaultPrice?: string | null
  range?: string | null
}) => {
  if (!config.range) {
    return config.defaultPrice || null
  }

  if (
    config.displayStyle === ProductBlockPriceDisplayStyle.PRICE_RANGE &&
    config.range
  ) {
    return config.range
  }

  return config.defaultPrice || null
}

const getRrpContainer = (
  shouldShowRRPText: boolean,
  shouldShowRRPValue: boolean,
  rrpValue: string | null | undefined,
  rrpTextFromProp: string | undefined,
  rrpTextFromTheme: string | undefined,
  shouldShowRRP: boolean = false,
  showRrpInline: boolean = false,
) =>
  rrpValue &&
  shouldShowRRP && (
    <RrpContainer showInline={showRrpInline}>
      {shouldShowRRPText && (
        <RrpText>{rrpTextFromProp ?? rrpTextFromTheme}</RrpText>
      )}
      {shouldShowRRPValue && (
        <RrpPrice data-testid="rrp-price" showInline={showRrpInline}>
          {rrpValue}
        </RrpPrice>
      )}
    </RrpContainer>
  )

const SaveText = styled.span<{
  savingStyle?: TextStyling
}>`
  ${(props) =>
    props.savingStyle
      ? css`
          ${Text(props.savingStyle.entry, props.savingStyle.style)};
          color: ${props.savingStyle.textColor};
          text-decoration: ${props.savingStyle.textDecoration};
        `
      : css`
          ${Text('bodyText', 'default')};
          color: ${({ theme }) => theme.colors.error.base};
        `}
`

export const ProductPrice = ({
  freeText,
  price,
  rrp,
  rrpText,
  hasFromPrice,
  fromText,
  className,
  saveUpToText,
  maxSavings,
  rrpRange,
  priceRange,
  showRrpInline,
  savings,
  i18nText,
  savingsPercentage,
}: ProductPriceProps) => {
  const theme = useTheme()
  const save = React.useMemo(() => {
    const currencySymbol = price.defaultPrice
      .replace(/[0-9\.,]+/g, '')
      .charAt(0)
    const indexOfCurrency = price.defaultPrice.indexOf(currencySymbol)
    const numberOnlyRegex = /[^0-9\.,]+/g
    const priceAsNumber = parseFloat(
      price.defaultPrice.replace(numberOnlyRegex, ''),
    )
    const rrpAsNumber = rrp?.defaultPrice
      ? parseFloat(rrp?.defaultPrice.replace(numberOnlyRegex, ''))
      : NaN

    if (
      priceAsNumber === rrpAsNumber ||
      priceAsNumber > rrpAsNumber ||
      isNaN(priceAsNumber) ||
      isNaN(rrpAsNumber)
    ) {
      return null
    }
    const savings = (rrpAsNumber - priceAsNumber).toFixed(2)
    return {
      value:
        indexOfCurrency !== 0
          ? `${savings}${currencySymbol}`
          : `${currencySymbol}${savings}`,
      percentage: (
        ((rrpAsNumber - priceAsNumber) / rrpAsNumber) *
        100
      ).toFixed(),
    }
  }, [price, rrp])

  if (freeText) {
    return <Container className={className}>{freeText}</Container>
  }

  const {
    displayStyle,
    orientation,
    displayRRPText,
    displayRRPValue,
    displayFromText,
  } = theme.patterns.productBlock.price

  const priceValue = createPriceValue({
    ...price,
    displayStyle,
    range: priceRange,
  })

  const rrpValue = createPriceValue({
    ...rrp,
    displayStyle,
    range: rrpRange,
  })

  const shouldShowRRPText = displayRRPText ?? true

  const shouldShowRRPValue = displayRRPValue ?? true

  const shouldShowFromText = displayFromText ?? false

  const fromPriceText = shouldShowFromText ? fromText : ''

  const getSavePercentage = () =>
    save &&
    savingsPercentage?.show &&
    save.percentage &&
    (savingsPercentage?.showPercentageOnly && i18nText?.offLabel
      ? ` ${save.percentage}% ${i18nText?.offLabel}`
      : i18nText?.offLabel
      ? ` (${save.percentage}% ${i18nText?.offLabel})`
      : ` (${save.percentage}%)`)

  const getSaveText = () => {
    const showSaveText = savings?.show && save && !hasFromPrice

    return (
      showSaveText && (
        <SaveText savingStyle={savings?.textStyle}>
          {i18nText?.saveLabel && `${i18nText.saveLabel}: `}
          {!savingsPercentage?.showPercentageOnly && save.value}
          {getSavePercentage()}
        </SaveText>
      )
    )
  }

  return (
    <Container
      className={className}
      displayStyle={displayStyle}
      orientation={orientation}
    >
      {!shouldShowFromText && hasFromPrice && <span>{fromText}</span>}
      {getRrpContainer(
        shouldShowRRPText,
        shouldShowRRPValue,
        rrpValue,
        rrpText,
        theme.patterns.productBlock.price.rrpText,
        !showRrpInline,
        showRrpInline,
      )}
      {priceValue && (
        <Price hasRrp={!!rrpValue} data-testid="sale-price">
          {fromPriceText} {priceValue}
          {getRrpContainer(
            shouldShowRRPText,
            shouldShowRRPValue,
            rrpValue,
            rrpText,
            theme.patterns.productBlock.price.rrpText,
            showRrpInline,
            showRrpInline,
          )}
        </Price>
      )}
      {getSaveText()}
      {Boolean(maxSavings) && (
        <SaveUpToContainer data-testid="savings-price">
          {saveUpToText} {maxSavings}
        </SaveUpToContainer>
      )}
    </Container>
  )
}
