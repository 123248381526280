import * as React from 'react'
import loadable from '@loadable/component'

import { Box, Flex } from '@thg-commerce/gravity-system'
import { mq, spacing, styled, Text } from '@thg-commerce/gravity-theme'

const IconError = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/IconError'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const IconSuccess = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/IconSuccess'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const IconInfo = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/IconInfo'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

import { Button, PlatformMessageType, RenderAnnouncerType, SafeHtml } from '../'

export interface PlatformMessageProps {
  /** Specify type of message */
  type: PlatformMessageType
  text: string
  centerText?: boolean
  subText?: string
  content?: React.ReactNode
  className?: string
  'data-testid'?: string
  renderAnnouncer?: RenderAnnouncerType
  containerRef?: React.RefObject<HTMLDivElement>
  /** Small sizing can be used for field level messaging */
  sizing?: 'small'
  hideIconMobile?: boolean
  iconBoxWidth?: number
  button?: { text: string; url: string }
  iconTopPosition?: boolean
}

const BaseMessage = styled.div<{
  type: PlatformMessageType
}>`
  display: inline-block;
  padding: ${spacing(2)};
  width: 100%;
  background-color: ${(props) => {
    switch (props.type) {
      case 'success':
        return props.theme.colors.success.light
      case 'error':
        return props.theme.colors.error.light
      case 'info':
      default:
        return props.theme.colors.info.light
    }
  }};
`

const StyledIconSuccess = styled(IconSuccess)`
  path {
    fill: ${(props) => props.theme.colors.success.base};
  }
`

const MessageText = styled(SafeHtml)<{
  type: PlatformMessageType
  hideIconMobile?: boolean
}>`
  ${Text('bodyText', 'alternate')}
  margin: ${(props) =>
    props.hideIconMobile ? `${spacing(0)}` : `${spacing(0)} ${spacing(2)}`};
  align-self: center;
  min-height: 20px;
  color: ${(props) => {
    switch (props.type) {
      case 'success':
        return props.theme.colors.success.base
      case 'error':
        return props.theme.colors.error.base
      case 'info':
      default:
        return props.theme.colors.info.base
    }
  }};

  a {
    ${Text('bodyText', 'alternate')}
    color: ${(props) => {
      switch (props.type) {
        case 'success':
          return props.theme.colors.success.base
        case 'error':
          return props.theme.colors.error.base
        case 'info':
        default:
          return props.theme.colors.info.base
      }
    }};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin: ${spacing(0)} ${spacing(2)};
  }
`

const MessageSubText = styled(SafeHtml)<{ type: PlatformMessageType }>`
  ${Text('bodyText', 'default')}
  margin: ${spacing(0)} ${spacing(2)};
  align-self: center;
  min-height: 20px;
  word-break: break-word;
  color: ${(props) => {
    switch (props.type) {
      case 'success':
        return props.theme.colors.success.base
      case 'error':
        return props.theme.colors.error.base
      case 'info':
      default:
        return props.theme.colors.info.base
    }
  }};

  a {
    ${Text('bodyText', 'alternate')}
    word-break: break-word;
    color: ${(props) => {
      switch (props.type) {
        case 'success':
          return props.theme.colors.success.base
        case 'error':
          return props.theme.colors.error.base
        case 'info':
        default:
          return props.theme.colors.info.base
      }
    }};
  }
`

const Content = styled.div<{ type: PlatformMessageType; sizing?: 'small' }>`
  margin: ${(props) =>
    `${spacing(0)} ${spacing(props.sizing === 'small' ? 1 : 2)}`};

  ${(props) => {
    if (props.sizing === 'small') {
      return Text('small', 'alternate')
    }
    return Text('bodyText', 'alternate')
  }}

  align-self: center;
  min-height: 20px;
  word-break: break-word;

  color: ${(props) => {
    switch (props.type) {
      case 'success':
        return props.theme.colors.success.base
      case 'error':
        return props.theme.colors.error.base
      case 'info':
      default:
        return props.theme.colors.info.base
    }
  }};

  > * {
    ${(props) => {
      if (props.sizing === 'small') {
        return Text('small', 'alternate')
      }
      return Text('bodyText', 'alternate')
    }}

    align-self: center;
    min-height: 20px;
    word-break: break-word;

    color: ${(props) => {
      switch (props.type) {
        case 'success':
          return props.theme.colors.success.base
        case 'error':
          return props.theme.colors.error.base
        case 'info':
        default:
          return props.theme.colors.info.base
      }
    }};
  }

  button:hover,
  button:focus,
  button:active {
    ${(props) => {
      if (props.sizing === 'small') {
        return Text('small', 'alternate')
      }
      return Text('bodyText', 'alternate')
    }}

    align-self: center;
    min-height: 20px;
    word-break: break-word;

    color: ${(props) => {
      switch (props.type) {
        case 'success':
          return props.theme.colors.success.base
        case 'error':
          return props.theme.colors.error.base
        case 'info':
        default:
          return props.theme.colors.info.base
      }
    }};
  }
`

const IconBox = styled(Box)<{
  hideIconMobile?: boolean
  iconBoxWidth?: number
  iconTopPosition?: boolean
}>`
  display: ${(props) => (props.hideIconMobile ? 'none' : 'flex')};
  align-items: ${(props) => (props.iconTopPosition ? 'flex-start' : 'center')};

  ${(props) => props.iconBoxWidth && 'width: ${props.iconBoxWidth}px;'}
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: flex;
  }
`

const ContentBox = styled(Box)`
  width: 100%;
`

const Icon = ({ type }: { type: PlatformMessageType }) => {
  switch (type) {
    case 'success':
      return <StyledIconSuccess data-testid="platform-message-success-icon" />
    case 'error':
      return <IconError data-testid="platform-message-error-icon" />
    case 'info':
    default:
      return <IconInfo data-testid="platform-message-info-icon" />
  }
}

const PlatformMessageStyledButton = styled(Button)`
  margin-top: ${spacing(3)};
  width: 100%;
`
const StyledButtonText = styled.div`
  ${Text('bodyText', 'default')}
  color: inherit;
`

export const PlatformMessage = (props: PlatformMessageProps) => {
  const { type, className, 'data-testid': dataTestId, renderAnnouncer } = props

  return (
    <BaseMessage
      ref={props.containerRef}
      type={type}
      className={className}
      data-testid={dataTestId}
    >
      <Flex
        flexDirection="row"
        justifyContent={props.centerText ? 'center' : ''}
      >
        <IconBox
          hideIconMobile={props.hideIconMobile}
          iconBoxWidth={props.iconBoxWidth}
          iconTopPosition={props.iconTopPosition}
        >
          <Icon type={type} />
        </IconBox>
        <ContentBox>
          {'content' in props ? (
            <Content type={type} sizing={props.sizing}>
              {props.content}
            </Content>
          ) : (
            <React.Fragment>
              <MessageText
                type={type}
                data-testid="platform-message-text"
                aria-live="polite"
                content={props.text}
                removeTagsAndContent={false}
                hideIconMobile={props.hideIconMobile}
              />
              {props.subText && (
                <MessageSubText
                  type={type}
                  data-testid="platform-message-subtext"
                  content={props.subText}
                  removeTagsAndContent={false}
                />
              )}
            </React.Fragment>
          )}
        </ContentBox>
      </Flex>
      {props.button && (
        <PlatformMessageStyledButton
          data-testid="info-message-button"
          emphasis="high"
          href={props.button.url}
          renderedAs="a"
        >
          <StyledButtonText>{props.button.text}</StyledButtonText>
        </PlatformMessageStyledButton>
      )}

      {'text' in props &&
        renderAnnouncer &&
        renderAnnouncer(
          'assertive',
          `${props.text}${props.subText ? ` ${props.subText}` : ''}`,
        )}
    </BaseMessage>
  )
}

export default PlatformMessage
