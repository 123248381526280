import * as React from 'react'
export const SvgChevronDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      fill={props.fill || '#333'}
      d="M16.137 9l1.363 1.39L12 16l-5.5-5.61L7.863 9 12 13.22z"
    />
  </svg>
)
export default SvgChevronDown
