export enum ProductChoiceType {
  DROPDOWN = 'DROPDOWN',
  RADIO = 'RADIO',
  IMAGE = 'IMAGE',
  SWATCH = 'SWATCH',
}

export type ProductChoiceTypeMap = {
  [key: string]: {
    type: ProductChoiceType
    propertyKey: string
    placeholderPropertyKey: string
  }
}

export interface DataLayerMap
  extends Record<
    string,
    Record<string, { propertyKey: string; label: string }>
  > {}

export enum PaymentType {
  AFTER_PAY = 'AFTERPAY',
  AMAZONPAY = 'AMAZONPAY',
  ALI_PAY = 'ALIPAY',
  ALI_PAY_PLUS = 'ALIPAY_PLUS',
  ATOME = 'ATOME',
  APPLE_PAY = 'APPLEPAY',
  ARVATO = 'ARVATO',
  ADYEN = 'ADYEN',
  CLEAR_PAY = 'CLEARPAY',
  GOOGLE_PAY = 'GOOGLEPAY',
  KLARNA = 'KLARNA',
  KLARNA_LATER = 'KLARNA_LATER',
  KLARNA_SLICE = 'KLARNA_SLICE',
  KLARNA_NOW = 'KLARNA_NOW',
  LAY_BUY = 'LAYBUY',
  MODE = 'MODE',
  MULTIBANCO = 'MULTIBANCO',
  NEWPAY = 'NEWPAY',
  OPEN_PAY = 'OPENPAY',
  PAYPAL = 'PAYPAL',
  PAYPAL_IN_3 = 'PAYPAL_IN_3',
  PAYVYNE = 'PAYVYNE',
  QUAD_PAY = 'QUADPAY',
  SEZZLE = 'SEZZLE',
  SPLIT_IT = 'SPLITIT',
  SPOTII = 'SPOTII',
  VISA_CHECKOUT = 'VISA_CHECKOUT',
  WE_CHAT_PAY = 'WECHATPAY',
  ZIP_PAY = 'ZIPPAY',
  GIFT_CARD = 'GIFT_CARD',
  RAZORPAY = 'RAZORPAY',
  FRASERS_PLUS = 'FRASERS_PLUS',
}

export enum SubPaymentType {
  DINERS_CLUB = 'diners',
  DISCOVER = 'discover',
  GIROPAY = 'giropay',
  IDEAL = 'ideal',
  INTERAC = 'interac',
  JCB = 'jcb',
  KCP = 'kcp',
  MOL_PAY = 'molpay_ebanking_fpx_MY',
  PAYPAL = 'paypal',
  PAYTM = 'paytm',
  PRIMEIRO_PAY = 'primeiropay_boleto',
  QIWI_WALLET = 'qiwiwallet',
  SEPA = 'sepadirectdebit',
  SOFORT = 'directEbanking',
  TRUSTLY = 'trustly',
  TRUST_PAY = 'trustpay',
  UNION_PAY = 'unionpay',
  CUP = 'cup',
  BANCONTACT = 'bcmc',
  UPI = 'upi',
  VISA = 'visa',
  MASTERCARD = 'mastercard',
}

export enum PaymentIcon {
  AFTER_PAY = 'after_pay',
  AMAZONPAY = 'amazonpay',
  ALI_PAY = 'ali_pay',
  ALI_PAY_PLUS = 'ali_pay_plus',
  ATOME = 'atome',
  SPOTII = 'spotii',
  AMERICAN_EXPRESS = 'american_express',
  APPLE_PAY = 'apple_pay',
  ARVATO = 'arvato',
  BANCONTACT = 'bancontact',
  CLEAR_PAY = 'clear_pay',
  DINERS_CLUB = 'diners_club',
  DISCOVER = 'discover',
  GIFT_CARD = 'gift_card',
  GIRO_PAY = 'giro_pay',
  GOOGLE_PAY = 'google_pay',
  IDEAL = 'ideal',
  INTERAC = 'interac',
  JCB = 'jcb',
  KCP = 'kcp',
  KLARNA = 'klarna',
  KLARNA_SLICE = 'klarna_slice',
  LAY_BUY = 'lay_buy',
  MODE = 'mode',
  MOL_PAY = 'mol_pay',
  MULTIBANCO = 'multibanco',
  NEWPAY = 'newpay',
  OPEN_PAY = 'open_pay',
  PAYPAL = 'paypal',
  PAYPAL_IN_3 = 'paypal_in_3',
  PAYTM = 'paytm',
  PAYVYNE = 'payvyne',
  PRIMEIRO_PAY = 'primeiro_pay',
  QIWI_WALLET = 'qiwi_wallet',
  QUAD_PAY = 'quad_pay',
  SEPA = 'sepa',
  SEZZLE = 'sezzle',
  SOFORT = 'sofort',
  SPLIT_IT = 'split_it',
  TRUSTLY = 'trustly',
  TRUST_PAY = 'trust_pay',
  UNION_PAY = 'union_pay',
  WE_CHAT_PAY = 'we_chat_pay',
  ZIP_PAY = 'zip_pay',
  VISA_CHECKOUT = 'visa_checkout',
  UPI = 'upi',
  MASTERCARD = 'mastercard',
  FRASERS_PLUS = 'frasers_plus',
}

export interface LocalPaymentType {
  id: number
  site: number
  subsite: string
  currency: string
  paymentType: PaymentType
  subPaymentType: SubPaymentType | null
  subPaymentTypeDisplayText: string | null
  countryCode: string | null
  paymentTypeElevation: {
    [criteriaAttribute: string]: {
      [criteriaData: string]: {
        criteriaAttribute: 'LOCATION' | 'UNKNOWN'
        criteriaData: string
        elevationScore: number
      }
    }
  }
  allowedForNonSubscription: boolean
  allowedForSubscription: boolean
  allowedForPreorder: boolean
}

export interface PaymentMethod {
  icon: PaymentIcon
  type: PaymentType
  requiredCustomerLocation?: string
  basketOrder: number
}

export type SessionSettingsPaymentMethods = {
  [currencyCode: string]: {
    [shippingDestination: string]: PaymentMethod[]
  }
}

export type HeaderSlot = HeaderSlotLink | HeaderSlotDropdown | HeaderSlotModal

export enum HeaderSlotType {
  LINK = 'LINK',
  DROPDOWN = 'DROPDOWN',
  MODAL = 'MODAL',
}

export enum HeaderSlotDropdownType {
  ACCOUNT = 'ACCOUNT',
  BASKET = 'BASKET',
}

export enum HeaderSlotModalType {
  SESSION_SETTINGS = 'SESSION_SETTINGS',
}

export interface HeaderSlotLink {
  textProperty: string
  type: HeaderSlotType.LINK
  urlProperty: string
}

export interface HeaderSlotDropdown {
  textProperty: string
  type: HeaderSlotType.DROPDOWN
  dropdownType: HeaderSlotDropdownType
}

export interface HeaderSlotModal {
  textProperty: string
  type: HeaderSlotType.MODAL
  modalType: HeaderSlotModalType
}

export enum CSPMode {
  DISABLED = 'DISABLED',
  BLOCKING = 'BLOCKING',
  REPORT_ONLY = 'REPORT_ONLY',
}

export interface ShippingDestination {
  code: string
  name: string
}

export interface NextConfig {
  publicRuntimeConfig: {
    ENABLE_LAYOUT_ESI: boolean
    ENABLE_TRANSMIT_BEACONS: boolean
    DEEPSPACE_URL: string
    GRAPHQL_SERVER_URI: string
    GRAPHQL_CLIENT_OVERRIDE: boolean
    IGNORE_RATE_LIMITING: boolean
    USE_TEST_CAPTCHA: boolean
    SITE_PROPERTIES_OVERRIDE: boolean
    SITE_PROPERTIES_BUCKET: string
    APP_NAME: string
    APP_VERSION: string
    APP_GENERATOR_CONTENT: string
    CACHE_PREFIX: string
    IS_PRODUCTION: boolean
    FLAGS: string[]
    FORCE_EMPTY_WIDGET_DATA: boolean
    SENTRY_DSN: string
    RENDER_ALT_SUBSITES: boolean
    assetPrefix: string
    servicePrefix: string
    siteConfig: {
      /** loaded dynamically */
    }
    siteDefinition: {
      /** loaded dynamically */
    }
    subsites: {
      /** loaded dynamically */
    }
    hreflangs: {
      /** loaded dynamically */
    }
    theme: {
      /** loaded dynamically */
    }
    basketCookieKey: string
    shippingDestinations: ShippingDestination[]
    sessionSettings: {
      /** loaded dynamically */
    }
  }
  serverRuntimeConfig: {
    THEME_UUID_NAMESPACE: string
    CONFIGURATION_PATH: string
    GALILEO_URL: string
    ISOLATED_LOAD_TEST: boolean
    FORCE_SSL: boolean
    DISABLE_CONFIG_CACHE: boolean
    DEEPSPACE_INTERNAL_URL: string
    siteDefinition: {
      /** loaded dynamically */
    }
  }
  poweredByHeader: boolean
  devIndicators: {
    autoPrerender: boolean
    buildActivity: boolean
  }
  assetPrefix: string
  useFileSystemPublicRoutes: boolean
}

export type SiteConfigMap = {
  [host: string]: { brand: string; subsite: string }
}

export enum ConfigFileName {
  SITE_DEFINITION = 'siteDefinition',
  SITE_CONFIG = 'siteConfig',
  SUBSITES = 'subsites',
  HREFLANGS = 'hreflangs',
  THEME = 'theme',
  SHIPPING_DESTINATIONS = 'shippingDestinations',
  SESSION_SETTINGS = 'sessionSettings',
  PRODUCT_DESCRIPTION_KEYS = 'productDescriptionKeys',
  PRODUCT_CHOICE_TYPES = 'productChoiceTypes',
  CONTENT_KEYS = 'contentKeys',
  DATA_LAYER_MAP_FILE = 'dataLayerMap',
  TRANSLATED_SUBSITES = 'translatedSubsites',
}

export interface SiteDefinitionInterface {
  graphQLServerURL: string
  siteCode: string
  customerServicesEmailAddress: string
  customerServicesTelephoneNumber: string
  domain: string
  hostname: string
  originUrl: string
  defaultCurrency: string
  defaultLocale: string
  siteName: string
  helpUrl: string
  checkoutUrl: string
  brand: string
  subsite: string
  siteId: string
  channel: string
  GRAPHQL_SERVER_URI: string
  contentRoutePrefix: string
  currencies: string[]
  imageServiceURL: string
  CONTENT_GRAPHQL_SERVER_URI?: string | null
  contentSlug?: string
  asgardToken?: string
  defaultSessionSettings: {
    currency: string
    shippingDestination: string
  }
}
