import * as React from 'react'

import { styled, spacing } from '@thg-commerce/enterprise-theme'
import {
  i18n,
  useTransmit,
  useSiteDefinition,
  EnterpriseContext,
} from '@thg-commerce/enterprise-core'
import { getColumboEventData } from '@thg-commerce/enterprise-metrics'

const StyledLink = styled.a`
  position: absolute;
  clip: rect(0, 0, 0, 0);
  background-color: ${(props) => props.theme.colors.palette.greys.white};

  :focus {
    position: relative;
    display: flex;
    z-index: 1;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    outline: none;
    padding: ${spacing(1)};
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`

export const SkipLink = () => {
  const transmit = useTransmit()
  const EnterpriseCtx = React.useContext(EnterpriseContext)
  const { defaultLocale, subsite, siteId, channel } = useSiteDefinition()
  const propertyArgs = {
    defaultLocale,
    subsite,
    siteId,
    channel,
    customerLocation: EnterpriseCtx.currentLocation,
  }

  return (
    <StyledLink
      tabIndex={0}
      href="#main-content"
      data-testid="skiplink"
      onClick={() =>
        transmit({
          type: 'columbo',
          payload: getColumboEventData({
            propertyArgs,
            argumentsObj: {
              '0': 'Accessibility',
              '1': 'Clicked',
              '2': 'SkipLink',
            },
            requestArgs: {
              client_timestamp: Math.round(Date.now() / 1000),
              url: window.location.href,
            },
            eventData: {
              type: 'clicked',
              subtype: 'accessibility_skip_link',
              contents: [],
            },
            nonce: EnterpriseCtx.metricNonce,
          }),
        })
      }
      onFocus={() =>
        transmit({
          type: 'columbo',
          payload: getColumboEventData({
            propertyArgs,
            argumentsObj: {
              '0': 'Accessibility',
              '1': 'Focused',
              '2': 'SkipLink',
            },
            requestArgs: {
              client_timestamp: Math.round(Date.now() / 1000),
              url: window.location.href,
            },
            eventData: {
              type: 'Focused',
              subtype: 'accessibility_skip_link',
              contents: [],
            },
            nonce: EnterpriseCtx.metricNonce,
          }),
        })
      }
    >
      {i18n('header.skip.link.text') || 'Skip to main content'}
    </StyledLink>
  )
}
