import { css, mq, spacing, styled, Text } from '@thg-commerce/gravity-theme'

import { SwatchShape, SwatchSize } from './types'

export interface SwatchStyledProps {
  background: string
  selected: boolean
  swatchShape: SwatchShape
  swatchSize: SwatchSize
  disabled?: boolean
  borderColor?: string
}

export const SwatchContainer = styled.div<{
  hideColours?: boolean
  showOnlyTwoRows?: boolean
  hideSwatches?: boolean
  paddingBottom?: number
}>`
  width: 100%;
  display: ${(props) => (props.hideSwatches ? 'none' : 'flex')};
  flex-wrap: wrap;
  ${(props) =>
    !props.showOnlyTwoRows &&
    props.paddingBottom &&
    `padding-bottom: ${spacing(props.paddingBottom || 2.5)};`}

  ${(props) =>
    props.hideColours &&
    `
      ${mq(props.theme.breakpointUtils.map, 'xs')} {
        max-height: 112px;
        overflow: hidden;
      }
      ${mq(props.theme.breakpointUtils.map, 'sm')} {
        max-height: none;
        overflow: visible;
        padding-bottom: ${spacing(props.paddingBottom || 2.5)};
      }
    `}
`
export const ExpandButton = styled.button`
  padding: 0 0 ${spacing(2)};
  text-decoration: underline
    ${(props) => props.theme.colors.palette.greys.darker};
  ${(props) =>
    Text(props.theme.formgroup.label.scale, props.theme.formgroup.label.style)}
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: none;
  }
`

export const SwatchElementContainer = styled.div<{
  swatchSize: SwatchSize
}>`
  margin: ${(props) =>
    props.swatchSize === SwatchSize.Small
      ? '0'
      : `0 ${spacing(1)} ${spacing(1)} 0`};
`

const circleSwatchAttribute = css`
  border-radius: 50%;
`

const selectedAttribute = css`
  background-color: #fff;
  border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
`

export const SwatchElementButton = styled.button<SwatchStyledProps>`
  display: inline-flex;
  ${(props) =>
    props.swatchShape === SwatchShape.CIRCLE && circleSwatchAttribute}

  &:focus {
    outline: none;
    & > span {
      border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
    }
  }
`

export const OuterSwatchElement = styled.span<SwatchStyledProps>`
  display: inline-flex;

  ${(props) =>
    props.swatchSize === SwatchSize.Small
      ? `
      height: 32px;
      width: 32px;`
      : `
      height: 48px;
      width: 48px;
    `}

  ${(props) =>
    props.swatchShape === SwatchShape.CIRCLE && circleSwatchAttribute}

  ${(props) => props.selected && selectedAttribute}

  &:hover {
    background-color: ${(props) =>
      props.selected
        ? props.theme.colors.palette.brand.lighter
        : props.theme.colors.palette.brand.lightest};
    border: ${(props) => (props.selected ? '3' : '2')}px solid
      ${(props) => props.theme.colors.palette.brand.base};
  }

  &:focus {
    outline: none;
    background-color: ${(props) =>
      props.selected
        ? props.theme.colors.palette.brand.lighter
        : props.theme.colors.palette.brand.lightest};
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }

  &:active {
    background-color: ${(props) =>
      props.selected
        ? props.theme.colors.palette.brand.dark
        : props.theme.colors.palette.brand.base};
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`

interface DisabledStyleProps {
  borderWidth: string
  elementSize: string
  swatchShape: SwatchShape
}

export const InnerSwatchElement = styled.span<
  SwatchStyledProps & DisabledStyleProps
>`
  margin: auto;
  ${(props) => `
    height: ${props.elementSize};
    width: ${props.elementSize};
    `}
  background: ${(props) => props.background};

  ${(props) =>
    props.swatchShape === SwatchShape.CIRCLE && circleSwatchAttribute}
  border: 1px solid ${(props) =>
    props.borderColor || props.theme.colors.palette.brand.base};

  &:focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`
export const SwatchDisableWrapper = styled.div<{ borderWidth: string }>`
  position: absolute;
  z-index: 5;
  ${(props) => `
    top: calc(${props.borderWidth}/2);
    left: calc(${props.borderWidth}/2);
    width: calc(${props.borderWidth}*11);
    height: calc(${props.borderWidth}*11);
  `}
`
