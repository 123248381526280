import axios from 'axios'
import gql from 'graphql-tag'

import { createStore, FetchMode } from '@thg-commerce/enterprise-cache'
import { httpsAgent } from '@thg-commerce/enterprise-network/src/httpsAgent'
import { sanitizeHtml } from '@thg-commerce/enterprise-utils/src/Security/cookieUtilities/safeHtml'

import pkg from '../../package.json'
import { Configuration, SiteProperties } from '../ConfigurationLoader/types'

import { defaults as sitePropertyDefaults } from './sitePropertiesDefaults'

const GQL_CACHE_PREFIX = `core:${pkg.version}`

const SITE_PROPERTIES_QUERY = gql`
  query SiteProperties(
    $propertyBucket: String
    $previewId: String
    $keys: [String!]
  ) {
    siteProperties(propertyBucket: $propertyBucket, previewId: $previewId) {
      properties(keys: $keys) {
        key
        value
        location
      }
    }
  }
`

const store = createStore<
  {
    brand: string
    subsite: string
    previewId?: string
    bucket: string
    graphQLURL: string
  },
  SiteProperties
>({
  key: (args) =>
    `graphql:${GQL_CACHE_PREFIX}:${args.brand}:${args.subsite}:${
      args.previewId ? `${args.previewId}:` : ''
    }:${args.bucket}:SiteProperties`,
  ttlSeconds: 300,
  fetchMode: FetchMode.BACKGROUND_IF_EXPIRED,
  lookup: async (args) => {
    const supportedSitePropertyKeys = Object.keys(sitePropertyDefaults)

    try {
      const response = await axios.post<{
        data: {
          siteProperties: {
            properties: {
              key: string
              value: string
              location: string
            }[]
          }
        }
      }>(
        args.graphQLURL,
        {
          query: SITE_PROPERTIES_QUERY.loc?.source.body,
          variables: {
            propertyBucket: args.bucket,
            previewId: args.previewId || '',
            keys: supportedSitePropertyKeys,
          },
        },
        {
          httpsAgent,
        },
      )

      if (!response.data.data.siteProperties) {
        return {}
      }

      const indexedProperties = response.data.data.siteProperties.properties.reduce<
        SiteProperties
      >((accumulator, property) => {
        accumulator[property.key] = {
          value: sanitizeHtml(property.value, {}),
          location: property.location,
        }
        return accumulator
      }, {})

      return indexedProperties
    } catch (e) {
      console.error('Failed to fetch site properties with error', e.message)
      return {}
    }
  },
})

export const loadSiteProperties = async (
  config: Configuration,
  previewId?: string,
): Promise<SiteProperties> => {
  let siteProperties: SiteProperties = {}

  const { siteDefinition } = config.publicRuntimeConfig

  if (
    !siteDefinition.GRAPHQL_SERVER_URI ||
    !SITE_PROPERTIES_QUERY.loc?.source
  ) {
    return siteProperties
  }

  try {
    siteProperties =
      (await store.get({
        previewId,
        brand: siteDefinition.brand,
        subsite: siteDefinition.subsite,
        bucket: config.publicRuntimeConfig.SITE_PROPERTIES_BUCKET,
        graphQLURL: siteDefinition.GRAPHQL_SERVER_URI,
      })) || {}
  } catch (e) {
    console.warn(`Failed to load site properties with error ${e.message}`)
  }
  return siteProperties
}
