import * as React from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import * as Cookies from 'js-cookie'
import dynamic from 'next/dynamic'

import {
  useConfig,
  useEnterpriseContext,
  useHorizonSessionSettings,
  useLogger,
  useSiteConfig,
  useSiteDefinition,
} from '@thg-commerce/enterprise-core'
import {
  BasketData,
  BasketVariables,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Basket/Basket'
import { Feature } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { createSubscribableRef } from '@thg-commerce/enterprise-utils'

import { BasketPage as BASKET_PAGE_QUERY } from '../../graphql/BasketPage.graphql'
import { setCookie } from '../../utils/CookieHelper'
import { COOKIE_GROUP_BASKET } from '../../utils/CookieHelper/types'

import { BasketContext } from './BasketContext'
import { BasketContextInterface } from './types'

const AddedToBasketModalPresenter = dynamic<{}>(() =>
  import('../AddedToBasketModalPresenter').then(
    (mod) => mod.AddedToBasketModalPresenter,
  ),
)
const QuickBuyModalPresenter = dynamic<{}>(() =>
  import('../QuickBuyModalPresenter').then((mod) => mod.QuickBuyModalPresenter),
)

const AuroraQuickBuyModalPresenter = dynamic<{}>(() =>
  import('../QuickBuyModalPresenter/AuroraQuickBuyModalPresenter').then(
    (mod) => mod.QuickBuyModalPresenter,
  ),
)

const ClickAndCollectModalPresenter = dynamic<{}>(() =>
  import('@thg-commerce/enterprise-modal').then(
    (mod) => mod.ClickAndCollectModalPresenter,
  ),
)

const ClickAndCollectInfoModalPresenter = dynamic<{}>(() =>
  import(
    '@thg-commerce/enterprise-pages/src/Basket/ClickAndCollect/ClickAndCollectInfoModalPresenter/ClickAndCollectInfoModalPresenter'
  ).then((mod) => mod.ClickAndCollectInfoModalPresenter),
)

export interface BasketRef {
  basket?: BasketData
  loading: boolean
}

const loadBasketId = (basketCookieKey: string) => {
  if (typeof window === 'undefined') {
    return undefined
  }

  const basketCookieValue = Cookies.get(basketCookieKey)
  if (typeof basketCookieValue === 'string') {
    return atob(basketCookieValue)
  }

  return undefined
}

export const BasketProvider: React.FunctionComponent<{
  children: React.ReactNode
}> = (props) => {
  const { basketCookieKey } = useConfig()
  const { domain } = useSiteDefinition()
  const basketIdRef = createSubscribableRef<string | undefined>(
    loadBasketId(basketCookieKey),
  )
  const basketRef = createSubscribableRef<BasketRef>({
    basket: undefined,
    loading: true,
  })

  const {
    productDetailKeyAdditionList,
    enableWishlists,
    enableWishlistsGlobal,
    enableWishlistOnBasketItem,
    useGA4EnhancedEcom,
    enableVipPrice,
    enableProductPersonalisation,
    alternateProductKeysForCategories,
  } = useSiteConfig()

  const {
    horizonFeatures,
    appConfig: {
      contentKeys: {
        productDescriptionKeys = {
          items: [],
          details: [],
          video: [],
          download: [],
        },
      } = {},
    },
    requestConfig: { applyCodeToBasketState },
  } = useEnterpriseContext()

  const loyaltyEnabled = horizonFeatures?.includes(Feature.Loyalty) || false
  const clickAndCollectEnabled =
    horizonFeatures?.includes(Feature.ClickAndCollect) || false

  const wishlistEnabled =
    (enableWishlists &&
      enableWishlistsGlobal &&
      enableWishlistOnBasketItem &&
      horizonFeatures?.includes(Feature.Wishlist)) ||
    false

  const vipPriceEnabled =
    (enableVipPrice && horizonFeatures?.includes(Feature.VipPricingEnabled)) ||
    false

  const sessionSettings = useHorizonSessionSettings()

  const {
    value: [getBasket, setBasket],
  } = basketRef

  const logger = useLogger()

  const value: BasketContextInterface = {
    basketIdRef,
    basketRef,
    applyCodeToBasketState,
    presentAddedToBasketModal: React.useRef((_: string) => {}),
    presentQuickBuyModal: React.useRef((_: string) => {}),
    presentAuroraQuickBuyModal: React.useRef(({}) => {}),
    presentClickAndCollectModal: React.useRef((_: string) => {}),
    presentClickAndCollectInfoModal: React.useRef(() => {}),
  }

  const client = useApolloClient()
  const persistBasketId = React.useCallback(
    (newBasketId: string | undefined) => {
      if (typeof window === 'undefined' || typeof newBasketId !== 'string') {
        return
      }

      const cookieExpires = new Date()
      cookieExpires.setDate(cookieExpires.getDate() + 30)

      setCookie({
        domain,
        name: basketCookieKey,
        value: btoa(newBasketId),
        group: COOKIE_GROUP_BASKET,
        expires: cookieExpires,
        path: '/',
      })
    },
    [basketCookieKey, domain],
  )

  const fetchBasket = React.useCallback(
    (newBasketId: string | undefined) => {
      const { basket: currentBasket } = getBasket()
      setBasket({ basket: currentBasket, loading: true })

      client
        .query<{ basket: BasketData }, BasketVariables>({
          query: BASKET_PAGE_QUERY,
          variables: {
            loyaltyEnabled,
            wishlistEnabled,
            clickAndCollectEnabled,
            productDescriptionKeys,
            vipPriceEnabled,
            ...sessionSettings,
            id: newBasketId,
            productContentKeys: [
              ...productDescriptionKeys.items,
              ...productDescriptionKeys.details,
              ...productDescriptionKeys.video,
              ...(productDetailKeyAdditionList || []),
              'hbg_priceType',
              'ppu_unit',
              'ppu_quantity',
            ],
            enableProductPersonalisation: enableProductPersonalisation || false,
            subscriptionsEnabled:
              horizonFeatures?.includes(Feature.Subscriptions) || false,
            subscriptionContractsEnabled:
              horizonFeatures?.includes(Feature.SubscribeAndSave) || false,
            keys: alternateProductKeysForCategories
              ? alternateProductKeysForCategories
              : (useGA4EnhancedEcom && ['productCategory', 'mat_category']) ||
                [],
          },
        })
        .then((response) => {
          setBasket({ basket: response.data.basket, loading: false })
          persistBasketId(response.data.basket.id)
        })
        .catch((e) =>
          logger.error(`Failed to load basket with error ${e.message}`),
        )
    },
    [
      alternateProductKeysForCategories,
      clickAndCollectEnabled,
      client,
      enableProductPersonalisation,
      getBasket,
      horizonFeatures,
      logger,
      loyaltyEnabled,
      persistBasketId,
      productDescriptionKeys,
      productDetailKeyAdditionList,
      sessionSettings,
      setBasket,
      useGA4EnhancedEcom,
      vipPriceEnabled,
      wishlistEnabled,
    ],
  )
  const {
    value: [getBasketId],
    subscribe: basketIdChanged,
    unsubscribe: unsubscribeBasketIdChanged,
  } = basketIdRef

  React.useEffect(() => {
    fetchBasket(getBasketId())
  }, [fetchBasket, getBasketId])

  React.useEffect(() => {
    basketIdChanged(persistBasketId)
    basketIdChanged(fetchBasket)

    return () => {
      unsubscribeBasketIdChanged(persistBasketId)
      unsubscribeBasketIdChanged(fetchBasket)
    }
  }, [
    basketIdChanged,
    fetchBasket,
    persistBasketId,
    unsubscribeBasketIdChanged,
  ])

  return (
    <BasketContext.Provider value={value}>
      {props.children}
      <AddedToBasketModalPresenter />
      <AuroraQuickBuyModalPresenter />
      <QuickBuyModalPresenter />
      {horizonFeatures?.includes(Feature.ClickAndCollect) && (
        <ClickAndCollectModalPresenter />
      )}
      {horizonFeatures?.includes(Feature.ClickAndCollect) && (
        <ClickAndCollectInfoModalPresenter />
      )}
    </BasketContext.Provider>
  )
}
