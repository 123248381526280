import * as React from 'react'
import loadable from '@loadable/component'

import { useTheme } from '@thg-commerce/gravity-patterns/theme'
import { css, mq, spacing, Text } from '@thg-commerce/gravity-theme'

const PaymentMethodIconPlaceholder = () => {
  return <div style={{ width: 60, height: 40 }}></div>
}

const Afterpay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Afterpay'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const AliPay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/AliPay'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Atome = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Atome'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const AlipayPlus = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/AlipayPlus'
    ),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const AmericanExpress = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/AmericanExpress'
    ),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const AmazonPay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/AmazonPay'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const ApplePay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/ApplePay'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Arvato = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Arvato'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Bancontact = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/Bancontact'
    ),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const ClearPay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/ClearPay'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const CustomIcon = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/CustomIcon/CustomIcon'
    ).then((mod) => mod.CustomIcon),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const DinersClub = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/DinersClub'
    ),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Discover = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Discover'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Elo = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Elo'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const GiftCard = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/GiftCard'),
)
const GooglePay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/GooglePay'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Hiper = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Hiper'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Humm = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Humm'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Ideal = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Ideal'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Interac = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Interac'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Jcb = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Jcb'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Kcp = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Kcp'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Klarna = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Klarna'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const KlarnaSlice = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/KlarnaSlice'
    ),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const LayBuy = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/LayBuy'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Maestro = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Maestro'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Mastercard = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/Mastercard'
    ),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const MolPay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/MolPay'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Multibanco = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/Multibanco'
    ),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const NewPay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/NewPay'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const One4All = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/One4All'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Openpay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Openpay'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Paypal = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Paypal'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Paytm = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Paytm'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const PrimeiroPay = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/PrimeiroPay'
    ),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Quadpay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Quadpay'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const RuPay = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/RuPay'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Sepa = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Sepa'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Solo = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Solo'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const SplitIt = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/SplitIt'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Spotii = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Spotii'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Sezzle = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Sezzle'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const TenPay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/TenPay'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const TrustPay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/TrustPay'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Union = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Union'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const VisaCredit = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Visa'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const VisaDebit = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/VisaDebit'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const VisaElectron = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/VisaElectron'
    ),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const WebMoney = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/WebMoney'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const WeChatPay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/WeChatPay'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Yandex = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Yandex'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Zippay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Zippay'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)
const Upi = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Upi'),
  { fallback: <PaymentMethodIconPlaceholder /> },
)

const FrasersPlus = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/FrasersPlus'
    ),
  { fallback: <PaymentMethodIconPlaceholder /> },
)

const FrasersPlusBNPL = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/FrasersPlusBNPL'
    ),
  { fallback: <PaymentMethodIconPlaceholder /> },
)

import { styled } from '../theme'

const TagLine = styled.div`
  ${(props) =>
    Text(
      props.theme.patterns.footer.copyrightAndPayments.font.payment.entry,

      props.theme.patterns.footer.copyrightAndPayments.font.payment.style,
    )}
  text-transform: ${(props) =>
    props.theme.patterns.footer.copyrightAndPayments.font.payment.transform};
  padding-bottom: ${spacing(1)};
  color: ${(props) =>
    props.theme.patterns.footer.copyrightAndPayments.font.payment.textColor};
`

const PaymentIconWrapper = styled.div<{
  includeBorder: boolean
  largeIcon?: boolean
  backgroundColor?: string
  fixedWidth?: boolean
}>`
  min-width: ${(props) => (props.largeIcon ? '96' : '60')}px;
  height: ${(props) => (props.largeIcon ? '36' : '40')}px;
  margin-right: ${spacing(1)};
  border-radius: 4px;
  ${(props) => props.includeBorder && `border: solid 1px #dddddd;`}
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.palette.greys.white};

  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.colors.palette.brand.base};
  }

  ${(props) => props.fixedWidth && `width: ${props.largeIcon ? '96' : '60'}px;`}

  ${(props) =>
    props.largeIcon &&
    props.includeBorder &&
    css`
      border-radius: 8px;
      border: solid 1px #000;
      margin-right: 0;
    `}
`

const StyledUl = styled.ul<{ direction?: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  flex-wrap: wrap;
`

const ListItemContainer = styled.li<{
  largeIcon?: boolean
  numProviders?: number
}>`
  ${(props) =>
    props.largeIcon &&
    css`
      display: flex;
      margin-top: ${spacing(
        props.numProviders && props.numProviders === 1 ? 1.5 : 2,
      )}
      ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
        margin-top: ${spacing(
          props.numProviders && props.numProviders === 1 ? 1.5 : 1,
        )}
    `}
`

interface PaymentDescription {
  providerName: string
  providerDescription: React.ReactElement
}

export interface PaymentMethodsProps {
  text: string
  paymentProviders: string[]
  paymentMethodsI18nText: PaymentMethodsI18nText
  paymentDescriptions?: PaymentDescription[]
  disableTagLine?: boolean
  bnplIcons?: boolean
  direction?: 'row' | 'column'
  fixedPaymentIconWidth?: boolean
}

interface PaymentMethodsI18nText {
  afterPay: string
  alipay: string
  alipayPlus: string
  atome: string
  americanExpress: string
  amazonpay: string
  applePay: string
  arvato: string
  bancontact: string
  clearpay: string
  dinersClub: string
  discover: string
  elo: string
  giftCard?: string
  googlePay: string
  hiper: string
  humm: string
  ideal: string
  interac: string
  jcb: string
  kcp: string
  klarna: string
  klarna_slice: string
  laybuy: string
  maestro: string
  mastercard: string
  molpay: string
  multibanco: string
  newpay: string
  one4All: string
  openpay: string
  paypal: string
  paytm: string
  primeiropay: string
  quadpay: string
  rupay: string
  sepa: string
  solo: string
  splitIt: string
  tenpay: string
  spotii: string
  sezzle: string
  trustPay: string
  unionpay: string
  visaDebit: string
  visaElectron: string
  visaCredit: string
  webMoney: string
  wechatPay: string
  yandex: string
  zippay: string
  upi: string
  frasersPlus: string
}

interface Icon {
  fillColour?: string
  showBorder?: boolean
}

const ICON_MAP: { [key: string]: Icon } = {
  KLARNA: { fillColour: '#ffb3c7', showBorder: false },
  KLARNA_SLICE: { fillColour: '#ffb3c7', showBorder: false },
  DISCOVER: { showBorder: false },
}

const getPaymentMethodIcon = (props: {
  paymentMethod: string
  paymentMethodsI18nText: PaymentMethodsI18nText
  bnplIcons?: boolean
}) => {
  const paymentMethodIcons: { [key: string]: React.ReactElement } = {
    AFTER_PAY: <Afterpay aria-label={props.paymentMethodsI18nText.afterPay} />,
    ALIPAY: <AliPay aria-label={props.paymentMethodsI18nText.alipay} />,
    ALI_PAY_PLUS: (
      <AlipayPlus aria-label={props.paymentMethodsI18nText.alipayPlus} />
    ),
    ATOM: <Atome aria-label={props.paymentMethodsI18nText.atome} />,
    ATOME: <Atome aria-label={props.paymentMethodsI18nText.atome} />,
    AMERICAN_EXPRESS: (
      <AmericanExpress
        aria-label={props.paymentMethodsI18nText.americanExpress}
      />
    ),
    AMAZONPAY: (
      <AmazonPay aria-label={props.paymentMethodsI18nText.amazonpay} />
    ),
    APPLE_PAY: <ApplePay aria-label={props.paymentMethodsI18nText.applePay} />,
    ARVATO: <Arvato aria-label={props.paymentMethodsI18nText.arvato} />,
    BANCONTACT: (
      <Bancontact aria-label={props.paymentMethodsI18nText.bancontact} />
    ),
    CLEAR_PAY: <ClearPay aria-label={props.paymentMethodsI18nText.clearpay} />,
    DINERS_CLUB: (
      <DinersClub aria-label={props.paymentMethodsI18nText.dinersClub} />
    ),
    DISCOVER: <Discover aria-label={props.paymentMethodsI18nText.discover} />,
    ELO: <Elo aria-label={props.paymentMethodsI18nText.elo} />,
    GIFT_CARD: <GiftCard aria-label={props.paymentMethodsI18nText.giftCard} />,
    GOOGLE_PAY: (
      <GooglePay aria-label={props.paymentMethodsI18nText.googlePay} />
    ),
    HIPER: <Hiper aria-label={props.paymentMethodsI18nText.hiper} />,
    HUMM: <Humm aria-label={props.paymentMethodsI18nText.humm} />,
    IDEAL: <Ideal aria-label={props.paymentMethodsI18nText.ideal} />,
    INTERAC: <Interac aria-label={props.paymentMethodsI18nText.interac} />,
    JCB: <Jcb aria-label={props.paymentMethodsI18nText.jcb} />,
    KCP: <Kcp aria-label={props.paymentMethodsI18nText.kcp} />,
    KLARNA: <Klarna aria-label={props.paymentMethodsI18nText.klarna} />,
    KLARNA_SLICE: (
      <KlarnaSlice aria-label={props.paymentMethodsI18nText.klarna_slice} />
    ),
    LAYBUY: <LayBuy aria-label={props.paymentMethodsI18nText.laybuy} />,
    LAY_BUY: <LayBuy aria-label={props.paymentMethodsI18nText.laybuy} />,
    MAESTRO: <Maestro aria-label={props.paymentMethodsI18nText.maestro} />,
    MASTERCARD: (
      <Mastercard aria-label={props.paymentMethodsI18nText.mastercard} />
    ),
    MOL_PAY: <MolPay aria-label={props.paymentMethodsI18nText.molpay} />,
    MULTIBANCO: (
      <Multibanco aria-label={props.paymentMethodsI18nText.multibanco} />
    ),
    NEWPAY: <NewPay aria-label={props.paymentMethodsI18nText.newpay} />,
    ONE4ALL: <One4All aria-label={props.paymentMethodsI18nText.one4All} />,
    OPEN_PAY: <Openpay aria-label={props.paymentMethodsI18nText.openpay} />,
    PAYPAL: <Paypal aria-label={props.paymentMethodsI18nText.paypal} />,
    PAYPAL_IN_3: <Paypal aria-label={props.paymentMethodsI18nText.paypal} />,
    PAYTM: <Paytm aria-label={props.paymentMethodsI18nText.paytm} />,
    PRIMEIRO_PAY: (
      <PrimeiroPay aria-label={props.paymentMethodsI18nText.primeiropay} />
    ),
    QUADPAY: <Quadpay aria-label={props.paymentMethodsI18nText.quadpay} />,
    QUAD_PAY: <Quadpay aria-label={props.paymentMethodsI18nText.quadpay} />,
    RUPAY: <RuPay aria-label={props.paymentMethodsI18nText.rupay} />,
    SEPA: <Sepa aria-label={props.paymentMethodsI18nText.sepa} />,
    SOLO: <Solo aria-label={props.paymentMethodsI18nText.solo} />,
    SPLIT_IT: <SplitIt aria-label={props.paymentMethodsI18nText.splitIt} />,
    SPOTII: <Spotii aria-label={props.paymentMethodsI18nText.spotii} />,
    SEZZLE: <Sezzle aria-label={props.paymentMethodsI18nText.sezzle} />,
    TENPAY: <TenPay aria-label={props.paymentMethodsI18nText.tenpay} />,
    TRUST_PAY: <TrustPay aria-label={props.paymentMethodsI18nText.trustPay} />,
    UNION: <Union aria-label={props.paymentMethodsI18nText.unionpay} />,
    VISA_DEBIT: (
      <VisaDebit aria-label={props.paymentMethodsI18nText.visaDebit} />
    ),
    VISA_ELECTRON: (
      <VisaElectron aria-label={props.paymentMethodsI18nText.visaElectron} />
    ),
    VISA_CREDIT: (
      <VisaCredit aria-label={props.paymentMethodsI18nText.visaCredit} />
    ),
    WEB_MONEY: <WebMoney aria-label={props.paymentMethodsI18nText.webMoney} />,
    WECHAT: <WeChatPay aria-label={props.paymentMethodsI18nText.wechatPay} />,
    YANDEX: <Yandex aria-label={props.paymentMethodsI18nText.yandex} />,
    ZIP_PAY: <Zippay aria-label={props.paymentMethodsI18nText.zippay} />,
    UPI: <Upi aria-label={props.paymentMethodsI18nText.upi} />,
    FRASERS_PLUS: props.bnplIcons ? (
      <FrasersPlusBNPL aria-label={props.paymentMethodsI18nText.frasersPlus} />
    ) : (
      <FrasersPlus aria-label={props.paymentMethodsI18nText.frasersPlus} />
    ),
  }

  return paymentMethodIcons[props.paymentMethod]
}

export const PaymentMethods = (props: PaymentMethodsProps) => {
  const createPaymentMethodListItem = (
    paymentMethod,
    index,
    providerDescription,
  ) => {
    const theme = useTheme()
    let PaymentIcon

    const paymentIcon =
      theme.patterns.footer.copyrightAndPayments.paymentIcons[paymentMethod]

    if (paymentIcon?.svgPath !== ('' || undefined)) {
      PaymentIcon = (
        <CustomIcon
          path={paymentIcon.svgPath}
          fillColor={paymentIcon.color}
          rect={
            paymentIcon.rectBackgroundFill &&
            paymentIcon.rectStrokeFill && {
              x: '0.5',
              y: '0.5',
              width: '59',
              height: '39',
              rx: '3.5',
              fill: paymentIcon.rectBackgroundFill,
              stroke: paymentIcon.rectStrokeFill,
            }
          }
          height={paymentIcon.height}
          width={paymentIcon.width}
          viewBox={paymentIcon.viewBox}
        />
      )
    } else {
      PaymentIcon = getPaymentMethodIcon({
        paymentMethod,
        paymentMethodsI18nText: props.paymentMethodsI18nText,
        bnplIcons: props.bnplIcons,
      })
    }

    if (PaymentIcon) {
      const iconConfig = ICON_MAP[paymentMethod]

      return (
        <ListItemContainer
          key={`payment-method-${index}`}
          largeIcon={!!props.bnplIcons}
          numProviders={props.paymentProviders.length}
        >
          <PaymentIconWrapper
            data-testid={'payment-icon'}
            includeBorder={
              typeof iconConfig?.showBorder === 'boolean'
                ? iconConfig.showBorder
                : true
            }
            backgroundColor={iconConfig?.fillColour}
            largeIcon={!!props.bnplIcons}
            fixedWidth={props.fixedPaymentIconWidth}
          >
            {PaymentIcon}
          </PaymentIconWrapper>
          {providerDescription ? providerDescription : null}
        </ListItemContainer>
      )
    }
    return null
  }

  if (!props.paymentProviders || props.paymentProviders.length === 0) {
    return null
  }

  const PaymentMethodsList = (
    paymentProviders: string[],
    paymentDescriptions?: PaymentDescription[],
  ) => {
    return paymentProviders.map((provider, index) => {
      const paymentDescription = paymentDescriptions?.find((description) => {
        return provider === description.providerName
      })
      return createPaymentMethodListItem(
        provider,
        index,
        paymentDescription?.providerDescription,
      )
    })
  }

  return (
    <React.Fragment>
      {props.disableTagLine === true ? null : (
        <TagLine id="paymentMethods">{props.text}</TagLine>
      )}
      <StyledUl aria-labelledby={'paymentMethods'} direction={props.direction}>
        {PaymentMethodsList(props.paymentProviders, props.paymentDescriptions)}
      </StyledUl>
    </React.Fragment>
  )
}
