import * as React from 'react'

import Link from 'next/link'

import { AvailableRoutes } from '../Routes'

type ValueOf<T> = T[keyof T]

export interface LinkProps {
  isExternal?: boolean
  href: ValueOf<AvailableRoutes>
  as?: ValueOf<AvailableRoutes>
  children: React.ReactElement
  [x: string]: any
}

export const isExternalLink = (href: string, host: string) => {
  try {
    const parsedUrl = new URL(href)
    return parsedUrl.host !== '' && parsedUrl.host !== host
  } catch {
    return false
  }
}

export const NextLink = (props: LinkProps) => {
  const { isExternal, href, children, ...rest } = props
  const isServer = typeof window === 'undefined'
  const [isSameDomain, setIsSameDomain] = React.useState(true)
  React.useEffect(() => {
    if (!isServer && href && isExternalLink(href, window.location.host)) {
      setIsSameDomain(false)
    }
  }, [])

  if (isSameDomain === false) {
    return React.cloneElement(children, {
      href,
      rel: 'noopener noreferrer',
      ...rest,
    })
  }

  return (
    <Link
      {...rest}
      href={decodeURI(href)}
      passHref={true}
      prefetch={isExternal ? false : undefined}
    >
      {children}
    </Link>
  )
}

NextLink.defaultProps = {
  isExternal: false,
}
