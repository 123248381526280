import { Property } from 'csstype'

import { ButtonEmphases } from '@thg-commerce/gravity-elements'
import { SwatchShape } from '@thg-commerce/gravity-elements/Swatch/types'
import {
  BreakpointArray,
  Margin,
  Orientation,
} from '@thg-commerce/gravity-theme'
import { colors } from '@thg-commerce/gravity-theme/colors'
import { TextStyling } from '@thg-commerce/gravity-theme/typography/typography'

import { CarouselThemeInterface } from '../Carousel/theme'

import {
  FlexAlignments,
  HorizontalAlignment,
  Order,
  ProductBlockComponents,
} from './types'

interface Components {
  titleLabel: {
    type: ProductBlockComponents
    order: Order
    title: {
      font: TextStyling
      alignment: HorizontalAlignment
    }
  }
  label: {
    type: ProductBlockComponents
    order: Order
    title: {
      font: TextStyling
    }
  }
  productReview: {
    type: ProductBlockComponents
    order: Order
    colorFills?: {
      fill: Property.Color
      background: Property.Color
    }
  }
  powerReviewCategorySnippet: {
    type: ProductBlockComponents
    order: Order
    displayStyle?: string
  }
  marketedSpecialOffer: {
    type: ProductBlockComponents
    order: Order
  }
  swatch: {
    type: ProductBlockComponents
    order: Order
    shape: SwatchShape
    border?: {
      color?: Property.Color
    }
    padding?: {
      bottom?: number
    }
  }
  iconsPrice: {
    type: ProductBlockComponents
    order: Order
    height: string
    justifyContent: string
    margin: Margin
  }
  description: {
    type: ProductBlockComponents
    order: Order
  }
  options: {
    type: ProductBlockComponents
    order: Order
    icon: { fill: string; path: string }
  }
  productButton: {
    type: ProductBlockComponents
    order: Order
    alignItem: FlexAlignments
    isFullWidth: boolean
    desktopWidth: string
    emphasis: ButtonEmphases
    height: string
  }
  wishlistButton: {
    type: ProductBlockComponents
    order: Order
    style?: WishlistButtonStyleInterface
  }
  smallQuickbuyButton: SmallQuickbuyButtonInterface
  inlineQuickbuyButton: InlineQuickbuyButtonInterface
}

export interface ProductBlockFocusStyle {
  width?: string
  style?: string
  color?: string
}

export enum ProductBlockPriceDisplayStyle {
  DEFAULT_PRICE = 'default_price',
  PRICE_RANGE = 'price_range',
}

export interface Price {
  displayStyle: ProductBlockPriceDisplayStyle
  displayRRPText: boolean
  rrpText?: string
  orientation: Orientation
  displayRRPValue?: boolean
  displayFromText?: boolean
  savings?: {
    show: boolean
    textStyle?: TextStyling
    label?: {
      show: boolean
    }
  }
  savingsPercentage?: {
    show: boolean
    showPercentageOnly?: boolean
  }
}

export interface Attributes {
  gap: BreakpointArray<number>
  alternateGap: BreakpointArray<number>
}

export interface ProductBlockInterface {
  price: Price
  transformedTitle: boolean
  components: Components
  attributes: Attributes
  height: string
  focusStyle?: ProductBlockFocusStyle
  alignment: {
    column: {
      justifyContent: FlexAlignments
      alignItems: FlexAlignments
    }
    row: {
      justifyContent: FlexAlignments
      alignItems: FlexAlignments
    }
  }
  carousel: CarouselThemeInterface
  productBorder?: string
}

const SmallQuickbuyButtonDefaultValue: Omit<
  SmallQuickbuyButtonInterface,
  'type' | 'order'
> = {
  icon: {
    fill: colors.palette.greys.white,
    width: ['36px', '36px', '30px', '30px'],
    height: ['36px', '36px', '30px', '30px'],
  },
  button: {
    backgroundColor: colors.palette.brand.base,
    borderRadius: '50%',
    size: ['56px', '56px', '42px', '42px'],
    bottom: ['-25px', '-25px', '-14px', '-14px'],
    carouselButtonBottom: ['-12px'],
    right: ['16px', '16px', '16px', '16px'],
  },
}

const InlineQuickbuyButtonDefaultValue: Omit<
  InlineQuickbuyButtonInterface,
  'type' | 'order'
> = {
  button: {
    backgroundColor: colors.palette.brand.base,
    hoverBackgroundColor: colors.palette.accent.base,
    textColor: colors.palette.greys.white,
    position: 'absolute',
    bottom: '8px',
    right: '8px',
  },
}

// @TODO REBUILD-5572: Update to use aseptic ratio once REBUILD-5068 is released,
//  remove manual image from theme
export interface ManualRecommendationInterface {
  image: {
    width: BreakpointArray<string>
    height: BreakpointArray<string>
  }
}

export const theme: ProductBlockInterface = {
  height: 'auto',
  transformedTitle: false,
  alignment: {
    column: {
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    row: {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  },
  price: {
    displayStyle: ProductBlockPriceDisplayStyle.DEFAULT_PRICE,
    displayRRPText: true,
    rrpText: 'RRP',
    orientation: Orientation.HORIZONTAL,
  },
  attributes: {
    gap: [2],
    alternateGap: [2],
  },
  components: {
    label: {
      type: ProductBlockComponents.SPONSORED_LABEL,
      order: Order.ONE,
      title: {
        font: {
          entry: 'bodyText',
          style: 'default',
          transform: 'none',
          textColor: '#666666',
          textDecoration: 'none',
        },
      },
    },
    titleLabel: {
      type: ProductBlockComponents.TITLE_LABEL,
      order: Order.TWO,
      title: {
        font: {
          entry: 'bodyText',
          style: 'default',
          transform: 'none',
          textColor: '#333333',
          textDecoration: 'none',
        },
        alignment: 'left',
      },
    },
    productReview: {
      type: ProductBlockComponents.PRODUCT_REVIEW,
      order: Order.THREE,
    },
    marketedSpecialOffer: {
      type: ProductBlockComponents.MARKETED_SPECIAL_OFFER,
      order: Order.FOUR,
    },
    powerReviewCategorySnippet: {
      type: ProductBlockComponents.POWER_REVIEW_CATEGORY_SNIPPET,
      order: Order.FIVE,
      displayStyle: 'none',
    },
    swatch: {
      type: ProductBlockComponents.SWATCH,
      order: Order.SIX,
      shape: SwatchShape.CIRCLE,
      padding: {
        bottom: 2.5,
      },
    },
    iconsPrice: {
      type: ProductBlockComponents.ICONS_PRICE,
      order: Order.SEVEN,
      height: 'auto',
      justifyContent: 'flex-start',
      margin: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
    },
    description: {
      type: ProductBlockComponents.DESCRIPTION,
      order: Order.EIGHT,
    },
    options: {
      type: ProductBlockComponents.OPTIONS,
      order: Order.NINE,
      icon: {
        fill: '#333333',
        path: '',
      },
    },
    productButton: {
      type: ProductBlockComponents.PRODUCT_BUTTON,
      order: Order.TEN,
      alignItem: 'flex-start',
      isFullWidth: false,
      desktopWidth: 'auto',
      emphasis: 'high',
      height: '100%',
    },
    wishlistButton: {
      type: ProductBlockComponents.WISHLIST_BUTTON,
      order: Order.NINE,
    },
    smallQuickbuyButton: {
      type: ProductBlockComponents.SMALL_QUICKBUY_BUTTON,
      order: Order.TEN,
      ...SmallQuickbuyButtonDefaultValue,
    },
    inlineQuickbuyButton: {
      type: ProductBlockComponents.INLINE_QUICKBUY_BUTTON,
      ...InlineQuickbuyButtonDefaultValue,
    },
  },
  carousel: {
    pageIndicator: {
      style: 'dot',
      wrapper: {
        margin: {
          top: 3,
          bottom: 0,
          right: 0,
          left: 0,
        },
      },
    },
    chevronIconStyle: {
      chevronIcon: {
        viewBox: '0 0 24 24',
        width: '24px',
        height: '24px',
        right: {
          svgPath: '',
        },
        left: {
          svgPath: '',
        },
      },
    },
  },
}

// @TODO REBUILD-5572: Update to use aseptic ratio once REBUILD-5068 is released,
// remove manual image from theme
export const ManualRecomendationTheme: ManualRecommendationInterface = {
  image: {
    width: ['150px', '200px'],
    height: ['200px', '250px'],
  },
}

interface IconInterface {
  fill: Property.Color
  opacity: number
  color: Property.Color
}

interface ButtonStyleInterface {
  icon: IconInterface
  button: {
    borderColor: Property.Color
    backgroundColor: Property.Color
    backgroundOpacity: number
  }
}

interface ButtonStyleInterface {
  icon: IconInterface
  button: {
    borderColor: Property.Color
    backgroundColor: Property.Color
    backgroundOpacity: number
  }
}

export interface WishlistButtonStyleInterface {
  default: ButtonStyleInterface
  hover: ButtonStyleInterface
  active: ButtonStyleInterface
  focus: ButtonStyleInterface
}

export interface SmallQuickbuyButtonInterface {
  type: ProductBlockComponents
  order: Order
  icon: {
    fill: string
    width: BreakpointArray<string>
    height: BreakpointArray<string>
  }
  button: {
    backgroundColor: string
    borderRadius: string
    size: BreakpointArray<string>
    bottom: BreakpointArray<string>
    carouselButtonBottom: BreakpointArray<string>
    right: BreakpointArray<string>
  }
}

export interface InlineQuickbuyButtonInterface {
  button: {
    backgroundColor: string
    hoverBackgroundColor: string
    textColor: string
    position: string
    right: string
    bottom: string
  }
  type: ProductBlockComponents
}
